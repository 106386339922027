/* This file is to assign new roles and what they can do. Make sure the role you are adding is in the role table in the db adn assigned to a user.
Static is where you will list pages that role can visit and actions they can take.
Make sure that any new roles added - their actions and pages they can visit are ADDED to the ADMIN role.
After you add the roles here, go to App.js and add the role to the role prop on any Protected Route component that the new role is allowed to
access. Then you can start using the Can component to dynamically render components based on role (which is pulled off the user logging in) and
perform (the action/item that must listed in that roles' static array) this must be listed under the role that is logged in or it will fail.
See Header.js for basic example on how the nav menu links are dynamically rendered based on role and perform in the Can component.
 */

const rules = {
  admin: {
    static: [
      "sku:view",
      "sku:edit",
      "sku:create",
      "inventory-cycle-count:edit",
      "schedule:edit",
      "inventory-page:visit",
      "inventory-cycle-count-page:visit",
      "home-page:visit",
      "production-schedule-page:visit",
      "skus-page:visit",
      //not sure if i need these because if use can access skus they should be able to access all these sub navs
      "brands-page:visit",
      "families-page:visit",
      "boxes-page:visit",
      "sizes-page:visit",
      "cores-page:visit",
      //may need to change the name of these to match the route for more consistency
      "core-material-page:visit",
      "core-type-page:visit",
      "fabric-layer-type-page:visit",
      "fabric-quilt-patterns-page:visit",
      "fabric-uses-page:visit",
      "customers-page:visit",
      "manufacturers-page:visit",
      "foundation-groups-page:visit",
      "foundations-page:visit",
      "material-groups-page:visit",
      "materials-page:visit",
      "prototypes-page:visit",
      "settings-page:visit",
      "shipping-page:visit",
      "shipping-page:edit",
      "retail-page:visit",
      "dealer-page:visit",
      "forecast-page:visit",
      "forecast-page:edit"
    ]
  },
  sku_edit: {
    static: [
      "sku:view",
      "sku:edit",
      "sku:create",
      "home-page:visit",
      "skus-page:visit",
      //not sure if i need these because if use can access skus they should be able to access all these sub navs
      "brands-page:visit",
      "families-page:visit",
      "boxes-page:visit",
      "sizes-page:visit",
      "cores-page:visit",
      //may need to change the name of these to match the route for more consistency
      "core-material-page:visit",
      "core-type-page:visit",
      "fabric-layer-type-page:visit",
      "fabric-quilt-patterns-page:visit",
      "fabric-uses-page:visit",
      "customers-page:visit",
      "manufacturers-page:visit",
      "foundation-groups-page:visit",
      "foundations-page:visit",
      "material-groups-page:visit",
      "materials-page:visit",
      "prototypes-page:visit"
    ]
  },
  sku_view: {
    static: [
      "sku:view",
      "home-page:visit",
      "skus-page:visit",
      "brands-page:visit",
      "families-page:visit",
      "boxes-page:visit",
      "sizes-page:visit",
      "cores-page:visit",
      //may need to change the name of these to match the route for more consistency
      "core-material-page:visit",
      "core-type-page:visit",
      "fabric-layer-type-page:visit",
      "fabric-quilt-patterns-page:visit",
      "fabric-uses-page:visit",
      "customers-page:visit",
      "manufacturers-page:visit",
      "foundation-groups-page:visit",
      "foundations-page:visit",
      "material-groups-page:visit",
      "materials-page:visit",
      "prototypes-page:visit"
    ]
  },
  schedule: {
    static: [
      "schedule:edit",
      "home-page:visit",
      "production-schedule-page:visit"
    ]
  },
  inventory_edit: {
    static: [
      "home-page:visit",
      "inventory-page:visit",
      "inventory-cycle-count-page:visit",
      "inventory-cycle-count:edit"
    ]
  },
  shipping_edit: {
    static: ["home-page:visit", "shipping-page:visit", "shipping-page:edit"]
  },
  piece: {
    static: ["piece-page:visit"]
  },
  retail_edit: {
    static: ["retail-page:visit", "retail-page:edit"]
  },
  dealer_edit: {
    static: ["dealer-page:visit", "dealer-page:edit"]
  },
  forecast_edit: {
    static: ["forecast-page:visit", "forecast-page:edit"]
  },
  scan: {
    static: ["scan-page:visit", "scan-page:edit"]
  }
};

export default rules;
