import React from 'react'
import '../styles/about-page.css'
import CoreForm from './forms/CoreForm'

const CoreFormPage = ({ location }) => {
  
    return (
        <div>
            <CoreForm location={location}/>
        </div>
    )
}

export default CoreFormPage