
export const MATERIAL_UPDATE_FIELD = 'MATERIAL_UPDATE_FIELD'

export const MATERIAL_LOADING_REQUEST = 'MATERIAL_LOADING_REQUEST'
export const MATERIAL_LOADING_SUCCESS = 'MATERIAL_LOADING_SUCCESS'
export const MATERIAL_LOADING_ERROR = 'MATERIAL_LOADING_ERROR'

export const MATERIALS_LOADING_REQUEST = 'MATERIALS_LOADING_REQUEST'
export const MATERIALS_LOADING_SUCCESS = 'MATERIALS_LOADING_SUCCESS'
export const MATERIALS_LOADING_ERROR = 'MATERIALS_LOADING_ERROR'

export const MATERIAL_IMPORT_LOADING_REQUEST = 'MATERIAL_IMPORT_LOADING_REQUEST'
export const MATERIAL_IMPORT_LOADING_SUCCESS = 'MATERIAL_IMPORT_LOADING_SUCCESS'
export const MATERIAL_IMPORT_LOADING_ERROR = 'MATERIAL_IMPORT_LOADING_ERROR'

export const MATERIAL_SAVING_REQUEST = 'MATERIAL_SAVING_REQUEST'
export const MATERIAL_SAVING_SUCCESS = 'MATERIAL_SAVING_SUCCESS'
export const MATERIAL_SAVING_ERROR = 'MATERIAL_SAVING_ERROR'

export const MATERIALS_SAVING_REQUEST = 'MATERIALS_SAVING_REQUEST'
export const MATERIALS_SAVING_SUCCESS = 'MATERIALS_SAVING_SUCCESS'
export const MATERIALS_SAVING_ERROR = 'MATERIALS_SAVING_ERROR'
