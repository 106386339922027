import React from 'react';
import '../styles/sku.css';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import {saveSku, saveSkuList, deleteDraft, triggerBOM} from '../actions/skuActions';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import { getParts, savePart, getPart } from "../actions/partActions";
import { getWeight, saveWeight, getWeights } from "../actions/weightActions";
import moment from 'moment';


class InputOverlay extends React.Component {
    componentDidMount() {
        this.setState({skuArr: this.props.masterSkuArr});
        this.setState({parts: this.props.masterSkuArr[0].parts});
        this.handleWeight();
    }

    handleSkuNameChange = async (e, i) => {
        let finalSkuArr = await this.state.skuArr.map((s, index) =>{
            if(i === index) {
                s.sku = e.target.value.toUpperCase().trim()
                return s
            }
            return s
        })
        //check if name exists on new skus that are being made
        if(this.props.newSkus == true){
            finalSkuArr = await finalSkuArr.map(s => {
                let nameExists = this.props.allSkus.find(sku => sku.sku === s.sku)
                if(nameExists != undefined){
                    return {
                        ...s,
                        nameExists: true
                    }
                } else {
                    return {
                        ...s,
                        nameExists: false
                    }
                }
            })
        }
        await this.setState({skuArr: finalSkuArr})
    }

    handleSkuUPCChange = (e, i) => {
        let finalSkuArr = this.state.skuArr.map((s, index) =>{
            if(i === index) {
                if(e.target.value == ''){
                    s.upc = null
                    return s
                } else {
                    s.upc = e.target.value
                    return s
                }
            }
            return s
        })
        this.setState({skuArr: finalSkuArr})
    }

    handleWeightChange = async(e,i ) => {
        console.log("Testing")
        let finalSkuArr = this.state.skuArr.map((s, index) =>{
            if (i === index) {
                s.weight = e.target.value
                return s
            }
            return s
        })
        await this.setState({skuArr: finalSkuArr})
    }

    // ~~State Variables~~
    state = {
        skuArr: [],
        disabled: false,
        weight: [],
        parts: this.props.masterSkuArr[0].parts,
        partsList: [],
    }

    handleSubmit = async (e) => {
        // e.preventDefault()
        const {dispatch} = this.props
        let draftSubmitParts = [];

        //remove values on object that do not go into DB
        let skus = await this.state.skuArr.map((s) => {
            if(s.skuAttribute.length > 1){
                s.skuAttribute = s.skuAttribute.map(({box,...rest}) => rest)
            }
            //remove all unnecessary attributes on sku Obj
            delete s.firmnessName;
            delete s.sizeName;
            delete s.sizeAlias;
            delete s.topPanel;
            delete s.sidePanel;
            delete s.gussetPanel;
            delete s.bottomPanel;
            delete s.ltCustomer;
            delete s.ltManufacturer;
            delete s.ltMaterialGroup;
            delete s.firmness;
            delete s.weight;
            delete s.family;
            delete s.brand;
            delete s.size;
            delete s.parts;
            delete s.core;
            delete s.fishbowl_id;
            delete s.core_type;
            delete s.firmnessAlias;
            delete s.totalHeight;
            delete s.nameExists;
            delete s.topQuiltPattern;
            delete s.gussetQuiltPattern;
            delete s.bottomQuiltPattern;
            delete s.sideQuiltPattern;
            delete s.topTape;
            delete s.sideTape;
            delete s.bottomTape;
            delete s.plastics;
            delete s.zipperParts;
            return s
        });
         await dispatch(saveSkuList(skus))
          .then(res => {
            if(res.type == 'SKUS_SAVING_SUCCESS') {
              dispatch(triggerBOM())
            }
          })
         /* saves skus */
        if(this.props.stagingId != ''){
             dispatch(deleteDraft(this.props.stagingId))
        }
        if(!this.props.isError){
            this.props.history.push("/skus")
        }
    }

    // function handles the weights and repacks SkuArr to remove a lot of useless parts
    handleWeight = async (e) => {
        const {dispatch} = this.props
        let skuArr = this.props.masterSkuArr;

        let weightPayload = await dispatch(getWeights())
        let weights = weightPayload.payload;

        for (let place in skuArr) {
            for (let iterator = 0; iterator < weights.length; iterator++) {
                if (skuArr[place].core_id == weights[iterator].core_id && skuArr[place].size_id == weights[iterator].size_id) {
                    //let newSkuArrPart = { weight: weights[iterator].mattress_weight }
                    skuArr[place] = {...skuArr[place], weight: weights[iterator].mattress_weight }
                }
            }
        }
        this.setState({skuArr: skuArr})
    }

    render() {
        return (
            <div className={'overlay-container'}>
                <div className={'input-overlay'}>
                  <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'flex-end'}}>
                      <CloseIcon
                          onClick={() => {this.props.closeFxn(); this.props.submitBtnFxn()}}
                          style={{marginTop: 20, marginRight: 20, cursor: 'pointer'}} fontSize='large'
                      />
                  </div>
                    <div className={'input-overlay-inputs'}>
                        {this.state.skuArr.map((sku, i) =>
                            <div key={i} style={{width: 1200}}>
                                {/*show size and firmness and parent core*/}
                                <FormControl className={'sku-form-control'} style={{display: 'inline'}}>
                                    <TextField
                                        label="Sku"
                                        name="sku"
                                        margin="normal"
                                        variant="outlined"
                                        className="name-field"
                                        value={sku.sku.toUpperCase().trim()}
                                        onChange={(e) => this.handleSkuNameChange(e, i)}
                                    />
                                </FormControl>
                                {this.props.newSkus == true && sku.nameExists == true ? <span style={{marginLeft: 10, marginRight: 20,color: 'red', fontSize: 15, top: 30, position: 'relative'}}>Sku name exists already</span> : ''}
                                {this.props.upc ?
                                <FormControl className={'sku-form-control'} style={{display: 'inline', position: 'relative'}}>
                                    <TextField
                                        label="UPC"
                                        name="upc"
                                        margin="normal"
                                        variant="outlined"
                                        className="name-field"

                                        value={sku.upc ? sku.upc : ''}
                                        onChange={(e) => this.handleSkuUPCChange(e, i)}
                                    />
                                </FormControl>
                                : ''}
                                <FormControl className={'sku-form-control'} style={{display: 'inline', position: 'relative'}}>
                                     <TextField 
                                        label="Weight Estimate (oz)"
                                        name="Weight"
                                        margin="normal"
                                        variant="outlined"
                                        className="name-field"
                                        value={sku.weight ? sku.weight : ''}
                                        disabled={true}
                                        onChange={(e) => this.handleWeightChange(e,i)}
                                     />
                                 </FormControl>
                                <span style={{display: 'inline', marginLeft: 15, position: 'relative', top: 30}}>
                                    {sku.sizeName} - {sku.firmnessName}
                                 </span>
                                 <hr width="100%"></hr>
                            </div>
                        )}
                    </div>
                    <div className={"sku-submit"} style={{marginRight: 30}}>
                        <Button
                            variant="contained"
                            style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                            onClick={(e) => {this.setState({disabled: true}, () => this.handleSubmit(e))}}
                            disabled={this.state.disabled}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(InputOverlay)