import React from 'react'
import '../styles/about-page.css'
import ParentCoreForm from './forms/ParentCoreForm'

const ParentCoreFormPage = ({ location }) => {
    return (
        <div>
            <ParentCoreForm/>
        </div>
    )
}

export default ParentCoreFormPage