import React from 'react';
import MaterialList from './List/MaterialList';
import '../styles/about-page.css';

export class MaterialListPage extends React.Component {

    render() {
        return (
            <MaterialList/>
        );
    }
}

export default MaterialListPage;
