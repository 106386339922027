import React from 'react';
import T from 'prop-types';
import { Box, Card, Modal, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./deleteconfirmmodal.css";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { deleteCoreLayer } from '../../actions/coreLayerActions';

const DeleteConfirmationModal = (props) => {
  const dispatch = useDispatch()
  const { 
    modalStatus, 
    message,
    setCoreData,
    layerId,
    layers, 
    setModalStatus } = props;
  
  const handleDeleteConfirmation = async () => {
    await dispatch(deleteCoreLayer(layerId))
      .then(res => {
        if(res.type === "CORE_LAYER_DELETE_SUCCESS") {
          setCoreData(prevState => ({
            ...prevState,
            layers: layers.filter(layer => layer.id !== layerId )
          }))
          setModalStatus({modalStatus: false})
        }
      })
      .catch(err => console.log(err))
  };
  
  const handleCancel = () => {
    setModalStatus({modalStatus: false})
  };
  return (
    <>
    {message ?
      <>
        <Modal
          open={modalStatus}
          aria-labelledby="delete-modal-modal-title"
          aria-describedby="delete-modal-modal-description"
        >
          <Box className='delete-modal-box'>
            <Card
            className='delete-modal-card'
            >
              <div className="delete-modal-title">
                <Typography
                  className='delete-modal-typo-title'
                  variant='h5'
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="3x"
                    color="white"
                  />
                </Typography>
              </div>
              {message.map((field, idx) => (
                  <div className="delete-modal-description" key={idx}>
                    <Typography id="delete-modal-modal-title" variant="h4" >
                      {field.title}
                    </Typography>
                    <Typography className="delete-modal-body-description">
                      {field.body}
                    </Typography>
                  </div>
                ))}
                <Box className='delete-modal-buttonBox'>
                  <Button 
                    variant='contained'
                    onClick={handleCancel}
                  >Cancel</Button>
                  <Button 
                    variant='contained'
                    onClick={handleDeleteConfirmation}
                  >Delete</Button>
                </Box>
            </Card>
          </Box>
        </Modal>
      </> 
    :
      ""
    }
    </>
  )
};

DeleteConfirmationModal.propTypes = {
  modalStatus: T.bool,
  message: T.string,
  layerId: T.string,
  layers: T.array,
};
export default DeleteConfirmationModal;