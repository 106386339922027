
export const CORE_TYPE_UPDATE_FIELD = 'CORE_TYPE_UPDATE_FIELD'

export const CORE_TYPE_LOADING_REQUEST = 'CORE_TYPE_LOADING_REQUEST'
export const CORE_TYPE_LOADING_SUCCESS = 'CORE_TYPE_LOADING_SUCCESS'
export const CORE_TYPE_LOADING_ERROR = 'CORE_TYPE_LOADING_ERROR'

export const CORE_TYPES_LOADING_REQUEST = 'CORE_TYPES_LOADING_REQUEST'
export const CORE_TYPES_LOADING_SUCCESS = 'CORE_TYPES_LOADING_SUCCESS'
export const CORE_TYPES_LOADING_ERROR = 'CORE_TYPES_LOADING_ERROR'

export const CORE_TYPE_IMPORT_LOADING_REQUEST = 'CORE_TYPE_IMPORT_LOADING_REQUEST'
export const CORE_TYPE_IMPORT_LOADING_SUCCESS = 'CORE_TYPE_IMPORT_LOADING_SUCCESS'
export const CORE_TYPE_IMPORT_LOADING_ERROR = 'CORE_TYPE_IMPORT_LOADING_ERROR'

export const CORE_TYPE_SAVING_REQUEST = 'CORE_TYPE_SAVING_REQUEST'
export const CORE_TYPE_SAVING_SUCCESS = 'CORE_TYPE_SAVING_SUCCESS'
export const CORE_TYPE_SAVING_ERROR = 'CORE_TYPE_SAVING_ERROR'

export const CORE_TYPES_SAVING_REQUEST = 'CORE_TYPES_SAVING_REQUEST'
export const CORE_TYPES_SAVING_SUCCESS = 'CORE_TYPES_SAVING_SUCCESS'
export const CORE_TYPES_SAVING_ERROR = 'CORE_TYPES_SAVING_ERROR'

export const CORE_TYPES_LIST_LOADING_REQUEST = "CORE_TYPES_LIST_LOADING_SUCCESS"
export const CORE_TYPES_LIST_LOADING_SUCCESS = "CORE_TYPES_LIST_LOADING_SUCCESS"
export const CORE_TYPES_LIST_LOADING_ERROR = "CORE_TYPES_LIST_LOADING_SUCCESS"