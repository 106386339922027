export const REPORT_UPDATE_FIELD = "REPORT_UPDATE_FIELD";

export const REPORTS_LOADING_REQUEST = "REPORTS_LOADING_REQUEST";
export const REPORTS_LOADING_SUCCESS = "REPORTS_LOADING_SUCCESS";
export const REPORTS_LOADING_ERROR = "REPORTS_LOADING_ERROR";

export const REPORT_EMBED_URL_LOADING_REQUEST =
  "REPORT_EMBED_URL_LOADING_REQUEST";
export const REPORT_EMBED_URL_LOADING_SUCCESS =
  "REPORT_EMBED_URL_LOADING_SUCCESS";
export const REPORT_EMBED_URL_LOADING_ERROR = "REPORT_EMBED_URL_LOADING_ERROR";

export const REPORT_STATUS_LOADING_REQUEST = "REPORT_STATUS_LOADING_REQUEST";
export const REPORT_STATUS_LOADING_SUCCESS = "REPORT_STATUS_LOADING_SUCCESS";
export const REPORT_STATUS_LOADING_ERROR = "REPORT_STATUS_LOADING_ERROR";

export const INGESTION_STATUS_LOADING_REQUEST =
  "INGESTION_STATUS_LOADING_REQUEST";
export const INGESTION_STATUS_SAVING_SUCCESS =
  "INGESTION_STATUS_LOADING_SUCCESS";
export const INGESTION_STATUS_LOADING_ERROR = "INGESTION_STATUS_LOADING_ERROR";
