import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { useDispatch } from "react-redux";
import {
  getFlatPackSchedule,
  updateFlatPackSchedule
} from "../../../actions/scheduleActions";
import { Box } from "@material-ui/core";
import moment from "moment";

const TruckUpdate = () => {
  const dispatch = useDispatch();
  const [dataObj, setDataObj] = useState([]);

  useEffect(() => {
    setDataObj([]);
    let newData = JSON.parse(window.localStorage.getItem("location"));
    dispatch(getFlatPackSchedule(25)).then((res) => {
      if (res.payload.length > 0) {
        const data = res.payload;
        let newData = [];
        let truck = {};
        let order = new Map();

        for (let i = 0, len = data.length; i < len; i++) {
          let item = data[i];
          let p = item.truck_number;
          let odr = item.order_number;
          if (!truck[p]) {
            truck[p] = [];
          }
          truck[p].push(item);

          if (!order.has(odr)) {
            order.set(odr, []);
          }
          order.get(odr).push(item);
        }

        for (let ord of order.keys()) {
          let data = { order_number: ord };
          newData.push(data);
          for (let item of order.get(ord)) {
            const customer = item.factOrder[0];

            if (data.order_number === item.order_number) {
              data.truck_number = item.truck_number;
              data.customer = customer?.orderShipName
                ? customer?.orderShipName
                : "N/A";
              data.stop_number = item.stop_number;
              data.schedule = moment.utc(item.date).format("MM-DD-YYYY");
              data.pickup_date = moment
                .utc(item.pickup_date)
                .format("MM-DD-YYYY");
              data.pickup_time = item.pickup_time;
              data.priority = item.priority;
              data.store = item.store;
            }
          }
        }
        setDataObj(newData);
      }
    });
  }, []);

  const updateScheduleData = (data) => {
    dispatch(updateFlatPackSchedule(data)).then((res) => {
      console.log("res", res);
    });
  };

  const headers = [
    {
      title: "Scheduled Date",
      field: "schedule",
      editable: "never"
    },
    {
      title: "Order Number",
      field: "order_number",
      editable: "never"
    },
    {
      title: "Pick Up Date ",
      field: "pickup_date",
      type: "date",
      cellStyle: {
        whiteSpace: "nowrap"
      }
    },
    {
      title: "Pick Up Time",
      field: "pickup_time"
    },
    {
      title: "Truck",
      field: "truck_number"
    },
    {
      title: "Priority",
      field: "priority",
      cellStyle: {
        whiteSpace: "nowrap"
      }
    },
    {
      title: "Stop",
      field: "stop_number",
      defaultSort: "desc"
    },

    {
      title: "Customer",
      field: "customer",
      editable: "never"
    },
    {
      title: "Store",
      field: "store",
      editable: "never"
    }
  ];

  return (
    <div className="production_truck_body">
      <Box m={2} pt={2}>
        <MaterialTable
          title="Flat Pack Schedule Update Table"
          data={dataObj}
          key={dataObj.id}
          columns={headers}
          localization={{
            header: {
              actions: "Edit"
            }
          }}
          options={{
            pageSize: 20,
            addRowPosition: "first",
            rowStyle: {
              fontSize: 16
            },
            rowStyle: (rowData) => ({
              fontSize: 16,
              fontWeight: "bold"
            }),
            headerStyle: {
              fontSize: 18,
              fontWeight: "bold"
            },
            headerStyle: {
              color: "Black",
              whiteSpace: "nowrap"
            }
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...dataObj];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setDataObj([...dataUpdate]);
                  updateScheduleData(newData);
                  resolve();
                }, 1000);
              })
          }}
        />
      </Box>
    </div>
  );
};

export default TruckUpdate;
