import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import XLSX from "xlsx";
import { styled } from "@material-ui/styles";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  getCampaign,
  getForecast,
  getSubchannel,
  forecastUpdate,
  createUpload
} from "../../actions/forecast/forecastActions";
import moment from "moment";
import ForecastModal from "./forecastModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const EXTENTIONS = ["xlsx", "xls", "cvs"];
const initialState = "";

const Forecast = (update) => {
  const isAuthenticated = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [colDefs, setColDefs] = useState();
  const [data, setData] = useState();
  const [subchannel, setSubchannel] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [fileName, setFileName] = useState("");
  const Input = styled("input")({
    display: "none"
  });
  const [sucessValue, setSucessValue] = useState(false);
  const [sucess, setSucess] = useState("");
  const [forecast, setForecast] = useState([]);
  const [error, setError] = useState("");
  const [errorSubchannel, setErrorSubchannel] = useState("");
  const [blankFieldError, setBlankFieldError] = useState("");
  const [updateExisting, setUpdateExisting] = useState(update.updateExisting);
  const [loading, setLoading] = useState(false);

  console.log(data);
  useEffect(() => {
    dispatch(getSubchannel()).then((res) => {
      setSubchannel(res.payload);
    });

    dispatch(getCampaign()).then((res) => {
      setCampaign(res.payload);
    });
    dispatch(getForecast()).then((res) => {
      // setForecast(res.payload);
      checkIfForecastExist(res.payload);
    });
  }, []);

  async function checkIfForecastExist(value) {
    let subChannelError = "";
    let avaliableToUpdate = await value.find(
      (cast) => moment.utc(cast.as_of_date).format("YYYY-MM-DD") == "9999-01-01"
    );
    if (avaliableToUpdate) {
      return true;
    } else {
      subChannelError = (
        <b>Forecast not Avaliable to Update please create new.</b>
      );
      setErrorSubchannel(subChannelError);
    }
  }
  function validate(newData) {
    let blankFields = "";
    newData.filter((obj) => {
      if (
        obj.campaign_id === undefined ||
        obj.subchannel_id === undefined ||
        obj.gross_revenue === undefined ||
        obj.gm_rate === undefined ||
        obj.return_rate === undefined ||
        obj.marketing_rate === undefined ||
        obj.variable_rate === undefined ||
        obj.mattress_rate === undefined ||
        obj.mattress_aur === undefined
      ) {
        blankFields = (
          <div>
            <b>
              Fields can not be left blank when updating a forecast. Please
              enter 0 if there is no value.
            </b>
          </div>
        );
      }
    });
    let subChannelError = "";
    let campaignError = "";
    let notAssigned = newData.filter((nd) =>
      subchannel.find((sb) => nd.subchannel_id != sb.id)
    );
    let campaignNotAssigned = newData.filter(
      (nd) => !campaign.find((cp) => cp.name === nd.campaign_id)
    );

    if (campaignNotAssigned.length > 0) {
      campaignError = (
        <Typography>
          <b>Campaign Id Not Found</b>
        </Typography>
      );
    }
    newData.filter((s) => {
      if (notAssigned.length < 0) {
        subChannelError = (
          <Typography>
            <b>Subchannel Id not found!</b>
          </Typography>
        );
      }
    });

    if (campaignError) {
      setLoading(false);

      setError(campaignError);
      window.scrollTo(0, 0);
      return false;
    }
    if (blankFields) {
      setLoading(false);

      setBlankFieldError(blankFields);
      window.scrollTo(0, 0);
      return false;
    }
    if (subChannelError) {
      setLoading(false);

      setErrorSubchannel(subChannelError);
      window.scrollTo(0, 0);
      return false;
    }
    setError(initialState);
    setLoading(false);

    return true;
  }
  const createUploadStamp = async (newData) => {
    let data = {
      timestamp: moment().format("YYYY-MM-DD, h:mm:ss"),
      user_id: isAuthenticated
    };
    await dispatch(createUpload(data)).then((res) => {
      if (res.type === "FORECAST_LOADING_SUCCESS") {
        let newForecastObj = [];
        newData.filter((obj) => {
          let str = obj.subchannel_id.slice(2, 5);
          if (str) {
            str.slice(2, 5);
            subchannel.find((sub) => {
              if (sub.short_name == str) {
                obj["subchannel_id"] = sub.id;
              } else {
              }
            });
          }
          campaign.filter((camp) => {
            if (moment(camp.as_of_date).format("YYYY-MM-DD") == "9999-01-01") {
              if (camp.name == obj.campaign_id) {
                obj["campaign_id"] = camp.id;
                obj["upload_id"] = res.payload.id;
                let newObj = {
                  campaign_id: obj.campaign_id,
                  subchannel_id: obj.subchannel_id,
                  gross_revenue:
                    typeof obj.gross_revenue !== "undefined"
                      ? obj.gross_revenue
                      : 0,
                  gm_rate: typeof obj.gm_rate !== "undefined" ? obj.gm_rate : 0,
                  return_rate:
                    typeof obj.return_rate !== "undefined"
                      ? obj.return_rate
                      : 0,
                  marketing_rate:
                    typeof obj.marketing_rate !== "undefined"
                      ? obj.marketing_rate
                      : 0,
                  variable_rate:
                    typeof obj.variable_rate !== "undefined"
                      ? obj.variable_rate
                      : 0,
                  mattress_rate:
                    typeof obj.mattress_rate !== "undefined"
                      ? obj.mattress_rate
                      : 0,
                  mattress_aur:
                    typeof obj.mattress_aur !== "undefined"
                      ? obj.mattress_aur
                      : 0,
                  as_of_date: moment("9999-01-01").format("YYYY-MM-DD"),
                  upload_id: res.payload.id
                };
                newForecastObj.push(newObj);
              }
            }
          });
          dispatch(forecastUpdate(newForecastObj));
        });
      }
    });
  };

  function getId(newData) {
    let isValid = validate(newData);
    if (isValid) {
      const isSucessful = createUploadStamp(newData);
      if (isSucessful) {
        setSucessValue(true);
        setSucess([
          { title: "Sucess!!" },
          {
            body: "Forecast sucessfully updated!"
          }
        ]);
      }
    }
  }

  function resetErrors() {
    setErrorSubchannel(initialState);
    setError(initialState);
  }

  const getExtention = (file) => {
    const parts = file.name.split(".");
    const extention = parts[parts.length - 1];
    return EXTENTIONS.includes(extention);
  };
  const convertToJson = (headers, data) => {
    let rows = [];
    data.forEach((row) => {
      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });
      let newRow = {
        campaign_id: rowData.campaign_id,
        subchannel_id: rowData.subchannel_id,
        gross_revenue: rowData.gross_revenue,
        gm_rate: rowData.gm_rate,
        return_rate: rowData.return_rate,
        marketing_rate: rowData.marketing_rate,
        variable_rate: rowData.variable_rate,
        mattress_rate: rowData.mattress_rate,
        mattress_aur: rowData.mattress_aur
      };
      rows.push(newRow);
    });
    return rows;
  };
  const importExcel = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: "binary" });

      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];

      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const headers = fileData[0];
      const heads = headers.map((head) => ({ title: head, field: head }));
      setColDefs(heads);

      fileData.splice(0, 1);
      setData(convertToJson(headers, fileData));
    };
    if (file) {
      if (getExtention(file)) {
        reader.readAsBinaryString(file);
      } else {
        alert("Invalid file input, Select Excel, CSV file");
      }
    } else {
      setData([]);
      setColDefs([]);
    }
  };
  return (
    <>
      <ForecastModal open={sucessValue} value={sucessValue} sucess={sucess} />
      {loading == true ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: 9999,
            color: "#2894c3"
          }}
        >
          <Box
            sx={{
              top: 1,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              letterSpacing: 10
            }}
          >
            <Typography variant="h1" component="div">
              Loading...
            </Typography>
          </Box>
        </Box>
      ) : (
        ""
      )}

      {updateExisting ? (
        <Container maxWidth="xl">
          <Grid style={{ marginTop: "20px", marginLeft: "-20px" }}>
            {error || errorSubchannel || blankFieldError ? (
              <>
                <Typography style={{ fontSize: 18, color: "red" }}>
                  {error}
                </Typography>
                <Typography style={{ fontSize: 18, color: "red" }}>
                  {errorSubchannel}
                </Typography>
                <Typography style={{ fontSize: 18, color: "red" }}>
                  {blankFieldError}
                </Typography>
              </>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={12}>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="xlsx, xls, cvs"
                    id="contained-button-file"
                    type="file"
                    onChange={importExcel}
                    multiple
                  />
                  <Button
                    size="large"
                    variant="contained"
                    component="span"
                    style={{
                      marginLeft: "-25px",
                      marginTop: "20px"
                    }}
                    className="forecast_button"
                    onClick={() => resetErrors()}
                  >
                    Upload File &nbsp; <FontAwesomeIcon icon={faUpload} />
                  </Button>
                </label>
                &nbsp; {fileName}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {data ? (
              <div className="campaign-container">
                <Grid style={{ marginTop: "25px" }}>
                  <MaterialTable
                    title="Forecast Table"
                    data={data}
                    columns={colDefs}
                    options={{
                      addRowPosition: "first",
                      actionsColumnIndex: -1,
                      pageSize: 5,
                      pageSizeOptions: [10, 20, 30],
                      rowStyle: {
                        fontSize: 16
                      }
                    }}
                  />
                </Grid>
                <Grid container direction="column" alignItems="flex-end">
                  <Button
                    className="forecast_button"
                    variant="contained"
                    style={{ backgroundColor: "#003764", color: "white" }}
                    onClick={() => {
                      setData([]), setColDefs([]), getId(data);
                    }}
                  >
                    Update &nbsp; <FontAwesomeIcon icon={faSave} />
                  </Button>
                </Grid>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Container>
      ) : (
        ""
      )}
    </>
  );
};

export default Forecast;
