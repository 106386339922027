let backendHost;
const apiVersion = "v1";

const hostname = window && window.location && window.location.hostname;

// if(hostname === 'localhost:9000') {
//     backendHost = 'http://localhost:9001';
//} else if(hostname === 'http://didweshipit.brooklynbedding.com') {
//    backendHost = 'http://didweshipit.brooklynbedding.com';
// } else if(/^qa/.test(hostname)) {
//     backendHost = `https://api.${hostname}`;
// } else {
//     backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:9001';
// }

if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test") {
  backendHost = "http://localhost:8000";
} else if (process.env.NODE_ENV === "test") {
  backendHost = "https://devapi.diditship.com";
} else {
  backendHost = "https://api.diditship.com";
}

//backendHost = 'http://localhost:9001';
// backendHost = "https://devapi.diditship.com";

export const API_ROOT = `${backendHost}/api/${apiVersion}`;

export const SHIPSTATION_API_ROOT = "https://ssapi.shipstation.com";
export const SHIPSTATION = {
  API_ROOT: "https://ssapi.shipstation.com",
  BASIC:
    "Basic ZDBhZGYwMDQ0YjFmNDA5NzgxZjgyYzUzOWMzZDkxOGY6YjZhNzIyYzYwN2VkNDRiNzk4NzUxNDg5OTVjNDQyYTE="
};

export const PIECE_RATE_PASS = "pi3c3Rat3Pass";
export const USER_SCAN_PASS = "pi3c3Rat3Pass";

export const PRINT_NODE_USERNAME =
  "1SdpJqFjl-4S7HXAElNA-S0j0dg1jvtv6KmulVxbY_Q";
export const PRINT_NODE_PASSWORD = "bbPrintNode2022!";
