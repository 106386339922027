import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {addLayer, getCore, saveCore, updateCoreField} from '../../actions/coreActions';
import { getCoreTypes } from '../../actions/coreTypeActions';
import {getSizes} from "../../actions/sizeActions";
import {getParentCores} from "../../actions/parentCoreActions";
import {getCoreFirmnesses} from "../../actions/coreFirmnessActions";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Paper, Typography} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import '../../styles/layers.css';
import {getCoreRawMaterials} from "../../actions/coreRawMaterialActions";
import {getParts} from "../../actions/partActions";
import {layerAdded, updateLayerField} from "../../actions/coreLayerActions";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../confirmationModal/ConfirmationModal';
import { getCoresByParentId } from '../../actions/viewCoreActions';
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? "" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "" : "none",
    width: 'auto'
});

class CoreForm extends React.Component {

    constructor(props) {
      super(props)
      // Bind the this context to the handler function
      this.handleCoreChange = this.handleCoreChange.bind(this);
      this.handleLayerChange = this.handleLayerChange.bind(this);
      this.onDragEnd = this.onDragEnd.bind(this);
    }
    
    componentDidMount() {
      this.getTypeList()
      this.getSizeList()
      this.getRawMaterialList()
      this.getPartList()
      this.getCore()
      this.getParentCores()
      this.getFirmnesses()
      this.getAllCoresByParentId()
    }

    getFirmnesses() {
      const {dispatch} = this.props
      dispatch(getCoreFirmnesses())
    }

    getParentCores() {
      const {dispatch} = this.props
      dispatch(getParentCores())
        .then( res => {
        const parent = res.payload.find(parent => parent.parent_core == this.props.location.state.parentCoreName)
        this.setState({parentCoreId: parent})
        })
    }
    getTypeList() {
      const {
        dispatch,
      } = this.props
      dispatch(getCoreTypes())
    }

    getSizeList() {
      const {
        dispatch,
      } = this.props
      dispatch(getSizes())
    }

    getRawMaterialList(){
      const {
        dispatch,
      } = this.props
      dispatch(getCoreRawMaterials())
    }
    getPartList(){
      const {
        dispatch,
      } = this.props
      dispatch(getParts())
    }
    getAllCoresByParentId(){
      const {
        dispatch,
      } = this.props
      dispatch(getCoresByParentId(this.props.location.state.parentCoreId))
        .then(res => {
          this.setState( prevState => ({
              ...prevState,
            existing_cores: [...res.payload]
          }))
        });
    };
    getCore = e => {

      const {
        dispatch,
        isLoading,
      } = this.props

      const id = this.props.match.params.id
      if (id) {
        dispatch(getCore(id))
      }
    }

    handleCoreChange = e => {
      const {dispatch} = this.props
      dispatch(updateCoreField(e.target.name, e.target.value))
    }

    handleLayerChange = e => {
      const {dispatch} = this.props
      dispatch(updateLayerField(e.target.name, e.target.value))
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.saveSuccess) {
        this.setState({open: true})
      }
    }

    state = {
        showNewLayer: false,
        parentCoreName: this.props.location.state.parentCoreName || "",
        parentCoreId: "",
        partNum: '',
        rawMaterial: '',
        depth: '',
        layers: [],
        firmness: '',
        topper: false,
        convoluted: false,
        actualDepth: '',
        existing_cores: [],
        //modal state
        modalOpenStatus: false,
        success: "",
        redirect: "",
        // snackbar
        open_snackbar: false,
        type: '',
        message: "",
        // submit button status
        submitButtonStatus: false
    }
    //on drag end
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        
        const layers = reorder(
            this.state.layers,
            result.source.index,
            result.destination.index
            );
            
            this.setState({
              layers
            });
        }

    handleClose = ( event, reason) => {
      if (reason === 'clickaway') {
        return;
      };
      this.setState(prevState => ({...prevState, open_snackbar: false}));
    };   

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            id,
            coreTypeId,
            coreType,
            sizeId,
            sizeName,
            type,
            layers,
            coreTypes,
            sizes,
            firmnesses,
            parentCores
        } = this.props

        //create core_type here
        let fId = null
        const fObj = await firmnesses.find(item => item.firmness === this.state.firmness)
        if(fObj !== undefined){
          fId = fObj.id
        }

        let pcId = null
        const pcObj = await parentCores.find(item => item.parent_core.toLowerCase() === this.state.parentCoreName.toLowerCase())
        if(pcObj !== undefined){
          pcId = pcObj.id
        }
        //find core type but if does not exist then create -> core_type_id set to null in core obj
        let ctId = null
        const ctObj = await coreTypes.find(item => item.core_firmness_id === fId && item.parent_core_id === pcId)
        if (ctObj !== undefined) {
          ctId = ctObj.id
        } 
        let sId = sizeId
        const sObj = await sizes.find(item => item.size === sizeName)
        if (sObj !== undefined) {
          sId = sObj.id
        }

        let orderedLayers = await this.state.layers.slice().reverse()

        let topperInc = 0
        await orderedLayers.forEach((l,index) => {
                delete l.pNum;
                delete l.material;
                delete l.pDesc;
            if(l.topper == false){
                l.core_layer = index
            } else {
                if(topperInc == 0){
                    l.core_layer = 99
                    topperInc += 1
                } else if(topperInc == 1){
                    l.core_layer = 999
                    topperInc += 1
                } else if(topperInc == 2){
                    l.core_layer = 9999
                    topperInc += 1
                } else if(topperInc == 3){
                    l.core_layer = 99999
                    topperInc += 1
                }
            }
            delete l.topper;
        })

        // await console.log(orderedLayers)
        let core = {
          id: null,
          core_type_id: ctId != null ? ctId : null,
          size_id: sId == -1 ? null : sId,
          layers: orderedLayers,
        }

        let core_type_obj = null
        if(core.core_type_id == null){
          core_type_obj = {
            id: null,
            core_type: this.state.parentCoreName + ' ' + this.state.firmness.toUpperCase(),
            core_firmness_id: fId,
            parent_core_id: pcId
          }
        }
        // core layers must be filled out in order to submit - then check if core_type needed to be add to be created
        const validateCore = this.state.existing_cores.find(core => core.size_id === sId && core.type.core_firmness_id === fId);
          if(validateCore) {
            await this.setState({ 
              open_snackbar: true,
              type: 'error',
              message: "THIS CORE SIZE/FIRMNESS ALREADY EXISTS!"
            });
          } else {
            if(core.layers.length  > 0) {
              if(core_type_obj != null){
                this.setState({ submitButtonStatus: true});
                core.core_type_obj = core_type_obj
                await dispatch(saveCore(core))
                  .then(res => {
                      if(res.type == "CORE_SAVING_SUCCESS") {
                        this.setState({ 
                          modalOpenStatus: true,
                          success: [
                              { title: "Success!!" },
                              {
                                body: `Core successfully created for ${this.state.parentCoreName}! You will now be redirected.`
                              }
                          ],
                          redirect: `/mat-core/parentCore/${pcId}`
                        });
                      }
                  });
              } else {
                this.setState({ submitButtonStatus: true});
                await dispatch(saveCore(core))
                .then(res => {
                    if(res.type == "CORE_SAVING_SUCCESS") {
                      this.setState({ 
                        modalOpenStatus: true,
                        success: [
                            { title: "Success!!" },
                            {
                              body: `Core successfully created for ${this.state.parentCoreName}! You will now be redirected.`
                            }
                        ],
                        redirect: `/mat-core/parentCore/${pcId}`
                      });
                    }
                });
              }
            } else {
              await this.setState({ 
                open_snackbar: true,
                type: 'error',
                message: "YOU CAN NOT SUBMIT WITHOUT ADDING LAYERS!"
              });
            }
          }
    }; //handle submit 


    handleRemoveLayer = async (index) => {
        let newLayerArr = this.state.layers
        await newLayerArr.splice(index, 1)
        await this.setState({layers: newLayerArr}, )
    }

    handleShowHideNewLayer = e => {
        e.preventDefault()
        let show = false
        if(!this.state.showNewLayer){
            show = true
        }
        this.setState({showNewLayer: show})
    }
    handleAddNewLayer = async e => {
        e.preventDefault()
        const {
            dispatch,
            rawMaterials,
            parts,
        } = this.props

        let rmId = null
        let material = null
        const rmObj = rawMaterials.find(item => item.raw_material === this.state.rawMaterial)

        if(rmObj !== undefined){
            rmId = rmObj.id
            material = rmObj
        }
        let pId = null
        let pNum = null
        let pDesc = null
        const pObj = parts.find(item => item.num === this.state.partNum)

        if(pObj !== undefined) {
            pId = pObj.id
            pNum = pObj.num
            pDesc = pObj.internal_desc
        }

        const layer = {
            id: null,
            core_id: null,
            part_id: pId,
            pNum: pNum,
            //core layer will just be incremented as the layers are created
            //we will adjust true core layer before user submits after they re-arrange the core in whatever order they want
            core_layer: this.state.layers.length === 0 ? 0 : this.state.layers.length,
            layer_depth_in: parseFloat(parseFloat(this.state.depth).toFixed(2)),
            raw_material_id: rmId,
            material: material,
            topper: this.state.topper,
            pDesc: pDesc,
            convoluted: this.state.convoluted == true ? 1 : 0,
        }
        if(layer.convoluted == 1){
            layer.convoluted_layer = {
                core_layer_id: null,
                actual_depth_in: this.state.actualDepth
            }
        }


         if(layer.part_id && layer.raw_material_id && layer.layer_depth_in){
             await this.setState({layers: [layer, ...this.state.layers]})
             await this.setState({partNum: ''})
             await this.setState({rawMaterial: ''})
             await this.setState({depth: ''})
             await this.setState({actualDepth: ''})
             await this.setState({topper: false})
             await this.setState({convoluted: false})

         }

        this.setState({showNewLayer: false})
    }
    
    render() {

        const {
            isLoading,
            isError,
            isNew,
            saveSuccess,
            id,
            coreTypeId,
            coreType,
            sizeId,
            sizeName,
            size,
            type,
            layers,
            coreTypes,
            parentCores,
            parentCoreName,
            sizes,
            part,
            rawMaterial,
            parts,
            coreLayer,
            layerDepthIn,
            rawMaterials,
            firmnesses,
        } = this.props
        //filtering the fishbowl parts by categories
        let filterCategories = ['Bun Foam', 'Coil Unit', 'RM Topper', 'Latex', 'Pad', 'Core', 'Roll Foam', 'Non Woven']
        let filteredParts = parts.filter(part => filterCategories.indexOf(part.category) > -1)
        return (
            <div className="baseContainer-coreForm">
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={this.state.open_snackbar}
                className="success"
                onClose={this.handleClose}
                autoHideDuration={5000}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
              >
                <Alert severity={this.state.type} >{this.state.message}</Alert>
              </Snackbar>
                <ConfirmationModal 
                modalOpenStatus={this.state.modalOpenStatus}
                redirect={this.state.redirect}
                success={this.state.success}
                />
                <Typography variant='h3' className='addNewCoreHeader'>Add New Core</Typography>
                <h3 style={{color: 'red'}}>
                    MOVE AROUND LAYERS SO THAT THE TOP CORE LAYER IS AT THE TOP
                    <br/>
                    AND THE BOTTOM CORE LAYER IS AT THE BOTTOM
                </h3>
                <div>
                    <FormControl margin={"normal"} variant="outlined" style={{minWidth: 120, marginRight: "15px", width: 200, marginLeft: 20}}>
                        <TextField
                            label={"Parent Core"}
                            name={"parentCore"}
                            placeholder={"ParentCore"}
                            value={this.state.parentCoreName}
                            key={this.state.parentCoreName}
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl margin={"normal"} variant="outlined" style={{minWidth: 120, marginRight: "15px"}}>
                        <InputLabel
                            ref={ref => {
                                this.InputLabelRef = ref;
                            }}
                            htmlFor="sizeName-placeholder"
                        >
                            Size
                        </InputLabel>
                        <Select
                            label={"Size"}
                            name={"sizeName"}
                            placeholder={"Size"}
                            value={sizeName}
                            key={sizeId}
                            input={<OutlinedInput labelWidth={120} name="sizeName" id="sizeName-placeholder"/>}
                            onChange={this.handleCoreChange}
                        >
                            {sizes.map((option) => <MenuItem key={option.id}
                                                             value={option.size}>{option.size}</MenuItem>)}
                        </Select>

                    </FormControl>

                    <FormControl margin={"normal"} variant="outlined" style={{minWidth: 120, marginRight: "15px", width: 200}}>
                        <InputLabel
                            ref={ref => {
                                this.InputLabelRef = ref;
                            }}
                            htmlFor="sizeName-placeholder"
                        >
                            Firmness
                        </InputLabel>
                        <Select
                            label={"Size"}
                            name={"sizeName"}
                            placeholder={"Size"}
                            value={this.state.firmness}
                            key={this.state.firmness}
                            input={<OutlinedInput labelWidth={120} name="sizeName" id="sizeName-placeholder"/>}
                            onChange={(e) => this.setState({firmness: e.target.value})}
                        >
                            {firmnesses.map((option) => <MenuItem key={option.id}
                                                             value={option.firmness}>{option.firmness}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {!this.state.showNewLayer &&
                    <FormControl margin={"normal"} style={{marginLeft: "20px", display: 'block', marginTop: 20, marginBottom: 30}}>
                        <Button margin="normal" variant="contained"  onClick={this.handleShowHideNewLayer}>Add New Layer</Button>
                    </FormControl>}
                    {this.state.showNewLayer &&
                    <div className={'newLayerForm'} style={{marginLeft: 20, marginTop: 20}}>
                        <div style={{display: 'block'}}><button onClick={this.handleShowHideNewLayer}><FontAwesomeIcon icon={faTimes} style={{ fontSize: '1.75em' }} /></button></div>
                        <div>
                            <FormControl margin={"normal"} variant="outlined" style={{minWidth: 200, marginRight: "15px"}}>
                                <InputLabel
                                    ref={ref => {
                                        this.InputLabelRef = ref;
                                    }}
                                    htmlFor="rawMaterial-placeholder"
                                >
                                    Raw Material Type
                                </InputLabel>
                                <Select
                                    label={"Raw Material Type"}
                                    name={"rawMaterial"}
                                    placeholder={"Raw Materials"}
                                    value={this.state.rawMaterial}
                                    //styles={styles}
                                    input={<Input name="rawMaterial" id="rawMaterial-placeholder"/>}
                                    onChange={(e) => this.setState({rawMaterial: e.target.value})}
                                >
                                    {rawMaterials.map((option) => <MenuItem key={option.id}
                                                                            value={option.raw_material}>{option.raw_material}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControl margin={"normal"} variant="outlined" style={{minWidth: 160, marginRight: "5px", marginTop: 6}}>
                                <Autocomplete
                                    id="autocomplete-bottom-panel"
                                    options={filteredParts}
                                    getOptionLabel={(options) => options.num + ' - ' + options.internal_desc}
                                    onSelect={(e) => this.setState({partNum: e.target.value.split(' -')[0]})}
                                    style={{width: 250}}
                                    renderInput={(params) => <TextField {...params}
                                                                        label="Part Number"
                                                                        className={'sku-auto-complete'}/>}
                                />
                            </FormControl>
                            <TextField
                                label="Depth (in inches)"
                                name="layerDepthIn"
                                margin="normal"
                                variant="outlined"
                                className="name-field"
                                value={this.state.depth || ''}
                                style={{marginLeft: 0}}
                                onChange={(e) => this.setState({depth: e.target.value})}
                            />
                            <br/>
                            <FormControlLabel
                                className={'sku-switch'}
                                label="Is this layer Convoluted?"
                                style={{marginLeft: 0}}
                                control={
                                    <Switch
                                        checked={this.state.convoluted}
                                        onChange={() => {
                                            this.setState({convoluted: !this.state.convoluted})
                                        }}
                                    />
                                }
                            />
                            <FormControlLabel
                                className={'sku-switch'}
                                label="Is this layer a Topper?"
                                style={{marginLeft: 0}}
                                control={
                                    <Switch
                                        checked={this.state.topper}
                                        onChange={() => {
                                            this.setState({topper: !this.state.topper})
                                        }}
                                    />
                                }
                            />
                            {this.state.convoluted == true ?
                                <div style={{marginTop: 20}}>
                                <span style={{fontSize: 20}}>
                                    The Actual Depth is half (.5) of the Starting Material Depth.
                                    <br/>
                                    Please make sure the Depth of the this core layer is the Nominal Depth.
                                </span>
                                    <br/>
                                <TextField
                                    label="Actual Depth (in inches)"
                                    name="actualDepthIn"
                                    margin="normal"
                                    variant="outlined"
                                    className="name-field"
                                    value={this.state.actualDepth || ''}
                                    style={{marginLeft: 0}}
                                    onChange={(e) => this.setState({actualDepth: e.target.value})}
                                />
                                </div>
                                : ''
                            }
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                style={{marginTop: 60, marginBottom: 40, background: 'rgb(30, 66, 113)', color: 'white'}}
                                onClick={this.handleAddNewLayer}
                            >
                                Add New Layer
                            </Button>
                        </div>
                    </div>
                    }
                </div>
                <div>
                    {this.state.layers.length >= 1 ?
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {this.state.layers.map((l,index) => {
                                            const paperStyle = {
                                                // background: 'linear-gradient(45deg, ' + l.material.color + ' 30%, ' + '#CFB6AE' + ' 90%)',
                                                background: l.material ? l.material.color : '',
                                                border: 0,
                                                borderRadius: 3,
                                                color: 'black',
                                                fontWeight: 'bold',
                                                paddingTop: l.layer_depth_in + 15 + 'px',
                                                paddingLeft: '30px',
                                                paddingRight: '30px',
                                                paddingBottom: l.layer_depth_in + 15 + 'px',
                                                width: '700px',
                                            }
                                            return (
                                                <Draggable key={l.core_layer} draggableId={l.core_layer.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            id={l.core_layer}
                                                        >
                                                            <div style={{padding: '2px', marginLeft: 20, marginTop: 20, width: 600}}>
                                                                <Paper style={paperStyle}>
                                                                    <FontAwesomeIcon icon={faTimes} style={{ fontSize: '1.75em', cursor: 'pointer', display: 'inline'}} onClick={() => this.handleRemoveLayer(index)}/>
                                                                    <span style={{marginLeft: 20, fontSize: 20}}>{l.material ? l.material.raw_material : ''}</span> <span style={{fontSize: 20}}>- {l.pNum ? l.pNum : ''} - {l.pDesc ? l.pDesc : ''}</span>
                                                                    <span style={{marginLeft: 15, fontSize: 15}}>{l.topper == true ? 'topper' : ''}</span> <span style={{marginLeft: 10, fontSize: 15}}>{l.convoluted == 1 ? 'convoluted' : ''}</span>
                                                                    <span className={"side-text"} style={{fontSize: 20}}>{l.layer_depth_in ? l.layer_depth_in : ''}"</span>
                                                                </Paper>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        : ''
                    }
                    {!this.state.showNewLayer &&
                    <div style={{marginTop: 150, marginLeft: 20}}>
                        <h3 style={{color: 'red'}}>
                            MAKE SURE LAYERS ARE IN CORRECT ORDER BEFORE SUBMITTING
                        </h3>
                        <Button
                            variant="contained"
                            style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                            onClick={this.handleSubmit}
                            disabled={this.state.submitButtonStatus}
                        >
                            {this.state.submitButtonStatus ? 'Submitting' : 'Submit'}
                        </Button>
                    </div>}
                </div>
            </div>
        )
    }
}

CoreForm.defaultProps = {

}

CoreForm.propTypes = {
    isLoading: T.bool,
    isNew: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    coreTypeId: T.number,
    coreType: T.string,
    sizeId: T.number,
    sizeName: T.string,
    size: T.object,
    type: T.object,
    layers: T.array,
    coreTypes: T.array,
    sizes: T.array,
    partId: T.number,
    coreLayer: T.number,
    layerDepthIn: T.number,
    rawMaterial: T.string,
    part: T.string,
    rawMaterialId: T.number,
    material: T.object,
    rawMaterials: T.array,
    parts: T.array,
    parentCores: T.array,
    firmnesses: T.array
}

function mapStateToProps(state) {
    const { coreReducer, coreTypeReducer, sizeReducer, coreLayerReducer, coreFirmnessReducer, coreRawMaterialReducer, partReducer, parentCoreReducer } = state
    return {
        isError: coreReducer.isError,
        isNew: coreReducer.isNew,
        saveSuccess: coreReducer.saveSuccess,
        id: coreReducer.id,
        coreTypeId: coreReducer.coreTypeId,
        coreType: coreReducer.coreType,
        sizeId: coreReducer.sizeId,
        sizeName: coreReducer.sizeName,
        size: coreReducer.size,
        type: coreReducer.type,
        layers: coreReducer.layers,
        coreTypes: coreTypeReducer.coreTypes,
        sizes: sizeReducer.sizes,
        partId: coreLayerReducer.partId,
        coreLayer: coreLayerReducer.coreLayer,
        layerDepthIn: coreLayerReducer.layerDepthIn,
        rawMaterialId: coreLayerReducer.rawMaterialId,
        rawMaterial: coreLayerReducer.rawMaterial,
        part: coreLayerReducer.part,
        material: coreLayerReducer.material,
        rawMaterials: coreRawMaterialReducer.coreRawMaterials,
        parts: partReducer.parts,
        parentCores: parentCoreReducer.parentCores,
        firmnesses: coreFirmnessReducer.firmnesses,
    }
}

export default withRouter(connect(mapStateToProps)(CoreForm))