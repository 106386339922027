import { Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import "./style.css";
import { useHistory } from "react-router-dom";

const ProductionScanHome = () => {
  const history = useHistory();

  const production = (value) => {
    history.push(value);
  };

  return (
    <Grid container
      style={{ width: "auto", height: "50vh", marginTop: '100px' }}
      
    >
      <Grid item xs={6} className="production_scan_grid_1">
        <Button
          style={{ height: "100%", width: "100%" }}
          onClick={() => production("/production")}
        >
          <Typography className="production_typography">Production</Typography>
        </Button>
      </Grid>
      {/* <Grid item xs={6} className="production_scan_grid_1" >
        <Button
          style={{ height: "100%", width: "100%" }}
          onClick={() => production("/inventory")}
        >
          <Typography className="production_typography">Inventory</Typography>
        </Button>
      </Grid> */}
      {/* <Grid item xs={6} className="production_scan_grid_1" >
        <Button
          style={{ height: "100%", width: "100%" }}
          onClick={() => production("/product-tracking")}
        >
          <Typography className="production_typography">
            Production Tracking
          </Typography>
        </Button>
      </Grid> */}
      <Grid item xs={6} className="production_scan_grid_1" >
        <Button
          style={{ height: "100%", width: "100%" }}
          onClick={() => production("/connect")}
        >
          <Typography className="production_typography">Connect</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProductionScanHome;
