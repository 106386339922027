import * as types from '../constants/lawTagViewerTypes'
import { RSAA } from 'redux-api-middleware'
import {API_ROOT} from "../utils/api";
import normalize from 'jsonapi-normalizer'

export function updateField() {
    return {
        type: types.LAW_TAG_UPDATE_FIELD,
        name,
        value
    }
}


export function categorySelected(category, categories){

    let categoryId = -1
    let selectedCategory = {id: -1, category: ''}
    for(let i=0;i<categories.length; i++) {
        if (categories[i].category == category) {
            selectedCategory = {
                id: categories[i].id,
                category: categories[i].category
            }
            categoryId = categories[i].id
            break
        }
    }
    return {
        type: types.LAW_TAG_CATEGORY_SELECTED,
        selectedCategoryId: categoryId,
        selectedCategory: selectedCategory
    }
}

export function brandSelected(brand, brands, families, skus, category){

    let brandId = -1
    let selectedBrand = {}
    for(let i=0;i<brands.length; i++) {
        if (brands[i].brand === brand) {
            brandId = brands[i].id
            selectedBrand = brands[i]
            break
        }
    }
    if(brandId > -1) {
        let filteredSkus = [];
        for (let i = 0; i < skus.length; i++) {
            if (skus[i].brandId === brandId) {
                filteredSkus.push(
                    {
                        id: skus[i].id,
                        brandId: skus[i].brandId,
                        familyId: skus[i].familyId,
                        sku: skus[i].sku
                    })
            }
        }

        return {
            type: types.LAW_TAG_BRAND_SELECTED,
            filteredSkus: filteredSkus,
            selectedBrand: selectedBrand,
            selectedSkus: [],
            selectedSku: '',
        }
    }
    else{
        return {
            type: types.LAW_TAG_BRAND_SELECTED,
            filteredSkus: skus,
            selectedBrand: selectedBrand,
            selectedSkus: [],
            selectedSku: '',
        }
    }
}
export function familySelected(family, families, skus, type){

    let familyId = -1
    let selectedFamily = {}
    for(let i=0;i<families.length; i++) {
        if (families[i].family === family) {
            familyId = families[i].id
            selectedFamily = families[i]
            break
        }
    }
    if(familyId > -1) {
        let filteredSkus = [];
        for (let i = 0; i < skus.length; i++) {
            if (skus[i].familyId === familyId) {
                filteredSkus.push(
                    {
                        id: skus[i].id,
                        brandId: skus[i].brandId,
                        familyId: skus[i].familyId,
                        sku: skus[i].sku
                    })
            }
        }

        return {
            type: types.LAW_TAG_FAMILY_SELECTED,
            filteredSkus: filteredSkus,
            selectedSkus: [],
            selectedSku: '',
            selectedFamily: selectedFamily,
        }
    }
    else{
        return {
            type: types.LAW_TAG_FAMILY_SELECTED,
            filteredSkus: skus,
            selectedSkus: [],
            selectedSku: '',
            selectedFamily: selectedFamily,
        }
    }
}
export function skuSelected(sku, skus, selectedSkus){

    let selectedSku = {}
    let hasSku = false
    if(sku != "" && !sku.startsWith('<')) {
        skus.map(s => {
            if (s !== undefined && s.sku === sku) {
                selectedSku = s
            }
            else{
                return s
            }
        })
        const updatedSelections = selectedSkus.map(s => {
            if(s.sku === sku){
                hasSku = true
            }
            return s
        })
        if(!hasSku) {
            updatedSelections.push(selectedSku)
        }
        return {
            type: types.LAW_TAG_SKU_SELECTED,
            filteredSkus: skus,
            selectedSkus: updatedSelections
        }
    }
    else{
        return {
            type: types.LAW_TAG_SKU_SELECTED,
            filteredSkus: skus,
            selectedSkus: selectedSkus
        }
    }

}

export function skuRemove(sku, skus, selectedSkus){

    const updatedSelections = selectedSkus.filter(s => s.sku != sku);
    return {
        type: types.LAW_TAG_SKU_REMOVED,
        filteredSkus: skus,
        selectedSkus: updatedSelections
    }
}

export function resetForm(categories, brands, families, skus){
    return{
        type: types.LAW_TAG_RESET_FORM_SUCCESS,
        payload: {
            skus: skus,
            categories: categories,
            brands: brands,
            families: families,
        }
    }
}

export function getCategories() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/categories`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.LAW_TAG_CATEGORIES_LOADING_REQUEST,
                types.LAW_TAG_CATEGORIES_LOADING_SUCCESS,
                types.LAW_TAG_CATEGORIES_LOADING_ERROR,
            ],
        },
    }
}
export function getBrands() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/brands`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.LAW_TAG_BRANDS_LOADING_ERROR,
                types.LAW_TAG_BRANDS_LOADING_SUCCESS,
                types.LAW_TAG_BRANDS_LOADING_ERROR,
            ],
        },
    }
}
export function getFamilies() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/families`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.LAW_TAG_FAMILIES_LOADING_REQUEST,
                types.LAW_TAG_FAMILIES_LOADING_SUCCESS,
                types.LAW_TAG_FAMILIES_LOADING_ERROR,
            ],
        },
    }
}
export function getSkus(active, type) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/skus/${active ? 1 : 0}/${type}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.LAW_TAG_SKUS_LOADING_REQUEST,
                types.LAW_TAG_SKUS_LOADING_SUCCESS,
                types.LAW_TAG_SKUS_LOADING_ERROR,
            ],
        },
    }
}

export function getPDF(skus, type) {
    const skuArray = skus.map(s => {
        return s.sku
    })
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/pdf/${type}`, // TODO: make base URL constant
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(skuArray),
            options: {
                mode: 'cors',
            },
            types: [
                types.LAW_TAG_PDF_LOADING_REQUEST,
                types.LAW_TAG_PDF_LOADING_SUCCESS,
                types.LAW_TAG_PDF_LOADING_ERROR,
            ],
        },
    }
}

export function getZPL(skus, type) {
    const skuArray = skus.map(s => {
        return s.sku
    })
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/zpl/${type}`, // TODO: make base URL constant
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(skuArray),
            options: {
                mode: 'cors',
            },
            types: [
                types.LAW_TAG_ZPL_LOADING_REQUEST,
                types.LAW_TAG_ZPL_LOADING_SUCCESS,
                types.LAW_TAG_ZPL_LOADING_ERROR,
            ],
        },
    }
}

