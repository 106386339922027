import * as types from '../constants/coreFirmnessTypes';
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function getCoreFirmnesses() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/firmnesses`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FIRMNESSES_LOADING_REQUEST,
                types.FIRMNESSES_LOADING_SUCCESS,
                types.FIRMNESSES_LOADING_ERROR,
            ],
        },
    }
}

export function getCoreFirmness(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/firmness/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FIRMNESS_LOADING_REQUEST,
                types.FIRMNESS_LOADING_SUCCESS,
                types.FIRMNESS_LOADING_ERROR,
            ],
        },
    }
}


