
export const FAMILY_UPDATE_FIELD = 'FAMILY_UPDATE_FIELD'
export const FAMILY_UPDATE_QUICKSHIP = 'FAMILY_UPDATE_QUICKSHIP'

export const FAMILY_LOADING_REQUEST = 'FAMILY_LOADING_REQUEST'
export const FAMILY_LOADING_SUCCESS = 'FAMILY_LOADING_SUCCESS'
export const FAMILY_LOADING_ERROR = 'FAMILY_LOADING_ERROR'

export const FAMILIES_LOADING_REQUEST = 'FAMILIES_LOADING_REQUEST'
export const FAMILIES_LOADING_SUCCESS = 'FAMILIES_LOADING_SUCCESS'
export const FAMILIES_LOADING_ERROR = 'FAMILIES_LOADING_ERROR'

export const FAMILY_IMPORT_LOADING_REQUEST = 'FAMILY_IMPORT_LOADING_REQUEST'
export const FAMILY_IMPORT_LOADING_SUCCESS = 'FAMILY_IMPORT_LOADING_SUCCESS'
export const FAMILY_IMPORT_LOADING_ERROR = 'FAMILY_IMPORT_LOADING_ERROR'

export const FAMILY_SAVING_REQUEST = 'FAMILY_SAVING_REQUEST'
export const FAMILY_SAVING_SUCCESS = 'FAMILY_SAVING_SUCCESS'
export const FAMILY_SAVING_ERROR = 'FAMILY_SAVING_ERROR'

export const FAMILIES_SAVING_REQUEST = 'FAMILIES_SAVING_REQUEST'
export const FAMILIES_SAVING_SUCCESS = 'FAMILIES_SAVING_SUCCESS'
export const FAMILIES_SAVING_ERROR = 'FAMILIES_SAVING_ERROR'

export const FAMILY_FABRIC_ADD_LAYER = 'FAMILY_FABRIC_ADD_LAYER'
export const FAMILY_FABRIC_LAYER_ADDED = 'FAMILY_FABRIC_LAYER_ADDED'


