import React from "react";
import {
  Box,
  Card,
  Modal,
  Typography,
  Backdrop,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  Grid
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./failedScansModal.css";
import moment from "moment";
import {
  faCheckCircle,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FailedScansModal = (props) => {
  const {
    modalOpenStatus,
    scansArray,
    redirect,
    setDisableField,
    setModalState,
    successfulScansArray,
    invalidScansArray,

    setPassedValidationArray,
    setScans,
    setInvalidScansArray,
    setFailedValidationArray
  } = props;

  const history = useHistory();
  let firstName = window.localStorage.getItem("firstName");
  let lastName = window.localStorage.getItem("lastName");
  const handleClose = (e) => {
    if (redirect) {
      setModalState({
        modalOpenStatus: false
      });
      setDisableField(true);
      // history.push(`${redirect}`);
      setScans([]);
      setPassedValidationArray([]);
      setInvalidScansArray([]);
      setPassedValidationArray([]);
      setFailedValidationArray([]);
      setTimeout(() => {
        const inputField = document.getElementById("SearchbyScanning");
        inputField.value = "";
        inputField.focus();
        setDisableField(false);
      }, 300);
    }
  };
  if (document.getElementById("failed-modal-scans")) {
    document.addEventListener("keydown", (e) => {
      let keys = e.key;
      if (e.key == "e") {
        handleClose();
      }
    });
  }

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    boxShadow: 23,
    p: 4
  };

  return (
    <>
      {scansArray ? (
        <>
          <Modal
            id="failed-modal-scans"
            open={modalOpenStatus}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
            disableScrollLock
          >
            <Box style={style}>
              <Card
                style={{
                  overflow: "auto",
                  height: "auto",
                  padding: "25px",
                  borderRadius: "10px"
                }}
              >
                <Box className="scans-modal-header">
                  <Grid className="scans-modal-results-grid">
                    <div className="scans-modal-total-successful-scans">
                      <Typography className="successful-scans">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="faCheckCircle"
                        />
                        Successful Scans: {successfulScansArray.length}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid className="scans-modal-title-grid">
                    <Typography className="scans-modal-title" variant="h5">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="faExclamationTriangle"
                      />
                      Error Scans:{" "}
                      {scansArray.length + invalidScansArray.length}
                    </Typography>
                  </Grid>
                </Box>
                <Box className="scans-modal-body">
                  {scansArray.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Serial Number</TableCell>
                            <TableCell align="right">SKU</TableCell>
                            <TableCell align="right">Error</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="right">Scanned by</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scansArray.map((row) => (
                            <TableRow
                              key={row.serial_number}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0
                                }
                              }}
                            >
                              <TableCell scope="row">
                                {row.serial_number}
                              </TableCell>
                              <TableCell align="right">
                                {row.scans[0].rfid_scan}
                              </TableCell>
                              <TableCell align="right">
                                {row.scans[0].user.first_name
                                  ? "Duplicate scan"
                                  : ""}
                              </TableCell>
                              <TableCell align="right">
                                {moment(row.scans[0].date).format(
                                  "MM/DD/YYYY hh:mm a"
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {row.scans[0].user.first_name +
                                  " " +
                                  row.scans[0].user.last_name}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    ""
                  )}
                  {invalidScansArray.length > 0 ? (
                    <>
                      <Grid className="scans-modal-invalid-scans-grid"></Grid>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Serial Number</TableCell>
                              <TableCell align="right">Error</TableCell>
                              <TableCell align="right">Date</TableCell>
                              <TableCell align="right">Scanned by</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invalidScansArray.map((sn) => (
                              <TableRow
                                key={sn}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell scope="row">{sn}</TableCell>
                                <TableCell align="right">
                                  Invalid Serial Number
                                </TableCell>
                                <TableCell align="right">
                                  {moment().format("MM/DD/YYYY hh:mm a")}
                                </TableCell>
                                <TableCell align="right">
                                  {firstName + " " + lastName}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Card>
            </Box>
          </Modal>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default FailedScansModal;
