import React from 'react';
import SkuItemForm from './forms/SkuItem/SkuItemForm';

const SkuItemPage = () => {
  return (
    <div>
      <SkuItemForm />
    </div>
  )
}

export default SkuItemPage