
import {
    CORE_TYPE_UPDATE_FIELD,
    CORE_TYPE_LOADING_REQUEST,
    CORE_TYPE_LOADING_SUCCESS,
    CORE_TYPE_LOADING_ERROR,
    CORE_TYPE_SAVING_REQUEST,
    CORE_TYPE_SAVING_SUCCESS,
    CORE_TYPE_SAVING_ERROR,
    CORE_TYPES_LOADING_ERROR,
    CORE_TYPES_LOADING_REQUEST,
    CORE_TYPES_LOADING_SUCCESS,
    CORE_TYPES_LIST_LOADING_REQUEST,
    CORE_TYPES_LIST_LOADING_SUCCESS,
    CORE_TYPES_LIST_LOADING_ERROR,
    CORE_TYPES_SAVING_ERROR,
    CORE_TYPES_SAVING_REQUEST,
    CORE_TYPES_SAVING_SUCCESS
} from '../constants/coreTypeTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"


const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    coreTypes: [],
    columns: [],
    rows: [],
    id: -1,
    coreType: '',
}

export default function coreTypeReducer(state = initialState, action) {
    switch (action.type) {
        // case CORE_TYPE_UPDATE_FIELD:
        //     return {
        //         ...state,
        //         isLoading: false,
        //         [action.name]: action.value,
        //     }
        case CORE_TYPES_LOADING_REQUEST:
        // case CORE_TYPES_SAVING_REQUEST:
        // case COLUMNS_LOADING_REQUEST:
        // case CORE_TYPE_LOADING_REQUEST:
        case CORE_TYPE_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        // case CORE_TYPE_LOADING_SUCCESS:
        //     return {
        //         ...state,
        //         isLoading: false,
        //         isError: false,
        //         id: action.payload.id,
        //         coreType: action.payload.core_type,
        //     }
        case CORE_TYPE_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: -1,
                coreType: '',
            }
        case CORE_TYPES_LOADING_ERROR:
        // case CORE_TYPES_SAVING_ERROR:
        // case COLUMNS_LOADING_ERROR:
        // case CORE_TYPE_LOADING_ERROR:
        case CORE_TYPE_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case CORE_TYPES_LOADING_SUCCESS:
             return {
                ...state,
                isLoading: false,
                isError: false,
                coreTypes: action.payload,
                
            }
        case CORE_TYPES_LIST_LOADING_SUCCESS:
            
            return {
                ...state,
                isLoading: false,
                isError: false,
            }
        case CORE_TYPES_LIST_LOADING_ERROR:
            console.log(action.payload)
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        // case CORE_TYPES_SAVING_SUCCESS:
        //     return {
        //         ...state,
        //         isLoading: false,
        //         isError: false,
        //         isSaved: true,
        //         coreTypes: action.payload,
        //     }
        // case COLUMNS_LOADING_SUCCESS:
        //     const columns = parseAllToJson(action.payload, 'options')
        //     return {
        //         ...state,
        //         isLoading: false,
        //         isError: false,
        //         columns: columns,
        //     }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}