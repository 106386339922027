
export const SCHEDULE_UPDATE_FIELD = 'SCHEDULE_UPDATE_FIELD'

export const SCHEDULE_GET_SKUS_REQUEST = 'SCHEDULE_GET_SKUS_REQUEST'
export const SCHEDULE_GET_SKUS_SUCCESS = 'SCHEDULE_GET_SKUS_SUCCESS'
export const SCHEDULE_GET_SKUS_ERROR = 'SCHEDULE_GET_SKUS_ERROR'

export const SCHEDULE_LOADING_REQUEST = 'SCHEDULE_LOADING_REQUEST'
export const SCHEDULE_LOADING_SUCCESS = 'SCHEDULE_LOADING_SUCCESS'
export const SCHEDULE_LOADING_ERROR = 'SCHEDULE_LOADING_ERROR'

export const SCHEDULE_IMPORT_LOADING_REQUEST = 'SCHEDULE_IMPORT_LOADING_REQUEST'
export const SCHEDULE_IMPORT_LOADING_SUCCESS = 'SCHEDULE_IMPORT_LOADING_SUCCESS'
export const SCHEDULE_IMPORT_LOADING_ERROR = 'SCHEDULE_IMPORT_LOADING_ERROR'

export const SCHEDULE_SAVING_REQUEST = 'SCHEDULE_SAVING_REQUEST'
export const SCHEDULE_SAVING_SUCCESS = 'SCHEDULE_SAVING_SUCCESS'
export const SCHEDULE_SAVING_ERROR = 'SCHEDULE_SAVING_ERROR'


export const SCHEDULE_DELETING_REQUEST = 'SCHEDULE_DELETING_REQUEST'
export const SCHEDULE_DELETING_SUCCESS = 'SCHEDULE_DELETING_SUCCESS'
export const SCHEDULE_DELETING_ERROR = 'SCHEDULE_DELETING_ERROR'

export const SCHEDULE_SKU_CHECK_REQUEST = 'SCHEDULE_SKU_CHECK_REQUEST'
export const SCHEDULE_SKU_CHECK_SUCCESS = 'SCHEDULE_SKU_CHECK_SUCCESS'
export const SCHEDULE_SKU_CHECK_ERROR = 'SCHEDULE_SKU_CHECK_ERROR'
