import {parseAllToJson} from "./string";
import {Link} from "react-router-dom";
import React from "react";

export function addLink(inputColumns){
    let columns = parseAllToJson(inputColumns, 'options')
    columns = columns.map(e => {
        if(e != null && (e.options != null)) {
            e.options.customBodyRender = (value, tableMeta, updateValue) => {
                return (<Link to={e.options.route + "/" + tableMeta.rowData[0]}>{value}</Link>)
            }
        }
        return e
    })
    return columns;
}