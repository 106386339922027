
import {
    PART_UPDATE_FIELD,
    PARTS_LOADING_REQUEST,
    PARTS_LOADING_SUCCESS,
    PARTS_LOADING_ERROR,
    PART_IMPORT_LOADING_REQUEST,
    PART_IMPORT_LOADING_SUCCESS,
    PART_IMPORT_LOADING_ERROR,
    PARTS_SAVING_REQUEST,
    PARTS_SAVING_SUCCESS,
    PARTS_SAVING_ERROR,
} from '../constants/partTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    parts: [],
    part: {},
    rows: [],
    cols: [],
    columns: [],
    file: '',
}

export default function partListReducer(state = initialState, action) {
    switch (action.type) {
        case PART_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case PARTS_LOADING_REQUEST:
        case PART_IMPORT_LOADING_REQUEST:
        case PARTS_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case PARTS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                parts: action.payload,
            }
        case PART_IMPORT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                parts: action.parts,
                rows: action.rows,
                cols: action.cols,
            }
        case PARTS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                parts: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            const columns = parseAllToJson(action.payload, 'options')
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: columns,
            }
        case PARTS_LOADING_ERROR:
        case PART_IMPORT_LOADING_ERROR:
        case PARTS_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}