import React, { useState, useEffect, useMemo, useRef } from "react";
import MaterialTable from "@material-table/core";
import { useDispatch } from "react-redux";
import {
  getFlatPackSchedule,
  getScheduleByTruckAndStop,
  updateSchedule
} from "../../../actions/scheduleActions";
import { printTruckShippedPdf } from "../../../actions/shipmentActions";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  Tabs,
  Button,
  LinearProgress,
  Snackbar,
  IconButton,
  Backdrop,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Container
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchLocation,
  faSearchMinus,
  faUser,
  faTruck,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { USER_SCAN_PASS } from "../../../utils/api";
import {
  getFlatPackShipping,
  getStatusList,
  upadteFlatPackShipping,
  validateBatchScans,
  postBatchShippingScansInScanLog
} from "../../../actions/scanActions";
import moment from "moment";
import TruckLoad from "./TruckLoad";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import ClosedTruck from "./ClosedTruck";
import TruckModal from "./TruckModal";
import FailedScanModal from "./FailedScanModal";
import { loginNewUser } from "../ProductionHelperFunctions/ProductionHelper";

const Truck = () => {
  let history = useHistory();
  const inputField1Ref = useRef(null);
  let dateTime = new Date();
  dateTime = moment(dateTime).format("MM/DD/YYYY");
  const dispatch = useDispatch();
  const initialState = "";
  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [scan, setScan] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [stationName, setStationName] = useState(null);
  const [shippingObj, setShippingObj] = useState(null);
  const [user, setUser] = useState("");
  const [area, setArea] = useState(null);
  const [serialTag, setSerialTag] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sucessGreen, setSucessGreen] = useState(false);
  const [failRed, setFailRed] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [scanError, setScanError] = useState("");
  const [machineNumber, setMachineNumber] = useState("");
  const [dataObj, setDataObj] = useState([]);
  const [selectedRow, setSelectedRow] = useState(initialState);
  const [truckInfo, setTruckInfo] = useState(null);
  const [flatPackData, setFlatPackData] = useState(initialState);
  const [load, setLoad] = useState(false);
  const [truckStatus, setTruckStatus] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentTruckData, setCurrentTruckData] = useState([]);
  const [skippedStop, setSkippedStop] = useState([]);
  const [openTruck, setOpenTruck] = useState(true);
  const [resetTable, setResetTable] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [show, setShow] = useState(false);
  const [totalLeftToScan, setTotalLeftToScan] = useState(0);
  const [count, setCount] = useState(true);
  const [failedValidationArray, setFailedValidationArray] = useState([]);
  const [passedValidationArray, setPassedValidationArray] = useState([]);
  const [invalidScansArray, setInvalidScansArray] = useState([]);
  const [scans, setScans] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [unloadScan, setUnloadScan] = useState(null);
  const [disableUnload, setDisableUnload] = useState(false);
  const [unloadTruck, setUnloadTruck] = useState(false);
  const [postedScansAmount, setPostedScansAmount] = useState("");
  const [skipStop, setSkipStop] = useState(false);
  const [stopToSkip, setStopToSkip] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setDisableField(false);
    setDisableUnload(false);
    setScan(initialState);
    setRefresh(false);
    setSerialTag(null);
    setShippingObj(null);
    setStopToSkip("");
    setSkipStop(false);
    setScans([]);
    setSubmitLoading(false);
  }, [refresh]);

  const resetDataPage = () => {
    setStopToSkip("");
    setSkipStop(false);
    setUnloadTruck(false);
    setDisableField(false);
    setDisableUnload(false);
    setScan(initialState);
  };

  useEffect(() => {
    if (unloadTruck) {
      document.getElementById("unload").focus();
    } else if (skipStop) {
      document.getElementById("skipped").focus();
    }
  }, [unloadTruck, skipStop]);

  useEffect(() => {
    dispatch(getStatusList()).then((res) => {
      setStatus(res.payload);
    });
    let firstName = window.localStorage.getItem("firstName");
    let lastName = window.localStorage.getItem("lastName");
    let station = JSON.parse(window.localStorage.getItem("location"));
    let userid = JSON.parse(window.localStorage.getItem("userId"));
    let newData = JSON.parse(window.localStorage.getItem("location"));
    let st = station ? station.name : "";
    let machineLocation = JSON.parse(window.localStorage.getItem("machine"))
      ? JSON.parse(window.localStorage.getItem("machine"))
      : null;
    setUser(firstName + " " + lastName);
    setArea(st);
    setStatusId(newData.id);
    setUserId(userid);
    setStationName(machineLocation);
  }, [statusId]);

  useEffect(() => {
    setScans([]);
    setLoading(true);
    setDataObj([]);
    setTruckStatus(true);
    setRefresh(true);
    setLoad(false);
    setScanError("");
    setFailRed(false);
    dispatch(getFlatPackShipping()).then((res) => {
      setFlatPackData(res.payload);
    });
    let newData = JSON.parse(window.localStorage.getItem("location"));
    dispatch(getFlatPackSchedule(newData.id)).then((res) => {
      if (res.payload.length > 0) {
        const data = res.payload;
        setCurrentTruckData(res.payload);
        let newData = [];
        let truck = {};
        let order = new Map();

        for (let i = 0, len = data.length; i < len; i++) {
          let item = data[i];
          let p = item.truck_number;
          let odr = item.order_number;
          if (!truck[p]) {
            truck[p] = [];
          }
          truck[p].push(item);

          if (!order.has(odr)) {
            order.set(odr, []);
          }
          order.get(odr).push(item);
        }

        for (let ord of order.keys()) {
          let data = { order_number: ord };
          newData.push(data);
          for (let item of order.get(ord)) {
            const customer = item.factOrder[0];

            if (data.order_number === item.order_number) {
              data.truck_number = item.truck_number;
              data.customer = customer?.orderShipName
                ? customer?.orderShipName
                : "N/A";
              data.stop_number = item.stop_number;
              data.schedule = moment.utc(item.date).format("MM-DD-YYYY");
              data.pickup_date = moment
                .utc(item.pickup_date)
                .format("MM-DD-YYYY");
              data.pickup_time = item.pickup_time;
            }
          }
          let totalPieces = order.get(ord);

          let isLoading = [];
          const scans = order.get(ord);
          scans.filter((sc) => {
            let scan = sc.scans;
            scan.find((st) => {
              if (st.status_id == "125" && st.deleted_at == null) {
                isLoading.push(st);
              }
            });
          });
          data.status = [
            isLoading.length != totalPieces.length && isLoading.length != 0
              ? "Loading"
              : isLoading.length == totalPieces.length
              ? "Closed"
              : isLoading.length == 0
              ? "Open"
              : ""
          ];

          data.rowData = totalPieces;
          data.total_pieces = totalPieces.length;
          const foundation = [];
          const matt = [];

          for (let item of order.get(ord)) {
            if (item.skuObj.category_id == 1) {
              matt.push(item);
            } else if (item.skuObj.category_id == 10) {
              foundation.push(item);
            }
          }

          data.matt = matt.length;
          data.foundation = foundation.length;
          data.scanned = isLoading.length;
        }
        setDataObj(newData);
        setLoading(false);
      } else {
        setScanError(`No Truck Data Avaliable!`);
        alertRed();
      }
    });
  }, [resetPage]);

  const getTruck = async (dumbTruck) => {
    setScans([]);
    localStorage.setItem("truck_number", dumbTruck.truck_number);
    await dispatch(getScheduleByTruckAndStop(dumbTruck)).then((res) => {
      setTableData({
        tableData: res.payload,
        sku: dumbTruck.sku,
        truckInfo: dumbTruck
      });
      setTruckStatus(false);
      setTruckInfo(dumbTruck);
      // document.getElementById("SearchbyScanning").focus();
    });
  };

  useEffect(() => {
    let truckScan = [];
    const newdata = [];
    if (tableData.tableData) {
      let data = tableData.tableData;
      let order = new Map();
      for (let i = 0, len = data.length; i < len; i++) {
        let item = data[i];
        let od = item.sku;
        if (!order.has(od)) {
          order.set(od, []);
        }
        order.get(od).push(item);
        newdata.push(item);
      }
      newdata.filter((scans) => {
        const skusScanned = scans.scans;
        skusScanned.filter((sc) => {
          if (sc.status_id == "125" && sc.deleted_at == null) {
            truckScan.push(sc);
          }
        });
      });
      setTotal(newdata.length);
      setTotalLeftToScan(newdata.length - truckScan.length);
    }
  }, [tableData, count]);

  const closeTruck = async () => {
    let closing = [];
    let schedule = [];
    const finalLoad = tableData.tableData;

    finalLoad.filter((data) => {
      let newData = {
        id: data.id,
        date_closed: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      schedule.push(newData);
    });

    dispatch(updateSchedule(schedule));

    await finalLoad.filter((data) => {
      let newData = {
        schedule_id: data.id,
        date_closed: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      closing.push(newData);
    });

    updateFlat(closing);
    setScan("");
  };

  const updateFlat = (flatPackData) => {
    dispatch(upadteFlatPackShipping(flatPackData)).then((res) => {
      setResetTable(!resetTable);
    });
  };
  const closeLoad = async () => {
    if (tableData.tableData.length == 0) {
      setScanError(`Please Select Truck`);
      alertRed();
      return false;
    } else {
      const closingData = [];
      const truckNumber = tableData.truckInfo.truck_number;
      const expectedTruckLoad = tableData.tableData;
      flatPackData.filter((flatTruck) => {
        if (truckNumber == flatTruck.truck_number) {
          closingData.push(flatTruck);
        }
      });
      if (closingData.length != expectedTruckLoad.length) {
        setScanError(
          `Do you want to close truck short? Please notify Manager!`
        );
        alertRed();
        return false;
      } else {
        submitDataForPrinting();
      }
    }
  };

  const submitDataForPrinting = () => {
    let truckData = tableData.tableData;
    if (truckData !== undefined && truckData.length >= 1) {
      const data = tableData.tableData;
      let newData = [];
      let truck = {};
      let order = new Map();

      for (let i = 0, len = data.length; i < len; i++) {
        let item = data[i];
        let p = item.truck_number;
        let odr = item.order_number;
        if (!truck[p]) {
          truck[p] = [];
        }
        truck[p].push(item);

        if (!order.has(odr)) {
          order.set(odr, []);
        }
        order.get(odr).push(item);
      }

      for (let ord of order.keys()) {
        let data = { order_number: ord };
        newData.push(data);

        for (let item of order.get(ord)) {
          const customer = item.factOrder[0];
          if (data.order_number === item.order_number) {
            data.truck_number = item.truck_number;
            data.customer = customer?.orderShipName;
            data.stop_number = item.stop_number;
            data.schedule = moment(item.date).format("MM-DD-YYYY");
            data.pickup_date = moment(item.pickup_date).format("MM-DD-YYYY");
            data.pickup_time = item.pickup_time;
          }
        }
        let totalPieces = order.get(ord);
        data.rowData = totalPieces;
        data.total_pieces = totalPieces.length;
        const foundation = [];
        const matt = [];
        for (let item of order.get(ord)) {
          if (item.skuObj.category_id == 1) {
            matt.push(item);
          } else if (item.skuObj.category_id == 10) {
            foundation.push(item);
          }
        }
        data.matt = matt.length;
        data.foundation = foundation.length;
      }

      ExportPdf(newData);

      closeTruck();
    } else {
      setScanError(`Please Select Truck`);
      alertRed();
    }
  };

  useEffect(() => {
    //batch scan auto submit at 50 items.
    if (scans.length == 50) {
      submitBatchScansForValidation();
    }
  }, [scans]);

  //
  const submitBatchScansForValidation = async (e) => {
    const scanObject = {
      serial_numbers: scans.map((scan) => scan.serial_number)
    };
    if (scanObject.serial_numbers.length > 0) {
      const res = await dispatch(validateBatchScans(scanObject, statusId));
      const scanPayload = res.payload;
      const batchScanObjects = [];
      const failedScansArray = [];
      const invalidScansArray = scanObject.serial_numbers
        .filter((sn) => !scanPayload.find((scan) => sn === scan.serial_number))
        .map((scans) => ({ invalid: scans, reason: "invalid data" }));

      const currentTruckLookup = {};

      currentTruckData.forEach((truckData) => {
        currentTruckLookup[truckData.id] = truckData;
      });

      const skippArr = [];
      // await Promise.all(
      scanPayload.map(async (scan) => {
        const checkTruckNumber = currentTruckData.some(
          (data) =>
            data.id == scan.schedule_id &&
            data.truck_number == localStorage.getItem("truck_number")
        );

        for (const truck of flatPackData) {
          const newTruck = currentTruckLookup[scan.schedule_id];

          if (
            newTruck.stop_number < truck.stop_number &&
            newTruck.truck_number == localStorage.getItem("truck_number")
          ) {
            skippArr.push(truck);
          }
        }
        // const checker = (arr) => arr.every((skip) => skip.stop_skipped <= 1);

        if (scan.scans.length > 0) {
          setFailedValidationArray((prevState) => [...prevState, scan]);
          failedScansArray.push(scan);
        } else if (!checkTruckNumber) {
          scan["wrong_truck"] = "WRONG TRUCK NUMBER!";
          setFailedValidationArray((prevState) => [...prevState, scan]);
          failedScansArray.push(scan);
          // } else if (checker(skippArr) === false) {
          //   scan["skipped_stop"] =
          //     "Loading out of Order please skip stop before scanning!";
          //   setFailedValidationArray((prevState) => [...prevState, scan]);
          //   failedScansArray.push(scan);
        } else {
          setPassedValidationArray((prevState) => [...prevState, scan]);
          let newObj = {
            status_id: statusId,
            epc: scan.serial_number,
            rfid_scan: "",
            date: moment().format("YYYY-MM-DD HH:mm:ss"),
            pass: 1,
            user_id: userId,
            shipping_location: "",
            schedule_id: "",
            serial_id: "",
            station_id: stationName ? stationName : null,
            order_number: ""
          };
          batchScanObjects.push(newObj);
        }
      });

      const passedBatchScanObjects = {
        scans: batchScanObjects
      };

      const hasFailedScans = failedScansArray.length > 0;
      // const hasInvalidScans = invalidScansArray.length > 0;
      const hasPassedScans = passedBatchScanObjects.scans.length > 0;

      setInvalidScansArray(invalidScansArray);

      if (hasFailedScans || hasPassedScans) {
        setSubmitLoading(false);
        setModalState(true);
        setDisableField(true);
        setScans([]);
      }

      if (hasPassedScans) {
        const res = await dispatch(
          postBatchShippingScansInScanLog(passedBatchScanObjects)
        );
        if (res.type === "SCANS_LOADING_SUCCESS") {
          setScans([]);
          if (!hasFailedScans) {
            setPassedValidationArray([]);
            setSubmitLoading(false);
            setPostedScansAmount(res.payload);
            setRefresh(true);
            getTruck(truckInfo);
          }
        }
      }
    } else {
      setRefresh(true);
    }
  };

  useEffect(() => {
    if (unloadScan != null && unloadScan.length == 16) {
      setScanError("");
      setFailRed(false);
      setDisableUnload(true);
      let serials = { serial_numbers: [unloadScan] };
      dispatch(validateBatchScans(serials, statusId)).then((res) => {
        if (res.payload.length > 0 && res.type == "VALIDATE_SCANS_SUCCESS") {
          const isValid = currentTruckData.find((data) => {
            if (data.id == res.payload[0].schedule_id) {
              return data;
            }
          });
          if (isValid == null) {
            setScanError(`Serial Number ${serialTag} not found on truck`);
            alertRed();
            return false;
          }

          let scans = res.payload[0];
          let isScanned = scans.scans;
          if (
            isScanned.length > 0 ||
            (isScanned.epc === serialTag && statusId == isScanned.status_id)
          ) {
            let lastScanInfo = isScanned[0];
            let lastUserScanned = isScanned[0].user;
            let firstName = lastUserScanned.first_name;
            let lastName = lastUserScanned.last_name;
            let date = lastScanInfo.date;
            let sku = lastScanInfo.rfid_scan;
            setModalData(lastScanInfo);
            setShow(true);
            setScanError(
              `${sku} - Scanned on ${moment(date).format(
                "MM-DD-YYYY hh:mm A"
              )} by ${firstName} ${lastName}`
            );
            alertRed();
            return false;
          } else {
            setScanError(`${scans.serial_number} - Not loaded on this truck`);
            alertRed();
          }
        } else {
          setScanError(`Not A Valid Serial Number`);
          alertRed();
        }
      });
    }
  }, [unloadScan]);

  useEffect(() => {
    if (stopToSkip != null && stopToSkip.length == 16) {
      setScanError("");
      setFailRed(false);
      setDisableUnload(true);
      let serials = { serial_numbers: [stopToSkip] };
      dispatch(validateBatchScans(serials, statusId)).then((res) => {
        if (res.payload.length > 0 && res.type == "VALIDATE_SCANS_SUCCESS") {
          const isValid = currentTruckData.find((data) => {
            if (data.id == res.payload[0].schedule_id) {
              return data;
            }
          });
          if (isValid == null) {
            setScanError(`Serial Number ${stopToSkip} not found on truck`);
            alertRed();
            return false;
          }
          if (isValid != null) {
            let stop_skipped = flatPackData.filter(
              (truck) =>
                isValid.stop_number < truck.stop_number &&
                isValid.truck_number == truck.truck_number
            );
            stop_skipped.filter((data) => {
              data.date = moment(data.date).format("YYYY-MM-DD HH:mm:ss");
            });

            setTruckStatus(false);
            setScan("");

            getTruck({
              truck_number: isValid.truck_number,
              stop_number: isValid.stop_number,
              sku: isValid.sku
            });
            if (stop_skipped.length > 0) {
              let checker = (arr) =>
                arr.every((skip) => skip.stop_skipped > isValid.stop_number);

              if (checker(stop_skipped) === false) {
                setScanError(
                  `Loading out of order! Stop number ${isValid.stop_number} will be skipped!`
                );
                alertRed();
                setSkippedStop(stop_skipped);
                skippingStop(stop_skipped);

                return false;
              }
            } else {
              setScanError(`No stops available to skip`);
              alertRed();
            }
          }
        } else {
          setScanError(`Not A Valid Serial Number`);
          alertRed();
        }
      });
    }
  }, [stopToSkip]);

  const skippingStop = async (data) => {
    let flatPackData = [];
    if (data.length == 0) {
      setScanError(`No stops avaliable to Skip!`);
      alertRed();
      return false;
    } else {
      data.filter((data) => {
        let newData = data;
        delete newData.id;
        newData.stop_skipped = 1;
        flatPackData.push(newData);
      });

      updateFlat(flatPackData);
      // setScanError(`Stop has been skipped`);
      // setScanError("");
      setFailRed(false);
      setRefresh(true);
      // alertRed();
      setSucessGreen(true);
    }
  };

  const alertRed = (e) => {
    setFailRed(true);
    setTimeout(() => {}, 2000);

    setTimeout(() => {
      setRefresh(true);
    }, 600);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSucessGreen(!sucessGreen);
    setRefresh(!refresh);
  };

  const logout = () => {
    let logoutItems = [
      "token",
      "firstName",
      "lastName",
      "employeeNum",
      "userId",
      "userRole"
    ];
    logoutItems.forEach((obj) => {
      localStorage.removeItem(obj);
    });

    window.location.href = "/";
  };

  let timeInterval = setInterval(() => {
    let lastAcivity = localStorage.getItem("lastActvity");
    var diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
    var seconds = Math.floor(diffMs / 1000);
    var minute = Math.floor(seconds / 60);
    if (minute == 15) {
      clearInterval(timeInterval);
      logout();
    }
  }, 1000);

  const handleChange = (e) => {
    setScan("");
    setSerialTag("");
    // setEpc("");
    setMachineNumber("");

    let data = e.target.value;
    let locationObj = status.find((item) => item.id == data);
    setStatusId(data);
    setStationName(initialState);

    if (locationObj) {
      if (
        locationObj.allow_batch_scan === 1 &&
        locationObj.allow_batch_scan != 0
      ) {
        history.push("/batch_scan");
      } else
        window.localStorage.setItem("location", JSON.stringify(locationObj));
      window.localStorage.setItem("machine", JSON.stringify(0));
      history.push("/production");
    }
  };
  const ExportPdf = async (rowData) => {
    const newTruck = rowData;
    newTruck.filter((truck) => {
      const pickUpTruck = truck.rowData;
      let newData = [];
      let order = new Map();
      for (let i = 0, len = pickUpTruck.length; i < len; i++) {
        let item = pickUpTruck[i];
        let od = item.sku;
        if (!order.has(od)) {
          order.set(od, []);
        }
        order.get(od).push(item);
      }

      for (let ord of order.keys()) {
        let data = { sku: ord };
        newData.push(data);

        let shippedQty = [];
        for (let item of order.get(ord)) {
          let dt = order.get(ord);
          dt.filter((scans) => {
            const scanned = scans.scans;
            scanned.filter((sc) => {
              if (sc.status_id == "125") {
                shippedQty.push(sc);
              }
            });
          });
          const qty = shippedQty.map((o) => o.id);
          const qtyShipped = shippedQty.filter(
            ({ id }, index) => !qty.includes(id, index + 1)
          );

          if (data.sku === item.sku) {
            dt.filter((factOrder) => {
              let customerData = factOrder.factOrder[0];
              data.description = item.familyName;
              data.sizeName = item.sizeName;
              data.order_qty = dt.length;
              data.pickup_date = item.pickup_date;
              data.shipped_qty = qtyShipped.length;
              data.order_number = customerData.orderNumber;
              data.po = customerData.orderCustomerNotes;
              data.orderShipName = customerData.orderShipName;
              data.orderShipStreetOne = customerData.orderShipStreetOne;
              data.orderShipStreetTwo = customerData.orderShipStreetTwo;
              data.orderShipCity = customerData.orderShipCity;
              data.orderShipState = customerData.orderShipState;
              data.orderShipPostalCode = customerData.orderShipPostalCode;
              data.orderShipCountry = customerData.orderShipCountry;
              data.orderCustomerEmail = customerData.orderCustomerEmail;
              data.category_id = item.skuObj.category_id;
            });
          }
        }
      }
      const customerInfo = pickUpTruck[0].factOrder[0];
      dispatch(printTruckShippedPdf({ customerInfo, newData })).then((res) => {
        if (res.payload[0] == "201-Created") {
          setResetPage(!resetPage);
        }
      });
    });
  };

  const headers = [
    {
      title: "Pick Up Date ",
      field: "pickup_date"
    },
    {
      title: "Pick Up Time",
      field: "pickup_time"
    },
    {
      title: "Truck",
      field: "truck_number",
      defaultSort: "desc"
    },
    {
      title: "Stop",
      field: "stop_number",
      defaultSort: "desc"
    },
    {
      title: "Order Number",
      field: "order_number"
    },
    {
      title: "Scheduled",
      field: "schedule"
    },
    {
      title: "Customer",
      field: "customer"
    },
    {
      title: "Total Pieces",
      field: "total_pieces"
    },
    {
      title: "Matt",
      field: "matt"
    },
    {
      title: "FND",
      field: "foundation"
    },
    {
      title: "Scanned",
      field: "scanned"
    },
    {
      title: "Status",
      field: "status"
    }
  ];

  const truckChildComponent = useMemo(
    () => <TruckLoad component={tableData} />,
    [tableData]
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      localStorage.setItem("lastActvity", new Date());
      if (scan.toUpperCase().includes("SKIPPEDSTOP") && scan.length == 11) {
        skippingStop();
      } else if (
        scan.toUpperCase().includes("CLOSINGLOAD") &&
        scan.length == 11
      ) {
        closeLoad();
      } else if (scan.toUpperCase().includes("E2C") && scan.length == 9) {
        loginNewUser(dispatch, scan, USER_SCAN_PASS);
      } else if (
        scan.toUpperCase().includes("CLOSESHORT") &&
        scan.length == 10
      ) {
        submitDataForPrinting();
      } else if (scan.toLowerCase() === "submit") {
        submitBatchScansForValidation();
        setSubmitLoading(true);
        setDisableField(true);
        setTimeout(() => {
          setScan("");
          setDisableField(false);
        }, 200);
      } else if (scan === "unload") {
        setUnloadTruck(true);
        setDisableField(true);
      } else if (scan === "skip") {
        setSkipStop(true);
        setDisableField(true);
      }
      setDisableField(true);
      if (scans.find((scanned) => scanned.serial_number == scan)) {
        setScans((prevScans) =>
          prevScans.filter((scan) => scan.serial_number !== scan)
        );
      } else {
        setScans((prevScans) => [...prevScans, { serial_number: scan }]);
      }
      setScan("");
      setDisableField(false);
      // document.getElementById("SearchbyScanning").focus();
    }
  };

  const handleClickOutside = (e) => {
    if (inputField1Ref.current && !inputField1Ref.current.contains(e.target)) {
      document.getElementById("SearchbyScanning").focus();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="production_truck_body">
      <Backdrop style={{ zIndex: "1", height: "auto" }} open={submitLoading}>
        <Container>
          <LinearProgress />
        </Container>
      </Backdrop>
      <FailedScanModal
        failed={failedValidationArray}
        passed={passedValidationArray}
        invalid={[]}
        show={modalState}
        close={(closeModal) => {
          setModalState(closeModal.show);
          setFailedValidationArray([]);
          setPassedValidationArray([]);
          setDisableField(false);
          // document.getElementById("SearchbyScanning").focus();
        }}
      />
      <TruckModal
        component={modalData}
        show={show}
        refresh={(component) => {
          setShow(component.show);
        }}
        close={(closeModal) => {
          setShow(closeModal.show),
            setUnloadTruck(false),
            setUnloadScan(""),
            refresh(true);
        }}
        getItem={(data) => {
          getTruck({
            truck_number: data.truck_id,
            stop_number: data.stop_number,
            sku: data.rfid_scan
          });
        }}
      />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        open={sucessGreen}
        autoHideDuration={10000}
        onClose={handleClose}
        message="Mattress sucessffully shipped!!"
      >
        <Alert severity="info">
          <Typography variant="h4">Stop has been skipped!!</Typography>
        </Alert>
      </Snackbar>
      <Box className="production_header_title" pt={2}>
        <Grid container style={{ borderBottom: "solid 1px" }}>
          <Grid container item xs={6} justifyContent="flex-start">
            <Box pl={2}>
              <Typography className="production_header_title" variant="h3">
                <FontAwesomeIcon icon={faUser} />
                &nbsp;{user}
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Box mr={2} mb={1}>
              <Typography className="production_header_title" variant="h3">
                <FontAwesomeIcon icon={faSearchLocation} />
                &nbsp;{area}&nbsp;
                {machineNumber != null ? <>{machineNumber} </> : ""}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="production_sub_header" pt={1} pl={1}>
        <Grid container justifyContent="center">
          <Box>
            <Tabs indicatorColor="primary" />
          </Box>
        </Grid>
      </Box>
      {failRed == true ? (
        <Alert variant="filled" severity="error" className="error">
          <Typography variant="h4"> {scanError}</Typography>
        </Alert>
      ) : (
        ""
      )}
      {scans.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="25%" className="truck_table_cell">
                  <Typography variant="h2">
                    Total Scans: {scans.length}
                  </Typography>
                </TableCell>
                <TableCell className="truck_table_cell">
                  <Typography variant="h2">Serial Number</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[]
                .concat(scans)
                .reverse()
                .map((row, index) => (
                  <TableRow
                    key={row.serial_number + "-" + index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 }
                    }}
                  >
                    <TableCell scope="row" className="truck_data">
                      {scans.length - index}
                    </TableCell>
                    <TableCell scope="row" className="truck_data">
                      {row.serial_number}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {dataObj && !loading ? (
        <Box m={4}>
          <Box mt={4}>
            <Grid container>
              <Grid item xs={2} sm={1}>
                <Typography
                  style={{
                    display: "inline-block",
                    fontWeight: "bold",
                    fontSize: "24px",
                    marginTop: "0px"
                  }}
                >
                  Scan Status
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Select
                  style={{ maxWidth: "200px" }}
                  fullWidth
                  onChange={handleChange}
                  value={statusId ? statusId : 0}
                  options={status.map((option) => ({
                    value: option.id,
                    label: option.name,
                    required: option.station_required
                  }))}
                >
                  <MenuItem key={0} value={0}>
                    Select
                  </MenuItem>
                  {status.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  style={{
                    backgroundColor: "#32CD32",
                    fontSize: "20px"
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    getTruck(truckInfo);
                    resetDataPage();
                  }}
                >
                  <FontAwesomeIcon icon={faSync} size="2x" />
                </IconButton>
              </Grid>

              <Grid item xs={3}>
                <Button
                  style={{
                    backgroundColor: "#2894c3",
                    fontSize: "20px",
                    width: "425px",
                    color: "#FFFFFF"
                  }}
                  variant="outlined"
                  size="large"
                  onClick={(e) => {
                    setOpenTruck(true);
                    setTruckStatus(true), setLoad(false);
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp; Truck status
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  style={{
                    backgroundColor: "red",
                    fontSize: "20px",
                    width: "425px",
                    color: "#FFFFFF"
                  }}
                  variant="outlined"
                  size="large"
                  onClick={(e) => {
                    setOpenTruck(false);
                  }}
                >
                  <FontAwesomeIcon icon={faTruck} />
                  &nbsp; Closed Trucks
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box mt={2} pt={2}>
            <Box mb={3}>
              <Grid container>
                {/* <Grid item xs={2} sm={1}></Grid> */}
                <Grid item xs={6}>
                  {load ? (
                    <form>
                      <Typography
                        style={{
                          display: "inline-block",
                          fontWeight: "bold",
                          fontSize: "24px",
                          marginTop: "24px",
                          paddingRight: "15px"
                        }}
                      >
                        Scan Data
                      </Typography>
                      <TextField
                        style={{ maxWidth: "325px" }}
                        value={scan}
                        fullWidth
                        ref={inputField1Ref}
                        id="SearchbyScanning"
                        className="sku_textfield"
                        disabled={disableField}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          setScan(e.target.value);
                        }}
                        variant="filled"
                        InputProps={{
                          minLength: 22,
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon
                                style={{ marginTop: "2px" }}
                                icon={faSearchMinus}
                                size="lg"
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                      <Grid item xs={12}>
                        <Box mt={2}>
                          {unloadTruck ? (
                            <>
                              <Typography
                                style={{
                                  display: "inline-block",
                                  fontWeight: "bold",
                                  fontSize: "24px",
                                  marginTop: "24px",
                                  paddingRight: "15px"
                                }}
                              >
                                Unload Scan
                              </Typography>
                              <TextField
                                style={{ maxWidth: "325px" }}
                                value={unloadScan}
                                fullWidth
                                id="unload"
                                className="sku_textfield"
                                disabled={disableUnload}
                                onChange={(e) => {
                                  setUnloadScan(e.target.value);
                                }}
                                variant="filled"
                                InputProps={{
                                  minLength: 22,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon
                                        style={{ marginTop: "2px" }}
                                        icon={faSearchMinus}
                                        size="lg"
                                      />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </>
                          ) : skipStop ? (
                            <>
                              <Typography
                                style={{
                                  display: "inline-block",
                                  fontWeight: "bold",
                                  fontSize: "24px",
                                  marginTop: "24px",
                                  paddingRight: "15px"
                                }}
                              >
                                Skip Stop
                              </Typography>
                              <TextField
                                style={{ maxWidth: "325px" }}
                                value={stopToSkip}
                                fullWidth
                                id="skipped"
                                className="sku_textfield"
                                onChange={(e) => {
                                  setStopToSkip(e.target.value);
                                }}
                                variant="filled"
                                InputProps={{
                                  minLength: 22,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FontAwesomeIcon
                                        style={{ marginTop: "2px" }}
                                        icon={faSearchMinus}
                                        size="lg"
                                      />
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                    </form>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6}>
                  {!truckStatus ? (
                    <>
                      <Typography
                        variant="h3"
                        style={{
                          display: "inline-block",
                          fontWeight: "bold"
                        }}
                      >
                        {`Truck # ${tableData?.truckInfo?.truck_number} short`}
                        &nbsp;
                        <Typography
                          display="inline"
                          style={{
                            color: "red",
                            fontSize: "75px",
                            fontWeight: "bold"
                          }}
                        >
                          {`${totalLeftToScan}`}
                        </Typography>
                        <Typography
                          display="inline"
                          style={{
                            fontSize: "75px",
                            fontWeight: "bold"
                          }}
                        >
                          / {total}
                        </Typography>
                        &nbsp; items!
                      </Typography>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Box>
            {openTruck ? (
              <>
                {truckStatus ? (
                  <>
                    <MaterialTable
                      title="Truck Status"
                      data={dataObj}
                      columns={headers}
                      onRowClick={(evt, selectedRow) => {
                        setSelectedRow(selectedRow.tableData.id),
                          getTruck({
                            truck_number: selectedRow.truck_number,
                            stop_number: selectedRow.stop_number
                          });
                        setLoad(true);
                      }}
                      options={{
                        search: false,
                        pageSize: 20,
                        addRowPosition: "first",
                        actionsColumnIndex: -1,
                        rowStyle: {
                          fontSize: 16
                        },
                        rowStyle: (rowData) => ({
                          fontSize: 16,
                          fontWeight: "bold",
                          backgroundColor:
                            selectedRow === rowData.tableData.id
                              ? "#6ABAC9"
                              : "#FFF"
                        }),
                        headerStyle: {
                          fontSize: 18,
                          fontWeight: "bold"
                        },
                        emptyRowsWhenPaging: false
                      }}
                    />
                  </>
                ) : (
                  <>{truckChildComponent}</>
                )}
              </>
            ) : (
              <>
                <ClosedTruck />
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box m={12} pt={12}></Box>
      )}
      {loading == true ? <LinearProgress /> : ""}
    </div>
  );
};

export default Truck;
