import { LinearProgress, Grid, Typography } from "@material-ui/core";
import React from "react";

const LinearProgressBar = () => {
  return (
    <div
      className="linear_component_page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Grid
        item
        xs={6}
        style={{
          textAlign: "center",
          color: "white"
        }}
      >
        <LinearProgress
          className="linear_progress_blue"
          style={{ padding: "10px" }}
        />
      </Grid>
    </div>
  );
};

export default LinearProgressBar;
