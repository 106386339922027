import React from 'react';
import LawTagViewer from './viewer/LawTagViewer';
import '../styles/about-page.css';

export class FoundationListPage extends React.Component {

    render() {
        return (
            <LawTagViewer/>
        );
    }
}

export default FoundationListPage;
