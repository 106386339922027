import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Backdrop,
  Snackbar
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faDigitalTachograph,
  faTree
} from "@fortawesome/free-solid-svg-icons";
import {
  postComponentScanLog,
  validateBatchScans
} from "../../actions/scanActions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { findComponentBySerialNumber } from "../../actions/serialNumberActions";
import { Alert } from "@material-ui/lab";
import LinearProgressBar from "./LinearProgressBar";
import { findComponentBySerial } from "../../actions/scheduleActions";

const SubComponentScan = (component) => {
  const dispatch = useDispatch();
  const [childComponent, setChildComponent] = useState([]);
  const [fields, setFields] = useState([]);
  const [overlaybackdrop, setOverlaybackdrop] = useState(false);
  const [componentSerialNumbers, setComponentSerialNumbers] = useState([]);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [scanError, setScanError] = useState(null);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const parentBomValidation = component.component.sku.skuObj[0].bom;

  useEffect(() => {
    const parent = component.component.parentData;
    var counter = 0;
    let rows = [];
    let rowTotal = "";

    component.component.component_schedule.map((component) => {
      // hard code status need to be taken out for line load once logic is written for parent to child relationship that is not like kitting parent to child relationship.
      if (component.component_type_id == 4 && parent.status_id != 119) {
        rowTotal = 2;
      } else {
        rowTotal = 1;
      }
    });

    if (rows) {
      for (let i = 0; i < rowTotal; i++) {
        counter = counter + 1;
        rows.push({ key: counter.toString() });
      }
      setFields(rows);
    }
  }, []);

  useEffect(() => {
    if (fields.length > 1) {
      document.getElementById("1").focus();
    }
  }, [fields]);

  const alertRed = (index) => {
    setFailed(true);
    setOverlaybackdrop(true);
    setFieldsDisabled(true);
    setTimeout(() => {
      setOverlaybackdrop(false);
      setFieldsDisabled(false);
      errorResetField(index);
    }, 1000);
  };

  const errorResetField = (index) => {
    if (index) {
      document.getElementById(index).value = "";
      document.getElementById(index).focus();
    }
    setChildComponent((prevState) =>
      prevState.filter((item, i) => i !== index - 1)
    );
    setComponentSerialNumbers((prevState) =>
      prevState.filter((item, i) => i !== index - 1)
    );
  };

  useEffect(() => {
    if (childComponent.length > 0 && childComponent.length === fields.length) {
      submitData(childComponent);
    }
  }, [childComponent]);

  const submitData = async (newData) => {
    setLoading(false);
    if (newData.length == fields.length) {
      setFieldsDisabled(true);
      let data = [];
      const results = newData.filter((element) => {
        return element !== undefined;
      });
      if (newData.length == fields.length || results.indexOf("SaveAssembly")) {
        const index = results.indexOf("SaveAssembly");
        if (index > -1) {
          results.splice(index, 1);
        }
        results.filter((item) => {
          data.push({
            epc: item.serial_number,
            scan_log_id: component.component.scanLogId,
            parent_serial_id: component.component.parentSerialId,
            station_id: component.component.parentData.station_id,
            child_serial_id: item.id,
            datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
            status_id: component.component.status,
            user_id: JSON.parse(window.localStorage.getItem("userId"))
          });
        });

        if (componentSerialNumbers.find((item) => item.allow_duplicates == 1)) {
          submitSubComponents({
            subcomponent: data,
            parent: component.component.parentData
          });
        } else {
          const arrOfSerialNums = data.map((obj) => obj.child_serial_id);
          const dups = Array.from(new Set(arrOfSerialNums));
          const duplicates = data.length !== dups.length ? true : false;
          if (duplicates || scanError != null) {
            return false;
          } else {
            submitSubComponents({
              subcomponent: data,
              parent: component.component.parentData
            });
          }
        }
      }
    }
  };

  const submitSubComponents = async (data) => {
    setLoading(false);
    if (data.subcomponent.length == fields.length) {
      setLoading(true);
      setFieldsDisabled(false);
      dispatch(postComponentScanLog(data));
      setLoading(!loading);
      setOpen(!open);
      setOverlaybackdrop(!open);
    }
  };

  const handleChangeInput = async (index, e) => {
    setLoading(true);
    setFailed(false);
    setScanError(null);
    e.persist();
    e.preventDefault();
    if (e.target.value.length == 16) {
      setFieldsDisabled(true);
      const event = new KeyboardEvent("keydown", {
        view: window,
        keyCode: 13,
        bubbles: true,
        cancelable: true
      });
      document.getElementById(index).dispatchEvent(event);
      const serials = { serial_numbers: [e.target.value] };
      await dispatch(
        validateBatchScans(serials, component.component.status)
      ).then(async (res) => {
        if (res.payload.length > 0) {
          const serialNumberPayload = res.payload[0];
          setComponentSerialNumbers((prevState) => [
            ...prevState,
            serialNumberPayload
          ]);
          if (serialNumberPayload.allow_duplicates != 1) {
            setLoading(true);
            const scans = res.payload[0].scans;
            const lastScanned = scans[0];
            serials.serial_numbers.map((serialNumbers) => {
              if (serialNumbers == component.component.parentData.epc) {
                alertRed(index);
                setScanError("Cannot have duplicate serial numbers");
                setLoading(false);
                return false;
              }
            });

            childComponent.filter((comp) => {
              if (comp.serial_number == e.target.value) {
                alertRed(index);
                setScanError("Cannot have duplicate serial numbers");
                setLoading(false);
                return false;
              }
            });

            if (lastScanned != undefined) {
              setScanError(
                "Sku " +
                  lastScanned.rfid_scan +
                  " last scanned by " +
                  lastScanned.user.first_name +
                  " " +
                  lastScanned.user.last_name +
                  " " +
                  moment(lastScanned.date).format("YYYY-MM-DD hh:mm a")
              );
              alertRed(index);
              setLoading(false);
              return false;
            } else {
              await dispatch(
                findComponentBySerial(
                  e.target.value,
                  component.component.status
                )
              ).then((res) => {
                const bomValidation = res.payload.component_schedule;

                bomValidation.find((bom) => {
                  const validate = parentBomValidation.some(
                    (part) => part.part_num == bom.component_itemid
                  );
                  const laminationValidation = bomValidation.some(
                    (part) =>
                      part.component_name ==
                      component.component.component_schedule[0].component_name
                  );
                  if (validate || laminationValidation === true) {
                    setChildComponent((prevState) => [
                      ...prevState,
                      serialNumberPayload
                    ]);
                    setLoading(false);
                  } else {
                    alertRed(index);
                    setScanError("Wrong subcomponent!!");
                    setLoading(false);
                    return false;
                  }
                });
              });
            }
          } else {
            setChildComponent((prevState) => [
              ...prevState,
              serialNumberPayload
            ]);
            setLoading(false);
          }
        } else {
          alertRed(index);
          setScanError("Wrong subcomponent!!");
          setLoading(false);
        }
      });
    }
  };

  const focusOnNextField = (e) => {
    setFieldsDisabled(false);
    if (e.key.toLowerCase() === "enter") {
      setLoading(false);
      e.preventDefault();
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      if (form.elements[index + 1] == null) {
        return false;
      }
      form.elements[index + 1].focus();
      return;
    }
  };
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(!open);
    component.refresh(true);
    component.scan(childComponent.length + 1);
  };

  const showRows = (
    <>
      {fields.map((area) => (
        <Grid item xs={8} sm={6} md={8} key={area.key}>
          <Box mb={5}>
            <TextField
              key={area.key}
              style={{ marginBottom: "25px" }}
              value={area.value}
              id={area.key}
              className="sku_textfield"
              fullWidth
              autoFocus
              disabled={fieldsDisabled}
              onKeyDown={focusOnNextField}
              variant="filled"
              label="Subcomponent"
              onChange={(e) => {
                handleChangeInput(area.key, e);
              }}
              InputProps={{
                minLength: 16,
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      style={{ marginTop: "2px" }}
                      icon={faBarcode}
                      size="lg"
                    />
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Grid>
      ))}
    </>
  );

  return (
    <>
      <Backdrop style={{ zIndex: "1" }} open={overlaybackdrop} />
      {loading == true ? <LinearProgressBar /> : ""}
      <Grid container style={{ color: "white", minHeight: "100vh" }}>
        <Paper style={{ width: " 100%", maxHeight: "600px" }}>
          <div style={{ margin: "25px" }}>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={open}
              autoHideDuration={800}
              onClose={handleClose}
              message="Component sucessfully updated!!"
            >
              <Alert variant="filled" severity="success">
                Component successfully updated!!
              </Alert>
            </Snackbar>

            <Grid container>
              <Box m={2}>
                <Typography variant="h4">
                  <FontAwesomeIcon icon={faBarcode} /> &nbsp; SKU:&nbsp;&nbsp;
                  {component.component.sku?.sku}
                </Typography>
                <Typography variant="h4" style={{ marginTop: "15px" }}>
                  <FontAwesomeIcon icon={faDigitalTachograph} /> &nbsp;
                  FAMILY:&nbsp;&nbsp;
                  {component.component.sku.skuObj[0]?.family?.family}
                </Typography>
              </Box>
            </Grid>
            {failed == true ? (
              <Grid item xs={12}>
                <Box>
                  <Alert variant="filled" severity="error">
                    <Typography variant="h4">{scanError}</Typography>
                  </Alert>
                </Box>
              </Grid>
            ) : (
              <></>
            )}
            <Box m={4}>
              <form id="create-course-form">
                <Grid container justifyContent="center">
                  {showRows}
                </Grid>
              </form>
            </Box>
          </div>
        </Paper>
      </Grid>
    </>
  );
};

export default SubComponentScan;
