import * as types from '../constants/roleTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";

export function getRoles() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/roles`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.ROLES_LOADING_REQUEST,
                types.ROLES_LOADING_SUCCESS,
                types.ROLES_LOADING_ERROR,
            ],
        },
    }
}

