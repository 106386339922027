import React, { useState , useEffect } from 'react';
import T from 'prop-types';
import { useLocation, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { 
  Box, 
  Typography, 
  Button, 
  FormControl, 
  TextField,
  MenuItem,
  OutlinedInput,
  } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';
import { saveCore } from '../../../actions/coreActions';
import { getParentCores } from '../../../actions/parentCoreActions';
import { getCoresByParentId } from '../../../actions/viewCoreActions';
import { getCoreFirmnesses } from '../../../actions/coreFirmnessActions';
import { getSizes } from '../../../actions/sizeActions';
import CopyBomDraggableContext from './CopyBomDraggableContext';
import CopyBomLayersForm from './CopyBomLayersForm';
import { getCoreRawMaterials } from '../../../actions/coreRawMaterialActions';
import { getParts } from '../../../actions/partActions';
import { getCore} from '../../../actions/coreActions';
import { getCoreTypes } from '../../../actions/coreTypeActions';
import ConfirmationModal from '../../confirmationModal/ConfirmationModal';
import "./copybomform.css"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CopyBomForm = (props) => {
  const {
    sizes,
    rawMaterials,
    parts,
    firmnesses,
    parentCores,
    coreTypes
  } = props
  const dispatch = useDispatch();
  const location = useLocation()
  const [snackbar, setSnackBarStatus] = useState({
    success: false,
    error: false,
    message: "",
    successMessage: "",
  });
  const [modalState, setModalState] = useState({
    modalOpenStatus: false,
    success: "",
    redirect: ""
  });
  const [coreData, setCoreData] = useState({
    existing_cores: [],
    parent_core: "",
    size: location.state.row.size.size || "",
    firmness: "",
    showNewLayer: false,
    topper: false,
    convoluted: false,
    layers: [],
    depth: "",
    actualDepth: "",
    rawMaterial: "",
    partNum: ""
  });
  const handleShowHideNewLayer = () => {
    setCoreData(prevState => ({...prevState, showNewLayer: !prevState.showNewLayer}))
  };
  const confirmLayerRemoval = async (index) => {
    let newLayerArr = coreData.layers
    await newLayerArr.splice(index, 1)
    newLayerArr.forEach((layer,index) => {
      layer.core_layer = index
    })
    await setCoreData(prevState => ({...prevState, layers: newLayerArr}) )
  };
  const getAllParentCores = async () => {
    await dispatch(getParentCores())
  };
  const getAllFirmness = async () => {
    await dispatch(getCoreFirmnesses())
  };
  const getAllSizes = async () => {
    await dispatch(getSizes())
  };
  const getRawMaterials = async () => {
    await dispatch(getCoreRawMaterials())
  };
  const getAllParts = async () => {
    await dispatch(getParts())
  }
  const getAllCoreTypes = async () => {
    dispatch(getCoreTypes())
  };
  const getAllCoresByParentId = async () =>  {
    await dispatch(getCoresByParentId(location.state.row.type.parent_core_id))
      .then(res => {
        setCoreData( prevState => ({
          ...prevState,
          existing_cores: [...res.payload]
        }))
      });
  }
  const getCoreById = async () => {
    await dispatch(getCore(location.state.row.id))
    .then(res => {
      let newLayers = res.payload.layers
      newLayers.forEach(layer => {
        layer.id = null;
        layer.core_id = null;
        layer.part = {
          id: layer.part.id,
          num: layer.part.num,
          description: layer.part.description
        }
        if(layer.core_layer >= 9) {
          layer.topper = true;
        } else {
          layer.topper = false;
        }
      })
      setCoreData(prevState => ({
        ...prevState,
        parent_core: res.payload.type.parentCore.parent_core,
        firmness: res.payload.type.firmness.firmness,
        layers: [...newLayers]
      }))
    })
  }
  const handleClose = ( event, reason) => {
    if (reason === 'clickaway') {
     return;
    };
    setSnackBarStatus(prevState => ({ ...prevState, success: false}));
    setSnackBarStatus(prevState => ({ ...prevState, error: false }));
  };
  const addNewLayer = () => {
    let rawMaterialId = null;
    let material = null;
    const rmObj = rawMaterials.find(material => material.raw_material === coreData.rawMaterial);
    if(rmObj !== undefined) {
      rawMaterialId = rmObj.id;
      material = rmObj
    };
    
    let partId = null;
    let partNum = null;
    let partDescription = null;
    const partObj = parts.find(part => part.num === coreData.partNum);
    if(partObj !== undefined) {
      partId = partObj.id;
      partNum = partObj.num;
      partDescription = partObj.internal_desc;
    };
    
    const layer = {
      id: null,
      core_id: null,
      part_id: partId,
      core_layer: coreData.layers.length == 0 ? 0 : coreData.layers.length,
      layer_depth_in: parseFloat(parseFloat(coreData.depth).toFixed(2)),
      part: {
        id: partId,
        num: partNum,
        description: partDescription,
      },
      raw_material_id: rawMaterialId,
      material: material,
      topper: coreData.topper,
      convoluted: coreData.convoluted == true ? 1 : 0,
    };
    if(layer.convoluted == 1) {
      layer.convoluted_layer = {
        core_layer_id: null,
        actual_depth_in: coreData.actualDepth
      }
    };
    if(layer.part_id && layer.raw_material_id && layer.layer_depth_in) {
      setSnackBarStatus({success: true, successMessage: "LAYER SUCCESSFULLY CREATED!"})
      setCoreData(prevState => ({
        ...prevState,
        rawMaterial: "",
        partNum: "",
        depth: "",
        actualDepth: "",
        topper: false,
        convoluted: false,
        layers: [layer, ...prevState.layers],
        showNewLayer: false,
      }))
    }
  };
  const handleSubmitCore = async () => {
    let firmnessId = null;
    const firmnessObj = await firmnesses.find(item => item.firmness === coreData.firmness)
    if(firmnessObj !== undefined){
      firmnessId = firmnessObj.id
    };
    let parent_coreId = null;
    const parent_coreObj = await parentCores.find(item => item.parent_core.toLowerCase() === coreData.parent_core.toLowerCase())
    if(parent_coreObj !== undefined){
      parent_coreId = parent_coreObj.id
    };
    let sizeId = null;
    const sizeObj = await sizes.find(item => item.size === coreData.size)
    if(sizeObj !== undefined) {
      sizeId = sizeObj.id
    };
    let coreTypeId = null;
    const core_type_obj = coreTypes.find(item => item.core_firmness_id == firmnessId && item.parent_core_id == parent_coreId);
    if(core_type_obj !== undefined) {
      coreTypeId = core_type_obj.id
    };
    /* check and see if current size and firmness is the same as original core size and firmness */
    const validateCore = coreData.existing_cores.find(core => core.size_id === sizeId && core.type.core_firmness_id === firmnessId);
    if(validateCore) {
      setSnackBarStatus({error: true, message: "THIS CORE SIZE/FIRMNESS ALREADY EXISTS!"});
    } else {
      let orderedLayers = await coreData.layers.slice().reverse();
      let topperIncluded = 0;
      if(orderedLayers.length) {
        orderedLayers.forEach((l,index) => {
          if(l.topper == false){
            l.core_layer = index
        } else {
            if(topperIncluded == 0){
                l.core_layer = 99
                topperIncluded += 1
            } else if(topperIncluded == 1){
                l.core_layer = 999
                topperIncluded += 1
            } else if(topperIncluded == 2){
                l.core_layer = 9999
                topperIncluded += 1
            } else if(topperIncluded == 3){
                l.core_layer = 99999
                topperIncluded += 1
            }
        }
          delete l.topper;
        });

        let core = {
          id: null,
          core_type_id: coreTypeId != null ? coreTypeId : null,
          size_id: sizeId,
          layers: orderedLayers
        };
        let core_type_obj = null;
        if(core.core_type_id == null) {
          core_type_obj = {
            id: null,
            core_type: coreData.parent_core + ' ' + coreData.firmness.toUpperCase(),
            core_firmness_id: firmnessId,
            parent_core_id: parent_coreId
          }
        };
        if(core_type_obj != null) {
          core.core_type_obj = core_type_obj;
          await dispatch(saveCore(core))
            .then(res => {
              if(res.type == "CORE_SAVING_SUCCESS") {
                setModalState({
                  modalOpenStatus: true,
                  success: [
                    { title: "Success!"},
                    {
                      body: `Core successfully created for ${coreData.parent_core}! You will now
                      be redirected back to the parent core list.`
                    }
                  ],
                  redirect: `/mat-core/parentCore/${parent_coreId}`
                })
              }
            })
            .catch(err => console.log(err))
          
        } else {
          await dispatch(saveCore(core))
            .then(res => {
              if(res.type == "CORE_SAVING_SUCCESS") {
                setModalState({
                  modalOpenStatus: true,
                  success: [
                    { title: "Success!"},
                    {
                      body: `Core successfully created for ${coreData.parent_core}! You will now
                      be redirected back to the parent core list.`
                    }
                  ],
                  redirect: `/mat-core/parentCore/${parent_coreId}`
                })
              }
            })
            .catch(err => console.log(err))
        }
        
      } else if (!orderedLayers.length) {
        setSnackBarStatus({error: true, message: "YOU NEED TO ADD LAYERS BEFORE SUBMITTING!"})
      }

    };

  };
  
  useEffect(() => {
    getAllParentCores();
    getAllFirmness();
    getAllSizes();
    getRawMaterials();
    getAllParts();
    getCoreById();
    getAllCoreTypes();
    getAllCoresByParentId();
  }, []);
  return (
    <Box className='boxContainer-copyBomForm'>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.success}
        className="success"
        onClose={handleClose}
        autoHideDuration={5000}
        ContentProps={{
            'aria-describedby': 'message-id',
        }}
      >
        <Alert severity="success" >{snackbar.successMessage}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.error}
        className="error"
        onClose={handleClose}
        autoHideDuration={5000}
        ContentProps={{
            'aria-describedby': 'message-id',
        }}
      >
        <Alert severity="error" >{snackbar.message}</Alert>
      </Snackbar>
      <ConfirmationModal
       modalOpenStatus={modalState.modalOpenStatus}
       redirect={modalState.redirect}
       success={modalState.success}
      />
      <Box className='bomCopy-update-heading-box'>
        <Typography variant='h3' className='copyBom-header'>
          Add new core
        </Typography>
        <Typography variant="body1" className='bomCopy-core-warning'>
          MOVE LAYERS AROUND SO THAT THE TOP CORE LAYER IS AT THE TOP
          <br/>
          AND THE BOTTOM CORE LAYER IS AT THE BOTTOM
        </Typography>
      </Box>
      <Box className='bomCopy-parent-core-info-box'>
        <FormControl className="bomCopy-core-info-input" variant="outlined" >
          <TextField
              select
              margin='normal'
              label="Parent Core"
              variant='outlined'
              name="ParentCoreName"
              placeholder="Parent Core"
              value={coreData.parent_core}
              key={coreData.parent_core}
              input={<OutlinedInput name="parentCore" id="parentCore-placeholder"/>}
              onChange={(e) => setCoreData(prevState => ({...prevState, parent_core: e.target.value}))}
          >
            {parentCores.map((option) => 
              <MenuItem key={option.id} value={option.parent_core}>{option.parent_core}</MenuItem>
            )}
          </TextField>
        </FormControl >
        <FormControl className="bomCopy-core-info-input" variant="outlined">
        <TextField
            select
            margin='normal'
            label="Size"
            variant='outlined'
            name="sizeName"
            placeholder="Size"
            value={coreData.size}
            key={coreData.size}
            input={<OutlinedInput name="size" id="size-placeholder"/>}
            onChange={(e) => setCoreData(prevState => ({...prevState, size: e.target.value}))}
          >
            {sizes.map((option) =>
              <MenuItem key={option.id} value={option.size}>{option.size}</MenuItem>
            )}
          </TextField>
        </FormControl >
        <FormControl className="bomCopy-core-info-input" variant="outlined">
          <TextField
            select
            margin='normal'
            label="Firmness"
            variant='outlined'
            name="FirmnessName"
            placeholder="Firmness"
            value={coreData.firmness}
            key={coreData.firmness}
            input={<OutlinedInput name="firmness" id="firmness-placeholder"/>}
            onChange={(e) => setCoreData(prevState => ({...prevState, firmness: e.target.value}))}
          >
            {firmnesses.map((option) =>
              <MenuItem key={option.id} value={option.firmness}>{option.firmness}</MenuItem>
            )}
          </TextField>
        </FormControl >
      </Box>
      <CopyBomDraggableContext confirmLayerRemoval={confirmLayerRemoval} {...coreData} setCoreData={setCoreData}/>
      {!coreData.showNewLayer &&
        <FormControl className='bomCopy-new-layer-btn' >
          <Button margin="normal" variant="contained" onClick={handleShowHideNewLayer}>Add New Layer</Button>
        </FormControl>
      }
      {coreData.showNewLayer &&
        <CopyBomLayersForm 
        showNewLayer={coreData.showNewLayer} 
        setCoreData={setCoreData} 
        addNewLayer={addNewLayer}
        materials={rawMaterials} 
        parts={parts}
        {...coreData}
      />
      }
      {!coreData.showNewLayer &&
        <Box className='bomCopy-add-box'>
          <Typography variant="body1" className="bomCopy-core-warning">
            MAKE SURE LAYERS ARE IN THE CORRECT ORDER BEFORE UPDATING
          </Typography>
          <Button
            variant='contained'
            className='bomCopy-add-btn'
            onClick={handleSubmitCore}
          >
            add core
          </Button>
        </Box>
      }
    </Box >
  )
}

CopyBomForm.propTypes = {
  coreType: T.string,
  sizeName: T.string,
  size: T.object,
  type: T.object,
  layers: T.array,
  coreTypes: T.array,
  sizes: T.array,
  coreLayer: T.number,
  layerDepthIn: T.number,
  rawMaterial: T.string,
  part: T.string,
  rawMaterialId: T.number,
  material: T.object,
  rawMaterials: T.array,
  parts: T.array,
  parentCores: T.array,
  firmnesses: T.array
}

function mapStateToProps(state) {
  const { 
    coreReducer, 
    coreTypeReducer, 
    sizeReducer, 
    coreLayerReducer, 
    coreFirmnessReducer, 
    coreRawMaterialReducer, 
    partReducer, 
    parentCoreReducer } = state
  return {
    size: coreReducer.size,
    type: coreReducer.type,
    layers: coreReducer.layers,
    coreTypes: coreTypeReducer.coreTypes,
    sizes: sizeReducer.sizes,
    coreLayer: coreLayerReducer.coreLayer,
    layerDepthIn: coreLayerReducer.layerDepthIn,
    rawMaterialId: coreLayerReducer.rawMaterialId,
    rawMaterial: coreLayerReducer.rawMaterial,
    material: coreLayerReducer.material,
    rawMaterials: coreRawMaterialReducer.coreRawMaterials,
    parts: partReducer.parts,
    parentCores: parentCoreReducer.parentCores,
    firmnesses: coreFirmnessReducer.firmnesses,
  }
}

export default withRouter(connect(mapStateToProps)(CopyBomForm))