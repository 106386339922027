
import {
    FABRIC_QUILT_PATTERN_UPDATE_FIELD,
    FABRIC_QUILT_PATTERN_LOADING_REQUEST,
    FABRIC_QUILT_PATTERN_LOADING_SUCCESS,
    FABRIC_QUILT_PATTERN_LOADING_ERROR,
    FABRIC_QUILT_PATTERN_SAVING_REQUEST,
    FABRIC_QUILT_PATTERN_SAVING_SUCCESS,
    FABRIC_QUILT_PATTERN_SAVING_ERROR,
    FABRIC_QUILT_PATTERNS_LOADING_ERROR,
    FABRIC_QUILT_PATTERNS_LOADING_REQUEST,
    FABRIC_QUILT_PATTERNS_LOADING_SUCCESS,
    FABRIC_QUILT_PATTERNS_SAVING_ERROR,
    FABRIC_QUILT_PATTERNS_SAVING_REQUEST,
    FABRIC_QUILT_PATTERNS_SAVING_SUCCESS
} from '../constants/fabricQuiltPatternTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    fabricQuiltPatterns: [],
    columns: [],
    rows: [],
    id: -1,
    fabricQuiltPattern: '',
}

export default function fabricQuiltPatternReducer(state = initialState, action) {
    switch (action.type) {
        case FABRIC_QUILT_PATTERN_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case FABRIC_QUILT_PATTERNS_LOADING_REQUEST:
        case FABRIC_QUILT_PATTERNS_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case FABRIC_QUILT_PATTERN_LOADING_REQUEST:
        case FABRIC_QUILT_PATTERN_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case FABRIC_QUILT_PATTERN_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                fabricQuiltPattern: action.payload.fabric_quilt_pattern,
            }
        case FABRIC_QUILT_PATTERN_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: null,
                fabricQuiltPattern: '',
            }
        case FABRIC_QUILT_PATTERNS_LOADING_ERROR:
        case FABRIC_QUILT_PATTERNS_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case FABRIC_QUILT_PATTERN_LOADING_ERROR:
        case FABRIC_QUILT_PATTERN_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case FABRIC_QUILT_PATTERNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                fabricQuiltPatterns: action.payload,
            }
        case FABRIC_QUILT_PATTERNS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                fabricQuiltPatterns: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            const columns = parseAllToJson(action.payload, 'options')
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: columns,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}