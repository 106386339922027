
import {
    CUSTOMER_UPDATE_FIELD,
    CUSTOMERS_LOADING_REQUEST,
    CUSTOMERS_LOADING_SUCCESS,
    CUSTOMERS_LOADING_ERROR,
    CUSTOMER_IMPORT_LOADING_REQUEST,
    CUSTOMER_IMPORT_LOADING_SUCCESS,
    CUSTOMER_IMPORT_LOADING_ERROR,
    CUSTOMERS_SAVING_REQUEST,
    CUSTOMERS_SAVING_SUCCESS,
    CUSTOMERS_SAVING_ERROR,
} from '../constants/customerTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    customers: [],
    rows: [],
    cols: [],
    columns: [],
    file: '',
}

export default function customerListReducer(state = initialState, action) {
    switch (action.type) {
        case CUSTOMER_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case CUSTOMERS_LOADING_REQUEST:
        case CUSTOMER_IMPORT_LOADING_REQUEST:
        case CUSTOMERS_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case CUSTOMERS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                customers: action.payload,
            }
        case CUSTOMER_IMPORT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                customers: action.customers,
                rows: action.rows,
                cols: action.cols,
            }
        case CUSTOMERS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                customers: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        case CUSTOMERS_LOADING_ERROR:
        case CUSTOMER_IMPORT_LOADING_ERROR:
        case CUSTOMERS_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}