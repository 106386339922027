import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { connect } from "react-redux";
import MainNavLink from "./MainNavLink";
import SubNavLink from "./SubNavLink";

import "../../styles/main.css";
import {
  faCalendarAlt,
  faCogs,
  faGavel,
  faHome,
  faPowerOff,
  faBarcode,
  faDolly,
  faShippingFast,
  faMoneyCheckAlt,
  faStore,
  faStoreAlt,
  faChartLine,
  faWarehouse
} from "@fortawesome/free-solid-svg-icons";

import Can from "../Can";
import { NavBar } from "./NavBar";

class Header extends React.Component {
  render() {
    let empNum = localStorage.getItem("employeeNum");
    var isScanner = String(empNum).slice(0, 3);
    // String(isScanner).slice(0, 3)
    if (isScanner.toUpperCase().includes("E2C")) {
      return false;
    }
    return this.props.userId !== "" ? (
      <AppBar
        position="static"
        color="primary"
        style={{ backgroundColor: "#163762" }}
      >
        <Toolbar style={{ minHeight: "1px" }}>
          <MainNavLink route="/" label="Did It Ship?" className="no-active" />
          {/* <Can
            role={this.props.role}
            perform="piece-page:visit"
            yes={() => (
              <MainNavLink
                route="/piece-rate"
                label="Piece Rate"
                icon={faMoneyCheckAlt}
              />
            )}
            no={() => null}
          /> */}
          <Can
            role={this.props.role}
            perform="scan-page:visit"
            yes={() => (
              <MainNavLink
                route="/production-scan"
                label="Production Scan"
                icon={faWarehouse}
              />
            )}
            no={() => null}
          />
          <Can
            role={this.props.role}
            perform="production-schedule-page:visit"
            yes={() => (
              <MainNavLink
                route={"#"}
                label="Production Schedule"
                icon={faCalendarAlt}
                className="no-active"
              >
                <SubNavLink
                  label={"Production Schedule"}
                  route={"/schedule"}
                  className={"batch"}
                />
                <SubNavLink
                  label={"Batch Manager"}
                  route={"/batch"}
                  className={"batch"}
                />
                <SubNavLink
                  label={"Batch Assignment"}
                  route={"/sku-batch"}
                  className={"batch"}
                />
              </MainNavLink>
            )}
            no={() => null}
          />
          <Can
            role={this.props.role}
            perform="skus-page:visit"
            yes={() => (
              <MainNavLink
                label={"Law Tag Data"}
                route={"#"}
                icon={faGavel}
                className="no-active"
              >
                <SubNavLink
                  label={"Customers"}
                  route={"/customers"}
                  className={"law-tag"}
                />
                <SubNavLink
                  label={"Manufacturers"}
                  route={"/manufacturers"}
                  className={"law-tag"}
                />
                <SubNavLink
                  label={"Foundation Groups"}
                  route={"/lt/foundation/groups"}
                  className={"law-tag"}
                />
                <SubNavLink
                  label={"Foundations"}
                  route={"/foundations"}
                  className={"law-tag"}
                />
                <SubNavLink
                  label={"Material Groups"}
                  route={"/lt/material/groups"}
                  className={"law-tag"}
                />
                <SubNavLink
                  label={"Materials"}
                  route={"/materials"}
                  className={"law-tag"}
                />
                <SubNavLink
                  label={"Prototypes"}
                  route={"/prototypes"}
                  className={"law-tag"}
                />
                <SubNavLink
                  label={"Law Tag Viewer"}
                  route={"/lawtag/viewer"}
                  className={"law-tag"}
                />
              </MainNavLink>
            )}
            no={() => null}
          />
          <Can
            role={this.props.role}
            perform="skus-page:visit"
            yes={() => (
              <MainNavLink
                label={"Sku Data"}
                route={"#"}
                icon={faBarcode}
                className="no-active"
              >
                <SubNavLink label={"Skus"} route={"/skus"} className={"sku"} />
                <SubNavLink
                  label={"Brands"}
                  route={"/brands"}
                  className={"sku"}
                />
                <SubNavLink
                  label={"Families"}
                  route={"/families"}
                  className={"sku"}
                />
                {/*<SubNavLink label={"Boxes"} route={"/boxes"} className={"sku"}/>*/}
                <SubNavLink
                  label={"Sizes"}
                  route={"/sizes"}
                  className={"sku"}
                />
                {/* <SubNavLink
                  label={"Cores"}
                  route={"/cores"}
                  className={"sku"}
                /> */}
                {/*<SubNavLink label={"Core Material"} route={"/mat-core/materials"} className={"sku"}/>*/}
                <SubNavLink
                  label={"Parent Cores"}
                  route={"/mat-core/parentCores"}
                  className={"sku"}
                />
                <SubNavLink
                  label={"Panels"}
                  route={"/panels"}
                  className={"sku"}
                />
                <SubNavLink
                  label={"Quilt Patterns"}
                  route={"/fabric/quilt/patterns"}
                  className={"sku"}
                />
                <SubNavLink
                  label={"SKU Labor Cost"}
                  route={"/labor"}
                  className={"sku"}
                />
                <SubNavLink
                  label={"SKU Shipping Cost"}
                  route={"/sku-shipping"}
                  className={"sku"}
                />
                <SubNavLink
                  label={"Item where used"}
                  route={"/sku-item"}
                  className={"sku"}
                />
                {/*<SubNavLink label={"Fabric Uses"} route={"/fabric/uses"} className={"sku"}/>*/}
              </MainNavLink>
            )}
            no={() => null}
          />
          {/* <MainNavLink
            label={"Production Tracker"}
            route={"/report/productiontracker"}
            icon={faBarcode}
          /> */}
          <Can
            role={this.props.role}
            perform="inventory-page:visit"
            yes={() => (
              <MainNavLink
                route={"#"}
                label={"Inventory"}
                icon={faDolly}
                className="no-active"
              >
                <SubNavLink
                  label={"Cycle Count"}
                  route={"/cycle/count"}
                  className={"inventory"}
                />
                <SubNavLink
                  label={"Fishbowl Reprocessing"}
                  route={"/fishbowl/reprocessing"}
                  className={"inventory"}
                />
              </MainNavLink>
            )}
            no={() => null}
          />
          <Can
            role={this.props.role}
            perform="dealer-page:visit"
            yes={() => (
              <MainNavLink
                route={"#"}
                label={"Dealer"}
                icon={faStoreAlt}
                className="no-active"
              >
                <SubNavLink
                  label={"Dealar Table"}
                  route={"/dealers"}
                  className={"dealer"}
                />
              </MainNavLink>
            )}
            no={() => null}
          />
          <Can
            role={this.props.role}
            perform="shipping-page:visit"
            yes={() => (
              <MainNavLink
                route={"#"}
                label={"Shipping"}
                icon={faShippingFast}
                className="no-active"
              >
                <SubNavLink
                  label={"Shipping"}
                  route={"/shipping"}
                  className={"shipping"}
                />
                <SubNavLink
                  label={"Sku Queue"}
                  route={"/skuqueue"}
                  className={"shipping"}
                />
              </MainNavLink>
            )}
            no={() => null}
          />
          <Can
            role={this.props.role}
            perform="settings-page:visit"
            yes={() => (
              <MainNavLink
                route={"#"}
                label={"Settings"}
                icon={faCogs}
                className="no-active"
              >
                <SubNavLink
                  label={"Users"}
                  route={"/users"}
                  className={"setting"}
                />
              </MainNavLink>
            )}
            no={() => null}
          />
          <Can
            role={this.props.role}
            perform="retail-page:visit"
            yes={() => (
              <MainNavLink
                route={"#"}
                label={"Retail"}
                icon={faStore}
                className="no-active"
              >
                <SubNavLink
                  label={"Commissions Managment"}
                  route={"/commission"}
                  className={"commission"}
                />
              </MainNavLink>
            )}
            no={() => null}
          />
          <Can
            role={this.props.role}
            perform="forecast-page:visit"
            yes={() => (
              <MainNavLink
                route={"#"}
                label={"Forecast"}
                icon={faChartLine}
                className="no-active"
              >
                {/* <SubNavLink
                  label={"Campaign Upload"}
                  route={"/campaign-upload"}
                  className={"campaign_upload"}
                />
                <SubNavLink
                  label={"Campaign Curve Upload"}
                  route={"/campaign-curve-upload"}
                  className={"campaign_curve_upload"}
                />
                <SubNavLink
                  label={"Forecast Upload"}
                  route={"/Forecast-upload"}
                  className={"forecast_upload"}
                /> */}
                <SubNavLink
                  label={"Forecast Upload"}
                  route={"/forecast-page"}
                  className={"forecast_upload"}
                />
              </MainNavLink>
            )}
            no={() => null}
          />
          <MainNavLink route="/logout" label="Logout" icon={faPowerOff} />
        </Toolbar>
      </AppBar>
    ) : (
      ""
    );
  }
}

Header.propTypes = {};

function mapStateToProps(state) {
  const { loginForm } = state;
  return {
    role: loginForm.userRole,
    userId: loginForm.userId,
    active: loginForm.active
  };
}
export default connect(mapStateToProps)(Header);
