
export const FOUNDATION_UPDATE_FIELD = 'FOUNDATION_UPDATE_FIELD'

export const FOUNDATION_LOADING_REQUEST = 'FOUNDATION_LOADING_REQUEST'
export const FOUNDATION_LOADING_SUCCESS = 'FOUNDATION_LOADING_SUCCESS'
export const FOUNDATION_LOADING_ERROR = 'FOUNDATION_LOADING_ERROR'

export const FOUNDATIONS_LOADING_REQUEST = 'FOUNDATIONS_LOADING_REQUEST'
export const FOUNDATIONS_LOADING_SUCCESS = 'FOUNDATIONS_LOADING_SUCCESS'
export const FOUNDATIONS_LOADING_ERROR = 'FOUNDATIONS_LOADING_ERROR'

export const FOUNDATION_IMPORT_LOADING_REQUEST = 'FOUNDATION_IMPORT_LOADING_REQUEST'
export const FOUNDATION_IMPORT_LOADING_SUCCESS = 'FOUNDATION_IMPORT_LOADING_SUCCESS'
export const FOUNDATION_IMPORT_LOADING_ERROR = 'FOUNDATION_IMPORT_LOADING_ERROR'

export const FOUNDATION_SAVING_REQUEST = 'FOUNDATION_SAVING_REQUEST'
export const FOUNDATION_SAVING_SUCCESS = 'FOUNDATION_SAVING_SUCCESS'
export const FOUNDATION_SAVING_ERROR = 'FOUNDATION_SAVING_ERROR'

export const FOUNDATIONS_SAVING_REQUEST = 'FOUNDATIONS_SAVING_REQUEST'
export const FOUNDATIONS_SAVING_SUCCESS = 'FOUNDATIONS_SAVING_SUCCESS'
export const FOUNDATIONS_SAVING_ERROR = 'FOUNDATIONS_SAVING_ERROR'
