
import {
    MATERIAL_UPDATE_FIELD,
    MATERIALS_LOADING_REQUEST,
    MATERIALS_LOADING_SUCCESS,
    MATERIALS_LOADING_ERROR,
    MATERIAL_IMPORT_LOADING_REQUEST,
    MATERIAL_IMPORT_LOADING_SUCCESS,
    MATERIAL_IMPORT_LOADING_ERROR,
    MATERIALS_SAVING_REQUEST,
    MATERIALS_SAVING_SUCCESS,
    MATERIALS_SAVING_ERROR,
} from '../constants/materialTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    materials: [],
    rows: [],
    cols: [],
    columns: [],
    file: '',
}

export default function materialListReducer(state = initialState, action) {
    switch (action.type) {
        case MATERIAL_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case MATERIALS_LOADING_REQUEST:
        case MATERIAL_IMPORT_LOADING_REQUEST:
        case MATERIALS_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case MATERIALS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                materials: action.payload,
            }
        case MATERIAL_IMPORT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                materials: action.materials,
                rows: action.rows,
                cols: action.cols,
            }
        case MATERIALS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                materials: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        case MATERIALS_LOADING_ERROR:
        case MATERIAL_IMPORT_LOADING_ERROR:
        case MATERIALS_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}