import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import T from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Box, Card, Modal, Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button'
//import "../style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { updateField, updateDialogOpen, updateDialogClose, updateDialogAccepted, importCycleCount, getSkuByBrand, getSkuCycleCount } from "../actions/fishbowlActions"
import { getDBFormattedDate } from "../utils/dates";
import {getBrands} from "../actions/brandActions";


class CycleCountModal extends React.Component {

  constructor(props) {
    super(props)
        // Bind the this context to the handler function
    this.handleChange = this.handleChange.bind(this);
  }

  // Both functions handle what happens with the modal if it's closed/changed
  handleChange = e => {
    const {dispatch } = this.props
    dispatch(updateField(e.target.name, e.target.value))
  }

  handleClose = e => {
    const { dispatch } = this.props;
    dispatch(updateDialogClose())
  }

  // Handles what happens when the user confirms. Will upload to Fishbowl and go about rerendering the scene.
  handleConfirmation = async e => {
    const { isError, dispatch,  object, locationGroupName, locationName, userId, deviceId, date } = this.props;
    await dispatch(importCycleCount(object))
    if(!isError && locationName === 'Retail') {
      await this.handleLocationResult()
      await this.handleClose()
      await window.scrollTo({top: 0})
    } else if (!isError && locationName != 'Retail') {
      await this.handleLocationResult()
      await this.handleClose()
      await window.scrollTo({top: 0})
    }
  }

    handleLocationResult = async () => {
        const {dispatch, locationName, locationGroups, userId, locationGroupName, deviceId, cycleCount} = this.props
        //await dispatch(updateField('brandName', ''))
        if(locationName == 'X Stock' || 'Q Stock'){
            //known brand id for brooklyn bedding skus bc when retail location is selected it is automatically BB skus
            await dispatch(getSkuCycleCount(10, locationName, userId, deviceId))
            await this.handleCycleCountDisplay()
        }
    }

  handleCycleCountDisplay = async () => {
      const {dispatch, cycleCount } = this.props
      let cycleCountDisplay = [];
      await cycleCount.forEach(s => {
          let skuObj = {
              "PartNumber": s.part_num,
              "fbQty": Math.round(s.qty),
              "Qty": ''
          }
          cycleCountDisplay.push(skuObj)
      })
      cycleCountDisplay.shift();
      await dispatch(updateField('cycleCountDisplay', cycleCountDisplay))
      cycleCountDisplay.length === 0 ? this.setState({noActiveSkusError: true}) : ''
  }

  handleChangesList = async() => {
    const {dispatch, cycleCountDisplay } = this.props
    let changesList = [];
    await cycleCountDisplay.forEach(cc => {
      if (cc.Qty != '') {
        let obj = 
        <li>{cc.PartNumber} is being changed from {cc.fbQty} to {cc.Qty}</li>
        changesList.push(obj)
      }
    })
    console.log(changesList)
    return (changesList)
  }



// Renders the display (logic and JavasScript)
  render() {
    const {
      isLoading,
      isError,
      isOpen,
      object,
      locationGroupName,
      locationName,
      userId,
      deviceId,
      date,
      cycleCountDisplay
    } = this.props;

    // Body text
    const successFields = [
      {title: "Warning - About to change Cycle Count Quantity"},
      {body: "Are you sure you want to change the quantity?"}
    ]

    // CSS styling
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4
    };

    const buttonStyle = {
      position: "relative",
      width: "auto",
      top: "50%",
      left: "30%",
      margin: "10px"
    };

    const modalStyle = {
      position: "relative",
      background: "yellow",
    }

    const changesStyles = {
      position: "relative",
      left: "15%",
    }

    function ChangesList() {
      let changesList = [];
      cycleCountDisplay.forEach(cc => {
        if (cc.Qty != '') {
          let obj = 
          <li><b>{cc.PartNumber}</b> is being from {cc.fbQty} to {cc.Qty}</li>
          changesList.push(obj)
        }
      })
      console.log(changesList)
      return (changesList)
    }

    // testing variable to keep the modal opened. Replace 'isOpen' with 'keepOpen' to use.
    let keepOpen = true


    // HTML & CSS for the display
    return (
      <>
      {successFields ? (
        <>
        <Modal
        open={isOpen}
        onClose={this.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box style={style}>
        <Card
        style={{ overflow: "auto", height: "auto", width: "500px", paddingBottom: "25px" }}
        >
        <div className="modal-title" style={modalStyle}>
        <Typography
        className="modal-typo-title"
        variant="h5"
        component="h2"
        >
        <FontAwesomeIcon
        icon={faExclamationTriangle}
        size="3x"
        color="white"
        />
        </Typography>
        </div>
        {successFields.map((field) => (
          <div className="modal-description">
          <Typography id="modal-modal-title" variant="h4" key={field.title}>
          {field.title}
          </Typography>
          <Typography key={field.body} className="modal-typo-description">
          {field.body}
          </Typography>
          </div>
          ))}
        <Button id="confirmation" variant="contained" style={buttonStyle} onClick={this.handleConfirmation}>Confirm</Button>
        <Button id="deny" variant="contained" style={buttonStyle} onClick={this.handleClose}>Go Back</Button>
        </Card>
        </Box>
        </Modal>
        </>
        ) : (
        ""
        )}
        </>
  ); // End of Return
  }
};

function mapStateToProps(state) {
  const { fishbowlReducer, loginForm, brandReducer } = state
  return {
    isError: fishbowlReducer.isError,
    saveSuccess: fishbowlReducer.saveSuccess,
    locationGroups: fishbowlReducer.locationGroups,
    locationGroupName: fishbowlReducer.locationGroupName,
    locations: fishbowlReducer.locations,
    locationName: fishbowlReducer.locationName,
    brandName: fishbowlReducer.brandName,
    cycleCount: fishbowlReducer.cycleCount,
    skusByBrand: fishbowlReducer.skusByBrand,
    cycleCountDisplay: fishbowlReducer.cycleCountDisplay,
    filteredCycleCount: fishbowlReducer.filteredCycleCount,
    skuNamesByBrand: fishbowlReducer.skuNamesByBrand,
    deviceId: fishbowlReducer.deviceId,
    isOpen: fishbowlReducer.isOpen,
    object: fishbowlReducer.object,
    userId: loginForm.userId,
    brands: brandReducer.brands
  }
}

CycleCountModal.defaultProps = {
  date: getDBFormattedDate()
}


CycleCountModal.propTypes = {
  isLoading: T.bool,
  isError: T.bool,
  locationGroups: T.array,
  locationGroup: T.string,
  locations: T.array,
  locationName: T.string,
  brandName: T.string,
  cycleCount: T.array,
  skusByBrand: T.array,
  isOpen: T.bool,
  object: T.object
}

export default withRouter(connect(mapStateToProps)(CycleCountModal));
