import * as types from '../constants/foundationGroupTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.FOUNDATION_GROUP_UPDATE_FIELD,
        name,
        value,
    }
}
export function addFoundation(foundation, groupFoundations, foundations) {
    const removeIndex = foundations.map(f => {return f.id}).indexOf(foundation.id)
    let newFoundations = [...foundations]
    newFoundations.splice(removeIndex, 1);
    return {
        type: types.ADD_FOUNDATION,
        groupFoundations: groupFoundations.concat(foundation),
        newFoundations: newFoundations,
    }
}
export function foundationAdded() {
    return {
        type: types.FOUNDATION_ADDED,
    }
}
export function removeFoundation(foundation, groupFoundations, foundations) {
    const removeIndex = groupFoundations.map(f => {return f.id}).indexOf(foundation.id)
    let newFoundations = [...groupFoundations]
    newFoundations.splice(removeIndex, 1);
    return {
        type: types.REMOVE_FOUNDATION,
        groupFoundations: newFoundations,
        newFoundations: foundations.concat(foundation),
    }
}
export function foundationRemoved() {
    return {
        type: types.FOUNDATION_REMOVED,
    }
}

export function getFoundations(groupId) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/foundation/notin/${groupId}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FG_FOUNDATIONS_LOADING_REQUEST,
                types.FG_FOUNDATIONS_LOADING_SUCCESS,
                types.FG_FOUNDATIONS_LOADING_ERROR,
            ],
        },
    }
}

export function getFoundationGroups() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/foundation/groups`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FOUNDATION_GROUPS_LOADING_REQUEST,
                types.FOUNDATION_GROUPS_LOADING_SUCCESS,
                types.FOUNDATION_GROUPS_LOADING_ERROR,
            ],
        },
    }
}

export function getFoundationGroup(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/foundation/group/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FOUNDATION_GROUP_LOADING_REQUEST,
                types.FOUNDATION_GROUP_LOADING_SUCCESS,
                types.FOUNDATION_GROUP_LOADING_ERROR,
            ],
        },
    }
}

export function saveFoundationGroupList(foundationGroups){
    const data = []
    for (let field in foundationGroups.data) {
        let row = removeEmptyFields(foundationGroups.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/foundation/groups`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FOUNDATION_GROUPS_SAVING_REQUEST,
                types.FOUNDATION_GROUPS_SAVING_SUCCESS,
                types.FOUNDATION_GROUPS_SAVING_ERROR,
            ],
        },
    }
}

export function saveFoundationGroup(foundationGroup){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/foundation/group`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(foundationGroup),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FOUNDATION_GROUP_SAVING_REQUEST,
                types.FOUNDATION_GROUP_SAVING_SUCCESS,
                types.FOUNDATION_GROUP_SAVING_ERROR,
            ],
        },
    }
}