import React from 'react';
import {connect} from 'react-redux';
import ScanList from './List/ScansList';
import '../styles/about-page.css';

export class DashboardPage extends React.Component {

  render() {
    return (
      <ScanList
      />
    );
  }
}

DashboardPage.propTypes = {

};

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);
