import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  Modal,
  Box
} from "@material-ui/core";
import moment from "moment";
import "./style.css";

const FailedScanModal = (props) => {
  const [total, setTotal] = useState(props.passed.length);
  const [failedTotal, setFailedTotal] = useState(props.failed.length);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [invalid, setInvalid] = useState(props.invalid);

  useEffect(() => {
    setOpen(props.show);
    setFailedTotal(props.failed.length + props.invalid.length);
    setTotal(props.passed.length);
    if (props.show === true) {
      sortData();
    }
  }, [props.show]);

  const handleClose = () => {
    setOpen(false);
    props.close(true);
  };
  const sortData = () => {
    const data = props.failed;

    data.map((failed) => {
      if (failed.wrong_truck != undefined) {
        failed["reason"] = `Wrong Truck# ${failed.wrong_truck}`;
      } else if (failed.skipped_stop != undefined) {
        failed["reason"] = `${failed.skipped_stop}`;
      }
      let failedScans = failed.scans;
      if (failedScans.length > 0) {
        let lastScanInfo = failedScans[0];
        let lastUserScanned = failedScans[0].user;
        let firstName = lastUserScanned.first_name;
        let lastName = lastUserScanned.last_name;
        let date = lastScanInfo.date;
        let sku = lastScanInfo.rfid_scan;
        let truck_id = lastScanInfo.truck_id;
        if (lastScanInfo) {
          failed["reason"] = `${sku} - Scanned ${moment(date).format(
            "MM-DD-YYYY hh:mm A"
          )} by ${firstName} ${lastName}`;
        } else {
          failed["reason"] = "fkjsdlkfas";
        }
      }
    });
    setTableData(data);
    const inv = props.invalid;
    if (inv) {
      setInvalid(props.invalid);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{
          top: `${5}%`,
          margin: "auto",
          width: "75%",
          overflow: "scroll",
          bottom: `${5}%`
        }}
      >
        <Box
          p={1}
          sx={{ borderColor: "primary.main", backgroundColor: "white" }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1050 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="table_cell_passed" width="25%">
                    &nbsp;TOTAL SHIPPED
                  </TableCell>
                  <TableCell className="table_cell_passed">
                    &nbsp;{total}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell className="table_cell" width="25%">
                    FAILED TOTAL
                  </TableCell>
                  <TableCell className="table_cell">
                    &nbsp;{failedTotal}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="left" className="tableRow_data">
                      {row.serial_number}
                    </TableCell>
                    <TableCell align="left" className="tableRow_data">
                      {row.reason}
                    </TableCell>
                  </TableRow>
                ))}
                {invalid.map((inv) => (
                  <TableRow key={inv.name}>
                    <TableCell align="left" className="tableRow_data">
                      {inv.invalid}
                    </TableCell>
                    <TableCell align="left" className="tableRow_data">
                      Reason: {inv.reason}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
};

export default FailedScanModal;
