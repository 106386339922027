import { Container, Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import MainNavPage from "./header/MainNavPage";

class HomePage extends React.Component {
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    return this.props.userRole.includes("scan") ? (
      //   <Redirect to={{ pathname: "/piece-rate" }} />
      // ) : this.props.userRole.includes("scan") ? (
      <Redirect to={{ pathname: "/production" }} />
    ) : (
      <>
        <div style={{ height: "100vh" }}>
          <h4>
            WELCOME TO DIDITSHIP where we answer the question.... (in your best
            game show audience voice) DID IT SHIP?
          </h4>
          <Grid container justifyContent="center">
            <Grid item xs={3} style={{ marginLeft: "-85px" }}>
              <img
                src="https://cdn.shopify.com/s/files/1/0335/2136/9133/t/15/assets/header-logo__desktop.svg?v=20026100176492188111643134370"
                style={{
                  marginTop: "100px",
                  marginLeft: "-350px",
                  marginRight: "auto",
                  width: "300%",
                  height: "auto",
                  display: "block"
                }}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { loginForm } = state;
  return {
    userRole: loginForm.userRole
  };
}

export default connect(mapStateToProps)(HomePage);
