import * as types from '../constants/fishbowlTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.CYCLE_COUNT_UPDATE_FIELD,
        name,
        value,
    }
}

export function updateDialogOpen(object) {
    return {
        type: types.DIALOG_OPEN,
        payload: object
    }
}

export function updateDialogClose() {
    return {
        type: types.DIALOG_DENIED,
    }
}

export function updateDialogAccepted() {
    return {
        type: types.DIALOG_ACCEPTED,
    }
}

export function resetCycleCount(){
    return {
        type: types.RESET_CYCLE_COUNT,
    }
}

export function getLocationGroups(userId, deviceId) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fishbowl/api/locationgroup/${userId}/${deviceId}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.LOCATION_GROUPS_LOADING_REQUEST,
                types.LOCATION_GROUPS_LOADING_SUCCESS,
                types.LOCATION_GROUPS_LOADING_ERROR,
            ],
        },
    }
}

export function getLocations(group, userId, deviceId) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fishbowl/api/location/${group}/${userId}/${deviceId}`, // TODO: make base URL constant
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.LOCATIONS_LOADING_REQUEST,
                types.LOCATIONS_LOADING_SUCCESS,
                types.LOCATIONS_LOADING_ERROR,
            ],
        },
    }
}

export function getSkuByBrand(id){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/sku/active/brand/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.SKU_BY_BRAND_LOADING_REQUEST,
                types.SKU_BY_BRAND_LOADING_SUCCESS,
                types.SKU_BY_BRAND_LOADING_ERROR,
            ],
        },
    }
}



export function getSkuCycleCount(group, name, userId, deviceId){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fishbowl/api/cyclecount/location/${group}/${name}/${userId}/${deviceId}/`, // TODO: make base URL constant
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.SKU_CYCLECOUNT_LOADING_REQUEST,
                types.SKU_CYCLECOUNT_LOADING_SUCCESS,
                types.SKU_CYCLECOUNT_LOADING_ERROR,
            ],
        },
    }
}

export function importCycleCount(count){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fishbowl/api/cyclecount/import`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(count),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CYCLE_COUNT_IMPORT_REQUEST,
                types.CYCLE_COUNT_IMPORT_SUCCESS,
                types.CYCLE_COUNT_IMPORT_ERROR,
            ],
        },
    }
}

export function getErrorLogs(){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fishbowl/api/error/open`, // TODO: make base URL constant
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.ERROR_OPEN_REQUEST,
                types.ERROR_OPEN_SUCCESS,
                types.ERROR_OPEN_ERROR,
            ],
        },
    }
}

export function closeError(error){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fishbowl/api/error/close`, // TODO: make base URL constant
            method: 'PUT',
            body: JSON.stringify(error),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.ERROR_CLOSE_REQUEST,
                types.ERROR_CLOSE_SUCCESS,
                types.ERROR_CLOSE_ERROR,
            ],
        },
    }
}

export function doReprocess(error){
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/fishbowl/api/reprocess/do`, // TODO: make base URL constant
      method: 'POST',
      body: JSON.stringify(error),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      options: {
        mode: 'cors',
      },
      types: [
        types.REPROCESS_REQUEST,
        types.REPROCESS_SUCCESS,
        types.REPROCESS_ERROR,
      ],
    },
  }
}

export function updateCoreLayerByFishbowl_part(update) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fishbowl/update-core_layer-part/${update.partId}`, // TODO: make base URL constant
            method: 'PUT',
            body: JSON.stringify(update),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.UPDATE_CORE_LAYER_FISHBOWL_PART_REQUEST,
                types.UPDATE_CORE_LAYER_FISHBOWL_PART_SUCCESS,
                types.UPDATE_CORE_LAYER_FISHBOWL_PART_ERROR,
            ],
        },
    }
}

export function updatePanelLayerByFishbowl_part(update) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fishbowl/update-panel_layer-part/${update.partId}`, // TODO: make base URL constant
            method: 'PUT',
            body: JSON.stringify(update),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.UPDATE_PANEL_LAYER_FISHBOWL_PART_REQUEST,
                types.UPDATE_PANEL_LAYER_FISHBOWL_PART_SUCCESS,
                types.UPDATE_PANEL_LAYER_FISHBOWL_PART_ERROR,
            ],
        },
    }
}