export const FIRMNESS_UPDATE_FIELD ='FIRMNESS_UPDATE_FIELD'

export const FIRMNESS_LOADING_REQUEST = 'FIRMNESS_LOADING_REQUEST'
export const FIRMNESS_LOADING_SUCCESS = 'FIRMNESS_LOADING_SUCCESS'
export const FIRMNESS_LOADING_ERROR = 'FIRMNESS_LOADING_ERROR'

export const FIRMNESSES_LOADING_REQUEST = 'FIRMNESSES_LOADING_REQUEST'
export const FIRMNESSES_LOADING_SUCCESS = 'FIRMNESSES_LOADING_SUCCESS'
export const FIRMNESSES_LOADING_ERROR = 'FIRMNESSES_LOADING_ERROR'

export const FIRMNESS_SAVING_REQUEST = 'FIRMNESS_SAVING_REQUEST'
export const FIRMNESS_SAVING_SUCCESS = 'FIRMNESS_SAVING_SUCCESS'
export const FIRMNESS_SAVING_ERROR = 'FIRMNESS_SAVING_ERROR'

export const FIRMNESSES_SAVING_REQUEST = 'FIRMNESSES_SAVING_REQUEST'
export const FIRMNESSES_SAVING_SUCCESS = 'FIRMNESSES_SAVING_SUCCESS'
export const FIRMNESSES_SAVING_ERROR = 'FIRMNESSES_SAVING_ERROR'

