
export const WEIGHT_UPDATE_FIELD = 'WEIGHT_UPDATE_FIELD'

export const WEIGHT_LOADING_REQUEST = 'WEIGHT_LOADING_REQUEST'
export const WEIGHT_LOADING_SUCCESS = 'WEIGHT_LOADING_SUCCESS'
export const WEIGHT_LOADING_ERROR = 'WEIGHT_LOADING_ERROR'

export const WEIGHTS_LOADING_REQUEST = 'WEIGHTS_LOADING_REQUEST'
export const WEIGHTS_LOADING_SUCCESS = 'WEIGHTS_LOADING_SUCCESS'
export const WEIGHTS_LOADING_ERROR = 'WEIGHTS_LOADING_ERROR'

export const WEIGHT_IMPORT_LOADING_REQUEST = 'WEIGHT_IMPORT_LOADING_REQUEST'
export const WEIGHT_IMPORT_LOADING_SUCCESS = 'WEIGHT_IMPORT_LOADING_SUCCESS'
export const WEIGHT_IMPORT_LOADING_ERROR = 'WEIGHT_IMPORT_LOADING_ERROR'

export const WEIGHT_SAVING_REQUEST = 'WEIGHT_SAVING_REQUEST'
export const WEIGHT_SAVING_SUCCESS = 'WEIGHT_SAVING_SUCCESS'
export const WEIGHT_SAVING_ERROR = 'WEIGHT_SAVING_ERROR'

export const WEIGHTS_SAVING_REQUEST = 'WEIGHTS_SAVING_REQUEST'
export const WEIGHTS_SAVING_SUCCESS = 'WEIGHTS_SAVING_SUCCESS'
export const WEIGHTS_SAVING_ERROR = 'WEIGHTS_SAVING_ERROR'
