import * as types from '../constants/parentCoreTypes';
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";

export function updateField(name, value) {
    return {
        type: types.PARENT_CORE_UPDATE_FIELD,
        name,
        value,
    }
}

export function getParentCores() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/parentcores`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PARENT_CORES_LOADING_REQUEST,
                types.PARENT_CORES_LOADING_SUCCESS,
                types.PARENT_CORES_LOADING_ERROR,
            ],
        },
    }
}

export function getParentCore(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/parentcore/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PARENT_CORE_LOADING_REQUEST,
                types.PARENT_CORE_LOADING_SUCCESS,
                types.PARENT_CORE_LOADING_ERROR,
            ],
        },
    }
}

export function saveParentCore(parentCore){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/parentcore`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(parentCore),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PARENT_CORE_SAVING_REQUEST,
                types.PARENT_CORE_SAVING_SUCCESS,
                types.PARENT_CORE_SAVING_ERROR,
            ],
        },
    }
}

