import React from 'react';
import FamilyList from './List/FamilyList';
import '../styles/about-page.css';

export class FamilyListPage extends React.Component {

    render() {
        return (
            <FamilyList/>
        );
    }
}

export default FamilyListPage;
