import { loginSubmit } from "../../../actions/loginActions";
import { getUserTotalScansByStatusToday } from "../../../actions/scanActions";

//This function is use to logout employee and remove localstorage items.
export async function logout_helper() {
  let logoutItems = [
    "token",
    "firstName",
    "lastName",
    "employeeNum",
    "userId",
    "userRole"
  ];
  logoutItems.forEach((obj) => {
    localStorage.removeItem(obj);
  });

  window.location.href = "/";
}

//This function is use to login a new employee to a station if another user is already logged into it.
export async function loginNewUser(dispatch, username, USER_SCAN_PASS) {
  if (username.toUpperCase().includes(localStorage.getItem("employeeNum"))) {
    logout_helper();
  } else if (username.toUpperCase() != localStorage.getItem("employeeNum")) {
    await dispatch(loginSubmit(username, USER_SCAN_PASS)).then((res) => {
      if ((res.type = "LOGIN_SUBMIT_FAILURE")) {
        window.location.href = "/";
      }
    });
  }
}

//This function is used to get the total amount of user scans by status Id.
export async function getUserTotalScans(dispatch, userId, statusId) {
  try {
    const res = await dispatch(getUserTotalScansByStatusToday(userId, statusId));
    return location.id === 21
        ? res.payload.length
        : res.payload.filter(goodScans => goodScans.pass === 1).length;
  } catch (error) {
    console.error('Error in getUserTotalScans:', error);
    throw error; // Or handle error as per your application's error handling strategy
  }
}

export async function autoLogout() {
  //// logout after 15min of no use
  let timeInterval = setInterval(() => {
    let lastActivity = localStorage.getItem("lastActivity");
    var diffMs = Math.abs(new Date(lastActivity) - new Date()); // milliseconds between now & last activity
    var seconds = Math.floor(diffMs / 1000);
    var minute = Math.floor(seconds / 60);
    if (minute == 30) {
      clearInterval(timeInterval);
      logout_helper();
    }
  }, 1000);
}
