import * as types from "../constants/shipmentTypes";
import { RSAA } from "redux-api-middleware";
import { API_ROOT, SHIPSTATION } from "../utils/api";

export function updateField(name, value) {
  return {
    type: types.SHIPMENT_UPDATE_FIELD,
    name,
    value
  };
}

export function getStores() {
  return {
    [RSAA]: {
      endpoint: `${SHIPSTATION.API_ROOT}/stores`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${SHIPSTATION.BASIC}`
      },
      options: {
        mode: "cors"
      },
      types: [
        types.STORES_LOADING_REQUEST,
        types.STORES_LOADING_SUCCESS,
        types.STORES_LOADING_ERROR
      ]
    }
  };
}

export function getOrders(startDate, endDate, storeId, statusId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/orders`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        startDate: startDate,
        endDate: endDate,
        storeId: storeId > -1 ? storeId.toString() : "",
        statusId: statusId
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SHIPMENTS_LOADING_REQUEST,
        types.SHIPMENTS_LOADING_SUCCESS,
        types.SHIPMENTS_LOADING_ERROR
      ]
    }
  };
}

export function getShipment(queryString) {
  return {
    [RSAA]: {
      endpoint: `${SHIPSTATION.API_ROOT}/shipments` + queryString,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${SHIPSTATION.BASIC}`
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SHIPMENT_LOADING_REQUEST,
        types.SHIPMENT_LOADING_SUCCESS,
        types.SHIPMENT_LOADING_ERROR
      ]
    }
  };
}

export function printTruckShippedPdf(data) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/truck-shipped-pdf`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.PRINT_TRUCK_SHIPPED_LOADING_REQUEST,
        types.PRINT_TRUCK_SHIPPED_LOADING_SUCCESS,
        types.PRINT_TRUCK_SHIPPED_LOADING_ERROR
      ]
    }
  };
}
