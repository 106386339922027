import React from 'react';
import T from 'prop-types'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { 
  Box, 
  Container, 
  Paper } from '@material-ui/core';
  import "./copybomdraggablecontext.css"

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((layer,index) => layer.core_layer = index)
    return result;
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? "" : "",
    ...draggableStyle
  });
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "" : "none",
    padding: "grid",
    width: '800px'
  });

const CopyBomDraggableContext = (props) => {
  const { layers, confirmLayerRemoval, setCoreData } = props;
  const onDragEnd = async (result) => {
    if(!result.destination) {
      return;
    }
    const reorderedLayers = reorder(
      layers,
      result.source.index,
      result.destination.index
    );
    setCoreData(prevState => ({...prevState, layers: reorderedLayers}))
  };
  return (
    <Box className="copyBom-draggable-box">
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {layers.map((l,index) => {
              const paperStyle = {
                background: l.material ? l.material.color : '',
                border: 0,
                borderRadius: 3,
                fontWeight: 'bold',
                padding: "18px 30px 18px 30px",
                width: '800px',
              }
              return (
                <Draggable key={l.core_layer} draggableId={l.core_layer.toString()} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                      )}
                      id={l.core_layer}
                    >
                      <Container className='copyBom-layers-container' >
                        <Paper style={paperStyle}>
                          <FontAwesomeIcon 
                            icon={faTimes}
                            id='copyBom-font-awesome-icon-remove' 
                            onClick={() => confirmLayerRemoval(index)}
                          />
                          <span id='copyBom-layer-material' >
                            {l.material ? l.material.raw_material : ''}</span> <span id='copyBom-layer-part'>- {l.part ? l.part.num : ''} - {l.part ? l.part.description : ''}
                          </span>
                          <span id='copyBom-layer-topper'>
                            {l.topper == true ? 'topper' : ''}</span> <span id='copyBom-layer-convoluted' >{l.convoluted == 1 ? 'convoluted' : ''}
                          </span>
                          <span id='copyBom-layer-depth_in'>
                            {l.layer_depth_in ? l.layer_depth_in : ''}"
                          </span>
                        </Paper>
                      </Container>
                    </Box>
                  )}
                </Draggable>
              )
            })}
              {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  </Box>
  )
}

CopyBomDraggableContext.propTypes = {
  layers: T.array
}

export default CopyBomDraggableContext