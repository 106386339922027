import React  from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import {getPrototypes, savePrototypeList} from "../../actions/prototypeActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";
import Can from "../Can";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

class PrototypeList extends React.Component {

    getOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <Can
                        role={this.props.role}
                        perform='sku:edit'
                        yes={() => (
                            <AddNewButton
                                entity={"prototype/-1"}
                                history={this.props.history}
                                data={data}
                            />
                        )}
                        no={() => null}
                    />
                )
            }
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            prototypes: [],
            rows: [],
            cols: [],
            columns: [],
            filename: '',
            file: '',
        }
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('prototype'))
    }

    //Toolbar functions
    handlePrototypeSaveClick = () => {
        const {
            dispatch,
            prototypes,
        } = this.props

        const data = {
            data: prototypes.concat(this.state.prototypes),
            filename: this.state.filename,
        }

        dispatch(savePrototypeList(data))
        this.setState({
            cols: [],
            rows: [],
            prototypes: [],
            filename: '',
        })
    }
    handlePrototypeDeleteClick = () => {

        this.setState({
            cols: [],
            rows: [],
            schedule: [],
        })
    }
    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {

        this.getColumns()

        const {
            dispatch,
            isLoading,
            prototypes,
            rows,
            cols,
        } = this.props

        const prototypeList = {
            isLoading,
            prototypes,
            rows,
            cols,
        }
        dispatch(getPrototypes())
    }

    render() {
        const {
            isLoading,
            prototypes,
            columns,
        } = this.props

        return (
            <div className="baseContainer prototypeList">
                <h1>
                </h1>
                <MUIDataTable
                    title={"Prototypes"}
                    data={prototypes.concat(this.state.prototypes)}
                    columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    options={this.getOptions(prototypes.concat(this.state.prototypes))}
                />
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

PrototypeList.defaultProps = {
    date: getDBFormattedDate()
}

PrototypeList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    prototypes: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { prototypeList, loginForm } = state
    return {
        isLoading: prototypeList.isLoading,
        prototypes: prototypeList.prototypes,
        rows: prototypeList.rows,
        cols: prototypeList.cols,
        columns: prototypeList.columns,
        file: prototypeList.file,
        role: loginForm.userRole
    }
}

export default withRouter(connect(mapStateToProps)(PrototypeList))