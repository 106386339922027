export const BATCHES_LOADING_REQUEST = 'BATCHES_LOADING_REQUEST'
export const BATCHES_LOADING_SUCCESS = 'BATCHES_LOADING_SUCCESS'
export const BATCHES_LOADING_ERROR = 'BATCHES_LOADING_ERROR'

export const BATCHE_UPDATE_FIELD = 'BATCHE_UPDATE_FIELD'

export const BATCHE_SAVING_REQUEST = 'BATCHE_SAVING_REQUEST'
export const BATCHE_SAVING_SUCCESS = 'BATCHE_SAVING_SUCCESS'
export const BATCHE_SAVING_ERROR = 'BATCHE_SAVING_ERROR'


export const BATCHES_SAVING_REQUEST = 'BATCHES_SAVING_REQUEST'
export const BATCHES_SAVING_SUCCESS = 'BATCHES_SAVING_SUCCESS'
export const BATCHES_SAVING_ERROR = 'BATCHES_SAVING_ERROR'

export const BATCHE_DELETING_REQUEST = 'BATCHE_DELETING_REQUEST'
export const BATCHE_DELETING_SUCCESS = 'BATCHE_DELETING_SUCCESS'
export const BATCHE_DELETING_ERROR = 'BATCHE_DELETING_ERROR'
