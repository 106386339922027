import * as types from '../constants/materialGroupTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.MATERIAL_GROUP_UPDATE_FIELD,
        name,
        value,
    }
}export function updateBlend(id, value, groupMaterials) {
    const newGroupMaterials = []
    for(let i=0; i< groupMaterials.length; i++){
        const material = groupMaterials[i]
        const newId = material.id
        const name = material.name
        const active = material.active
        let blend = material.blend
        if(material.id === parseInt(id)){
            blend = value
        }
        newGroupMaterials.push({
            id: newId,
            name: name,
            blend: blend != null ? parseFloat(blend) : null,
            active: active,
        })
    }
    return {
        type: types.MATERIAL_UPDATE_BLEND,
        newGroupMaterials: newGroupMaterials,
    }
}
export function addMaterial(material, groupMaterials, materials) {
    const removeIndex = materials.map(m => {return m.id}).indexOf(material.id)
    let newMaterials = [...materials]
    newMaterials.splice(removeIndex, 1);
    return {
        type: types.ADD_MATERIAL,
        groupMaterials: groupMaterials.concat(material),
        newMaterials: newMaterials,
    }
}
export function materialAdded() {
    return {
        type: types.MATERIAL_ADDED,
    }
}
export function removeMaterial(material, groupMaterials, materials) {
    const removeIndex = groupMaterials.map(m => {return m.id}).indexOf(material.id)
    let newMaterials = [...groupMaterials]
    newMaterials.splice(removeIndex, 1);
    return {
        type: types.REMOVE_MATERIAL,
        groupMaterials: newMaterials,
        newMaterials: materials.concat(material),
    }
}
export function materialRemoved() {
    return {
        type: types.MATERIAL_REMOVED,
    }
}

export function getMaterials(groupId) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/material/notin/${groupId}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.MG_MATERIALS_LOADING_REQUEST,
                types.MG_MATERIALS_LOADING_SUCCESS,
                types.MG_MATERIALS_LOADING_ERROR,
            ],
        },
    }
}

export function getMaterialGroups() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/material/groups`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.MATERIAL_GROUPS_LOADING_REQUEST,
                types.MATERIAL_GROUPS_LOADING_SUCCESS,
                types.MATERIAL_GROUPS_LOADING_ERROR,
            ],
        },
    }
}

export function getMaterialGroup(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/material/group/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.MATERIAL_GROUP_LOADING_REQUEST,
                types.MATERIAL_GROUP_LOADING_SUCCESS,
                types.MATERIAL_GROUP_LOADING_ERROR,
            ],
        },
    }
}

export function saveMaterialGroupList(materialGroups){
    const data = []
    for (let field in materialGroups.data) {
        let row = removeEmptyFields(materialGroups.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/material/groups`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.MATERIAL_GROUPS_SAVING_REQUEST,
                types.MATERIAL_GROUPS_SAVING_SUCCESS,
                types.MATERIAL_GROUPS_SAVING_ERROR,
            ],
        },
    }
}

export function saveMaterialGroup(materialGroup){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/lt/material/group`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(materialGroup),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.MATERIAL_GROUP_SAVING_REQUEST,
                types.MATERIAL_GROUP_SAVING_SUCCESS,
                types.MATERIAL_GROUP_SAVING_ERROR,
            ],
        },
    }
}