import React from 'react'
import '../styles/about-page.css'
import MaterialForm from './forms/MaterialForm'

const MaterialFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Material</h2>
            <MaterialForm location={location}/>
        </div>
    )
}

export default MaterialFormPage