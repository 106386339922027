import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save"
import AddIcon from "@material-ui/icons/Add"
import React from "react";
import {Tooltip, withStyles} from "@material-ui/core";
import { withRouter } from 'react-router-dom';

const defaultToolbarStyles = {
    iconButton: {},
};

let toUrl = ''

class AddNewButton extends React.Component {


    redirectToTarget = () => {
        this.props.history.push(`/${toUrl}`)
    }

    render() {
        const {
            classes,
            entity,
            history,
        } = this.props;

        toUrl = entity
        return (
            <React.Fragment>
                <Tooltip title={"Add New " + entity}>
                    <IconButton className={classes.iconButton} onClick={this.redirectToTarget}>
                        <AddIcon className={classes.deleteIcon} />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        );
    }

}

export default withRouter(withStyles(defaultToolbarStyles, { name: "AddNewButton" })(AddNewButton));