
export const BRAND_UPDATE_FIELD = 'BRAND_UPDATE_FIELD'

export const BRAND_LOADING_REQUEST = 'BRAND_LOADING_REQUEST'
export const BRAND_LOADING_SUCCESS = 'BRAND_LOADING_SUCCESS'
export const BRAND_LOADING_ERROR = 'BRAND_LOADING_ERROR'

export const BRANDS_LOADING_REQUEST = 'BRANDS_LOADING_REQUEST'
export const BRANDS_LOADING_SUCCESS = 'BRANDS_LOADING_SUCCESS'
export const BRANDS_LOADING_ERROR = 'BRANDS_LOADING_ERROR'

export const BRAND_IMPORT_LOADING_REQUEST = 'BRAND_IMPORT_LOADING_REQUEST'
export const BRAND_IMPORT_LOADING_SUCCESS = 'BRAND_IMPORT_LOADING_SUCCESS'
export const BRAND_IMPORT_LOADING_ERROR = 'BRAND_IMPORT_LOADING_ERROR'

export const BRAND_SAVING_REQUEST = 'BRAND_SAVING_REQUEST'
export const BRAND_SAVING_SUCCESS = 'BRAND_SAVING_SUCCESS'
export const BRAND_SAVING_ERROR = 'BRAND_SAVING_ERROR'

export const BRANDS_SAVING_REQUEST = 'BRANDS_SAVING_REQUEST'
export const BRANDS_SAVING_SUCCESS = 'BRANDS_SAVING_SUCCESS'
export const BRANDS_SAVING_ERROR = 'BRANDS_SAVING_ERROR'