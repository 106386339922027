
export const MANUFACTURER_UPDATE_FIELD = 'MANUFACTURER_UPDATE_FIELD'

export const MANUFACTURER_LOADING_REQUEST = 'MANUFACTURER_LOADING_REQUEST'
export const MANUFACTURER_LOADING_SUCCESS = 'MANUFACTURER_LOADING_SUCCESS'
export const MANUFACTURER_LOADING_ERROR = 'MANUFACTURER_LOADING_ERROR'

export const MANUFACTURERS_LOADING_REQUEST = 'MANUFACTURERS_LOADING_REQUEST'
export const MANUFACTURERS_LOADING_SUCCESS = 'MANUFACTURERS_LOADING_SUCCESS'
export const MANUFACTURERS_LOADING_ERROR = 'MANUFACTURERS_LOADING_ERROR'

export const MANUFACTURER_IMPORT_LOADING_REQUEST = 'MANUFACTURER_IMPORT_LOADING_REQUEST'
export const MANUFACTURER_IMPORT_LOADING_SUCCESS = 'MANUFACTURER_IMPORT_LOADING_SUCCESS'
export const MANUFACTURER_IMPORT_LOADING_ERROR = 'MANUFACTURER_IMPORT_LOADING_ERROR'

export const MANUFACTURER_SAVING_REQUEST = 'MANUFACTURER_SAVING_REQUEST'
export const MANUFACTURER_SAVING_SUCCESS = 'MANUFACTURER_SAVING_SUCCESS'
export const MANUFACTURER_SAVING_ERROR = 'MANUFACTURER_SAVING_ERROR'

export const MANUFACTURERS_SAVING_REQUEST = 'MANUFACTURERS_SAVING_REQUEST'
export const MANUFACTURERS_SAVING_SUCCESS = 'MANUFACTURERS_SAVING_SUCCESS'
export const MANUFACTURERS_SAVING_ERROR = 'MANUFACTURERS_SAVING_ERROR'
