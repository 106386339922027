import React from 'react'
import '../styles/about-page.css'
import FishbowlReprocessingForm from './forms/FishbowlReprocessingForm'

const FishbowlReprocessingFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Fishbowl Reprocessing</h2>
            <FishbowlReprocessingForm location={location}/>
        </div>
    )
}

export default FishbowlReprocessingFormPage