import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button, Snackbar, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete, Alert } from "@material-ui/lab";
import "../styles/ShippingAddModal.styles.css";
import { getActiveSkus } from "../actions/skuActions";
import { useDispatch } from "react-redux";
import { saveScheduleList } from "../actions/scheduleActions";
import moment from "moment";

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: "9999999",
    backgroundColor: "rgba(0,0,0,0.85)"
  },
  content: {
    position: "absolute",
    top: "15%",
    left: "35%",
    right: "35%",
    bottom: "55%",
    padding: "1.5rem",
    justifyContent: "center",
    minHeight: "355px"
  }
};

export default function ShippingAddModal(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [allSkus, setAllSkus] = useState([]);
  const [filteredSkus, setFilteredSkus] = useState(allSkus);
  const [inputSku, setInputSku] = useState("");
  const [inputOrderNumber, setInputOrderNumber] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();

  const urgentSkuObj = {
    data: {
      id: null,
      sku: "",
      qty: 1,
      location: 0,
      priority: "9999 - Shipping Urgent",
      order_number: "",
      inspection_required: 0,
      alt_process_id: 0,
      flat_pack: 0,
      user_id: "",
      date: moment().format("YYYY-MM-DD")
    },
    bypassFilter: true
  };

  useEffect(() => {
    getActiveSkusOnly();
  }, []);

  useEffect(() => {
    const newFilteredSkus = allSkus.filter((sku) => {
      return sku.toLowerCase().includes(searchField);
    });
    setFilteredSkus(newFilteredSkus);
  }, [allSkus, searchField]);

  function getActiveSkusOnly() {
    dispatch(getActiveSkus()).then((res) => {
      const data = res.payload;
      const activeSkus = data.map((x) => x.sku);
      setAllSkus(activeSkus);
    });
  }

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const handleSnackbarClick = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    urgentSkuObj.data.sku = inputSku;
    urgentSkuObj.data.order_number = inputOrderNumber;
    urgentSkuObj.data.user_id = localStorage.userId;
    dispatch(saveScheduleList(urgentSkuObj)).then((response) => {
      if (response.type === "SCHEDULE_SAVING_SUCCESS") {
        setSnackbarType("success");
        setSnackbarMessage(`SKU ${inputSku} has been saved`);
      } else if (response.type === "SCHEDULE_SAVING_ERROR") {
        setSnackbarType("error");
        setSnackbarMessage("Something went wrong, nothing was saved");
      }
    });
    handleSnackbarClick();
    setTimeout(() => {
      setInputSku("");
      setInputOrderNumber("");
      setSnackbarOpen(false);
      props.handleSave();
    }, 1000);
    setTimeout(() => {
      toggleModal();
    }, 2000);
  };

  return (
    <div>
      <label className="shippingAddButton" onClick={toggleModal}>
        Shipping Add
      </label>

      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={toggleModal}
        style={modalStyle}
      >
        <div className="closeButton">
          <CloseIcon onClick={toggleModal} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="skuAndOrderNumDiv">
            <div>
              <Autocomplete
                className="skuInputDiv"
                disablePortal
                options={allSkus}
                value={inputSku}
                onChange={(e, v) => setInputSku(v?.inputSku || v)}
                getOptionLabel={(option) => option || ""}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField {...params} required label="SKU" />
                )}
              />
            </div>
            <div className="orderNumDiv">
              <TextField
                label="Order Number"
                type="text"
                value={inputOrderNumber}
                onChange={(e) => setInputOrderNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="submitButtonDiv">
            <Button
              variant="outlined"
              className="submitButton"
              value="Submit"
              type="submit"
              style={{ backgroundColor: "#003764", color: "white" }}
            >
              Submit
            </Button>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackbarType}
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        </form>
      </Modal>
    </div>
  );
}
