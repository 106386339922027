import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import XLSX from "xlsx";
import { styled } from "@material-ui/styles";
import {
  Button,
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  getCampaign,
  curveUpdate,
  createUpload
} from "../../actions/forecast/forecastActions";
import moment from "moment";
import ForecastModal from "./forecastModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const EXTENTIONS = ["xlsx", "xls", "cvs"];
const initialState = "";
const CampagineCurve = (update) => {
  const isAuthenticated = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [colDefs, setColDefs] = useState();
  const [curveData, setCurveData] = useState();
  const [campaign, setCampaign] = useState(null);
  const [sumError, setSumError] = useState("");
  const [rangeError, setRangeError] = useState("");
  const [updateExisting, setUpdateExisting] = useState(update.updateExisting);
  const [curveError, setCurveError] = useState(false);
  const [sucessValue, setSucessValue] = useState(false);
  const [sucess, setSucess] = useState("");
  const [campaignMissing, setCampaignMissing] = useState("");
  const Input = styled("input")({
    display: "none"
  });
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getCampaign()).then((res) => {
      setCampaign(res.payload);
      checkIfCurveExist(res.payload);
    });
  }, [update]);
  function resetErrors() {
    setCurveError(initialState);
    setRangeError(initialState);
    setSumError(initialState);
    setCampaignMissing(initialState);
  }
  async function validate(value) {
    let curveError = "";
    let avaliableCampaign = await value.find(
      (cam) => moment(cam.as_of_date).format("YYYY-MM-DD") == "9999-01-01"
    );

    if (avaliableCampaign) {
      return true;
    } else {
      curveError = (
        <b>
          Campaign Curve not avaliable to update... Please create new Forecast
          Workbook Upload
        </b>
      );
    }

    if (curveError) {
      setLoading(false);
      setCurveError(curveError);
      window.scrollTo(0, 0);
      return false;
    }
    return true;
  }

  function checkIfCurveExist(value) {
    const isValid = validate(value);
    if (isValid) {
      setCurveError(initialState);
    }
  }

  function group(arr, key) {
    return [
      ...arr
        .reduce(
          (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
          new Map()
        )
        .values()
    ];
  }

  function handleValidation(newData) {
    let sum = "";
    let errorMissingCampaign = "";
    let range = [];
    let data = [];

    let validDate = campaign.filter(
      (cp) => moment.utc(cp.as_of_date).format("YYYY-MM-DD") == "9999-01-01"
    );
    let campaignCheck = validDate.filter((camp) =>
      newData.find((nd) => camp.id === nd.campaign_id)
    );
    if (campaignCheck.length <= 0) {
      errorMissingCampaign = <b>Campaign Name not found!</b>;
    }
    if (errorMissingCampaign) {
      setLoading(false);
      setCampaignMissing(errorMissingCampaign);
    }

    newData.reduce(function (res, values) {
      if (!res[values.campaign_name]) {
        res[values.campaign_name] = {
          campaign_id: values.campaign_id,
          campaign_name: values.campaign_name,
          curve: 0
        };
        range.push(res[values.campaign_name]);
      }
      res[values.campaign_name].curve += values.curve;
      return res;
    }, {});
    if (range) {
      let missing = [];
      range.filter((res) => {
        if (res.curve.toFixed(2) != 1.0) {
          sum = <b>Campaign Name {res.campaign_name} not equal to 1!</b>;
          missing.push(sum);
        }
      });
      if (missing.length > 0) {
        setSumError(missing);
        setLoading(false);
      }
    }

    let result = [];
    var dateArray = [];
    let datesMissing = "";
    range.filter((data) => {
      campaign.filter((date) => {
        if (moment(date.as_of_date).format("YYYY-MM-DD") == "9999-01-01") {
          if (data.campaign_id === date.id) {
            var id = date.id;
            var campaign_name = date.name;
            var currentDate = moment(date.date_start);
            var stopDate = moment(date.date_end);
            while (currentDate <= stopDate) {
              dateArray.push({
                date: moment(currentDate).format("YYYY-MM-DD"),
                campaign_id: id,
                campaign_name: campaign_name
              });
              currentDate = moment(currentDate).add(1, "days");
            }
          }
        }
      });
    });

    newData.reduce(function (res, values) {
      if (!res[values.campaign_name]) {
        res[values.date] = {
          campaign_name: values.campaign_name,
          campaign_id: values.campaign_id,
          date: values.date
        };
        result.push(res[values.date]);
      }
      return res;
    }, {});
    const curveObj = group(result, "campaign_name");

    let curveDataArray = [];
    curveObj.forEach((item) => {
      item.filter((obj) => {
        campaign.find((id) => {
          if (moment(id.as_of_date).format("YYYY-MM-DD") == "9999-01-01") {
            if (id.id === obj.campaign_id) {
              curveDataArray.push(obj);
            }
          }
        });
      });
    });
    const a = curveDataArray;
    const b = dateArray;

    const isSame = (a, b) =>
      a.date === b.date &&
      a.campaign_id === b.campaign_id &&
      a.campaign_name === b.campaign_name;

    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        (leftValue) =>
          !right.some((rightValue) => compareFunction(leftValue, rightValue))
      );

    const onlyInA = onlyInLeft(a, b, isSame);
    const onlyInB = onlyInLeft(b, a, isSame);

    const missing = [...onlyInA, ...onlyInB];
    const arrUniq = [
      ...new Map(missing.map((v) => [JSON.stringify(v), v])).values()
    ];

    let missingRange = [];
    if (arrUniq) {
      arrUniq.filter((missing) => {
        {
          datesMissing = <b>Date {missing.date} from Daily Distribution</b>;
        }
        missingRange.push(datesMissing);
      });
    }
    if (missingRange.length > 0) {
      setLoading(false);
      setRangeError(missingRange);
    }
    if (errorMissingCampaign) {
      window.scrollTo(0, 0);
      return false;
    }

    if (sum) {
      window.scrollTo(0, 0);
      return false;
    }

    return true;
  }

  const updateNewCurve = (newData) => {
    setLoading(true);
    newData.filter((item) => {
      campaign.find((camp) => {
        if (item.campaign_name === camp.name) {
          item["campaign_id"] = camp.id;
        }
      });
    });
    const isValid = handleValidation(curveData);

    if (isValid) {
      const isSucessful = createUploadStamp(newData);
      if (isSucessful) {
        setLoading(false);
        setSucessValue(true);
        setSucess([
          { title: "Sucess!!" },
          {
            body: "You had a sucessful upload!"
          }
        ]);
      }
    }
  };

  const createUploadStamp = (newData) => {
    let data = {
      timestamp: moment().format("YYYY-MM-DD, h:mm:ss"),
      user_id: isAuthenticated
    };
    dispatch(createUpload(data)).then((res) => {
      if (res.type === "FORECAST_LOADING_SUCCESS") {
        let newDataObj = [];
        newData.filter((cd) => {
          if (moment(cd.as_of_date).format("YYYY-MM-DD") == "9999-01-01") {
            let uploadData = {
              campaign_id: cd.campaign_id,
              date: moment(cd.date).format("YYYY-MM-DD"),
              curve: cd.curve,
              as_of_date: moment("9999-01-01").format("YYYY-MM-DD"),
              upload_id: res.payload.id
            };
            newDataObj.push(uploadData);
          }
        });
        dispatch(curveUpdate(newDataObj));
      }
    });
    setLoading(false);
  };

  const getExtention = (file) => {
    const parts = file.name.split(".");
    const extention = parts[parts.length - 1];
    return EXTENTIONS.includes(extention);
  };
  const convertToJson = (headers, data) => {
    let rows = [];
    data.forEach((row) => {
      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });
      if (data.length) {
        let newDate = new Date(Math.round((rowData.date - 25569) * 864e5));
        newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
        let newRow = {
          campaign_name: rowData.campaign_name,
          date:
            rowData.date == ""
              ? rowData.date
              : moment(newDate).format("YYYY-MM-DD"),
          curve: rowData.curve,
          as_of_date: moment("9999-01-01").format("YYYY-MM-DD")
        };
        rows.push(newRow);
      } else {
        const formatYmd = (date) => date.toISOString().slice(0, 10);
        let newRow = {
          campaign_name: rowData.campaign_name,
          date: formatYmd(new Date(rowData.date)),
          curve: rowData.curve,
          as_of_date: moment("9999-01-01").format("YYYY-MM-DD")
        };
        rows.push(newRow);
      }
    });
    return rows;
  };

  const importExcel = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: "binary" });

      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];

      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const headers = fileData[0];
      const heads = headers.map((head) => ({ title: head, field: head }));
      setColDefs(heads);

      fileData.splice(0, 1);
      setCurveData(convertToJson(headers, fileData));
    };
    if (file) {
      if (getExtention(file)) {
        reader.readAsBinaryString(file);
      } else {
        alert("Invalid file input, Select Excel, CSV file");
      }
    } else {
      setCurveData([]);
      setColDefs([]);
    }
  };

  return (
    <>
      <ForecastModal open={sucessValue} value={sucessValue} sucess={sucess} />
      {loading == true ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: 9999,
            color: "#2894c3"
          }}
        >
          <Box
            sx={{
              top: 1,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              letterSpacing: 10
            }}
          >
            <Typography variant="h1" component="div">
              Loading...
            </Typography>
          </Box>
        </Box>
      ) : (
        ""
      )}
      {updateExisting ? (
        <Container maxWidth="xl">
          <Grid style={{ marginTop: "20px", marginLeft: "-20px" }}>
            {sumError || campaignMissing || rangeError || curveError ? (
              <>
                <Typography style={{ fontSize: 18, color: "red" }}>
                  {sumError}
                </Typography>
                <Typography style={{ fontSize: 18, color: "red" }}>
                  {campaignMissing}
                </Typography>
                <Typography style={{ fontSize: 18, color: "red" }}>
                  {rangeError}
                </Typography>
                <Typography style={{ fontSize: 18, color: "red" }}>
                  {curveError}
                </Typography>
              </>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="xlsx, xls, cvs"
                    id="contained-button-file"
                    type="file"
                    onChange={importExcel}
                    multiple
                  />
                  <Button
                    size="large"
                    variant="contained"
                    component="span"
                    className="forecast_button"
                    style={{
                      backgroundColor: "#003764",
                      color: "white",
                      marginLeft: "-25px",
                      marginTop: "20px"
                    }}
                    onClick={() => resetErrors()}
                  >
                    Upload File &nbsp; <FontAwesomeIcon icon={faUpload} />
                  </Button>
                </label>
                &nbsp; {fileName}
              </Grid>
            </Grid>
            {curveData ? (
              <div className="campaign-container">
                <Grid style={{ marginTop: "50px" }}>
                  <MaterialTable
                    title="Curve Avaliable to Update"
                    data={curveData}
                    columns={colDefs}
                    options={{
                      pageSize: 5,
                      addRowPosition: "first",
                      actionsColumnIndex: -1,
                      rowStyle: {
                        fontSize: 16
                      }
                    }}
                  />
                </Grid>
                <Grid container item justifyContent="flex-end">
                  <Button
                    size="large"
                    variant="contained"
                    className="forecast_button"
                    onClick={() => {
                      setLoading(true),
                        setCurveData([]),
                        setColDefs([]),
                        setTimeout(() => {
                          updateNewCurve(curveData);
                        }, 100);
                    }}
                  >
                    Update &nbsp; <FontAwesomeIcon icon={faSave} />
                  </Button>
                </Grid>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Container>
      ) : (
        ""
      )}
    </>
  );
};

export default CampagineCurve;
