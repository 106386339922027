
export const CORE_RAW_MATERIAL_UPDATE_FIELD = 'CORE_RAW_MATERIAL_UPDATE_FIELD'

export const CORE_RAW_MATERIAL_LOADING_REQUEST = 'CORE_RAW_MATERIAL_LOADING_REQUEST'
export const CORE_RAW_MATERIAL_LOADING_SUCCESS = 'CORE_RAW_MATERIAL_LOADING_SUCCESS'
export const CORE_RAW_MATERIAL_LOADING_ERROR = 'CORE_RAW_MATERIAL_LOADING_ERROR'

export const CORE_RAW_MATERIALS_LOADING_REQUEST = 'CORE_RAW_MATERIALS_LOADING_REQUEST'
export const CORE_RAW_MATERIALS_LOADING_SUCCESS = 'CORE_RAW_MATERIALS_LOADING_SUCCESS'
export const CORE_RAW_MATERIALS_LOADING_ERROR = 'CORE_RAW_MATERIALS_LOADING_ERROR'

export const CORE_RAW_MATERIAL_IMPORT_LOADING_REQUEST = 'CORE_RAW_MATERIAL_IMPORT_LOADING_REQUEST'
export const CORE_RAW_MATERIAL_IMPORT_LOADING_SUCCESS = 'CORE_RAW_MATERIAL_IMPORT_LOADING_SUCCESS'
export const CORE_RAW_MATERIAL_IMPORT_LOADING_ERROR = 'CORE_RAW_MATERIAL_IMPORT_LOADING_ERROR'

export const CORE_RAW_MATERIAL_SAVING_REQUEST = 'CORE_RAW_MATERIAL_SAVING_REQUEST'
export const CORE_RAW_MATERIAL_SAVING_SUCCESS = 'CORE_RAW_MATERIAL_SAVING_SUCCESS'
export const CORE_RAW_MATERIAL_SAVING_ERROR = 'CORE_RAW_MATERIAL_SAVING_ERROR'

export const CORE_RAW_MATERIALS_SAVING_REQUEST = 'CORE_RAW_MATERIALS_SAVING_REQUEST'
export const CORE_RAW_MATERIALS_SAVING_SUCCESS = 'CORE_RAW_MATERIALS_SAVING_SUCCESS'
export const CORE_RAW_MATERIALS_SAVING_ERROR = 'CORE_RAW_MATERIALS_SAVING_ERROR'
