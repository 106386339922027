import React from 'react';
import PieceRateScanForm from './forms/PieceRateScanForm';
import '../styles/about-page.css';

export class PieceRateScanFormPage extends React.Component {

    render() {
        return (
            <PieceRateScanForm/>
        );
    }
}

export default PieceRateScanFormPage;