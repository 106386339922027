import {
  SKU_FISHBOWL_PART_LOADING_REQUEST,
  SKU_FISHBOWL_PART_LOADING_SUCCESS,
  SKU_FISHBOWL_PART_LOADING_ERROR,
  SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_REQUEST,
  SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_SUCCESS,
  SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_ERROR,
  SKU_UPDATE_FIELD,
    SKUS_ACTIVE_LOADING_REQUEST,
    SKUS_ACTIVE_LOADING_SUCCESS,
    SKUS_ACTIVE_LOADING_ERROR,
    SKUS_INACTIVE_LOADING_REQUEST,
    SKUS_INACTIVE_LOADING_SUCCESS,
    SKUS_INACTIVE_LOADING_ERROR,
    SKU_IMPORT_LOADING_REQUEST,
    SKU_IMPORT_LOADING_SUCCESS,
    SKU_IMPORT_LOADING_ERROR,
    SKUS_SAVING_REQUEST,
    SKUS_SAVING_SUCCESS,
    SKUS_SAVING_ERROR,
    SKU_GROUPS_LOADING_REQUEST,
    SKU_GROUPS_LOADING_SUCCESS,
    SKU_GROUPS_LOADING_ERROR,
    SKU_DRAFTS_LOADING_REQUEST,
    SKU_DRAFTS_LOADING_SUCCESS,
    SKU_DRAFTS_LOADING_ERROR,
    SKUS_ALL_LOADING_REQUEST,
    SKUS_ALL_LOADING_SUCCESS,
    SKUS_ALL_LOADING_ERROR
} from '../constants/skuTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";
import {addLink} from "../utils/column";
import {compareValues} from "../utils/sort";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    activeSkus: [],
    inactiveSkus: [],
    // unusedFamilies: [],
    skuGroups: [],
    skuDrafts: [],
    tab: 0,
    allSkus: [],
    skuRows: [],
    skuCols: [],
    skuColumns: [],
    groupColumns: [],
    draftColumns: [],
    file: '',
}

export default function skuListReducer(state = initialState, action) {
    switch (action.type) {
        case SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_ERROR:
        case SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_REQUEST:
        case SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_SUCCESS:
          return {
            ...state,
            isLoading: false,
          }
        case SKU_FISHBOWL_PART_LOADING_ERROR:
        case SKU_FISHBOWL_PART_LOADING_REQUEST:
        case SKU_FISHBOWL_PART_LOADING_SUCCESS:
          //console.log("reducer action: ", action)
          return {
            ...state,

          }
        case SKU_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case SKUS_ACTIVE_LOADING_REQUEST:
        case SKUS_INACTIVE_LOADING_REQUEST:
        case SKUS_ALL_LOADING_REQUEST:
        case SKU_GROUPS_LOADING_REQUEST:
        case SKU_DRAFTS_LOADING_REQUEST:
        case SKU_IMPORT_LOADING_REQUEST:
        case SKUS_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case SKUS_ACTIVE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                activeSkus: action.payload,
            }
        case SKUS_INACTIVE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                inactiveSkus: action.payload,
            }
        case SKUS_ALL_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                allSkus: action.payload
            }
        case SKU_GROUPS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                skuGroups: action.payload
            }
        case SKU_DRAFTS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                skuDrafts: action.payload
            }
        case SKU_IMPORT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                activeSkus: action.skus,
                skuRows: action.rows,
                skuCols: action.cols,
            }
        case SKUS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                activeSkus: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                skuColumns: addLink(action.payload).sort(compareValues('order_by')),
                groupColumns: addLink([
                    {"key": "id", "label": "Id", "name": "id", "options": {"display": false, }},
                    {"key": "family", "label": "Groups - (by family)", "name": "family", "options": {"route": "/sku-group", "custom": true, "count": 20}},
                ]),
                draftColumns: addLink([
                    {"key": "id", "label": "Id", "name": "id", "options": {"display": false, }},
                    {"key": "sku", "label": "Sku Drafts", "name": "sku", "options": {"route": "/sku-staging", "custom": true, "count": 20}},
                ])
                // excelCols: action.payload.sort(compareValues('order_by'))
            }
        case SKUS_ACTIVE_LOADING_ERROR:
        case SKUS_INACTIVE_LOADING_ERROR:
        case SKUS_ALL_LOADING_ERROR:
        case SKU_GROUPS_LOADING_ERROR:
        case SKU_DRAFTS_LOADING_ERROR:
        case SKU_IMPORT_LOADING_ERROR:
        case SKUS_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}