import {
  USER_UPDATE_FIELD,
  USER_LOADING_REQUEST,
  USER_LOADING_SUCCESS,
  USER_LOADING_ERROR,
  USER_SAVING_REQUEST,
  USER_SAVING_SUCCESS,
  USER_SAVING_ERROR,
  USER_UPDATE_PASS_REQ,
  USER_RESET
} from "../constants/userTypes";
import T from "prop-types";

const initialState = {
  isError: false,
  isLoading: false,
  saveSuccess: false,
  id: -1,
  firstName: "",
  lastName: "",
  username: "",
  password: "",
  changePwdReqd: "",
  active: "",
  userRoleName: [],
  userRole: []
};

export default function userFormReducer(state = initialState, action) {
  switch (action.type) {
    case USER_UPDATE_FIELD:
      return {
        ...state,
        [action.name]: action.value
      };
    case USER_UPDATE_PASS_REQ:
      return {
        ...state,
        changePwdReqd: action.value
      };
    case USER_LOADING_REQUEST:
    case USER_SAVING_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case USER_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        id: action.payload.id,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        username: action.payload.username,
        password: action.payload.password,
        changePwdReqd: action.payload.change_pwd_reqd,
        active: action.payload.active,
        userRole: action.payload.roles,
        userRoleName: action.payload.roles.map((r) =>
          r.active === 1 ? r.name : ""
        )
      };
    case USER_SAVING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        saveSuccess: true,
        id: null,
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        changePwdReqd: "",
        active: ""
      };
    case USER_LOADING_ERROR:
    case USER_SAVING_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        saveSuccess: false
      };
    case USER_RESET: {
      return {
        isError: false,
        isLoading: false,
        saveSuccess: false,
        id: -1,
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        changePwdReqd: "",
        active: "",
        userRoleName: [],
        userRole: []
      };
    }
    default:
      return state;
  }
}
