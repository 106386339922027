import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const SubNavLink = (props) => {
  const { label, route, className, ...rest } = props;

  const activeStyle = { color: "white", fontWeight: "bold" };
  const normalStyle = {
    color: "#efefef",
    padding: 10,
    textDecoration: "none"
  };
  const hoverStyle = { textDecoration: "underline" };

  const addedClass = "nav-subnavlink " + className;

  return (
    <NavLink
      exact
      {...rest}
      to={route}
      activeStyle={activeStyle}
      style={normalStyle}
      className={addedClass}
    >
      {label}
    </NavLink>
  );
};

SubNavLink.propTypes = {
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
};

export default SubNavLink;
