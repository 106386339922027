import * as types from '../constants/fabricQuiltPatternTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.FABRIC_QUILT_PATTERN_UPDATE_FIELD,
        name,
        value,
    }
}

export function getFabricQuiltPatterns() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/quilt/patterns`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_QUILT_PATTERNS_LOADING_REQUEST,
                types.FABRIC_QUILT_PATTERNS_LOADING_SUCCESS,
                types.FABRIC_QUILT_PATTERNS_LOADING_ERROR,
            ],
        },
    }
}

export function getFabricQuiltPattern(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/quilt/pattern/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_QUILT_PATTERN_LOADING_REQUEST,
                types.FABRIC_QUILT_PATTERN_LOADING_SUCCESS,
                types.FABRIC_QUILT_PATTERN_LOADING_ERROR,
            ],
        },
    }
}

export function saveFabricQuiltPatternList(fabricQuiltPatterns){
    const data = []
    for (let field in fabricQuiltPatterns.data) {
        let row = removeEmptyFields(fabricQuiltPatterns.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/quilt/patterns`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_QUILT_PATTERNS_SAVING_REQUEST,
                types.FABRIC_QUILT_PATTERNS_SAVING_SUCCESS,
                types.FABRIC_QUILT_PATTERNS_SAVING_ERROR,
            ],
        },
    }
}

export function saveFabricQuiltPattern(fabricQuiltPattern){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/quilt/pattern`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(fabricQuiltPattern),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_QUILT_PATTERN_SAVING_REQUEST,
                types.FABRIC_QUILT_PATTERN_SAVING_SUCCESS,
                types.FABRIC_QUILT_PATTERN_SAVING_ERROR,
            ],
        },
    }
}