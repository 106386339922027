import React from 'react'
import '../styles/about-page.css'
import FoundationForm from './forms/FoundationForm'

const FoundationFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Foundation</h2>
            <FoundationForm location={location}/>
        </div>
    )
}

export default FoundationFormPage