import React from 'react';
import ParentCoreList from './List/ParentCoreList';
import '../styles/about-page.css';

export class ParentCoreListPage extends React.Component {

    render() {
        return (
            <ParentCoreList/>
        );
    }
}

export default ParentCoreListPage;
