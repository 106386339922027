import * as types from '../constants/customerTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.CUSTOMER_UPDATE_FIELD,
        name,
        value,
    }
}

export function getCustomers() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/customers`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CUSTOMERS_LOADING_REQUEST,
                types.CUSTOMERS_LOADING_SUCCESS,
                types.CUSTOMERS_LOADING_ERROR,
            ],
        },
    }
}

export function getCustomer(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/customer/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CUSTOMER_LOADING_REQUEST,
                types.CUSTOMER_LOADING_SUCCESS,
                types.CUSTOMER_LOADING_ERROR,
            ],
        },
    }
}

export function saveCustomerList(customers){
    const data = []
    for (let field in customers.data) {
        let row = removeEmptyFields(customers.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/customers`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CUSTOMERS_SAVING_REQUEST,
                types.CUSTOMERS_SAVING_SUCCESS,
                types.CUSTOMERS_SAVING_ERROR,
            ],
        },
    }
}

export function saveCustomer(customer){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/customer`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(customer),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CUSTOMER_SAVING_REQUEST,
                types.CUSTOMER_SAVING_SUCCESS,
                types.CUSTOMER_SAVING_ERROR,
            ],
        },
    }
}