import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  Button,
  InputLabel,
  Backdrop,
  Tabs
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faCheckCircle,
  faSatellite,
  faSearchLocation,
  faSearchMinus,
  faShippingFast,
  faSync,
  faTimesCircle,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from "react-router-dom";
import {
  getStatusList,
  postScanLog,
  validateBatchScans
} from "../../../actions/scanActions";
import "./shipped.css";
import { Alert } from "@material-ui/lab";
import { USER_SCAN_PASS } from "../../../utils/api";
import LinearProgressBar from "../LinearProgressBar";
import moment from "moment";
import {
  loginNewUser,
  getUserTotalScans,
  autoLogout
} from "../ProductionHelperFunctions/ProductionHelper";

export default function ShippedStatus() {
  //// logout after 15min of no use
  autoLogout();
  const dispatch = useDispatch();
  let history = useHistory();
  let locationHistory = useLocation();
  const inputField1Ref = useRef(null);
  const inputField2Ref = useRef(null);
  const inputField3Ref = useRef(null);
  const initialState = "";
  const [overlayBackdrop, setOverlayBackdrop] = useState(false);
  const [successGreen, setSuccessGreen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [user, setUser] = useState("");
  const [area, setArea] = useState(null);
  const [status, setStatus] = useState([]);
  const [failRed, setFailRed] = useState(false);
  const [totalScanned, setTotalScanned] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [scan, setScan] = useState("");
  const [serialTag, setSerialTag] = useState(null);
  const [barcodeScan, setBarcodeScan] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [shippingObj, setShippingObj] = useState(null);
  const [shippedObj, setShippedObj] = useState(null);
  const [serialNumberSkuName, setSerialNumberBarcodeSkuName] = useState("");
  const [shippingCheckButtonStyle, setShippingCheckButtonStyle] = useState({});
  const [shippingXButtonStyle, setShippingXButtonStyle] = useState({});
  const [enableGreenShippingButton, setEnableGreenShippingButton] =
    useState(true);
  const [enableRedShippingButton, setEnableRedShippingButton] = useState(true);
  const [disableField, setDisableField] = useState(false);
  const [location, setLocation] = useState({});
  const [userId, setUserId] = useState("");
  const [epc, setEpc] = useState("");
  const [machineNumber, setMachineNumber] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [machineRequired, setMachineRequired] = useState(false);
  const [scanError, setScanError] = useState("");
  const [loading, setLoading] = useState(false);
  const [previouslyScanned, setPreviouslyScanned] = useState([]);
  const [stationName, setStationName] = useState(null);
  const [stationPrinterInfo, setStationPrinterInfo] = useState([]);
  const [scheduleLocation, setScheduleLocation] = useState(initialState)
  const [scheduleOrderNumber, setScheduleOrderNumber] = useState(initialState)

  let newScanObj = [];

  useEffect(() => {
    setBarcodeScan(initialState);
    setScheduleLocation(initialState)
    setDisableField(false);
    setOrderNumber(initialState);
    setScan(initialState);
    setRefresh(false);
    setSerialNumberBarcodeSkuName(initialState);
    setSerialTag(null);
    setOpenSuccess(false);
    setPreviouslyScanned([]);
    document.querySelector('[tabindex = "1"]').focus();
    setShippingCheckButtonStyle({
      animation: "none",
      borderRadius: "125px"
    });
    setShippingXButtonStyle({
      animation: "none",
      borderRadius: "125px"
    });
  }, [refresh]);

  //handleClick function is used to keep field focused when user clicks on other areas of the page.
  const handleClick = (e) => {
    if (
      inputField1Ref.current &&
      inputField2Ref.current &&
      inputField3Ref.current &&
      !inputField1Ref.current.contains(e.target) &&
      !inputField3Ref.current.contains(e.target) &&
      !inputField2Ref.current.contains(e.target)
    ) {
      // Click occurred outside of both input fields, so refocus on inputField1
      document.querySelector('[tabindex = "1"]').focus();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const checkForPrinter = async () => {
    if (window.localStorage.getItem("station printer/s") != null) {
      setStationPrinterInfo(
        JSON.parse(window.localStorage.getItem("station printer/s"))
      );
    }
  };

  useEffect(() => {
    dispatch(getStatusList()).then((res) => {
      setStatus(res.payload);
    });
    /// Used to get location / station area
    let newData = JSON.parse(window.localStorage.getItem("location"));

    if (newData === null) {
    } else {
      setStatusId(newData.id);
      setLocation(newData);

      let userid = JSON.parse(window.localStorage.getItem("userId"));
      setUserId(userid);

      /// Used to get machine status location
      let machineLocation = JSON.parse(window.localStorage.getItem("machine"))
        ? JSON.parse(window.localStorage.getItem("machine"))
        : null;
      setStationName(machineLocation);

      if (newData.station_required == 1) {
        setMachineRequired(true);
      }
      if (newData.allow_batch_scan == 1) {
        setMachineRequired(true);
        history.push("/batch_scan");
      }
      if (newData.allow_truck_scan == 1) {
        setMachineRequired(true);
        history.push("/truck_scan");
      }
      if (newData.id == 55) {
        history.push("/shipped_scan");
      }
    }
    getUserScans(JSON.parse(window.localStorage.getItem("userId")), statusId);
    checkForPrinter();
  }, [statusId]);

  /* useEffect to set user information */
  useEffect(() => {
    let firstName = window.localStorage.getItem("firstName");
    let lastName = window.localStorage.getItem("lastName");
    setUser(firstName + " " + lastName);
    let station = JSON.parse(window.localStorage.getItem("location"));
    let st = station ? station.name : "";
    setArea(st);
  }, [statusId, refresh, locationHistory.key, location]);

  const getUserScans = async (userId, statusId) => {
    let total = await getUserTotalScans(dispatch, userId, statusId);
    setTotalScanned(total);
  };

  // RFID
  useEffect(() => {
    localStorage.setItem("lastActivity", new Date());
    if (serialTag != null && serialTag.length == 24) {
      setScanError("");
      setFailRed(false);
      let serials = { serial_numbers: [serialTag] };
      setDisableField(true);
      setLoading(true);
      dispatch(validateBatchScans(serials, statusId)).then(async (res) => {
        if (res.payload.length > 0) {
          setSerialNumberBarcodeSkuName(res.payload[0].schedule?.sku);
          setScheduleLocation(res.payload[0].schedule?.location)
          setScheduleOrderNumber(res.payload[0].schedule?.order_number)
          let scans = res.payload[0];
          let isScanned = scans.scans;
          const pvs = previouslyScanned.filter((pv) => pv == serialTag);
          if (pvs.length > 0) {
            setScanError(`Please scan valid Serial Number`);
            setLoading(false);
            alertRed();
            return false;
          }
          if (
            isScanned.length > 0 ||
            (isScanned.epc === serialTag && statusId == isScanned.status_id)
          ) {
            setPreviouslyScanned((prev) =>
              prev.filter((data) => {
                data !== scans.serial_number;
              })
            );
            let lastScanInfo = isScanned[0];
            let lastUserScanned = isScanned[0].user;
            let firstName = lastUserScanned.first_name;
            let lastName = lastUserScanned.last_name;
            let date = lastScanInfo.date;
            let sku = lastScanInfo.rfid_scan;
            setScanError(
              `${sku} - Scanned on ${moment(date).format(
                "MM-DD-YYYY hh:mm A"
              )} by ${firstName} ${lastName}`
            );
            setLoading(false);
            alertRed();
            return false;
          } else {
            setPreviouslyScanned((prev) => [...prev, serialTag]);
            const timeOutId = setTimeout(async () => {
              let serials = { serial_numbers: [serialTag], scans };
              scannedData(serials);
              200;
            });
            return () => clearTimeout(timeOutId);
          }
        } else {
          setScanError(`Not A Valid Serial Number`);
          alertRed();
          setLoading(false);
          return false;
        }
      });
    }
  }, [serialTag]);

  /* function executes if RFID tag has already been scanned */
  const scannedData = (scheduleData, scheduleComponent) => {
    let newObj = {
      status_id: statusId,
      epc: epc,
      rfid_scan: "",
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      pass: 1,
      user_id: userId,
      shipping_location: "",
      schedule_id: scheduleData.scans.schedule_id,
      serial_id: scheduleData.scans.id,
      station_id: stationName ? stationName : null,
      printerArray: stationPrinterInfo,
      order_number: "",
      status: location
    };
    setLoading(false);
    setDisabled(false);
    newScanObj.push(newObj);

    status.find((stp) => {
      if (stp.id == statusId) {
        if (stp.pass_fail == 1) {
          setLoading(false);
          setScanLogObj(newScanObj);
          return false;
        }
      }
    });
    setShippingObj(newObj);
  };

  /* submit shipping */
  const submitShipping = async (obj) => {
    shippedObj.order_number = orderNumber;
    setSerialNumberBarcodeSkuName("");
    setBarcodeScan("");
    dispatch(postScanLog(shippedObj)).then((res) => {
      if (res.type === "SCAN_LOG_SUCCESS") {
        setPreviouslyScanned((prev) =>
          prev.filter((data) => data !== res.payload.epc)
        );
        getUserScans(userId, statusId);
      }
    });

    setSuccessGreen(true);
    setOverlayBackdrop(true);

    setTimeout(() => {
      setSuccessGreen(false);
      setOverlayBackdrop(false);
      setLoading(false);
      setRefresh(true);
      setShippingCheckButtonStyle({
        animation: "none",
        borderRadius: "125px"
      });
      setEnableRedShippingButton(true);
      setEnableGreenShippingButton(true);
    }, 400);
  };

  /* shipping alert red */
  const shippingAlertRed = async (obj) => {
    setScanError(`Sku does not match`);
    setEnableRedShippingButton(false);
    setShippingXButtonStyle({
      animation: "red-glowing 2s infinite",
      borderRadius: "125px"
    });
    setDisableField(true);
    setFailRed(true);
    setOverlayBackdrop(true);

    setTimeout(() => {
      setOverlayBackdrop(false);
    }, 600);
  };

  /* shipping alert green */
  const shippingAlertGreen = async (obj) => {
    setDisableField(true);
    if (obj.status.mfg_complete == 1 || obj.status.quick_scan == 1) {
      setEnableRedShippingButton(false);
      setEnableGreenShippingButton(false);
      setShippingCheckButtonStyle((prevState) => ({
        animation: "glowing 2s infinite",
        borderRadius: "125px"
      }));
      setShippedObj(obj);
    }

    if (location.child_assembly == null) {
      setLoading(false);
      setSuccessGreen(true);
      setOverlayBackdrop(true);
    }

    setTimeout(() => {
      setSuccessGreen(false);
      setOverlayBackdrop(false);
      setLoading(false);
    }, 400);
  };

  const handleStatusChange = (e) => {
    setScan("");
    setSerialTag("");
    setEpc("");
    setMachineNumber("");

    let data = e.target.value;
    let locationObj = status.find((item) => item.id == data);
    setStatusId(data);
    setStationName(initialState);

    if (locationObj) {
      setLocation(locationObj);
      if (
        locationObj.allow_batch_scan === 1 &&
        locationObj.allow_batch_scan != 0
      ) {
        setMachineRequired(true);
        history.push("/batch_scan");
      } else setMachineRequired(false);
      window.localStorage.setItem("location", JSON.stringify(locationObj));
      window.localStorage.setItem("machine", JSON.stringify(0));
      history.push("/production");
      setRefresh(true);
    }
  };

  /* focuses on next input line if line is present */
  const focusOnNextField = (e) => {
    const statusFiltering = status.filter(
      (st) =>
        st.id == statusId &&
        st.rfid == 1 &&
        st.barcode == 1 &&
        st.order_number == 1
    );
    if (statusFiltering) {
      const currentInput = document.activeElement;
      const currentTabIndex = parseInt(currentInput.getAttribute("tabIndex"));
      const nextTabIndex = currentTabIndex + 1;
      const nextInput = document.querySelector(
        '[tabindex="' + nextTabIndex + '"]'
      );
      if (nextInput !== null) {
        // Focus on the next input element
        nextInput.focus();
      }
    }
    return;
  };

  /* log user out after inactivity time is reached */

  const alertRed = (e) => {
    setDisableField(true);
    setFailRed(true);
    setOverlayBackdrop(true);
    setTimeout(() => {
      setDisabled(true);
      document.querySelector('[tabindex = "1"]').focus();
    }, 2000);

    setTimeout(() => {
      setRefresh(true);
      setOverlayBackdrop(false);
    }, 600);
  };

  const handleKeyDown = (event) => {
    setFailRed(false);
    setShippingXButtonStyle({
      animation: "none",
      borderRadius: "125px"
    });
    setShippingCheckButtonStyle({
      animation: "none",
      borderRadius: "125px"
    });
    if (
      event.key === "Enter" &&
      serialTag != null &&
      serialTag.length === 24 &&
      barcodeScan != ""
    ) {
      const input = document.querySelector('[tabindex="2"]');
      let timeout = null;
      let executing = false;

      const handleKeyup = (e) => {
        if (input.value.length > 0) {
          if (executing) {
            return;
          }

          clearTimeout(timeout);

          timeout = setTimeout(function () {
            executing = true; // Set flag to true while executing

            if (
              serialNumberSkuName === input.value &&
              serialNumberSkuName.length === input.value.length
            ) {
              let newObj = {
                ...shippingObj,
                barcode_scan: serialNumberSkuName
              };
              newScanObj.push(newObj);
              newScanObj.forEach((dataObj) => {
                shippingAlertGreen(dataObj);
                focusOnNextField();
              });
            } else {
              shippingAlertRed();
            }

            executing = false; // Reset flag after execution
          }, 500);
        }
      };

      input.addEventListener("keyup", handleKeyup);

      return () => {
        input.removeEventListener("keyup", handleKeyup);
      };
    }
  };

  return (
    <>
      <Backdrop style={{ zIndex: "1" }} open={overlayBackdrop}>
        {successGreen == true ? (
          <FontAwesomeIcon
            id="flashGreen"
            icon={faCheckCircle}
            style={{
              fontSize: "967px",
              color: "rgb(57, 237, 33)",
              zIndex: "1"
            }}
          />
        ) : (
          ""
        )}
      </Backdrop>
      <div className="production_body">
        {loading == true ? <LinearProgressBar /> : ""}
        <Box className="production_header_title" pt={2}>
          <Grid container style={{ borderBottom: "solid 1px" }}>
            <Grid container item xs={6} justifyContent="flex-start">
              <Box pl={2}>
                <Typography className="production_header_title" variant="h3">
                  <FontAwesomeIcon icon={faUser} />
                  &nbsp;{user}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <Box mr={2} mb={1}>
                <Typography className="production_header_title" variant="h3">
                  <FontAwesomeIcon icon={faSearchLocation} />
                  &nbsp;{area}&nbsp;
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="production_sub_header" pt={1} pl={1}>
          <Grid container justifyContent="center">
            <Box>
              <Tabs indicatorColor="primary"></Tabs>
            </Box>
          </Grid>
        </Box>
        {failRed == true ? (
          <Alert variant="filled" severity="error" className="error">
            <Typography variant="h4"> {scanError}</Typography>
          </Alert>
        ) : (
          ""
        )}
        <Grid container style={{ marginBottom: "-65px" }}>
          <Grid item lg={8} xs={6}>
            <Box
              className="production_user_scanned-box"
              m={12}
              xs={6}
              textAlign="center"
            >
              <Typography
                className="production_user_scanned"
                style={{
                  backgroundColor: "white"
                }}
              >
                {totalScanned}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} lg={4}>
            <Grid container justifyContent="center">
              <Box mt={6} className="refresh-box">
                <Button
                  id="refresh"
                  onClick={(e) => {
                    setRefresh(true);
                    getUserScans(userId, statusId);
                    setFailRed(false);
                    setBarcodeScan("");
                    document.querySelector('[tabindex = "1"]').focus();
                    setShippingCheckButtonStyle({
                      animation: "none",
                      borderRadius: "125px"
                    });
                    setShippingXButtonStyle({
                      animation: "none",
                      borderRadius: "125px"
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSync}
                    className="production_font_icon_sync"
                    size="3x"
                  />
                </Button>
              </Box>
            </Grid>
            <Box ml={5} pl={2} pr={10} className="production_input_label-box">
              <Box mt={3} mb={2} className="scan-status-box">
                <InputLabel className="production_input_label">
                  Scan Status
                </InputLabel>
              </Box>
              <Select
                variant="outlined"
                fullWidth
                onChange={handleStatusChange}
                value={statusId ? statusId : 0}
                options={status.map((option) => ({
                  value: option.id,
                  label: option.name,
                  required: option.station_required
                }))}
              >
                <MenuItem key={0} value={0}>
                  Select
                </MenuItem>
                {status.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <Box mt={4} className="scan-data-box">
                <Typography variant="h4">
                  <b>Scan Data</b>
                </Typography>
                <form>
                  <TextField
                    value={scan}
                    id="SearchbyScanning"
                    className="sku_textfield"
                    inputProps={{ tabIndex: "1" }}
                    disabled={disableField}
                    ref={inputField1Ref}
                    onChange={(e) => {
                      setScan(e.target.value);
                      let data = e.target.value;
                      if (data.toUpperCase().includes("STA")) {
                        setScanError("Station setting not available!");
                        alertRed();
                      }
                      if (
                        e.target.value.toUpperCase().includes("E2C") &&
                        e.target.value.length == 9
                      ) {
                        loginNewUser(dispatch, e.target.value, USER_SCAN_PASS);
                      }
                      if (
                        e.target.value.length == 24 &&
                        !e.target.value.toUpperCase().includes("STA")
                      ) {
                        setSerialTag(e.target.value);
                        setScan(e.target.value);
                        setEpc(e.target.value);
                        focusOnNextField(e);
                      }
                    }}
                    fullWidth
                    variant="filled"
                    label="Electronic Product Code"
                    InputProps={{
                      minLength: 22,
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            style={{ marginTop: "2px" }}
                            icon={faSearchMinus}
                            size="lg"
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                  {serialNumberSkuName != "" ? (
                    <Typography className="serial_number-sku_textfield">
                      {serialNumberSkuName}
                    </Typography>
                  ) : (
                    ""
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    className="sku_textfield"
                    ref={inputField2Ref}
                    inputProps={{ tabIndex: "2" }}
                    style={{ marginTop: "10px" }}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                      setBarcodeScan(e.target.value);
                    }}
                    value={barcodeScan}
                    label="Packing Slip Sku"
                    InputProps={{
                      minLength: 16,
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faBarcode} size="lg" />
                        </InputAdornment>
                      )
                    }}
                  />
                  <Box pt={2}>
                    <Typography className="scanned_typography" variant="h5">
                      Order Number
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    variant="filled"
                    inputProps={{ tabIndex: "3" }}
                    ref={inputField3Ref}
                    className="sku_textfield"
                    label="Order Barcode"
                    onChange={(e) => {
                      setOrderNumber(e.target.value);
                    }}
                    value={orderNumber}
                    // ref={orderRef}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            icon={faShippingFast}
                            size="lg"
                            style={{ marginTop: "5px" }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                </form>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* shipping status check and cancel buttons */}
        <Grid
          container
          justifyContent="center"
          className="shipping-buttons-grid"
        >
            <Grid item xs={12}>
                <Box sx={{ fontWeight: "bold" }} ml={1} textAlign="center">
                  <Typography
                    style={{
                      fontSize: "36px",
                      fontWeight: "700px",
                      resize: "both"
                    }}
                  >
                    {scheduleLocation ? (
                      <Box mt={5}>
                      <Grid container>
                        <Grid item xs={12}>  
                        Location: {scheduleLocation !=0 ?scheduleLocation :""}</Grid>
                        <Grid item xs={12}> Order: {scheduleOrderNumber}</Grid>
                      </Grid>
            
                      </Box>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Grid>
          <Grid item xs={6} md={6}>
            <Box textAlign="center" mt={4}>
              <Button
                className="shipping-green-button"
                style={shippingCheckButtonStyle}
                disabled={enableGreenShippingButton}
                onClick={(e) => {
                  submitShipping();
                }}
              >
                <FontAwesomeIcon
                  className="icon-green-check"
                  style={{ color: "green" }}
                  icon={faCheckCircle}
                  size="10x"
                />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box textAlign="center" mt={6}>
              <Button
                className="shipping-red-button"
                style={shippingXButtonStyle}
                disabled={enableRedShippingButton}
                onClick={(e) => {
                  setFailRed(false);
                  setRefresh(true);
                  getUserScans(userId, statusId);
                  document.querySelector('[tabindex = "1"]').focus();
                  setShippingCheckButtonStyle({
                    animation: "none",
                    borderRadius: "125px"
                  });
                  setShippingXButtonStyle({
                    animation: "none",
                    borderRadius: "125px"
                  });
                  setEnableRedShippingButton(true);
                  setEnableGreenShippingButton(true);
                }}
              >
                <FontAwesomeIcon
                  className="icon-red-x"
                  style={{ color: "red" }}
                  icon={faTimesCircle}
                  size="10x"
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
