
export const MATERIAL_GROUP_UPDATE_FIELD = 'MATERIAL_GROUP_UPDATE_FIELD'
export const MATERIAL_UPDATE_BLEND = 'MATERIAL_UPDATE_BLEND'

export const MATERIAL_GROUP_LOADING_REQUEST = 'MATERIAL_GROUP_LOADING_REQUEST'
export const MATERIAL_GROUP_LOADING_SUCCESS = 'MATERIAL_GROUP_LOADING_SUCCESS'
export const MATERIAL_GROUP_LOADING_ERROR = 'MATERIAL_GROUP_LOADING_ERROR'

export const MATERIAL_GROUPS_LOADING_REQUEST = 'MATERIAL_GROUPS_LOADING_REQUEST'
export const MATERIAL_GROUPS_LOADING_SUCCESS = 'MATERIAL_GROUPS_LOADING_SUCCESS'
export const MATERIAL_GROUPS_LOADING_ERROR = 'MATERIAL_GROUPS_LOADING_ERROR'

export const MATERIAL_GROUP_IMPORT_LOADING_REQUEST = 'MATERIAL_GROUP_IMPORT_LOADING_REQUEST'
export const MATERIAL_GROUP_IMPORT_LOADING_SUCCESS = 'MATERIAL_GROUP_IMPORT_LOADING_SUCCESS'
export const MATERIAL_GROUP_IMPORT_LOADING_ERROR = 'MATERIAL_GROUP_IMPORT_LOADING_ERROR'

export const MATERIAL_GROUP_SAVING_REQUEST = 'MATERIAL_GROUP_SAVING_REQUEST'
export const MATERIAL_GROUP_SAVING_SUCCESS = 'MATERIAL_GROUP_SAVING_SUCCESS'
export const MATERIAL_GROUP_SAVING_ERROR = 'MATERIAL_GROUP_SAVING_ERROR'

export const MATERIAL_GROUPS_SAVING_REQUEST = 'MATERIAL_GROUPS_SAVING_REQUEST'
export const MATERIAL_GROUPS_SAVING_SUCCESS = 'MATERIAL_GROUPS_SAVING_SUCCESS'
export const MATERIAL_GROUPS_SAVING_ERROR = 'MATERIAL_GROUPS_SAVING_ERROR'


export const MG_MATERIALS_LOADING_REQUEST = 'MG_MATERIALS_LOADING_REQUEST'
export const MG_MATERIALS_LOADING_SUCCESS = 'MG_MATERIALS_LOADING_SUCCESS'
export const MG_MATERIALS_LOADING_ERROR = 'MG_MATERIALS_LOADING_ERROR'

export const ADD_MATERIAL = 'ADD_MATERIAL'
export const MATERIAL_ADDED = 'MATERIAL_ADDED'
export const REMOVE_MATERIAL = 'REMOVE_MATERIAL'
export const MATERIAL_REMOVED = 'MATERIAL_REMOVED'


