import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MainNavLink = (props) => {
  const { label, route, icon, children, className, ...rest } = props;

  const activeStyle = {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#1d4171"
  };

  const normalStyle = {
    color: "#efefef",
    padding: "20px 15px",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
    // borderRight: '2px solid #1e4271'
  };

  const labelStyle = {
    paddingLeft: "15px",
    textTransform: "uppercase",
    fontWeight: "500"
  };

  const addedClass = "nav-navlink " + (className || "");

  return (
    <div className={addedClass}>
      <NavLink
        exact
        {...rest}
        to={route}
        activeStyle={activeStyle}
        style={normalStyle}
        className={className}
      >
        {label === "Did It Ship?" ? (
          <img
            width="30"
            src="https://assets.brooklynbedding.com/wp-content/uploads/2018/04/18120722/bb-logo-white.png"
            alt=""
          />
        ) : (
          ""
        )}
        {icon != null && (
          <FontAwesomeIcon icon={icon} style={{ fontSize: "1.75em" }} />
        )}
        <span style={labelStyle}>{label}</span>
      </NavLink>
      {children ? <div className="nav-submenu">{children}</div> : ""}
    </div>
  );
};

MainNavLink.propTypes = {
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
};

export default MainNavLink;
