import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getClosedTrucks } from "../../../actions/scheduleActions";
import MaterialTable from "@material-table/core";
import moment from "moment";
import ClosedLoad from "./ClosedLoad";
import { printTruckShippedPdf } from "../../../actions/shipmentActions";

const ClosedTruck = () => {
  const dispatch = useDispatch();
  const [closedTruckData, setClosedTruckData] = useState([]);
  const [dataObj, setDataObj] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [truckStatus, setTruckStatus] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    dispatch(getClosedTrucks()).then((res) => {
      const data = res.payload;
      setClosedTruckData(res.payload);
      let newData = [];
      let truck = {};
      let order = new Map();

      for (let i = 0, len = data.length; i < len; i++) {
        let item = data[i];
        let p = item.truck_number;
        let odr = item.order_number;
        if (!truck[p]) {
          truck[p] = [];
        }
        truck[p].push(item);

        if (!order.has(odr)) {
          order.set(odr, []);
        }
        order.get(odr).push(item);
      }

      for (let ord of order.keys()) {
        let data = { order_number: ord };
        newData.push(data);
        for (let item of order.get(ord)) {
          const customer = item.factOrder[0];

          if (data.order_number === item.order_number) {
            data.truck_number = item.truck_number;
            data.customer = customer?.orderShipName
              ? customer?.orderShipName
              : "N/A";
            data.stop_number = item.stop_number;
            data.schedule = moment(item.date).format("MM-DD-YYYY");
            data.date_closed = moment(item.date_closed).format("MM-DD-YYYY");
          }
        }
        let totalPieces = order.get(ord);

        data.rowData = totalPieces;
        data.total_pieces = totalPieces.length;
        const foundation = [];
        const matt = [];

        for (let item of order.get(ord)) {
          if (item.skuObj.category_id == 1) {
            matt.push(item);
          } else if (item.skuObj.category_id == 10) {
            foundation.push(item);
          }
        }

        data.matt = matt.length;
        data.foundation = foundation.length;
      }
      setDataObj(newData);
    });
  }, []);

  const getTruck = (truck) => {
    const loadClosed = [];
    closedTruckData.filter((closedInfo) => {
      if (
        truck.date_closed ===
          moment(closedInfo.date_closed).format("MM-DD-YYYY") &&
        truck.truck_number === closedInfo.truck_number
      ) {
        loadClosed.push(closedInfo);
      }
    });
    setTableData(loadClosed);
    setTruckStatus(false);
  };

  const headers = [
    {
      title: "Closed Date",
      field: "date_closed"
    },

    {
      title: "Truck",
      field: "truck_number",
      defaultSort: "desc"
    },
    {
      title: "Stop",
      field: "stop_number",
      defaultSort: "desc"
    },
    {
      title: "Order Number",
      field: "order_number"
    },
    {
      title: "Scheduled",
      field: "schedule"
    },
    {
      title: "Customer",
      field: "customer"
    },
    {
      title: "Total Pieces",
      field: "total_pieces"
    },
    {
      title: "Matt",
      field: "matt"
    },
    {
      title: "FND",
      field: "foundation"
    }
  ];

  const ExportPdf = async (rowData) => {
    const pickUpTruck = rowData.rowData;
    let newData = [];
    let order = new Map();
    for (let i = 0, len = pickUpTruck.length; i < len; i++) {
      let item = pickUpTruck[i];
      let od = item.sku;
      if (!order.has(od)) {
        order.set(od, []);
      }
      order.get(od).push(item);
    }

    for (let ord of order.keys()) {
      let data = { sku: ord };
      newData.push(data);

      let shippedQty = [];
      for (let item of order.get(ord)) {
        let dt = order.get(ord);
        dt.filter((scans) => {
          const scanned = scans.scans;
          scanned.filter((sc) => {
            if (sc.status_id == "125") {
              shippedQty.push(sc);
            }
          });
        });
        const qty = shippedQty.map((o) => o.id);
        const qtyShipped = shippedQty.filter(
          ({ id }, index) => !qty.includes(id, index + 1)
        );

        if (data.sku === item.sku) {
          dt.filter((factOrder) => {
            let customerData = factOrder.factOrder[0];
            data.description = item.familyName;
            data.sizeName = item.sizeName;
            data.order_qty = dt.length;
            data.pickup_date = item.pickup_date;
            data.shipped_qty = qtyShipped.length;
            data.order_number = customerData.orderNumber;
            data.po = customerData.orderCustomerNotes;
            data.orderShipName = customerData.orderShipName;
            data.orderShipStreetOne = customerData.orderShipStreetOne;
            data.orderShipStreetTwo = customerData.orderShipStreetTwo;
            data.orderShipCity = customerData.orderShipCity;
            data.orderShipState = customerData.orderShipState;
            data.orderShipPostalCode = customerData.orderShipPostalCode;
            data.orderShipCountry = customerData.orderShipCountry;
            data.orderCustomerEmail = customerData.orderCustomerEmail;
            data.category_id = item.skuObj.category_id;
          });
        }
      }
    }
    const customerInfo = pickUpTruck[0].factOrder[0];

    dispatch(printTruckShippedPdf({ customerInfo, newData })).then((res) => {
      if (res.payload[0] == "201-Created") {
        // setResetPage(!resetPage);
      }
    });
  };

  return (
    <div>
      {truckStatus ? (
        <>
          <MaterialTable
            title="Closed Trucks"
            data={dataObj}
            columns={headers}
            onRowClick={(evt, selectedRow) => {
              setSelectedRow(selectedRow.tableData.id);
              getTruck({
                truck_number: selectedRow.truck_number,
                stop_number: selectedRow.stop_number,
                date_closed: selectedRow.date_closed
              });
            }}
            actions={[
              {
                icon: "print",
                tooltip: "Print order",
                onClick: (event, rowData) => {
                  ExportPdf(rowData);
                }
              }
            ]}
            options={{
              search: false,
              pageSize: 20,
              addRowPosition: "first",
              actionsColumnIndex: -1,
              rowStyle: {
                fontSize: 16
              },
              rowStyle: (rowData) => ({
                fontSize: 16,
                fontWeight: "bold",
                backgroundColor:
                  selectedRow === rowData.tableData.id ? "#6ABAC9" : "#FFF"
              }),
              headerStyle: {
                fontSize: 18,
                fontWeight: "bold"
              },
              emptyRowsWhenPaging: false
            }}
          />
        </>
      ) : (
        <>
          <ClosedLoad component={tableData} />
        </>
      )}
    </div>
  );
};

export default ClosedTruck;
