import React from 'react'
import '../styles/about-page.css'
import CycleCountForm from './forms/CycleCountForm'

const CycleCountFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Cycle Count</h2>
            <CycleCountForm location={location}/>
        </div>
    )
}

export default CycleCountFormPage