import React from 'react'
import '../styles/about-page.css'
import MaterialGroupForm from './forms/MaterialGroupForm'

const MaterialGroupFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">MaterialGroup</h2>
            <MaterialGroupForm location={location}/>
        </div>
    )
}

export default MaterialGroupFormPage