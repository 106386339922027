import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {getParentCore, saveParentCore, updateField, getParentCores} from "../../actions/parentCoreActions"
import ConfirmationModal from '../confirmationModal/ConfirmationModal';

class ParentCoreForm extends React.Component {
  
    constructor(props) {
      super(props)
      // Bind the this context to the handler function
      this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
      const { dispatch } = this.props;
        dispatch(getParentCores())
        .then(res => this.setState({parentCores: [...res.payload]}))
    }

    handleChange = e => {
      const { dispatch } = this.props
      dispatch(updateField(e.target.name, e.target.value.toUpperCase()))
    }

    state = {
      parentCoreError: '',
      parentCores: [],
      //modal state
      modalOpenStatus: false,
      success: "",
      redirect: ""
    }
  
    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            id,
            parentCore,
        } = this.props
       
        let parentCoreExists =  this.state.parentCores.find(pc => pc.parent_core === parentCore);
        if(parentCoreExists){
            this.setState({parentCoreError: 'This Parent Core exists already!'});
        } else {
            const parentCoreObj = {
                parent_core: parentCore,
            };
           
            await dispatch(saveParentCore(parentCoreObj))
              .then(res => {
                if(res.type == "PARENT_CORE_SAVING_SUCCESS") {
                  this.setState({
                    modalOpenStatus: true,
                    success: [
                      {title: "Success!"},
                      {body: `${res.payload.parent_core} parent core created successfully! You will now be redirected to the core list.`}
                    ], 
                    redirect: `/mat-core/parentCore/${res.payload.id}`
                  })
                }
              });
        }

    }

    render() {
      return (
        <div className="baseContainer projectForm" style={{height: "100vh"}}>
          <ConfirmationModal
            modalOpenStatus={this.state.modalOpenStatus}
            success={this.state.success}
            redirect={this.state.redirect}
          />
          <h1>Add new Parent Core</h1>
          <div>
            <TextField
              label="Parent Core"
              name="parentCore"
              margin="normal"
              variant="outlined"
              className="name-field"
              onChange={this.handleChange}
            />
          </div>
          {this.state.parentCoreError != '' ? <span style={{fontSize: 18, color: 'red', fontWeight: 'bold'}}>{this.state.parentCoreError}</span> : ''}
          <div style={{marginTop: 20}}>
            <Button
              variant="contained"
              style={{background: 'rgb(30, 66, 113)', color: 'white'}}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      )
  };
};

ParentCoreForm.defaultProps = {

}

ParentCoreForm.propTypes = {
  isLoading: T.bool,
  isError: T.bool,
  saveSuccess: T.bool,
  id: T.number,
  parentCore: T.string,
}

function mapStateToProps(state) {
  const { parentCoreReducer } = state
  return {
    isError: parentCoreReducer.isError,
    saveSuccess: parentCoreReducer.saveSuccess,
    id: parentCoreReducer.id,
    parentCore: parentCoreReducer.parentCore,
    parentCores: parentCoreReducer.parentCores
  }
}

export default withRouter(connect(mapStateToProps)(ParentCoreForm))