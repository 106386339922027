
export const BOX_UPDATE_FIELD = 'BOX_UPDATE_FIELD'

export const BOX_LOADING_REQUEST = 'BOX_LOADING_REQUEST'
export const BOX_LOADING_SUCCESS = 'BOX_LOADING_SUCCESS'
export const BOX_LOADING_ERROR = 'BOX_LOADING_ERROR'

export const BOXES_LOADING_REQUEST = 'BOXES_LOADING_REQUEST'
export const BOXES_LOADING_SUCCESS = 'BOXES_LOADING_SUCCESS'
export const BOXES_LOADING_ERROR = 'BOXES_LOADING_ERROR'

export const BOX_IMPORT_LOADING_REQUEST = 'BOX_IMPORT_LOADING_REQUEST'
export const BOX_IMPORT_LOADING_SUCCESS = 'BOX_IMPORT_LOADING_SUCCESS'
export const BOX_IMPORT_LOADING_ERROR = 'BOX_IMPORT_LOADING_ERROR'

export const BOX_SAVING_REQUEST = 'BOX_SAVING_REQUEST'
export const BOX_SAVING_SUCCESS = 'BOX_SAVING_SUCCESS'
export const BOX_SAVING_ERROR = 'BOX_SAVING_ERROR'

export const BOXES_SAVING_REQUEST = 'BOXES_SAVING_REQUEST'
export const BOXES_SAVING_SUCCESS = 'BOXES_SAVING_SUCCESS'
export const BOXES_SAVING_ERROR = 'BOXES_SAVING_ERROR'