import React from 'react';
import FoundationGroupList from './List/FoundationGroupList';
import '../styles/about-page.css';

export class FoundationGroupListPage extends React.Component {

    render() {
        return (
            <FoundationGroupList/>
        );
    }
}

export default FoundationGroupListPage;
