import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

import { getManufacturer, saveManufacturer, updateField} from '../../actions/manufacturerActions'
import AddNewButton from "../global/AddNewButton";
import Can from "../Can";

class ManufacturerForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        this.getManufacturer()
    }
    getManufacturer = e => {
        const {
            dispatch,
            isLoading,
        } = this.props

        const id = this.props.match.params.id
        if (id) {
            dispatch(getManufacturer(id))
        }
    }

    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSuccess) {
            this.setState({ open: true })
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            id,
            name,
            abbreviation,
            addressLineOne,
            addressLineTwo,
            city,
            state,
            postalCode,
            active,
        } = this.props

        const manufacturer = {
            id: id,
            name: name,
            abbreviation: abbreviation != null ? abbreviation : '',
            address_line_one: addressLineOne != null ? addressLineOne : '',
            address_line_two: addressLineTwo != null ? addressLineTwo : '',
            city: city != null ? city : '',
            state: state != null ? state : '',
            postal_code: postalCode != null ? postalCode : '',
            active: active === 1,
        }
        if(!id || id == -1){
            delete manufacturer.id
        }

        await dispatch(saveManufacturer(manufacturer))
        if(!isError) {
            this.props.history.push("/manufacturers")
        }
    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            id,
            name,
            abbreviation,
            addressLineOne,
            addressLineTwo,
            city,
            state,
            postalCode,
            active,
        } = this.props

        return (
            <div className="baseContainer projectForm" style={{marginLeft: 20}}>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    className="success"
                    onClose={this.handleClose}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Manufacturer saved</span>}
                />
                <h1>
                    {name}
                </h1>
                <div>
                    <TextField
                        label="Name"
                        name="name"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={name}
                        onChange={this.handleChange}
                    />
                    <TextField
                        label="Abbreviation"
                        name="abbreviation"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={abbreviation}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <TextField
                        label="Line 1"
                        name="addressLineOne"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={addressLineOne}
                        onChange={this.handleChange}
                    />
                    <TextField
                        label="Line 2"
                        name="addressLineTwo"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={addressLineTwo}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <TextField
                        label="City"
                        name="city"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={city}
                        onChange={this.handleChange}
                    />
                    <TextField
                        label="State"
                        name="state"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={state}
                        onChange={this.handleChange}
                    />
                    <TextField
                        label="Zip"
                        name="postalCode"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={postalCode}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <div id="active" className="btn" style={{fontSize: 20, marginLeft: 10, marginBottom: 10}}>
                        {active ? <span>Active</span> : <span>Inactive</span>}
                    </div>
                </div>
                <Can
                    role={this.props.role}
                    perform='sku:edit'
                    yes={() => (
                        <div style={{marginTop: 20}}>
                            <Button
                                variant="contained"
                                style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                                onClick={this.handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    )}
                    no={() => null}
                />
            </div>
        )
    }
}

ManufacturerForm.defaultProps = {

}

ManufacturerForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    name: T.string,
    abbreviation: T.string,
    addressLineOne: T.string,
    addressLineTwo: T.string,
    city: T.string,
    state: T.string,
    postalCode: T.string,
    // active: T.bool,
}

function mapStateToProps(state) {
    const { manufacturerForm, loginForm } = state
    return {
        isError: manufacturerForm.isError,
        saveSuccess: manufacturerForm.saveSuccess,
        id: manufacturerForm.id,
        name: manufacturerForm.name,
        abbreviation: manufacturerForm.abbreviation,
        addressLineOne: manufacturerForm.addressLineOne,
        addressLineTwo: manufacturerForm.addressLineTwo,
        city: manufacturerForm.city,
        state: manufacturerForm.state,
        postalCode: manufacturerForm.postalCode,
        active: manufacturerForm.active,
        role: loginForm.userRole
    }
}

export default withRouter(connect(mapStateToProps)(ManufacturerForm))