import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

import { getBrand, saveBrand, updateField} from '../../actions/brandActions'

class BrandForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        this.getBrand()
    }

    getBrand = e => {
        const {
            dispatch,
            isLoading,
        } = this.props

        const id = this.props.match.params.id
        if (id) {
            dispatch(getBrand(id))
        }
    }

    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSuccess) {
            this.setState({ open: true })
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
        brandError: ''
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            id,
            brand,
            brands
        } = this.props

        let brandExists = await brands.find(b => b.brand === brand)
        if(brandExists){
            this.setState({brandError: 'This Brand exists already!'})
        } else {
            const brandObj = {
                id: id,
                brand: brand,
            }
            if(!id || id === -1){
                delete brandObj.id
            }
            await dispatch(saveBrand(brandObj))
            if(!isError) {
                this.props.history.push("/brands")
            }
        }


    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            id,
            brand,
        } = this.props

        return (
            <div className="baseContainer projectForm">
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    className="success"
                    onClose={this.handleClose}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Brand saved</span>}
                />
                <h1>
                </h1>
                <div>
                    <TextField
                        label="Brand"
                        name="brand"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={brand}
                        onChange={this.handleChange}
                    />
                </div>
                {this.state.brandError != '' ? <span style={{fontSize: 18, color: 'red', fontWeight: 'bold'}}>{this.state.brandError}</span> : ''}
                <div style={{marginTop: 20}}>
                    <Button
                        variant="contained"
                        style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        )
    }
}

BrandForm.defaultProps = {

}

BrandForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    brand: T.string,
}

function mapStateToProps(state) {
    const { brandReducer } = state
    return {
        isError: brandReducer.isError,
        saveSuccess: brandReducer.saveSuccess,
        id: brandReducer.id,
        brand: brandReducer.brand,
        brands: brandReducer.brands
    }
}

export default withRouter(connect(mapStateToProps)(BrandForm))