export const TOP_PANEL_TICKS_LOADING_ERROR = 'TOP_PANEL_TICKS_LOADING_ERROR'
export const TOP_PANEL_TICKS_LOADING_REQUEST = 'TOP_PANEL_TICKS_LOADING_REQUEST'
export const TOP_PANEL_TICKS_LOADING_SUCCESS = 'TOP_PANEL_TICKS_LOADING_SUCCESS'

export const SIDE_PANEL_TICKS_LOADING_ERROR = 'SIDE_PANEL_TICKS_LOADING_ERROR'
export const SIDE_PANEL_TICKS_LOADING_REQUEST = 'SIDE_PANEL_TICKS_LOADING_REQUEST'
export const SIDE_PANEL_TICKS_LOADING_SUCCESS = 'SIDE_PANEL_TICKS_LOADING_SUCCESS'

export const BOTTOM_PANEL_TICKS_LOADING_ERROR = 'BOTTOM_PANEL_TICKS_LOADING_ERROR'
export const BOTTOM_PANEL_TICKS_LOADING_REQUEST = 'BOTTOM_PANEL_TICKS_LOADING_REQUEST'
export const BOTTOM_PANEL_TICKS_LOADING_SUCCESS = 'BOTTOM_PANEL_TICKS_LOADING_SUCCESS'

export const GUSSET_PANEL_TICKS_LOADING_ERROR = 'GUSSET_PANEL_TICKS_LOADING_ERROR'
export const GUSSET_PANEL_TICKS_LOADING_REQUEST = 'GUSSET_PANEL_TICKS_LOADING_REQUEST'
export const GUSSET_PANEL_TICKS_LOADING_SUCCESS = 'GUSSET_PANEL_TICKS_LOADING_SUCCESS'

export const PANEL_LAYERS_LOADING_REQUEST = 'PANEL_LAYERS_LOADING_REQUEST'
export const PANEL_LAYERS_LOADING_SUCCESS = 'PANEL_LAYERS_LOADING_SUCCESS'
export const PANEL_LAYERS_LOADING_ERROR = 'PANEL_LAYERS_LOADING_ERROR'