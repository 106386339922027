import React, { useState } from "react"
import T from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../styles/about-page.css';
import {getActiveSkus, getInactiveSkus, saveSkuList, getSkuGroups, getSkuDrafts, updateField} from "../../actions/skuActions";
import {getColumns} from "../../actions/globalActions";
import {ExcelRenderer} from 'react-excel-renderer';
import MUIDataTable from "mui-datatables";
import {getDBFormattedDate} from "../../utils/dates";
import {setValueByType} from "../../utils/object";
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";
import Tabs from "@material-ui/core/es/Tabs/Tabs";
import {Tab, TableRow} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import TableCell from "@material-ui/core/TableCell";
import {getFamilies} from "../../actions/familyActions";
import {getBrands} from "../../actions/brandActions";
import {getSizes} from "../../actions/sizeActions";
import {getManufacturers} from "../../actions/manufacturerActions";
import Can from "../Can";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

function TabContainer({ children, dir }) {
    // console.log(children, 'children of tab container')
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: T.node.isRequired,
    dir: T.string.isRequired,
};

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'block',
        marginTop: 20
    },
});

class SkuList extends React.Component {

    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    };

    handleChangeIndex = index => {
        this.setState({ tab: index });
    };

    //Gets column headers for mapping
    fileHandlerGetHeaders =(event) => {
        const {
            skuColumns,
            date,
        } = this.props

        let fileObj = event.target.files[0];
        // console.log(fileObj, 'file object in file handle headers')

        if (event.target.files.length > 1) {
            let inputMessage = event.target.files.length + ' files selected';
            this.setState({inputMessage: inputMessage});
        } else if (event.target.files && event.target.files.length === 1 && event.target.files[0].name) {
            this.setState({inputMessage: event.target.files[0].name});
        } else {
            this.setState({inputMessage: 'Choose a file'});
        }

        this.fileHandlerGetData(event)
    }

    //associate ids to excel values
    getFamilyList() {
        const{dispatch,} = this.props
        dispatch(getFamilies())
    }
    getBrandList() {
        const{dispatch,} = this.props
        dispatch(getBrands())
    }
    getSizeList() {
        const {dispatch} = this.props
        dispatch(getSizes())
    }
    getManufacturerList() {
        const {dispatch} = this.props
        dispatch(getManufacturers())
    }

    //sku object with corresponding ids to entries
    masterSkuObj = {
        id: null,
        // active: active,
        // brand_id: '',
        // size_id: '',
        // family_id: familyId,
        // skuAttribute: skuAttribute,
        // customer_id: customerId,
        // manufacturer_id: manufacturerId,
        // // prototype_id: prototypeId,
        // material_group_id: ltMaterialGroup && ltMaterialGroup.id ? ltMaterialGroup.id : null,
        // foundation_group_id: ltFoundationGroup && ltFoundationGroup.id ? ltFoundationGroup.id : null,
        // skuTopPanelAttribute: skuTopPanelAttribute,
        // skuGussetPanelAttribute: skuGussetPanelAttribute,
        // skuSidePanelAttribute: skuSidePanelAttribute,
        // skuBottomPanelAttribute: skuBottomPanelAttribute,
        // //topPanel on existing sku is an object but if we are creating a sku it is an id ...i think
        // top_panel_id: id != -1 ? topPanel.id : topPanel,
        // gussett_panel_id: id != -1 ? gussetPanel.id : gussetPanel,
        // side_panel_id: id != -1 ? sidePanel.id : sidePanel,
        // bottom_panel_id: id != -1 ? bottomPanel.id : bottomPanel,
        // skuTape: skuTape != undefined ? skuTape : null,
        // //just for testing purposes
        // sku: ''
    }


    fileHandlerGetData = (event) => {

        const {
            skuColumns,
            date,
        } = this.props

        let fileObj = event.target.files[0];


        if (event.target.files.length > 1) {
            let inputMessage = event.target.files.length + ' files selected';
            this.setState({ inputMessage: inputMessage });
        } else if (event.target.files && event.target.files.length === 1 && event.target.files[0].name) {
            this.setState({ inputMessage: event.target.files[0].name });
        } else {
            this.setState({ inputMessage: 'Choose a file' });
        }

        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                // console.log(err, 'err in handleGetData')
            } else {
                const headerRaw = resp.rows[0]
                const headerRow = headerRaw.map(function(h) {
                    return h.toLowerCase().trim()
                })
                const skus = []
                //     console.log(headerRow)
                if(resp.rows.length > 0) {

                    for (let i = 1; i < resp.rows.length; i++) {
                        let row = {}

                        for (let c = 0; c < skuColumns.length; c++) {
                            const label = skuColumns[c].label.toLowerCase().trim()
                            const key = skuColumns[c].key
                            const dataType = skuColumns[c].data_type

                            const idx = Object.keys(headerRow).find(key => headerRow[key] === label.toLowerCase().trim())

                            if (idx !== undefined) {
                                if (resp.rows[i][idx] !== undefined) {
                                    // console.log(row, 'row console.log to find out what to put for firmness')
                                    if(label === 'brand'){
                                        const {brands} = this.props
                                        let brandId = null
                                        const brandObj = brands.find(item => item.brand.toLowerCase() === resp.rows[i][idx].toString().toLowerCase())
                                        brandObj !== undefined ? brandId = brandObj.id : ''
                                        // console.log(brandId, 'brandId')
                                        this.masterSkuObj.brand_id = brandId;
                                        row['brand'] = {brand: setValueByType(resp.rows[i][idx] == null ? '' : resp.rows[i][idx].toString().replace(/"/gi, ' inch').replace(/'/, '').trim(), dataType)}
                                    }
                                    else if(label === 'family'){
                                        const {families} = this.props
                                        let familyId = null
                                        const familyObj = families.find(item => item.family.toLowerCase() === resp.rows[i][idx].toString().toLowerCase())
                                        familyObj !== undefined ? familyId = familyObj.id : ''
                                        // console.log(familyId, 'familyId')
                                        this.masterSkuObj.family_id = familyId
                                        row['family'] = {family: setValueByType(resp.rows[i][idx] == null ? '' : resp.rows[i][idx].toString().replace(/"/gi, ' inch').replace(/'/, '').trim(), dataType)}
                                    }
                                    else if(label === 'size'){
                                        const {sizes} = this.props
                                        let sizeId = null
                                        const sizeObj = sizes.find(item => item.size.toLowerCase() === resp.rows[i][idx].toString().toLowerCase())
                                        // sizeObj!== undefined ? sizeId = sizeObj.id : ''
                                        // console.log(sizeId, 'sizeId')
                                        this.masterSkuObj.size_id = sizeId
                                        row['size'] = {size: setValueByType(resp.rows[i][idx] == null ? '' : resp.rows[i][idx].toString().replace(/"/gi, ' inch').replace(/'/, '').trim(), dataType)}
                                    }
                                    else if(label === 'firmness'){
                                        // console.log(resp.rows[i][idx], 'firmness repsonse')
                                        row['firmness'] = {firmness: setValueByType(resp.rows[i][idx] == null ? '' : resp.rows[i][idx].toString().replace(/"/gi, ' inch').replace(/'/, '').trim(), dataType)}
                                    }
                                    else if (label === 'manufacturer') {
                                        // console.log(resp.rows[i][idx], 'MANUFACTURER repsonse')
                                        const {manufacturers} = this.props
                                        // console.log(manufacturers, 'list o fmanufacturers in else if')
                                        let manufacturerId = null
                                        const manufacturer = manufacturers.find(item => item.name === resp.rows[i][idx])
                                        // console.log(manufacturer, 'obj manufacturer')
                                        manufacturer !== undefined ? manufacturerId = manufacturer.id : ''
                                        // console.log(manufacturerId, 'manufacturer')
                                        this.masterSkuObj.manufacturer_id = manufacturerId
                                    }
                                    else {
                                        row[key] = setValueByType(resp.rows[i][idx] == null ? '' : resp.rows[i][idx].toString().replace(/"/gi, ' inch').replace(/'/, '').trim(), dataType)
                                    }
                                    // else if(label === 'customer'){
                                    //     row[key] = setValueByType(resp.rows[i][idx] == null ? '' : resp.rows[i][idx].toString().replace(/"/gi, ' inch').replace(/'/, '').trim(), dataType)
                                    // }
                                    // else if(label === 'manufactuer'){
                                    //     row[key] = setValueByType(resp.rows[i][idx] == null ? '' : resp.rows[i][idx].toString().replace(/"/gi, ' inch').replace(/'/, '').trim(), dataType)
                                    // }
                                }
                                else {
                                    row[key] = setValueByType('', dataType)
                                }
                            }
                        }
                        row.id = null
                        // row.user_id = parseInt(localStorage.getItem('userId'))
                        // row.date = date
                        // console.log(this.masterSkuObj, 'master sku obj after upload')
                        // console.log(row, 'row from excel sheet')
                        // console.log(row.sku, 'row.sku')
                        if(row.sku && row.sku != '') {
                            skus.push(row)
                        }
                        // console.log(skus, 'skus array')
                    }
                }
                this.setState({
                    skuCols: resp.skuCols,
                    skuRows: resp.rows,
                    newSkus: skus,
                    date: this.props.date,
                    file: '',
                    filename: fileObj.name,
                }, )
            }
        })
    }


    getOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <Can
                        role={this.props.role}
                        perform='sku:edit'
                        yes={() => (
                            <AddNewButton
                                entity={"sku/-1"}
                                history={this.props.history}
                                data={data}
                            />
                        )}
                        no={() => null}
                    />
                )
            },

        }
        return options
    }

    getInActiveOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
        }
        return options
    }

    getImportTableOptions(data) {
        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            customRowRender: (data, dataIndex, rowIndex) => {
                // console.log(data, 'data in table import options')
                const jobId = data[0]
                let jobClass = 'job-row-odd'
                if (jobId % 2) {
                    jobClass = 'job-row-even'
                }
                const colSpan = 1;
                let row = 0
                let job = 0
                return (
                    <TableRow className={jobClass}>
                        {/*{data.map((d, i) => {*/}
                        {/*    // console.log(d, 'data map of the getImportTableOptions')*/}
                        {/*    // console.log(i, ' INDEX data map of the getImportTableOptions')*/}
                        {/*    return (*/}
                        {/*        <TableCell key={i}>*/}
                        {/*            <Typography>{d}</Typography>*/}
                        {/*        </TableCell>*/}
                        {/*    )*/}

                        {/*})}*/}
                    </TableRow>
                )
            },
            //  selectableRowsHeader: true,
            //   selectableRows: 'miltiple',
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            skus: [],
            skuRows: [],
            skuCols: [],
            skuColumns: [],
            filename: '',
            file: '',
            inputMessage: 'Choose a file',
            status: 'Active',
            tab: 0
        }
    }


    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('sku'))
    }

    //Toolbar functions
    handleSkuSaveClick = () => {
        const {
            dispatch,
            skus,
        } = this.props

        const data = {
            data: skus.concat(this.state.skus),
            filename: this.state.filename,
        }

        dispatch(saveSkuList(data))
        this.setState({
            skuCols: [],
            skuRows: [],
            skus: [],
            filename: '',
        })
    }
    handleSkuDeleteClick = () => {
        const {
        } = this.props

        this.setState({
            skuCols: [],
            skuRows: [],
            newSkus: [],
        })
    }
    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {
        //associate ids to excel values
        this.getBrandList()
        this.getFamilyList()
        this.getSizeList()
        this.getManufacturerList()
        this.getColumns()

        const {
            dispatch,
        } = this.props

        dispatch(getActiveSkus())
        dispatch(getInactiveSkus())
        dispatch(getSkuGroups())
        dispatch(getSkuDrafts())
    }

    render() {
        const {
            isLoading,
            activeSkus,
            inactiveSkus,
            skuGroups,
            skuColumns,
            groupColumns,
            draftColumns,
            skuDrafts,
            skuCols,
            tab,
            classes,
            theme
        } = this.props

        //get the family ids off of the sku groups (families) that came back
        let familyIds = skuGroups.map(f => f.id)
        //get the family_ids of all the active skus
        let assignedFamIds = activeSkus.map(s => s.family_id)
        familyIds = familyIds.filter(f => assignedFamIds.indexOf(f) !== -1)
        // console.log(familyIds, 'family ids of the families used on active skus')
        //make list of all active sku group objects based on the family ids that are on active skus
        let activeSkuGroups = skuGroups.filter(f => familyIds.indexOf(f.id) !== -1)
        // console.log(activeSkuGroups)
        // console.log(draftColumns, 'draft columns log')
        // console.log(skuDrafts, 'sku drafts log')

        return (
            <div className="baseContainer skuList">
                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.tab}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="Sku Groups"/>
                            <Tab label="Sku Drafts"/>
                            <Tab label="Active Skus" />
                            <Tab label="Discontinued Skus" />
                            {/*Can component was breaking this page.. idk why.. come back gotta keep going*/}
                            {/*<Can*/}
                            {/*    role={this.props.role}*/}
                            {/*    perform='sku:edit'*/}
                            {/*    yes={() => (*/}
                            {/*{this.props.role.find(r => r === 'admin' ||  r === 'sku_edit') ? <Tab label="Import New Skus" /> : ''}*/}
                            {/*)}*/}
                            {/*no={() => null}*/}
                            {/*/>*/}
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={this.state.tab}
                        onChangeIndex={this.handleChangeIndex}
                    >
                        <TabContainer dir={theme.direction}>
                            <MUIDataTable
                                title={"Sku Groups"}
                                data={activeSkuGroups}
                                columns={groupColumns.map(c => ({...c}))}
                                options={this.getOptions({"route": "/sku-group", "custom": true, "count": 20})}
                            />
                        </TabContainer>
                        <TabContainer dir={theme.direction}>
                            <MUIDataTable
                                title={"Sku Drafts"}
                                data={skuDrafts}
                                columns={draftColumns.map(c => ({ ...c}))}
                                options={this.getOptions({"route": "/sku-staging", "custom": true, "count": 20})}

                            />
                        </TabContainer>
                        <TabContainer dir={theme.direction}>
                            <MUIDataTable
                                title={"Active Sku List"}
                                data={activeSkus}
                                columns={skuColumns.map(c => ({ ...c, ...defaultColumnProperties }))}
                                options={this.getOptions(activeSkus)}

                            />
                        </TabContainer>
                        <TabContainer dir={theme.direction}>
                            <MUIDataTable
                                title={"Discontinued Sku List"}
                                data={inactiveSkus}
                                columns={skuColumns.map(c => ({ ...c, ...defaultColumnProperties }))}
                                options={this.getInActiveOptions(inactiveSkus)}
                            />
                        </TabContainer>

                         {/*EXCEL DISPLAY OF FILE IMPORTED */}
                        <Can
                            role={this.props.role}
                            perform='sku:edit'
                            yes={() => (
                                <TabContainer dir={theme.direction}>
                                    {this.state.fileColumns !== undefined &&
                                    this.state.fileColumns.map(c => {
                                        // console.log(c, 'filecolumns c in mapping')
                                        let isMapped = false
                                        let skuColValue = ''
                                        {skuColumns.map(sc => {
                                            // console.log(sc.label, 'sc.label in skucolumns')
                                            if (sc.label.toLowerCase().trim() === c.toLowerCase().trim()) {
                                                isMapped = true
                                                skuColValue = c
                                            }
                                        })}
                                        return (
                                            <TableRow>
                                                <TableCell>
                                                    {c}
                                                </TableCell>
                                                {isMapped && <TableCell>{skuColValue}</TableCell>}
                                                {!isMapped && <TableCell>Needs Mapping</TableCell>}
                                            </TableRow>
                                        )
                                    })}
                                    <input type="file" name="file" id="file" className="inputfile" onChange={this.fileHandlerGetHeaders.bind(this)} style={{"padding":"10px"}} />
                                    <label htmlFor="file">{this.state.inputMessage}</label>
                                    {(this.state.newSkus !== undefined && this.state.newSkus.size > 0) && <button onClick={this.fileHandlerGetData.bind(this)}>Save Skus</button>}
                                    {/* columns that display upon importing an excel file*/}
                                    <MUIDataTable
                                        title={"Bulk Add Skus"}
                                        data={this.state.newSkus}
                                        columns={skuColumns.map(c => ({ ...c, ...defaultColumnProperties }))}
                                        options={this.getImportTableOptions(this.state.newSkus)}
                                    />
                                </TabContainer>
                            )}
                            no={() => null}
                        />
                    </SwipeableViews>
                </div>

                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

SkuList.defaultProps = {
    date: getDBFormattedDate()
}

SkuList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    activeSkus: T.array,
    inactiveSkus: T.array,
    skuGroups: T.array,
    skuDrafts: T.array,
    status: T.string,
    tab: T.number,
    skuRows: T.array,
    skuCols: T.array,
    skuColumns: T.array,
    file: T.string,
    brands: T.array,
    families: T.array,
    sizes: T.array,
    manufacturers: T.array,
}

function mapStateToProps(state) {
    const { skuList, brandReducer, sizeReducer, familyReducer, loginForm, manufacturerList } = state
    return {
        isLoading: skuList.isLoading,
        activeSkus: skuList.activeSkus,
        inactiveSkus: skuList.inactiveSkus,
        skuGroups: skuList.skuGroups,
        skuDrafts: skuList.skuDrafts,
        tab: skuList.tab,
        skuRows: skuList.skuRows,
        skuCols: skuList.skuCols,
        skuColumns: skuList.skuColumns,
        groupColumns: skuList.groupColumns,
        draftColumns: skuList.draftColumns,
        file: skuList.file,
        role: loginForm.userRole,
        //associate ids to excel values
        brands: brandReducer.brands,
        sizes: sizeReducer.sizes,
        families: familyReducer.families,
        manufacturers: manufacturerList.manufacturers,


    }
}
export default withRouter(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(SkuList)))