import React from 'react';
import FabricQuiltPatternList from './List/FabricQuiltPatternList';
import '../styles/about-page.css';

export class FabricQuiltPatternListPage extends React.Component {

    render() {
        return (
            <FabricQuiltPatternList/>
        );
    }
}

export default FabricQuiltPatternListPage;
