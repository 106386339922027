import React from 'react';
import BrandList from './List/BrandList';
import '../styles/about-page.css';

export class BrandListPage extends React.Component {

    render() {
        return (
            <BrandList/>
        );
    }
}

export default BrandListPage;