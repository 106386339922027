import React from 'react';
import ScheduleList from './List/ScheduleList';
import '../styles/about-page.css';

export class ScheduleListPage extends React.Component {

    render() {
        return (
            <ScheduleList />
        );
    }
}

export default ScheduleListPage
