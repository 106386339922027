import * as types from "../constants/userTypes";
import { RSAA } from "redux-api-middleware";
import { removeEmptyFields } from "../utils/object";
import { API_ROOT } from "../utils/api";
import md5 from "md5";

export function updateField(name, value) {
  return {
    type: types.USER_UPDATE_FIELD,
    name,
    value
  };
}

export function updateListField(name, value) {
  return {
    type: types.USERS_UPDATE_FIELD,
    name,
    value
  };
}

export function updatePasswordReqd(name, value) {
  return {
    type: types.USER_UPDATE_PASS_REQ,
    name,
    value
  };
}

export function resetUser() {
  return {
    type: types.USER_RESET
  };
}

export function getUsers() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/users`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.USERS_LOADING_REQUEST,
        types.USERS_LOADING_SUCCESS,
        types.USERS_LOADING_ERROR
      ]
    }
  };
}

export function getUser(id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/user/` + id, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.USER_LOADING_REQUEST,
        types.USER_LOADING_SUCCESS,
        types.USER_LOADING_ERROR
      ]
    }
  };
}

export function saveUser(user) {
  if (user.id === null) {
    user.hash = md5("12345");
  }
  console.log(user, "dispatch user object");
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/user`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.USER_SAVING_REQUEST,
        types.USER_SAVING_SUCCESS,
        types.USER_SAVING_ERROR
      ]
    }
  };
}
