import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  InputLabel,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Snackbar
} from "@material-ui/core";
import {
  getStationlists,
  getStatusList,
  getUserComponentScans,
  validateBatchScans,
  postBatchScansInScanLog
} from "../../../actions/scanActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchLocation,
  faSearchMinus,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./batch_scan.css";
import FailedScansModal from "./FailedScansModal";
import {
  loginNewUser,
  autoLogout
} from "../ProductionHelperFunctions/ProductionHelper";
import { USER_SCAN_PASS } from "../../../utils/api";
import MuiAlert from "@material-ui/lab/Alert";
import { isNull } from "lodash";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function BatchScan() {
  //// logout after 15min of no use
  autoLogout();
  const dispatch = useDispatch();
  const history = useHistory();
  const [status, setStatus] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [station, setStation] = useState([]);
  const [stationName, setStationName] = useState(null);
  const [scans, setScans] = useState([]);
  const [user, setUser] = useState("");
  const [area, setArea] = useState(null);
  const [userId, setUserId] = useState("");
  const [totalScanned, setTotalScanned] = useState(null);
  const [machineRequired, setMachineRequired] = useState(false);
  const [headerValue, setHeaderValue] = useState("active");
  const [scan, setScan] = useState("");
  const [failedValidationArray, setFailedValidationArray] = useState([]);
  const [passedValidationArray, setPassedValidationArray] = useState([]);
  const [invalidScansArray, setInvalidScansArray] = useState([]);
  const [modalState, setModalState] = useState({
    modalOpenStatus: false,
    redirect: ""
  });
  const [showTotalScans, setShowTotalScans] = useState(true);
  const [showScansDisplay, setShowScansDisplay] = useState("none");
  const [refreshCounter, setRefreshCounter] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [machineNumber, setMachineNumber] = useState("");
  const [snackBarStatus, setSnackBarStatus] = useState(false);
  const [postedScansAmount, setPostedScansAmount] = useState(0);

  useEffect(() => {
    dispatch(getStatusList()).then((res) => {
      const stationStatus = res.payload;
      stationStatus.filter((location) => {
        if (location.station_required == 1) {
          return location;
        }
      });
      setStatus(res.payload);
    });
    dispatch(getStationlists()).then((res) => {
      setStation(res.payload);
    });
    const inputField = document.getElementById("SearchbyScanning");
    inputField.value = "";
  }, []);

  useEffect(() => {
    if (statusId) {
      getUserStatusTotalScans(userId, statusId);
    }
  }, [refreshCounter]);

  useEffect(() => {
    let firstName = window.localStorage.getItem("firstName");
    let lastName = window.localStorage.getItem("lastName");
    setUser(firstName + " " + lastName);

    let station = JSON.parse(window.localStorage.getItem("location"));
    fetchAvaliableStations(station);
    let st = station ? station.name : "";
    setArea(st);

    let machineLocation = JSON.parse(window.localStorage.getItem("machine"));
    setStationName(machineLocation);
  }, [statusId]);

  useEffect(() => {
    let newData = JSON.parse(window.localStorage.getItem("location"))
      ? JSON.parse(window.localStorage.getItem("location"))
      : 0;
    setStatusId(newData.id);
    // setLocation(newData);

    let userId = JSON.parse(window.localStorage.getItem("userId"));
    setUserId(userId);

    let machineLocation = JSON.parse(window.localStorage.getItem("machine"))
      ? JSON.parse(window.localStorage.getItem("machine"))
      : null;
    setStationName(machineLocation);

    if (newData.station_required == 1) {
      setMachineRequired(true);
    }
    getUserStatusTotalScans(userId, newData.id);
    document.getElementById("SearchbyScanning").focus();
  }, [statusId]);

  const getUserStatusTotalScans = (user_id, status_id) => {
    let userId = JSON.parse(window.localStorage.getItem("userId"));
    dispatch(getUserComponentScans(user_id, status_id)).then((res) => {
      if (res.payload != undefined) {
        setTotalScanned(res.payload.length);
      }
    });
  };

  const handleChange = async (e) => {
    let data = e.target.value;
    let locationObj = status.find((item) => item.id == data);
    setMachineNumber("");
    setStatusId(data);
    if (locationObj) {
      if (
        locationObj.allow_batch_scan == 1 &&
        locationObj.allow_batch_scan != 0
      ) {
        setMachineRequired(true);
      } else {
        setMachineRequired(false);
        window.localStorage.setItem("location", JSON.stringify(locationObj));
        window.localStorage.setItem("machine", JSON.stringify(0));
        history.push("/production");
      }
    }
  };

  const handleMachineChange = (e) => {
    let data = e.target.value;
    let stationArea = station.find((item) => item.id == data);
    let result = stationArea.station.split(" ").pop();
    setMachineNumber(result);
    window.localStorage.setItem("machine", JSON.stringify(data));
    setStationName(data);
  };

  const fetchAvaliableStations = async (data) => {
    let newStatus = data;
    await dispatch(getStationlists()).then((res) => {
      let filteredStations = [];
      let machine = res.payload;
      machine.filter((area) => {
        if (area.station_type_id == newStatus.station_type_id) {
          filteredStations.push(area);
        }
      });
      setStation(filteredStations);
      let newData = window.localStorage.getItem("machine");
      if (newData != null) {
        let stationArea = filteredStations.find((item) => item.id == newData);
        let result = stationArea?.station.split(" ").pop();
        setMachineNumber(result);
      }
    });
  };

  useEffect(() => {
    localStorage.setItem("lastActvity", new Date());
    if (scan.length == 16) {
      setDisableField(true);
      if (scans.find((scanned) => scanned.serial_number == scan)) {
        setScans((prevScans) =>
          prevScans.filter((scan) => scan.serial_number !== scan)
        );
      } else {
        setScans((prevScans) => [...prevScans, { serial_number: scan }]);
      }
      setTimeout(() => {
        setScan("");
        setDisableField(false);
        document.getElementById("SearchbyScanning").focus();
      }, 150);
      setShowTotalScans(false);
      setShowScansDisplay("block");
    } else {
      setTimeout(() => {
        setScan("");
        document.getElementById("SearchbyScanning").focus();
      }, 150);
    }
  }, [scan]);

  useEffect(() => {
    if (scans.length == 25) {
      submitBatchScansForValidation();
    }
  }, [scans]);

  const submitBatchScansForValidation = async (e) => {
    const scanObject = {
      serial_numbers: scans.map((scan) => scan.serial_number)
    };
    dispatch(validateBatchScans(scanObject, statusId)).then((res) => {
      const scanPayload = res.payload;
      const batchScanObjects = [];
      let failedScansArray = [];
      if (res.type == "VALIDATE_SCANS_SUCCESS") {
        scanPayload.map((scan) => {
          if (isNull(scan.component_schedule_id)) {
            failedScansArray.push(scan);
          } else if (scan.scans.length > 0) {
            setFailedValidationArray((prevState) => [...prevState, scan]);
            failedScansArray.push(scan);
          } else {
            setPassedValidationArray((prevState) => [...prevState, scan]);
            let newObj = {
              status_id: statusId,
              epc: scan.serial_number,
              rfid_scan: "",
              date: moment().format("YYYY-MM-DD HH:mm:ss"),
              pass: 1,
              user_id: userId,
              shipping_location: "",
              schedule_id: "",
              serial_id: "",
              station_id: stationName ? stationName : null,
              order_number: ""
            };
            batchScanObjects.push(newObj);
          }
        });

        const invalidScans = scanObject.serial_numbers.filter(
          (sn) => !scanPayload.find((scan) => sn == scan.serial_number)
        );
        setInvalidScansArray([...invalidScans]);

        let passedBatchScanObjects = {
          scans: batchScanObjects
        };
        if (failedScansArray.length > 0 || invalidScans.length > 0) {
          setModalState({
            modalOpenStatus: true,
            redirect: "/"
          });
          setDisableField(true);
          setShowScansDisplay("none");
          setScans([]);
          setShowTotalScans(true);
          setRefreshCounter((prevState) => !prevState);
        }
        if (passedBatchScanObjects.scans.length > 0) {
          dispatch(postBatchScansInScanLog(passedBatchScanObjects)).then(
            (res) => {
              if (res.type == "SCANS_LOADING_SUCCESS") {
                setScans([]);
                setShowTotalScans(true);
                setRefreshCounter((prevState) => !prevState);
                setShowScansDisplay("none");
                if (failedScansArray.length == 0 && invalidScans.length == 0) {
                  setPassedValidationArray([]);
                  setSnackBarStatus(true);
                  setPostedScansAmount(res.payload.length);
                }
              }
            }
          );
        }
      }
    });
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackBarStatus}
        id="success"
        onClose={handleSnackBarClose}
        autoHideDuration={5000}
      >
        <Alert id="alert-box" onClose={handleSnackBarClose} severity="success">
          {postedScansAmount} Scan(s) Successful.
        </Alert>
      </Snackbar>
      <FailedScansModal
        modalOpenStatus={modalState.modalOpenStatus}
        redirect={modalState.redirect}
        scansArray={failedValidationArray}
        successfulScansArray={passedValidationArray}
        invalidScansArray={invalidScansArray}
        setDisableField={setDisableField}
        setShowScansDisplay={setShowScansDisplay}
        setModalState={setModalState}
        setPassedValidationArray={setPassedValidationArray}
        setScans={setScans}
        setInvalidScansArray={setInvalidScansArray}
        setFailedValidationArray={setFailedValidationArray}
      />
      <div className="batch_scan_body">
        <Box className="production_header_title" pt={2}>
          <Grid container style={{ borderBottom: "solid 1px" }}>
            <Grid container item xs={6} justifyContent="flex-start">
              <Box pl={2}>
                <Typography className="production_header_title" variant="h3">
                  <FontAwesomeIcon icon={faUser} />
                  &nbsp;{user}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <Box mr={2} mb={1}>
                <Typography className="production_header_title" variant="h3">
                  <FontAwesomeIcon icon={faSearchLocation} />
                  &nbsp;{area}&nbsp;
                  {machineNumber != null ? <>{machineNumber} </> : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="production_sub_header" pt={1} pl={1}>
          <Grid container justifyContent="center">
            <Box>
              <Tabs value={headerValue} indicatorColor="primary"></Tabs>
            </Box>
          </Grid>
        </Box>
        <Grid container className="batch_scan_body">
          {showTotalScans ? (
            <Grid item lg={8} xs={6}>
              <Box mt={10} textAlign="center">
                <Typography
                  className="production_user_scanned"
                  style={{
                    backgroundColor: "white"
                  }}
                >
                  {totalScanned}
                </Typography>
              </Box>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            item
            lg={8}
            xs={6}
            style={{ display: showScansDisplay }}
            className="total-scanned"
          >
            {scans.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Count</TableCell>
                      <TableCell>Serial Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[]
                      .concat(scans)
                      .reverse()
                      .map((row, index) => (
                        <TableRow
                          key={row.serial_number + "-" + index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}
                        >
                          <TableCell scope="row">
                            {scans.length - index}
                          </TableCell>
                          <TableCell scope="row">{row.serial_number}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} lg={4} className="scan">
            <Box ml={5} pl={2} pr={10}>
              <Box mt={3} mb={2}>
                <InputLabel className="batch_scan_input_label">
                  Scan Status
                </InputLabel>
              </Box>
              <Select
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={statusId ? statusId : 0}
                options={status.map((option) => ({
                  value: option.id,
                  label: option.name,
                  required: option.station_required
                }))}
              >
                <MenuItem key={0} value={0}>
                  Select
                </MenuItem>
                {status.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {machineRequired == true ? (
                <Box mt={3}>
                  <InputLabel className="batch_scan_input_label">
                    Machine Number
                  </InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    onChange={handleMachineChange}
                    value={stationName ? stationName : 0}
                    options={station.map((option) => ({
                      value: option.id,
                      label: option.station
                    }))}
                  >
                    <MenuItem key={0} value={0}>
                      Select
                    </MenuItem>
                    {station.map((option) => (
                      <MenuItem key={option.station} value={option.id}>
                        {option.station}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              ) : (
                ""
              )}
              <Box mt={4}>
                <Typography variant="h4">
                  <b>Scan Data</b>
                </Typography>
                <form>
                  <TextField
                    value={scan}
                    id="SearchbyScanning"
                    className="sku_textfield"
                    disabled={disableField}
                    onChange={(e) => {
                      setScan(e.target.value);
                      if (e.target.value == "submit") {
                        submitBatchScansForValidation();
                        setDisableField(true);
                        setTimeout(() => {
                          setScan("");
                          setDisableField(false);
                        }, 200);
                        setShowTotalScans(true);
                        setShowScansDisplay("none");
                      } else if (e.target.value == "") {
                        setShowTotalScans(true);
                        setScan("");
                        setScans([]);
                        setShowScansDisplay("none");
                      } else if (
                        e.target.value.toUpperCase().includes("E2C") &&
                        e.target.value.length == 9
                      ) {
                        loginNewUser(dispatch, e.target.value, USER_SCAN_PASS);
                      }
                    }}
                    fullWidth
                    variant="filled"
                    label="Electronic Product Code"
                    InputProps={{
                      minLength: 22,
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            style={{ marginTop: "2px" }}
                            icon={faSearchMinus}
                            size="lg"
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                </form>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default BatchScan;
