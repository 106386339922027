import {CORE_LAYER_NEW_SUCCESS} from "./coreLayerTypes";

export const CORE_UPDATE_FIELD = 'CORE_UPDATE_FIELD'

export const CORE_LOADING_REQUEST = 'CORE_LOADING_REQUEST'
export const CORE_LOADING_SUCCESS = 'CORE_LOADING_SUCCESS'
export const CORE_LOADING_ERROR = 'CORE_LOADING_ERROR'

export const CORES_LOADING_REQUEST = 'CORES_LOADING_REQUEST'
export const CORES_LOADING_SUCCESS = 'CORES_LOADING_SUCCESS'
export const CORES_LOADING_ERROR = 'CORES_LOADING_ERROR'

export const CORE_IMPORT_LOADING_REQUEST = 'CORE_IMPORT_LOADING_REQUEST'
export const CORE_IMPORT_LOADING_SUCCESS = 'CORE_IMPORT_LOADING_SUCCESS'
export const CORE_IMPORT_LOADING_ERROR = 'CORE_IMPORT_LOADING_ERROR'

export const CORE_SAVING_REQUEST = 'CORE_SAVING_REQUEST'
export const CORE_SAVING_SUCCESS = 'CORE_SAVING_SUCCESS'
export const CORE_SAVING_ERROR = 'CORE_SAVING_ERROR'

export const UPDATE_CORE_SAVING_REQUEST = 'UPDATE_CORE_SAVING_REQUEST'
export const UPDATE_CORE_SAVING_SUCCESS = 'UPDATE_CORE_SAVING_SUCCESS'
export const UPDATE_CORE_SAVING_ERROR = 'UPDATE_CORE_SAVING_ERROR'

export const CORES_SAVING_REQUEST = 'CORES_SAVING_REQUEST'
export const CORES_SAVING_SUCCESS = 'CORES_SAVING_SUCCESS'
export const CORES_SAVING_ERROR = 'CORES_SAVING_ERROR'

export const CORE_NEW_SUCCESS = 'CORE_NEW_SUCCESS'
export const CORE_ADD_LAYER = 'CORE_ADD_LAYER'