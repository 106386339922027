import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import InputAdornment from '@material-ui/core/InputAdornment';

import { getSize, saveSize, updateField} from '../../actions/sizeActions';

class SizeForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        this.getSize()
    }

    getSize = e => {
        const {
            dispatch,
            isLoading,
        } = this.props

        const id = this.props.match.params.id
        if (id) {
            dispatch(getSize(id))
        }
    }

    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSuccess) {
            this.setState({ open: true })
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            id,
            size,
            width,
            length,
            sizeAlias,
        } = this.props

        const sizeObj = {
            id: id,
            width_in: parseFloat(parseFloat(width).toFixed(2)),
            length_in: parseFloat(parseFloat(length).toFixed(2)),
            size: size,
            alias: sizeAlias,
            talalay_qty: width >= 66 || length >= 88 ? 2 : 1,
            topper_qty: width <= 38 ? 0.5 : 1,
            lcore_qty: width <=38 ? 0.25 : 0.5,
            split_topper_qty: 1
        }
        if(!id || id === -1){
            delete sizeObj.id
        }
        await dispatch(saveSize(sizeObj))
        if(!isError) {
            this.props.history.push("/sizes")
        }
    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            id,
            size,
            width,
            length,
            sizeAlias
        } = this.props

        return (
            <div className="baseContainer projectForm">
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    className="success"
                    onClose={this.handleClose}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Size saved</span>}
                />
                <h1>
                    {size}
                </h1>
                <div>
                    <TextField
                        label="Size"
                        name="size"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={size}
                        onChange={this.handleChange}
                        style={{marginRight: 20}}
                    />
                    <TextField
                        label="Size Alias"
                        name="sizeAlias"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={sizeAlias}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <TextField
                        label="Width"
                        name="width"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={width}
                        onChange={this.handleChange}
                        style={{marginRight: "15px", width: 200}}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">inches</InputAdornment>,
                        }}
                    />
                    <TextField
                        label="Length"
                        name="length"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={length}
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">inches</InputAdornment>,
                        }}
                        style={{width: 200}}
                    />
                </div>
                <div style={{marginTop: 20}}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        )
    }
}

SizeForm.defaultProps = {

}

SizeForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    size: T.string,
    width: T.number,
    length: T.number,
    sizeAlias: T.string
}

function mapStateToProps(state) {
    const { sizeReducer } = state
    return {
        isError: sizeReducer.isError,
        saveSuccess: sizeReducer.saveSuccess,
        id: sizeReducer.id,
        size: sizeReducer.size,
        width: sizeReducer.width,
        length: sizeReducer.length,
        sizeAlias: sizeReducer.sizeAlias
    }
}

export default withRouter(connect(mapStateToProps)(SizeForm))