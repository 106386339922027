import React, { useState, useEffect } from "react";
import "../styles/about-page.css";
import { API_ROOT } from "../utils/api";
import DealerForm from "./forms/DealerForm";
import { useHistory } from "react-router-dom";

const DealerFormPage = ({ location, props }) => {
  const history = useHistory();
  const [dealerData, setDealerData] = useState(null);

  const [id, setId] = useState(history.location.pathname.split("/dealer/")[1]);

  useEffect(() => {
    fetch(`${API_ROOT}/dealer/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    })
      .then((res) => res.json())
      .then((data) => {
        data.map((d) => setDealerData(d));
      });
  }, []);
  return (
    <div style={{ padding: "25px" }}>
      {dealerData ? (
        <>
          <h1 className="alt-header">Dealer</h1>
          <DealerForm data={dealerData} location={location} />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default DealerFormPage;
