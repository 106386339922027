import '../../styles/sku-queue.scss';
import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import '../../styles/shipping.scss';
import Loader from '../global/Loader';
import ConfirmButton from '../global/ConfirmButton'
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight, faForward, faFastForward, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import Refresh from '@material-ui/icons/Refresh';
import axios from "axios";
import { AXIOS_HEADERS } from '../global/axiosHeaders';
import {API_ROOT, SHIPSTATION} from "../../utils/api";

const SS_AXIOS_HEADERS = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': SHIPSTATION.BASIC
  },
  options: {
    mode: 'cors',
  }
};

const SQ_AXIOS_HEADERS = {
  headers: {
    'x-api-key': 'CRE0bfiaJe7pjlyxBCPHxaWftFyPXUMV4t8OW5pH',
    'x-scope': 'skuqueue', 
    'x-invocation-type': 'Event'
  }
};

const SHIPSTATION_URGENT_TAG_ID = 64545;
const SHIPSTATION_CSINQUIRY_TAG_ID = 64545;

const skuQueueRebuildTimeMS = 300000; //5min

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    margin: '2rem',
    width: '100%'
  }
});

class SkuQueueList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
     skuQueueData: [],
     isSkuQueueLoading: false,
     isSkuQueueRebuilding: false,
     showSnackBar: false,
     lastRefresh: moment(),
     currentTime: moment()
    };
  }

  componentDidMount() {
    this.loadSkuQueue();
  }

  loadSkuQueue = () => {
    this.setState({ skuQueueData: null, isSkuQueueLoading: true });
    axios.get(`${API_ROOT}/skuqueue`, AXIOS_HEADERS).then(this.skuQueueLoaded).catch(this.dataLoadError);
  }

  skuQueueLoaded = (response) => {
    let rankedData = this.determineAndAddSkuRanks(response.data);
    this.setState({ skuQueueData: rankedData, isSkuQueueLoading: false, lastRefresh: moment() });
  }

  determineAndAddSkuRanks(data) {
    let uniqueSkus = this.getUniqueSkusFromData(data);
    let rankedSkuQueue = [];

    for (let i = 0; i < uniqueSkus.length; i++) {
      const theSku = uniqueSkus[i];
      let filteredItems = this.filterDataItemsBySku(data, theSku);
      let rankedItems = this.rankFilteredDataItems(filteredItems);
      rankedSkuQueue.push(...rankedItems);
    }

    return rankedSkuQueue;
  }

  getUniqueSkusFromData(data) {
    let uniques = [];
    //boring for loop for maximum speed (lots of records to filter)
    for (let i = 0; i < data.length; i++) {
      const sku = data[i].sku;
      if (uniques.indexOf(sku) === -1) {
        uniques.push(sku);
      }
    }

    return uniques;
  }

  filterDataItemsBySku(data, sku) {
    let filteredItems = [];
    //boring for loop for maximum speed (lots of records to filter)
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.sku === sku) {
        filteredItems.push(item);
      }
    }

    return filteredItems;
  }

  rankFilteredDataItems(filteredData) {
    filteredData.sort(this.sortByPriority);

    for (let i = 0; i < filteredData.length; i++) {
      filteredData[i]['rank'] = i+1;
    }

    return filteredData;
  }

  sortByPriority = (a, b) => {
    if (a.priority === b.priority) {
      return this.sortByDate(a, b);
    }
    return a.priority > b.priority ? 1 : -1;
  }

  sortByDate = (a, b) => {
    let aDateTime = new Date(a.date).getTime();
    let bDateTime = new Date(b.date).getTime();

    if (aDateTime === bDateTime) {
      return this.sortById(a, b);
    }
    return aDateTime > bDateTime ? 1 : -1;
  }

  sortById = (a, b) => {
    return a.id > b.id ? 1 : -1;
  }

  getMUIColumns() {
    const defaultColumnProperties = {
      sortable: true,
      filter: true,
    };

    const muiTableColumns = [
      {
        name: "order_id",
        label: "Order Id",
        options: {
          display: false
        }
      },
      {
        name: "sku",
        label: "SKU",
        options: {...defaultColumnProperties}
      },
      {
        name: "date",
        label: "Order Date",
        options: {
          ...defaultColumnProperties,
          customBodyRender: this.formatValueAsDate
        }
      },
      {
        name: "order_number",
        label: "Order Number",
        options: {...defaultColumnProperties}
      },
      {
        name: "store",
        label: "Store",
        options: {...defaultColumnProperties}
      },
      {
        name: "priority_note",
        label: "Priority Note",
        options: {
          ...defaultColumnProperties,
          customBodyRender: this.formatPriorityNoteValue
        }
      },
      {
        name: "rank",
        label: "SKU Rank",
        options: {...defaultColumnProperties}
      },
      {
        name: "note",
        label: "Note",
        options: {...defaultColumnProperties}
      },
      {
        name: "flag",
        label: "Urgent Flag",
        options: {
          ...defaultColumnProperties,
          customBodyRender: this.buildUrgencyButton
        }
      }
    ];

    return muiTableColumns;
  }

  formatValueAsDate = (value) => {
    return moment(value).format('MM/DD/YYYY')
  }
  
  formatPriorityNoteValue = (value) => {
    let priorityNoteClassNamePrefix = 'skuqueue-prioritynote';
    let priorityNoteSanitized = value.toLowerCase().replace(/ /g, '');
    let priorityNoteIcon;
  
    switch(priorityNoteSanitized) {
      case 'normal':
      case 'palletizepo':
        priorityNoteIcon = faCaretRight;
        break;
      case 'retailover3days':
      case 'csurgent':
        priorityNoteIcon = faForward;
        break;
      case 'urgent':
        priorityNoteIcon = faFastForward;
        break;
    };
  
    return (<span className={`${priorityNoteClassNamePrefix} ${priorityNoteClassNamePrefix}-${priorityNoteSanitized}`}>
              <FontAwesomeIcon icon={priorityNoteIcon} className={`${priorityNoteClassNamePrefix}-icon`} />
              &nbsp;&nbsp;&nbsp;
              {value}
            </span>);
  }
  
  buildUrgencyButton = (value, tableMeta, updateValue) => {
    let priorityNoteSanitized = (tableMeta.rowData[4])? tableMeta.rowData[4].toLowerCase().replace(/ /g, '') : '';
    // return (
    //   (priorityNoteSanitized !== 'urgent')?<Button variant="outlined" color="primary" onClick={() => this.updateUrgency(tableMeta.rowData, updateValue)}>Mark as Urgent</Button>:''
    // );

    return (
      (priorityNoteSanitized !== 'urgent')?
        <FormControl className="skuqueue-form-control">
          <InputLabel id="urgency-select-label">Change Urgency</InputLabel>
          <Select
            labelId="urgency-select-label"
            id="urgency-select"
            value=""
            onChange={(event) => this.updateUrgency(tableMeta.rowData, updateValue, event.target.value)}
          >
            <MenuItem value="urgent">Urgent</MenuItem>
            {(priorityNoteSanitized !== 'csurgent')&&<MenuItem value="csurgent">CS Inquiry</MenuItem>}
          </Select>
        </FormControl>
      : ''
    );
  }

  getMUITableOptions() {
    const muiTableOptions = {
      filterType: 'multiselect',
      responsive: 'scrollFullHeight',
      stickyHeader: true,
      rowsPerPage: 100,
      selectableRowsHeader: true,
      selectableRows: 'none',
      customToolbar: () => {
        return (
            <Tooltip title="Reload List">
              <IconButton onClick={this.loadSkuQueue}>
                <Refresh />
              </IconButton>
            </Tooltip>
          );
      }
    };

    return muiTableOptions;
  }

  rebuildSkuQueue = () => {
    this.setState({ isSkuQueueRebuilding: true });
    this.skuQueueRebuilding();
    axios.get(`https://hj2b4dq2o2.execute-api.us-west-2.amazonaws.com/test-in-prod/schedule`, SQ_AXIOS_HEADERS).then(this.skuQueueRebuilding);
  }

  skuQueueRebuilding = () => {
    setTimeout(this.skuQueueRebuilt, skuQueueRebuildTimeMS);
  }

  skuQueueRebuilt = () => {
    this.setState({ isSkuQueueRebuilding: false, showSnackBar: true });
  }

  updateUrgency = (skuQueueItem, updateValue, newPriorityNoteSanitized) => {
    if (newPriorityNoteSanitized === 'urgent') skuQueueItem[5] = 'Urgent';
    else if (newPriorityNoteSanitized === 'csurgent') skuQueueItem[5] = 'CS Urgent';

    updateValue(skuQueueItem);
    
    this.getOrderFromShipstation(skuQueueItem[0]).then((result) => this.ordersLoaded(result, newPriorityNoteSanitized)).catch(this.shipstationError);
  }

  getOrderFromShipstation = (orderId) => {
    return axios.get(`${SHIPSTATION.API_ROOT}/orders/${orderId}`, SS_AXIOS_HEADERS);
  }

  ordersLoaded = (result, newPriorityNoteSanitized) => {
    const order = result;

    if (order) {
      this.addUrgentTagToOrder(order, newPriorityNoteSanitized).then(this.tagAdded).catch(this.shipstationError);
    }
  }

  addUrgentTagToOrder = (order, newPriorityNoteSanitized) => {
    let tagId = (newPriorityNoteSanitized === 'urgent') ? this.SHIPSTATION_URGENT_TAG_ID : this.SHIPSTATION_CSINQUIRY_TAG_ID;
    let tags;

    if(order.tagIds !== undefined && order.tagIds !== null) {
      tags = _.uniq([...order.tagIds, tagId]);
    } else {
      tags = [tagId];
    }
    order.tagIds = tags;

    return axios.post(`${SSBASE}/orders/createorder`, order, SS_AXIOS_HEADERS);
  }

  tagAdded = () => {
    console.log("tagAdded!");
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;

    this.setState({ showSnackBar: false });
  };

  shipstationError = (err) => {
    throw new Error(err);
  }

  dataLoadError(err) {
    throw new Error(err);
  }

  render() {
    const {
      classes,
      theme,
    } = this.props;

    const {
      isSkuQueueLoading,
      isSkuQueueRebuilding,
      skuQueueData,
      showSnackBar
    } = this.state;

    return (
      <div className="baseContainer">
        <div className={classes.root}>
          {
          (isSkuQueueLoading)? 
              <Loader isLoading={isSkuQueueLoading} />
              : 
              <>
                <ConfirmButton disabled={isSkuQueueRebuilding} className={isSkuQueueRebuilding ? 'skuqueue-confirmbutton animated' : 'skuqueue-confirmbutton'} message="Are you sure you want to rebuild the SKU Queue? This could potentially halt shipping for several minutes." onConfirm={this.rebuildSkuQueue}>
                  <FontAwesomeIcon icon={faSyncAlt} />&nbsp;&nbsp;
                  Rebuild{(isSkuQueueRebuilding)?'ing':''} Sku Queue
                </ConfirmButton>
                <Alert key={this.state.currentTime.format()} severity="success">List last refreshed: {moment(this.state.lastRefresh).format('MM/DD/YYYY hh:mm a')}</Alert>
                <MUIDataTable
                  title={"Sku Queue"}
                  data={skuQueueData}
                  columns={this.getMUIColumns()}
                  options={this.getMUITableOptions()}
                />
              </>
        }
        </div>
        <Snackbar open={showSnackBar} autoHideDuration={6000} onClose={(event, reason) => this.handleSnackbarClose(event, reason)}>
          <Alert elevation={6} variant="filled" onClose={(event, reason) => this.handleSnackbarClose(event, reason)} severity="success">Sku Queue Finished Rebuilding!</Alert>
        </Snackbar>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { loginForm } = state;
  return {
    role: loginForm.userRole
  };
}

export default withRouter(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(SkuQueueList)))


