import React from 'react';
import T from 'prop-types';
import { 
  Box,
  Button, 
  FormGroup,
  FormControlLabel,
  TextField,
  Switch
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "./skubyfishbowlpart.css";

const UpdatePartSelect = props => {
  const { setSwapPartSwitchSelection, setPartToSwap, filteredParts, swapFishbowlPart } = props;
  const handleSwapPartCoreSwitch = (e) => {
    setSwapPartSwitchSelection(prevState => ({...prevState, update_core: !prevState.core}))
  };
  const handleSwapPartPanelLayerSwitch = (e) => {
    setSwapPartSwitchSelection(prevState => ({...prevState, update_panel_layer: !prevState.panel_layer}))
  };
  const handlePartSwap = (e) => {
    let value = e.target.value;
    const [id, num] = value.split(' - ')
    setPartToSwap(id)
  };
  return (
    <Box className='update-fishbowl_part-sku-search-box' >
    <Autocomplete
    id="sku-item-part"
    style={{width: 700}}
    options={filteredParts}
    getOptionLabel={(options) => `${options.id} - ${options.num} - ${options.description}`}
    onSelect={handlePartSwap}
    renderInput={(params) => 
      <TextField {...params} 
        label="Select Fishbowl Part to update with" 
        id='sku-auto-complete'
      />}
    />
    <FormGroup className='switch-group' >
      <FormControlLabel onChange={handleSwapPartCoreSwitch} control={<Switch className='core-switch' color='primary' />} label="Core Layer" />
      <FormControlLabel onChange={handleSwapPartPanelLayerSwitch} control={<Switch color='primary'/>} label="Panel Layer" />
    </FormGroup>
    <Button className='find-fishbowl-part-btn' variant='contained' onClick={swapFishbowlPart}>swap fishbowl part</Button>
  </Box>
  )
}

UpdatePartSelect.propTypes = {
  filteredParts: T.array,
}

export default UpdatePartSelect