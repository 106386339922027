import * as types from '../constants/foundationTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.FOUNDATION_UPDATE_FIELD,
        name,
        value,
    }
}

export function getFoundations() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/foundations`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FOUNDATIONS_LOADING_REQUEST,
                types.FOUNDATIONS_LOADING_SUCCESS,
                types.FOUNDATIONS_LOADING_ERROR,
            ],
        },
    }
}

export function getFoundation(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/foundation/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FOUNDATION_LOADING_REQUEST,
                types.FOUNDATION_LOADING_SUCCESS,
                types.FOUNDATION_LOADING_ERROR,
            ],
        },
    }
}

export function saveFoundationList(foundations){
    const data = []
    for (let field in foundations.data) {
        let row = removeEmptyFields(foundations.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/foundations`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FOUNDATIONS_SAVING_REQUEST,
                types.FOUNDATIONS_SAVING_SUCCESS,
                types.FOUNDATIONS_SAVING_ERROR,
            ],
        },
    }
}

export function saveFoundation(foundation){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/foundation`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(foundation),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FOUNDATION_SAVING_REQUEST,
                types.FOUNDATION_SAVING_SUCCESS,
                types.FOUNDATION_SAVING_ERROR,
            ],
        },
    }
}