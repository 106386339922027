
export const PART_UPDATE_FIELD = 'PART_UPDATE_FIELD'

export const PART_LOADING_REQUEST = 'PART_LOADING_REQUEST'
export const PART_LOADING_SUCCESS = 'PART_LOADING_SUCCESS'
export const PART_LOADING_ERROR = 'PART_LOADING_ERROR'

export const PARTS_LOADING_REQUEST = 'PARTS_LOADING_REQUEST'
export const PARTS_LOADING_SUCCESS = 'PARTS_LOADING_SUCCESS'
export const PARTS_LOADING_ERROR = 'PARTS_LOADING_ERROR'

export const PART_IMPORT_LOADING_REQUEST = 'PART_IMPORT_LOADING_REQUEST'
export const PART_IMPORT_LOADING_SUCCESS = 'PART_IMPORT_LOADING_SUCCESS'
export const PART_IMPORT_LOADING_ERROR = 'PART_IMPORT_LOADING_ERROR'

export const PART_SAVING_REQUEST = 'PART_SAVING_REQUEST'
export const PART_SAVING_SUCCESS = 'PART_SAVING_SUCCESS'
export const PART_SAVING_ERROR = 'PART_SAVING_ERROR'

export const PARTS_SAVING_REQUEST = 'PARTS_SAVING_REQUEST'
export const PARTS_SAVING_SUCCESS = 'PARTS_SAVING_SUCCESS'
export const PARTS_SAVING_ERROR = 'PARTS_SAVING_ERROR'
