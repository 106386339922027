import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

import { getPrototype, savePrototype, updateField} from '../../actions/prototypeActions'
import Can from "../Can";
import AddNewButton from "../global/AddNewButton";

class PrototypeForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        this.getPrototype()
    }
    getPrototype = e => {
        const {
            dispatch,
            isLoading,
        } = this.props

        const id = this.props.match.params.id
        if (id) {
            dispatch(getPrototype(id))
        }
    }

    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSuccess) {
            this.setState({ open: true })
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            id,
            number,
        } = this.props

        const prototype = {
            id: id,
            number: number,
        }
        if(!id || id == -1){
            delete prototype.id
        }

        await dispatch(savePrototype(prototype))
        if(!isError) {
            this.props.history.push("/prototypes")
        }
    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            id,
            number,
        } = this.props

        return (
            <div className="baseContainer projectForm">
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    className="success"
                    onClose={this.handleClose}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Prototype saved</span>}
                />
                <h1>
                    {number}
                </h1>
                <div>
                    <TextField
                        label="Prototype ID"
                        name="number"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={number}
                        onChange={this.handleChange}
                    />
                </div>
                <Can
                    role={this.props.role}
                    perform='sku:edit'
                    yes={() => (
                        <div style={{marginTop: 20}}>
                            <Button
                                variant="contained"
                                style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                                onClick={this.handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    )}
                    no={() => null}
                />
            </div>
        )
    }
}

PrototypeForm.defaultProps = {

}

PrototypeForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    number: T.string,
}

function mapStateToProps(state) {
    const { prototypeForm, loginForm } = state
    return {
        isError: prototypeForm.isError,
        saveSuccess: prototypeForm.saveSuccess,
        id: prototypeForm.id,
        number: prototypeForm.number,
        role: loginForm.userRole
    }
}

export default withRouter(connect(mapStateToProps)(PrototypeForm))