import {
    USERS_UPDATE_FIELD, USERS_LOADING_REQUEST, USERS_LOADING_SUCCESS, USERS_LOADING_ERROR
} from '../constants/userTypes'


import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";
import {parseAllToJson} from "../utils/string";

const initialState = {
    isError: false,
    isLoading: false,
    users: [],
    columns: [],
}

export default function userListReducer(state = initialState, action) {
    switch (action.type) {
        case USERS_UPDATE_FIELD:
            return {
                ...state,
                [action.name]: action.value,
            }
        case USERS_LOADING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case USERS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                users: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            const columns = parseAllToJson(action.payload, 'options')
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: columns,
            }
        case USERS_LOADING_ERROR:
        case COLUMNS_LOADING_ERROR:
        default:
            return state
    }
}