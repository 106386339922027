
import {
    PROTOTYPE_UPDATE_FIELD,
    PROTOTYPE_LOADING_REQUEST,
    PROTOTYPE_LOADING_SUCCESS,
    PROTOTYPE_LOADING_ERROR,
    PROTOTYPE_SAVING_REQUEST,
    PROTOTYPE_SAVING_SUCCESS,
    PROTOTYPE_SAVING_ERROR,
} from '../constants/prototypeTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    id: null,
    number: '',
}

export default function prototypeFormReducer(state = initialState, action) {
    switch (action.type) {
        case PROTOTYPE_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case PROTOTYPE_LOADING_REQUEST:
        case PROTOTYPE_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case PROTOTYPE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                number: action.payload.number,
            }
        case PROTOTYPE_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: null,
                number: '',
            }
        case PROTOTYPE_LOADING_ERROR:
        case PROTOTYPE_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}