import * as types from '../constants/panelLayerTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function getAllPanelLayers() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/panel-layers`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PANEL_LAYERS_LOADING_REQUEST,
                types.PANEL_LAYERS_LOADING_SUCCESS,
                types.PANEL_LAYERS_LOADING_ERROR,
            ],
        },
    }
}

export function getTopPanelTicks() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/panel-layer/top/ticks`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.TOP_PANEL_TICKS_LOADING_REQUEST,
                types.TOP_PANEL_TICKS_LOADING_SUCCESS,
                types.TOP_PANEL_TICKS_LOADING_ERROR,
            ],
        },
    }
}

export function getSidePanelTicks() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/panel-layer/side/ticks`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.SIDE_PANEL_TICKS_LOADING_REQUEST,
                types.SIDE_PANEL_TICKS_LOADING_SUCCESS,
                types.SIDE_PANEL_TICKS_LOADING_ERROR,
            ],
        },
    }
}

export function getBottomPanelTicks() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/panel-layer/bottom/ticks`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.BOTTOM_PANEL_TICKS_LOADING_REQUEST,
                types.BOTTOM_PANEL_TICKS_LOADING_SUCCESS,
                types.BOTTOM_PANEL_TICKS_LOADING_ERROR,
            ],
        },
    }
}

export function getGussetPanelTicks() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/panel-layer/gusset/ticks`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.GUSSET_PANEL_TICKS_LOADING_REQUEST,
                types.GUSSET_PANEL_TICKS_LOADING_SUCCESS,
                types.GUSSET_PANEL_TICKS_LOADING_ERROR,
            ],
        },
    }
}