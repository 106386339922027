export const SHIPMENT_UPDATE_FIELD = 'SHIPMENT_UPDATE_FIELD'


export const ORDER_LOADING_REQUEST = 'ORDER_LOADING_REQUEST'
export const ORDER_LOADING_SUCCESS = 'ORDER_LOADING_SUCCESS'
export const ORDER_LOADING_ERROR = 'ORDER_LOADING_ERROR'


export const ORDERS_LOADING_REQUEST = 'ORDERS_LOADING_REQUEST'
export const ORDERS_LOADING_SUCCESS = 'ORDERS_LOADING_SUCCESS'
export const ORDERS_LOADING_ERROR = 'ORDERS_LOADING_ERROR'


export const SHIPMENT_LOADING_REQUEST = 'SHIPMENT_LOADING_REQUEST'
export const SHIPMENT_LOADING_SUCCESS = 'SHIPMENT_LOADING_SUCCESS'
export const SHIPMENT_LOADING_ERROR = 'SHIPMENT_LOADING_ERROR'

export const SHIPMENTS_LOADING_REQUEST = 'SHIPMENTS_LOADING_REQUEST'
export const SHIPMENTS_LOADING_SUCCESS = 'SHIPMENTS_LOADING_SUCCESS'
export const SHIPMENTS_LOADING_ERROR = 'SHIPMENTS_LOADING_ERROR'


export const STORES_LOADING_REQUEST = 'STORES_LOADING_REQUEST'
export const STORES_LOADING_SUCCESS = 'STORES_LOADING_SUCCESS'
export const STORES_LOADING_ERROR = 'STORES_LOADING_ERROR'

export const PRINT_TRUCK_SHIPPED_LOADING_REQUEST = 'PRINT_TRUCK_SHIPPED_LOADING_REQUEST'
export const PRINT_TRUCK_SHIPPED_LOADING_SUCCESS = 'PRINT_TRUCK_SHIPPED_LOADING_SUCCESS'
export const PRINT_TRUCK_SHIPPED_LOADING_ERROR = 'PRINT_TRUCK_SHIPPED_LOADING_ERROR'
