import React from 'react'
import '../styles/about-page.css'
import FabricQuiltPatternForm from './forms/FabricQuiltPatternForm'

const FabricQuiltPatternFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Quilt Pattern</h2>
            <FabricQuiltPatternForm location={location}/>
        </div>
    )
}

export default FabricQuiltPatternFormPage