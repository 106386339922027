import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import { useDispatch } from "react-redux";
import {
  createNewDealer,
  getDealers,
  saveDealer,
  saveDealerList
} from "../actions/dealerActions";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const DealerPage = () => {
  const dispatch = useDispatch();
  const [dealers, setDealers] = useState(null);
  const history = useHistory();
  const [errorMessages, setErrorMessages] = useState({
    error: false,
    label: "",
    helperText: "",
    validateInput: false
  });
  useEffect(() => {
    getAllDealers();
  }, []);

  function getAllDealers() {
    dispatch(getDealers()).then((res) => {
      let data = res.payload;
      data.filter((data) => {
        if (!data.deleted_at) {
          return data;
        }
      });
      setDealers(data);
    });
  }

  const editDealer = async (rowData) => {
    history.push(`/dealer/${rowData.account_id}`);
  };

  const updateDataEntry = (newData) => {
    const dealer = {
      account_id: parseInt(newData.account_id),
      dealer_code: newData.dealer_code.toUpperCase(),
      cfs_dealer_id: newData.cfs_dealer_id.toUpperCase(),
      dealer_name: newData.dealer_name,
      first_name: newData.first_name,
      last_name: newData.last_name,
      company_name: newData.company_name,
      main_phone: newData.main_phone,
      main_email: newData.main_email,
      street: newData.street,
      city: newData.city,
      state: newData.state.toUpperCase(),
      zip: newData.zip
    };

    dispatch(createNewDealer(dealer)).then((res) => {
      getAllDealers();
    });
  };

  const dataColums = [
    {
      title: "Account Id",
      field: "account_id",
      cellStyle: {
        textAlign: "left"
      },
      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => (
        <TextField
          name={"account_id"}
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (errorMessages.validateInput) {
              setErrorMessages({
                ...errorMessages,
                validateInput: false
              });
            }
            onChange(e.target.value);
          }}
          error={errorMessages.validateInput ? errorMessages.error : false}
          helperText={
            errorMessages.validateInput
              ? errorMessages.helperText + " & Account Id must be unique."
              : ""
          }
        />
      )
    },
    {
      title: "CFS Dealer Id",
      field: "cfs_dealer_id",
      cellStyle: {
        textAlign: "left"
      },
      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => (
        <TextField
        inputProps={{style: {textTransform: 'uppercase'}}}
          name={"cfs_dealer_id"}
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (errorMessages.validateInput) {
              setErrorMessages({
                ...errorMessages,
                validateInput: false
              });
            }
            onChange(e.target.value);
          }}
          error={errorMessages.validateInput ? errorMessages.error : false}
          helperText={
            errorMessages.validateInput
              ? errorMessages.helperText + " & Dealer Id must be unique."
              : ""
          }
        />
      )
    },
    {
      title: "Dealer Code",
      field: "dealer_code",

      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => (
        <TextField
        inputProps={{style: {textTransform: 'uppercase'}}}
          error={errorMessages.validateInput ? errorMessages.error : false}
          helperText={
            errorMessages.validateInput
              ? errorMessages.helperText + " & dealer Code must be unique."
              : ""
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (errorMessages.validateInput) {
              setErrorMessages({
                ...errorMessages,
                validateInput: false
              });
            }
            onChange(e.target.value);
          }}
        />
      )
    },
    {
      title: "First Name",
      field: "first_name",

      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => (
        <TextField
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (errorMessages.validateInput) {
              setErrorMessages({
                ...errorMessages,
                validateInput: false
              });
            }
            onChange(e.target.value);
          }}
          error={
            !props.value && errorMessages.validateInput
              ? errorMessages.error
              : false
          }
          helperText={
            !props.value && errorMessages.validateInput
              ? errorMessages.helperText
              : ""
          }
        />
      )
    },
    {
      title: "Last Name",
      field: "last_name",

      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => (
        <TextField
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (errorMessages.validateInput) {
              setErrorMessages({
                ...errorMessages,
                validateInput: false
              });
            }
            onChange(e.target.value);
          }}
          error={
            !props.value && errorMessages.validateInput
              ? errorMessages.error
              : false
          }
          helperText={
            !props.value && errorMessages.validateInput
              ? errorMessages.helperText
              : ""
          }
        />
      )
    },
    {
      title: "state",
      field: "state",

      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => (
        <TextField
        inputProps={{style: {textTransform: 'uppercase'}}}
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (errorMessages.validateInput) {
              setErrorMessages({
                ...errorMessages,
                validateInput: false
              });
            }
            onChange(e.target.value);
          }}
          error={
            !props.value && errorMessages.validateInput
              ? errorMessages.error
              : false
          }
          helperText={
            !props.value && errorMessages.validateInput
              ? errorMessages.helperText
              : ""
          }
        />
      )
    },
    {
      title: "Zip Code",
      field: "zip",
      type: "numeric",
      cellStyle: {
        textAlign: "left"
      },

      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => (
        <TextField
          error={
            !props.value && errorMessages.validateInput
              ? errorMessages.error
              : false
          }
          helperText={
            !props.value && errorMessages.validateInput
              ? errorMessages.helperText
              : ""
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (errorMessages.validateInput) {
              setErrorMessages({
                ...errorMessages,
                validateInput: false
              });
            }
            onChange(e.target.value);
          }}
        />
      )
    }
  ];

  return (
    <div>
      {dealers ? (
        <MaterialTable
          title="Active Dealers Table"
          options={{
            pageSize: 20,
            addRowPosition: "first",
            actionsColumnIndex: -1,
            padding: "default",
            rowStyle: { height: "100px" }
          }}
          key={dealers.accountId}
          style={{ color: "black", marginTop: "75px" }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  let cfsId = dealers.find(
                    (cfs) =>
                      cfs.cfs_dealer_id === newData.cfs_dealer_id &&
                      newData.cfs_dealer_id != "ROIDXXX"
                  );
                  let dealerCode = dealers.find(
                    (dealCode) => dealCode.dealer_code === newData.dealer_code
                  );
                  let account = dealers.find(
                    (accountId) =>
                      accountId.account_id === parseInt(newData.account_id)
                  );
                  if (
                    account != undefined ||
                    cfsId != undefined ||
                    dealerCode != undefined ||
                    newData.dealer_code === undefined ||
                    newData.first_name === undefined ||
                    newData.last_name === undefined ||
                    newData.state === undefined ||
                    newData.zip === undefined
                  ) {
                    setErrorMessages({
                      error: true,
                      label: "Required",
                      helperText: "Required",
                      validateInput: true
                    });
                    reject();
                    return;
                  } else setDealers([...dealers, newData]);
                  updateDataEntry(newData);
                  resolve();
                }, 1000);
              })
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Row",
              onClick: (event, rowData) => {
                editDealer(rowData);
              }
            }
          ]}
          columns={dataColums}
          data={dealers}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default DealerPage;
