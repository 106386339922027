import React from 'react';
import MaterialGroupList from './List/MaterialGroupList';
import '../styles/about-page.css';

export class MaterialGroupListPage extends React.Component {

    render() {
        return (
            <MaterialGroupList/>
        );
    }
}

export default MaterialGroupListPage;
