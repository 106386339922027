import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ConfirmButton extends React.Component {
    constructor(props) {
        super(props);

        this.initVarsFromProps(props);

        this.state = { 
            isOpen: false
        };
    }

    initVarsFromProps(props) {
        this.onConfirm = props.onConfirm;
        this.onCancel = props.onCancel;
        this.message = !props.message ? 'Are you sure you want to do this?' : props.message;
        this.transclude = props.children ? props.children : 'Confirm';
        this.className = props.className ? props.className : '';
        this.disabled = props.disabled;
    }

    componentWillReceiveProps (nextProps) {
        this.initVarsFromProps(nextProps);
    }

    openDialog = () => {
        this.setState({ isOpen: true });
    }

    handleCancel = () => {
        if (this.onCancel) {
            this.onCancel();
        } else {
            this.setState({ isOpen: false });
        }
    }

    handleConfirm = () => {
        this.onConfirm();
        this.setState({ isOpen: false });
    }

    render() {
        const {
            isOpen
        } = this.state;

        return (
            <div className={`confirmbutton ${this.className}`}>
                <Button variant="outlined" color="primary" onClick={this.openDialog} disabled={this.disabled}>{this.transclude}</Button>
                <Dialog open={isOpen} onClose={this.handleCancel}>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{this.message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel}>Cancel</Button>
                        <Button onClick={this.handleConfirm} color="secondary" autoFocus>I'm Sure</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ConfirmButton;