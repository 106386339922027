export const PANELS_LOADING_ERROR = 'PANELS_LOADING_ERROR'
export const PANELS_LOADING_REQUEST = 'PANELS_LOADING_REQUEST'
export const PANELS_LOADING_SUCCESS = 'PANELS_LOADING_SUCCESS'

export const PANEL_LOADING_ERROR = 'PANEL_LOADING_ERROR'
export const PANEL_LOADING_REQUEST = 'PANEL_LOADING_REQUEST'
export const PANEL_LOADING_SUCCESS = 'PANEL_LOADING_SUCCESS'

export const PANEL_SAVING_ERROR = 'PANEL_SAVING_ERROR'
export const PANEL_SAVING_REQUEST = 'PANEL_SAVING_REQUEST'
export const PANEL_SAVING_SUCCESS = 'PANEL_SAVING_SUCCESS'

export const PANEL_UPDATE_FIELD = 'PANEL_UPDATE_FIELD'