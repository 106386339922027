import * as types from '../constants/globalTypes'
import { RSAA } from 'redux-api-middleware'
import { API_ROOT } from "../utils/api";


export function getColumns(type) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/columns/` + type, // TODO: make base URL constant
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.COLUMNS_LOADING_REQUEST,
                types.COLUMNS_LOADING_SUCCESS,
                types.COLUMNS_LOADING_ERROR,
            ],
        },
    }
}

