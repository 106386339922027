
import {
    LAW_TAG_BRANDS_LOADING_ERROR,
    LAW_TAG_BRANDS_LOADING_REQUEST,
    LAW_TAG_BRANDS_LOADING_SUCCESS,
    LAW_TAG_FAMILIES_LOADING_ERROR,
    LAW_TAG_FAMILIES_LOADING_REQUEST,
    LAW_TAG_FAMILIES_LOADING_SUCCESS,
    LAW_TAG_PDF_LOADING_ERROR,
    LAW_TAG_PDF_LOADING_REQUEST,
    LAW_TAG_PDF_LOADING_SUCCESS,
    LAW_TAG_BRAND_SELECTED,
    LAW_TAG_FAMILY_SELECTED,
    LAW_TAG_SKU_SELECTED,
    LAW_TAG_SKU_REMOVED,
    LAW_TAG_SKUS_LOADING_ERROR,
    LAW_TAG_SKUS_LOADING_REQUEST,
    LAW_TAG_SKUS_LOADING_SUCCESS,
    LAW_TAG_UPDATE_FIELD,
    LAW_TAG_ZPL_LOADING_ERROR,
    LAW_TAG_ZPL_LOADING_REQUEST,
    LAW_TAG_ZPL_LOADING_SUCCESS,
    LAW_TAG_CATEGORIES_LOADING_REQUEST,
    LAW_TAG_CATEGORIES_LOADING_SUCCESS,
    LAW_TAG_CATEGORIES_LOADING_ERROR,
    LAW_TAG_CATEGORY_SELECTED,
    LAW_TAG_RESET_FORM_ERROR,
    LAW_TAG_RESET_FORM_REQUEST, LAW_TAG_RESET_FORM_SUCCESS,
} from '../constants/lawTagViewerTypes'


const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    s3url: '',
    zpl: '',
    categories: [],
    selectedCategoryId: 1,
    selectedCategory: {
        id: 1,
        category: 'Mattress',
    },
    skus: [],
    selectedSku: '',
    filteredSkus: [],
    selectedSkus: [],
    selectedBrand: {},
    selectedFamily: {},
}

export default function lawTagViewerReducer(state = initialState, action) {
    switch (action.type) {
        case LAW_TAG_UPDATE_FIELD:
            return {
                ...state,
                name,
                value
            }
        case LAW_TAG_PDF_LOADING_REQUEST:
        case LAW_TAG_ZPL_LOADING_REQUEST:
        case LAW_TAG_CATEGORIES_LOADING_REQUEST:
        case LAW_TAG_BRANDS_LOADING_REQUEST:
        case LAW_TAG_FAMILIES_LOADING_REQUEST:
        case LAW_TAG_SKUS_LOADING_REQUEST:
        case LAW_TAG_RESET_FORM_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case LAW_TAG_PDF_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                s3url: action.payload.url,
            }
        case LAW_TAG_ZPL_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                zpl: action.payload.sb
            }
        case LAW_TAG_CATEGORIES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                categories: action.payload,
                brands: [],
                families: [],
                skus: [],
                filteredSkus: []
            }
        case LAW_TAG_BRANDS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                brands: action.payload,
                families: [],
                skus: [],
                filteredSkus: []
            }
        case LAW_TAG_FAMILIES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                families: action.payload,
                skus: [],
                filteredSkus: []
            }
        case LAW_TAG_SKUS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                skus: action.payload,
                filteredSkus: action.payload
            }
        case LAW_TAG_CATEGORY_SELECTED:
            return {
                ...state,
                selectedCategoryId: action.selectedCategoryId,
                selectedCategory: action.selectedCategory,
            }
        case LAW_TAG_BRAND_SELECTED:
            return {
                ...state,
                selectedBrand: action.selectedBrand,
                filteredSkus: action.filteredSkus,
                selectedSku: action.selectedSku,
            }
        case LAW_TAG_FAMILY_SELECTED:
            return {
                ...state,
                selectedFamily: action.selectedFamily,
                filteredSkus: action.filteredSkus,
                selectedSku: action.selectedSku,
            }
        case LAW_TAG_SKU_SELECTED:
        case LAW_TAG_SKU_REMOVED:
            return {
                ...state,
                filteredSkus: action.filteredSkus,
                selectedSkus: action.selectedSkus,
                selectedSku: '',
            }
        case LAW_TAG_PDF_LOADING_ERROR:
        case LAW_TAG_ZPL_LOADING_ERROR:
        case LAW_TAG_CATEGORIES_LOADING_ERROR:
        case LAW_TAG_BRANDS_LOADING_ERROR:
        case LAW_TAG_FAMILIES_LOADING_ERROR:
        case LAW_TAG_SKUS_LOADING_ERROR:
        case LAW_TAG_RESET_FORM_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
                s3url: '',
                zpl: '',
                skus: [],
                filteredSkus: [],
                selectedSkus: [],
                selectedBrand: {},
                selectedFamily: {},
                selectedSku: '',
            }
        case LAW_TAG_RESET_FORM_SUCCESS:
            return{
                isError: false,
                isLoading: false,
                isSaved: false,
                s3url: '',
                zpl: '',
                categories: action.payload.categories,
                brands: action.payload.brands,
                families: action.payload.families,
                selectedCategoryId: 1,
                selectedCategory: {
                    id: 1,
                    category: 'Mattress',
                },
                skus: action.payload.skus,
                filteredSkus: action.payload.skus,
                selectedSku: '',
                selectedSkus: [],
                selectedBrand: {},
                selectedFamily: {},
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}