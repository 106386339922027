
export const DEALER_UPDATE_FIELD = 'DEALER_UPDATE_FIELD'

export const DEALER_LOADING_REQUEST = 'DEALER_LOADING_REQUEST'
export const DEALER_LOADING_SUCCESS = 'DEALER_LOADING_SUCCESS'
export const DEALER_LOADING_ERROR = 'DEALER_LOADING_ERROR'

export const DEALER_IMPORT_LOADING_REQUEST = 'DEALER_IMPORT_LOADING_REQUEST'
export const DEALER_IMPORT_LOADING_SUCCESS = 'DEALER_IMPORT_LOADING_SUCCESS'
export const DEALER_IMPORT_LOADING_ERROR = 'DEALER_IMPORT_LOADING_ERROR'

export const DEALER_SAVING_REQUEST = 'DEALER_SAVING_REQUEST'
export const DEALER_SAVING_SUCCESS = 'DEALER_SAVING_SUCCESS'
export const DEALER_SAVING_ERROR = 'DEALER_SAVING_ERROR'

export const DEALERS_LOADING_REQUEST = 'DEALERS_LOADING_REQUEST'
export const DEALERS_LOADING_SUCCESS = 'DEALERS_LOADING_SUCCESS'
export const DEALERS_LOADING_ERROR = 'DEALERS_LOADING_ERROR'


export const DEALERS_SAVING_REQUEST = 'DEALERS_SAVING_REQUEST'
export const DEALERS_SAVING_SUCCESS = 'DEALERS_SAVING_SUCCESS'
export const DEALERS_SAVING_ERROR = 'DEALERS_SAVING_ERROR'

export const DEALER_DELETING_REQUEST = 'DEALER_DELETING_REQUEST'
export const DEALER_DELETING_SUCCESS = 'DEALER_DELETING_SUCCESS'
export const DEALER_DELETING_ERROR = 'DEALER_DELETING_ERROR'
