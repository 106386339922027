import React from "react"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import SaveIcon from "@material-ui/icons/Save"
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

const defaultToolbarStyles = {
    iconButton: {},
};

class ScheduleListFilter extends React.Component {



    render() {
        const {
            classes,
            date,
            saveClick,
            dateChanged,
            deleteClick,
            saveDisabled
        } = this.props;

        return (
            <React.Fragment>
                <Tooltip title={"Save Schedule"}>
                    <IconButton className={classes.iconButton} onClick={saveClick} disabled={saveDisabled}>
                        <SaveIcon className={classes.deleteIcon} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Schedule Date"}>
                    <TextField
                        type="date"
                        label="Date"
                        name="date"
                        variant="outlined"
                        value={date}
                        onChange={dateChanged}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Tooltip>
                <Tooltip title={"Clear Table"}>
                    <IconButton className={classes.iconButton} onClick={deleteClick}>
                        <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        );
    }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(ScheduleListFilter);
