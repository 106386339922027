import React from 'react';
import SkuList from './List/SkuList';
import '../styles/about-page.css';

export class SkuListPage extends React.Component {

    render() {
        return (
            <SkuList/>
        );
    }
}

export default SkuListPage;
