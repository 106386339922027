import React from 'react'
import '../styles/about-page.css'
import ManufacturerForm from './forms/ManufacturerForm'

const ManufacturerFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Manufacturer</h2>
            <ManufacturerForm location={location}/>
        </div>
    )
}

export default ManufacturerFormPage