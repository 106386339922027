
import {
    MANUFACTURER_UPDATE_FIELD,
    MANUFACTURER_LOADING_REQUEST,
    MANUFACTURER_LOADING_SUCCESS,
    MANUFACTURER_LOADING_ERROR,
    MANUFACTURER_SAVING_REQUEST,
    MANUFACTURER_SAVING_SUCCESS,
    MANUFACTURER_SAVING_ERROR,
} from '../constants/manufacturerTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    id: null,
    name: '',
    abbreviation: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    postalCode: '',
    active: false,
}

export default function manufacturerFormReducer(state = initialState, action) {
    switch (action.type) {
        case MANUFACTURER_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case MANUFACTURER_LOADING_REQUEST:
        case MANUFACTURER_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case MANUFACTURER_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                name: action.payload.name,
                abbreviation: action.payload.abbreviation,
                addressLineOne: action.payload.address_line_one,
                addressLineTwo: action.payload.address_line_two,
                city: action.payload.city,
                state: action.payload.state,
                postalCode: action.payload.postal_code,
                active: action.payload.active,
            }
        case MANUFACTURER_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: null,
                name: '',
                abbreviation: '',
                addressLineOne: '',
                addressLineTwo: '',
                city: '',
                state: '',
                postalCode: '',
                active: false,
            }
        case MANUFACTURER_LOADING_ERROR:
        case MANUFACTURER_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}