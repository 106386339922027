import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Tabs,
  Tab,
  Typography
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCoins } from "@fortawesome/free-solid-svg-icons";
import ForecastWorkbookUpload from "./ForecastWorkbookUpload";
import ProductionTracker from "../report/ProductionTracker";
import "./style.css";

const ForecastPage = () => {
  const [value, setValue] = useState("one");
  const [tabValue, setTabValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container style={{ height: "100vh" }}>
      <Box mt={6} sx={{ width: "100%" }}></Box>
      <Box mt={4} pt={1} pb={1} className="forecast-page-box">
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Box mt={1} ml={2}>
              <Typography variant="h3">Forecast Upload</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Tabs
              style={{
                paddingLeft: "25px",
                paddingRight: "25px",
                justifyContent: "space-between"
              }}
              value={value}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              <Tab
                value="one"
                icon={<FontAwesomeIcon size="lg" icon={faBook} />}
                label="Forecast Workbook"
                style={{ minWidth: "50%" }}
                onClick={() => setTabValue(1)}
              />
              <Tab
                value="two"
                icon={<FontAwesomeIcon size="lg" icon={faCoins} />}
                label="Quicksight"
                style={{ minWidth: "50%" }}
                onClick={() => setTabValue(2)}
              />
            </Tabs>
          </Grid>
        </Grid>
      </Box>
      <div>{tabValue == 1 ? <ForecastWorkbookUpload /> : ""}</div>
      <div>{tabValue == 2 ? <ProductionTracker /> : ""}</div>
    </Container>
  );
};

export default ForecastPage;
