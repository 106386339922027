import React from 'react'
import '../styles/about-page.css'
import SkuForm from './forms/SkuForm'

const SkuFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Sku</h2>
            <SkuForm location={location}/>
        </div>
    )
}

export default SkuFormPage