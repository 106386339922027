
import {
    MATERIAL_GROUP_UPDATE_FIELD,
    MATERIAL_GROUPS_LOADING_REQUEST,
    MATERIAL_GROUPS_LOADING_SUCCESS,
    MATERIAL_GROUPS_LOADING_ERROR,
    MATERIAL_GROUP_IMPORT_LOADING_REQUEST,
    MATERIAL_GROUP_IMPORT_LOADING_SUCCESS,
    MATERIAL_GROUP_IMPORT_LOADING_ERROR,
    MATERIAL_GROUPS_SAVING_REQUEST,
    MATERIAL_GROUPS_SAVING_SUCCESS,
    MATERIAL_GROUPS_SAVING_ERROR,
    MATERIAL_GROUP_LOADING_REQUEST,
    MATERIAL_GROUP_SAVING_REQUEST,
    MATERIAL_GROUP_LOADING_SUCCESS,
    MATERIAL_GROUP_SAVING_SUCCESS,
    MATERIAL_GROUP_LOADING_ERROR,
    MATERIAL_GROUP_SAVING_ERROR,
    MG_MATERIALS_LOADING_REQUEST,
    MG_MATERIALS_LOADING_ERROR,
    ADD_MATERIAL,
    REMOVE_MATERIAL,
    MATERIAL_ADDED,
    MATERIAL_REMOVED, MG_MATERIALS_LOADING_SUCCESS, MATERIAL_UPDATE_BLEND,
} from '../constants/materialGroupTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    materialGroups: [],
    materialGroup: {},
    rows: [],
    cols: [],
    columns: [],
    file: '',
    id: -1,
    groupName: '',
    groupMaterials: [],
    materials: [],
    materialName: ''
}

export default function materialGroupListReducer(state = initialState, action) {
    switch (action.type) {
        case MATERIAL_GROUP_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case MATERIAL_UPDATE_BLEND:
            return {
                ...state,
                isLoading: false,
                groupMaterials: action.newGroupMaterials
            }
        case MATERIAL_GROUP_LOADING_REQUEST:
        case MATERIAL_GROUP_SAVING_REQUEST:
        case MATERIAL_GROUPS_LOADING_REQUEST:
        case MATERIAL_GROUP_IMPORT_LOADING_REQUEST:
        case MATERIAL_GROUPS_SAVING_REQUEST:
        case MG_MATERIALS_LOADING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ADD_MATERIAL:
            return {
                ...state,
                groupMaterials: action.groupMaterials,
                materials: action.newMaterials,
            }
        case MATERIAL_ADDED:
            return {
               ...state,
                materialName: '',
            }
        case REMOVE_MATERIAL:
            return {
                ...state,
                groupMaterials: action.groupMaterials,
                materials: action.newMaterials,
            }
        case MATERIAL_REMOVED:
            return {
                ...state,
                materialName: '',
            }
        case MG_MATERIALS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                materials: action.payload,
            }
        case MATERIAL_GROUPS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                materialGroups: action.payload,
            }
        case MATERIAL_GROUP_IMPORT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                materialGroups: action.materialGroups,
                rows: action.rows,
                cols: action.cols,
            }
        case MATERIAL_GROUP_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                groupName: action.payload.group_name,
                groupMaterials: action.payload.materials,
                materialName: '',
            }
        case MATERIAL_GROUP_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: -1,
                groupName: '',
                groupMaterials: [],
                materialName: '',
            }
        case MATERIAL_GROUPS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                materialGroups: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        case MATERIAL_GROUP_LOADING_ERROR:
        case MATERIAL_GROUP_SAVING_ERROR:
        case MATERIAL_GROUPS_LOADING_ERROR:
        case MATERIAL_GROUP_IMPORT_LOADING_ERROR:
        case MATERIAL_GROUPS_SAVING_ERROR:
        case MG_MATERIALS_LOADING_ERROR:
        case COLUMNS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}