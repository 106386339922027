import React  from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import {getParentCores} from "../../actions/parentCoreActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";
import parentCoreReducer from "../../reducers/parentCoreReducer";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

class ParentCoreList extends React.Component {

    getOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: false,
            customToolbar: () => {
                return (
                    <AddNewButton
                        entity={"mat-core/parentCore/-1"}
                        history={this.props.history}
                        data={data}
                    />
                )
            }
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            coreTypes: [],
            rows: [],
            cols: [],
            columns: [],
            filename: '',
            file: '',
        }
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('parent_core'))
    }

    //Toolbar functions
    handleCoreTypeSaveClick = () => {
        const {
            dispatch,
            coreTypes,
        } = this.props

        const data = {
            data: coreTypes.concat(this.state.coreTypes),
            filename: this.state.filename,
        }

        // dispatch(saveCoreTypeList(data))
        this.setState({
            cols: [],
            rows: [],
            parentCores: [],
            filename: '',
        })
    }
    handleCoreTypeDeleteClick = () => {
        this.setState({
            cols: [],
            rows: [],
            schedule: [],
        })
    }
    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {

        this.getColumns()

        const {
            dispatch,
        } = this.props

        dispatch(getParentCores())
    }

    render() {
        const {
            isLoading,
            parentCores,
            columns,
        } = this.props

        return (
            <div className="baseContainer parentCoreList">
                <h1>

                </h1>
                <MUIDataTable
                    title={"Parent Cores"}
                    data={parentCores.concat(this.state.parentCores)}
                    columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    options={this.getOptions(parentCores.concat(this.state.parentCores))}
                />
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

ParentCoreList.defaultProps = {
    date: getDBFormattedDate()
}

ParentCoreList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    coreTypes: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { parentCoreReducer } = state
    return {
        isLoading: parentCoreReducer.isLoading,
        parentCores: parentCoreReducer.parentCores,
        rows: parentCoreReducer.rows,
        cols: parentCoreReducer.cols,
        columns: parentCoreReducer.columns,
    }
}

export default withRouter(connect(mapStateToProps)(ParentCoreList))