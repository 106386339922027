
export const LAW_TAG_PDF_LOADING_REQUEST = 'LAW_TAG_PDF_LOADING_REQUEST'
export const LAW_TAG_PDF_LOADING_SUCCESS = 'LAW_TAG_PDF_LOADING_SUCCESS'
export const LAW_TAG_PDF_LOADING_ERROR = 'LAW_TAG_PDF_LOADING_ERROR'

export const LAW_TAG_ZPL_LOADING_REQUEST = 'LAW_TAG_ZPL_LOADING_REQUEST'
export const LAW_TAG_ZPL_LOADING_SUCCESS = 'LAW_TAG_ZPL_LOADING_SUCCESS'
export const LAW_TAG_ZPL_LOADING_ERROR = 'LAW_TAG_ZPL_LOADING_ERROR'

export const LAW_TAG_CATEGORIES_LOADING_REQUEST = 'LAW_TAG_CATEGORIES_LOADING_REQUEST'
export const LAW_TAG_CATEGORIES_LOADING_SUCCESS = 'LAW_TAG_CATEGORIES_LOADING_SUCCESS'
export const LAW_TAG_CATEGORIES_LOADING_ERROR = 'LAW_TAG_CATEGORIES_LOADING_ERROR'

export const LAW_TAG_BRANDS_LOADING_REQUEST = 'LAW_TAG_BRANDS_LOADING_REQUEST'
export const LAW_TAG_BRANDS_LOADING_SUCCESS = 'LAW_TAG_BRANDS_LOADING_SUCCESS'
export const LAW_TAG_BRANDS_LOADING_ERROR = 'LAW_TAG_BRANDS_LOADING_ERROR'

export const LAW_TAG_FAMILIES_LOADING_REQUEST = 'LAW_TAG_FAMILIES_LOADING_REQUEST'
export const LAW_TAG_FAMILIES_LOADING_SUCCESS = 'LAW_TAG_FAMILIES_LOADING_SUCCESS'
export const LAW_TAG_FAMILIES_LOADING_ERROR = 'LAW_TAG_FAMILIES_LOADING_ERROR'

export const LAW_TAG_SKUS_LOADING_REQUEST = 'LAW_TAG_SKUS_LOADING_REQUEST'
export const LAW_TAG_SKUS_LOADING_SUCCESS = 'LAW_TAG_SKUS_LOADING_SUCCESS'
export const LAW_TAG_SKUS_LOADING_ERROR = 'LAW_TAG_SKUS_LOADING_ERROR'

export const LAW_TAG_CATEGORY_SELECTED = 'LAW_TAG_CATEGORY_SELECTED'
export const LAW_TAG_BRAND_SELECTED = 'LAW_TAG_BRAND_SELECTED'
export const LAW_TAG_FAMILY_SELECTED = 'LAW_TAG_FAMILY_SELECTED'
export const LAW_TAG_SKU_SELECTED = 'LAW_TAG_SKU_SELECTED'
export const LAW_TAG_SKU_REMOVED = 'LAW_TAG_SKU_REMOVED'
export const LAW_TAG_UPDATE_FIELD = 'LAW_TAG_UPDATE_FIELD'

export const LAW_TAG_RESET_FORM_REQUEST = 'LAW_TAG_RESET_FORM_REQUEST'
export const LAW_TAG_RESET_FORM_ERROR = 'LAW_TAG_RESET_FORM_ERROR'
export const LAW_TAG_RESET_FORM_SUCCESS = 'LAW_TAG_RESET_FORM_SUCCESS'


