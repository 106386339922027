import {
    TOP_PANEL_TICKS_LOADING_ERROR,
    TOP_PANEL_TICKS_LOADING_REQUEST,
    TOP_PANEL_TICKS_LOADING_SUCCESS,
    SIDE_PANEL_TICKS_LOADING_ERROR,
    SIDE_PANEL_TICKS_LOADING_REQUEST,
    SIDE_PANEL_TICKS_LOADING_SUCCESS,
    BOTTOM_PANEL_TICKS_LOADING_ERROR,
    BOTTOM_PANEL_TICKS_LOADING_REQUEST,
    BOTTOM_PANEL_TICKS_LOADING_SUCCESS,
    GUSSET_PANEL_TICKS_LOADING_ERROR,
    GUSSET_PANEL_TICKS_LOADING_REQUEST,
    GUSSET_PANEL_TICKS_LOADING_SUCCESS,
    PANEL_LAYERS_LOADING_REQUEST,
    PANEL_LAYERS_LOADING_SUCCESS,
    PANEL_LAYERS_LOADING_ERROR
} from '../constants/panelLayerTypes'

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    panelLayers: [],
    topPanelTicks: [],
    sidePanelTicks: [],
    bottomPanelTicks: [],
    gussetPanelTicks: [],

}

export default function panelReducer(state = initialState, action) {
    switch (action.type) {
        case TOP_PANEL_TICKS_LOADING_REQUEST:
        case SIDE_PANEL_TICKS_LOADING_REQUEST:
        case BOTTOM_PANEL_TICKS_LOADING_REQUEST:
        case GUSSET_PANEL_TICKS_LOADING_REQUEST:
        case PANEL_LAYERS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case TOP_PANEL_TICKS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                topPanelTicks: action.payload,
            }
        case SIDE_PANEL_TICKS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                sidePanelTicks: action.payload,
            }
        case BOTTOM_PANEL_TICKS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                bottomPanelTicks: action.payload,
            }
        case GUSSET_PANEL_TICKS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                gussetPanelTicks: action.payload,
            }
        case PANEL_LAYERS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                panelLayers: action.payload
            }
        case TOP_PANEL_TICKS_LOADING_ERROR:
        case SIDE_PANEL_TICKS_LOADING_ERROR:
        case BOTTOM_PANEL_TICKS_LOADING_ERROR:
        case GUSSET_PANEL_TICKS_LOADING_ERROR:
        case PANEL_LAYERS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}
