import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {getFabricLayerTypes} from '../../actions/fabricLayerTypeActions';
import {getPanel, updateField, savePanel} from '../../actions/panelActions';
import {getFabricUses} from '../../actions/fabricUseActions';
import {getParts} from '../../actions/partActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import fabricUseReducer from "../../reducers/fabricUseReducer";
import {addLayer} from "../../actions/coreActions";
import {layerAdded} from "../../actions/coreLayerActions";
import {Paper} from "@material-ui/core";

class PanelForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        this.getPanel()
        this.getFabricUseList()
        this.getPartsList()
        this.getFabricLayerTypesList()
    }

    getPanel = e => {
        const {dispatch, isLoading,} = this.props

        const id = this.props.match.params.id
        if (id) {
            dispatch(getPanel(id))
        }
    }
    getFabricUseList = () => {
        const {dispatch} = this.props
        dispatch(getFabricUses())
    }
    getPartsList = () => {
        const {dispatch} = this.props
        dispatch(getParts())
    }
    getFabricLayerTypesList = () => {
        const {dispatch} = this.props
        dispatch(getFabricLayerTypes())
    }

    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSuccess) {
            this.setState({ open: true })
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    handleShowHideNewLayer = e => {
        e.preventDefault()
        let show = false
        if(!this.state.showNewLayer){
            show = true
        }
        this.setState({showNewLayer: show})
    }

    handleRemoveLayer = async (index) => {
        // console.log(index, 'index')
        let newLayerArr = this.state.layers
        await newLayerArr.splice(index, 1)
        // console.log(newLayerArr, 'newlayers arr')
        await this.setState({layers: newLayerArr})
    }

    handleAddNewLayer = async (e) => {
        e.preventDefault()
        const {
            dispatch,
            fabricLayerTypes,
            parts,
        } = this.props

        let ftId = null
        let ftName = null
        const ftObj = fabricLayerTypes.find(item => item.fabric_layer_type === this.state.fabricLayerType)
        if(ftObj !== undefined){
            ftId = ftObj.id
            ftName = ftObj.fabric_layer_type
        }

        let pId = null
        let pNum = null
        let pDesc = null
        const pObj = parts.find(item => item.num === this.state.partNum)
        if(pObj !== undefined) {
            pId = pObj.id
            pNum = pObj.num
            pDesc = pObj.internal_desc
        }

        const layer = {
            id: null,
            panel_id: null,
            fishbowl_part_id: pId,
            ftName: ftName,
            fabric_layer_type_id: ftId,
            pNum: pNum,
            pDesc: pDesc
        }

        if(layer.fishbowl_part_id && layer.fabric_layer_type_id){
            await this.setState({layers: [...this.state.layers, layer]})
            await this.setState({fabricLayerType: ''})
            await this.setState({partNum: ''})
        }
        this.setState({showNewLayer: false})
    }

    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
        showNewLayer: false,
        layers: [],
        fabricLayerType: '',
        partNum: ''
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            fabricUses,
            fabricUseName
        } = this.props
        let fabricUseId = null
        const fabricUseObj = await fabricUses.find(use => use.fabric_use === fabricUseName)
        if(fabricUseObj != undefined){
            fabricUseId = fabricUseObj.id
        }
        await this.state.layers.forEach(l => {
            delete l.ftName;
            delete l.pNum;
            delete l.pDesc;
        })
        let panel = {
            id: null,
            fabric_use_id: fabricUseId,
            panelLayers: this.state.layers
        }
        // await console.log(panel, 'panel from handlesubmite')
        await dispatch(savePanel(panel))
        if(!isError) {
            this.props.history.push("/panels")
        }
    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            id,
            parts,
            fabricUses,
            fabricUseName,
            fabricLayerTypes
        } = this.props
        //filtering the fishbowl parts by categories
        let filterCategories = ['Fabric', 'FR', 'Backing', 'Roll Foam', 'Fiber', 'Label', 'Handles']
        let filteredParts = parts.filter(part => filterCategories.indexOf(part.category) > -1)

        return (
            <div className="baseContainer projectForm">
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    className="success"
                    onClose={this.handleClose}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Panel saved</span>}
                />
                <h1>
                </h1>
                <FormControl className={'sku-form-select'}>
                    <InputLabel>Panel Type</InputLabel>
                    <Select
                        className={'sku-select'}
                        name={"fabricUseName"}
                        placeholder={"Panel Type"}
                        value={fabricUseName}
                        key={fabricUseName}
                        input={<Input name="fabricUseName" id="fabricUseName-placeholder"/>}
                        onChange={this.handleChange}
                    >
                        {fabricUses.map((option) => <MenuItem key={option.id} value={option.fabric_use}>{option.fabric_use}</MenuItem>)}
                    </Select>
                </FormControl>
                {this.state.layers.map((l, i)=> {
                    const paperStyle = {
                        // background: 'linear-gradient(45deg, ' + l.material.color + ' 30%, ' + '#CFB6AE' + ' 90%)',
                        background: 'lightgrey',
                        border: 0,
                        borderRadius: 3,
                        color: 'black',
                        fontWeight: 'bold',
                        paddingTop: '15px',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        paddingBottom: '15px',
                        width: '700px',
                    }
                    return (
                        <div key={i}>
                            <div style={{padding: '2px', marginLeft: 20, marginTop: 20, width: 600}}>
                                <Paper style={paperStyle}>
                                <FontAwesomeIcon icon={faTimes} style={{ fontSize: '1.75em', cursor: 'pointer'}} onClick={() => this.handleRemoveLayer(i)}/>
                                <span style={{fontSize: 20, fontWeight: 'bold', marginLeft: 30}}>   {l.ftName} - {l.pNum} - {l.pDesc}</span>
                                </Paper>
                            </div>
                        </div>
                    )
                })}
                {!this.state.showNewLayer &&
                <FormControl margin={"normal"} style={{marginLeft: "20px", display: 'block', marginTop: 60}}>
                    <Button margin="normal" variant="contained"  onClick={this.handleShowHideNewLayer}>Add New Layer</Button>
                </FormControl>}
                {this.state.showNewLayer &&
                <div className={'newLayerForm'} style={{marginLeft: 20, marginTop: 40}}>
                    <div style={{display: 'block'}}><button onClick={this.handleShowHideNewLayer}><FontAwesomeIcon icon={faTimes} style={{ fontSize: '1.75em' }} /></button></div>
                    <div style={{marginTop: 20}}>
                        <FormControl margin={"normal"} variant="outlined" style={{minWidth: 200, marginRight: "15px"}}>
                            <InputLabel
                                ref={ref => {
                                    this.InputLabelRef = ref;
                                }}
                                htmlFor="layerType-placeholder"
                            >
                                Layer Type
                            </InputLabel>
                            <Select
                                label={"Layer Type"}
                                name={"fabricLayerType"}
                                placeholder={"Layer Type"}
                                value={this.state.fabricLayerType}
                                //styles={styles}
                                input={<Input name="fabricLayerType" id="fabricLayerType-placeholder"/>}
                                onChange={(e) => this.setState({fabricLayerType: e.target.value})}
                            >
                                {fabricLayerTypes.map((option) => <MenuItem key={option.id}
                                                                        value={option.fabric_layer_type}>{option.fabric_layer_type}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl margin={"normal"} variant="outlined" style={{minWidth: 160, marginRight: "15px", marginTop: 6}}>
                            <Autocomplete
                                id="autocomplete-bottom-panel"
                                options={filteredParts}
                                getOptionLabel={(options) => options.num + ' - ' + options.internal_desc}
                                onSelect={(e) => this.setState({partNum: e.target.value.split(' -')[0]})}
                                style={{width: 300}}
                                renderInput={(params) => <TextField {...params}
                                                                    label="Part Number"
                                                                    className={'sku-auto-complete'}/>}
                            />
                        </FormControl>
                    </div>
                    <div>
                        <Button
                            style={{marginTop: 20, background: 'rgb(30, 66, 113)', color: 'white'}}
                            variant="contained"
                            color="primary"
                            onClick={this.handleAddNewLayer}
                        >
                            Add New Layer
                        </Button>
                    </div>
                </div> }
                {!this.state.showNewLayer &&
                <div style={{marginTop: 150, marginLeft: 20}}>
                    <Button
                        variant="contained"
                        style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>}
            </div>
        )
    }
}

PanelForm.defaultProps = {

}

PanelForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    fabricLayerTypes: T.array,
    fabricUses: T.array,
    parts: T.array,
    fabricUseName: T.string
}

function mapStateToProps(state) {
    const { panelReducer, fabricUseReducer, partReducer, fabricLayerTypeReducer } = state
    return {
        isError: panelReducer.isError,
        // saveSuccess: fabricLayerTypeReducer.saveSuccess,
        fabricLayerTypes: fabricLayerTypeReducer.fabricLayerTypes,
        id: panelReducer.id,
        fabricUses: fabricUseReducer.fabricUses,
        parts: partReducer.parts,
        fabricUseName: panelReducer.fabricUseName
    }
}

export default withRouter(connect(mapStateToProps)(PanelForm))