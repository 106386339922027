import React from 'react'
import '../styles/about-page.css'
import FoundationGroupForm from './forms/FoundationGroupForm'

const FoundationGroupFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">FoundationGroup</h2>
            <FoundationGroupForm location={location}/>
        </div>
    )
}

export default FoundationGroupFormPage