import React, { useEffect, useState } from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import T from 'prop-types'
import { 
  Table, 
  TableRow, 
  TableCell, 
  TableBody, 
  TableHead, 
  TableContainer, 
  Paper, 
  Typography,
  Box } from '@material-ui/core'
import { getCores } from '../../../actions/coreActions'
import { getCoreTypes } from '../../../actions/coreTypeActions';
import TablePagination from '@material-ui/core/TablePagination';
import { getFamilies } from '../../../actions/familyActions';
import { getBrands } from '../../../actions/brandActions';
import "./skubyfishbowlpart.css";

const SkuFromFishbowlPartThroughPanelLayer = (props) => {
  const { skus, panelIds, cores, coreTypes, setSnackBarStatus } = props;
  const [isLoading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [families, setFamilies] = useState([]);
  const dispatch = useDispatch();
  const skusFilterAndSorted = skus.filter(sku => sku !== null && sku.active == 1).sort((a,b) => a.id - b.id);
  const uniqueIds = [];
  const uniqueSkus = skusFilterAndSorted.filter(element => {
  const isDuplicate = uniqueIds.includes(element.id);
  if (!isDuplicate) {
    uniqueIds.push(element.id);
    return true;
  }
  return false;
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getBrandName = async () => {
    await dispatch(getBrands())
      .then(res => {
        setBrands([...res.payload])
      })
    };
  const getFamilyName = async () => {
    await dispatch(getFamilies())
    .then(res => setFamilies([...res.payload]))
  };
  const getCoreName = async () => {
    await dispatch(getCores())
  };
  const getCoreType = async () => {
    await dispatch(getCoreTypes())
  };
  
  useEffect(() => {
    async function loaded ()  {
      await getCoreName()
      await getBrandName()
      await getFamilyName()
      await getCoreType()
      setSnackBarStatus({open: true, severity: "success", message: "SUCCESS! FISHBOWL PART FOUND"})
      setLoading(false)
    }
    loaded()
  },[])
  return (
    <Box className="fishbowl_part-sku-panel_layer-box" >
      {isLoading ?
        <Box className='loading-box'>
          <ScaleLoader
            color='#163762'
            height={55}
            width={15}
            loading={isLoading}
          />
          <Typography><b>Panel Layers Table Loading...</b></Typography>
        </Box>
      :
      <>
        <Typography className="data-table-header" variant='h4'>Skus by fishbowl part in panel layers</Typography>
        <TableContainer className='' component={Paper} style={{marginTop: '20px', marginBottom: '10px'}}>
        <Table aria-label="core sku table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Sku</TableCell>
              <TableCell align='right'>Brand</TableCell>
              <TableCell align='right'>Family</TableCell>
              <TableCell align='right'>Core</TableCell>
              <TableCell align='right'>Top Panel</TableCell>
              <TableCell align='right'>Bottom Panel</TableCell>
              <TableCell align='right'>Side Panel</TableCell>
              <TableCell align='right'>Gussett Panel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueSkus?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sku) => {
              const findCore = cores?.filter(core => core.id === sku.core_id).map(item => item.core_type_id)
              return (
              <TableRow key={sku.id}>
                <TableCell scope='row'>{sku.id}</TableCell>
                <TableCell scope='row'>{sku.sku}</TableCell>
                <TableCell align="right">
                  {brands.filter(brand => brand.id === sku.brand_id).map(item => item.brand)}
                </TableCell>
                <TableCell align="right">
                  {families.filter(family => family.id === sku.family_id).map(item => item.family)}
                </TableCell>
                <TableCell align="right">
                  {coreTypes.filter(coreType => findCore.includes(coreType.id)).map(item => item.core_type)}
                </TableCell>
                <TableCell align="right">
                  <b>{panelIds.includes(sku.top_panel_id) ? "+" : ""}</b>
                </TableCell>
                <TableCell align="right">
                  <b>{panelIds.includes(sku.bottom_panel_id) ? "+" : ""}</b>
                </TableCell>
                <TableCell align="right">
                  <b>{panelIds.includes(sku.side_panel_id) ? "+" : ""}</b>
                </TableCell>
                <TableCell align="right">
                  <b>{panelIds.includes(sku.gussett_panel_id) ? "+" : ""}</b>
                </TableCell>
              </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, { label: 'All', value: uniqueSkus.length}]}
          component="div"
          count={uniqueSkus.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      </>
      }
    </Box>
  )
}

SkuFromFishbowlPartThroughPanelLayer.propTypes = {
  skus: T.array,
  panelIds: T.array,
  cores: T.array,
  coreTypes: T.array,
}

function mapStateToProps(state) {
  const { 
    coreReducer, 
    coreTypeReducer, 
    coreLayerReducer, 
    partReducer } = state;
  return {
    cores: coreReducer.cores,
    coreTypes: coreTypeReducer.coreTypes,
    layerDepthIn: coreLayerReducer.layerDepthIn,
    parts: partReducer.parts,
  }
};
export default withRouter(connect(mapStateToProps)(SkuFromFishbowlPartThroughPanelLayer));