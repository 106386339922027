
import {
    FABRIC_USE_UPDATE_FIELD,
    FABRIC_USE_LOADING_REQUEST,
    FABRIC_USE_LOADING_SUCCESS,
    FABRIC_USE_LOADING_ERROR,
    FABRIC_USE_SAVING_REQUEST,
    FABRIC_USE_SAVING_SUCCESS,
    FABRIC_USE_SAVING_ERROR,
    FABRIC_USES_LOADING_ERROR,
    FABRIC_USES_LOADING_REQUEST,
    FABRIC_USES_LOADING_SUCCESS,
    FABRIC_USES_SAVING_ERROR,
    FABRIC_USES_SAVING_REQUEST,
    FABRIC_USES_SAVING_SUCCESS
} from '../constants/fabricUseTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    fabricUses: [],
    columns: [],
    rows: [],
    id: -1,
    fabricUse: {},
    fabricUseName: '',
}

export default function fabricUseReducer(state = initialState, action) {
    switch (action.type) {
        case FABRIC_USE_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case FABRIC_USES_LOADING_REQUEST:
        case FABRIC_USES_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case FABRIC_USE_LOADING_REQUEST:
        case FABRIC_USE_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case FABRIC_USE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                fabricUse: action.payload,
                fabricUseName: action.payload.fabric_use,
            }
        case FABRIC_USE_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: -1,
                fabricUse: {},
                fabricUseName: '',
            }
        case FABRIC_USES_LOADING_ERROR:
        case FABRIC_USES_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case FABRIC_USE_LOADING_ERROR:
        case FABRIC_USE_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case FABRIC_USES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                fabricUses: action.payload,
            }
        case FABRIC_USES_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                fabricUses: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            const columns = parseAllToJson(action.payload, 'options')
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: columns,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}