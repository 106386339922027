import React from "react";
import T from "prop-types";
import { connect } from "react-redux";
import "../../styles/newLogin.css";
import {
  loginSubmit,
  updateField,
  updatePassword,
  clearUser
} from "../../actions/loginActions";
import { Redirect } from "react-router-dom";
import { PIECE_RATE_PASS, USER_SCAN_PASS } from "../../utils/api";
//material ui
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Box, Paper } from "@material-ui/core";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameStatus: "",
      passwordStatus: ""
    };

    this.loginClick = this.loginClick.bind(this);
    this.updatePasswordClick = this.updatePasswordClick.bind(this);
  }

  loginClick = async (e) => {
    const { dispatch, isLoading, username, password, status } = this.props;
    e ? e.preventDefault() : "";
    //for scanned logins
    if (username.toUpperCase().includes("E2C")) {
      // await dispatch(loginSubmit(username, PIECE_RATE_PASS));
      await dispatch(loginSubmit(username, USER_SCAN_PASS));
      await dispatch(updateField("username", ""));
    } else {
      !username
        ? this.setState({ usernameStatus: "Username required" })
        : this.setState({ usernameStatus: "" });
      !password
        ? this.setState({ passwordStatus: "Password required" })
        : this.setState({ passwordStatus: "" });
      !username || !password ? dispatch(updateField("username", "")) : "";
      !username || !password ? dispatch(updateField("password", "")) : "";
      username !== "" && password !== ""
        ? dispatch(loginSubmit(username, password))
        : "";
    }
  };

  updatePasswordClick() {
    const { dispatch, isLoading, username, password, userId, status } =
      this.props;

    if (password === "") {
      this.setState({
        passwordStatus: "Password required"
      });
    } else {
      this.setState({
        passwordStatus: ""
      });
    }

    if (password !== "") {
      dispatch(updatePassword(userId, password));
    }
  }

  handleChange = async (e) => {
    const { dispatch } = this.props;
    if (
      e.target.value.length >= 9 &&
      e.target.value.toUpperCase().includes("E2C")
    ) {
      await dispatch(updateField(e.target.name, e.target.value));
      await this.loginClick();
    } else {
      await dispatch(updateField(e.target.name, e.target.value));
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(clearUser());
  }

  render() {
    const {
      isLoading,
      username,
      password,
      changePwdReq,
      status,
      loginResponse,
      active
    } = this.props;
    //  const {usernameStatus, passwordStatus} = this.state;
    const token = localStorage.getItem("token");

    if (isLoading) {
      return <div style={{ size: "60px" }}>Logging in...</div>;
    } else if (changePwdReq === 1) {
      return (
        <div className="Card">
          <h1>Password change required</h1>
          <div id="password">
            <label>Password</label>
            <input
              type="password"
              name="password"
              onChange={this.handleChange}
              value={password}
            />
            <p className="error-message">{status}</p>
            <button type="submit" onClick={this.updatePasswordClick}>
              Update Password
            </button>
          </div>
          <p className="error-message">
            <strong>{status}</strong>
          </p>
        </div>
      );
    } else if (token != "" && token != null && active === 1) {
      return <Redirect to={"/"} push={true} />;
    } else {
      return (
        <Container component="main">
          <Box>
            <Container
              // component="Paper"
              maxWidth="xs"
            >
              <CssBaseline />
              <div
                style={{
                  height: "auto",
                  width: "auto",
                  position: "relative"
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, 60%)"
                  }}
                >
                  <div className="login-title">
                    <Box
                      // component="image"
                      style={{
                        backgroundImage: `url(https://cdn.shopify.com/s/files/1/0335/2136/9133/t/15/assets/header-logo__desktop.svg?v=20026100176492188111643134370)`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat"
                        // position: "absolute",
                        // top: "50%",
                        // left: "50%",
                        // transform: "translate(-50%, -50%)"
                      }}
                      sx={{
                        height: 55,
                        width: 325
                      }}
                    />
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{
                        fontWeight: 500,
                        fontSize: "35px",
                        color: "#173963"
                      }}
                    >
                      DID IT SHIP?
                    </Typography>
                  </div>
                  <form noValidate>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      autoComplete="off"
                      autoFocus
                      type="text"
                      name="username"
                      onChange={this.handleChange}
                      value={username}
                    />
                    <div style={{ color: "red" }}>
                      {this.state.usernameStatus}
                    </div>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Password"
                      id="password"
                      autoComplete="off"
                      type="password"
                      name="password"
                      onChange={this.handleChange}
                      value={password}
                    />
                    <div style={{ color: "red" }}>
                      {this.state.passwordStatus}
                    </div>
                    <Button
                      fullWidth
                      variant="contained"
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#ED7137",
                        color: "white"
                      }}
                      type="submit"
                      onClick={(e) => this.loginClick(e)}
                    >
                      Sign In
                    </Button>
                    <p className="error-message">
                      <strong>{status}</strong>
                    </p>
                  </form>
                </div>
              </div>
            </Container>
          </Box>
        </Container>
      );
    }
  }
}

LoginForm.propTypes = {
  username: T.string,
  password: T.string,
  changePwdReq: T.bool,
  status: T.string,
  loginResponse: T.string
};

function mapStateToProps(state) {
  const { loginForm } = state;
  return {
    isError: loginForm.isError,
    isLoading: loginForm.isLoading,
    username: loginForm.username,
    password: loginForm.password,
    userId: loginForm.userId,
    changePwdReq: loginForm.changePwdReq,
    status: loginForm.status,
    loginResponse: loginForm.loginResponse,
    active: loginForm.active,
    noAccessStatus: loginForm.noAccessStatus
  };
}

export default connect(mapStateToProps)(LoginForm);
