import React from 'react';
import { Link } from 'react-router-dom';

const NotAuthorizedPage = () => {
    return (
        <div>
            <h4>
                You do NOT have authorization for this request.
            </h4>
            <Link to="/"> Go back to homepage </Link>
        </div>
    );
};

export default NotAuthorizedPage;
