export const PARENT_CORE_UPDATE_FIELD ='PARENT_CORE_UPDATE_FIELD'

export const PARENT_CORE_LOADING_REQUEST = 'PARENT_CORE_LOADING_REQUEST'
export const PARENT_CORE_LOADING_SUCCESS = 'PARENT_CORE_LOADING_SUCCESS'
export const PARENT_CORE_LOADING_ERROR = 'PARENT_CORE_LOADING_ERROR'

export const PARENT_CORES_LOADING_REQUEST = 'PARENT_CORES_LOADING_REQUEST'
export const PARENT_CORES_LOADING_SUCCESS = 'PARENT_CORES_LOADING_SUCCESS'
export const PARENT_CORES_LOADING_ERROR = 'PARENT_CORES_LOADING_ERROR'

export const PARENT_CORE_SAVING_REQUEST = 'PARENT_CORE_SAVING_REQUEST'
export const PARENT_CORE_SAVING_SUCCESS = 'PARENT_CORE_SAVING_SUCCESS'
export const PARENT_CORE_SAVING_ERROR = 'PARENT_CORE_SAVING_ERROR'

export const PARENT_CORES_SAVING_REQUEST = 'PARENT_CORES_SAVING_REQUEST'
export const PARENT_CORES_SAVING_SUCCESS = 'PARENT_CORES_SAVING_SUCCESS'
export const PARENT_CORES_SAVING_ERROR = 'PARENT_CORES_SAVING_ERROR'

