import React, { useState, iseEffect} from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {getDBFormattedDate} from "../../utils/dates"
import '../../styles/sku.css';
import {Paper, TableCell, TableHead, TableBody, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import {getLocationGroups, updateField, getLocations, getSkuByBrand, getSkuCycleCount, resetCycleCount,
    importCycleCount, updateDialogOpen, updateDialogClose
} from '../../actions/fishbowlActions'
import {getBrands} from "../../actions/brandActions";
import MUIDataTable from "mui-datatables"
import CycleCountModal from "../../components/CycleCountModal"

class CycleCountForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    // Runs three functions
    componentDidMount () {
        this.resetCycleCount()
        this.getLocationGroupsList()
        this.handleLocationGroupChange()
    }

    // Function 1: Gets Location Group
    getLocationGroupsList() {
        const {dispatch, userId, deviceId} = this.props
        dispatch(getLocationGroups(userId, deviceId))

    }

    // Function 2: Gets Resets the Cycle count
    resetCycleCount() {
        const {dispatch} = this.props
        dispatch(resetCycleCount())
    }

    handleDialogOpen(obj) {
        const{dispatch,} = this.props
        dispatch(updateDialogOpen(obj))
    }



    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    //update locationGroupName in reducer
    handleLocationGroupChange() {
        const { dispatch} = this.props
        dispatch(updateField("locationGroupName", "BB3"))
        this.handleGetLocations()
    }

    //get the list of locations based on the location group selected
    handleGetLocations = async e => {
        const { dispatch, locationGroups, locationGroupName, userId, deviceId} = this.props
            let groupId = 10
            await dispatch(getLocations(groupId, userId, deviceId))
    }

    //update the locationName in reducer
    handleLocationChange = async e => {
        const { dispatch } = this.props
        await dispatch(updateField(e.target.name, e.target.value))
        await this.handleLocationResult()
    }

    /*get the cycle count based on the location name and location group id
    if the location Name is retail then just get bb brand skus and their cycle count
    if location is not retail then another input box to select by brand will appear*/
    handleLocationResult = async () => {
        const {dispatch, locationName, locationGroups, userId, locationGroupName, deviceId, cycleCount} = this.props
        await dispatch(updateField("isLoading", true))
        //await dispatch(updateField('brandName', ''))
        if(locationName == 'X Stock' || 'Q Stock'){
            //known brand id for brooklyn bedding skus bc when retail location is selected it is automatically BB skus
            await dispatch(getSkuCycleCount(10, locationName, userId, deviceId))
            await this.handleCycleCountDisplay()
        }
    }

    /*make the array we will iterate over to display the cycle count by brand
    iterate over the brand names list and if it exists in the cycle count then use that num for
    fbQty - fishbowl quantity, if not then use 0
    Qty - will be the updated quantity if a user updates the cycle count*/
    handleCycleCountDisplay = async () => {
        const {dispatch, cycleCount } = this.props
        let cycleCountDisplay = [];
        await cycleCount.forEach(s => {
            let skuObj = {
                "PartNumber": s.part_num,
                "fbQty": Math.round(s.qty),
                "Qty": ''
            }
            cycleCountDisplay.push(skuObj)
        })
        cycleCountDisplay.shift();
        await dispatch(updateField('cycleCountDisplay', cycleCountDisplay))
        cycleCountDisplay.length === 0 ? this.setState({noActiveSkusError: true}) : ''
    }

    /*user updates the cycle count and we update the Qty on the object matching the
    part number they wish to update*/
    handleQtyChange = async (value, rowData) => {
        const {dispatch, cycleCountDisplay, } = this.props
        const updatedQty = value
        let newCycleCountDisplay = []
        await cycleCountDisplay.forEach(cc => {
            let obj = {}
            if(cc.PartNumber === rowData[0]){
                obj = {
                    "PartNumber": cc.PartNumber,
                    "fbQty": cc.fbQty,
                    "Qty": updatedQty
                }
            } else {
                obj = cc
            }
            newCycleCountDisplay.push(obj)
        })
        await dispatch(updateField('cycleCountDisplay', newCycleCountDisplay))

    }



    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
        noActiveSkusError: false
    }


    // Single submit for 1 category
    handleRowSubmit = async (event, dataIndex) => {
        //event.preventDefault()
        const {
            isError,
            dispatch,
            cycleCountDisplay,
            locationGroupName,
            locationName,
            userId,
            date,
            deviceId,
            isOpen,
            isConfirmed,
            object
        } = this.props

        // Obtain information from the row index
        let obj = {
            "PartNumber": cycleCountDisplay[dataIndex].PartNumber,
            "Qty": parseInt(cycleCountDisplay[dataIndex].Qty)
        }

        // Wrap data into something Fishbowl will accept
        let wrapper = []
        wrapper.push(obj)
        let masterObj ={
            "rows": wrapper,
            "group": locationGroupName,
            "location": locationName,
            "userId": userId,
            "deviceId": deviceId,
            "date": date
        }

        if (cycleCountDisplay[dataIndex].Qty == '') {
            console.log("no data")
        } else {
            const waiting = await this.handleDialogOpen(masterObj)
        }




    }

    render() {
        const {
            isLoading,
            isError,
            saveSuccess,
            locationGroups,
            locationGroupName,
            locations,
            locationName,
            brandName,
            brands,
            cycleCountDisplay,
            deviceId,
            isOpen,
            isConfirmed,
            object,
        } = this.props
        
        // TABLE INFORMATION
        let filteredBrandNames = brands.filter(b => b.brand !== '')
        let filteredLocationGroups = locationGroups.filter(lg => lg.name === 'BB3')
        let filterLocations = ['X Stock', 'Q Stock']
        let filteredLocations = locations.filter(lg => filterLocations.indexOf(lg.name) > -1)

        let loader = {
            border: "16px solid #f3f3f3",
            borderTop: "16px solid #3498db",
            borderRadius: "50%",
            width: "130px",
            height: "130px",
            animation: "spin 2s linear infinite"
        }


        const columns = [
            {
                label: "SKU",
                name: "PartNumber",
                key: "PartNumber",
                options: {
                    customBodyRender: (PartNumber, tableMeta, updateValue) => {
                        return (
                            <span style={{fontSize: 18}}>{PartNumber}</span>
                        );
                    },
                    customHeadRender: () => {
                        return (
                                <span className={'MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-44 MUIDataTableHeadCell-fixedHeaderCommon-46 MUIDataTableHeadCell-fixedHeaderXAxis-47 MUIDataTableHeadCell-fixedHeaderYAxis-48'}
                                    style={{background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold', fontSize: 20}}>
                                    SKU
                                </span>
                        );
                    },
                },
            },
            {
                label: "Current Fishbowl Count",
                name: "fbQty",
                key: "fbQty",
                options: {
                    customBodyRender: (fbQty, tableMeta, updateValue) => {
                        return (
                            <span className={fbQty > 0 ? 'grn-num' : 'red-num'}>{fbQty}</span>
                        );
                    },
                    customHeadRender: () => {
                        return (
                            <span className={'MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-44 MUIDataTableHeadCell-fixedHeaderCommon-46 MUIDataTableHeadCell-fixedHeaderXAxis-47 MUIDataTableHeadCell-fixedHeaderYAxis-48'}
                                  style={{background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold', fontSize: 20, borderRight: '1px solid white', borderLeft: '1px solid white'}}>
                                    Current Fishbowl Count
                                </span>
                        );
                    },
                },
            },
            {

                label: "CHANGED COUNT",
                name: "Qty",
                key: "Qty",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (Qty, tableMeta, updateValue) => {
                        return (
                            <input
                                type="text"
                                value={Qty}
                                style={{height: 30, fontSize: 16}}
                                placeholder={'Quantity'}
                                onChange={(e) => this.handleQtyChange(e.target.value, tableMeta.rowData)}
                            />

                        ); // return

                    },
                    customHeadRender: () => {
                        return (
                            <span className={'MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-44 MUIDataTableHeadCell-fixedHeaderCommon-46 MUIDataTableHeadCell-fixedHeaderXAxis-47 MUIDataTableHeadCell-fixedHeaderYAxis-48'}
                                  style={{background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold', fontSize: 20}}>
                                    CHANGED COUNT
                                </span>
                        );
                    },
                },
            },
            {
                // Submit Changes Button
                name: "button",
                key: "button",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRenderLite: (button, dataIndex, tableMeta) => {
                        return (
                            <Button 
                                variant="contained" 
                                //onClick={this.handleRowSubmit(dataIndex, e.target.value)}
                                onClick={(e) => {
                                    this.handleRowSubmit(e.target.value, dataIndex)
                                }}
                                

                                >
                                Submit Data
                                </Button>
                            ); // Return
                    },
                    customHeadRender: () => {
                        return (
                            <span className={'MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-44 MUIDataTableHeadCell-fixedHeaderCommon-46 MUIDataTableHeadCell-fixedHeaderXAxis-47 MUIDataTableHeadCell-fixedHeaderYAxis-48'}
                                  style={{background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold', fontSize: 20}}>
                                    
                                </span>
                        );
                    },
                },
            },
        ];


        // DYNAMIC TABLE OPTIONS
        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
        };

        const sucess = [
            {title: "Warning - About to change Cycle Count Quantity"},
            {body: "Are you sure you want to change the quantity?"}
        ]


        // DROPDOWN CREATION
        return (
            <div className="baseContainer CycleCountForm">
                {/* Cycle Count Confirmation Model */}
                <CycleCountModal
                    open={isOpen}
                    value={isOpen}
                    sucess={sucess}
                  />
                <div>
                {locations.length > 0 ?
                <FormControl className={'sku-form-select'}>
                    <InputLabel>Location</InputLabel>
                    <Select
                        className={'sku-select'}
                        name={"locationName"}
                        placeholder={"Location"}
                        value={locationName}
                        key={locationName}
                        input={<Input name="locationName" id="locationName-placeholder"/>}
                        onChange={this.handleLocationChange}
                    >
                        {/*to make dynamic for all locations then map over locations instead of filteredLocations*/}
                        {filteredLocations.map((option) => <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>)}
                    </Select>
                </FormControl>
                : '' }
                </div>
                {cycleCountDisplay.length > 0 ?
                <div style={{marginTop: 30, marginLeft: 20}}>
                    <MUIDataTable columns={columns} options={options} data={cycleCountDisplay}/>
                </div>
                : ''}
                {this.state.noActiveSkusError ?
                    <div style={{fontSize: 20, marginTop: 20, marginLeft: 20, fontWeight: 'bold'}}>
                        The brand {brandName} has no active skus.
                    </div>
                : ''}
            </div>
        )
    }
}

CycleCountForm.defaultProps = {
    date: getDBFormattedDate()
}

CycleCountForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    locationGroups: T.array,
    locationGroup: T.string,
    locations: T.array,
    locationName: T.string,
    brandName: T.string,
    cycleCount: T.array,
    skusByBrand: T.array,
    isOpen: T.bool,
    isConfirmed: T.bool,
    object: T.object,
}

function mapStateToProps(state) {
    const { fishbowlReducer, loginForm, brandReducer } = state
    return {
        isError: fishbowlReducer.isError,
        saveSuccess: fishbowlReducer.saveSuccess,
        locationGroups: fishbowlReducer.locationGroups,
        locationGroupName: fishbowlReducer.locationGroupName,
        userId: loginForm.userId,
        locations: fishbowlReducer.locations,
        locationName: fishbowlReducer.locationName,
        brandName: fishbowlReducer.brandName,
        brands: brandReducer.brands,
        cycleCount: fishbowlReducer.cycleCount,
        skusByBrand: fishbowlReducer.skusByBrand,
        cycleCountDisplay: fishbowlReducer.cycleCountDisplay,
        filteredCycleCount: fishbowlReducer.filteredCycleCount,
        skuNamesByBrand: fishbowlReducer.skuNamesByBrand,
        deviceId: fishbowlReducer.deviceId,
        isOpen: fishbowlReducer.isOpen,
        isConfirmed: fishbowlReducer.isConfirmed,
        object: fishbowlReducer.object
    }
}

export default withRouter(connect(mapStateToProps)(CycleCountForm))