import '../../styles/shipping.scss';
import React from "react";
import T from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import '../../styles/shipping.scss';
import { getDBFormattedDate } from "../../utils/dates";
import Loader from '../global/Loader';
import ShippingOrdersListSelectedToolbar from './ShippingOrdersListSelectedToolbar';
import { Tab } from "@material-ui/core";
import Tabs from "@material-ui/core/es/Tabs/Tabs";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton";
import Refresh from '@material-ui/icons/Refresh';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import axios from "axios";
import { API_ROOT } from '../../utils/api';
import { AXIOS_HEADERS } from '../global/axiosHeaders';

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: T.node.isRequired,
  dir: T.string.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    marginTop: '2rem',
    width: '100%'
  }
});

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const defaultColumnProperties = {
  sortable: true,
  width: 250,
}

const userId = localStorage.getItem('userId');

const CustomCellDate = ({ date }) => (
  <div className="custom-table-cell">
    {moment(date).format('DD MMM YYYY HH:mm')}
  </div>
);

const customCellAwaitingShipment = {
  options: {
    customBodyRender: (value, tableMeta) => {
      if (tableMeta.columnData.name === 'date') {
        return (
          <CustomCellDate date={value} />
        )
      }
      return (
        <div className="custom-table-cell has-click" onClick={() => copyToClipboard(value)} tabIndex="0" onKeyPress={(e) => { if (e.key === "Enter") copyToClipboard(value) }}>
          <div>{value}</div>
          <FileCopyIcon className="hover-icon-copy" />
        </div>
      )
    }
  }
};

const customCellShippedOrders = {
  options: {
    customBodyRender: (value, tableMeta) => {
      if (tableMeta.columnData.name === 'date') {
        return (
          <CustomCellDate date={value} />
        )
      }
      return (
      <div className="custom-table-cell">
        {value}
      </div>)
    }
  }
}

const formattedToday = moment().format("YYYY-MM-DD");

const searchOptions = ['date', 'order-number'];

const SearchInput = ({ option, value, onChange, onKeyPress }) => (
  <Tooltip title={_.startCase(option)}>
    <TextField
      type={option === 'date' ? 'date' : 'text'}
      label={_.startCase(option)}
      name={option}
      variant="outlined"
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      InputLabelProps={{
        shrink: true,
      }}
    />
  </Tooltip>
);

class ShippingOrdersList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      cols: [],
      columns: [],
      columnsShipped: [],
      filename: '',
      file: '',
      openOrders: [],
      shippedOrders: [],
      searchQuery: {
        shippedDate: formattedToday,
        orderNumber: ''
      },
      isAwaitingOrdersLoading: false,
      isShippedOrdersLoading: false,
      tab: 0,
      lastRefresh: moment(),
      currentTime: moment(),
      openItem: null,
      anchorEl: null,
      searchOption: 'date'
    }
  }

  getColumns = async e => {
    const columns = await axios.get(`${API_ROOT}/columns/shipping`, AXIOS_HEADERS);
    await this.setState({ columns: columns.data });
  }

  getShippedColumns = async e => {
    const columns = await axios.get(`${API_ROOT}/columns/shipped`, AXIOS_HEADERS);
    await this.setState({ columnsShipped: columns.data });
  }

  handleTabChange = (event, value) => {
    this.setState({ tab: value });
    if (value === 0) {
      this.getOpenOrders();
    }
    if (value === 1) {
      this.getShippedOrders();
    }
  };

  handleChangeIndex = index => {
    this.setState({ tab: index });
  };

  handleChangeSearch = (e) => {
    const { searchOption } = this.state;
    const newQuery = {
      shippedDate: '',
      orderNumber: ''
    }
    if (searchOption === 'date') {
      newQuery.shippedDate = e.target.value;
    }
    if (searchOption === 'order-number') {
      newQuery.orderNumber = e.target.value
    }
    this.setState({ searchQuery: newQuery });
  }

  handleSearchKeypress = (e) => {
    if (e.key === 'Enter') {
      this.getShippedOrders();
    }
  }

  handleUpdateShippedOrders = async (data) => {
    const dataArray = data.data;
    const { openOrders } = this.state;
    const shippedOrders = dataArray.map(d => {
      const currentOrder = openOrders[d.dataIndex];
      return {
        "schedule_id": currentOrder.id,
        "date": moment().format("YYYY-MM-DD HH:mm:ss"),
        "user_id": userId,
        "id": null
      }
    })
    await axios.post(`${API_ROOT}/shipping/order/shipped`, shippedOrders, AXIOS_HEADERS);
    this.getOpenOrders();
  };

  handleCellClick = (value, id) => {
    const resetState = () => setTimeout(() => this.setState({ openItem: null, anchorEl: null }), 1000);
    const popoverParentElem = document.getElementById(id);
    copyToClipboard(value);
    this.setState({ openItem: id, anchorEl: popoverParentElem });
  }

  handleChangeSearchOption = (e) => {
    this.setState({ searchOption: e.target.value });
  }

  getOpenOrders = async () => {
    this.setState({ isAwaitingOrdersLoading: true });
    const openOrders = await axios.get(`${API_ROOT}/shipping/orders/open`, AXIOS_HEADERS);
    await this.setState({ openOrders: openOrders.data }, () => this.setState({ isAwaitingOrdersLoading: false, lastRefresh: moment(), currentTime: moment() }));
  }

  getShippedOrders = async () => {
    this.setState({ isShippedOrdersLoading: true });
    const { searchOption, searchQuery: { shippedDate, orderNumber } } = this.state;
    let userQuery = '';
    if (searchOption === 'date') {
      userQuery = `/shipping/orders/shipped/${moment(shippedDate).startOf('day').format()}/${moment(shippedDate).endOf('day').format()}`;
    }
    if (searchOption === 'order-number') {
      userQuery = `/shipping/order/shipped/${orderNumber}`;
    }
    const shippedOrders = await axios.get(`${API_ROOT}${userQuery}`, AXIOS_HEADERS);
    await this.setState({ shippedOrders: shippedOrders.data }, () => this.setState({ isShippedOrdersLoading: false }));
  }

  getOptions(hasDate) {
    const selectableRows = this.state.tab === 0 ? 'multiple' : 'none';
    const options = {
      filterType: 'multiselect',
      responsive: 'standard',
      fixedHeader: true,
      rowsPerPage: 100,
      selectableRowsHeader: true,
      selectableRows,
      customToolbar: () => {
        return hasDate ?
          null : (
            <Tooltip title="Refresh List">
              <IconButton className={this.props.classes.iconButton} onClick={this.getOpenOrders}>
                <Refresh />
              </IconButton>
            </Tooltip>
          );
      },
      customToolbarSelect: (selectedRows) => {
        return <ShippingOrdersListSelectedToolbar selectedRows={selectedRows} onClick={this.handleUpdateShippedOrders} />
      }
    }
    return options
  }

  getAletType() {
    const { lastRefresh, currentTime } = this.state;
    const diffLastRefreshToNow = currentTime.diff(lastRefresh, 'minutes');
    let type = 'success';
    if (diffLastRefreshToNow >= 9) {
      type = 'error';
    } else if (diffLastRefreshToNow >= 4) {
      type = 'warning';
    }

    return type;
  }


  componentDidMount() {
    this.getColumns();
    this.getShippedColumns();
    this.getOpenOrders();
    this.interval = setInterval(() => this.setState({ currentTime: moment() }), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      classes,
      theme
    } = this.props

    const {
      tab,
      isAwaitingOrdersLoading,
      isShippedOrdersLoading,
      columns,
      columnsShipped,
      openOrders,
      shippedOrders,
      searchOption,
      searchQuery: {
        shippedDate,
        orderNumber
      }
    } = this.state;

    const getSearchValue = () => {
      if (searchOption === 'date') {
        return shippedDate;
      }
      if (searchOption === 'order-number') {
        return orderNumber;
      }
      return '';
    }
    return (
      <div className="baseContainer">
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={tab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Awaiting Shipment" />
              <Tab label="Shipped Orders" />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={this.state.tab}
            onChangeIndex={this.handleChangeIndex}
          >
            <TabContainer dir={theme.direction}>
              {isAwaitingOrdersLoading ? <Loader isLoading={isAwaitingOrdersLoading} /> :
                <>
                  <Alert key={this.state.currentTime.format()} severity={this.getAletType()}>Last refreshed {moment(this.state.lastRefresh).fromNow()}</Alert>
                  <MUIDataTable
                    className="awaiting-shipment-table"
                    title={"Awaiting Shipment"}
                    data={openOrders}
                    columns={columns.map(c => ({ ...c, ...defaultColumnProperties, ...customCellAwaitingShipment }))}
                    options={this.getOptions()}
                  />
                </>
              }
            </TabContainer>
            <TabContainer dir={theme.direction}>
              {isShippedOrdersLoading ? <Loader isLoading={isShippedOrdersLoading} /> :
                <>
                  <div className="shipped-orders-search-container">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="search-by-select-label">Search By</InputLabel>
                      <Select
                        labelId="search-by-select-label"
                        id="search-by-select"
                        value={searchOption}
                        onChange={this.handleChangeSearchOption}
                      >
                        {searchOptions.map(option => (
                          <MenuItem value={option} key={`select-option-${option}`}>
                            {_.startCase(option)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <SearchInput option={searchOption} value={getSearchValue()} onChange={this.handleChangeSearch} onKeyPress={this.handleSearchKeypress} />
                    <Tooltip title={"Search Shipped Orders"}>
                      <Button onClick={this.getShippedOrders} color="primary" variant="contained" >Search Orders</Button>
                    </Tooltip>
                  </div>
                  <MUIDataTable
                    title={"Shipped Orders"}
                    data={shippedOrders}
                    columns={columnsShipped.map(c => ({ ...c, ...defaultColumnProperties, ...customCellShippedOrders }))}
                    options={this.getOptions(true)}
                  />
                </>
              }
            </TabContainer>
          </SwipeableViews>
        </div>
      </div>
    )
  }
}

ShippingOrdersList.defaultProps = {
  date: getDBFormattedDate()
}

ShippingOrdersList.propTypes = {
  isLoading: T.bool,
  isSaved: T.bool,
  rows: T.array,
  cols: T.array,
  columns: T.array,
  file: T.string,
}

function mapStateToProps(state) {
  const { loginForm } = state
  return {
    role: loginForm.userRole
  }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(ShippingOrdersList)))


