import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from "@material-ui/core/MenuItem";
import '../../styles/user.css';
import { getUser, saveUser, updateField, updatePasswordReqd, resetUser} from '../../actions/userActions';
import {getRoles} from '../../actions/roleActions'
import {resetSKU} from "../../actions/skuActions";


class UserForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        const {dispatch} = this.props
        this.getSingleUser()
        dispatch(getRoles())
    }

    getSingleUser = async () => {
        const {dispatch} = this.props

        await dispatch(resetUser())
        const id = await this.props.match.params.id
        dispatch(getUser(id))
    }


    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    handleRoleChange = async (e) => {
        const {rolesAvailable, dispatch} = this.props
        let finalArr = e.target.value.map((r) => {
            let roleObj = rolesAvailable.find(role => role.name === r)
            return roleObj
        })
        await this.setState({roles: finalArr})
        await dispatch(updateField('userRole', this.state.roles))
    }

    handlePasswordChange = (name, value) => {
        const {dispatch} = this.props
        dispatch(updatePasswordReqd(name, value))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSuccess) {
            this.setState({ open: true })
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    handleActiveChange = e => {
        const {
            dispatch,
            active,
        } = this.props
        dispatch(updateField('active', active ? 0 : 1))
    }
    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
        roles: []
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            username,
            firstName,
            lastName,
            id,
            changePwdReqd,
            active,
            userRole
        } = this.props
        const user = {
            id: id !== -1 ? id : null,
            username: username,
            first_name: firstName,
            last_name: lastName,
            change_pwd_reqd: changePwdReqd,
            active: active,
            roles: userRole
        }
        if(!id){
            delete user.id
        }

        await dispatch(saveUser(user))
        if(!isError) {
            this.props.history.push("/users")
        }
    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            id,
            username,
            firstName,
            lastName,
            changePwdReqd,
            active,
            userRoleName,
            rolesAvailable,
        } = this.props

        let activeButtonColor = active === 1 ? '#009900' : '#cc2900'
        return (
            <div className="baseContainer projectForm">
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    className="success"
                    onClose={this.handleClose}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">User saved</span>}
                />
                <div className='user-container'>
                    <h1 style={{marginLeft: 0,}}>
                        {firstName} {lastName}
                    </h1>
                    <Button
                        name={'active'}
                        variant="contained"
                        style= {{backgroundColor: activeButtonColor, color: 'white', marginBottom: 20, maxWidth: 250,}}
                        onClick={this.handleActiveChange}
                    >
                        {active === 1 ? 'Active' : 'Inactive'}
                    </Button>
                    <div>
                        <TextField
                            label="First Name"
                            name="firstName"
                            margin="normal"
                            variant="outlined"
                            className="name-field"
                            value={firstName}
                            onChange={this.handleChange}
                            style={{marginRight: 30,}}
                        />
                        <TextField
                            label="Last Name"
                            name="lastName"
                            margin="normal"
                            variant="outlined"
                            className="name-field"
                            value={lastName}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div style={{marginTop: 20,}}>
                        <TextField
                            label="Username"
                            name="username"
                            margin="normal"
                            variant="outlined"
                            className="name-field"
                            value={username}
                            onChange={this.handleChange}
                            style={{marginRight: 10,}}
                        />

                        <FormControl className={'user-form-select'}>
                            <InputLabel id="demo-mutiple-chip-label">Roles</InputLabel>
                            <Select
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                multiple
                                value={userRoleName ? userRoleName : ''}
                                name={'userRoleName'}
                                onChange={(e) => {this.handleChange(e), this.handleRoleChange(e)}}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={() => (
                                    <div>
                                        {userRoleName ? userRoleName.map((r) => (
                                            <Chip key={r} label={r}/>
                                        )): ''}
                                    </div>
                                )}
                            >
                                {rolesAvailable ? rolesAvailable.map((role) => (
                                    <MenuItem key={role.id} value={role.name}>
                                        {role.name}
                                    </MenuItem>
                                )) : ''}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            className={'user-pwd-switch'}
                            label="Password Change Required"
                            name='changePwdReqd'
                            control={
                                <Switch
                                    checked={changePwdReqd}
                                    onChange={(e) => {
                                        let value = changePwdReqd === 1 ? 0 : 1
                                        this.handlePasswordChange(e.target.name, value)
                                    }}
                                />}
                          />
                    </div>
                    <div className='submit-user-btn'>
                        <Button
                            variant="contained"
                            style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

UserForm.defaultProps = {

}

UserForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    firstName: T.string,
    lastName: T.string,
    username: T.string,
    password: T.string,
    changePwdReqd: T.bool,
    active: T.bool,
    userRole: T.array,
    roles: T.array
}

function mapStateToProps(state) {
    const { userForm, roleReducer } = state
    return {
        isError: userForm.isError,
        saveSuccess: userForm.saveSuccess,
        id: userForm.id,
        firstName: userForm.firstName,
        lastName: userForm.lastName,
        username: userForm.username,
        password: userForm.password,
        changePwdReqd: userForm.changePwdReqd,
        active: userForm.active,
        userRole: userForm.userRole,
        userRoleName: userForm.userRoleName,
        rolesAvailable: roleReducer.roles
    }
}

export default withRouter(connect(mapStateToProps)(UserForm))
