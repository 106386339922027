import * as types from "../constants/coreTypeTypes";
import { RSAA } from "redux-api-middleware";
import { API_ROOT } from "../utils/api";

// GET ALL CORE TYPE FOR PARENT USING PARENT CORE ID
export function getCoresByParentId(parentId) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/core-types/${parentId}`, // TODO: make base URL constant
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_TYPES_LIST_LOADING_REQUEST,
                types.CORE_TYPES_LIST_LOADING_SUCCESS,
                types.CORE_TYPES_LIST_LOADING_ERROR,
            ],
        },
    }
}