import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import XLSX from "xlsx";
import { styled } from "@material-ui/styles";
import { Button, Container, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  createCampaign,
  getCampaign,
  createStoreProcedure
} from "../../actions/forecast/forecastActions";
import moment from "moment";
import { ExportToExcel } from "../template/ExportToExcel";
import ForecastModal from "./forecastModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faSave } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const EXTENTIONS = ["xlsx", "xls", "cvs"];
const initialState = "";

const Campaign = () => {
  const isAuthenticated = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [colDefs, setColDefs] = useState();
  const [data, setData] = useState();
  const Input = styled("input")({
    display: "none"
  });
  const [excelData, setExcelData] = useState([
    { name: "", description: "", date_start: "", date_end: "" }
  ]);
  const fileName = "Campaign Upload Template";
  const [sucessValue, setSucessValue] = useState(false);
  const [sucess, setSucess] = useState("");
  const [redirect, setRedirect] = useState("/campaign-curve-upload");
  const [campaign, setCampaign] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(getCampaign()).then((res) => {
      setCampaign(res.payload);
    });
  }, [error]);

  function validate(data) {
    setError(initialState);
    let campaignError = "";
    campaign.filter((cam) => {
      data.filter((d) => {
        if (cam.name === d.name) {
          if (cam.as_of_date === "9999-01-01T07:00:00.000Z") {
            campaignError = (
              <div style={{ lineHeight: "35px" }}>
                <div>
                  <b>Campaign Already Exist!</b> &nbsp;
                </div>
                <div>
                  <b>Please Archive and Save to Create New Campaign</b>
                </div>
              </div>
            );
          } else {
            campaignError = "";
          }
        }
      });
    });
    if (campaignError) {
      setError(campaignError);
      window.scrollTo(0, 0);
      return false;
    }
    setError(initialState);
    return true;
  }
  const createForcast = (data) => {
    const isValid = validate(data);
    if (isValid) {
      if (data != null) {
        data.filter((d) => {
          const campaignData = {
            name: d.name,
            description: d.description,
            date_start: d.date_start,
            date_end: d.date_end,
            as_of_date: moment("9999-01-01").format("YYYY-MM-DD"),
            upload_id: isAuthenticated
          };
          dispatch(createCampaign(campaignData)).then((res) => {
            if (res.type == "FORECAST_LOADING_SUCCESS") {
              setSucessValue(true);
              setSucess([
                { title: "Sucess!!" },
                {
                  body: "You had a sucessful upload! You will now be redirected to upload campaign Curve Upload"
                }
              ]);
            }
          });
        });
      }
    }
  };

  const getExtention = (file) => {
    const parts = file.name.split(".");
    const extention = parts[parts.length - 1];
    return EXTENTIONS.includes(extention);
  };
  const convertToJson = (headers, data) => {
    let rows = [];
    data.forEach((row) => {
      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });

      let newDateStart = new Date(
        Math.round((rowData.date_start - 25569) * 864e5)
      );
      newDateStart.setMinutes(
        newDateStart.getMinutes() + newDateStart.getTimezoneOffset()
      );
      let newDateEnd = new Date(Math.round((rowData.date_end - 25569) * 864e5));
      newDateEnd.setMinutes(
        newDateEnd.getMinutes() + newDateEnd.getTimezoneOffset()
      );
      let newRow = {
        name: rowData.name,
        description: rowData.description,
        date_start:
          rowData.date_start == ""
            ? rowData.date_start
            : moment(newDateStart).format("YYYY-MM-DD"),
        date_end:
          rowData.date_end == ""
            ? rowData.date_end
            : moment(newDateEnd).format("YYYY-MM-DD"),
        as_of_date: moment("9999-01-01").format("YYYY-MM-DD")
      };
      rows.push(newRow);
    });
    return rows;
  };

  const importExcel = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: "binary" });
      console.log(workBook);
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      console.log(workSheetName);

      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const headers = fileData[0];
      const heads = headers.map((head) => ({ title: head, field: head }));
      setColDefs(heads);

      fileData.splice(0, 1);
      setData(convertToJson(headers, fileData));
    };
    if (file) {
      if (getExtention(file)) {
        reader.readAsBinaryString(file);
      } else {
        alert("Invalid file input, Select Excel, CSV file");
      }
    } else {
      setData([]);
      setColDefs([]);
    }
  };
  function archive(e) {
    dispatch(createStoreProcedure()).then((res) => {
      if (res.type === "FORECAST_LOADING_SUCCESS") {
        setError(initialState);
      }
    });
  }

  return (
    <div>
      <ForecastModal
        open={sucessValue}
        value={sucessValue}
        redirect={redirect}
        sucess={sucess}
      />
      <Container maxWidth="xl">
        <div className="campaign-header" style={{ marginTop: "40px" }}>
          <Grid container>
            <Grid item>
              <p
                style={{
                  fontSize: "35px",
                  fontWeight: "700",
                  color: "rgb(0, 55, 100)"
                }}
              >
                Campaign Upload
              </p>
            </Grid>
          </Grid>
        </div>
        <Grid container style={{ marginTop: "25px", marginBottom: "25px" }}>
          <Grid item xs={3} container>
            <label htmlFor="contained-button-file">
              <Input
                accept="xlsx, xls, cvs"
                id="contained-button-file"
                type="file"
                onChange={importExcel}
                multiple
              />
              <Button
                size="large"
                variant="contained"
                component="span"
                style={{ backgroundColor: "#003764", color: "white" }}
              >
                Upload File &nbsp; <FontAwesomeIcon icon={faUpload} />
              </Button>
            </label>
          </Grid>

          <Grid item container xs={6}>
            <ExportToExcel
              apiData={excelData}
              fileName={fileName}
              title={"Download Template"}
            />
          </Grid>
        </Grid>
        {error ? (
          <>
            <div style={{ fontSize: 24, color: "red" }}>{error}</div>
            <Grid container style={{ marginTop: "25px" }}>
              <Grid item xs={3}>
                <Button
                  size="large"
                  variant="contained"
                  component="span"
                  style={{ backgroundColor: "#003764", color: "white" }}
                  onClick={(e) => archive()}
                >
                  Archive
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        <MaterialTable
          options={{
            pageSize: 20,
            addRowPosition: "first",
            actionsColumnIndex: -1
          }}
          title="Campagin Upload"
          data={data}
          columns={colDefs}
        />
        <Grid container direction="column" alignItems="flex-end">
          <Button
            variant="contained"
            style={{ backgroundColor: "#003764", color: "white" }}
            onClick={() => createForcast(data)}
          >
            Save &nbsp; <FontAwesomeIcon icon={faSave} />
          </Button>
        </Grid>
      </Container>
    </div>
  );
};

export default Campaign;
