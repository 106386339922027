import React from "react";
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core";

class LogoutPage extends React.Component {
  state = {
    navigate: false
  };

  logout = () => {
    localStorage.setItem("token", "");
    this.setState({ navigate: true });
  };

  render() {
    const { navigate } = this.state;
    let logoutItems = [
      "token",
      "firstName",
      "lastName",
      "employeeNum",
      "userId",
      "userRole"
    ];
    logoutItems.forEach((obj) => {
      localStorage.removeItem(obj);
    });

    // localStorage.setItem('token', '')
    return <Redirect to={"/login"} push={true} />;

    // return <Button onClick={this.logout}>Logout</Button>
  }
}

export default LogoutPage;
