import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../styles/piece-rate.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { PIECE_RATE_PASS } from "../../utils/api";
import { loginSubmit } from "../../actions/loginActions";
import moment from "moment";
import {
  updateScanStatus,
  postScanLog,
  updateField,
  getStatusList,
  getUserTotalScansByStatusToday,
  getScanScheduleSerialData
} from "../../actions/scanActions";

class PieceRateScanForm extends React.Component {
  constructor(props) {
    super(props);
    // Bind the this context to the handler function
    this.handleChange = this.handleChange.bind(this);
    this.textInput = React.createRef();
    this.focus = this.focus.bind(this);
    this.handleFinishScan = this.handleFinishScan.bind(this);
  }

  componentDidMount = async () => {
    await this.getStatus();
    await this.getUserTotalScansByStatusToday();
  };

  padLeadingZero(value) {
    return value > 9 ? value : `0${value}`;
  }

  getDateTime(date = new Date()) {
    return `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()} ${date.getHours()}:${this.padLeadingZero(
      date.getMinutes()
    )}:${this.padLeadingZero(date.getSeconds())}`;
  }

  getUserTotalScansByStatusToday = async () => {
    const { dispatch, userId, scanStatusId } = this.props;
    await dispatch(getUserTotalScansByStatusToday(userId, scanStatusId));
  };

  getStatus = async () => {
    const { dispatch } = this.props;
    await dispatch(getStatusList());
  };

  setLogoutTimer = () => {
    let t = setTimeout(() => {
      this.props.history.push("/logout");
    }, 900000);
    this.setState({ timer: t });
  };

  clearScanInfoTimer = async () => {
    const { dispatch } = this.props;
    dispatch(updateField("scanLog", {}));
    await setTimeout(() => {
      this.setState({
        serialNumber: "",
        scannedUser: "",
        scannedStation: "",
        scannedDate: "",
        sku: "",
        orderNum: "",
        alreadyScanned: null,
        disableInput: false,
        notFound: ""
      });
      this.focus();
    }, 15000);
  };

  focus() {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    this.textInput.current.focus();
  }

  handleFinishScan = async (e) => {
    if (e.key === "Enter") {
      if (
        !this.state.serialNumber.includes("E2C") &&
        this.state.serialNumber.length !== 16
      ) {
        this.setState({
          serialNumber: "",
          scannedUser: "",
          scannedStation: "",
          scannedDate: "",
          sku: "",
          orderNum: "",
          alreadyScanned: null
        });
      }
    }
  };

  handleChange = async (e) => {
    const { dispatch, userId, scanStatusId } = this.props;
    await this.setState({ serialNumber: e.target.value }, async () => {
      if (this.state.serialNumber.length >= 9) {
        if (this.state.serialNumber.includes("E2C")) {
          await dispatch(loginSubmit(this.state.serialNumber, PIECE_RATE_PASS));
          this.setState({ serialNumber: "" });
          await dispatch(
            getUserTotalScansByStatusToday(
              this.props.userId,
              this.props.scanStatusId
            )
          );
        }
      }
      if (this.state.serialNumber.length === 16) {
        this.setState({ disableInput: true });
        //find out if serial number + schedule record exists
        await dispatch(
          getScanScheduleSerialData(
            this.state.serialNumber,
            this.props.scanStatusId
          )
        );
        //scanScheduleSerialData on props
        // if serial number + schedule record can not be found then display not found
        if (JSON.stringify(this.props.scanScheduleSerialData) === "{}") {
          this.setState({
            notFound: "This is not a Brooklyn Bedding Mattress"
          });
          clearTimeout(this.state.timer);
          this.clearScanInfoTimer();
          this.setLogoutTimer();
        } else {
          //set state of scanData object that was found
          await this.setState({ scanData: this.props.scanScheduleSerialData });
          await this.setState({ sku: this.state.scanData.sku });
          await this.setState({ orderNum: this.state.scanData.order_number });
          //check to see if scan_log record exists for serial number + status/station (tapping, mat build, etc)
          let alreadyScanned = await this.state.scanData.scans.find(
            (scan) =>
              scan.epc == this.state.serialNumber &&
              scan.status_id == this.props.scanStatusId
          );
          if (alreadyScanned == undefined) {
            //no scan_log record exists for this serial number + status - so insert record
            let time = this.getDateTime();
            let scanLogObj = {
              id: null,
              status_id: this.props.scanStatusId,
              epc: this.state.serialNumber,
              date: time,
              user_id: this.props.userId,
              schedule_id: this.state.scanData.serial_numbers[0].schedule_id,
              serial_id: this.state.scanData.serial_numbers[0].id,
              pass: 1
            };
            //post to scan_log table
            await dispatch(postScanLog(scanLogObj));
            await dispatch(
              getUserTotalScansByStatusToday(userId, scanStatusId)
            );
            await this.setState({
              alreadyScanned: false,
              scannedUser:
                this.props.scanLog.user.first_name +
                " " +
                this.props.scanLog.user.last_name,
              scannedStation: this.props.scanLog.status.description,
              scannedDate: this.props.scanLog.date
            });
            clearTimeout(this.state.timer);
            await this.clearScanInfoTimer();
            await this.setLogoutTimer();
          } else {
            //scan_log record exists for this serial number + status - so display who scanned it
            await this.setState({ alreadyScanned: true });
            await this.setState({
              scannedUser:
                alreadyScanned.user.first_name +
                " " +
                alreadyScanned.user.last_name
            });
            await this.setState({
              scannedStation: alreadyScanned.status.description
            });
            await this.setState({ scannedDate: alreadyScanned.date });
            clearTimeout(this.state.timer);
            await this.clearScanInfoTimer();
            await this.setLogoutTimer();
          }
        }
      }
    });
  };

  handleStatusChange = async (e) => {
    const { dispatch, statusList } = this.props;
    const name = e.target.value;
    const statusObj = await statusList.find((item) => item.name === name);
    if (statusObj !== undefined) {
      await dispatch(updateScanStatus(name, statusObj.id));
    }
    await this.getUserTotalScansByStatusToday();
  };

  state = {
    open: false,
    vertical: "top",
    horizontal: "center",
    brandError: "",
    serialNumber: "",
    scanData: {},
    statusName: "",
    statusId: "",
    sku: "",
    orderNum: "",
    alreadyScanned: null,
    scannedUser: "",
    scannedStation: "",
    scannedDate: "",
    disableInput: false,
    notFound: "",
    timer: null
  };

  render() {
    const {
      isLoading,
      isError,
      saveSuccess,
      firstName,
      lastName,
      employeeNum,
      scanStatusName,
      statusList,
      userTotalScansByStatusToday
    } = this.props;

    let filteredStatusList = statusList.filter((scan) => scan.rfid == 0);

    return (
      // <div className={"baseContainer" }>
      <div className="scan-container">
        <div className="scan-col">
          <div className="employee-header">
            {firstName} {lastName} {employeeNum}
          </div>
          <FormControl>
            <Select
              className={"status-select"}
              name={"status"}
              placeholder={"Status"}
              value={scanStatusName}
              key={scanStatusName}
              input={<OutlinedInput name="status" id="status-placeholder" />}
              onChange={this.handleStatusChange}
            >
              {filteredStatusList.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="scan-info-container">
            <div className="scan-info-row-1">
              <span className="scan-title margin-bottom">
                <label className="right">Serial Number</label>
                <input
                  onChange={(e) => this.handleChange(e)}
                  onKeyPress={(e) => this.handleFinishScan(e)}
                  value={this.state.serialNumber}
                  autoFocus={true}
                  name="serialNumberInput"
                  className="scan-input"
                  disabled={this.state.disableInput}
                  ref={this.textInput}
                  autoComplete="off"
                />
              </span>
              {this.props.isSerialSearchLoading ? (
                <CircularProgress
                  style={{
                    display: "inline-block",
                    position: "relative",
                    marginLeft: 20
                  }}
                />
              ) : (
                ""
              )}
              <div className="not-found">{this.state.notFound}</div>
              <div
                className="scan-title margin-bottom"
                style={{ marginTop: 70 }}
              >
                SKU <span className="scan-data">{this.state.sku}</span>
              </div>

              <div className="scan-title margin-bottom">
                Order Number{" "}
                <span className="scan-data">{this.state.orderNum}</span>
              </div>
            </div>

            {this.state.scannedUser ? (
              <div
                className={
                  this.state.alreadyScanned == true
                    ? "scanner-result scan-info-row-2 already-scanned"
                    : this.state.alreadyScanned == false
                    ? "scanner-result scan-info-row-2 not-scanned"
                    : "scan-info-row-2"
                }
              >
                <span className="scan-title white">
                  <div style={{ marginLeft: 20 }}>
                    Scanned by {this.state.scannedUser}
                  </div>
                  <div class="scan-title white">
                    <div
                      style={{
                        marginBottom: 15,
                        marginTop: 40,
                        marginLeft: 20
                      }}
                    >
                      {this.state.scannedStation}
                    </div>
                    <div
                      className="scan-title white"
                      style={{
                        marginBottom: 15,
                        marginTop: 40,
                        marginLeft: 20
                      }}
                    >
                      {moment(this.state.scannedDate.replace("Z", "")).format(
                        "LLL"
                      )}
                    </div>
                  </div>
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="scan-col text-center">
          <div className="scan-title">Total Scans Today</div>
          <div className="scan-count">{userTotalScansByStatusToday}</div>
        </div>
      </div>
      // </div>
    );
  }
}

function mapStateToProps(state) {
  const { loginForm, scanList } = state;
  return {
    userId: loginForm.userId,
    firstName: loginForm.firstName,
    lastName: loginForm.lastName,
    employeeNum: loginForm.employeeNum,
    scanStatusName: scanList.scanStatusName,
    scanStatusId: scanList.scanStatusId,
    scanLog: scanList.scanLog,
    statusList: scanList.statusList,
    userTotalScansByStatusToday: scanList.userTotalScansByStatusToday,
    scanScheduleSerialData: scanList.scanScheduleSerialData,
    isSerialSearchLoading: scanList.isSerialSearchLoading
  };
}

export default withRouter(connect(mapStateToProps)(PieceRateScanForm));
