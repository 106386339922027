import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import "jspdf-autotable";

const ClosedLoad = (tableData) => {
  const [selectedRow, setSelectedRow] = useState({});
  let newData = [];
  const [table, setTable] = useState([]);

  useEffect(() => {
    const rowData = tableData.component;
    if (rowData != null) {
      let numberOfStops = [];
      rowData.filter((td) => {
        numberOfStops.push(td.stop_number);
      });
      numberOfStops = [...new Set(numberOfStops)];
      let data = tableData.component;
      let order = new Map();
      for (let i = 0, len = data.length; i < len; i++) {
        let item = data[i];
        let od = item.sku;
        if (!order.has(od)) {
          order.set(od, []);
        }
        order.get(od).push(item);
      }

      for (let ord of order.keys()) {
        let data = { sku: ord };
        newData.push(data);
        setTable(newData);
        for (let item of order.get(ord)) {
          let dt = order.get(ord);
          let truckScan = [];
          let baggedTotal = [];
          dt.filter((scans) => {
            const skusScanned = scans.scans;
            skusScanned.filter((sc) => {
              if (sc.status_id == "125") {
                truckScan.push(sc);
              }
              if (sc.status_id == "33") {
                baggedTotal.push(sc);
              }
            });
          });

          data.total_pieces = dt.length;
          if (data.sku === item.sku) {
            let child = [];
            dt.filter((data) => {
              let childTruckScan = [];
              let childBaggedScan = [];
              const skusScanned = data.scans;
              skusScanned.filter((sc) => {
                if (sc.status_id == "125") {
                  childTruckScan.push(sc);
                }
                if (sc.status_id == "33") {
                  childBaggedScan.push(sc);
                }
              });

              let newObj = {
                serial: data?.serial_numbers[0]?.serial_number,
                sku: data.sku,
                stop_number: data.stop_number,
                qty: data.qty,
                shipped: childTruckScan.length,
                short: data.qty - childTruckScan.length,
                status: [
                  data.qty == childTruckScan.length
                    ? "Shipped"
                    : childBaggedScan.length < data.qty
                    ? "In Production"
                    : "Bagged"
                ]
              };
              child.push(newObj);
            });
            data.truck_number = item.truck_number;
            data.stop_number = item.stop_number;
            data.short = data.total_pieces - truckScan.length;
            data.shipped = data.total_pieces - data.short;
            data.childData = child;
            data.status = [
              data.shipped != 0
                ? "Shipped"
                : baggedTotal.length < data.total_pieces && data.shipped == 0
                ? "In Production"
                : data.total_pieces == baggedTotal.length
                ? "Bagged"
                : baggedTotal.length != data.total_pieces
                ? "Loading"
                : ""
            ];
          }
        }
      }
    }
  }, [tableData]);
  const truckLoad = [
    {
      title: "Sku",
      field: "sku"
    },
    {
      title: "Stop",
      field: "stop_number",
      defaultSort: "desc"
    },
    {
      title: "Order Qty",
      field: "total_pieces"
    },
    {
      title: "Shipped",
      field: "shipped"
    },
    {
      title: "Short",
      field: "short"
    },
    {
      title: "Status",
      field: "status"
    }
  ];

  const childColumns = [
    {
      title: "Sku",
      field: "sku"
    },
    {
      title: "Serial Number",
      field: "serial"
    },
    {
      title: "Stop",
      field: "stop_number",
      defaultSort: "desc"
    },
    {
      title: "Order Qty",
      field: "qty"
    },
    {
      title: "Shipped",
      field: "shipped"
    },
    {
      title: "Short",
      field: "short"
    },
    {
      title: "Status",
      field: "status"
    }
  ];

  return (
    <div>
      <MaterialTable
        title=" Closed Truck Load"
        data={table}
        columns={truckLoad}
        onRowClick={(evt, selectedRow) => {
          setSelectedRow(selectedRow.tableData.id);
        }}
        options={{
          search: false,
          pageSize: 15,
          addRowPosition: "first",
          actionsColumnIndex: -1,
          rowStyle: (rowData) => {
            if (rowData.total_pieces === rowData.shipped) {
              return {
                backgroundColor: "#78f542",
                fontSize: 16
              };
            } else {
              return {
                fontSize: 16
              };
            }
          }
        }}
        detailPanel={(selectedRow) => (
          <MaterialTable
            columns={childColumns}
            data={selectedRow.rowData.childData}
            options={{
              toolbar: false,
              showTitle: false,
              search: false,
              pageSize: 10,
              addRowPosition: "first",
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false,

              rowStyle: {
                backgroundColor: "#6ABAC9",
                fontSize: 16
              }
            }}
          />
        )}
      />
    </div>
  );
};

export default ClosedLoad;
