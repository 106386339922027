import * as types from '../constants/coreTypeTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


// export function updateField(name, value) {
//     return {
//         type: types.CORE_TYPE_UPDATE_FIELD,
//         name,
//         value,
//     }
// }

export function getCoreTypes() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/types`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_TYPES_LOADING_REQUEST,
                types.CORE_TYPES_LOADING_SUCCESS,
                types.CORE_TYPES_LOADING_ERROR,
            ],
        },
    }
}

export function getCoreType(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/type/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_TYPE_LOADING_REQUEST,
                types.CORE_TYPE_LOADING_SUCCESS,
                types.CORE_TYPE_LOADING_ERROR,
            ],
        },
    }
}
//
// export function saveCoreTypeList(coreTypes){
//     const data = []
//     for (let field in coreTypes.data) {
//         let row = removeEmptyFields(coreTypes.data[field])
//         data.push(row)
//     }
//     const saveObj = {
//         data: data,
//     }
//     return {
//         [RSAA]: {
//             endpoint: `${API_ROOT}/mat-core/types`, // TODO: make base URL constant
//             method: 'POST',
//             body: JSON.stringify(saveObj),
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + localStorage.getItem('token')
//             },
//             options: {
//                 mode: 'cors',
//             },
//             types: [
//                 types.CORE_TYPES_SAVING_REQUEST,
//                 types.CORE_TYPES_SAVING_SUCCESS,
//                 types.CORE_TYPES_SAVING_ERROR,
//             ],
//         },
//     }
// }
//{saveCore
export function saveCoreType(coreType){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/type`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(coreType),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_TYPE_SAVING_REQUEST,
                types.CORE_TYPE_SAVING_SUCCESS,
                types.CORE_TYPE_SAVING_ERROR,
            ],
        },
    }
}