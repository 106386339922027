
import {
    CORE_RAW_MATERIAL_UPDATE_FIELD,
    CORE_RAW_MATERIAL_LOADING_REQUEST,
    CORE_RAW_MATERIAL_LOADING_SUCCESS,
    CORE_RAW_MATERIAL_LOADING_ERROR,
    CORE_RAW_MATERIAL_SAVING_REQUEST,
    CORE_RAW_MATERIAL_SAVING_SUCCESS,
    CORE_RAW_MATERIAL_SAVING_ERROR,
    CORE_RAW_MATERIALS_LOADING_ERROR,
    CORE_RAW_MATERIALS_LOADING_REQUEST,
    CORE_RAW_MATERIALS_LOADING_SUCCESS,
    CORE_RAW_MATERIALS_SAVING_ERROR,
    CORE_RAW_MATERIALS_SAVING_REQUEST,
    CORE_RAW_MATERIALS_SAVING_SUCCESS
} from '../constants/coreRawMaterialTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    coreRawMaterials: [],
    columns: [],
    rows: [],
    id: -1,
    rawMaterial: '',
    color: '',
}

export default function coreRawMaterialReducer(state = initialState, action) {
    switch (action.type) {
        case CORE_RAW_MATERIAL_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case CORE_RAW_MATERIALS_LOADING_REQUEST:
        case CORE_RAW_MATERIALS_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case CORE_RAW_MATERIAL_LOADING_REQUEST:
        case CORE_RAW_MATERIAL_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case CORE_RAW_MATERIAL_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                rawMaterial: action.payload.raw_material,
                color: action.payload.color,
            }
        case CORE_RAW_MATERIAL_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: -1,
                rawMaterial: '',
                color: '',
            }
        case CORE_RAW_MATERIALS_LOADING_ERROR:
        case CORE_RAW_MATERIALS_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case CORE_RAW_MATERIAL_LOADING_ERROR:
        case CORE_RAW_MATERIAL_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case CORE_RAW_MATERIALS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                coreRawMaterials: action.payload,
            }
        case CORE_RAW_MATERIALS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                coreRawMaterials: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            const columns = parseAllToJson(action.payload, 'options')
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: columns,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}