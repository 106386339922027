
import {
    FAMILY_UPDATE_FIELD,
    FAMILY_UPDATE_QUICKSHIP,
    FAMILY_LOADING_REQUEST,
    FAMILY_LOADING_SUCCESS,
    FAMILY_LOADING_ERROR,
    FAMILY_SAVING_REQUEST,
    FAMILY_SAVING_SUCCESS,
    FAMILY_SAVING_ERROR,
    FAMILIES_LOADING_ERROR,
    FAMILIES_LOADING_REQUEST,
    FAMILIES_LOADING_SUCCESS,
    FAMILIES_SAVING_ERROR,
    FAMILIES_SAVING_REQUEST,
    FAMILIES_SAVING_SUCCESS,
    FAMILY_FABRIC_ADD_LAYER,
    FAMILY_FABRIC_LAYER_ADDED,
} from '../constants/familyTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    id: -1,
    family: '',
    families: [],
    columns: [],
    rows: [],
    fabricGroupId: -1,
    fabricGroup: {},
    fabricQuiltPatternId: -1,
    fabricQuiltPatternName: '',
    fabricQuiltPattern: {},
    height: 0.00,
    embroidery: '',
    layers: [],
    quickship: false,
}

export default function familyReducer(state = initialState, action) {
    switch (action.type) {
        case FAMILY_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }

        case FAMILY_UPDATE_QUICKSHIP:
            return {
                ...state,
                isLoading: false,
                quickship: action.value,
            }

        case FAMILY_FABRIC_ADD_LAYER:
            return {
                ...state,
                layers: action.layers,//.sort(compareValues('core_layer', 'desc')),
            }
        case FAMILY_FABRIC_LAYER_ADDED:
            return {
                ...state,
                fabricType: {},
                typeId: -1,
                fabricUse: {},
                useId: -1,
                partNum: '',
                part: {},
                partId: -1,
            }
        case FAMILIES_LOADING_REQUEST:
        case FAMILIES_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case FAMILY_LOADING_REQUEST:
        case FAMILY_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case FAMILY_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                family: action.payload.family,
                fabricGroupId: action.payload.fabric_group_id,
                fabricGroup: action.payload.fabric,
                fabricQuiltPatternId: action.payload.fabric != null ? action.payload.fabric.fabric_quilt_pattern_id : null,
                fabricQuiltPattern: action.payload.fabric != null ? action.payload.fabric.pattern : {},
                fabricQuiltPatternName: action.payload.fabric != null && action.payload.fabric.pattern != null ? action.payload.fabric.pattern.fabric_quilt_pattern : '',
                height: action.payload.fabric != null ? action.payload.fabric.height : 0.00,
                embroidery: action.payload.fabric != null ? action.payload.fabric.embroidery : '',
                layers: action.payload.fabric != null ? action.payload.fabric.layers : [],
            }
        case FAMILY_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: null,
                family: '',
                fabricGroupId: -1,
                fabricGroup: {},
                fabricQuiltPatternId: -1,
                fabricQuiltPatternName: '',
                fabricQuiltPattern: {},
                height: 0.00,
                embroidery: '',
                layers: [],
            }
        case FAMILIES_LOADING_ERROR:
        case FAMILIES_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case FAMILY_LOADING_ERROR:
        case FAMILY_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case FAMILIES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                families: action.payload,
            }
        case FAMILIES_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                families: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}