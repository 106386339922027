import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import DeleteIcon from '@material-ui/icons/Refresh'
import IconButton from "@material-ui/core/IconButton";

const defaultToolbarStyles = {
    iconButton: {},
};

class ScanListFilter extends React.Component {

    render() {
        const {
            classes,
            startDate,
            endDate,
            scanStartDateChanged,
            scanEndDateChanged,
            scanRefreshClicked
        } = this.props;

        return (
            <React.Fragment>
                <Tooltip title={"Start Date"}>
                    <TextField
                        type="date"
                        label="Start Date"
                        name="scanStartDate"
                        variant="outlined"
                        value={startDate}
                        onChange={scanStartDateChanged}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Tooltip>
                <Tooltip title={"End Date"}>
                    <TextField
                        type="date"
                        label="End Date"
                        name="scanEndDate"
                        variant="outlined"
                        value={endDate}
                        onChange={scanEndDateChanged}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Tooltip>
                <Tooltip title={"Get Scans"}>
                    <IconButton className={classes.iconButton} onClick={scanRefreshClicked}>
                        <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        );
    }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(ScanListFilter);
