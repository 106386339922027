import React, { useEffect, useState } from "react";
import {
  getCommissions,
  updateCommission
} from "../actions/commissionsActions";
import { useDispatch } from "react-redux";
import MaterialTable from "@material-table/core";
import { Box, Checkbox, Container, Grid, TextField } from "@material-ui/core";
import "../styles/retailCommission.css";
import { API_ROOT } from "../utils/api";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import BasicTable from "./List/BasicTable";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const RetailCommission = () => {
  const dispatch = useDispatch();
  const [commission, setCommission] = useState(null);
  const [shopify, setShopify] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [productName, setProductName] = useState([]);
  const [isActive, setActive] = useState(null);
  const [commissionData, setCommissionData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [value, setValue] = useState("active");
  const [futureCommissions, setFutureCommissions] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    error: false,
    label: "",
    helperText: "",
    validateInput: false
  });

  const selectedTabStyle = {
    backgroundColor: "#163762",
    color: "#FFFFFF",
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  };
  const unselectedTabStyle = {
    backgroundColor: "rgba(0,0,0,0.0)"
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    function getActiveTableDate() {
      if (commissionData.length) {
        let unassignedCommissions = activeData.filter(
          (pid) => !commissionData.find((f) => f.productID == pid.productID)
        );
        setActive(unassignedCommissions);
      }
    }
    getActiveTableDate();
  }, [activeData, commissionData]);

  function getAllData() {
    dispatch(getCommissions()).then((res) => {
      setShopify(res.payload.shopify);
      setCommission(res.payload.commission);
    });
  }

  async function addNewEntry(newCommission) {
    fetch(`${API_ROOT}/commission`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      method: "POST",
      body: JSON.stringify(newCommission)
    }).then((res) => {
      if (res.status === 200) {
        let idCheck = newCommission.productID;
        let tableUpdate = tableData.filter(
          (oldData) => oldData.productID === idCheck
        );
        tableUpdate.filter((checkDate) => {
          checkDate.date_end > newCommission.date_start;
        });
        tableUpdate.map((data) => {
          let startDate = moment(newCommission.date_start)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
          let updatedData = {
            pkRID: data.pkRID,
            date_end: startDate,
            date_start: moment(data.date_start).format("YYYY-MM-DD"),
            max_discount: data.max_discount,
            deduction_amount: data.deduction_amount,
            commission: data.commission,
            productID: data.productID,
            giveaway_deduction: data.giveaway_deduction
          };
          upDateEntry(updatedData);
        });
      }

      getAllData();
    });
  }

  async function upDateEntry(newData) {
    dispatch(updateCommission(newData)).then((res) => {
      if (res.type === "COMMISSIONS_LOADING_SUCCESS") {
        getAllData();
      }
    });
  }

  async function getProductID(newData) {
    let shopifyData = shopify.filter((siteId) => {
      return siteId.siteID === 113;
    });

    shopifyData.find((productTitle) => {
      if (newData.productTitle === productTitle.productTitle) {
        newData["productID"] = productTitle.productID;

        if (newData.giveaway_deduction === true) {
          newData["giveaway_deduction"] = 1;
        } else {
          newData["giveaway_deduction"] = 0;
        }

        const newCommission = {
          date_end: moment("01-01-9999").format("YYYY-MM-DD"),
          date_start: moment(newData.date_start).format("YYYY-MM-DD"),
          max_discount: newData.max_discount,
          deduction_amount: newData.deduction_amount,
          commission: newData.commission,
          productID: newData.productID,
          giveaway_deduction: newData.giveaway_deduction
        };

        addNewEntry(newCommission);
      }
    });
  }

  useEffect(() => {
    if (commission != null) {
      const newShopifyData = [...shopify];
      const newCommissions = [...commission];
      let commissionArray = [];
      let activeArray = [];

      newCommissions.filter((commissionData) => {
        commissionArray.push(commissionData);
      });

      setCommissionData(commissionArray);
      let shopifyData = newShopifyData.filter((siteId) => {
        return siteId.siteID === 113;
      });

      shopifyData.filter((active) => {
        if (active.productStatus === "active") {
          activeArray.push(active);
          return active.productID;
        }
        setActiveData(activeArray);
      });

      let uniqueArray = [];
      shopifyData.filter((shopName) => {
        uniqueArray.push(shopName.productTitle);
      });
      setProductName(uniqueArray);

      shopifyData.filter((shop) => {
        newCommissions.filter((productTitle) => {
          if (productTitle.productID === shop.productID) {
            newCommissions.forEach((element) => {
              if (element.productID === shop.productID) {
                element["productTitle"] = shop.productTitle;
              }
            });
          }
        });
      });

      let activeCommissions = newCommissions.filter((active) => {
        let endDate = moment(active.date_end).format("YYYY-MM-DD");
        let startDate = moment(active.date_start).isAfter(Date.now());

        if (moment(active.date_start).isBefore(Date.now())) {
          return (startDate =
            (moment(active.date_start).isAfter(Date.now()) &&
              endDate === "9999-01-01") ||
            endDate >= moment(Date.now()).format("YYYY-MM-DD"));
        }
      });
      setTableData(activeCommissions);

      let futureCommission = newCommissions.filter((future) => {
        let startDate = moment(future.date_start).format("YYYY-MM-DD");
        return startDate >= moment(Date.now()).format("YYYY-MM-DD");
      });
      setFutureCommissions(futureCommission);
    }
  }, [commission, shopify]);

  const tableColumns = [
    {
      title: "Product",
      field: "productTitle",
      editable: "onAdd",
      minWidth: 250,

      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => {
        return (
          <Autocomplete
            onKeyDown={(e) => handleKey(e)}
            options={productName}
            name={"productTitle"}
            defaultValue={rowData.productTitle ? rowData.productTitle : null}
            onChange={(e, value) => {
              e.stopPropagation();
              onChange(value);
              onRowDataChange(value);
            }}
            onChange={(e, value) => {
              e.stopPropagation();
              let alikeProduct = commissionData.filter((prev) => {
                return value === prev.productTitle;
              });
              if (alikeProduct.length) {
                let data = alikeProduct[alikeProduct.length - 1];
                onRowDataChange(data);
              }

              // const newRowData = {
              //   ...rowData,
              //   productTitle: value,
              // };
              // onChange(value);
              // onRowDataChange(newRowData);
            }}
            renderInput={(params) => (
              <div
                ref={params.InputProps.ref}
                style={{ marginTop: "-10px", marginLeft: "20px" }}
              >
                <TextField
                  name={"productTitle"}
                  {...params.inputProps}
                  variant="standard"
                  margin="normal"
                  inputProps={{
                    ...params,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    }
                  }}
                />
              </div>
            )}
          />
        );
      },
      render: (rowData) => <p value={"productTitle"}>{rowData.productTitle}</p>
    },

    {
      title: "Commission",
      field: "commission",
      type: "numeric",
      editComponent: (props) => (
        <TextField
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }
          }}
          type="numeric"
          error={
            !props.value && errorMessages.validateInput
              ? errorMessages.error
              : false
          }
          helpertext={
            !props.value && errorMessages.validateInput
              ? errorMessages.helperText
              : ""
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (errorMessages.validateInput) {
              setErrorMessages({
                ...errorMessages,
                validateInput: false
              });
            }
            props.onChange(e.target.value);
          }}
        />
      )
    },
    {
      title: "Max Discount",
      field: "max_discount",
      type: "numeric",
      editComponent: (props) => (
        <TextField
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }
          }}
          type="numeric"
          error={
            !props.value && errorMessages.validateInput
              ? errorMessages.error
              : false
          }
          helpertext={
            !props.value && errorMessages.validateInput
              ? errorMessages.helperText
              : ""
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            e.stopPropagation();

            if (errorMessages.validateInput) {
              setErrorMessages({
                ...errorMessages,
                validateInput: false
              });
            }

            props.onChange(e.target.value);
          }}
        />
      )
    },
    {
      title: "Giveaway Deduction",
      field: "giveaway_deduction",
      type: "boolean",
      maxWidth: 50,

      editComponent: ({
        onChange,
        onRowDataChange,
        rowData,
        inputProps,
        ...props
      }) => {
        return (
          <Checkbox
            color="primary"
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }
            }}
            checked={props.value || false}
            error={
              !props.value && errorMessages.validateInput
                ? errorMessages.error
                : false
            }
            helpertext={
              !props.value && errorMessages.validateInput
                ? errorMessages.helperText
                : ""
            }
            onChange={(e) => {
              e.stopPropagation();
              const newRowData = {
                ...rowData,
                giveaway_deduction: e.target.checked
              };

              onRowDataChange(newRowData);
              if (newRowData.giveaway_deduction === false) {
                const newRowData = {
                  ...rowData,
                  giveaway_deduction: 0
                };

                onChange(e.target.checked);
                onRowDataChange(newRowData);
              }
              if (newRowData.giveaway_deduction === true) {
                const newRowData = {
                  ...rowData,
                  giveaway_deduction: 1
                };
                onChange(e.target.checked);
                onRowDataChange(newRowData);
              }
            }}
          />
        );
      },
      render: (rowdata) => (
        <input type="checkbox" checked={rowdata.giveaway_deduction} readOnly />
      )
    },
    {
      title: "Deduction Amount",
      field: "deduction_amount",
      type: "numeric",
      currencySetting: {
        currencyCode: "USD"
      },
      cellStyle: {
        textAlign: "left"
      },
      editComponent: ({ onRowDataChange, newRowData, rowData, ...props }) => {
        return (
          <>
            <TextField
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation();
                  }
                }
              }}
              type="numeric"
              error={
                !props.value && errorMessages.validateInput
                  ? errorMessages.error
                  : false
              }
              helperText={
                !props.value && errorMessages.validateInput
                  ? errorMessages.helperText
                  : ""
              }
              value={props.value ? props.value : ""}
              onChange={(e) => {
                e.stopPropagation();

                if (errorMessages.validateInput) {
                  setErrorMessages({
                    ...errorMessages,
                    validateInput: false
                  });
                }

                props.onChange(e.target.value);
              }}
            />
          </>
        );
      }
    },
    {
      title: "Start Date",
      field: "date_start",
      type: "date",
      editComponent: ({
        newDate,
        rowData,
        onChange,
        onRowDataChange,
        ...props
      }) => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return (
          <DatePicker
            minDate={date}
            dateFormat="MM-DD-YYYY"
            popperProps={{
              positionFixed: true,
              placement: "bottom"
            }}
            value={moment(rowData.date_start).format("MM-DD-YYYY")}
            selected={date ? date : props.selected}
            onChange={(date) => {
              const newRowData = {
                ...rowData,
                date_start: date
              };
              onChange(props.selected);
              onRowDataChange(newRowData);
            }}
          />
        );
      }
    },
    {
      title: "End Date",
      field: "date_end",
      type: "date",
      editable: "onUpdate",

      editComponent: ({
        newDate,
        rowData,
        onChange,
        onRowDataChange,
        ...props
      }) => {
        const endDateStart = new Date(rowData.date_start);
        endDateStart.setDate(endDateStart.getDate() + 1);
        return (
          <DatePicker
            minDate={endDateStart}
            dateFormat="MM-DD-YYYY"
            popperProps={{
              positionFixed: true,
              placement: "bottom"
            }}
            value={moment(rowData.date_end).format("MM-DD-YYYY")}
            selected={props.selected}
            onChange={(date) => {
              e.stopPropagation();

              const newRowData = {
                ...rowData,
                date_end: date
              };
              onChange(props.selcted);
              onRowDataChange(newRowData);
            }}
          />
        );
      }
    }
  ];

  function getTabDetails(tabName) {
    if (tabName === "future") {
      return (
        <>
          <fieldset style={{ border: "none" }}>
            {futureCommissions ? (
              <MaterialTable
                key={futureCommissions.id}
                style={{ color: "black" }}
                title=" Future Retail Commissions"
                options={{
                  pageSize: 20,
                  addRowPosition: "first",
                  actionsColumnIndex: -1
                }}
                columns={tableColumns}
                data={futureCommissions}
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        if (
                          newData.commission === undefined ||
                          newData.max_discount === undefined ||
                          newData.date_start === undefined ||
                          newData.productTitle === "Select Product"
                        ) {
                          setErrorMessages({
                            error: true,
                            label: "required",
                            helperText: "required",
                            validateInput: true
                          });
                          reject();
                          return;
                        } else
                          setFutureCommissions([...futureCommissions, newData]);
                        getProductID(newData), resolve();
                      }, 1000);
                    }),

                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...futureCommissions];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        newData["date_end"] = moment(newData.date_end).format(
                          "YYYY-MM-DD"
                        );
                        newData["date_start"] = moment(
                          newData.date_start
                        ).format("YYYY-MM-DD");
                        const data = {
                          pkRID: newData.pkRID,
                          date_end: newData.date_end,
                          date_start: newData.date_start,
                          max_discount: newData.max_discount,
                          deduction_amount: newData.deduction_amount,
                          commission: newData.commission,
                          productID: newData.productID,
                          giveaway_deduction: newData.giveaway_deduction
                        };

                        setFutureCommissions([...dataUpdate]);
                        upDateEntry(data);

                        resolve();
                      }, 1000);
                    })
                }}
              />
            ) : (
              ""
            )}
          </fieldset>
        </>
      );
    } else if (tabName === "active") {
      return (
        <>
          <fieldset style={{ border: "none" }}>
            {tableData ? (
              <MaterialTable
                className="retail-table"
                key={tableData.id}
                style={{ color: "black" }}
                title="Active Retail Commissions"
                options={{
                  pageSize: 20,
                  addRowPosition: "first",
                  actionsColumnIndex: -1
                }}
                columns={tableColumns}
                data={tableData}
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        if (
                          newData.commission === undefined ||
                          newData.max_discount === undefined
                        ) {
                          setErrorMessages({
                            error: true,
                            label: "required",
                            helperText: "required",
                            validateInput: true
                          });
                          reject();
                          return;
                        } else setTableData([...tableData, newData]);
                        getProductID(newData), resolve();
                      }, 1000);
                    }),

                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...tableData];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        newData["date_end"] = moment(newData.date_end).format(
                          "YYYY-MM-DD"
                        );
                        newData["date_start"] = moment(
                          newData.date_start
                        ).format("YYYY-MM-DD");

                        const data = {
                          pkRID: newData.pkRID,
                          date_end: newData.date_end,
                          date_start: newData.date_start,
                          max_discount: newData.max_discount,
                          deduction_amount: newData.deduction_amount,
                          commission: newData.commission,
                          productID: newData.productID,
                          giveaway_deduction: newData.giveaway_deduction
                        };

                        setTableData([...dataUpdate]);
                        upDateEntry(data);

                        resolve();
                      }, 1000);
                    })
                }}
              />
            ) : (
              ""
            )}
          </fieldset>
        </>
      );
    } else if (tabName === "without") {
      return (
        <>
          <fieldset style={{ border: "none" }}>
            {isActive != null ? (
              <div className="active_commission">
                <BasicTable active={isActive} />
              </div>
            ) : (
              ""
            )}
          </fieldset>
        </>
      );
    }
  }

  return (
    <>
      <Container maxWidth="xl">
        <Grid container justifyContent="center">
          <Box m={2} pt={3}>
            <Tabs value={value} indicatorColor="primary">
              <Tab
                value="active"
                label="Active Retail Commissions"
                onClick={() => setValue("active")}
                style={
                  value === "active" ? selectedTabStyle : unselectedTabStyle
                }
              />
              <Tab
                value="future"
                label="Future Retail Commissions"
                onClick={() => setValue("future")}
                style={
                  value === "future" ? selectedTabStyle : unselectedTabStyle
                }
              />
              <Tab
                value="without"
                label="Products missing Commissions"
                onClick={() => setValue("without")}
                style={
                  value === "without" ? selectedTabStyle : unselectedTabStyle
                }
              />
            </Tabs>
          </Box>
        </Grid>
        <div className="dataTable_row">{getTabDetails(value)}</div>
      </Container>
    </>
  );
};

export default RetailCommission;
