import * as types from '../constants/fabricUseTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.FABRIC_USE_UPDATE_FIELD,
        name,
        value,
    }
}

export function getFabricUses() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/uses`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_USES_LOADING_REQUEST,
                types.FABRIC_USES_LOADING_SUCCESS,
                types.FABRIC_USES_LOADING_ERROR,
            ],
        },
    }
}

export function getFabricUse(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/use/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_USE_LOADING_REQUEST,
                types.FABRIC_USE_LOADING_SUCCESS,
                types.FABRIC_USE_LOADING_ERROR,
            ],
        },
    }
}

export function saveFabricUseList(fabricUses){
    const data = []
    for (let field in fabricUses.data) {
        let row = removeEmptyFields(fabricUses.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/uses`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_USES_SAVING_REQUEST,
                types.FABRIC_USES_SAVING_SUCCESS,
                types.FABRIC_USES_SAVING_ERROR,
            ],
        },
    }
}

export function saveFabricUse(fabricUse){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/use`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(fabricUse),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_USE_SAVING_REQUEST,
                types.FABRIC_USE_SAVING_SUCCESS,
                types.FABRIC_USE_SAVING_ERROR,
            ],
        },
    }
}