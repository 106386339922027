import React from 'react'
import '../styles/about-page.css'
import CustomerForm from './forms/CustomerForm'

const CustomerFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Customer</h2>
            <CustomerForm location={location}/>
        </div>
    )
}

export default CustomerFormPage