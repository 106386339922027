import React from 'react';
import '../styles/about-page.css';
import SkuQueueList from './List/SkuQueueList';

const SkuQueueListPage = ({ location }) => {
    return (
        <SkuQueueList location={location}/>
    )
}

export default SkuQueueListPage;