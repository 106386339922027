
import {
    FOUNDATION_GROUP_UPDATE_FIELD,
    FOUNDATION_GROUPS_LOADING_REQUEST,
    FOUNDATION_GROUPS_LOADING_SUCCESS,
    FOUNDATION_GROUPS_LOADING_ERROR,
    FOUNDATION_GROUP_IMPORT_LOADING_REQUEST,
    FOUNDATION_GROUP_IMPORT_LOADING_SUCCESS,
    FOUNDATION_GROUP_IMPORT_LOADING_ERROR,
    FOUNDATION_GROUPS_SAVING_REQUEST,
    FOUNDATION_GROUPS_SAVING_SUCCESS,
    FOUNDATION_GROUPS_SAVING_ERROR,
    FOUNDATION_GROUP_LOADING_REQUEST,
    FOUNDATION_GROUP_SAVING_REQUEST,
    FOUNDATION_GROUP_LOADING_SUCCESS,
    FOUNDATION_GROUP_SAVING_SUCCESS,
    FOUNDATION_GROUP_LOADING_ERROR,
    FOUNDATION_GROUP_SAVING_ERROR,
    ADD_FOUNDATION,
    FOUNDATION_ADDED,
    REMOVE_FOUNDATION,
    FOUNDATION_REMOVED,
    FG_FOUNDATIONS_LOADING_SUCCESS,
    FG_FOUNDATIONS_LOADING_REQUEST, FG_FOUNDATIONS_LOADING_ERROR,
} from '../constants/foundationGroupTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    foundationGroups: [],
    foundationGroup: {},
    rows: [],
    cols: [],
    columns: [],
    file: '',
    id: -1,
    groupName: '',
    groupFoundations: [],
    foundationName: '',
    foundations: [],
}

export default function foundationGroupListReducer(state = initialState, action) {
    switch (action.type) {
        case FOUNDATION_GROUP_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case FOUNDATION_GROUP_LOADING_REQUEST:
        case FOUNDATION_GROUP_SAVING_REQUEST:
        case FOUNDATION_GROUPS_LOADING_REQUEST:
        case FOUNDATION_GROUP_IMPORT_LOADING_REQUEST:
        case FOUNDATION_GROUPS_SAVING_REQUEST:
        case FG_FOUNDATIONS_LOADING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ADD_FOUNDATION:
            return {
                ...state,
                groupFoundations: action.groupFoundations,
                foundations: action.newFoundations,
            }
        case FOUNDATION_ADDED:
            return {
                ...state,
                foundationName: '',
            }
        case REMOVE_FOUNDATION:
            return {
                ...state,
                groupFoundations: action.groupFoundations,
                foundations: action.newFoundations,
            }
        case FOUNDATION_REMOVED:
            return {
                ...state,
                foundationName: '',
            }
        case FG_FOUNDATIONS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                foundations: action.payload,
            }
        case FOUNDATION_GROUPS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                foundationGroups: action.payload,
            }
        case FOUNDATION_GROUP_IMPORT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                foundationGroups: action.foundationGroups,
                rows: action.rows,
                cols: action.cols,
            }
        case FOUNDATION_GROUP_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                groupName: action.payload.group_name,
                groupFoundations: action.payload.foundations,
                foundationName: '',
            }
        case FOUNDATION_GROUP_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: -1,
                groupName: '',
                groupFoundations: [],
                foundationName: '',
                foundations: [],
            }
        case FOUNDATION_GROUPS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                foundationGroups: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        case FOUNDATION_GROUP_LOADING_ERROR:
        case FOUNDATION_GROUP_SAVING_ERROR:
        case FOUNDATION_GROUPS_LOADING_ERROR:
        case FOUNDATION_GROUP_IMPORT_LOADING_ERROR:
        case FOUNDATION_GROUPS_SAVING_ERROR:
        case FG_FOUNDATIONS_LOADING_ERROR:
        case COLUMNS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}