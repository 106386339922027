import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Row from "./ViewCoreRow";
import { useDispatch } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import "./viewCoreList.css";
import TablePagination from '@material-ui/core/TablePagination';
import { getParentCore } from '../../../actions/parentCoreActions';
import { getCoresByParentId } from '../../../actions/viewCoreActions';
import { Box, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function ViewCoreList() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [parentCore, setParent] = useState("");
  const [coreTypes, setCoreTypes] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let history = useHistory()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getData = async () => {
    await dispatch(getParentCore(id))
      .then((res) => setParent(res.payload.parent_core) )
  }
  const getAllCoreTypesByParentId = async () => {
    await dispatch(getCoresByParentId(id))
      .then(res => setCoreTypes(res.payload))
  }
  const handleNewCore = () => {
    history.push("/core/-1", {parentCoreName: parentCore, parentCoreId: id})
  }
  useEffect(() => {
    getData()
    getAllCoreTypesByParentId()
  }, [])
  return (
    <div className='viewCoreList'>
      <Box className='parentCoreContainer'>
        <Typography variant='h4' className="parentCoreTitle">Parent Core: {parentCore}</Typography>
        <Button 
          variant="contained"
          style={{ background: "rgb(30, 66, 113)", color: "white" }}
          onClick={handleNewCore} 
          className="newCoreButton"
          >Add new core</Button>
      </Box>
      <TableContainer component={Paper} className='viewCoreListTable'>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Core Name</TableCell>
              <TableCell align="right">Core Size</TableCell>
              <TableCell style={{width: "275px"}} />
            </TableRow>
          </TableHead>
          <TableBody>
            {coreTypes?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((core) => (
              <Row key={core.id} row={core} />
            ))}
          </TableBody>
        </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, { label: 'All', value: coreTypes.length}]}
        component="div"
        count={coreTypes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
    </div>
  );
} 