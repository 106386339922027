import * as types from "../constants/dealerTypes";
import { RSAA } from "redux-api-middleware";
import { removeEmptyFields } from "../utils/object";
import { API_ROOT } from "../utils/api";

export function updateField(name, value) {
  return {
    type: types.DEALER_UPDATE_FIELD,
    name,
    value
  };
}

export function getDealers() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/dealers`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.DEALERS_LOADING_REQUEST,
        types.DEALERS_LOADING_SUCCESS,
        types.DEALERS_LOADING_ERROR
      ]
    }
  };
}

export function getDealer(account_id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/dealer/:${account_id}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.DEALER_LOADING_REQUEST,
        types.DEALER_LOADING_SUCCESS,
        types.DEALER_LOADING_ERROR
      ]
    }
  };
}

export function saveDealerList(dealers) {
  const data = [];
  for (let field in dealers.data) {
    let row = removeEmptyFields(dealers.data[field]);
    data.push(row);
  }
  const saveObj = {
    data: data
  };
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/dealers`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(saveObj),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.DEALERS_SAVING_REQUEST,
        types.DEALERS_SAVING_SUCCESS,
        types.DEALERS_SAVING_ERROR
      ]
    }
  };
}

export function saveDealer(dealer) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/dealer`, // TODO: make base URL constant
      method: "PUT",
      body: JSON.stringify(dealer),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.DEALER_SAVING_REQUEST,
        types.DEALER_SAVING_SUCCESS,
        types.DEALER_SAVING_ERROR
      ]
    }
  };
}
export function createNewDealer(dealer) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/dealer`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(dealer),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.DEALER_SAVING_REQUEST,
        types.DEALER_SAVING_SUCCESS,
        types.DEALER_SAVING_ERROR
      ]
    }
  };
}
