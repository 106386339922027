import React from 'react'
import '../styles/about-page.css'
import BrandForm from './forms/BrandForm'

const BrandFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Brand</h2>
            <BrandForm location={location}/>
        </div>
    )
}

export default BrandFormPage