import React, { useEffect, useState } from "react";
import T from "prop-types";
import { connect } from "react-redux";
import "../../styles/about-page.css";
import {
  getSchedule,
  saveScheduleList,
  deleteSchedule,
  updateField,
  getScheduleSkus,
  skuInfoCheck
} from "../../actions/scheduleActions";
import { getColumns } from "../../actions/globalActions";
import { ExcelRenderer } from "react-excel-renderer";
import MUIDataTable from "mui-datatables";
import ScheduleListFilter from "./ScheduleListFilter";
import { getDBFormattedDate } from "../../utils/dates";
import { setValueByType } from "../../utils/object";
import Loader from "../global/Loader";
import ShippingAddModal from "../ShippingAddModal";
import { useDispatch } from "react-redux";
import moment from "moment";

const defaultColumnProperties = {
  sortable: true,
  width: 250
};

class ScheduleList extends React.Component {
  getOptions(data, date) {
    data = data.concat(this.state.schedule);
    const options = {
      filterType: "multiselect",
      responsive: "standard",
      fixedHeader: true,
      rowsPerPage: 100,
      // selectableRowsHeader: false,
      // selectableRows: 'multiple',
      customToolbar: () => {
        return (
          <ScheduleListFilter
            date={date}
            saveClick={this.handleScheduleSaveClick}
            dateChanged={this.handleScheduleDateChanged}
            deleteClick={this.handleScheduleDeleteClick}
            saveDisabled={this.state.errorMessages.length >= 1 ? true : false}
          />
        );
      },
      isRowSelectable: (dataIndex, selectedRows) => {
        return data[dataIndex].printed == null;
      },
      onRowsDelete: (data) => {
        const { dispatch, schedule, date } = this.props;

        let deletes = [];

        data.data.forEach(function (row) {
          deletes.push(schedule[row.dataIndex].id);
        });
        dispatch(deleteSchedule(deletes, date));
      }
    };

    return options;
  }

  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      rows: [],
      cols: [],
      columns: [],
      filename: "",
      errorMessages: new Set(),
      unknownSkuSet: new Set(),
      file: "",
      inputMessage: "Choose a file",
      selectableRowsHideCheckboxes: false,
      message: "beforestatechange"
    };
  }

  getColumns = (e) => {
    const { dispatch } = this.props;
    dispatch(getColumns("schedule"));
  };

  //Toolbar functions
  handleScheduleSaveClick = () => {
    const { dispatch, schedule, date } = this.props;
    const rawSchedule = schedule.concat(this.state.schedule);
    const cleanedSchedule = this.removeColumns(rawSchedule);

    const data = {
      data: cleanedSchedule,
      date: date,
      filename: this.state.filename
    };
    //right here
    dispatch(saveScheduleList(data));
    this.setState({
      cols: [],
      rows: [],
      schedule: [],
      filename: "",
      date: date,
      inputMessage: "Choose a file"
    });
  };

  removeColumns = (data) => {
    const { columns } = this.props;

    const schedule = data.map((s) => {
      return {
        id: s.id,
        brand: s.brand,
        build_group: s.build_group,
        coil: s.coil,
        core: s.core,
        date: s.date,
        deleted_at: s.deleted_at,
        deleted_by: s.deleted_by,
        embroidery: s.embroidery,
        family: s.family,
        inspection_required: s.inspection_required,
        location: s.location,
        notes: s.notes,
        order_number: s.order_number,
        priority: s.priority,
        put_away_location: s.put_away_location,
        qty: s.qty,
        sku: s.sku,
        original_id: s.original_id,
        alt_process_id: s.alt_process_id,
        user_id: s.user_id,
        flat_pack: s.flat_pack,
        truck_number: s.truck_number,
        stop_number: s.stop_number,
        customer_serial: s.customer_serial,
        store: s.store
        // pickup_date: s.pickup_date,
        // pickup_time: s.pickup_time
      };
    });

    return schedule;
  };

  handleDefaultDate() {
    const { dispatch, date } = this.props;
    const curr = new Date();
    curr.setDate(curr.getDate());
    const defaultDate = curr.toISOString().substr(0, 10);
    dispatch(updateField("date", defaultDate));
    dispatch(getSchedule(defaultDate));
  }
  handleScheduleDateChanged = (e) => {
    const { dispatch } = this.props;
    const newDate = e.target.value;
    dispatch(updateField(e.target.name, e.target.value));
    dispatch(getSchedule(newDate));

    let schedule = this.state.schedule;
    for (let d = 0; d < schedule.length; d++) {
      schedule[d].date = newDate;
    }

    this.setState({
      schedule: schedule
    });
  };

  handleScheduleDeleteClick = () => {
    const { date } = this.props;

    this.setState({
      cols: [],
      rows: [],
      schedule: [],
      date: date
    });
  };
  /*END TOOLBAR FUNCTIONS*/

  componentDidMount() {
    this.getColumns();

    const { dispatch, date } = this.props;
    this.handleDefaultDate();
    dispatch(getScheduleSkus());
  }

  handleUploadErrors = () => {
    const { dispatch } = this.props;

    dispatch(skuInfoCheck(Array.from(this.state.unknownSkuSet))).then(
      (resp) => {
        if (resp.payload.length > 0) {
          this.setState(
            {
              errorMessages: new Set([
                ...this.state.errorMessages,
                ...resp.payload
              ])
            },
            this.convertErrorsToArray
          );
        } else {
          this.setState(
            {
              errorMessages: new Set([
                ...this.state.errorMessages,
                "An unknown error occurred"
              ])
            },
            this.convertErrorsToArray
          );
        }
      }
    );
  };

  convertErrorsToArray = () => {
    this.setState({ errorMessages: Array.from(this.state.errorMessages) });
  };

  fileHandler = (event) => {
    const { columns, date } = this.props;

    this.setState({ errorMessages: new Set() }); //clear any error message from previous upload
    this.setState({ unknownSkuSet: new Set() }); //clear any error message from previous upload

    let fileObj = event.target.files[0];

    if (event.target.files.length > 1) {
      var inputMessage = event.target.files.length + " files selected";
      this.setState({ inputMessage: inputMessage });
    } else if (
      event.target.files &&
      event.target.files.length === 1 &&
      event.target.files[0].name
    ) {
      this.setState({ inputMessage: event.target.files[0].name });
    } else {
      this.setState({ inputMessage: "Choose a file" });
    }

    //  dispatch(updateField(event.target.name, fileObj))

    //just pass the fileObj as parameter
    // dispatch(importExcel(fileObj))
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
      } else {
        const headerRow = resp.rows[0];
        let newSchedule = [];
        if (resp.rows.length > 0) {
          for (let i = 1; i < resp.rows.length; i++) {
            let row = {};

            for (let c = 0; c < columns.length; c++) {
              const label = columns[c].label;
              const key = columns[c].key;
              const dataType = columns[c].data_type;
              const post = columns[c].post_data === 1;

              const idx = Object.keys(headerRow).find(
                (key) => headerRow[key] === label
              );

              if (idx !== undefined && post) {
                if (resp.rows[i][idx] !== undefined) {
                  row[key] = setValueByType(
                    resp.rows[i][idx] == null
                      ? ""
                      : resp.rows[i][idx]
                          .toString()
                          .replace(/"/gi, " inch")
                          .replace(/'/, "")
                          .trim(),
                    dataType
                  );
                } else {
                  row[key] = setValueByType("", dataType);
                }
              }
            }
            row.id = null;
            row.user_id = parseInt(localStorage.getItem("userId"));
            row.date = date;
            // if (row.pickup_date.length != 0) {
            //   let converted_date = new Date(
            //     Math.round((row.pickup_date - 25569) * 864e5) + 864e5
            //   );
            //   converted_date = String(converted_date).slice(4, 15);
            //   row.pickup_date = converted_date.split(" ");
            //   let day = row.pickup_date[1];
            //   let month = row.pickup_date[0];
            //   month =
            //     "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1;
            //   if (month.toString().length <= 1) month = "0" + month;
            //   let year = row.pickup_date[2];
            //   row.pickup_date = month + "/" + day + "/" + year;
            //   row.pickup_date = moment(row.pickup_date).format("YYYY/MM/DD");
            // }
            newSchedule.push(row);

            //Error handling for truck shipping not on schedule
            // if (
            //   row.flat_pack == 1 &&
            //   row.truck_number != null &&
            //   row.stop_number != null &&
            //   row.store != null
            // ) {
            //   console.log("row", row);
            //   //  this.setState({
            //   //    errorMessages: new Set(this.state.errorMessages).add(
            //   //      `No truck or stop Information for ${row.sku}`
            //   //    )
            //   //  });
            // }

            //is there a way to create a Set of the skus
            //send the set to DB to check if they exist and that they are either inactive or don't exist
            const formattedSku = row.sku ? row.sku.toLowerCase() : row.sku;
            if (row.qty !== 1) {
              this.setState({
                errorMessages: new Set(this.state.errorMessages).add(
                  `Quantity is NOT 1 for ${row.sku}`
                )
              });
            } else if (this.props.skuObj[formattedSku] === undefined) {
              this.setState({
                unknownSkuSet: new Set(this.state.unknownSkuSet).add(row.sku)
              });
            } else if (this.props.skuObj[formattedSku] !== "active") {
              this.setState({
                unknownSkuSet: new Set(this.state.unknownSkuSet).add(row.sku)
              });
            }

            //at the end of the excel file check to see if there were any errors
            if (i === resp.rows.length - 1) {
              if (
                this.state.unknownSkuSet.size >= 1 ||
                this.state.errorMessages.size >= 1
              ) {
                newSchedule = [];
                this.handleUploadErrors();
              }
            }
          }
        }
        this.setState({
          cols: resp.cols,
          rows: resp.rows,
          schedule: newSchedule,
          date: this.props.date,
          file: "",
          filename: fileObj.name
        });
      }
    });
  };

  render() {
    const { isLoading, schedule, date, columns } = this.props;

    return (
      <div className="base-container schedule-list">
        <input
          type="file"
          name="file"
          id="file"
          className="inputfile"
          onChange={this.fileHandler.bind(this)}
          style={{ padding: "10px" }}
        />
        <label htmlFor="file">{this.state.inputMessage}</label>
        <div>
          <ShippingAddModal handleSave={this.handleScheduleSaveClick} />
        </div>
        {this.state.errorMessages.length >= 1 ? (
          <div>
            <h1 style={{ color: "red" }}>UPLOAD FAILED:</h1>
            {this.state.errorMessages.map((error) => (
              <h2 key={error} style={{ color: "red" }}>
                {error}
              </h2>
            ))}
          </div>
        ) : (
          ""
        )}
        <MUIDataTable
          title={"Production Schedule"}
          data={schedule.concat(this.state.schedule)}
          columns={columns.map((c) => ({ ...c, ...defaultColumnProperties }))}
          options={this.getOptions(schedule, date)}
        />
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

ScheduleList.defaultProps = {
  date: getDBFormattedDate()
};

ScheduleList.propTypes = {
  isLoading: T.bool,
  isSaved: T.bool,
  schedule: T.array,
  rows: T.array,
  cols: T.array,
  date: T.string,
  columns: T.array,
  file: T.string,
  infoSkus: T.array
};

function mapStateToProps(state) {
  const { scheduleList } = state;
  return {
    isLoading: scheduleList.isLoading,
    schedule: scheduleList.schedule,
    rows: scheduleList.rows,
    cols: scheduleList.cols,
    date: scheduleList.date,
    columns: scheduleList.columns,
    file: scheduleList.file,
    skuObj: scheduleList.skuObj,
    infoSkus: scheduleList.infoSkus
  };
}

export default connect(mapStateToProps)(ScheduleList);
