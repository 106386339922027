import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";

const BasicTable = ({ active }) => {
  const [isActive, setIsActive] = useState([...active]);
  const [commission, setCommission] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [labor, setLabor] = useState(false);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    isActive.filter((act) => {
      if (act.unitCost) {
        setShipping(true);
        setTitle("Skus Missing Shipping Cost");
      } else if (act.sku) {
        setLabor(true);
        setTitle("Skus Missing Labor Cost");
      } else if (act.pkProductID === null || act.pkProductID != undefined) {
        setCommission(true);
        setTitle("Retail Products Without Assigned Commissions");
      }
    });
  }, [active]);
  const columns =
    commission === true
      ? [
          {
            title: "Products Name",
            field: "productTitle"
          },
          {
            title: "Products Vendor",
            field: "productVendor"
          }
        ]
      : shipping === true
      ? [
          {
            title: "Part Number",
            field: "num"
          }
        ]
      : labor === true
      ? [
          {
            title: "Sku",
            field: "sku"
          }
        ]
      : [
          {
            title: "No Data Avaliable",
            field: "No Data Avaliable"
          }
        ];

  return (
    <>
      {isActive.length && columns.length ? (
        <fieldset style={{ border: "none" }}>
          <MaterialTable
            key={isActive.id}
            title={title}
            data={isActive}
            columns={columns}
            options={{
              pageSize: 10,
              addRowPosition: "first",
              actionsColumnIndex: -1
            }}
          />
        </fieldset>
      ) : (
        <MaterialTable title={title} />
      )}
    </>
  );
};

export default BasicTable;
