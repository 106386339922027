import React from 'react'

import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import '../../styles/about-page.css'
import {getCategories, getBrands, getFamilies, getSkus, getPDF, getZPL, categorySelected, brandSelected, familySelected, skuSelected, skuRemove, resetForm} from "../../actions/lawTagViewerActions"
import Loader from '../global/Loader'

import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import {Select} from "@material-ui/core";
import Input from "@material-ui/core/Input/Input";

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// import jspm from 'jsprintmanager'

import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import SyncIcon from '@material-ui/icons/Sync'
import PrintIcon from '@material-ui/icons/Print'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Tooltip from "@material-ui/core/Tooltip";

class LawTagViewer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            active: true
        }
    }

    componentDidMount() {

        const skus = [
            'ABRIPR1167',
            'ABRIPR1166',
            'ABRIPR1150',
            'ABRIPR1146',
            'ABRIPR1133XL',
            'ABRIPR1133']

        this.getCategories()
        this.getBrands()
        this.getFamilies()
        this.getSkus()
    }

    //getting all the things
    getCategories(){
        const {
            dispatch,
        } = this.props
        dispatch(getCategories())
    }
    getBrands(){
        const {
            dispatch,
        } = this.props

        dispatch(getBrands())
    }
    getFamilies(){
        const {
            dispatch,
        } = this.props

        dispatch(getFamilies())
    }
    getSkus(){
        const {
            dispatch,
            selectedCategory
        } = this.props

        const active = this.state.active
        dispatch(getSkus(active, selectedCategory.id))
    }

    //selections have been made
    handleCategorySelectedChange = async e => {

        const {
            dispatch,
            categories,
            selectedCategory,
            skus,
            selectedSkus,
        } = this.props

        const value = e.target.value
        const active = this.state.active
        const newCategory = await dispatch(categorySelected(value, categories))
        dispatch(getSkus(active, newCategory.selectedCategory.id))
    }
    handleBrandSelectedChange = async e => {

        const {
            dispatch,
            selectedCategory,
            brands,
            families,
            skus,
            selectedSkus,
            filteredSkus,
        } = this.props

        const value = e.target.innerHTML
        dispatch(brandSelected(value, brands, families, skus, selectedCategory))

    }
    handleFamilySelectedChange = async e => {

        const {
            dispatch,
            selectedCategoryId,
            families,
            skus,
            selectedSkus,
        } = this.props

        const value = e.target.innerHTML
        dispatch(familySelected(value, families, skus, selectedCategoryId))

    }
    handleSkuSelectedChange = async e => {

        const {
            dispatch,
            skus,
            selectedSkus,
        } = this.props

        const value = e.target.innerHTML
        dispatch(skuSelected(value, skus, selectedSkus))
    }
    handleSkuRemove = async e => {

        const {
            dispatch,
            skus,
            selectedSkus,
        } = this.props

        const value = e.target.innerHTML
        dispatch(skuRemove(value, skus, selectedSkus))
    }
    handleResetForm = async e => {

        const{
            categories,
            brands,
            families,
            dispatch,
        } = this.props

        this.setState({active: true})
        await dispatch(getSkus(true, 1))

        const{
            skus
        } = this.props
        dispatch(resetForm(categories, brands, families, skus))
        // window.location.reload(false);

    }
    handleActiveToggle = async e => {

        this.setState({active: e.target.checked})
        const {
            dispatch,
            selectedCategoryId,
        } = this.props

        const active = !this.state.active
        dispatch(getSkus(active, selectedCategoryId))

    }

    //get the meat and potatoes
    handleGetPDF = async e => {
        e.preventDefault()

        const {
            dispatch,
            selectedCategory,
            selectedSkus
        } = this.props
        await dispatch(getPDF(selectedSkus, selectedCategory.id))
      //  await this.handleLoadPDF()
    }

    handleGetZPL = async e => {
        e.preventDefault()

        const {
            dispatch,
            selectedCategoryId,
            selectedSkus,
            zpl,
        } = this.props
        dispatch(getZPL(selectedSkus, selectedCategoryId))
    }

    handlePrintLabels = async e => {
        e.preventDefault()

        const {
            dispatch,
            selectedCategoryId,
            selectedSkus,
            zpl,
        } = this.props
        await dispatch(getZPL(selectedSkus, selectedCategoryId))

        const printWindow = window.open();
        printWindow.document.open('text/plain')
        printWindow.document.write(zpl);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();

    }

    render() {

        const {
            isLoading,
            s3url,
            zpl,
            categories,
            selectedCategoryId,
            selectedCategory,
            brands,
            families,
            skus,
            selectedSku,
            filteredSkus,
            selectedSkus,
            selectedBrand,
            selectedFamily,
        } = this.props

        return (

            <div className="baseContainer">
                <FormControlLabel style={{marginTop: 24, marginLeft: 75, paddingRight: 20}}
                                  className={'sku-category-switch'}
                                  label="Active"
                                  control={
                                      <Switch
                                          checked={this.state.active}
                                          onChange={this.handleActiveToggle}
                                      />
                                  }
                />
                <FormControl style={{width: '200px', marginTop: 24}}>
                    <Select
                        id="autocomplete-category"
                        name={"category"}
                        className={'sku-select'}
                        placeholder={"Category"}
                        value={selectedCategory.category}
                        key={selectedCategory.id}
                        input={<Input name="categoryName" id="category-placeholder"/>}
                        options={categories}
                        onChange={this.handleCategorySelectedChange}
                        style={{width: 200, marginTop: 1}}
                    >
                        {categories.map((option) => <MenuItem key={option.id}
                                                              value={option.category}>{option.category}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl style={{width: '200px', marginTop: 0}}>
                    <Autocomplete
                        id="autocomplete-sku"
                        options={brands}
                        disabled={((filteredSkus === undefined || filteredSkus === [] || filteredSkus.length === 0) && selectedBrand.id === undefined) || selectedFamily.id !== undefined || selectedCategory.id > 1}
                        getOptionLabel={(options) => options.brand}
                        onChange={this.handleBrandSelectedChange}
                        style={{width: 300}}
                        renderInput={(params) => <TextField {...params}
                                                            label="Select A Brand"
                                                            className={'sku-auto-complete'}/>}
                    />
                </FormControl>
                <FormControl style={{width: '200px', marginTop: 0}}>
                    <Autocomplete
                        id="autocomplete-sku"
                        options={families}
                        disabled={((filteredSkus === undefined || filteredSkus === [] || filteredSkus.length === 0) && selectedFamily.id === undefined) || selectedBrand.id !== undefined || selectedCategory.id > 1}
                        getOptionLabel={(options) => options.family}
                        onChange={this.handleFamilySelectedChange}
                        style={{width: 300}}
                        renderInput={(params) => <TextField {...params}
                                                            label="Select A Family"
                                                            className={'sku-auto-complete'}/>}
                    />
                </FormControl>
                <FormControl style={{width: '200px', marginTop: 0, paddingRight: 20}}>
                    <Autocomplete
                        id="autocomplete-sku"
                        options={filteredSkus}
                        disabled={filteredSkus === undefined || filteredSkus === [] || filteredSkus.length === 0}
                        getOptionLabel={(options) => options.sku}
                        onChange={this.handleSkuSelectedChange}
                        style={{width: 300}}
                        renderInput={(params) => <TextField {...params}
                                                            label="Select A Sku"
                                                            className={'sku-auto-complete'}
                        />}
                    />
                </FormControl>


                <FormControl style={{width: '200px', marginTop: 20, paddingRight: 15}}>
                    <List dense component="div" role="list">
                        {selectedSkus.map((value) => {
                            const labelId = `transfer-list-item-${value.sku}-label`;
                            return (
                                <ListItem key={value} role="listitem" button onClick={this.handleSkuRemove}>
                                    <ListItemIcon>{DeleteForeverIcon}</ListItemIcon>
                                    <ListItemText id={labelId} primary={value.sku}/>
                                </ListItem>
                            );
                        })}
                        <ListItem/>
                    </List>
                </FormControl>

                <Tooltip title="Preview PDF">
                        <span>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="print preview"
                            style={{marginTop: 20, marginRight: 35}}
                            disabled={selectedSkus.length === 0}
                            onClick={this.handleGetPDF}
                        >
                            <VisibilityIcon/>
                        </IconButton>
                            </span>
                </Tooltip>
                <Tooltip title="Send to Printer">
                         <span>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="print zpl code"
                            style={{marginTop: 20, marginRight: 35}}
                            disabled={selectedSkus.length === 0}
                            onClick={this.handlePrintLabels}
                        >
                            <PrintIcon/>
                        </IconButton>
                              </span>
                </Tooltip>

                <Tooltip title="Reset Form">
                        <span>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="reset form"
                            style={{marginTop: 20}}
                            onClick={this.handleResetForm}
                        >
                            <SyncIcon/>
                        </IconButton>
                              </span>
                </Tooltip>

                {s3url !== '' &&
                <iframe
                    id={"pdf-viewer"}
                    src={s3url}
                    height={'800pt'}
                    width={'100%'}
                />
                }

                <Loader isLoading={isLoading}/>
            </div>

        )
    }
}

LawTagViewer.defaultProps = {
    selectedCategoryId: 1
}

LawTagViewer.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    s3url: T.string,
    zpl: T.string,
    categories: T.array,
    selectedCategoryId: T.number,
    selectedCategory: T.object,
    brands: T.array,
    families: T.array,
    skus: T.array,
    selectedSku: T.string,
    filteredSkus: T.array,
    selectedSkus: T.array,
    selectedBrand: T.object,
    selectedFamily: T.object,
}

function mapStateToProps(state) {
    const { lawTagViewerReducer } = state
    return {
        isLoading: lawTagViewerReducer.isLoading,
        s3url: lawTagViewerReducer.s3url,
        zpl: lawTagViewerReducer.zpl,
        categories: lawTagViewerReducer.categories,
        selectedCategoryId: lawTagViewerReducer.selectedCategoryId,
        selectedCategory: lawTagViewerReducer.selectedCategory,
        brands: lawTagViewerReducer.brands,
        families: lawTagViewerReducer.families,
        skus: lawTagViewerReducer.skus,
        selectedSku: lawTagViewerReducer.selectedSku,
        filteredSkus: lawTagViewerReducer.filteredSkus,
        selectedSkus: lawTagViewerReducer.selectedSkus,
        selectedBrand: lawTagViewerReducer.selectedBrand,
        selectedFamily: lawTagViewerReducer.selectedFamily,
    }
}

export default withRouter(connect(mapStateToProps)(LawTagViewer))