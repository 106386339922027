import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

import {
    getMaterialGroup,
    saveMaterialGroup,
    updateField,
    addMaterial,
    materialAdded,
    removeMaterial, materialRemoved,
    getMaterials, updateBlend
} from '../../actions/materialGroupActions'
import Table from "@material-ui/core/Table";
import {TableCell, TableHead, Tooltip} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableBody from '@material-ui/core/TableBody';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

import '../../styles/material.css'
import AddNewButton from "../global/AddNewButton";
import Can from "../Can";

class MaterialGroupForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        this.getMaterialList()
        this.getMaterialGroup()
    }
    getMaterialList() {
        const{
            dispatch,
        } = this.props

        const id = this.props.match.params.id ?  this.props.match.params.id : -1
        dispatch(getMaterials(id))
    }
    getMaterialGroup = e => {
        const {
            dispatch,
            isLoading,
        } = this.props

        const id = this.props.match.params.id
        if (id) {
            dispatch(getMaterialGroup(id))
        }
    }

    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSuccess) {
            this.setState({ open: true })
        }
    }

    handleAddMaterial = async e => {
        e.preventDefault()
        const {
            dispatch,
            materialName,
            groupMaterials,
            materials,
        } = this.props

        const material = materials.find(item => item.name === materialName)
        await dispatch(addMaterial(material, groupMaterials, materials))
        await dispatch(materialAdded())
    }

    handleRemoveMaterial(id){
        const {
            dispatch,
            materialName,
            groupMaterials,
            materials,
        } = this.props

        const material = groupMaterials.find(item => item.id === id)
        dispatch(removeMaterial(material, groupMaterials, materials))
    }

    handleBlendChange = e => {

        const id = e.target.name.replace('mt_', '')
        const {
            dispatch,
            materialName,
            groupMaterials,
        } = this.props

        if (materialName === '') {
            dispatch(updateBlend(id, e.target.value, groupMaterials))
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            id,
            groupName,
            groupMaterials,
        } = this.props

        const materialGroup = {
            id: id,
            group_name: groupName,
            materials: groupMaterials,
        }
        if(!id || id == -1){
            delete materialGroup.id
        }
        await dispatch(saveMaterialGroup(materialGroup))
        if(!isError) {
            this.props.history.push("/lt/material/groups")
        }
    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            id,
            groupName,
            groupMaterials,
            materials,
            materialName,
        } = this.props

        return (
            <div className="baseContainer projectForm">
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    className="success"
                    onClose={this.handleClose}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">MaterialGroup saved</span>}
                />
                <h1>
                    {groupName}
                </h1>
                <div>
                    <TextField
                        label="Group #"
                        name="id"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={id}
                        disabled={true}
                        onChange={this.handleChange}
                    />
                    <TextField
                        label="Group Name"
                        name="groupName"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={groupName}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <FormControl className={'sku-form-select'}>
                        <InputLabel >Material</InputLabel>
                        <Select
                            className={'sku-select'}
                            name={"materialName"}
                            placeholder={"Material"}
                            value={materialName}
                            key={materialName}
                            input={<Input name="materialName" id="materialName-placeholder"/>}
                            onChange={this.handleChange}
                        >
                            {materials.map((option) => <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {materialName != '' &&
                    <FormControl className={'material-form-control-add-row'}>
                        <Tooltip title={"Add New Material"}>
                            <IconButton>
                                <FontAwesomeIcon icon={faPlus} style={{fontSize: '.75em'}}
                                                 onClick={this.handleAddMaterial}/>
                            </IconButton>
                        </Tooltip>
                    </FormControl>
                    }
                </div>
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Material</TableCell>
                                <TableCell>Blend</TableCell>
                                <TableCell>Active</TableCell>
                            </TableRow>
                        </TableHead>
                        {groupMaterials.map(m => {
                            return (
                                <TableBody key={m.id}>
                                    <TableRow>
                                        <TableCell>{m.name}</TableCell>
                                        <TableCell>
                                            <TextField
                                                name={"mt_" + m.id}
                                                value={m.blend}
                                                className="content-editable"
                                                type={"number"}
                                                onChange={this.handleBlendChange}
                                            />
                                        </TableCell>
                                        <TableCell>{m.active}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => this.handleRemoveMaterial(m.id)} >
                                                <FontAwesomeIcon icon={faTimes} style={{fontSize: '.75em'}} id={m.id} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )
                        })}
                    </Table>
                </div>
                <Can
                    role={this.props.role}
                    perform='sku:edit'
                    yes={() => (
                        <div style={{marginTop: 20}}>
                            <Button
                                variant="contained"
                                style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                                onClick={this.handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    )}
                    no={() => null}
                />
            </div>
        )
    }
}

MaterialGroupForm.defaultProps = {

}

MaterialGroupForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    groupName: T.string,
    groupMaterials: T.array,
    materials: T.array,
    materialName: T.string,
}

function mapStateToProps(state) {
    const { materialGroupReducer, loginForm } = state
    return {
        isError: materialGroupReducer.isError,
        saveSuccess: materialGroupReducer.saveSuccess,
        id: materialGroupReducer.id,
        groupName: materialGroupReducer.groupName,
        groupMaterials: materialGroupReducer.groupMaterials,
        materials: materialGroupReducer.materials,
        materialName: materialGroupReducer.materialName,
        role: loginForm.userRole
    }
}

export default withRouter(connect(mapStateToProps)(MaterialGroupForm))