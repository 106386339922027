import Loader from "../global/Loader";
import React from "react";
import { connect } from "react-redux";

import {
  getEmbedUrl,
  updateField,
  getStatusList,
  setProductionTrackerIsLoading,
  getProductionTracker,
  getAWSingestion
} from "../../actions/reportActions";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";

import T from "prop-types";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Box, Grid, Snackbar, SnackbarContent } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import ForecastModal from "../Forcasting/forecastModal";
import { report } from "process";

class ProductionTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasRun: false,
      seconds: 0,
      minutes: 0,
      open: false
    };
  }

  tick() {
    const { date, status } = this.props;

    const time = new Date();
    let minLeft = "15 Minutes";

    //Adjust the date on the page by weekday business day if we hit midnight
    if (
      time.getHours() === 0 &&
      time.getMinutes() === 0 &&
      time.getSeconds() === 0
    ) {
      this.handleDateRoll();
    }

    const min = time.getMinutes();
    const sec = time.getSeconds();
    const hour = time.getHours();

    let msg = "";

    if (min > 0 && min < 15) {
      minLeft = 15 - min + " Minutes";
    } else if (min > 15 && min < 30) {
      minLeft = 30 - min + " Minutes";
    } else if (min > 30 && min < 45) {
      minLeft = 45 - min + " Minutes";
    } else if (min > 45 && min < 60) {
      minLeft = 60 - min + " Minutes";
    }

    let seconds = 0;
    let minutes = 0;

    if (this.state.seconds === 60) {
      seconds = 0;
      minutes = this.state.minutes + 1;
    } else {
      seconds = this.state.seconds + 1;
    }

    if (hour >= 4 && hour < 22) {
      if ((min === 59 || min === 14 || min === 29 || min === 44) && sec === 0) {
        this.getUrl();
      } else if (
        (min === 0 || min === 15 || min === 30 || min === 45) &&
        sec === 0
      ) {
        this.getDashboard(date, status);
      }
    } else {
      msg = "This report will update again at 4:00 AM";
      if (hour < 4) {
        const hoursLeft = Math.floor(((4 - hour) * 60 - min) / 60);
        if (hoursLeft > 0) {
          minLeft = hoursLeft + " Hours & " + (60 - min) + " Minutes";
        } else {
          minLeft = 60 - min + " Minutes";
        }
      } else {
        const hoursLeft = Math.floor(((24 - hour + 4) * 60 - min) / 60);
        if (hoursLeft > 0) {
          minLeft = hoursLeft + " Hours & " + (60 - min) + " Minutes";
        } else {
          minLeft = 60 - min + " Minutes";
        }
      }
    }

    this.setState((state) => ({
      hasRun: true,
      seconds: seconds,
      minutes: minutes,
      time: time.toLocaleString(),
      minLeft: minLeft,
      msg: msg
    }));
  }

  getUrl() {
    const { dispatch } = this.props;

    dispatch(getEmbedUrl());
  }
  getIngestion() {
    const { dispatch, isLoading } = this.props;
    dispatch(getAWSingestion()).then((res) => {
      if (res.type === "INGESTION_STATUS_LOADING_REQUEST") {
        this.setState({ open: true });
      }
    });
  }
  getDashboard(date, status) {
    const { dispatch, embedUrl } = this.props;
    if (embedUrl != "") {
      const div = document.getElementById("embed-dashboard-container");
      div.innerHTML = "";
      dispatch(getProductionTracker(embedUrl, date, status));
    }
  }

  componentDidMount() {
    this.getUrl();
    this.handleDefaultDate();
    this.getStatusList();
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleDefaultDate() {
    const { dispatch } = this.props;
    const curr = new Date();
    curr.setDate(curr.getDate());
    const defaultDate = curr.toISOString().substr(0, 10);
    dispatch(updateField("date", defaultDate));
  }

  handleDateRoll() {
    const { dispatch, status, date } = this.props;

    const selected = new Date(date);
    selected.setDate(selected.getDate() + 1);
    //Set to Monday if Sunday
    if (selected.getDay() === 0) {
      selected.setDate(selected.getDate() + 1);
    }
    //Set to Monday if Saturday
    if (selected.getDay() === 6) {
      selected.setDate(selected.getDate() + 2);
    }
    const selectedDate = selected.toISOString().substr(0, 10);
    dispatch(updateField("date", selectedDate)).then(
      this.getDashboard(selectedDate, status)
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.setState({ open: true });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  state = {
    open: false,
    vertical: "top",
    horizontal: "center"
  };

  handleDateChanged = (e) => {
    const { dispatch, status } = this.props;

    const newDate = e.target.value;
    dispatch(updateField(e.target.name, e.target.value));
    dispatch(getEmbedUrl()).then(this.getDashboard(newDate, status));
  };

  getStatusList() {
    const { dispatch } = this.props;

    dispatch(getStatusList());
  }

  handleStatusChange = (e) => {
    const { dispatch, embedUrl, date, isLoading } = this.props;
    dispatch(updateField(e.target.name, e.target.value));
    dispatch(getEmbedUrl()).then(this.getDashboard(date, e.target.value));
  };

  refreshReportClick = (e) => {
    e.preventDefault();
    this.getIngestion();
  };

  render() {
    const { isLoading, date, status, statusList } = this.props;

    return (
      <div className="baseContainer report">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={this.state.open}
          className="success"
          onClose={this.handleClose}
          autoHideDuration={2000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          // message={<span id="message-id">Report Saved</span>}
        >
          <SnackbarContent
            message={<span id="message-id">Report Refreshed!</span>}
            style={{
              backgroundColor: "#198754",
              fontSize: "18px",
              fontWeight: "bold"
            }}
          />
        </Snackbar>
        <Box mt={2}>
          <Grid container>
            <Grid item xs={6}>
              <span>
                <p>
                  Current Time: <b>{this.state.time}</b>
                </p>
              </span>
              <span>
                <p>
                  Next Update in <b> {this.state.minLeft}</b>{" "}
                  <b>{this.state.msg}</b>
                </p>
              </span>
              <br />
              <span>
                <TextField
                  type="date"
                  label="Date"
                  name="date"
                  variant="outlined"
                  value={date}
                  onChange={this.handleDateChanged}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </span>
              <span className={"report-status"}>
                <InputLabel
                  className={"report-status-label"}
                  htmlFor="status-placeholder"
                >
                  Status
                </InputLabel>
                <Select
                  className={"report-status-list"}
                  placeholder={"status"}
                  value={"VCON"}
                  variant="outlined"
                  input={<Input name="status" id="status-placeholder" />}
                  onChange={this.handleStatusChange}
                  options={statusList.map((option) => ({
                    value: option.id,
                    label: option.name
                  }))}
                >
                  <MenuItem value={"VCON"}>VCON</MenuItem>
                </Select>
                &nbsp; &nbsp; &nbsp;
                <Button
                  className="forecast_button"
                  variant="outlined"
                  color="primary"
                  size="large"
                  value={"VCON"}
                  onClick={this.handleStatusChange}
                >
                  Get Embeded Table
                </Button>
              </span>
            </Grid>
            {/* <Grid container xs={6} justifyContent="flex-end">
              <Box mt={3}>
                <span className={"report-refresh"}>
                  <Button
                    className="forecast_button"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={this.refreshReportClick}
                    style={{ backgroundColor: "#003764" }}
                  >
                    Refresh Report &nbsp;
                    <FontAwesomeIcon icon={faChartLine} />
                  </Button>
                </span>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        <br />
        <div id={"embed-dashboard-container"} />
        {/* <Loader isLoading={isLoading} />s */}
      </div>
    );
  }
}

ProductionTracker.propTypes = {
  isLoading: T.bool,
  isSaved: T.bool,
  date: T.string,
  embedUrl: T.string,
  status: T.string,
  statusList: T.array,
  value: T.string,
  sucess: T.string,
  open: T.bool
};

function mapStateToProps(state) {
  const { reportEmbed } = state;
  return {
    isLoading: reportEmbed.isLoading,
    date: reportEmbed.date,
    embedUrl: reportEmbed.embedUrl,
    status: reportEmbed.status,
    statusList: reportEmbed.statusList,
    value: reportEmbed.sucessValue,
    sucess: reportEmbed.sucess,
    saveSuccess: reportEmbed.saveSuccess,
    open: reportEmbed.open
  };
}

export default connect(mapStateToProps)(ProductionTracker);
