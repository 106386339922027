import React, { useState, useEffect } from "react";
import { Box, Card, Modal, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ForecastModal = ({ value, sucess, redirect, ...props }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [sucessFields, setSucessFields] = useState([]);
  useEffect(() => {
    setOpen(value);
    setSucessFields(sucess);
  }, [value, sucess, redirect]);

  const handleClose = () => {
    if (redirect) {
      history.push(`${redirect}`);
    }
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  };
  return (
    <>
      {sucessFields ? (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style}>
              <Card
                style={{
                  overflow: "auto",
                  height: "auto",
                  width: "500px",
                  paddingBottom: "25px"
                }}
              >
                <div className="modal-title">
                  <Typography
                    className="modal-typo-title"
                    variant="h5"
                    component="h2"
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size="3x"
                      color="white"
                    />
                  </Typography>
                </div>
                {sucessFields.map((field) => (
                  <div className="modal-description">
                    <div key={field.title}>
                      <Typography
                        key={field.title}
                        id="modal-modal-title"
                        variant="h4"
                      >
                        {field.title}
                      </Typography>
                    </div>
                    <div key={field.body}>
                      <Typography
                        key={field.body}
                        className="modal-typo-description"
                      >
                        {field.body}
                      </Typography>
                    </div>
                  </div>
                ))}
              </Card>
            </Box>
          </Modal>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ForecastModal;
