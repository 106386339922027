
export const FABRIC_QUILT_PATTERN_UPDATE_FIELD = 'FABRIC_QUILT_PATTERN_UPDATE_FIELD'

export const FABRIC_QUILT_PATTERN_LOADING_REQUEST = 'FABRIC_QUILT_PATTERN_LOADING_REQUEST'
export const FABRIC_QUILT_PATTERN_LOADING_SUCCESS = 'FABRIC_QUILT_PATTERN_LOADING_SUCCESS'
export const FABRIC_QUILT_PATTERN_LOADING_ERROR = 'FABRIC_QUILT_PATTERN_LOADING_ERROR'

export const FABRIC_QUILT_PATTERNS_LOADING_REQUEST = 'FABRIC_QUILT_PATTERNS_LOADING_REQUEST'
export const FABRIC_QUILT_PATTERNS_LOADING_SUCCESS = 'FABRIC_QUILT_PATTERNS_LOADING_SUCCESS'
export const FABRIC_QUILT_PATTERNS_LOADING_ERROR = 'FABRIC_QUILT_PATTERNS_LOADING_ERROR'

export const FABRIC_QUILT_PATTERN_IMPORT_LOADING_REQUEST = 'FABRIC_QUILT_PATTERN_IMPORT_LOADING_REQUEST'
export const FABRIC_QUILT_PATTERN_IMPORT_LOADING_SUCCESS = 'FABRIC_QUILT_PATTERN_IMPORT_LOADING_SUCCESS'
export const FABRIC_QUILT_PATTERN_IMPORT_LOADING_ERROR = 'FABRIC_QUILT_PATTERN_IMPORT_LOADING_ERROR'

export const FABRIC_QUILT_PATTERN_SAVING_REQUEST = 'FABRIC_QUILT_PATTERN_SAVING_REQUEST'
export const FABRIC_QUILT_PATTERN_SAVING_SUCCESS = 'FABRIC_QUILT_PATTERN_SAVING_SUCCESS'
export const FABRIC_QUILT_PATTERN_SAVING_ERROR = 'FABRIC_QUILT_PATTERN_SAVING_ERROR'

export const FABRIC_QUILT_PATTERNS_SAVING_REQUEST = 'FABRIC_QUILT_PATTERNS_SAVING_REQUEST'
export const FABRIC_QUILT_PATTERNS_SAVING_SUCCESS = 'FABRIC_QUILT_PATTERNS_SAVING_SUCCESS'
export const FABRIC_QUILT_PATTERNS_SAVING_ERROR = 'FABRIC_QUILT_PATTERNS_SAVING_ERROR'
