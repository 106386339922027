import React from 'react';
import T from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SnackBar = (props) => {
  const { open, message, severity, setSnackBarStatus } = props;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarStatus(prevState => ({...prevState, open: false }));
  };
  return (
    <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        className="snackbar-container"
        onClose={handleClose}
        autoHideDuration={4000}
        ContentProps={{
            'aria-describedby': 'message-id',
        }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
  )
};

SnackBar.propTypes = {
  open: T.bool,
  message: T.string,
  severity: T.string
};

export default SnackBar