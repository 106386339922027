import {
  SHIPMENT_LOADING_REQUEST,
  SHIPMENT_LOADING_SUCCESS,
  SHIPMENT_LOADING_ERROR,
  ORDERS_LOADING_REQUEST,
  ORDERS_LOADING_SUCCESS,
  ORDERS_LOADING_ERROR,
  SHIPMENT_UPDATE_FIELD,
  STORES_LOADING_REQUEST,
  STORES_LOADING_SUCCESS,
  STORES_LOADING_ERROR,
  PRINT_TRUCK_SHIPPED_LOADING_REQUEST,
  PRINT_TRUCK_SHIPPED_LOADING_SUCCESS,
  PRINT_TRUCK_SHIPPED_LOADING_ERROR
} from "../constants/shipmentTypes";
import { COLUMNS_LOADING_SUCCESS } from "../constants/globalTypes";
import { parseAllToJson } from "../utils/string";
import { getFormattedDateTime } from "../utils/dates";
import { getShipment } from "../actions/shipmentActions";

const initialState = {
  isError: false,
  isLoading: false,
  store: -1,
  stores: [],
  status: "",
  orders: [],
  shipments: [],
  shipment: {},
  columns: [],
  shipStartDate: "", //getFormattedDateTime(new Date()),
  shipEndDate: "" //getFormattedDateTime(new Date()),
};

export default function ShipmentListReducer(state = initialState, action) {
  switch (action.type) {
    case ORDERS_LOADING_REQUEST:
    case SHIPMENT_LOADING_REQUEST:
    case STORES_LOADING_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ORDERS_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        orders: action.payload
      };
    case PRINT_TRUCK_SHIPPED_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        orders: action.payload
      };
    case SHIPMENT_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        shipment: action.payload
      };
    case STORES_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        stores: action.payload
      };
    case COLUMNS_LOADING_SUCCESS:
      const columns = parseAllToJson(action.payload, "options");
      return {
        ...state,
        isLoading: false,
        isError: false,
        columns: columns
      };
    case ORDERS_LOADING_ERROR:
    case SHIPMENT_LOADING_ERROR:
    case STORES_LOADING_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case SHIPMENT_UPDATE_FIELD:
      return {
        ...state,
        [action.name]: action.value
      };
    default:
      return state;
  }
}
