import { combineReducers } from 'redux'
import loginForm from './loginFormReducer'
import { connectRouter } from 'connected-react-router'
import scanList from "./scanListReducer"
import scheduleList from './scheduleReducer'
import userForm from "./userFormReducer";
import userList from "./userListReducer";
import shipmentList from "./shipmentListReducer";
import skuForm from './skuFormReducer'
import skuList from './skuListReducer'

import customerForm from './customerFormReducer'
import customerList from './customerListReducer'

import foundationForm from './foundationFormReducer'
import foundationList from './foundationListReducer'

import manufacturerForm from './manufacturerFormReducer'
import manufacturerList from './manufacturerListReducer'

import materialForm from './materialFormReducer'
import materialList from './materialListReducer'

import prototypeForm from './prototypeFormReducer'
import prototypeList from './prototypeListReducer'


import dealerForm from './dealerFormReducer'
import dealerList from './dealerListReducer'

import reportEmbed from './reportPageReducer'

import boxReducer from "./boxReducer"
import brandReducer from "./brandReducer";

import coreReducer from "./coreReducer";
import coreLayerReducer from "./coreLayerReducer";
import coreRawMaterialReducer from "./coreRawMaterialReducer";
import coreTypeReducer from "./coreTypeReducer";
import coreFirmnessReducer from "./coreFirmnessReducer";
import parentCoreReducer from "./parentCoreReducer";

import fabricLayerTypeReducer from "./fabricLayerTypeReducer";
import fabricQuiltPatternReducer from "./fabricQuiltPatternReducer";
import fabricUseReducer from "./fabricUseReducer";

import familyReducer from "./familyReducer";
import sizeReducer from "./sizeReducer";
import batchReducer from "./batchReducer";

import partReducer from "./partReducer"

import foundationGroupReducer from "./foundationGroupReducer"
import materialGroupReducer from "./materialGroupReducer";

import roleReducer from "./roleReducer";
import panelReducer from "./panelReducer";
import panelLayerReducer from "./panelLayerReducer";
import fishbowlReducer from "./fishbowlReducer";

import lawTagViewerReducer from "./lawTagViewerReducer";

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  loginForm,
  scanList,
  scheduleList,
  userList,
  userForm,
  shipmentList,
  skuForm,
  skuList,
  customerForm,
  customerList,
  foundationGroupReducer,
  foundationForm,
  foundationList,
  manufacturerForm,
  manufacturerList,
  materialGroupReducer,
  materialForm,
  materialList,
  prototypeForm,
  prototypeList,
  dealerForm,
  dealerList,
  reportEmbed,
  brandReducer,
  coreReducer,
  coreLayerReducer,
  coreRawMaterialReducer,
  coreTypeReducer,
  coreFirmnessReducer,
  parentCoreReducer,
  fabricLayerTypeReducer,
  fabricQuiltPatternReducer,
  fabricUseReducer,
  familyReducer,
  sizeReducer,
  batchReducer,
  partReducer,
  boxReducer,
  roleReducer,
  panelReducer,
  panelLayerReducer,
  fishbowlReducer,
  lawTagViewerReducer
})

export default rootReducer
