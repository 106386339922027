import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFile } from '@fortawesome/free-solid-svg-icons';
import "./viewcorerow.css"

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = (props) => {
  const { row } = props;
  const history = useHistory()
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const handleEdit = (id) => {
    history.push(`/core-update/${row.id}`, {row})
  }
  const handleCopyBOM = () => {
    history.push("/copy-bom/-1", {row})
  }
  return (
      <>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell >{row.type.core_type}</TableCell>
          <TableCell align="right">{row.size?.size}</TableCell>
          <TableCell align="right" id='id-buttons'>
            <Button 
              onClick={() => handleCopyBOM()}
              aria-labelledby='copy bom'
              variant='outlined'
              label="edit"
              style={{borderColor: "#163762", color: "#163762"}}
            ><FontAwesomeIcon icon={faFile} /> Copy BOM</Button>
            <Button 
              onClick={() => handleEdit(row.id)}
              aria-labelledby='edit core'
              variant='outlined'
              label="edit"
              style={{borderColor: "#163762", color: "#163762"}}
            ><FontAwesomeIcon name='edit' icon={faEdit} /> edit core</Button>
          </TableCell>
        </TableRow>
        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
        <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">Core Details</Typography>
          <Table size="small" aria-label="details">
            <TableHead>
              <TableRow>
                <TableCell>Layer Number</TableCell>
                <TableCell align="right">Part Number</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Depth</TableCell>
              </TableRow>
            </TableHead>
          <TableBody>
            {row.layers
            .sort((a,b) => a.core_layer - b.core_layer)
            .map((layer) => (
              <TableRow key={layer.id}>
                <TableCell >{layer.core_layer}</TableCell>
                <TableCell align="right">{layer.part.num}</TableCell>
                <TableCell align="right">{layer.part.internal_desc}</TableCell>
                <TableCell align="right">{layer.layer_depth_in} in.</TableCell>
              </TableRow>
            ))}
          </TableBody>
          </Table>
        </Box>
        </Collapse>
        </TableCell>
        </TableRow>
      </>
  )
}

export default Row;