import * as types from '../constants/manufacturerTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.MANUFACTURER_UPDATE_FIELD,
        name,
        value,
    }
}

export function getManufacturers() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/manufacturers`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.MANUFACTURERS_LOADING_REQUEST,
                types.MANUFACTURERS_LOADING_SUCCESS,
                types.MANUFACTURERS_LOADING_ERROR,
            ],
        },
    }
}

export function getManufacturer(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/manufacturer/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.MANUFACTURER_LOADING_REQUEST,
                types.MANUFACTURER_LOADING_SUCCESS,
                types.MANUFACTURER_LOADING_ERROR,
            ],
        },
    }
}

export function saveManufacturerList(manufacturers){
    const data = []
    for (let field in manufacturers.data) {
        let row = removeEmptyFields(manufacturers.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/manufacturers`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.MANUFACTURERS_SAVING_REQUEST,
                types.MANUFACTURERS_SAVING_SUCCESS,
                types.MANUFACTURERS_SAVING_ERROR,
            ],
        },
    }
}

export function saveManufacturer(manufacturer){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/manufacturer`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(manufacturer),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.MANUFACTURER_SAVING_REQUEST,
                types.MANUFACTURER_SAVING_SUCCESS,
                types.MANUFACTURER_SAVING_ERROR,
            ],
        },
    }
}