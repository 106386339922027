import {
    BOX_UPDATE_FIELD,
    BOX_LOADING_REQUEST,
    BOX_LOADING_SUCCESS,
    BOX_LOADING_ERROR,
    BOX_SAVING_REQUEST,
    BOX_SAVING_SUCCESS,
    BOX_SAVING_ERROR,
    BOXES_LOADING_ERROR,
    BOXES_LOADING_REQUEST,
    BOXES_LOADING_SUCCESS,
    BOXES_SAVING_ERROR,
    BOXES_SAVING_REQUEST,
    BOXES_SAVING_SUCCESS
} from '../constants/boxTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"
import {addLink} from "../utils/column";
import T from "prop-types";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    boxes: [],
    columns: [],
    rows: [],
    id: -1,
    brandId: -1,
    brand: '',
    partId: -1,
    part: '',
    cost: 0,
    length: 0,
    width: 0,
    height: 0,
    boxSizeId: 0,
}

export default function boxReducer(state = initialState, action) {
    switch (action.type) {
        case BOX_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case BOXES_LOADING_REQUEST:
        case BOXES_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case BOX_LOADING_REQUEST:
        case BOX_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case BOX_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                brandId: action.payload.brand_id,
                brand: action.payload.brand != undefined ? action.payload.brand.brand : '',
                partId: action.payload.part_id,
                part: action.payload.part != undefined ? action.payload.part.num : '',
                cost: action.payload.cost,
                length: action.payload.length_in,
                width: action.payload.width_in,
                height: action.payload.height_in,
                boxSizeId: action.payload.box_size_id,
            }
        case BOX_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: -1,
                brandId: -1,
                brand: '',
                partId: -1,
                part: '',
                cost: 0,
                length: 0,
                width: 0,
                height: 0,
                boxSizeId: 0,
            }
        case BOXES_LOADING_ERROR:
        case BOXES_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case BOX_LOADING_ERROR:
        case BOX_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case BOXES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                boxes: action.payload,
            }
        case BOXES_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                boxes: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}