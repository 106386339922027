import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  Typography,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  upadteFlatPackShipping,
  updateScan
} from "../../../actions/scanActions";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchMinus } from "@fortawesome/free-solid-svg-icons";

const TruckModal = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(props.show);
  const [sku, setSku] = useState("");
  const [truckNumber, setTruckNumber] = useState("");
  const [scannedItem, setScannedItem] = useState({});
  const [unload, setUnload] = useState("");

  useEffect(() => {
    if (props.component != null) {
      let skuItem = props.component;
      setScannedItem(skuItem);
      setSku(skuItem.rfid_scan);
      setTruckNumber(skuItem.truck_id);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setUnload("");
    props.close(true);
  };

  const handleCloseYes = async () => {
    const data = [scannedItem];
    const newData = await Object.assign({}, props.component, {});
    newData.deleted_at = moment().format("YYYY-MM-DD HH:mm:ss");
    newData.user_id = JSON.parse(window.localStorage.getItem("userId"));
    newData.date = moment(data.date).format("YYYY-MM-DD");
    delete newData.user;
    delete newData.status;
    delete newData.scans;
    let flatPackData = {
      schedule_id: newData.schedule_id,
      scan_log_id: null
    };
    dispatch(upadteFlatPackShipping(flatPackData));
    await dispatch(updateScan(newData)).then((res) => {
      props.getItem(res.payload);
      handleClose();
    });
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 2, borderColor: "black" }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {`This Item Was Already Loaded On Truck ${truckNumber}!`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: "24px" }}>
            Would you like to unload SKU item {sku} from truck {truckNumber}?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Typography
            style={{
              display: "inline-block",
              fontWeight: "bold",
              fontSize: "24px",
              marginTop: "24px",
              paddingRight: "15px"
            }}
          >
            Scan Data
          </Typography>
          <TextField
            key="password"
            style={{ maxWidth: "325px" }}
            value={unload}
            fullWidth
            id="unloadTruck"
            autoFocus={true}
            className="sku_textfield"
            onChange={(e) => {
              setUnload(e.target.value);
              if (e.target.value == "00000001") {
                handleClose();
              } else if (e.target.value === "01111001") {
                handleCloseYes();
              }
            }}
            variant="filled"
            InputProps={{
              minLength: 22,
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    style={{ marginTop: "2px" }}
                    icon={faSearchMinus}
                    size="lg"
                  />
                </InputAdornment>
              )
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TruckModal;
