
import {
    CORE_LAYER_UPDATE_FIELD,
    CORE_LAYER_LOADING_REQUEST,
    CORE_LAYER_LOADING_SUCCESS,
    CORE_LAYER_LOADING_ERROR,
    CORE_LAYER_SAVING_REQUEST,
    CORE_LAYER_SAVING_SUCCESS,
    CORE_LAYER_SAVING_ERROR,
    CORE_LAYERS_LOADING_ERROR,
    CORE_LAYERS_LOADING_REQUEST,
    CORE_LAYERS_LOADING_SUCCESS,
    CORE_LAYERS_SAVING_ERROR,
    CORE_LAYERS_SAVING_REQUEST,
    CORE_LAYERS_SAVING_SUCCESS, CORE_LAYER_NEW_SUCCESS, CORE_LAYER_ADDED
} from '../constants/coreLayerTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"
import {CORE_ADD_LAYER} from "../constants/coreTypes";
import {compareValues} from "../utils/sort";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    coreLayers: [],
    columns: [],
    rows: [],
    id: -1,
    coreId: -1,
    partId: -1,
    coreLayer: 0,
    layerDepthIn: 0.00,
    rawMaterialId: -1,
    rawMaterial: '',
    part: '',
    coreType: '',
    material: {},
    rawMaterials: [],
    parts: [],
}

export default function coreLayerReducer(state = initialState, action) {
    switch (action.type) {
        case CORE_LAYER_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case CORE_LAYER_ADDED:
            return {
                ...state,
                coreLayer: 0,
                layerDepthIn: 0,
                rawMaterial: '',
                part: '',
            }
        case CORE_LAYERS_LOADING_REQUEST:
        case CORE_LAYERS_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case CORE_LAYER_LOADING_REQUEST:
        case CORE_LAYER_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case CORE_LAYER_NEW_SUCCESS:
            return{
                ...state,
                isLoading: false,
                isError: false,
                id: action.id,
                coreId: action.core_id,
                partId: action.part_id,
                coreLayer: action.core_layer,
                layerDepthIn: action.layer_depth_in,
                rawMaterialId: action.raw_material_id,
                rawMaterial: '',
                part: '',
                coreType: '',
                material: action.material,
            }
        case CORE_LAYER_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                coreId: action.payload.core_id,
                partId: action.payload.part_id,
                coreLayer: action.payload.core_layer,
                layerDepthIn: action.payload.layer_depth_in,
                rawMaterialId: action.payload.raw_material_id,
                rawMaterial: action.payload.material.raw_material,
                part: action.payload.part.num,
                coreType: action.payload.core.type.core_type,
                material: action.payload.material,
            }
        case CORE_LAYER_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: action.payload.id,
                coreId: action.payload.core_id,
                partId: action.payload.part_id,
                coreLayer: action.payload.core_layer,
                layerDepthIn: action.payload.layer_depth_in,
                rawMaterialId: action.payload.raw_material_id,
                material: action.payload.material,
            }
        case CORE_LAYERS_LOADING_ERROR:
        case CORE_LAYERS_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case CORE_LAYER_LOADING_ERROR:
        case CORE_LAYER_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case CORE_LAYERS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                coreLayers: action.payload,
            }
        case CORE_LAYERS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                coreLayers: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            const columns = parseAllToJson(action.payload, 'options')
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: columns,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}