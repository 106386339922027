import React  from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import '../../styles/icons.css'
import {getDealers, saveDealerList} from "../../actions/dealerActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

class DealerList extends React.Component {

    getOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <AddNewButton
                        entity={"dealer"}
                        history={this.props.history}
                        data={data}
                    />
                )
            }
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            dealers: [],
            rows: [],
            cols: [],
            columns: [],
            filename: '',
            file: '',
        }
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('dealer'))
    }

    //Toolbar functions
    handleDealerSaveClick = () => {
        const {
            dispatch,
            dealers,
        } = this.props

        const data = {
            data: dealers.concat(this.state.dealers),
            filename: this.state.filename,
        }

        dispatch(saveDealerList(data))
        this.setState({
            cols: [],
            rows: [],
            dealers: [],
            filename: '',
        })
    }
    handleDealerDeleteClick = () => {

        this.setState({
            cols: [],
            rows: [],
            schedule: [],
        })
    }

    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {

        this.getColumns()

        const {
            dispatch,
        } = this.props

        dispatch(getDealers())
    }

    redirectToTarget = () => {
        this.props.history.push("/dealer");
    }

    render() {
        const {
            isLoading,
            dealers,
            columns,
        } = this.props

        return (
            <div className="baseContainer dealerList">
                <h1>

                </h1>
                <MUIDataTable
                    title={"CFS Dealers"}
                    data={dealers.concat(this.state.dealers)}
                    columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    options={this.getOptions(dealers.concat(this.state.dealers))}
                />
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

DealerList.defaultProps = {
    date: getDBFormattedDate()
}

DealerList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    dealers: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { dealerList } = state
    return {
        isLoading: dealerList.isLoading,
        dealers: dealerList.dealers,
        rows: dealerList.rows,
        cols: dealerList.cols,
        columns: dealerList.columns,
        file: dealerList.file,
    }
}

export default withRouter(connect(mapStateToProps)(DealerList))