import * as types from '../constants/familyTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateFamilyField(name, value, innerText) {
    return {
        type: types.FAMILY_UPDATE_FIELD,
        name,
        value,
        innerText,
    }
}

export function updateQuickshipBox(value) {
    return {
        type: types.FAMILY_UPDATE_QUICKSHIP,
        value,
    }
}

export function addFabricLayer(layer, layers) {
    return {
        type: types.FAMILY_FABRIC_ADD_LAYER,
        layers: layers.concat(layer),
    }
}

export function getFamilies() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/families`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FAMILIES_LOADING_REQUEST,
                types.FAMILIES_LOADING_SUCCESS,
                types.FAMILIES_LOADING_ERROR,
            ],
        },
    }
}

export function getFamily(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/family/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FAMILY_LOADING_REQUEST,
                types.FAMILY_LOADING_SUCCESS,
                types.FAMILY_LOADING_ERROR,
            ],
        },
    }
}

export function saveFamilyList(families){
    const data = []
    for (let field in families.data) {
        let row = removeEmptyFields(families.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/families`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FAMILIES_SAVING_REQUEST,
                types.FAMILIES_SAVING_SUCCESS,
                types.FAMILIES_SAVING_ERROR,
            ],
        },
    }
}

export function saveFamily(family){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/family`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(family),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FAMILY_SAVING_REQUEST,
                types.FAMILY_SAVING_SUCCESS,
                types.FAMILY_SAVING_ERROR,
            ],
        },
    }
}
// Build type
export function getBuildType() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/build`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FAMILIES_LOADING_REQUEST,
                types.FAMILIES_LOADING_SUCCESS,
                types.FAMILIES_LOADING_ERROR,
            ],
        },
    }
}
export function updateFamily(family) {
        return {
          [RSAA]: {
            endpoint: `${API_ROOT}/update-family`, // TODO: make base URL constant
            method: "PUT",
            body: JSON.stringify(family),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            options: {
              mode: "cors"
            },
            types: [
              types.FAMILY_SAVING_REQUEST,
              types.FAMILY_SAVING_SUCCESS,
              types.FAMILY_SAVING_ERROR,
            ]
          }
        };
      }