import React from "react";
import "../styles/about-page.css";
import ProductionTracker from "./report/ProductionTracker";

export class ReportPage extends React.Component {
  render() {
    return <ProductionTracker />;
  }
}

export default ReportPage;
