
export const SIZE_UPDATE_FIELD = 'SIZE_UPDATE_FIELD'

export const SIZE_LOADING_REQUEST = 'SIZE_LOADING_REQUEST'
export const SIZE_LOADING_SUCCESS = 'SIZE_LOADING_SUCCESS'
export const SIZE_LOADING_ERROR = 'SIZE_LOADING_ERROR'

export const SIZES_LOADING_REQUEST = 'SIZES_LOADING_REQUEST'
export const SIZES_LOADING_SUCCESS = 'SIZES_LOADING_SUCCESS'
export const SIZES_LOADING_ERROR = 'SIZES_LOADING_ERROR'

export const SIZE_IMPORT_LOADING_REQUEST = 'SIZE_IMPORT_LOADING_REQUEST'
export const SIZE_IMPORT_LOADING_SUCCESS = 'SIZE_IMPORT_LOADING_SUCCESS'
export const SIZE_IMPORT_LOADING_ERROR = 'SIZE_IMPORT_LOADING_ERROR'

export const SIZE_SAVING_REQUEST = 'SIZE_SAVING_REQUEST'
export const SIZE_SAVING_SUCCESS = 'SIZE_SAVING_SUCCESS'
export const SIZE_SAVING_ERROR = 'SIZE_SAVING_ERROR'

export const SIZES_SAVING_REQUEST = 'SIZES_SAVING_REQUEST'
export const SIZES_SAVING_SUCCESS = 'SIZES_SAVING_SUCCESS'
export const SIZES_SAVING_ERROR = 'SIZES_SAVING_ERROR'
