import * as types from '../constants/loginTypes';
import { RSAA } from 'redux-api-middleware';
import md5 from "md5";
import {API_ROOT} from "../utils/api";
import {removeEmptyFields} from "../utils/object";


function validateForm(username, password) {
    return username && password && username.length > 0 && password.length > 0;
}

export function loginSubmit (username, password) {
    if (validateForm(username, password)) {
        const postData = {
            username: username,
            password: md5(password),
        };

        return {
            [RSAA]: {
                endpoint: `${API_ROOT}/login`,
                method: 'POST',
                body: JSON.stringify(removeEmptyFields(postData)),
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                options: {
                    mode: 'cors',
                },
                types: [
                    types.LOGIN_SUBMIT_REQUEST,
                    types.LOGIN_SUBMIT_SUCCESS,
                    types.LOGIN_SUBMIT_FAILURE,
                ],
            },
        }
    }
    else{
        return {
            type: types.LOGIN_SUBMIT_FAILURE,
            status: 'Username and Password Required'
        }
    }
}

export function updatePassword(userId, password) {
    if (userId > 0 && password.length > 0) {

        const token = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(token);

        const data = {
            hash: md5(password),
            change_pwd_reqd: 0,
            id: userId
        }
        return {
            [RSAA]: {
                endpoint: `${API_ROOT}/user`,
                method: 'POST',
                headers: {
                    // "Content-Type": "application/x-www-form-urlencoded",
                    // "Access-Control-Allow-Origin": "*",
                    // "Access-Control-Allow-Credentials": "true",
                    // "Authorization": AuthStr,
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(data),
                options: {
                    mode: 'cors',
                },
                types: [
                    types.PASSWORD_CHANGE_REQUEST,
                    types.PASSWORD_CHANGE_SUCCESS,
                    types.PASSWORD_CHANGE_FAILURE
                ]
            }
        }
    } else {
        return function (dispatch) {
            return dispatch({
                type: types.PASSWORD_CHANGE_FAILURE
            });
        };
    }

}

export function updateField(name, value) {
    return {
        type: types.LOGIN_UPDATE_FIELD,
        name,
        value,
    }
}

export function clearUser(){
    return {
        type: types.CLEAR_USER
    }
}

