
export const SKU_UPDATE_FIELD = 'SKU_UPDATE_FIELD'
export const SKU_UPDATE_CORE = 'SKU_UPDATE_CORE'
export const SKU_UPDATE_FAMILY = 'SKU_UPDATE_FAMILY'
export const SKU_UPDATE_BATCH = 'SKU_UPDATE_BATCH'
export const SKU_UPDATE_FIRMNESS = 'SKU_UPDATE_FIRMNESS'
export const SKU_UPDATE_PARENT_CORE = 'SKU_UPDATE_PARENT_CORE'
export const SKU_UPDATE_SKU_ATTRIBUTE = 'SKU_UPDATE_SKU_FABRIC_ATTRIBUTE'
export const SKU_PROTOTYPE_UPDATE = 'SKU_PROTOTYPE_UPDATE'


export const SKU_UPDATE_TOP_PANEL = 'SKU_UPDATE_TOP_PANEL'
export const SKU_UPDATE_GUSSET_PANEL = 'SKU_UPDATE_GUSSET_PANEL'
export const SKU_UPDATE_SIDE_PANEL = 'SKU_UPDATE_SIDE_PANEL'
export const SKU_UPDATE_BOTTOM_PANEL = 'SKU_UPDATE_BOTTOM_PANEL'


export const SKU_LOADING_REQUEST = 'SKU_LOADING_REQUEST'
export const SKU_LOADING_SUCCESS = 'SKU_LOADING_SUCCESS'
export const SKU_LOADING_ERROR = 'SKU_LOADING_ERROR'

export const SKUS_ACTIVE_LOADING_REQUEST = 'SKUS_ACTIVE_LOADING_REQUEST'
export const SKUS_ACTIVE_LOADING_SUCCESS = 'SKUS_ACTIVE_LOADING_SUCCESS'
export const SKUS_ACTIVE_LOADING_ERROR = 'SKUS_ACTIVE_LOADING_ERROR'

export const SKUS_INACTIVE_LOADING_REQUEST = 'SKUS_INACTIVE_LOADING_REQUEST'
export const SKUS_INACTIVE_LOADING_SUCCESS = 'SKUS_INACTIVE_LOADING_SUCCESS'
export const SKUS_INACTIVE_LOADING_ERROR = 'SKUS_INACTIVE_LOADING_ERROR'

export const SKUS_ALL_LOADING_REQUEST = 'SKUS_ALL_LOADING_REQUEST'
export const SKUS_ALL_LOADING_SUCCESS = 'SKUS_ALL_LOADING_SUCCESS'
export const SKUS_ALL_LOADING_ERROR = 'SKUS_ALL_LOADING_ERROR'

export const SKU_GROUPS_LOADING_REQUEST = 'SKU_GROUPS_LOADING_REQUEST'
export const SKU_GROUPS_LOADING_SUCCESS = 'SKU_GROUPS_LOADING_SUCCESS'
export const SKU_GROUPS_LOADING_ERROR = 'SKU_GROUPS_LOADING_ERROR'
export const SKU_GROUP_LOADING_REQUEST = 'SKU_GROUP_LOADING_REQUEST'
export const SKU_GROUP_LOADING_SUCCESS = 'SKU_GROUP_LOADING_SUCCESS'
export const SKU_GROUP_LOADING_ERROR = 'SKU_GROUP_LOADING_ERROR'
//sku drafts
export const SKU_DRAFTS_LOADING_REQUEST = 'SKU_DRAFTS_LOADING_REQUEST'
export const SKU_DRAFTS_LOADING_SUCCESS = 'SKU_DRAFTS_LOADING_SUCCESS'
export const SKU_DRAFTS_LOADING_ERROR = 'SKU_DRAFTS_LOADING_ERROR'
export const SKU_DRAFT_LOADING_REQUEST = 'SKU_DRAFT_LOADING_REQUEST'
export const SKU_DRAFT_LOADING_SUCCESS = 'SKU_DRAFT_LOADING_SUCCESS'
export const SKU_DRAFT_LOADING_ERROR = 'SKU_DRAFT_LOADING_ERROR'
export const SKU_DRAFT_SAVING_REQUEST = 'SKU_DRAFT_SAVING_REQUEST'
export const SKU_DRAFT_SAVING_SUCCESS = 'SKU_DRAFT_SAVING_SUCCESS'
export const SKU_DRAFT_SAVING_ERROR = 'SKU_DRAFT_SAVING_ERROR'
export const SKU_DRAFT_DELETE_ERROR ='SKU_DRAFT_DELETE_ERROR'
export const SKU_DRAFT_DELETE_SUCCESS ='SKU_DRAFT_DELETE_SUCCESS'
export const SKU_DRAFT_DELETE_REQUEST ='SKU_DRAFT_DELETE_REQUEST'

export const SKU_IMPORT_LOADING_REQUEST = 'SKU_IMPORT_LOADING_REQUEST'
export const SKU_IMPORT_LOADING_SUCCESS = 'SKU_IMPORT_LOADING_SUCCESS'
export const SKU_IMPORT_LOADING_ERROR = 'SKU_IMPORT_LOADING_ERROR'

export const SKU_SAVING_REQUEST = 'SKU_SAVING_REQUEST'
export const SKU_SAVING_SUCCESS = 'SKU_SAVING_SUCCESS'
export const SKU_SAVING_ERROR = 'SKU_SAVING_ERROR'

export const SKUS_SAVING_REQUEST = 'SKUS_SAVING_REQUEST'
export const SKUS_SAVING_SUCCESS = 'SKUS_SAVING_SUCCESS'
export const SKUS_SAVING_ERROR = 'SKUS_SAVING_ERROR'

export const SKU_ADD_MATERIAL = 'SKU_ADD_MATERIAL'
export const SKU_MATERIAL_ADDED = 'SKU_MATERIAL_ADDED'

export const SKU_ADD_FOUNDATION= 'SKU_ADD_FOUNDATION'
export const SKU_FOUNDATION_ADDED = 'SKU_FOUNDATION_ADDED'

export const SKU_MATERIAL_GROUP_UPDATE = 'SKU_MATERIAL_GROUP_UPDATE'
export const SKU_FOUNDATION_GROUP_UPDATE = 'SKU_FOUNDATION_GROUP_UPDATE'

export const SKU_UPDATE_MODAL_CLOSED = 'SKU_UPDATE_MODAL_CLOSED'
export const SKU_UPDATE_MODAL_OPEN = 'SKU_UPDATE_MODAL_OPEN'


export const SKU_TAB_CHANGE = 'SKU_TAB_CHANGE'

export const SKU_RESET = 'SKU_RESET'

export const SKU_UPDATE_IDENTIFIER = 'SKU_UPDATE_IDENTIFIER'

export const SKU_SET_TOP = 'SKU_SET_TOP'
export const SKU_SET_GUSSET = 'SKU_SET_GUSSET'
export const SKU_SET_SIDE = 'SKU_SET_SIDE'
export const SKU_SET_BOTTOM = 'SKU_SET_BOTTOM'

export const SKU_FISHBOWL_PART_LOADING_REQUEST = 'SKU_FISHBOWL_PART_LOADING_REQUEST'
export const SKU_FISHBOWL_PART_LOADING_SUCCESS = 'SKU_FISHBOWL_PART_LOADING_SUCCESS'
export const SKU_FISHBOWL_PART_LOADING_ERROR = 'SKU_FISHBOWL_PART_LOADING_ERROR'

export const SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_REQUEST = 'SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_REQUEST'
export const SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_SUCCESS = 'SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_SUCCESS'
export const SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_ERROR = 'SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_ERROR'

export const TRIGGER_BOM_REQUEST = 'TRIGGER_BOM_REQUEST'
export const TRIGGER_BOM_SUCCESS = 'TRIGGER_BOM_SUCCESS'
export const TRIGGER_BOM_ERROR = 'TRIGGER_BOM_ERROR'



