
import {
    BRAND_UPDATE_FIELD,
    BRAND_LOADING_REQUEST,
    BRAND_LOADING_SUCCESS,
    BRAND_LOADING_ERROR,
    BRAND_SAVING_REQUEST,
    BRAND_SAVING_SUCCESS,
    BRAND_SAVING_ERROR,
    BRANDS_LOADING_ERROR,
    BRANDS_LOADING_REQUEST,
    BRANDS_LOADING_SUCCESS,
    BRANDS_SAVING_ERROR,
    BRANDS_SAVING_REQUEST,
    BRANDS_SAVING_SUCCESS
} from '../constants/brandTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    id: -1,
    brand: '',
    brands: [],
    columns: [],
    rows: [],
}

export default function brandReducer(state = initialState, action) {
    switch (action.type) {
        case BRAND_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case BRANDS_LOADING_REQUEST:
        case BRANDS_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case BRAND_LOADING_REQUEST:
        case BRAND_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case BRAND_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                brand: action.payload.brand,
            }
        case BRAND_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: null,
                brand: '',
            }
        case BRANDS_LOADING_ERROR:
        case BRANDS_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case BRAND_LOADING_ERROR:
        case BRAND_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case BRANDS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                brands: action.payload,
            }
        case BRANDS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                brands: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}