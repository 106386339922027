import React  from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import {getFabricLayerTypes, saveFabricLayerTypeList} from "../../actions/fabricLayerTypeActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

//this used to be a Fabric Layer Type component (tick, fill, fr, backing)
//since we do not need the user to add layer types - it doesn't happen frequently
//I just made this into PanelList component -
// still trying to figure out how we are going to display panels since they don't have 'names'
class PanelList extends React.Component {

    getOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <AddNewButton
                        entity={"panel/-1"}
                        history={this.props.history}
                        data={data}
                    />
                )
            }
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            // fabricLayerTypes: [],
            rows: [],
            cols: [],
            columns: [],
            filename: '',
            file: '',
        }
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        // dispatch(getColumns('fabric_layer_type'))
    }

    //Toolbar functions
    // handleFabricLayerTypeSaveClick = () => {
    //     const {
    //         dispatch,
    //         fabricLayerTypes,
    //     } = this.props
    //
    //     const data = {
    //         data: fabricLayerTypes.concat(this.state.fabricLayerTypes),
    //         filename: this.state.filename,
    //     }
    //
    //     dispatch(saveFabricLayerTypeList(data))
    //     this.setState({
    //         cols: [],
    //         rows: [],
    //         fabricLayerTypes: [],
    //         filename: '',
    //     })
    // }
    // handleFabricLayerTypeDeleteClick = () => {
    //     this.setState({
    //         cols: [],
    //         rows: [],
    //         schedule: [],
    //     })
    // }
    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {

        this.getColumns()

        const {
            dispatch,
        } = this.props

        // dispatch(getFabricLayerTypes())
    }

    render() {
        const {
            isLoading,
            fabricLayerTypes,
            columns,
        } = this.props

        return (
            <div className="baseContainer fabricLayerTypeList">
                <h1>

                </h1>
                <MUIDataTable
                    title={"Panels"}
                    // data={fabricLayerTypes.concat(this.state.fabricLayerTypes)}
                    // columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    columns={["Panels"]}
                    options={this.getOptions()}
                    // options={{
                    //     selectableRows: false // <===== will turn off checkboxes in rows
                    // }}
                />
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

PanelList.defaultProps = {
    date: getDBFormattedDate()
}

PanelList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    // fabricLayerTypes: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { fabricLayerTypeReducer } = state
    return {
        isLoading: fabricLayerTypeReducer.isLoading,
        // fabricLayerTypes: fabricLayerTypeReducer.fabricLayerTypes,
        // rows: fabricLayerTypeReducer.rows,
        // cols: fabricLayerTypeReducer.cols,
        // columns: fabricLayerTypeReducer.columns,
        // file: fabricLayerTypeReducer.file,
    }
}

export default withRouter(connect(mapStateToProps)(PanelList))