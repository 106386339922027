export const LOCATION_GROUPS_LOADING_REQUEST = 'LOCATION_GROUPS_LOADING_REQUEST'
export const LOCATION_GROUPS_LOADING_SUCCESS = 'LOCATION_GROUPS_LOADING_SUCCESS'
export const LOCATION_GROUPS_LOADING_ERROR = 'LOCATION_GROUPS_LOADING_ERROR'

export const CYCLE_COUNT_UPDATE_FIELD = 'CYCLE_COUNT_UPDATE_FIELD'

export const LOCATIONS_LOADING_ERROR = 'LOCATIONS_LOADING_ERROR'
export const LOCATIONS_LOADING_REQUEST = 'LOCATIONS_LOADING_REQUEST'
export const LOCATIONS_LOADING_SUCCESS = 'LOCATIONS_LOADING_SUCCESS'

export const SKU_BY_BRAND_LOADING_REQUEST = 'SKU_BY_BRAND_LOADING_REQUEST'
export const SKU_BY_BRAND_LOADING_SUCCESS = 'SKU_BY_BRAND_LOADING_SUCCESS'
export const SKU_BY_BRAND_LOADING_ERROR = 'SKU_BY_BRAND_LOADING_ERROR'

export const SKU_CYCLECOUNT_LOADING_REQUEST = 'SKU_CYCLECOUNT_LOADING_REQUEST'
export const SKU_CYCLECOUNT_LOADING_SUCCESS = 'SKU_CYCLECOUNT_LOADING_SUCCESS'
export const SKU_CYCLECOUNT_LOADING_ERROR = 'SKU_CYCLECOUNT_LOADING_ERROR'

export const RESET_CYCLE_COUNT = 'RESET_CYCLE_COUNT'

export const CYCLE_COUNT_IMPORT_REQUEST = 'CYCLE_COUNT_IMPORT_REQUEST'
export const CYCLE_COUNT_IMPORT_SUCCESS = 'CYCLE_COUNT_IMPORT_SUCCESS'
export const CYCLE_COUNT_IMPORT_ERROR = 'CYCLE_COUNT_IMPORT_ERROR'


export const ERROR_OPEN_REQUEST = 'ERROR_OPEN_REQUEST'
export const ERROR_OPEN_SUCCESS = 'ERROR_OPEN_SUCCESS'
export const ERROR_OPEN_ERROR = 'ERROR_OPEN_ERROR'

export const ERROR_CLOSE_REQUEST = 'ERROR_CLOSE_REQUEST'
export const ERROR_CLOSE_SUCCESS = 'ERROR_CLOSE_SUCCESS'
export const ERROR_CLOSE_ERROR = 'ERROR_CLOSE_ERROR'

export const REPROCESS_REQUEST = 'REPROCESS_REQUEST'
export const REPROCESS_ERROR = 'REPROCESS_ERROR'
export const REPROCESS_SUCCESS = 'REPROCESS_SUCCESS'

export const DIALOG_OPEN = 'DIALOG_OPEN'
export const DIALOG_OPEN_BULK = 'DIALOG_OPEN_BULK'
export const DIALOG_ACCEPTED = 'DIALOG_ACCEPTED'
export const DIALOG_DENIED = 'DIALOG_DENIED'

export const SKU_LOADING_REQUEST = 'SKU_LOADING_REQUEST'
export const SKU_LOADING_ERROR = 'SKU_LOADING_ERROR'
export const SKU_LOADING_SUCCESS = 'SKU_LOADING_SUCCESS'

export const UPDATE_CORE_LAYER_FISHBOWL_PART_REQUEST = 'UPDATE_CORE_LAYER_FISHBOWL_PART_REQUEST'
export const UPDATE_CORE_LAYER_FISHBOWL_PART_SUCCESS = 'UPDATE_CORE_LAYER_FISHBOWL_PART_SUCCESS'
export const UPDATE_CORE_LAYER_FISHBOWL_PART_ERROR = 'UPDATE_CORE_LAYER_FISHBOWL_PART_ERROR'

export const UPDATE_PANEL_LAYER_FISHBOWL_PART_REQUEST = 'UPDATE_PANEL_LAYER_FISHBOWL_PART_REQUEST'
export const UPDATE_PANEL_LAYER_FISHBOWL_PART_SUCCESS = 'UPDATE_PANEL_LAYER_FISHBOWL_PART_SUCCESS'
export const UPDATE_PANEL_LAYER_FISHBOWL_PART_ERROR = 'UPDATE_PANEL_LAYER_FISHBOWL_PART_ERROR'