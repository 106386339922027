import React from 'react';
import T from 'prop-types';
import { 
  Box, 
  Button, 
  FormControl, 
  FormControlLabel, 
  InputLabel, 
  MenuItem, 
  Select, 
  Switch, 
  TextField, 
  Typography } from '@material-ui/core';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "./copybomlayersform.css"

const CopyBomLayersForm = (props) => {
  const { 
    setCoreData, 
    convoluted, 
    topper, 
    rawMaterial,
    depth,
    actualDepth,
    addNewLayer,
    parts,
    materials,
   } = props;
   const handleConvolutedChange = (e) => {
    setCoreData(prevState => ({ ...prevState, convoluted: e.target.checked}))
  };
  const handleTopperChange = (e) => {
    setCoreData(prevState => ({ ...prevState, topper: e.target.checked}))
  };
  const handleRawMaterialsSelect = (e) => {
    setCoreData(prevState => ({...prevState, rawMaterial: e.target.value}))
  };
  const handlePartSelect = (e) => {
    e.persist();
    setCoreData(prevState => ({...prevState, partNum: e.target.value.split(' -')[0]}))
  };
  const handleDepthChange = (e) => {
    e.persist();
    setCoreData(prevState => ({...prevState, depth: e.target.value}))
  };
  const handleActualDepthChange = (e) => {
    e.persist();
    setCoreData(prevState => ({...prevState, actualDepth: e.target.value}))
  };
  let filterCategories = ['Bun Foam', 'Coil Unit', 'RM Topper', 'Latex', 'Pad', 'Core', 'Roll Foam', 'Non Woven'];
  let filteredParts = parts.filter(part => filterCategories.indexOf(part.category) > -1);
  return (
    <Box className='copyBom-layers-form'>
       <FormControl
      >
        <Box className='copyBom-layer-inputs' >
          <InputLabel
            variant="outlined"
            id='raw-mat-type'
          >
            Raw Material Type
          </InputLabel>
          <Select
            label='Raw Material Type'
            labelId='raw-mat-type'
            placeholder='Raw Material Type'
            name='Raw Material Type'
            id='copyBom-raw-mat-type-input'
            onChange={handleRawMaterialsSelect}
            value={rawMaterial}
          >
            {materials.map((option) => 
              <MenuItem key={option.id} value={option.raw_material} >
                {option.raw_material}
              </MenuItem>)};
          </Select>
          <Autocomplete
            id="copyBom-autocomplete-bottom-panel"
            options={filteredParts}
            getOptionLabel={(options) => `${options.num} - ${options.description}`}
            onSelect={handlePartSelect}
            renderInput={(params) => 
              <TextField {...params} label="Part Number" id='sku-auto-complete'/>
            }
          />
          <TextField
            variant='outlined'
            label='Depth (in inches)'
            value={depth || ""}
            onChange={handleDepthChange}
          />
        </Box>
        <Box>
          <FormControlLabel
            label='Is this layer convoluted?'
            control={
              <Switch
                checked={convoluted}
                onChange={handleConvolutedChange}  
              />}  
          />
          <FormControlLabel
            label='Is this layer a topper?'
            control={
              <Switch
                checked={topper}
                onChange={handleTopperChange} 
              />}  
          />
        </Box>
        {convoluted ? 
          <Box className='copyBom-convoluted-box' >
            <Typography variant="body1" >
              The Actual Depth is half (.5) of the Starting Material Depth.
              <br/>
              Please make sure the Depth of the this core layer is the Nominal Depth.
            </Typography>
            <br/>
            <TextField
              label="Actual Depth (in inches)"
              name="actualDepthIn"
              variant="outlined"
              className="name-field"
              onChange={handleActualDepthChange}
              value={actualDepth || ""}
            />
          </Box>
        :
        ""
        }
      </FormControl>
      <Typography className='copyBom-inputs-warning'>
        Make sure you have selected material type, part number and enter depth before hitting "add layer"
      </Typography>
      <Box className='layer-btn-box'>
        <Button
          variant='contained' 
          className="copyBom-cancel-layer-btn" 
          onClick={() => setCoreData(prevState => ({ ...prevState, showNewLayer: !prevState }))}
        >
          <CancelPresentationIcon style={{verticalAlign: "middle"}}/> Cancel Layer
        </Button>
        <Button
          variant='contained'
          className='copyBom-add-layer-btn'
          onClick={() => addNewLayer()}
        >
          <AddBoxIcon  style={{verticalAlign: "middle"}} /> add layer
        </Button>
      </Box>
    </Box>
  )
}

CopyBomLayersForm.propTypes = {
  layers: T.array,
  rawMaterialsArray: T.array,
  coreParts: T.array,
}

export default CopyBomLayersForm