import {LOGIN_UPDATE_FIELD, LOGIN_SUBMIT_REQUEST, LOGIN_SUBMIT_SUCCESS, LOGIN_SUBMIT_FAILURE, PASSWORD_CHANGE_REQUEST, PASSWORD_CHANGE_SUCCESS, PASSWORD_CHANGE_FAILURE, CLEAR_USER} from '../constants/loginTypes';

const initialState = {
    isError: false,
    isLoading: false,
    status: '',
    username: '',
    password: '',
    userRole: localStorage.getItem('userRole') != null ? localStorage.getItem('userRole').split(",") : '',
    userId: localStorage.getItem('userId'),
    firstName: localStorage.getItem('firstName'),
    lastName: localStorage.getItem('lastName'),
    employeeNum: localStorage.getItem('employeeNum'),
    changePwdReq: false,
    loginResponse:  {'token': localStorage.getItem('token')},
}

export default function loginFormReducer(state = initialState, action) {

    switch (action.type) {
        case LOGIN_SUBMIT_REQUEST:
            return {
                ...state,
                isLoading: false,
            }
        case LOGIN_SUBMIT_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('userId', action.payload.user_id);
            localStorage.setItem('firstName', action.payload.first_name);
            localStorage.setItem('lastName', action.payload.last_name);
            localStorage.setItem('employeeNum', action.payload.employee_number);
            localStorage.setItem('userRole', JSON.parse(JSON.stringify(action.payload.roles.map(r => r.name))));
            localStorage.setItem('aws_username', action.payload.aws_username);
            return {
                ...state,
                isLoading: false,
                isError: false,
                status: '',
                username: '',
                password: '',
                changePwdReq: action.payload.change_pwd_reqd,
                loginResponse: {token: action.payload.token},
                userId: localStorage.getItem('userId'),
                userRole: localStorage.getItem('userRole').split(","),
                active: action.payload.active,
                firstName: localStorage.getItem('firstName'),
                lastName: localStorage.getItem('lastName'),
                employeeNum: localStorage.getItem('employeeNum'),
            }
        case PASSWORD_CHANGE_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('userId', action.payload.user_id);
            localStorage.setItem('aws_username', action.payload.aws_username);
            localStorage.setItem('user_role', JSON.parse(JSON.stringify(action.payload.roles.map(r => r.name))));;
            return {
                ...state,
                isLoading: false,
                isError: false,
                status: '',
                username: action.payload.username,
                password: '',
                changePwdReq: action.payload.change_pwd_reqd,
                loginResponse: {token: action.payload.token},
                userId: localStorage.getItem('userId'),
                user_role: localStorage.getItem('user_role').split(",")
            }
        case LOGIN_SUBMIT_FAILURE:
            localStorage.setItem('token', "");
            localStorage.setItem('userId', "")
            localStorage.setItem('aws_username', "")
            return {
                ...state,
                isLoading: false,
                isError: true,
                status: 'Login Failed',
                // noAccessStatus: action.payload.response.message,
                loginResponse: {token: ''}
            }
        case PASSWORD_CHANGE_FAILURE:
            localStorage.setItem('token', "");
            localStorage.setItem('userId', "")
            localStorage.setItem('aws_username', "")
            return {
                ...state,
                isLoading: false,
                isError: true,
                status: 'Login Failed',
                loginResponse: {token: ''}
            }
        case PASSWORD_CHANGE_REQUEST:
            return {
                ...state,
                isLoading: false,
                isError: false,
                status: '',
                password: '',
                changePwdReq: true,
            }
        case LOGIN_UPDATE_FIELD:
            return {
                ...state,
                [action.name]: action.value,
            }
        case CLEAR_USER:
            return {
                isError: false,
                isLoading: false,
                status: '',
                username: '',
                password: '',
                userRole: '',
                userId: '',
                changePwdReq: false,
                loginResponse: '',
            }
        default:
            return state;
    }
}