import * as types from "../constants/coreTypes";
import { RSAA } from "redux-api-middleware";
import { removeEmptyFields } from "../utils/object";
import { API_ROOT } from "../utils/api";
import { CORE_NEW_SUCCESS } from "../constants/coreTypes";

export function updateCoreField(name, value) {
  return {
    type: types.CORE_UPDATE_FIELD,
    name,
    value
  };
}

export function addLayer(layer, layers) {
  return {
    type: types.CORE_ADD_LAYER,
    layers: layers.concat(layer)
  };
}

export function getCores() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/cores`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.CORES_LOADING_REQUEST,
        types.CORES_LOADING_SUCCESS,
        types.CORES_LOADING_ERROR
      ]
    }
  };
}

export function getCoresBySize(sizeId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/cores/${sizeId}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.CORES_LOADING_REQUEST,
        types.CORES_LOADING_SUCCESS,
        types.CORES_LOADING_ERROR
      ]
    }
  };
}

export function getCore(id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/core/${id}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.CORE_LOADING_REQUEST,
        types.CORE_LOADING_SUCCESS,
        types.CORE_LOADING_ERROR
      ]
    }
  };
}

export function saveCoreList(cores) {
  const data = [];
  for (let field in cores.data) {
    let row = removeEmptyFields(cores.data[field]);
    data.push(row);
  }
  const saveObj = {
    data: data
  };
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/cores`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(saveObj),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.CORES_SAVING_REQUEST,
        types.CORES_SAVING_SUCCESS,
        types.CORES_SAVING_ERROR
      ]
    }
  };
}

export function saveCore(core) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/core`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(core),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.CORE_SAVING_REQUEST,
        types.CORE_SAVING_SUCCESS,
        types.CORE_SAVING_ERROR
      ]
    }
  };
}
export function updateCoreById(core) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/core/${core.id}`, // TODO: make base URL constant
      method: "PUT",
      body: JSON.stringify(core),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.UPDATE_CORE_SAVING_REQUEST,
        types.UPDATE_CORE_SAVING_SUCCESS,
        types.UPDATE_CORE_SAVING_ERROR
      ]
    }
  };
}