
import {
    PARENT_CORE_UPDATE_FIELD,
    PARENT_CORE_LOADING_REQUEST,
    PARENT_CORE_LOADING_SUCCESS,
    PARENT_CORE_LOADING_ERROR,
    PARENT_CORE_SAVING_REQUEST,
    PARENT_CORE_SAVING_SUCCESS,
    PARENT_CORE_SAVING_ERROR,
    PARENT_CORES_LOADING_ERROR,
    PARENT_CORES_LOADING_REQUEST,
    PARENT_CORES_LOADING_SUCCESS,
    PARENT_CORES_SAVING_ERROR,
    PARENT_CORES_SAVING_REQUEST,
    PARENT_CORES_SAVING_SUCCESS
} from '../constants/parentCoreTypes';

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";


import {parseAllToJson} from "../utils/string"
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    id: -1,
    parentCore: '',
    parentCores: [],
    columns: [],
    rows: [],
}

export default function coreFirmnessReducer(state = initialState, action) {
    switch (action.type) {
        case PARENT_CORE_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case PARENT_CORES_LOADING_REQUEST:
        case PARENT_CORES_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case PARENT_CORE_LOADING_REQUEST:
        case PARENT_CORE_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case PARENT_CORE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                parentCore: action.payload.parent_core,
            }
        case PARENT_CORE_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: null,
                parentCore: '',
            }
        case PARENT_CORES_LOADING_ERROR:
        case PARENT_CORES_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case PARENT_CORE_LOADING_ERROR:
        case PARENT_CORE_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case PARENT_CORES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                parentCores: action.payload,
            }
        case PARENT_CORES_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                parentCores: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}