import * as types from '../constants/batchTypes'
import { RSAA } from 'redux-api-middleware'
import {API_ROOT} from "../utils/api";

export function getBatches() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/batches`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.BATCHES_LOADING_REQUEST,
                types.BATCHES_LOADING_SUCCESS,
                types.BATCHES_LOADING_ERROR
            ]
        },
    }
}
export function updateBatches(batch) {
    return {
      [RSAA]: {
        endpoint: `${API_ROOT}/update-batch`, // TODO: make base URL constant
        method: "PUT",
        body: JSON.stringify(batch),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        options: {
          mode: "cors"
        },
        types: [
            types.BATCHE_UPDATE_FIELD,
            types.BATCHES_LOADING_SUCCESS,
            types.BATCHES_LOADING_ERROR
        ]
      }
    };
  }
export function createBatch(batch) {
    return {
      [RSAA]: {
        endpoint: `${API_ROOT}/create-batch`, // TODO: make base URL constant
        method: "POST",
        body: JSON.stringify(batch),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        options: {
          mode: "cors"
        },
        types: [
            types.BATCHE_UPDATE_FIELD,
            types.BATCHES_LOADING_SUCCESS,
            types.BATCHES_LOADING_ERROR
        ]
      }
    };
  }
export function deleteBatch(batch) {
    return {
      [RSAA]: {
        endpoint: `${API_ROOT}/delete-batch/${batch.id}`, // TODO: make base URL constant
        method: "DELETE",
        body: JSON.stringify(batch),

        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        options: {
          mode: "cors"
        },
        types: [
            types.BATCHE_DELETING_REQUEST,
            types.BATCHE_DELETING_SUCCESS,
            types.BATCHE_DELETING_ERROR
        ]
      }
    };
  }