import * as types from "../constants/skuTypes";
import { RSAA } from "redux-api-middleware";
import { removeEmptyFields } from "../utils/object";
import { API_ROOT } from "../utils/api";

export function updateField(name, value) {
  return {
    type: types.SKU_UPDATE_FIELD,
    name,
    value
  };
}

//reset sku
export function resetSKU() {
  return {
    type: types.SKU_RESET
  };
}

// Update modal for the SKU form's Panels to close/open
export function updateCloseModal() {
  return {
    type: types.SKU_UPDATE_MODAL_CLOSED,
    openModal: false,
  }
}

export function updateOpenModal() {
  return {
    type: types.SKU_UPDATE_MODAL_OPEN,
    openModal: true,
  }
}

export function updateIdentifier(identifier) {
  return {
    type: types.SKU_UPDATE_IDENTIFIER,
    identifier: identifier
  };
}

export function setTopPanel() {
  return {
    type: types.SKU_SET_TOP,
    isTopPanel: true,
    isGussetPanel: false,
    isSidePanel: false,
    isBottomPanel: false,
  }
}

export function setGussetPanel() {
  return {
    type: types.SKU_SET_GUSSET,
    isTopPanel: false,
    isGussetPanel: true,
    isSidePanel: false,
    isBottomPanel: false,
  }
}

export function setSidePanel() {
  return {
    type: types.SKU_SET_SIDE,
    isTopPanel: false,
    isGussetPanel: false,
    isSidePanel: true,
    isBottomPanel: false,
  }
}

export function setBottomPanel() {
  return {
    type: types.SKU_SET_BOTTOM,
    isTopPanel: false,
    isGussetPanel: false,
    isSidePanel: false,
    isBottomPanel: true,
  }
}

//update sku Attribute list that only has boxes on it right now
export function updateSkuAttribute(skuAttribute) {
  return {
    type: types.SKU_UPDATE_SKU_ATTRIBUTE,
    skuAttribute: skuAttribute
  };
}

//update the topPanel
export function updateSkuTopPanel(topPanel, topPanelLayers) {
  return {
    type: types.SKU_UPDATE_TOP_PANEL,
    topPanel: topPanel,
    topPanelLayers: topPanelLayers
  };
}
export function updateSkuGussetPanel(gussetPanel, gussetPanelLayers) {
  return {
    type: types.SKU_UPDATE_GUSSET_PANEL,
    gussetPanel: gussetPanel,
    gussetPanelLayers: gussetPanelLayers
  };
}
export function updateSkuSidePanel(sidePanel, sidePanelLayers) {
  return {
    type: types.SKU_UPDATE_SIDE_PANEL,
    sidePanel: sidePanel,
    sidePanelLayers: sidePanelLayers
  };
}
export function updateSkuBottomPanel(bottomPanel, bottomPanelLayers) {
  return {
    type: types.SKU_UPDATE_BOTTOM_PANEL,
    bottomPanel: bottomPanel,
    bottomPanelLayers: bottomPanelLayers
  };
}

export function updateSkuFamily(name, family) {
  return {
    type: types.SKU_UPDATE_FAMILY,
    familyName: name,
    family: family
  };
}

export function updateSkuBatch(batchId) {
  return {
    type: types.SKU_UPDATE_BATCH,
    batchId: batchId
  };
}

//new to updated firmness to what is selected in dropdown

export function updateSkuFirmness(name, firmness) {
  return {
    type: types.SKU_UPDATE_FIRMNESS,
    firmnessName: name,
    firmness: firmness
  };
}
//new to update the parentCore to what is selected in dropdown
export function updateSkuParentCore(name, parentCore) {
  return {
    type: types.SKU_UPDATE_PARENT_CORE,
    parentCoreName: name,
    parentCore: parentCore
  };
}
export function updateSkuCore(core) {
  return {
    type: types.SKU_UPDATE_CORE,
    // coreName: name,
    core: core
  };
}

export function updateMaterialGroup(value, group) {
  return {
    type: types.SKU_MATERIAL_GROUP_UPDATE,
    ltMaterialGroup: group,
    materialGroupName: value
  };
}

export function updateFoundationGroup(value, group) {
  return {
    type: types.SKU_FOUNDATION_GROUP_UPDATE,
    ltFoundationGroup: group,
    foundationGroupName: value
  };
}

export function updatePrototype(prototype, prototypeName) {
  return {
    type: types.SKU_PROTOTYPE_UPDATE,
    prototype: prototype,
    prototypeName: prototypeName
  };
}

export function addMaterial(material, materials) {
  return {
    type: types.SKU_ADD_MATERIAL,
    ltMaterialGroup: materials.concat(material)
  };
}
export function materialAdded(material, materials) {
  return {
    type: types.SKU_MATERIAL_ADDED
  };
}

export function addFoundation(foundation, foundations) {
  return {
    type: types.SKU_ADD_FOUNDATION,
    ltFoundationGroup: foundations.concat(foundation)
  };
}
export function foundationAdded(material, materials) {
  return {
    type: types.SKU_FOUNDATION_ADDED
  };
}

export function getActiveSkus() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/skus/active`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKUS_ACTIVE_LOADING_REQUEST,
        types.SKUS_ACTIVE_LOADING_SUCCESS,
        types.SKUS_ACTIVE_LOADING_ERROR
      ]
    }
  };
}

export function getInactiveSkus() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/skus/inactive`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKUS_INACTIVE_LOADING_REQUEST,
        types.SKUS_INACTIVE_LOADING_SUCCESS,
        types.SKUS_INACTIVE_LOADING_ERROR
      ]
    }
  };
}

export function getAllSkus() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/skus`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKUS_ALL_LOADING_REQUEST,
        types.SKUS_ALL_LOADING_SUCCESS,
        types.SKUS_ALL_LOADING_ERROR
      ]
    }
  };
}

export function getSku(id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku/${id}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_LOADING_REQUEST,
        types.SKU_LOADING_SUCCESS,
        types.SKU_LOADING_ERROR
      ]
    }
  };
}

export function saveSkuList(skus) {
  //use saveSkuList for both individual sku and sku list bc both array of obj(s)
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/skus`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(skus),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKUS_SAVING_REQUEST,
        types.SKUS_SAVING_SUCCESS,
        types.SKUS_SAVING_ERROR
      ]
    }
  };
}

export function saveSku(sku) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(sku),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_SAVING_REQUEST,
        types.SKU_SAVING_SUCCESS,
        types.SKU_SAVING_ERROR
      ]
    }
  };
}

export function getSkuGroups() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku-groups`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_GROUPS_LOADING_REQUEST,
        types.SKU_GROUPS_LOADING_SUCCESS,
        types.SKU_GROUPS_LOADING_ERROR
      ]
    }
  };
}

export function getSkuGroup(id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku-group/${id}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_GROUP_LOADING_REQUEST,
        types.SKU_GROUP_LOADING_SUCCESS,
        types.SKU_GROUP_LOADING_ERROR
      ]
    }
  };
}

export function getSkuDrafts() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku-staging`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_DRAFTS_LOADING_REQUEST,
        types.SKU_DRAFTS_LOADING_SUCCESS,
        types.SKU_DRAFTS_LOADING_ERROR
      ]
    }
  };
}

export function getSkuDraft(id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku-staging/${id}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_DRAFT_LOADING_REQUEST,
        types.SKU_DRAFT_LOADING_SUCCESS,
        types.SKU_DRAFT_LOADING_ERROR
      ]
    }
  };
}

export function saveSkuDraft(draft) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku-staging`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(draft),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_DRAFT_SAVING_REQUEST,
        types.SKU_DRAFT_SAVING_SUCCESS,
        types.SKU_DRAFT_SAVING_ERROR
      ]
    }
  };
}

export function deleteDraft(id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku-staging/${id}`, // TODO: make base URL constant
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_DRAFT_DELETE_REQUEST,
        types.SKU_DRAFT_DELETE_SUCCESS,
        types.SKU_DRAFT_DELETE_ERROR
      ]
    }
  };
}

export function getSkuLabor() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku-labor`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKUS_ALL_LOADING_REQUEST,
        types.SKUS_ALL_LOADING_SUCCESS,
        types.SKUS_ALL_LOADING_ERROR
      ]
    }
  };
}
export function updateSkuLabor(skuLabor) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/update-sku-labor`, // TODO: make base URL constant
      method: "PUT",
      body: JSON.stringify(skuLabor),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_SAVING_REQUEST,
        types.SKU_SAVING_SUCCESS,
        types.SKU_SAVING_ERROR
      ]
    }
  };
}
export function createSkuLabor(skuLabor) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/create-sku-labor`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(skuLabor),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_SAVING_REQUEST,
        types.SKU_SAVING_SUCCESS,
        types.SKU_SAVING_ERROR
      ]
    }
  };
}

export function getSkuShipping() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku-shipping`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKUS_ALL_LOADING_REQUEST,
        types.SKUS_ALL_LOADING_SUCCESS,
        types.SKUS_ALL_LOADING_ERROR
      ]
    }
  };
}
export function updateSkuShipping(skuLabor) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/update-sku-shipping`, // TODO: make base URL constant
      method: "PUT",
      body: JSON.stringify(skuLabor),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_SAVING_REQUEST,
        types.SKU_SAVING_SUCCESS,
        types.SKU_SAVING_ERROR
      ]
    }
  };
}
export function createSkuShipping(skuLabor) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/create-sku-shipping`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(skuLabor),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_SAVING_REQUEST,
        types.SKU_SAVING_SUCCESS,
        types.SKU_SAVING_ERROR
      ]
    }
  };
}
export function getCoreSkuByFishbowlPart(id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/core-skus-by-part/${id}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SKU_FISHBOWL_PART_LOADING_REQUEST,
        types.SKU_FISHBOWL_PART_LOADING_SUCCESS,
        types.SKU_FISHBOWL_PART_LOADING_ERROR
      ]
    }
  };
}
export function getSkuByFishbowlPartPanelLayer(id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/panel_layer-skus-by-part/${id}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [ 
        types.SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_REQUEST,
        types.SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_SUCCESS,
        types.SKU_FISHBOWL_PART_PANEL_LAYER_LOADING_ERROR
      ]
    }
  };
}
export function triggerBOM() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/trigger-build_bom`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [ 
        types.TRIGGER_BOM_REQUEST,
        types.TRIGGER_BOM_SUCCESS,
        types.TRIGGER_BOM_ERROR
      ]
    }
  };
}