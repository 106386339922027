
import {
    SIZE_UPDATE_FIELD,
    SIZE_LOADING_REQUEST,
    SIZE_LOADING_SUCCESS,
    SIZE_LOADING_ERROR,
    SIZE_SAVING_REQUEST,
    SIZE_SAVING_SUCCESS,
    SIZE_SAVING_ERROR,
    SIZES_LOADING_ERROR,
    SIZES_LOADING_REQUEST,
    SIZES_LOADING_SUCCESS,
    SIZES_SAVING_ERROR,
    SIZES_SAVING_REQUEST,
    SIZES_SAVING_SUCCESS
} from '../constants/sizeTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    sizes: [],
    columns: [],
    rows: [],
    id: -1,
    width: '',
    length: '',
    sizeAlias: '',
}

export default function sizeReducer(state = initialState, action) {
    switch (action.type) {
        case SIZE_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case SIZES_LOADING_REQUEST:
        case SIZES_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case SIZE_LOADING_REQUEST:
        case SIZE_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case SIZE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                size: action.payload.size,
                width: action.payload.width_in,
                length: action.payload.length_in,
                sizeAlias: action.payload.alias
            }
        case SIZE_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: null,
                size: '',
                width: '',
                length: '',
                sizeAlias: ''
            }
        case SIZES_LOADING_ERROR:
        case SIZES_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case SIZE_LOADING_ERROR:
        case SIZE_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case SIZES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                sizes: action.payload,
            }
        case SIZES_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                sizes: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}