import * as types from '../constants/brandTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";
import {BRAND_NEW_SUCCESS} from "../constants/brandTypes";


export function updateField(name, value) {
    return {
        type: types.BRAND_UPDATE_FIELD,
        name,
        value,
    }
}

export function getBrands() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/brands`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.BRANDS_LOADING_REQUEST,
                types.BRANDS_LOADING_SUCCESS,
                types.BRANDS_LOADING_ERROR,
            ],
        },
    }
}

export function getBrand(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/brand/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.BRAND_LOADING_REQUEST,
                types.BRAND_LOADING_SUCCESS,
                types.BRAND_LOADING_ERROR,
            ],
        },
    }
}

export function saveBrandList(brands){
    const data = []
    for (let field in brands.data) {
        let row = removeEmptyFields(brands.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/brands`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.BRANDS_SAVING_REQUEST,
                types.BRANDS_SAVING_SUCCESS,
                types.BRANDS_SAVING_ERROR,
            ],
        },
    }
}

export function saveBrand(brand){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/brand`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(brand),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.BRAND_SAVING_REQUEST,
                types.BRAND_SAVING_SUCCESS,
                types.BRAND_SAVING_ERROR,
            ],
        },
    }
}