import * as types from "../constants/commissionTypes";
import { RSAA } from "redux-api-middleware";
import { API_ROOT } from "../utils/api";

export function getCommissions() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/commissions`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.COMMISSIONS_LOADING_REQUEST,
        types.COMMISSIONS_LOADING_SUCCESS,
        types.COMMISSIONS_LOADING_ERROR
      ]
    }
  };
}
export function createCommission(commission) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/commission`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(commission),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.COMMISSIONS_LOADING_REQUEST,
        types.COMMISSIONS_LOADING_SUCCESS,
        types.COMMISSIONS_LOADING_ERROR
      ]
    }
  };
}
export function updateCommission(commission) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/commission`, // TODO: make base URL constant
      method: "PUT",
      body: JSON.stringify(commission),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.COMMISSIONS_LOADING_REQUEST,
        types.COMMISSIONS_LOADING_SUCCESS,
        types.COMMISSIONS_LOADING_ERROR
      ]
    }
  };
}
