import React from 'react';
import DealerList from './List/DealerList';
import '../styles/about-page.css';

export class DealerListPage extends React.Component {

    render() {
        return (
            <DealerList/>
        );
    }
}

export default DealerListPage;
