import React from 'react';
import SizeList from './List/SizeList';
import '../styles/about-page.css';

export class SizeListPage extends React.Component {

    render() {
        return (
            <SizeList/>
        );
    }
}

export default SizeListPage;
