import React from 'react'
import '../styles/about-page.css'
import UserForm from './forms/UserForm'

const UserFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">User</h2>
            <UserForm location={location}/>
        </div>
    )
}

export default UserFormPage