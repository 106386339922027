import React, { useState, useEffect } from "react";
import { Box, Card, Modal, Typography } from "@material-ui/core";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";

const SkuModal = ({ value, sucess, modalType, ...props }) => {
  const [open, setOpen] = useState(false);
  const [sucessFields, setSucessFields] = useState([]);
  useEffect(() => {
    setOpen(value);
    setSucessFields(sucess);
  }, [value, sucess]);

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };
  const sucessStyle = {
    backgroundColor: "#3FAFB5"
  };
  const errorStyle = {
    backgroundColor: "#cc0000"
  };
  return (
    <>
      {sucessFields ? (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style}>
              <Card
                border={false}
                style={{ overflow: "auto", height: "335px", width: "500px" }}
              >
                <div
                  className="sku-modal-title"
                  style={
                    modalType == "sucess"
                      ? sucessStyle
                      : modalType == "error"
                      ? errorStyle
                      : null
                  }
                >
                  {modalType == "sucess" ? (
                    <Typography
                      className="modal-typo-title"
                      variant="h5"
                      component="h2"
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        size="3x"
                        color="white"
                      />
                    </Typography>
                  ) : modalType == "error" ? (
                    <Typography
                      className="modal-typo-title"
                      variant="h5"
                      component="h2"
                    >
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        size="3x"
                        color="white"
                      />
                    </Typography>
                  ) : (
                    ""
                  )}
                </div>
                {sucessFields.map((field) => (
                  <div className="modal-description">
                    <Typography id="modal-modal-title" variant="h3">
                      {field.title}
                    </Typography>
                    <Typography className="modal-typo-description">
                      {field.body}
                    </Typography>
                  </div>
                ))}
              </Card>
            </Box>
          </Modal>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SkuModal;
