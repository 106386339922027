import React, { useState, useEffect }  from "react"
import T from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import {getFamilies, saveFamilyList} from "../../actions/familyActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";
import Can from "../Can";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

function FamilyList(props) {
  const { isLoading, role, families, columns, history} = props
  const dispatch = useDispatch();
  const options = (data) => {
    const options = {
      filterType: 'multiselect',
      responsive: 'standard',
      fixedHeader: true,
      rowsPerPage: 100,
      selectableRowsHeader: false,
      selectableRows: 'none',
      customToolbar: () => {
        return (
          <Can
            role={role}
            perform='sku:edit'
            yes={() => (
              <AddNewButton
                entity={"family/-1"}
                history={history}
                data={data}
              />
            )}
            no={() => null}
          />
        )
      }
    }
    return options
  }
  const family = () => {
    dispatch(getFamilies())
    dispatch(getColumns("family"))
  };
  useEffect(() => {
    family()
    
  },[]);
  return (

    <div className="baseContainer familyList">
      <h1>
      </h1>
      <MUIDataTable
        title={"Families"}
        data={families}
        columns={columns.map(c => ({ ...c, ...defaultColumnProperties}))}
        options={options(families)}
      />
      <Loader isLoading={isLoading}/>
    </div>
  );
}

FamilyList.defaultProps = {
    date: getDBFormattedDate()
}

FamilyList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    families: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { familyReducer, loginForm } = state
    return {
        isLoading: familyReducer.isLoading,
        families: familyReducer.families,
        rows: familyReducer.rows,
        cols: familyReducer.cols,
        columns: familyReducer.columns,
        file: familyReducer.file,
        role: loginForm.userRole
    }
}

export default withRouter(connect(mapStateToProps)(FamilyList))