import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import {
  Box,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faSave,
  faPlus,
  faPenAlt,
  faArchive
} from "@fortawesome/free-solid-svg-icons";
import XLSX from "xlsx";
import moment from "moment";
import MaterialTable from "@material-table/core";
import "./style.css";
import { useDispatch } from "react-redux";
import {
  createCampaign,
  getCampaign,
  createStoreProcedure,
  createForecast,
  getSubchannel,
  createCurve,
  spNewPlanFromLast,
  junkStoreProcedure,
  createUpload
} from "../../actions/forecast/forecastActions";
import ForecastModal from "./forecastModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CampagineCurve from "./CampaginCurve";
import Forecast from "./Forecast";
import "./style.css";

const EXTENTIONS = ["xlsx", "xls", "cvs"];
const TYPE = [
  { label: "Campaign Forecast", value: 1 },
  { label: "Campaign Curve", value: 2 }
];
const initialState = "";

const ForecastWorkbookUpload = () => {
  const [fileName, setFileName] = useState("");
  const [campaignColDefs, setCampaignColDefs] = useState();
  const [curveColDefs, setCurveColDefs] = useState();
  const [forecastColDefs, setForecastColDefs] = useState();
  const [curve, setCurve] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [curveData, setCurveData] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [tabValue, setTabValue] = useState(1);
  const isAuthenticated = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [sucessValue, setSucessValue] = useState(false);
  const [sucess, setSucess] = useState("");
  const [campaigns, setCampaigns] = useState(null);
  const [error, setError] = useState("");
  const [missingDates, setMissingDates] = useState("");
  const [subchannel, setSubchannel] = useState([]);
  const [forecast, setForecast] = useState([]);
  const [sumError, setSumError] = useState("");
  const [subError, setSubError] = useState("");
  const [newCurve, setNewCurve] = useState(false);
  const [updateExisting, setUpdateExisting] = useState(false);
  const [campaignError, setCampaignError] = useState("");
  const [campaignNameError, setCampaignNameError] = useState("");
  const [redirect, setRedirect] = useState(null);
  const [notArchived, setNotArchived] = useState(true);
  const [blankFieldError, setBlankFieldError] = useState("");
  const [loading, setLoading] = useState(false);
  const [archiveObj, setArchiveObj] = useState(false);

  const Input = styled("input")({
    display: "none"
  });
  const selectedTabStyle = {
    backgroundColor: "#2894c3",
    color: "#FFFFFF",
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: "16px"
  };
  const unselectedTabStyle = {
    backgroundColor: "rgba(0,0,0,0.0)",
    fontSize: "16px"
  };

  useEffect(() => {
    dispatch(getSubchannel()).then((res) => {
      setSubchannel(res.payload);
    });
    dispatch(getCampaign()).then((res) => {
      setCampaigns(res.payload);
      wasLastPlanArchived(res.payload);
      setArchiveObj(false);
    });
  }, [error, campaignError, sucess, archiveObj]);

  useEffect(() => {
    setCampaignData([]);
    setCurveData([]);
    setForecastData([]);
    setFileName("");
    dispatch(getCampaign()).then((res) => {
      setCampaigns(res.payload);
      wasLastPlanArchived(res.payload);
    });
  }, [
    error,
    sumError,
    missingDates,
    subError,
    campaignError,
    campaignNameError,
    archiveObj
  ]);

  function archive(active) {
    dispatch(createStoreProcedure()).then((res) => {
      if (res.type === "FORECAST_LOADING_SUCCESS") {
        if (active === 2) {
          reset();
        }
      }
    });
  }
  function archiveJunkError() {
    dispatch(junkStoreProcedure()).then((res) => {
      if (res.type === "FORECAST_LOADING_SUCCESS") {
      }
    });
  }

  function startFromLastArchive() {
    dispatch(spNewPlanFromLast()).then((res) => {
      if (res.type === "FORECAST_LOADING_SUCCESS") {
        reset();
        setRedirect(null);
        setSucessValue(true);
        setSucess([
          { title: "SUCESS!" },
          {
            body: "You have sucessfully archived and created a new forecast plan! You will be redirected and able to update your new forecast!"
          }
        ]);
        setUpdateExisting(true), setNewCurve(false);
      }
    });
  }
  async function wasLastPlanArchived(camp) {
    setNotArchived(true);
    await camp.find((campaign) => {
      if (moment(campaign.as_of_date).format("YYYY-MM-DD") == "9999-01-01") {
        setNotArchived(false);
      }
    });
  }

  const reset = () => {
    setError(initialState);
    setSumError(initialState);
    setMissingDates(initialState);
    setSubError(initialState);
    setCampaignNameError(initialState);
    setBlankFieldError(initialState);
    setCampaignError(initialState);
  };

  const getExtention = (file) => {
    const parts = file.name.split(".");
    const extention = parts[parts.length - 1];
    return EXTENTIONS.includes(extention);
  };

  const convertToJson = (campaignHeaders, campaignFileData) => {
    if (campaignFileData) {
      let campaignRows = [];
      campaignFileData.forEach((row) => {
        let rowData = {};
        row.forEach((element, index) => {
          rowData[campaignHeaders[index]] = element;
        });

        let newDateStart = new Date(
          Math.round((rowData.date_start - 25569) * 864e5)
        );
        newDateStart.setMinutes(
          newDateStart.getMinutes() + newDateStart.getTimezoneOffset()
        );
        let newDateEnd = new Date(
          Math.round((rowData.date_end - 25569) * 864e5)
        );
        newDateEnd.setMinutes(
          newDateEnd.getMinutes() + newDateEnd.getTimezoneOffset()
        );
        let newRow = {
          name: rowData.name,
          description: rowData.description,
          date_start:
            rowData.date_start == ""
              ? rowData.date_start
              : moment(newDateStart).format("YYYY-MM-DD"),
          date_end:
            rowData.date_end == ""
              ? rowData.date_end
              : moment(newDateEnd).format("YYYY-MM-DD"),
          as_of_date: moment("9999-01-01").format("YYYY-MM-DD")
        };
        campaignRows.push(newRow);
      });
      setCampaignData(campaignRows);
    }
  };
  const convertCurveToJson = (curveHeaders, curveFileData) => {
    if (curveFileData) {
      let curveRows = [];
      curveHeaders, curveFileData;
      curveFileData.forEach((row) => {
        let rowData = {};
        row.forEach((element, index) => {
          rowData[curveHeaders[index]] = element;
        });
        if (curveFileData.length) {
          let newDate = new Date(Math.round((rowData.date - 25569) * 864e5));
          newDate.setMinutes(
            newDate.getMinutes() + newDate.getTimezoneOffset()
          );
          let newRow = {
            campaign_name: rowData.campaign_name,
            date:
              rowData.date == ""
                ? rowData.date
                : moment(newDate).format("YYYY-MM-DD"),
            curve: rowData.curve,
            as_of_date: moment("9999-01-01").format("YYYY-MM-DD")
          };
          curveRows.push(newRow);
        } else {
          const formatYmd = (date) => date.toISOString().slice(0, 10);
          let newRow = {
            campaign_name: rowData.campaign_name,
            date: formatYmd(new Date(rowData.date)),
            curve: rowData.curve,
            as_of_date: moment("9999-01-01").format("YYYY-MM-DD")
          };
          curveRows.push(newRow);
        }
      });
      setCurveData(curveRows);
    }
  };
  const convertForecastToJson = (forecastHeaders, forecastFileData) => {
    if (forecastFileData) {
      let forecastRows = [];
      forecastFileData.forEach((row) => {
        let rowData = {};
        row.forEach((element, index) => {
          rowData[forecastHeaders[index]] = element;
        });
        let newRow = {
          campaign_id: rowData.campaign_id,
          subchannel_id: rowData.subchannel_id,
          gross_revenue: rowData.gross_revenue,
          gm_rate: rowData.gm_rate,
          return_rate: rowData.return_rate,
          marketing_rate: rowData.marketing_rate,
          variable_rate: rowData.variable_rate,
          mattress_rate: rowData.mattress_rate,
          mattress_aur: rowData.mattress_aur
        };
        forecastRows.push(newRow);
      });
      setForecastData(forecastRows);
    }
  };
  const importExcel = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setFileName(file.name);
    reader.onload = (event) => {
      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: "binary" });
      const campaignWorkSheetName = workBook.SheetNames[2];
      const curveWorkSheetName = workBook.SheetNames[3];
      const forecastWorkSheetName = workBook.SheetNames[4];
      const campaignWorkSheet = workBook.Sheets[campaignWorkSheetName];
      const cueveWorkSheet = workBook.Sheets[curveWorkSheetName];
      const forecastWorkSheet = workBook.Sheets[forecastWorkSheetName];
      const campaignFileData = XLSX.utils.sheet_to_json(campaignWorkSheet, {
        header: 1
      });
      const curveFileData = XLSX.utils.sheet_to_json(cueveWorkSheet, {
        header: 1
      });
      const forecastFileData = XLSX.utils.sheet_to_json(forecastWorkSheet, {
        header: 1
      });

      const campaignHeaders = campaignFileData[0];
      const curveHeaders = curveFileData[0];
      const forecastHeaders = forecastFileData[0];

      const campaingnHeads = campaignHeaders.map((head) => ({
        title: head,
        field: head
      }));
      const curveHeads = curveHeaders.map((head) => ({
        title: head,
        field: head
      }));
      const forecastHeads = forecastHeaders.map((head) => ({
        title: head,
        field: head
      }));

      setCampaignColDefs(campaingnHeads);
      setCurveColDefs(curveHeads);
      setForecastColDefs(forecastHeads);

      campaignFileData.splice(0, 1);
      curveFileData.splice(0, 1);
      forecastFileData.splice(0, 1);

      convertToJson(campaignHeaders, campaignFileData);
      convertCurveToJson(curveHeaders, curveFileData);
      convertForecastToJson(forecastHeaders, forecastFileData);
    };
    if (file) {
      if (getExtention(file)) {
        reader.readAsBinaryString(file);
      } else {
        alert("Invalid file input, Select Excel, CSV file");
      }
    }
  };
  function getTabDetails(tabName) {
    if (tabName == 1) {
      return (
        <div className="campaign-container">
          <Grid>
            {campaignData ? (
              <MaterialTable
                options={{
                  rowStyle: {
                    fontSize: 16
                  }
                }}
                title="Campaign"
                data={campaignData}
                columns={campaignColDefs}
              />
            ) : (
              "Loading"
            )}
          </Grid>
        </div>
      );
    } else if (tabName == 2) {
      return (
        <div className="campaign-container">
          <Grid item>
            {curveData ? (
              <MaterialTable
                options={{
                  rowStyle: {
                    fontSize: 16
                  }
                }}
                fullWidth
                title="Curve"
                data={curveData}
                columns={curveColDefs}
              />
            ) : (
              "Loading"
            )}
          </Grid>
        </div>
      );
    } else if (tabName == 3) {
      return (
        <div className="campaign-container">
          <Grid item>
            {forecastData ? (
              <MaterialTable
                options={{
                  rowStyle: {
                    fontSize: 16
                  }
                }}
                fullWidth
                title="Forecast"
                data={forecastData}
                columns={forecastColDefs}
              />
            ) : (
              "Loading"
            )}
          </Grid>
        </div>
      );
    }
  }

  function forecastUpload(campaign, uploadId) {
    let data = forecastData;
    let subChannelError = "";
    let campaignError = "";
    let blankFields = "";
    let uploadData = [];
    data.filter((obj) => {
      let str = obj.subchannel_id.slice(2, 5);
      subchannel.find((sub) => {
        if (sub.short_name == str) {
          obj["subchannel_id"] = sub.id;
        }
      });
      if (typeof obj.subchannel_id === "string") {
        subChannelError = (
          <div>
            <b>Invalid subchannel_id </b>
          </div>
        );
        setSubError(subChannelError);
      }
    });

    let campaignNotAssigned = data.filter(
      (nd) => !campaigns.find((cp) => cp.name === nd.campaign_id)
    );
    if (campaignNotAssigned.length > 0) {
      campaignError = (
        <div>
          <b>Campaign Id Not Found</b>
        </div>
      );
      setCampaignError(campaignError);
    }
    if (campaignError) {
      setError(campaignError);
      window.scrollTo(0, 0);
      archiveJunkError();
      return false;
    }

    if (subChannelError) {
      window.scrollTo(0, 0);
      archiveJunkError();
      return false;
    }

    if (campaign) {
      data.filter((obj) => {
        if (
          obj.campaign_id === undefined ||
          obj.subchannel_id === undefined ||
          obj.gross_revenue === undefined ||
          obj.gm_rate === undefined ||
          obj.return_rate === undefined ||
          obj.marketing_rate === undefined ||
          obj.variable_rate === undefined ||
          obj.mattress_rate === undefined ||
          obj.mattress_aur === undefined
        ) {
          blankFields = (
            <div>
              <b>
                Fields can not be left blank when updating a forecast. Please
                enter 0 if there is no value.
              </b>
            </div>
          );
        }
        campaign.filter((camp) => {
          if (camp.as_of_date === "9999-01-01") {
            if (camp.name === obj.campaign_id) {
              obj["campaign_id"] = camp.id;
            }
          }
        });
        let newObj = {
          campaign_id: obj.campaign_id,
          subchannel_id: obj.subchannel_id,
          gross_revenue: obj.gross_revenue,
          gm_rate: obj.gm_rate,
          return_rate: obj.return_rate,
          marketing_rate: obj.marketing_rate,
          variable_rate: obj.variable_rate,
          mattress_rate: obj.mattress_rate,
          mattress_aur: obj.mattress_aur,
          as_of_date: moment("9999-01-01").format("YYYY-MM-DD"),
          upload_id: uploadId.id
        };
        uploadData.push(newObj);
      });
      if (blankFields) {
        setBlankFieldError(blankFields);
        window.scrollTo(0, 0);
        archiveJunkError();
        return false;
      }
      if (uploadData.length) {
        dispatch(createForecast(uploadData)).then((res) => {
          if (res.type === "FORECAST_LOADING_SUCCESS") {
            setLoading(false);
            setSucessValue(true);
            setSucess([
              { title: "Sucess!!" },
              {
                body: "You had a sucessful upload!"
              }
            ]);
          }
        });
      }
    }
  }
  const createUploadStamp = async () => {
    let data = {
      timestamp: moment().format("YYYY-MM-DD, h:mm:ss"),
      user_id: isAuthenticated
    };

    await dispatch(createUpload(data)).then((res) => {
      createForcastWorkbookUpload(res.payload);
    });
  };

  const createCurveUpload = (campagin, uploadId) => {
    let curve = [];
    let campaignNotFound = "";
    curveData.filter((item) => {
      campagin.find((camp) => {
        if (item.campaign_name === camp.name) {
          if (camp.as_of_date === "9999-01-01") {
            item["campaign_id"] = camp.id;
          }
        }
      });
      let curveData = {
        campaign_id: item.campaign_id,
        date: moment(item.date).format("YYYY-MM-DD"),
        curve: item.curve,
        as_of_date: moment("9999-01-01").format("YYYY-MM-DD"),
        upload_id: uploadId.id
      };
      curve.push(curveData);
    });
    curveData.filter((item) => {
      if (!item.campaign_id) {
        campaignNotFound = (
          <div>
            <b>Campaign Name {item.campaign_name} not found</b>
          </div>
        );
        setCampaignNameError(campaignNotFound);
      }
    });
    if (campaignNotFound) {
      window.scrollTo(0, 0);
      archiveJunkError();
      return false;
    }

    const isValid = handleValidation(campagin);
    if (isValid) {
      if (curve.length) {
        dispatch(createCurve(curve)).then((res) => {
          if (res.type == "FORECAST_LOADING_SUCCESS") {
            forecastUpload(campagin, uploadId);
          }
        });
      }
    }
  };
  function handleValidation(campaign) {
    var result = [];
    let sum = "";
    curveData.reduce(function (res, values) {
      if (!res[values.campaign_name]) {
        res[values.campaign_name] = {
          campaign_name: values.campaign_name,
          curve: 0
        };
        result.push(res[values.campaign_name]);
      }
      res[values.campaign_name].curve += values.curve;
      return res;
    }, {});
    if (result) {
      let missing = [];
      result.filter((res) => {
        if (res.curve.toFixed(2) != 1.0) {
          sum = (
            <div key={res.campaign_name}>
              <b>Campaign {res.campaign_name} curve total not 100%</b>
            </div>
          );
          missing.push(sum);
        }
      });
      if (missing.length > 0) {
        setSumError(missing);
      }
    }
    let result1 = [];
    var dateArray = [];
    let datesMissing = "";
    campaign.forEach((date) => {
      var id = date.id;
      var campaign_name = date.name;
      var currentDate = moment(date.date_start);
      var stopDate = moment(date.date_end);
      while (currentDate <= stopDate) {
        dateArray.push({
          date: moment(currentDate).format("YYYY-MM-DD"),
          campaign_id: id,
          campaign_name: campaign_name
        });
        currentDate = moment(currentDate).add(1, "days");
      }
    });

    curveData.reduce(function (res, values) {
      if (!res[values.campaign_name]) {
        res[values.date] = {
          campaign_name: values.campaign_name,
          campaign_id: values.campaign_id,
          date: values.date
        };
        result1.push(res[values.date]);
      }
      return res;
    }, {});

    const curveObj = group(result1, "campaign_name");
    let curveDataArray = [];
    curveObj.forEach((item) => {
      item.forEach((obj) => {
        campaignData.forEach((id) => {
          if (id.name === obj.campaign_name) {
            curveDataArray.push(obj);
          }
        });
      });
    });
    const a = curveDataArray;
    const b = dateArray;
    const isSame = (a, b) =>
      a.date === b.date &&
      a.campaign_id === b.campaign_id &&
      a.campaign_name === b.campaign_name;

    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        (leftValue) =>
          !right.some((rightValue) => compareFunction(leftValue, rightValue))
      );

    const onlyInA = onlyInLeft(a, b, isSame);
    const onlyInB = onlyInLeft(b, a, isSame);

    const missing = [...onlyInA, ...onlyInB];
    if (missing.length > 0) {
      datesMissing = (
        <div>
          <b>Date missing from campaign curve distribution.</b>
        </div>
      );
      setMissingDates(datesMissing);
    }

    if (sum) {
      window.scrollTo(0, 0);
      archiveJunkError();
      return false;
    }
    if (datesMissing) {
      window.scrollTo(0, 0);
      archiveJunkError();
      return false;
    }

    return true;
  }
  function group(arr, key) {
    return [
      ...arr
        .reduce(
          (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
          new Map()
        )
        .values()
    ];
  }

  const createForcastWorkbookUpload = (uploadId) => {
    reset();
    const isValid = validate(campaignData);
    if (isValid) {
      if (campaignData) {
        let camp = [];
        campaignData.filter((d) => {
          const data = {
            name: d.name,
            description: d.description,
            date_start: d.date_start,
            date_end: d.date_end,
            as_of_date: moment("9999-01-01").format("YYYY-MM-DD"),
            upload_id: uploadId.id
          };
          camp.push(data);
        });
        if (camp.length) {
          dispatch(createCampaign(camp)).then((res) => {
            if (res.type == "FORECAST_LOADING_SUCCESS") {
              createCurveUpload(res.payload, uploadId);
            }
          });
        }
      }
    }
  };

  function validate(data) {
    let campaignError = "";
    campaigns.filter((cam) => {
      data.filter((d) => {
        if (cam.name === d.name) {
          if (moment(cam.as_of_date).format("YYYY-MM-DD") == "9999-01-01") {
            campaignError = (
              <div>
                <b>
                  Campaign Already Exist! <br />
                  Please Archive before creating Campaign Upload!
                </b>
              </div>
            );
          } else {
            campaignError = "";
          }
        }
      });
    });
    if (campaignError) {
      setError(campaignError);
      window.scrollTo(0, 0);
      return false;
    }
    return true;
  }
  function selectUpdateTable(value) {
    if (value.value === 2) {
      setCurve(true);
      setForecast(false);
    }
    if (value.value === 1) {
      setForecast(true);
      setCurve(false);
    }
  }

  return (
    <div style={{ backgroundColor: "#f6f9fc", height: "100vh" }}>
      <ForecastModal
        open={sucessValue}
        value={sucessValue}
        sucess={sucess}
        redirect={redirect}
      />
      {loading == true ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            zIndex: 9999,
            color: "#2894c3"
          }}
        >
          <Box
            sx={{
              top: 1,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              letterSpacing: 10
            }}
          >
            <Typography variant="h1" component="div">
              Loading...
            </Typography>
          </Box>
        </Box>
      ) : (
        ""
      )}
      {campaigns ? (
        <Container className="forecast-campaign-container" maxWidth="xl">
          <Box mt={5}>
            <Grid container className="campaign_sub_container">
              <Grid item xs={3}>
                <Button
                  size="large"
                  variant="contained"
                  className="forecast_button"
                  onClick={() => {
                    setNewCurve(true), setUpdateExisting(false);
                    setCurveData([]),
                      setCampaignData([]),
                      setForecastData([]),
                      setCurve(false);
                    setForecast(false);
                  }}
                >
                  Create New &nbsp; <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Grid>

              <Grid item xs={3}>
                <Button
                  size="large"
                  variant="contained"
                  className="forecast_button"
                  style={{ marginRight: "-55px" }}
                  onClick={() => {
                    setUpdateExisting(true), setNewCurve(false);
                  }}
                >
                  Update existing &nbsp; <FontAwesomeIcon icon={faPenAlt} />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      ) : (
        <Container style={{ justifyContent: "center" }} maxWidth="xl">
          <div className="progress-bar">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "150px"
              }}
            >
              <CircularProgress size={200} />
              <Box
                sx={{
                  top: 1,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Typography variant="caption" component="div">
                  Loading...
                </Typography>
              </Box>
            </Box>
          </div>
        </Container>
      )}

      {newCurve === true ? (
        <Box mt={5}>
          <Grid container className="curve_gird_container">
            <Grid item xs={2} sm={3} md={4} lg={3}>
              <Box ml={1}>
                <div style={{ whiteSpace: "nowrap" }}>
                  <label
                    htmlFor="contained-button-file"
                    className="contained-button-file"
                  >
                    <Input
                      accept="xlsx, xls, cvs"
                      id="contained-button-file"
                      type="file"
                      onChange={importExcel}
                      multiple
                    />
                    <Button
                      size="large"
                      variant="contained"
                      component="span"
                      className="forecast_button"
                    >
                      Upload File &nbsp; <FontAwesomeIcon icon={faUpload} />
                    </Button>
                  </label>
                  &nbsp; {fileName ? fileName : "no file selected"}
                </div>
              </Box>
            </Grid>

            {notArchived === false ? (
              <>
                <Grid
                  container
                  item
                  xs={4}
                  sm={5}
                  lg={7}
                  justifyContent="flex-end"
                >
                  <Tooltip
                    title="Once Archived new plan must be saved before updating!"
                    placement="top-start"
                  >
                    <Button
                      size="large"
                      variant="contained"
                      component="span"
                      className="forecast_button_archive_template"
                      onClick={(e) => startFromLastArchive()}
                      disabled={notArchived}
                    >
                      Archive Data & use Data as template &nbsp;
                      <FontAwesomeIcon icon={faArchive} />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={3} lg={2}>
                  <Box ml={7}>
                    <Button
                      size="large"
                      variant="contained"
                      component="span"
                      className="forecast_button_archive"
                      onClick={(e) => {
                        archive(2), setArchiveObj(true);
                      }}
                    >
                      Archive &nbsp;
                      <FontAwesomeIcon icon={faArchive} />
                    </Button>
                  </Box>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>

          <Container maxWidth="xl" style={{ margin: "25px" }}>
            {error ||
            sumError ||
            missingDates ||
            subError ||
            campaignError ||
            campaignNameError ||
            blankFieldError ? (
              <>
                <Grid container spacing={6} style={{ marginTop: "15px" }}>
                  <Grid
                    style={{
                      marginLeft: "-35px",
                      marginTop: "15px",
                      marginBottom: "25px",
                      paddingLeft: "15px"
                    }}
                  >
                    <div style={{ fontSize: 18, color: "red" }}>{error}</div>
                    <div style={{ fontSize: 18, color: "red" }}>{sumError}</div>
                    <div style={{ fontSize: 18, color: "red" }}>
                      {campaignError}
                    </div>
                    <div style={{ fontSize: 18, color: "red" }}>
                      {missingDates}
                    </div>
                    <div style={{ fontSize: 18, color: "red" }}>{subError}</div>
                    <div style={{ fontSize: 18, color: "red" }}>
                      {campaignNameError}
                    </div>
                    <div style={{ fontSize: 18, color: "red" }}>
                      {blankFieldError}
                    </div>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Container>
          <Container maxWidth="xl">
            <Box mt={6}>
              <Grid item className="tabGrid">
                <Tabs
                  value={tabValue}
                  indicatorColor="primary"
                  className="tab_container"
                >
                  <Tab
                    className="tab_box"
                    value={1}
                    label="Campaign"
                    onClick={() => setTabValue(1)}
                    style={
                      tabValue === 1 ? selectedTabStyle : unselectedTabStyle
                    }
                  />
                  <Tab
                    className="tab_box"
                    value={2}
                    label="Campaign Curve"
                    onClick={() => setTabValue(2)}
                    style={
                      tabValue === 2 ? selectedTabStyle : unselectedTabStyle
                    }
                  />
                  <Tab
                    className="tab_box"
                    value={3}
                    label="Forecast"
                    onClick={() => setTabValue(3)}
                    style={
                      tabValue === 3 ? selectedTabStyle : unselectedTabStyle
                    }
                  />
                </Tabs>
              </Grid>
            </Box>
          </Container>
          <Container className="campaign-container" maxWidth="xl">
            <div className="dataTable_row">{getTabDetails(tabValue)}</div>

            <Grid
              container
              style={{ marginLeft: "25px" }}
              direction="column"
              alignItems="flex-end"
            >
              <Button
                variant="contained"
                size="large"
                className="forecast_button"
                onClick={() => {
                  setCampaignData([]);
                  setCurveData([]);
                  setForecastData([]), createUploadStamp();
                  setLoading(true);
                }}
              >
                Save &nbsp; <FontAwesomeIcon size="1x" icon={faSave} />
              </Button>
            </Grid>
          </Container>
        </Box>
      ) : (
        ""
      )}

      {updateExisting == true ? (
        <Container className="paper_curve_gird_container">
          <Box mt={1} mb={1}>
            <Grid
              container
              style={{
                paddingTop: "15px",
                marginLeft: "0px",
                paddingBottom: "30px"
              }}
            >
              <p
                style={{
                  marginTop: "-10px",
                  fontSize: "15px",
                  fontWeight: "700"
                }}
              >
                Select Table To Update
              </p>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={TYPE}
                  defaultValue={TYPE ? TYPE : null}
                  getOptionLabel={(option) => option.label || ""}
                  fullWidth={true}
                  onChange={(e, value) => {
                    selectUpdateTable(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select" variant="outlined" />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      ) : (
        ""
      )}
      {curve == true ? (
        <CampagineCurve updateExisting={curve} />
      ) : forecast == true ? (
        <Forecast updateExisting={forecast} />
      ) : (
        ""
      )}
    </div>
  );
};

export default ForecastWorkbookUpload;
