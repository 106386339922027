import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  FormGroup,
  FormControlLabel, 
  Switch } from '@material-ui/core';
import T from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { getParts } from '../../../actions/partActions';
import { getCoreSkuByFishbowlPart, getSkuByFishbowlPartPanelLayer, triggerBOM } from '../../../actions/skuActions';
import { updateCoreLayerByFishbowl_part, updatePanelLayerByFishbowl_part } from '../../../actions/fishbowlActions';
import SnackBar from "../../snackbar/SnackBar"
import SkuFromCoreFishbowlPart from './SkuFromCoreFishbowlPart';
import SkuFromFishbowlPartThroughPanelLayer from './SkuFromFishbowlPartThroughPanelLayer';
import "./skubyfishbowlpart.css";
import UpdatePartSelect from './UpdatePartSelect';


const SkuItemForm = (props) => {
  const [filteredParts, setFilteredParts] = useState([]);
  const [skusFromFishbowlPartThroughCore, setSkusFromFishbowlPartThroughCore] = useState([]);
  const [skusFromFishbowlPartThroughThroughPanel, setSkusFromFishbowlPartThroughThroughPanel] = useState([]);
  const [panel_ids, setPanelIds] = useState([]);
  const [part, setPart] = useState("");
  const [partToSwap, setPartToSwap] = useState("");
  const [switchSelection, setSwitchSelection] = useState({
    core: false,
    panel_layer: false
  });
  const [swapPartSwitchSelection, setSwapPartSwitchSelection] = useState({
    update_core: false,
    update_panel_layer: false
  });
  const [snackbar, setSnackBarStatus] = useState({
    open: false,
    severity: "",
    message: ""
  });
  const [handleEdit, setHandleEdit] = useState(false)
  const dispatch = useDispatch()
  const getAllRawMaterials = async () => {
    await dispatch(getParts())
      .then(res => {
        let categories = ["Protector/Encasement", "Sheets", "Frame", "Adjustable Base", "Pillow", "Mattress",
        "T Shirt", "Layer Exchange", "Blanket", "Face Mask", "Accessory", "Bedding","Furniture",
        "Furniture Accessory", "Foundation Spare Part", "Furniture Spare Part", "none", null];
        let activeSku = res.payload.filter(part => part.active == "true" && !categories.includes(part.category) )
        setFilteredParts(activeSku)
      })
  };
  const handleAutocompleteSelect = (e) => {
    let value = e.target.value;
    const [id, num] = value.split(' - ')
    setPart(id)
  };
  const handleCoreSwitch = (e) => {
    setSwitchSelection(prevState => ({...prevState, core: !prevState.core}))
  };
  const handlePanelLayerSwitch = (e) => {
    setSwitchSelection(prevState => ({...prevState, panel_layer: !prevState.panel_layer}))
  };
  const getSkusByFishbowlPartThroughCore = () => {
    dispatch(getCoreSkuByFishbowlPart(part))
    .then(res => {
      if(res.payload.length == 0) {
        setSnackBarStatus({open: true, severity: "warning", message: "FISHBOWL PART NOT FOUND IN CORE LAYERS"})
      } else {
        setSkusFromFishbowlPartThroughCore([...res.payload])
      }
    });
  };
  const getSkusByFishbowlPartThroughPanelLayer = () => {
    dispatch(getSkuByFishbowlPartPanelLayer(part))
      .then(res => {
        if(res.payload.length == 0) {
          setSnackBarStatus({open: true, severity: "warning", message: "FISHBOWL PART NOT FOUND IN PANEL LAYERS"})
        } else {
          const topPanel = res.payload.reduce((previousValue, currentValue) => previousValue.concat(currentValue.top_panel),
          [],).filter(item => item !== null && item.active == 1)
          const sidePanel = res.payload.reduce((previousValue, currentValue) => previousValue.concat(currentValue.side_panel),
          [],).filter(item => item !== null && item.active == 1)
          const bottomPanel = res.payload.reduce((previousValue, currentValue) => previousValue.concat(currentValue.bottom_panel),
          [],).filter(item => item !== null && item.active == 1)
          const gussettPanel = res.payload.reduce((previousValue, currentValue) => previousValue.concat(currentValue.gussett_panel),
          [],).filter(item => item !== null && item.active == 1)
          const panelIds = res.payload.map(sku => sku.panel_id)
          setPanelIds([...panelIds])
          setSkusFromFishbowlPartThroughThroughPanel([...topPanel, ...sidePanel, ...bottomPanel, ...gussettPanel])
        }
      });
  };
  const handleFindSkuItem = () => {
    if(!part) {
      setSnackBarStatus({open: true, severity: "error", message: "SELECT A FISHBOWL PART FROM THE LIST"})
    } else if (part && switchSelection.core) {
      getSkusByFishbowlPartThroughCore()
    } else if(part && switchSelection.panel_layer) {
      getSkusByFishbowlPartThroughPanelLayer()
    } else if (part && !switchSelection.panel_layer && !switchSelection.core) {
      setSnackBarStatus({open: true, severity: "error", message: "SELECT TO SEARCH CORE LAYER OR PANEL LAYER"})
    } 
  };
  const handleSwapPartEdit = () => {
    setHandleEdit(prevState => !prevState)
  };
  const swapFishbowlPart = async () => {
    const partSwapUpdate = {
      partId: part,
      newPartId: partToSwap
    }
    if(!partToSwap) {
        setSnackBarStatus({open: true, severity: "error", message: "SELECT A FISHBOWL PART TO UPDATE"})
    } else if (partToSwap && swapPartSwitchSelection.update_core) {
        await dispatch(updateCoreLayerByFishbowl_part(partSwapUpdate))
          .then(res => {
            if(res.type === "UPDATE_CORE_LAYER_FISHBOWL_PART_SUCCESS") {
              dispatch(triggerBOM())
              setSnackBarStatus({open: true, severity: "success", message: "FISHBOWL PART SUCCESSFULLY SWAPPED"})
            }
          })
    } else if (partToSwap && swapPartSwitchSelection.update_panel_layer) {
        await dispatch(updatePanelLayerByFishbowl_part(partSwapUpdate))
        .then(res => {
          if(res.type == "UPDATE_PANEL_LAYER_FISHBOWL_PART_SUCCESS") {
            dispatch(triggerBOM())
            setSnackBarStatus({open: true, severity: "success", message: "FISHBOWL PART SUCCESSFULLY SWAPPED"})
          }
        }) 
    } else {
        setSnackBarStatus({open: true, severity: "error", message: "SELECT CORE OR PANEL LAYER TO UPDATE"})
    }
  };
  const handleClearTables = (e) => {
    setSkusFromFishbowlPartThroughCore([])
    setSkusFromFishbowlPartThroughThroughPanel([])
    setSnackBarStatus({open: true, severity: "error", message: "TABLES CLEARED"})
  }
  useEffect(() => {
    getAllRawMaterials()
  },[])
  return (
    <Box className='fishbowl_part-sku-form' >
      <SnackBar  
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        setSnackBarStatus={setSnackBarStatus}
      />
      <Box className='fishbowl_part-sku-form-header-box'>
        <Typography className='fishbowl_part-sku-form-header' variant='h2'>
          Sku search by Fishbowl part
        </Typography>
      </Box>
      <Box className='fishbowl_part-sku-search-box' >
        <Autocomplete
          id="sku-item-part"
          style={{width: 700}}
          options={filteredParts}
          getOptionLabel={(options) => `${options.id} - ${options.num} - ${options.description}`}
          onSelect={handleAutocompleteSelect}
          renderInput={(params) => 
            <TextField {...params} 
              label="Select Fishbowl Part to search Core or Panel Layers" 
              id='sku-auto-complete'
            />
          }
        />
        <FormGroup className='switch-group' >
          <FormControlLabel onChange={handleCoreSwitch} control={<Switch className='core-switch' color='primary' />} label="Core Layer" />
          <FormControlLabel onChange={handlePanelLayerSwitch} control={<Switch color='primary'/>} label="Panel Layer" />
        </FormGroup>
        <Button className='find-fishbowl-part-btn' variant='contained' onClick={handleFindSkuItem}>find fishbowl part</Button>
        <Button className='find-fishbowl-part-btn' variant='contained' onClick={handleClearTables}>clear tables</Button>
        <Button style={{width: "127px"}} className='find-fishbowl-part-btn' variant='contained' onClick={handleSwapPartEdit}>
          { handleEdit ?
              "cancel update"
              :
              "update part"
          }
        </Button>
      </Box>
      {handleEdit ?
        <UpdatePartSelect 
          filteredParts={filteredParts}
          setPartToSwap={setPartToSwap}
          setSwapPartSwitchSelection={setSwapPartSwitchSelection}
          swapFishbowlPart={swapFishbowlPart}
        />
        :
        "" 
      }
      {skusFromFishbowlPartThroughCore.length > 0 ?
        <SkuFromCoreFishbowlPart setSnackBarStatus={setSnackBarStatus} skus={skusFromFishbowlPartThroughCore}/>
       : 
       ""
      }
      {skusFromFishbowlPartThroughThroughPanel.length > 0 ?
        <SkuFromFishbowlPartThroughPanelLayer setSnackBarStatus={setSnackBarStatus} skus={skusFromFishbowlPartThroughThroughPanel} panelIds={panel_ids}/>
        :
        ""
      }
    </Box>
  )
};
SkuItemForm.propTypes = {
  parts: T.array,
  cores: T.array,
  coreTypes: T.array,
  isLoading: T.bool,
}

function mapStateToProps(state) {
  const { 
    coreReducer,
    coreTypeReducer, 
    partReducer } = state;
    return {
    cores: coreReducer.cores,
    coreTypes: coreTypeReducer.coreTypes,
    parts: partReducer.parts,
  }
};

export default withRouter(connect(mapStateToProps)(SkuItemForm));