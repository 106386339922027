import React, { useState } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css';
import { getUsers } from '../../actions/userActions';
import { getColumns } from '../../actions/globalActions';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme } from '@material-ui/core/styles';
import AddNewButton from '../global/AddNewButton';
import Loader from '../global/Loader';

const defaultColumnProperties = {
  sortable: true,
  width: 250,
};

class UserList extends React.Component {
  getOptions() {
    const options = {
      filterType: 'dropdown',
      responsive: 'standard',
      fixedHeader: true,
      rowsPerPage: 100,
      selectableRowsHeader: false,
      selectableRows: 'none',
      customToolbar: () => {
        return <AddNewButton entity={'user/-1'} history={this.props.history} />;
      },
    };
    return options;
  }

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      modalIsOpen: false,
    };

    // this.openModal = this.openModal.bind(this);
    // this.afterOpenModal = this.afterOpenModal.bind(this);
    // this.closeModal = this.closeModal.bind(this);
  }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#FFFFFF',
          },
        },
      },
    });

  getColumns = (e) => {
    const { dispatch } = this.props;

    dispatch(getColumns('users'));
  };

  componentDidMount() {
    this.getColumns();

    const { dispatch } = this.props;

    dispatch(getColumns('users'));
    dispatch(getUsers());
  }

  render() {
    const { isLoading, users, columns } = this.props;
    return (
      <div id='userList' name='userList' className='baseContainer userList'>
        <h1></h1>
        <MUIDataTable
          title={'Users'}
          data={users}
          columns={columns.map((c) => ({ ...c, ...defaultColumnProperties }))}
          options={this.getOptions()}
        />
        <Loader isLoading={isLoading} />
      </div>
    );
  }
}

UserList.defaultProps = {};

UserList.propTypes = {
  isLoading: T.bool,
  users: T.array,
  columns: T.array,
};

function mapStateToProps(state) {
  const { userList } = state;
  return {
    isLoading: userList.isLoading,
    users: userList.users,
    columns: userList.columns,
  };
}

export default withRouter(connect(mapStateToProps)(UserList));
