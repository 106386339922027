
import {
    FIRMNESS_UPDATE_FIELD,
    FIRMNESS_LOADING_REQUEST,
    FIRMNESS_LOADING_SUCCESS,
    FIRMNESS_LOADING_ERROR,
    FIRMNESS_SAVING_REQUEST,
    FIRMNESS_SAVING_SUCCESS,
    FIRMNESS_SAVING_ERROR,
    FIRMNESSES_LOADING_ERROR,
    FIRMNESSES_LOADING_REQUEST,
    FIRMNESSES_LOADING_SUCCESS,
    FIRMNESSES_SAVING_ERROR,
    FIRMNESSES_SAVING_REQUEST,
    FIRMNESSES_SAVING_SUCCESS
} from '../constants/coreFirmnessTypes';

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    id: -1,
    firmness: '',
    firmnesses: [],
    columns: [],
    rows: [],
}

export default function coreFirmnessReducer(state = initialState, action) {
    switch (action.type) {
        case FIRMNESS_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case FIRMNESSES_LOADING_REQUEST:
        case FIRMNESSES_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case FIRMNESS_LOADING_REQUEST:
        case FIRMNESS_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case FIRMNESS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                firmness: action.payload.firmness,
            }
        case FIRMNESS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: null,
                firmness: '',
            }
        case FIRMNESSES_LOADING_ERROR:
        case FIRMNESSES_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case FIRMNESS_LOADING_ERROR:
        case FIRMNESS_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case FIRMNESSES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                firmnesses: action.payload,
            }
        case FIRMNESSES_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                firmnesses: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}