export const FORECAST_LOADING_REQUEST = 'FORECAST_LOADING_REQUEST'
export const FORECAST_LOADING_SUCCESS = 'FORECAST_LOADING_SUCCESS'
export const FORECAST_LOADING_ERROR = 'FORECAST_LOADING_ERROR'

export const FORECAST_UPDATE_FIELD = 'FORECAST_UPDATE_FIELD'

export const FORECAST_SAVING_REQUEST = 'FORECAST_SAVING_REQUEST'
export const FORECAST_SAVING_SUCCESS = 'FORECAST_SAVING_SUCCESS'
export const FORECAST_SAVING_ERROR = 'FORECAST_SAVING_ERROR'


export const FORECAST_DELETING_REQUEST = 'FORECAST_DELETING_REQUEST'
export const FORECAST_DELETING_SUCCESS = 'FORECAST_DELETING_SUCCESS'
export const FORECAST_DELETING_ERROR = 'FORECAST_DELETING_ERROR'

export const SUBCHANNEL_LOADING_REQUEST = 'FORECAST_LOADING_REQUEST'
export const SUBCHANNEL_LOADING_SUCCESS = 'FORECAST_LOADING_SUCCESS'
export const SUBCHANNEL_LOADING_ERROR = 'FORECAST_LOADING_ERROR'