import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography
} from "@material-ui/core";
import {
  faCalendarAlt,
  faCogs,
  faGavel,
  faHome,
  faPowerOff,
  faBarcode,
  faDolly,
  faShippingFast,
  faMoneyCheckAlt,
  faStore,
  faStoreAlt,
  faChartLine,
  faExpandAlt,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import Can from "../Can";
import MainNavLink from "./MainNavLink";
import SubNavLink from "./SubNavLink";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MainNavPage = (props) => {
  return props.userId !== "" ? (
    <Container className="main_nav_container">
      <Grid container justifyContent="center">
        {/* <Can
          role={props.role}
          perform="piece-page:visit"
          yes={() => (
            <MainNavLink
              route="/piece-rate"
              label="Piece Rate"
              icon={faMoneyCheckAlt}
            />
          )}
          no={() => null}
        /> */}
        <Accordion className="grid_accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="main_nav_typography"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Production Schedule <FontAwesomeIcon icon={faCalendarAlt} />
            </Typography>
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          </AccordionSummary>
          <AccordionDetails>
            <Can
              role={props.role}
              perform="production-schedule-page:visit"
              yes={() => (
                <Grid container spacing={12} justifyContent="space-evenly">
                  <Grid item xs={3}>
                    <u>
                      <SubNavLink
                        label={"Production Schedule"}
                        route={"/schedule"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Batch Manager"}
                        route={"/batch"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={3}>
                    <u>
                      <SubNavLink
                        label={"Batch Assignment"}
                        route={"/sku-batch"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                </Grid>
              )}
              no={() => null}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="grid_accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="main_nav_typography"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Law Tag Data <FontAwesomeIcon icon={faGavel} />
            </Typography>
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          </AccordionSummary>
          <AccordionDetails>
            <Can
              role={props.role}
              perform="skus-page:visit"
              yes={() => (
                <Grid container justifyContent="space-evenly">
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Customers"}
                        route={"/customers"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Manufacturers"}
                        route={"/manufacturers"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Foundation Groups"}
                        route={"/lt/foundation/groups"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Foundations"}
                        route={"/foundations"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Material Groups"}
                        route={"/lt/material/groups"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Materials"}
                        route={"/materials"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Prototypes"}
                        route={"/prototypes"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Law Tag Viewer"}
                        route={"/lawtag/viewer"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Prototypes"}
                        route={"/prototypes"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                </Grid>
              )}
              no={() => null}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="grid_accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="main_nav_typography"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Sku Data <FontAwesomeIcon icon={faBarcode} />
            </Typography>
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          </AccordionSummary>
          <AccordionDetails>
            <Can
              role={props.role}
              perform="skus-page:visit"
              yes={() => (
                <Grid container justifyContent="space-evenly">
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Skus"}
                        route={"/skus"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Brands"}
                        route={"/brands"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Families"}
                        route={"/families"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Sizes"}
                        route={"/sizes"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Cores"}
                        route={"/cores"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Parent Cores"}
                        route={"/mat-core/parentCores"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Panels"}
                        route={"/panels"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Quilt Patterns"}
                        route={"/fabric/quilt/patterns"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"SKU Labor Cost"}
                        route={"/labor"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"SKU Shipping Cost"}
                        route={"/sku-shipping"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                </Grid>
              )}
              no={() => null}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="grid_accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="main_nav_typography"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Inventory <FontAwesomeIcon icon={faDolly} />
            </Typography>
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          </AccordionSummary>
          <AccordionDetails>
            <Can
              role={props.role}
              perform="production-schedule-page:visit"
              yes={() => (
                <Grid container spacing={12} justifyContent="space-evenly">
                  <Grid item xs={3}>
                    <u>
                      <SubNavLink
                        label={"Cycle Count"}
                        route={"/cycle/count"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Fishbowl Reprocessing"}
                        route={"/fishbowl/reprocessing"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                </Grid>
              )}
              no={() => null}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="grid_accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="main_nav_typography"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Dealer <FontAwesomeIcon icon={faStoreAlt} />
            </Typography>
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          </AccordionSummary>
          <AccordionDetails>
            <Can
              role={props.role}
              perform="production-schedule-page:visit"
              yes={() => (
                <Grid container spacing={12} justifyContent="space-evenly">
                  <Grid item xs={3}>
                    <u>
                      <SubNavLink
                        label={"Dealar Table"}
                        route={"/dealers"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                </Grid>
              )}
              no={() => null}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="grid_accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="main_nav_typography"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Shipping <FontAwesomeIcon icon={faShippingFast} />
            </Typography>
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          </AccordionSummary>
          <AccordionDetails>
            <Can
              role={props.role}
              perform="production-schedule-page:visit"
              yes={() => (
                <Grid container spacing={12} justifyContent="space-evenly">
                  <Grid item xs={3}>
                    <u>
                      <SubNavLink
                        label={"Shipping"}
                        route={"/shipping"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                  <Grid item xs={4} className="grid_links">
                    <u>
                      <SubNavLink
                        label={"Sku Queue"}
                        route={"/skuqueue"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                </Grid>
              )}
              no={() => null}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="grid_accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="main_nav_typography"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Settings <FontAwesomeIcon icon={faCogs} />
            </Typography>
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          </AccordionSummary>
          <AccordionDetails>
            <Can
              role={props.role}
              perform="production-schedule-page:visit"
              yes={() => (
                <Grid container spacing={12} justifyContent="space-evenly">
                  <Grid item xs={3}>
                    <u>
                      <SubNavLink
                        label={"Users"}
                        route={"/users"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                </Grid>
              )}
              no={() => null}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="grid_accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="main_nav_typography"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Retail <FontAwesomeIcon icon={faStore} />
            </Typography>
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          </AccordionSummary>
          <AccordionDetails>
            <Can
              role={props.role}
              perform="production-schedule-page:visit"
              yes={() => (
                <Grid container spacing={12} justifyContent="space-evenly">
                  <Grid item xs={3}>
                    <u>
                      <SubNavLink
                        label={"Commissions Managment"}
                        route={"/commission"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                </Grid>
              )}
              no={() => null}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className="grid_accordion">
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="main_nav_typography"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              Forecast <FontAwesomeIcon icon={faChartLine} />
            </Typography>
            <FontAwesomeIcon icon={faAngleDown} size="lg" />
          </AccordionSummary>
          <AccordionDetails>
            <Can
              role={props.role}
              perform="production-schedule-page:visit"
              yes={() => (
                <Grid container spacing={12} justifyContent="space-evenly">
                  <Grid item xs={3}>
                    <u>
                      <SubNavLink
                        label={"Forecast Upload"}
                        route={"/forecast-workbook-upload"}
                        className="main_nav_sublink"
                      />
                    </u>
                  </Grid>
                </Grid>
              )}
              no={() => null}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Container>
  ) : (
    ""
  );
};

function mapStateToProps(state) {
  const { loginForm } = state;
  return {
    role: loginForm.userRole,
    userId: loginForm.userId,
    active: loginForm.active
  };
}

export default connect(mapStateToProps)(MainNavPage);
