import React from 'react';
import CustomerList from './List/CustomerList';
import '../styles/about-page.css';

export class CustomerListPage extends React.Component {

    render() {
        return (
            <CustomerList/>
        );
    }
}

export default CustomerListPage;
