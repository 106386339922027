
export const PROTOTYPE_UPDATE_FIELD = 'PROTOTYPE_UPDATE_FIELD'

export const PROTOTYPE_LOADING_REQUEST = 'PROTOTYPE_LOADING_REQUEST'
export const PROTOTYPE_LOADING_SUCCESS = 'PROTOTYPE_LOADING_SUCCESS'
export const PROTOTYPE_LOADING_ERROR = 'PROTOTYPE_LOADING_ERROR'

export const PROTOTYPES_LOADING_REQUEST = 'PROTOTYPES_LOADING_REQUEST'
export const PROTOTYPES_LOADING_SUCCESS = 'PROTOTYPES_LOADING_SUCCESS'
export const PROTOTYPES_LOADING_ERROR = 'PROTOTYPES_LOADING_ERROR'

export const PROTOTYPE_IMPORT_LOADING_REQUEST = 'PROTOTYPE_IMPORT_LOADING_REQUEST'
export const PROTOTYPE_IMPORT_LOADING_SUCCESS = 'PROTOTYPE_IMPORT_LOADING_SUCCESS'
export const PROTOTYPE_IMPORT_LOADING_ERROR = 'PROTOTYPE_IMPORT_LOADING_ERROR'

export const PROTOTYPE_SAVING_REQUEST = 'PROTOTYPE_SAVING_REQUEST'
export const PROTOTYPE_SAVING_SUCCESS = 'PROTOTYPE_SAVING_SUCCESS'
export const PROTOTYPE_SAVING_ERROR = 'PROTOTYPE_SAVING_ERROR'

export const PROTOTYPES_SAVING_REQUEST = 'PROTOTYPES_SAVING_REQUEST'
export const PROTOTYPES_SAVING_SUCCESS = 'PROTOTYPES_SAVING_SUCCESS'
export const PROTOTYPES_SAVING_ERROR = 'PROTOTYPES_SAVING_ERROR'
