import React  from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import {getCores, saveCoreList} from "../../actions/coreActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

class CoreList extends React.Component {

    getOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <AddNewButton
                        entity={"core/-1"}
                        history={this.props.history}
                        data={data}
                    />
                )
            }
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            cores: [],
            rows: [],
            cols: [],
            columns: [],
            filename: '',
            file: '',
        }
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('core'))
    }

    //Toolbar functions
    handleCoreSaveClick = () => {
        const {
            dispatch,
            cores,
        } = this.props

        const data = {
            data: cores.concat(this.state.cores),
            filename: this.state.filename,
        }

        dispatch(saveCoreList(data))
        this.setState({
            cols: [],
            rows: [],
            cores: [],
            filename: '',
        })
    }
    handleCoreDeleteClick = () => {
        this.setState({
            cols: [],
            rows: [],
            schedule: [],
        })
    }
    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {

        this.getColumns()

        const {
            dispatch,
        } = this.props

        dispatch(getCores())
    }

    render() {
        const {
            isLoading,
            cores,
            columns,
        } = this.props

        return (
            <div className="baseContainer coreList">
                <h1>

                </h1>
                <MUIDataTable
                    title={"Cores"}
                    // data={cores.concat(this.state.cores)}
                    // columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    options={this.getOptions(cores.concat(this.state.cores))}
                />
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

CoreList.defaultProps = {
    date: getDBFormattedDate()
}

CoreList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    cores: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { coreReducer } = state
    return {
        isLoading: coreReducer.isLoading,
        cores: coreReducer.cores,
        rows: coreReducer.rows,
        cols: coreReducer.cols,
        columns: coreReducer.columns,
        file: coreReducer.file,
    }
}

export default withRouter(connect(mapStateToProps)(CoreList))