import {
  UPDATE_CORE_LAYER_FISHBOWL_PART_REQUEST,
  UPDATE_CORE_LAYER_FISHBOWL_PART_SUCCESS,
  UPDATE_CORE_LAYER_FISHBOWL_PART_ERROR,
  UPDATE_PANEL_LAYER_FISHBOWL_PART_REQUEST,
  UPDATE_PANEL_LAYER_FISHBOWL_PART_SUCCESS,
  UPDATE_PANEL_LAYER_FISHBOWL_PART_ERROR,
  CYCLE_COUNT_UPDATE_FIELD,
    LOCATION_GROUPS_LOADING_REQUEST,
    LOCATION_GROUPS_LOADING_SUCCESS,
    LOCATION_GROUPS_LOADING_ERROR,
    SKU_BY_BRAND_LOADING_REQUEST,
    SKU_BY_BRAND_LOADING_SUCCESS,
    SKU_BY_BRAND_LOADING_ERROR,
    LOCATIONS_LOADING_ERROR,
    LOCATIONS_LOADING_REQUEST,
    LOCATIONS_LOADING_SUCCESS,
    SKU_CYCLECOUNT_LOADING_ERROR,
    SKU_CYCLECOUNT_LOADING_REQUEST,
    SKU_CYCLECOUNT_LOADING_SUCCESS,
    RESET_CYCLE_COUNT,
    CYCLE_COUNT_IMPORT_REQUEST,
    CYCLE_COUNT_IMPORT_SUCCESS,
    CYCLE_COUNT_IMPORT_ERROR,
    ERROR_OPEN_REQUEST,
    ERROR_OPEN_SUCCESS,
    ERROR_OPEN_ERROR,
    ERROR_CLOSE_REQUEST,
    ERROR_CLOSE_SUCCESS,
    ERROR_CLOSE_ERROR,
    REPROCESS_REQUEST,
    REPROCESS_ERROR,
    REPROCESS_SUCCESS,
    DIALOG_OPEN,
    DIALOG_ACCEPTED,
    DIALOG_DENIED,
    SKU_LOADING_ERROR,
    SKU_LOADING_REQUEST,
    SKU_LOADING_SUCCESS,
} from '../constants/fishbowlTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string"
import {addLink} from "../utils/column";
import T from "prop-types";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    isOpen: false,
    isConfirmed: false,
    locationGroups: [],
    locationGroup: '',
    locations: [],
    locationName: '',
    brandName: '',
    skusByBrand: [],
    cycleCount: [],
    cycleCountDisplay: [],
    filteredCycleCount: [],
    skuNamesByBrand: [],
    deviceId: 'diditship.com',
    errorLoad: [],
    columns: [],
    rows: [],
    reprocessResult: {},
    object: {},
}

export default function fishbowlReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CORE_LAYER_FISHBOWL_PART_ERROR:
        case UPDATE_CORE_LAYER_FISHBOWL_PART_REQUEST:
        case UPDATE_CORE_LAYER_FISHBOWL_PART_SUCCESS:
          return {
            ...state,
            isLoading: false,
            isError: false,
          }
        case UPDATE_PANEL_LAYER_FISHBOWL_PART_ERROR:
        case UPDATE_PANEL_LAYER_FISHBOWL_PART_REQUEST:
        case UPDATE_PANEL_LAYER_FISHBOWL_PART_SUCCESS:
          return {
            ...state,
            isLoading: false,
            isError: false,
          }
        case CYCLE_COUNT_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case CYCLE_COUNT_IMPORT_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case LOCATION_GROUPS_LOADING_REQUEST:
        case SKU_BY_BRAND_LOADING_REQUEST:
        case SKU_CYCLECOUNT_LOADING_REQUEST:
        case LOCATIONS_LOADING_REQUEST:
        case ERROR_OPEN_REQUEST:
        case ERROR_CLOSE_REQUEST:
        case REPROCESS_REQUEST:
        case SKU_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case LOCATION_GROUPS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                locationGroups: action.payload
            }
        case LOCATIONS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                locations: action.payload
            }
        case SKU_BY_BRAND_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                skusByBrand: action.payload
            }
        case SKU_CYCLECOUNT_LOADING_SUCCESS:
            return{
                ...state,
                isLoading: false,
                isError: false,
                cycleCount: action.payload
            }
        case CYCLE_COUNT_IMPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                // locationGroup: '',
                // locationName: '',
                // brandName: '',
                // skusByBrand: [],
                // cycleCount: [],
                // cycleCountDisplay: [],
                // filteredCycleCount: [],
                // skuNamesByBrand: []
            }
        case ERROR_OPEN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                errorLoad: action.payload
            }
        case ERROR_CLOSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
            }
        case SKU_LOADING_SUCCESS:
        return {
            ...state,
            isLoading: false,
            isError: false,
            skuNamesByBrand: action.payload,
        }
        case REPROCESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                reprocessResult: action.payload
            }
        case COLUMNS_LOADING_ERROR:
        case LOCATION_GROUPS_LOADING_ERROR:
        case LOCATIONS_LOADING_ERROR:
        case SKU_BY_BRAND_LOADING_ERROR:
        case CYCLE_COUNT_IMPORT_ERROR:
        case SKU_CYCLECOUNT_LOADING_ERROR:
        case ERROR_OPEN_ERROR:
        case ERROR_CLOSE_ERROR:
        case REPROCESS_ERROR:
        case SKU_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        case RESET_CYCLE_COUNT:
            return {
                deviceId: 'diditship.com',
                isError: false,
                isLoading: false,
                isSaved: false,
                isOpen: false,
                locationGroups: [],
                locationGroup: '',
                locations: [],
                locationName: '',
                brandName: '',
                skusByBrand: [],
                cycleCount: [],
                cycleCountDisplay: [],
                filteredCycleCount: [],
                skuNamesByBrand: [],
                object: {},
            }

        case DIALOG_OPEN:
            return {
                ... state,
                isOpen: true,
                object: action.payload,
            }

        case DIALOG_ACCEPTED:
            return {
                ... state,
                isConfirmed: true,
                isOpen: false,
            }

        case DIALOG_DENIED:
            return {
                ... state,
                isConfirmed: false,
                isOpen: false,
                object: {},
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}