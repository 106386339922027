import * as types from "../constants/scanTypes";
import { RSAA } from "redux-api-middleware";
import { API_ROOT } from "../utils/api";

export function updateField(name, value) {
  return {
    type: types.SCAN_UPDATE_FIELD,
    name,
    value
  };
}

export function postScanLog(scanLog) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/scan`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(scanLog),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCAN_LOG_REQUEST,
        types.SCAN_LOG_SUCCESS,
        types.SCAN_LOG_ERROR
      ]
    }
  };
}
/* reprint label route */
export function reprintLabelByScanInfo(scanInfo) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/reprintLabel`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(scanInfo),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.REPRINT_LABEL_REQUEST,
        types.REPRINT_LABEL_SUCCESS,
        types.REPRINT_LABEL_ERROR
      ]
    }
  };
}

/* get all printers from label_printers table */
export function getLabelPrinters() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/printers`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.LABEL_PRINTER_REQUEST,
        types.LABEL_PRINTER_SUCCESS,
        types.LABEL_PRINTER_ERROR
      ]
    }
  };
}

export function updateScanStatus(scanStatusName, scanStatusId) {
  return {
    type: types.SCAN_STATUS_UPDATE,
    scanStatusName: scanStatusName,
    scanStatusId: scanStatusId
  };
}

export function getStatusList() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/status`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCAN_STATUS_LIST_REQUEST,
        types.SCAN_STATUS_LIST_SUCCESS,
        types.SCAN_STATUS_LIST_ERROR
      ]
    }
  };
}

export function getUserTotalScansByStatusToday(userId, scanStatusId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/user/scans/${userId}/${scanStatusId}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.USER_SCANS_BY_STATUS_DAY_REQUEST,
        types.USER_SCANS_BY_STATUS_DAY_SUCCESS,
        types.USER_SCANS_BY_STATUS_DAY_ERROR
      ]
    }
  };
}
export function getUserComponentScans(userId, scanStatusId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/component/user/scans/${userId}/${scanStatusId}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.USER_SCANS_BY_STATUS_DAY_REQUEST,
        types.USER_SCANS_BY_STATUS_DAY_SUCCESS,
        types.USER_SCANS_BY_STATUS_DAY_ERROR
      ]
    }
  };
}
export function getTotalScansByStatusToday(scanStatusId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/user/scans/${scanStatusId}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCANS_BY_STATUS_DAY_REQUEST,
        types.SCANS_BY_STATUS_DAY_SUCCESS,
        types.SCANS_BY_STATUS_DAY_ERROR
      ]
    }
  };
}
/* get all scans for status for past 30 days  */
export function getTotalPastScansByStatusIdAndSerialNumber(
  statusId,
  serialNumber
) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/past-scans/${statusId}/${serialNumber}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_REQUEST,
        types.SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_SUCCESS,
        types.SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_ERROR
      ]
    }
  };
}

export function getScanScheduleSerialData(serialNumber, scanStatusId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule/serial/${serialNumber}/${scanStatusId}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCAN_SCHED_SERIAL_DATA_REQUEST,
        types.SCAN_SCHED_SERIAL_DATA_SUCCESS,
        types.SCAN_SCHED_SERIAL_DATA_ERROR
      ]
    }
  };
}

export function getScans(start, end) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/scans`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        startDate: start,
        endDate: end
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCANS_LOADING_REQUEST,
        types.SCANS_LOADING_SUCCESS,
        types.SCANS_LOADING_ERROR
      ]
    }
  };
}
export function getScansBySerialId(serialId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/scans/${serialId}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCANS_LOADING_REQUEST,
        types.SCANS_LOADING_SUCCESS,
        types.SCANS_LOADING_ERROR
      ]
    }
  };
}
export function postBatchScansInScanLog(scans) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/batch_scans`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(scans),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCANS_LOADING_REQUEST,
        types.SCANS_LOADING_SUCCESS,
        types.SCANS_LOADING_ERROR
      ]
    }
  };
}
export function postBatchShippingScansInScanLog(scans) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/shipping_batch_scans`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(scans),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCANS_LOADING_REQUEST,
        types.SCANS_LOADING_SUCCESS,
        types.SCANS_LOADING_ERROR
      ]
    }
  };
}

export function testPrinter(test) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/test-printer`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(test),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCANS_LOADING_REQUEST,
        types.SCANS_LOADING_SUCCESS,
        types.SCANS_LOADING_ERROR
      ]
    }
  };
}

export function getStationlists() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/stations`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.STATION_LOADING_REQUEST,
        types.STATION_LOADING_SUCCESS,
        types.STATION_LOADING_ERROR
      ]
    }
  };
}
export function postComponentScanLog(scanLog) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/componentScan`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(scanLog),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCAN_LOG_REQUEST,
        types.SCAN_LOG_SUCCESS,
        types.SCAN_LOG_ERROR
      ]
    }
  };
}
export function getScanLogComponent(childSerialId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/componentScans/${childSerialId}`, // TODO: make base URL constant
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCAN_LOG_REQUEST,
        types.SCAN_LOG_SUCCESS,
        types.SCAN_LOG_ERROR
      ]
    }
  };
}
export function validateBatchScans(scans, status_id) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/scan/validate/${status_id}`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(scans),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.VALIDATE_SCANS_REQUEST,
        types.VALIDATE_SCANS_SUCCESS,
        types.VALIDATE_SCANS_ERROR
      ]
    }
  };
}
export function findSibling(serial_number) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/scan/serial_sibling`,
      method: "POST",
      body: JSON.stringify(serial_number),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCAN_SCHED_SERIAL_DATA_REQUEST,
        types.SCAN_SCHED_SERIAL_DATA_SUCCESS,
        types.SCAN_SCHED_SERIAL_DATA_ERROR
      ]
    }
  };
}
export function upadteFlatPackShipping(flatPack) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/scan/update_flatpack_shipping`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(flatPack),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FLATPACK_SHIPPING_REQUEST,
        types.FLATPACK_SHIPPING_SUCCESS,
        types.FLATPACK_SHIPPING_ERROR
      ]
    }
  };
}
export function getFlatPackShipping() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/scan/flatpack_shipping`, // TODO: make base URL constant
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FLATPACK_SHIPPING_REQUEST,
        types.FLATPACK_SHIPPING_SUCCESS,
        types.FLATPACK_SHIPPING_ERROR
      ]
    }
  };
}

export function updateScan(scans) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/scan/${scans.id}`, // TODO: make base URL constant
      method: "PUT",
      body: JSON.stringify(scans),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCAN_LOG_REQUEST,
        types.SCAN_LOG_SUCCESS,
        types.SCAN_LOG_ERROR
      ]
    }
  };
}
