import React, { Component } from 'react';
// import Spinner from 'react-spin';
import { css } from '@emotion/core';
import PacmanLoader from 'react-spinners/PacmanLoader';
import T from "prop-types";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;`


const Loader = props => {
    const {isLoading, ...rest} = props
    return (
        <PacmanLoader
            exact
            {...rest}
            css={override}
            sizeUnit={"px"}
            size={50}
            margin={2}

            color={'#123abc'}
            loading={isLoading}
        />
    )
}

Loader.propTypes = {
    isLoading: T.bool,
}

export default Loader
