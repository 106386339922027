import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

import { getFabricQuiltPattern, saveFabricQuiltPattern, updateField} from '../../actions/fabricQuiltPatternActions'

class FabricQuiltPatternForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        this.getFabricQuiltPattern()
    }

    getFabricQuiltPattern = e => {
        const {
            dispatch,
            isLoading,
        } = this.props

        const id = this.props.match.params.id
        if (id) {
            dispatch(getFabricQuiltPattern(id))
        }
    }

    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSuccess) {
            this.setState({ open: true })
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            id,
            fabricQuiltPattern,
        } = this.props

        const fabricQuiltPatternObj = {
            id: id,
            fabric_quilt_pattern: fabricQuiltPattern,
        }
        if(!id || id === -1){
            delete fabricQuiltPatternObj.id
        }
        await dispatch(saveFabricQuiltPattern(fabricQuiltPatternObj))
        if(!isError) {
            this.props.history.push("/fabric/quilt/patterns")
        }
    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            id,
            fabricQuiltPattern,
        } = this.props

        return (
            <div className="baseContainer projectForm" style={{marginLeft: 20}}>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    className="success"
                    onClose={this.handleClose}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Quilt Pattern saved</span>}
                />
                <h1>
                </h1>
                <div>
                    <TextField
                        label="Quilt Pattern"
                        name="fabricQuiltPattern"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={fabricQuiltPattern}
                        onChange={this.handleChange}
                    />
                </div>
                <div style={{marginTop: 20}}>
                    <Button
                        variant="contained"
                        style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        )
    }
}

FabricQuiltPatternForm.defaultProps = {

}

FabricQuiltPatternForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    fabricQuiltPattern: T.string,
}

function mapStateToProps(state) {
    const { fabricQuiltPatternReducer } = state
    return {
        isError: fabricQuiltPatternReducer.isError,
        saveSuccess: fabricQuiltPatternReducer.saveSuccess,
        id: fabricQuiltPatternReducer.id,
        fabricQuiltPattern: fabricQuiltPatternReducer.fabricQuiltPattern,
    }
}

export default withRouter(connect(mapStateToProps)(FabricQuiltPatternForm))