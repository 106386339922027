
export const CUSTOMER_UPDATE_FIELD = 'CUSTOMER_UPDATE_FIELD'

export const CUSTOMER_LOADING_REQUEST = 'CUSTOMER_LOADING_REQUEST'
export const CUSTOMER_LOADING_SUCCESS = 'CUSTOMER_LOADING_SUCCESS'
export const CUSTOMER_LOADING_ERROR = 'CUSTOMER_LOADING_ERROR'

export const CUSTOMERS_LOADING_REQUEST = 'CUSTOMERS_LOADING_REQUEST'
export const CUSTOMERS_LOADING_SUCCESS = 'CUSTOMERS_LOADING_SUCCESS'
export const CUSTOMERS_LOADING_ERROR = 'CUSTOMERS_LOADING_ERROR'

export const CUSTOMER_IMPORT_LOADING_REQUEST = 'CUSTOMER_IMPORT_LOADING_REQUEST'
export const CUSTOMER_IMPORT_LOADING_SUCCESS = 'CUSTOMER_IMPORT_LOADING_SUCCESS'
export const CUSTOMER_IMPORT_LOADING_ERROR = 'CUSTOMER_IMPORT_LOADING_ERROR'

export const CUSTOMER_SAVING_REQUEST = 'CUSTOMER_SAVING_REQUEST'
export const CUSTOMER_SAVING_SUCCESS = 'CUSTOMER_SAVING_SUCCESS'
export const CUSTOMER_SAVING_ERROR = 'CUSTOMER_SAVING_ERROR'

export const CUSTOMERS_SAVING_REQUEST = 'CUSTOMERS_SAVING_REQUEST'
export const CUSTOMERS_SAVING_SUCCESS = 'CUSTOMERS_SAVING_SUCCESS'
export const CUSTOMERS_SAVING_ERROR = 'CUSTOMERS_SAVING_ERROR'
