import * as types from "../constants/reportTypes";
import { RSAA } from "redux-api-middleware";
import { removeEmptyFields } from "../utils/object";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { API_ROOT } from "../utils/api";

export function updateField(name, value) {
  return {
    type: types.REPORT_UPDATE_FIELD,
    name,
    value
  };
}

export function getStatusList() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/status`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.REPORT_STATUS_LOADING_REQUEST,
        types.REPORT_STATUS_LOADING_SUCCESS,
        types.REPORT_STATUS_LOADING_ERROR
      ]
    }
  };
}

export function getEmbedUrl() {
  //const dashboard = '99db013e-39ec-457d-906b-584a56dba25e'
  const dashboard = "bff51a6b-ca16-42d7-9ae2-90e0da65251c";
  return {
    [RSAA]: {
      endpoint:
        `${API_ROOT}/report/embedurl/` + `Justin.Conklin` + `/` + dashboard, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.REPORT_EMBED_URL_LOADING_REQUEST,
        types.REPORT_EMBED_URL_LOADING_SUCCESS,
        types.REPORT_EMBED_URL_LOADING_ERROR
      ]
    }
  };
}
export function getAWSingestion() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/ingestion`, // TODO: make base URL constant
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.INGESTION_STATUS_SAVING_SUCCESS,
        types.INGESTION_STATUS_LOADING_REQUEST,
        types.INGESTION_STATUS_LOADING_ERROR
      ]
    }
  };
}

export function getProductionTracker(embedUrl, date, status) {
  const options = {
    url: embedUrl,
    container: document.getElementById("embed-dashboard-container"),
    scrolling: "no",
    height: "1200px",
    width: "100%",
    locale: "en-US",
    parameters: {
      Schedule: date + " 00:00:00",
      Status: status
    }
  };
  const dashboard = embedDashboard(options);
  return {
    type: types.REPORTS_LOADING_SUCCESS,
    dashboard
  };
}
