import React, { useState } from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import '../../styles/about-page.css'
import {getScans, updateField} from "../../actions/scanActions"
import {getColumns} from "../../actions/globalActions";

import MUIDataTable from "mui-datatables"

import ScanListFilter from "./ScanListFilter";
import Loader from "../global/Loader";

const defaultColumnProperties = {
  sortable: true,
  width: 120
}

const options = {
  filterType: 'checkbox',
};


class ScanList extends React.Component {

    getOptions(data, scanStartDate, scanEndDate) {

        const options = {
            filterType: 'dropdown',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <ScanListFilter
                        scanStartDate={scanStartDate}
                        scanEndDate={scanEndDate}
                        scanStartDateChanged={this.handleScanStartDateChanged}
                        scanEndDateChanged={this.handleScanEndDateChanged}
                        scanRefreshClicked={this.handleScanRefreshClicked}
                    />
                )
            }
        }
        return options
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('scans'))
    }

    handleScanStartDateChanged = (e) => {
        const {
            dispatch,
        } = this.props

        dispatch(updateField(e.target.name, e.target.value))
    }

    handleScanEndDateChanged = (e) => {
        const {
            dispatch,
        } = this.props

        dispatch(updateField(e.target.name, e.target.value))
    }

    handleScanRefreshClicked = (e) => {
        const {
            dispatch,
            scanStartDate,
            scanEndDate,
        } = this.props

        dispatch(getScans(scanStartDate, scanEndDate))
    }

    componentDidMount() {

        this.getColumns()

        const {
            dispatch,
            scanStartDate,
            scanEndDate,
        } = this.props

        dispatch(getScans(scanStartDate, scanEndDate))
    }

    render() {

        const {
            isLoading,
            scans,
            scanStartDate,
            scanEndDate,
            columns,
        } = this.props

        return (
            <div className="baseContainer scansList">
                <h1>
                </h1>
                <Loader isLoading={isLoading}/>
                <MUIDataTable
                    title={"Scan Log"}
                    data={scans}
                    columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    options={this.getOptions(scans, scanStartDate, scanEndDate)}
                />

            </div>
        )
    }
}

ScanList.defaultProps = {

}

ScanList.propTypes = {
    isLoading: T.bool,
    scans: T.array,
    columns: T.array,
    scanStartDate: T.string,
    scanEndDate: T.string,
}

function mapStateToProps(state) {
    const {scanList} = state
    return {
        scans: scanList.scans,
        columns: scanList.columns,
        scanStartDate: scanList.scanStartDate,
        scanEndDate: scanList.scanEndDate,
        isLoading: scanList.isLoading,
    }
}

export default connect(mapStateToProps)(ScanList)