import React from 'react'
import '../styles/about-page.css'
import ShippingOrdersList from './List/ShippingOrdersList'

const ShippingOrdersListPage = ({ location }) => {
    return (
        <ShippingOrdersList location={location}/>
    )
}

export default ShippingOrdersListPage