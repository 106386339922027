import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CoreLayerUpdateForm from './CoreLayerUpdateForm';
import { 
  Box, 
  Button, 
  FormControl, 
  TextField, 
  Typography } from '@material-ui/core';
import "./coreupdateform.css";
import ConfirmationModal from '../../confirmationModal/ConfirmationModal';
import { getCoreFirmness } from '../../../actions/coreFirmnessActions';
import CoreLayerDraggableContext from "./CoreLayerDraggableContext"
import { getCoreRawMaterials } from '../../../actions/coreRawMaterialActions';
import { getParts } from '../../../actions/partActions';
import { getCore, updateCoreById } from '../../../actions/coreActions';
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';
import { saveCoreLayer } from '../../../actions/coreLayerActions';
import DeleteConfirmationModal from '../../deleteconfirmationmodal/deleteConfirmationModal';
import { triggerBOM } from '../../../actions/skuActions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CoreUpdateForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {state:{row}} = location;
  const [snackbar, setSnackBarStatus] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
    modalStatus: false,
    message: "",
    layerId: ""
  })
  const [modalState, setModalState] = useState({
    modalOpenStatus: false,
    success: "",
    redirect: ""
  });
  const [coreData, setCoreData] = useState({
    parent_core: "",
    size: "",
    firmness: "",
    showNewLayer: false,
    topper: false,
    convoluted: false,
    layers: [],
    depth: "",
    actualDepth: "",
    rawMaterialsArray: [],
    rawMaterial: "",
    coreParts: [],
    partNum: ""

  });
  const handleShowHideNewLayer = () => {
    setCoreData(prevState => ({...prevState, showNewLayer: !prevState.showNewLayer}))
  };
  const getCoreById = () => {
    dispatch(getCore(row.id))
      .then(res => {
        setCoreData(prevState => ({
          ...prevState, 
          parent_core: res.payload.type.parentCore.parent_core, 
          layers: [...res.payload.layers]}))
      })
  }
  const getCoreParts = async () => {
    await dispatch(getParts())
      .then(res => setCoreData(prevState => ({...prevState, coreParts: [...res.payload]})))
  }
  const getRawMaterial = async () => {
    await dispatch(getCoreRawMaterials())
      .then(res => setCoreData(prevState => ({...prevState, rawMaterialsArray: [...res.payload]})))
  }

    const getCoreSize = () => {
    const size = row.size;
    if (typeof size === 'object' && size !== null) {
      const newSize = size.size;
      setCoreData(prevState => ({
        ...prevState,
        size: newSize,
        }))

    } else {
      setCoreData(prevState => ({
        ...prevState,
        size: row.size,
      }))
    }
  }

  const data = async () => {
    await dispatch(getCoreFirmness(row.type.core_firmness_id))
      .then(res => setCoreData(prevState => ({ ...prevState, firmness: res.payload.firmness })))
  };
  const confirmLayerRemoval = (id, index) => {
    if(id == null) {
      setCoreData(prevState => ({
        ...prevState,
        layers: prevState.layers.splice(index, 1)
      }))
    }
    setDeleteConfirmationModal({
      modalStatus: true,
      message: [{
        title: "Are you sure you want to delete?",
        body: "This will permanently remove the layer."
      }],
      layerId: id,
    })
  };
  const addNewLayer = () => {
    let rawMaterialId = null;
    let material = null;
    const rmObj = coreData.rawMaterialsArray.find(material => material.raw_material === coreData.rawMaterial);
    if(rmObj !== undefined) {
      rawMaterialId = rmObj.id;
      material = rmObj
    };
    
    let partId = null;
    let partNum = null;
    let partDescription = null;
    const partObj = coreData.coreParts.find(part => part.num === coreData.partNum);
    if(partObj !== undefined) {
      partId = partObj.id
      partNum = partObj.num
      partDescription = partObj.internal_desc
    };
    
    const layer = {
      id: null,
      core_id: row.id,
      part_id: partId,
      core_layer: coreData.layers.length == 0 ? 0 : coreData.layers.length,
      layer_depth_in: parseFloat(parseFloat(coreData.depth).toFixed(2)),
      part: {
        id: partId,
        num: partNum,
        description: partDescription,
      },
      raw_material_id: rawMaterialId,
      material: material,
      topper: coreData.topper,
      convoluted: coreData.convoluted == true ? 1 : 0,
    };

    
    if(layer.convoluted == 1) {
      layer.convoluted_layer = {
        core_layer_id: layer.id,
        actual_depth_in: coreData.actualDepth
      }
    };
    /* set core_layer = 9 if topper is true  */
    if(layer.topper ) { 
      layer.core_layer = 9;
    } else if(layer.topper == false){
      layer.core_layer = coreData.layers.length;
    }
    delete layer.topper;
    if(layer.part_id && layer.raw_material_id && layer.layer_depth_in) {
      dispatch(saveCoreLayer(layer))
        .then(res => {
          if(res.type === "CORE_LAYER_SAVING_SUCCESS") {
            setSnackBarStatus({open: true})
            setCoreData(prevState => ({
              ...prevState,
              rawMaterial: "",
              partNum: "",
              depth: "",
              actualDepth: "",
              topper: false,
              convoluted: false,
              layers: [res.payload, ...prevState.layers],
              showNewLayer: false,
            }))
          }
        })
        .catch(err => console.log(err))
    }
  };

  const handleUpdateSubmit = async () => {
    let orderedLayers =  coreData.layers.slice().reverse();
    let topper = 0;
    await orderedLayers.forEach(layer => {
      if(layer.core_layer > 90) {
        layer.core_layer = 9
      }
    })
    for(let i = 0; i < orderedLayers.length; i++) {
      if(orderedLayers[i].core_layer !== 9) {
        orderedLayers[i].core_layer = i;
      } else if (orderedLayers[i].core_layer == 9) {
        if(topper == 0) {
          orderedLayers[i].core_layer = 99;
          topper += 1;
        } else if (topper == 1) {
          orderedLayers[i].core_layer = 999;
          topper += 1;
        } else if (topper == 2) {
          orderedLayers[i].core_layer = 9999;
          topper += 1;
        } else if (topper == 3) {
          orderedLayers[i].core_layer = 99999;
          topper += 1;
        } 
      }
    }
    let core = {
      id: row.id,
      core_type_id: row.type.id,
      size_id: row.size_id,
      layers: orderedLayers,
    }
    await dispatch(updateCoreById(core))
      .then(res => {
        if(res.type === "UPDATE_CORE_SAVING_SUCCESS") {
          dispatch(triggerBOM())
          setModalState({
            modalOpenStatus: true,
            success: [
                { title: "Success!!" },
                {
                  body: `Core successfully updated! You will now be redirected back to the parent core.`
                }
            ],
            redirect: `/mat-core/parentCore/${row.type.parent_core_id}`
          })
        }
      })
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarStatus({open: false});
  };

  useEffect(() => {
    data()
    getRawMaterial()
    getCoreParts()
    getCoreById()
    getCoreSize()
  },[]);

  return (
    <Box className='core-update-form'>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbar.open}
        className="success"
        onClose={handleClose}
        autoHideDuration={4000}
        ContentProps={{
            'aria-describedby': 'message-id',
        }}
      >
      <Alert severity="success">CORE LAYER SUCCESSFULLY CREATED</Alert>
      </Snackbar>
      <ConfirmationModal
        modalOpenStatus={modalState.modalOpenStatus}
        redirect={modalState.redirect}
        success={modalState.success}
      />
      <DeleteConfirmationModal
      modalStatus={deleteConfirmationModal.modalStatus}
      message={deleteConfirmationModal.message}
      layerId={deleteConfirmationModal.layerId}
      layers={coreData.layers}
      setModalStatus={setDeleteConfirmationModal}
      setCoreData={setCoreData}
      />
      <Box className='update-heading-box'>
        <Typography variant="h3">Core update</Typography>
        <Typography variant="body1" className='core-warning'>
          MOVE LAYERS AROUND SO THAT THE TOP CORE LAYER IS AT THE TOP
          <br/>
          AND THE BOTTOM CORE LAYER IS AT THE BOTTOM
        </Typography>
      </Box>
      <Box className='parent-core-info-box'>
        <FormControl margin={"normal"} className="core-info-input" variant="outlined" >
          <TextField
            variant='outlined'
            InputProps={{
              readOnly: true,
            }}
            label='Parent Core'
            value={coreData.parent_core}
          />
        </FormControl >
        <FormControl margin={"normal"} className="core-info-input" variant="outlined">
          <TextField
            variant='outlined'
            InputProps={{
              readOnly: true,
            }}
            label="Size"
            value={coreData.size}
          />
        </FormControl >
        <FormControl margin={"normal"} className="core-info-input" variant="outlined">
          <TextField
            variant='outlined'
            InputProps={{
              readOnly: true,
            }}
            label='Firmness'
            value={coreData.firmness}
          />
        </FormControl >
      </Box>
      <CoreLayerDraggableContext confirmLayerRemoval={confirmLayerRemoval} {...coreData} setCoreData={setCoreData}/>
      {!coreData.showNewLayer &&
        <FormControl className='new-layer-btn' margin={"normal"}>
          <Button margin="normal" variant="contained" onClick={handleShowHideNewLayer}>Add New Layer</Button>
        </FormControl>
      }
      {coreData.showNewLayer &&
        <CoreLayerUpdateForm showNewLayer={coreData.showNewLayer} {...coreData} setCoreData={setCoreData} addNewLayer={addNewLayer}/>
      }
      {!coreData.showNewLayer &&
        <Box className='update-box'>
          <Typography variant="body1" className="core-warning">
            MAKE SURE LAYERS ARE IN THE CORRECT ORDER BEFORE UPDATING
          </Typography>
          <Button
            variant='contained'
            className='update-btn'
            onClick={handleUpdateSubmit}
          >
            update core
          </Button>
        </Box>
      }
    </Box>
  )
}
export default CoreUpdateForm;