import {
  REPORT_UPDATE_FIELD,
  REPORTS_LOADING_REQUEST,
  REPORTS_LOADING_SUCCESS,
  REPORTS_LOADING_ERROR,
  REPORT_EMBED_URL_LOADING_REQUEST,
  REPORT_EMBED_URL_LOADING_SUCCESS,
  REPORT_EMBED_URL_LOADING_ERROR,
  REPORT_STATUS_LOADING_ERROR,
  REPORT_STATUS_LOADING_REQUEST,
  REPORT_STATUS_LOADING_SUCCESS,
  INGESTION_STATUS_LOADING_ERROR,
  INGESTION_STATUS_SAVING_SUCCESS,
  INGESTION_STATUS_LOADING_REQUEST
} from "../constants/reportTypes";

import { parseAllToJson } from "../utils/string";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  embedUrl: "",
  dashboard: {},
  date: "",
  statue: "",
  statusList: [],
  ingestion: {},
  sucessValue: true,
  sucess: "",
  open: false
};

export default function reportPageReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_UPDATE_FIELD:
      return {
        ...state,
        isLoading: false,
        [action.name]: action.value
      };
    case REPORT_STATUS_LOADING_REQUEST:
    case REPORTS_LOADING_REQUEST:
    case REPORT_EMBED_URL_LOADING_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSaved: false
      };
    case REPORTS_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false
      };
    case REPORT_STATUS_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        statusList: action.payload
      };
    case REPORT_EMBED_URL_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        embedUrl: action.payload.EmbedUrl
      };
    case REPORT_STATUS_LOADING_ERROR:
    case REPORTS_LOADING_ERROR:
    case REPORT_EMBED_URL_LOADING_ERROR:
      return {
        ...state,
        isLoading: true,
        isError: true
      };
    case INGESTION_STATUS_LOADING_ERROR:
    case INGESTION_STATUS_LOADING_REQUEST:
    case INGESTION_STATUS_SAVING_SUCCESS:
      return {
        ...state,
        isLoading: true,
        isSaved: false,
        sucessValue: false,
        sucess: "",
        saveSuccess: false
      };
    default:
      return {
        ...state,
        isLoading: false
      };
  }
}
