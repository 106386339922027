import * as types from '../constants/sizeTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.SIZE_UPDATE_FIELD,
        name,
        value,
    }
}

export function getSizes() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/sizes`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.SIZES_LOADING_REQUEST,
                types.SIZES_LOADING_SUCCESS,
                types.SIZES_LOADING_ERROR,
            ],
        },
    }
}

export function getSize(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/size/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.SIZE_LOADING_REQUEST,
                types.SIZE_LOADING_SUCCESS,
                types.SIZE_LOADING_ERROR,
            ],
        },
    }
}

export function saveSizeList(sizes){
    const data = []
    for (let field in sizes.data) {
        let row = removeEmptyFields(sizes.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/sizes`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.SIZES_SAVING_REQUEST,
                types.SIZES_SAVING_SUCCESS,
                types.SIZES_SAVING_ERROR,
            ],
        },
    }
}

export function saveSize(size){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/size`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(size),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.SIZE_SAVING_REQUEST,
                types.SIZE_SAVING_SUCCESS,
                types.SIZE_SAVING_ERROR,
            ],
        },
    }
}