import React from 'react';
import ManufacturerList from './List/ManufacturerList';
import '../styles/about-page.css';

export class ManufacturerListPage extends React.Component {

    render() {
        return (
            <ManufacturerList/>
        );
    }
}

export default ManufacturerListPage;
