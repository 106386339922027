import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import {
  getSkuShipping,
  updateSkuShipping,
  createSkuShipping,
  getActiveSkus
} from "../../actions/skuActions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Autocomplete, Alert } from "@material-ui/lab";
import {
  Box,
  Container,
  Grid,
  TextField,
  Tab,
  Tabs,
  Snackbar,
  Backdrop,
  makeStyles,
  Typography,
} from '@material-ui/core';
import BasicTable from './BasicTable';
import { getParts } from '../../actions/partActions';
import { ExcelRenderer } from 'react-excel-renderer';
import { CSVLink } from 'react-csv';
import '../../styles/ShippingAddModal.styles.css';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.75)',
  },
  textStyles: {
    fontSize: '2.5rem',
    textShadow: '2px 0 black',
    fontWeight: 'bold',
  },
}));

const ShippingList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [skuId, setSkuId] = useState([]);
  const [value, setValue] = useState("active");
  const [isActive, setActive] = useState([]);
  const [partId, setPartId] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [sku, setSku] = useState([]);
  const [previousShipping, setPreviousShipping] = useState([]);
  const [inputMessage, setInputMessage] = useState('Upload File');
  const [allSkus, SetAllSkus] = useState([]);
  const [allParts, SetAllParts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState('info');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const csvData = [{ SkuColumn: ' ' }, { ShippingCost: ' ' }];

  const selectedTabStyle = {
    backgroundColor: "#163762",
    color: "#FFFFFF",
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  };

  const unselectedTabStyle = {
    backgroundColor: "rgba(0,0,0,0.0)"
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    getActive();
    getPartNum();
  }, [partId, sku]);

  function getAllData() {
    let active = [];
    dispatch(getSkuShipping()).then((res) => {
      active.push(res.payload);
      SetAllSkus(res);
      let data = active[0].filter((res) => {
        if (res.date_end > moment().format("YYYY-MM-DD")) {
          return active;
        }
      });
      setTableData(data);
      setPreviousShipping(res.payload);
    });
    dispatch(getParts()).then((res) => {
      setPartId(res.payload);
      SetAllParts(res);
    });
    dispatch(getActiveSkus()).then((res) => {
      setSku(res.payload);
    });
  }

  function getPartNum() {
    let getNum = [...partId];
    let table = [];
    getNum.filter((part) => {
      if (part.active === "true")
        tableData.filter((element) => {
          if (element.part_id === part.id) {
            element["partNum"] = part.num;
            table.push(element);
          }
        });
    });
    setAllTableData(table);
    setTableData(table);
    let partID = [];
    partId.filter((part) => {
      partID.push(part.num);
    });
    setSkuId(partID);
  }

  function getActive(data) {
    if (data != null) {
      let filteredData = isActive.filter((ac) => {
        return ac.id != data.part_id;
      });
      setActive(filteredData);
    }
    if (data == null) {
      let unassigned = partId.filter((part) => {
        if ((part.active = "true")) {
          if (sku.find((s) => s.sku == part.num && s.active == "1")) {
            return !tableData.find((f) => f.part_id == part.id);
          }
        }
      });
      setActive([...unassigned]);
    }
  }

  function addNewEntry(newData) {
    isActive.find((part) => {
      if (part.num === newData.partNum) {
        newData["part_id"] = part.id;
      }
    });
    tableData.find((partId) => {
      if (partId.partNum === newData.partNum) {
        newData["part_id"] = partId.part_id;
      }
    });
    let data = {
      id: newData.id,
      part_id: parseInt(newData.part_id),
      shipping_cost: parseFloat(newData.shipping_cost),
      date_start: moment(newData.date_start).format("YYYY-MM-DD"),
      date_end: moment(newData.date_end).format("YYYY-MM-DD")
    };
    setActive([]);
    getActive(data);

    dispatch(createSkuShipping(data)).then((res) => {
      let idCheck = newData.part_id;
      if (res.type === "SKU_SAVING_SUCCESS") {
        let tableToUpdate = tableData.filter(
          (oldData) => oldData.part_id === idCheck
        );
        tableToUpdate.filter((checkDate) => {
          checkDate.date_end > newData.date_start;
        });
        tableToUpdate.map((data) => {
          let startDate = moment(newData.date_start)
            .subtract(1, 'days')
            .format('YYYY-MM-DD');
          let updatedData = {
            id: data.id,
            part_id: parseInt(data.part_id),
            shipping_cost: parseFloat(data.shipping_cost),
            date_start: moment(data.date_start).format("YYYY-MM-DD"),
            date_end: startDate
          };
          upDateEntry(updatedData);
        });
        getAllData();
      }
    });
  }

  function upDateEntry(newData) {
    dispatch(updateSkuShipping(newData)).then((res) => {
      if (res.type === "SKU_SAVING_SUCCESS") {
        getAllData();
      }
    });
  }

  const tableColumns = [
    {
      title: "Part Number",
      field: "partNum",
      type: "numeric",
      editable: "onAdd",
      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => {
        return (
          <Autocomplete
            options={skuId}
            getOptionLabel={(skuId) => skuId.toString()}
            name={"partNum"}
            defaultValue={rowData.partNum ? rowData.partNum : null}
            onChange={(e, value) => {
              e.stopPropagation();
              onChange(value);
              onRowDataChange(value);
            }}
            // onChange={(e, value) => {
            //   e.stopPropagation();
            //   const newRowData = {
            //     ...rowData,
            //     partNum: value,
            //   };
            //   onChange(value);
            //   onRowDataChange(newRowData);
            // }}
            renderInput={(params) => (
              <div
                ref={params.InputProps.ref}
                style={{ marginTop: "-10px", marginLeft: "20px" }}
              >
                <TextField
                  name={"partNum"}
                  {...params.inputProps}
                  variant="standard"
                  margin="normal"
                  inputProps={{
                    ...params,
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                      }
                    }
                  }}
                />
              </div>
            )}
          />
        );
      }
    },
    {
      title: "Sku Shipping Cost",
      field: "shipping_cost",
      type: "numeric",
      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => (
        <TextField
          type="integer"
          value={props.value ? props.value : ""}
          onChange={(e) => {
            e.stopPropagation();
            onChange(e.target.value);
          }}
        />
      )
    }
  ];

  function fileHandler(event) {
    let fileObj = event.target.files[0];
    let indexes = [];
    let newDataToSave = [];
    let oldDataToUpdate = [];
    const errorHandlingString = fileObj.name.toLowerCase().slice(-4);
    setInputMessage(fileObj.name);
    setSnackbarType('success');
    setSnackbarMessage(`All Entries were saved!`);

    if (errorHandlingString !== ".csv") {
      setSnackbarType("error");
      setSnackbarMessage("Please choose a correct File Type");
      handleSnackbarClick();
    } else if (errorHandlingString == '.csv') {
      setOpen(!open);
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          setOpen(false);
          console.log(err);
        } else if (resp.rows[0][1] !== 'ShippingCost') {
          setSnackbarType('error');
          setSnackbarMessage('Please use a ShippingCost CSV');
          setOpen(false);
          handleSnackbarClick();
          return;
        } else {
          for (const index of resp.rows) {
            let foundIndex = undefined;
            let oldShipping = 'this will become old labor info';
            let parentIndex = 'this will become an object';
            for (const value of allParts.payload) {
              if (index[0] === value.num) {
                foundIndex = value.id;
                parentIndex = value;

                let indexObject = {
                  id: "id goes here",
                  part_id: foundIndex,
                  shipping_cost: index[1],
                  parentIndex: parentIndex
                };
                indexes.push(indexObject);
              }
            }
            for (const value of previousShipping) {
              if (foundIndex === value.part_id) {
                oldShipping = value;
              }
            }

            let indexObject = {
              id: undefined,
              part_id: foundIndex,
              shipping_cost: index[1],
              parentIndex: parentIndex,
              previousInfo: oldShipping,
            };
            indexes.push(indexObject);
          }
          indexes.shift();
        }
        for (const index of indexes) {
          for (let i = 0; i < allSkus.payload.length; i++) {
            if (index.part_id == allSkus.payload[i].part_id) {
              index.id = allSkus.payload[i].id;
            }
          }
          let oldData = index.previousInfo;
          let endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
          const updateOldData = {
            id: index.id,
            part_id: index.part_id,
            shipping_cost: oldData.shipping_cost,
            date_start: moment(oldData.date_start).format('YYYY-MM-DD'),
            date_end: endDate,
          };
          oldDataToUpdate.push(updateOldData);
          const newDataEntry = {
            part_id: index.part_id,
            shipping_cost: index.shipping_cost,
            date_start: moment(oldData.date_start).format('YYYY-MM-DD'),
            date_end: moment('9999-01-01').format('YYYY-MM-DD'),
          };
          console.log(newDataEntry);
          if (newDataEntry.part_id === undefined) {
            setSnackbarType('error'),
              setSnackbarMessage(
                'Something went wrong, one or more entries were not updated.'
              );
            continue;
          } else {
            newDataToSave.push(newDataEntry);
          }
        }
        for (const index of oldDataToUpdate) {
          const updateAllPromise = Promise.allSettled([
            dispatch(updateSkuShipping(index)).then((res) => {
              return res.type;
            }),
          ]).then((results) =>
            results.forEach((result) => {
              if (result.value === 'SKU_SAVING_ERROR') {
                const createErrorType = new Promise(
                  (resolve, reject) => setSnackbarType('error'),
                  setSnackbarMessage(
                    'Something went wrong, one or more entries were not updated.'
                  )
                );
              }
            })
          );
        }
        for (const index of newDataToSave) {
          const createAllPromise = Promise.allSettled([
            dispatch(createSkuShipping(index)).then((res) => {
              return res.type;
            }),
          ]).then((results) =>
            results.forEach((result) => {
              if (result.value === 'SKU_SAVING_ERROR') {
                const createErrorType = new Promise(
                  (resolve, reject) => setSnackbarType('error'),
                  setSnackbarMessage(
                    'Something went wrong, one or more entries were not updated.'
                  )
                );
              }
            })
          );
        }
        setOpen(false);
        handleSnackbarClick();
        getAllData();
      });
    }
  }

  const handleSnackbarClick = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  function getTabDetails(tabName) {
    if (tabName === "active") {
      if (allTableData.length) {
        return (
          <fieldset style={{ border: "none" }}>
            <MaterialTable
              key={allTableData.id}
              style={{ color: "black" }}
              title="Sku Shipping Cost"
              options={{
                pageSize: 20,
                addRowPosition: "first",
                actionsColumnIndex: -1
              }}
              columns={tableColumns}
              data={allTableData}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      setAllTableData([...tableData, newData]);
                      const data = {
                        partNum: newData.partNum,
                        shipping_cost: parseFloat(newData.shipping_cost),
                        date_start: moment().format("YYYY-MM-DD"),
                        date_end: moment("9999-01-01").format("YYYY-MM-DD")
                      };
                      addNewEntry(data);
                      resolve();
                    }, 2000);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...tableData];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      let endDate = moment()
                        .subtract(1, "days")
                        .format("YYYY-MM-DD");
                      const updateOldData = {
                        id: oldData.id,
                        part_id: parseInt(oldData.part_id),
                        shipping_cost: parseFloat(oldData.shipping_cost),
                        date_start: moment(oldData.date_start).format(
                          "YYYY-MM-DD"
                        ),
                        date_end: endDate
                      };
                      upDateEntry(updateOldData);
                      const data = {
                        part_id: parseInt(newData.part_id),
                        shipping_cost: parseFloat(newData.shipping_cost),
                        date_start: moment().format("YYYY-MM-DD"),
                        date_end: moment("9999-01-01").format("YYYY-MM-DD")
                      };
                      addNewEntry(data);
                      resolve();
                    }, 1000);
                  })
              }}
            />
          </fieldset>
        );
      }
    } else if (tabName === "without") {
      return <BasicTable active={isActive} />;
    }
  }

  return (
    <>
      <div>
        <Backdrop
          className={classes.backdrop}
          open={open}
          onClick={() => {
            setOpen(false);
          }}
        >
          <Typography display='inline' className={classes.textStyles}>
            Loading, please wait. <br /> This may take a while; <br /> do not
            refresh the page.
          </Typography>
        </Backdrop>
      </div>
      <Container maxWidth='xl'>
        <Grid container justifyContent='center'>
          <Box m={2} pt={3}>
            <Tabs value={value} indicatorColor="primary">
              <Tab
                value="active"
                label="Active Shipping Cost"
                onClick={() => setValue("active")}
                style={
                  value === "active" ? selectedTabStyle : unselectedTabStyle
                }
              />

              <Tab
                value="without"
                label="Parts missing Shipping Cost"
                onClick={() => setValue("without")}
                style={
                  value === "without" ? selectedTabStyle : unselectedTabStyle
                }
              />
            </Tabs>
            <input
              type="file"
              name="file"
              id="file"
              className="inputfile"
              onChange={fileHandler.bind(this)}
              style={{ padding: "10px" }}
            />
            <label htmlFor='file'>{inputMessage}</label>
            <CSVLink data={csvData} filename='ShippingCost.csv'>
              <label
                className='shippingAddButton'
                style={{ marginLeft: '5px' }}
              >
                Download ShippingCost Template
              </label>
            </CSVLink>
          </Box>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarType}
              sx={{ width: "150%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Grid>
        <div className="dataTable_row">{getTabDetails(value)}</div>
      </Container>
    </>
  );
};

export default ShippingList;
