import {
    SCHEDULE_UPDATE_FIELD, SCHEDULE_LOADING_REQUEST, SCHEDULE_LOADING_SUCCESS, SCHEDULE_LOADING_ERROR,
    SCHEDULE_IMPORT_LOADING_REQUEST, SCHEDULE_IMPORT_LOADING_SUCCESS, SCHEDULE_IMPORT_LOADING_ERROR,
    SCHEDULE_SAVING_REQUEST, SCHEDULE_SAVING_SUCCESS, SCHEDULE_SAVING_ERROR,
    SCHEDULE_DELETING_REQUEST, SCHEDULE_DELETING_ERROR, SCHEDULE_DELETING_SUCCESS, SCHEDULE_GET_SKUS_REQUEST,
    SCHEDULE_GET_SKUS_ERROR, SCHEDULE_GET_SKUS_SUCCESS, SCHEDULE_SKU_CHECK_REQUEST, SCHEDULE_SKU_CHECK_SUCCESS, SCHEDULE_SKU_CHECK_ERROR
} from '../constants/scheduleTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    schedule: [],
    rows: [],
    cols: [],
    columns: [],
    file: '',
    date: '',
    skuObj: {},
    infoSkus: []
}

export default function scheduleReducer(state = initialState, action) {
    switch (action.type) {
        case SCHEDULE_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
                date: action.value,
            }
        case SCHEDULE_LOADING_REQUEST:
        case SCHEDULE_IMPORT_LOADING_REQUEST:
        case SCHEDULE_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case SCHEDULE_GET_SKUS_REQUEST:
        case SCHEDULE_DELETING_REQUEST:
        case SCHEDULE_SKU_CHECK_REQUEST:    
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case SCHEDULE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                schedule: action.payload,
            }
        case SCHEDULE_IMPORT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                schedule: action.schedule,
                rows: action.rows,
                cols: action.cols,
            }
        case SCHEDULE_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                schedule: action.payload,
            }
        case SCHEDULE_DELETING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                schedule: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            const columns = parseAllToJson(action.payload, 'options')
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: columns,
            }
        case SCHEDULE_GET_SKUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                skuObj: action.payload,
            }
        case SCHEDULE_SKU_CHECK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                infoSkus: action.payload,
            }
        case SCHEDULE_LOADING_ERROR:
        case SCHEDULE_IMPORT_LOADING_ERROR:
        case SCHEDULE_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case SCHEDULE_GET_SKUS_ERROR:
        case SCHEDULE_DELETING_ERROR:
        case SCHEDULE_SKU_CHECK_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}