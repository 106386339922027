import React from 'react';
import { Box, Card, Modal, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./confirmationModal.css"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ConfirmationModal = ({ modalOpenStatus, success, redirect }) => {
  const history = useHistory();
  const handleClose = () => {
    if(redirect) {
      history.push(`${redirect}`)
    }
 }

 const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  boxShadow: 23,
  p: 4,
 }
  return (
    <>
    {success ?
      <>
        <Modal
          open={modalOpenStatus}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={style}>
            <Card
            style={{ overflow: "auto", height: "auto", width: "500px", paddingBottom: "25px" }}
            >
              <div className="modal-title">
                <Typography
                  className='modal-typo-title'
                  variant='h5'
                >
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="3x"
                    color="white"
                  />
                </Typography>
              </div>
              {success.map((field, idx) => (
                  <div className="modal-description" key={idx}>
                    <Typography id="modal-modal-title" variant="h4" >
                      {field.title}
                    </Typography>
                    <Typography className="modal-typo-description">
                      {field.body}
                    </Typography>
                  </div>
                ))}
            </Card>
          </Box>
        </Modal>
      </> 
    :
      ""
    }
    </>
  )
};
export default ConfirmationModal