import React from 'react';
import PrototypeList from './List/PrototypeList';
import '../styles/about-page.css';

export class PrototypeListPage extends React.Component {

    render() {
        return (
            <PrototypeList/>
        );
    }
}

export default PrototypeListPage;
