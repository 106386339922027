
import {
    UPDATE_CORE_SAVING_REQUEST,
    UPDATE_CORE_SAVING_SUCCESS,
    UPDATE_CORE_SAVING_ERROR,
    CORE_UPDATE_FIELD,
    CORE_LOADING_REQUEST,
    CORE_LOADING_SUCCESS,
    CORE_LOADING_ERROR,
    CORE_SAVING_REQUEST,
    CORE_SAVING_SUCCESS,
    CORE_SAVING_ERROR,
    CORES_LOADING_ERROR,
    CORES_LOADING_REQUEST,
    CORES_LOADING_SUCCESS,
    CORES_SAVING_ERROR,
    CORES_SAVING_REQUEST,
    CORES_SAVING_SUCCESS, CORE_NEW_SUCCESS, CORE_ADD_LAYER,
} from '../constants/coreTypes'
import {
  CORE_LAYER_DELETE_ERROR,
  CORE_LAYER_DELETE_SUCCESS,
  CORE_LAYER_DELETE_REQUEST,
} from "../constants/coreLayerTypes"
import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";
import {addLink} from "../utils/column"
import {compareValues} from '../utils/sort'

const initialState = {
    isError: false,
    isNew: false,
    isLoading: false,
    isSaved: false,
    cores: [],
    columns: [],
    rows: [],
    id: -1,
    coreTypeId: -1,
    coreType: '',
    sizeId: -1,
    sizeName: '',
    size: {},
    type: {},
    layers: [],
    sizes: [],
}

export default function coreReducer(state = initialState, action) {
    switch (action.type) {
        case CORE_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case CORE_ADD_LAYER:
            return {
                ...state,
                layers: action.layers.sort(compareValues('core_layer', 'desc')),
            }
        case CORES_LOADING_REQUEST:
        case CORES_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
        case CORE_LOADING_REQUEST:
        case CORE_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case CORE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                coreTypeId: action.payload.core_type_id,
                coreType: action.payload.type.core_type,
                sizeId: action.payload.size_id,
                type: action.payload.type,
                layers: action.payload.layers.sort(compareValues('core_layer', 'desc')) ,
            }
        case CORE_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isNew: '',
                isError: false,
                isSaved: true,
                id: '',
                coreTypeId: '',
                coreType: '',
                sizeId: '',
                sizeName: '',
                size: '',
                type: '',
                layers: '' ,
            }
        case CORES_LOADING_ERROR:
        case CORES_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
        case CORE_LOADING_ERROR:
        case CORE_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case CORES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                cores: action.payload,
            }
        case CORES_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                cores: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        case CORE_LAYER_DELETE_REQUEST:
        case CORE_LAYER_DELETE_SUCCESS:
          return {
            ...state,
          }
        case CORE_LAYER_DELETE_ERROR:
        case UPDATE_CORE_SAVING_REQUEST:
        case UPDATE_CORE_SAVING_SUCCESS:
          return {
            ...state,
          }
        case UPDATE_CORE_SAVING_ERROR:
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}