import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import T from 'prop-types';
import {connect} from "react-redux";
import NotFoundPage from "./NotFoundPage";

class ProtectedRoute extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {component: Component, role, ...rest} = this.props;
        //Component is the component being passed into the <ProtectedRoute>
        //role is the array of roles that are allowed to access said  component
        const {userRole} = this.props;
        //userRole comes off of the user who is logged in
        // console.log(userRole, 'userRole from REDUCER')
        // console.log(this.props.role, 'role on props')
        // we take the userRole array (list of roles the user logged in has)
        // and compare it with role array (list of roles allowed to access the component trying to be accessed)
        let authorizedRole = userRole && role ? userRole.some(r=> role.includes(r)) : '';
        //so now authorizedRole will be a truthy or falsely value
        // console.log(authorizedRole, 'true if role we have is allowed')
        const isAuthenticated = localStorage.getItem('token');

        if(isAuthenticated){
            //user is authenticated and they are authorized return what they're accessing
            if(authorizedRole){
                    return (
                        <Route {...rest}>
                            <Component />
                        </Route>
                    )
            }else if(!userRole && authorizedRole == undefined){
                return (
                    <Redirect to={{ pathname: '/logout' }}/>
                )
            }
            //user is authenticated but they are not authorized and role exists for the component
            //    unauthorized page
            else if(!authorizedRole && role){
                return (
                    <Redirect to={{ pathname: '/unauthorized' }} />
                )
            }
            //the route does not have a role, then allow them to go (ONLY CASE FOR LOGOUT, HOME, AND UNAUTHORIZED)
            else if(!role){
                return (
                        <Route {...rest}>
                            <Component />
                        </Route>
                    )
            }
            //user is authenticated but trying to access route that does not exist
            //    not found page
            else{
                return (
                    <Route>
                        <NotFoundPage/>
                    </Route>
                )
            }
        }
        //if a user is not authenticated then only direct to login page
        else {
            return (
                    <Redirect to={{ pathname: '/login' }} />
                )
        }


    }
}

ProtectedRoute.propTypes = {
    userRole: T.array
}

function mapStateToProps(state) {
    const {loginForm} = state
    return {
        userRole: loginForm.userRole,
    }
}

export default connect(mapStateToProps)(ProtectedRoute);