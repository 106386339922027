import React from 'react'
import '../styles/about-page.css'
import FamilyForm from './forms/FamilyForm'

const FamilyFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Family</h2>
            <FamilyForm location={location}/>
        </div>
    )
}

export default FamilyFormPage