import React from 'react'
import '../styles/about-page.css'
import SizeForm from './forms/SizeForm'

const SizeFormPage = ({ location }) => {
    return (
        <div>
            <h2 className="alt-header">Size</h2>
            <SizeForm location={location}/>
        </div>
    )
}

export default SizeFormPage