import React  from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import {getSizes, saveSizeList} from "../../actions/sizeActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

class SizeList extends React.Component {

    getOptions(data) {
        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <AddNewButton
                        entity={"size/-1"}
                        history={this.props.history}
                        data={data}
                    />
                )
            }
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            sizes: [],
            rows: [],
            cols: [],
            columns: [],
            filename: '',
            file: '',
        }
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('size'))
    }

    //Toolbar functions
    handleSizeSaveClick = () => {
        const {
            dispatch,
            sizes,
        } = this.props

        const data = {
            data: sizes.concat(this.state.sizes),
            filename: this.state.filename,
        }

        dispatch(saveSizeList(data))
        this.setState({
            cols: [],
            rows: [],
            sizes: [],
            filename: '',
        })
    }
    handleSizeDeleteClick = () => {
        this.setState({
            cols: [],
            rows: [],
            schedule: [],
        })
    }
    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {

        this.getColumns()

        const {
            dispatch,
        } = this.props

        dispatch(getSizes())
    }

    render() {
        const {
            isLoading,
            sizes,
            columns,
        } = this.props

        return (
            <div className="baseContainer sizeList">
                <h1>

                </h1>
                <MUIDataTable
                    title={"Sizes"}
                    data={sizes.concat(this.state.sizes)}
                    columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    options={this.getOptions(sizes.concat(this.state.sizes))}
                />
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

SizeList.defaultProps = {
    date: getDBFormattedDate()
}

SizeList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    sizes: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { sizeReducer } = state
    return {
        isLoading: sizeReducer.isLoading,
        sizes: sizeReducer.sizes,
        rows: sizeReducer.rows,
        cols: sizeReducer.cols,
        columns: sizeReducer.columns,
        file: sizeReducer.file,
    }
}

export default withRouter(connect(mapStateToProps)(SizeList))