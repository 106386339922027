import * as types from '../constants/panelTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.PANEL_UPDATE_FIELD,
        name,
        value,
    }
}
export function getAllPanels() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/panels`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PANELS_LOADING_REQUEST,
                types.PANELS_LOADING_SUCCESS,
                types.PANELS_LOADING_ERROR,
            ],
        },
    }
}

export function getPanel(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/panel/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PANEL_LOADING_REQUEST,
                types.PANEL_LOADING_SUCCESS,
                types.PANEL_LOADING_ERROR,
            ],
        },
    }
}

export function savePanel(panel){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/panel`, // TODO: make base URL constant
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(panel),
            options: {
                mode: 'cors',
            },
            types: [
                types.PANEL_SAVING_REQUEST,
                types.PANEL_SAVING_SUCCESS,
                types.PANEL_SAVING_ERROR,
            ],
        },
    }
}