import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getBatches,
  updateBatches,
  createBatch,
  deleteBatch
} from "../../../actions/batchActions";
import MaterialTable from "@material-table/core";
import { Container, TextField, Box, CircularProgress } from "@material-ui/core";
import SkuModal from "./skuModal";

const PriorityBatch = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [sucess, setSucess] = useState("");
  const [sucessValue, setSucessValue] = useState([]);
  const [modalType, setModalType] = useState("");

  useEffect(() => {
    getAllData();
  }, []);

  function getAllData() {
    dispatch(getBatches()).then((res) => {
      setData(res.payload);
    });
  }

  async function upDateEntry(newData) {
    dispatch(updateBatches(newData)).then((res) => {
      if (res.type === "BATCHES_LOADING_SUCCESS") {
        getAllData();
        setSucess([
          { title: "Sucess!!" },
          {
            body: "Batch sucessfully updated!"
          }
        ]);
        setSucessValue(true);
        setModalType("sucess");
      }
    });
  }

  
  async function addNewEntry(newData) {
    dispatch(createBatch(newData)).then((res) => {
      if (res.type === "BATCHES_LOADING_SUCCESS") {
        getAllData();
        setSucess([
          { title: "Sucess!!" },
          {
            body: "Batch sucessfully added!"
          }
        ]);
        setSucessValue(true);
        setModalType("sucess");
      }
    });
  }
  async function deleteEntry(newData) {
    dispatch(deleteBatch(newData)).then((res) => {
      if (res.type === "BATCHE_DELETING_SUCCESS") {
        getAllData();
      }
    });
  }
  function validation(newData) {
    let error = "";
    data.find((dta) => {
      if (dta.batch_number === newData.batch_number) {
        error = [
          { title: "Error" },
          {
            body:
              "Batch Number " +
              newData.batch_number +
              " is already in use please use unique number."
          }
        ];
        setSucessValue(true);
        setModalType("error");
      }
    });
    if (!newData.batch) {
      error = [
        { title: "Error" },
        {
          body: "Batch Name can not be null"
        }
      ];
      setSucessValue(true);
      setModalType("error");
     
    }
    if (error) {
      setSucess(error);
      setSucessValue(true);
      setModalType("error");
      return false;
    }
    return true;
  }
  const tableColumns = [
    {
      title: "Batch Number",
      field: "batch_number",
      type: "numeric",
      editComponent: (props) => (
        <TextField
          inputProps={{
            maxLength: 3,
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }
          }}
          value={props.value ? props.value : ""}
          onChange={(e) => {
            let input = e.target.value;
            if (
              !input ||
              (input[input.length - 1].match("[0-9]") &&
                input[0].match("[1-9]"))
            )
              props.onChange(e.target.value);
          }}
        />
      )
    },
    {
      title: "Batch Name",
      field: "batch",
      editComponent: (props) => (
        <TextField
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }
          }}
          value={props.value ? props.value : ""}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        />
      )
    }
  ];
  return (
    <div>
      <SkuModal
        open={sucessValue}
        value={sucessValue}
        sucess={sucess}
        modalType={modalType}
      />
      {data ? (
        <Container className="table-container">
          <MaterialTable
            className="batch-table"
            style={{ color: "black" }}
            title="Priority Batch Managment"
            options={{
              pageSize: 20,
              addRowPosition: "first",
              actionsColumnIndex: -1
            }}
            columns={tableColumns}
            data={data}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const isValid = validation(newData);
                    if (isValid) {
                      setData([...data, newData]);
                      addNewEntry(newData);
                      resolve();
                    }
                    reject();
                    resolve();
                  }, 1000);
                }),

              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    upDateEntry(newData);

                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);
                    deleteEntry(oldData);
                    resolve();
                  }, 1000);
                })
            }}
          />
        </Container>
      ) : (
        <Container style={{ justifyContent: "center" }} maxWidth="xl">
          <div className="progress-bar">
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={200} />
            </Box>
          </div>
        </Container>
      )}
    </div>
  );
};

export default PriorityBatch;
