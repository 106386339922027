import * as types from '../constants/coreRawMaterialTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.CORE_RAW_MATERIAL_UPDATE_FIELD,
        name,
        value,
    }
}

export function getCoreRawMaterials() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/materials`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_RAW_MATERIALS_LOADING_REQUEST,
                types.CORE_RAW_MATERIALS_LOADING_SUCCESS,
                types.CORE_RAW_MATERIALS_LOADING_ERROR,
            ],
        },
    }
}

export function getCoreRawMaterial(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/material/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_RAW_MATERIAL_LOADING_REQUEST,
                types.CORE_RAW_MATERIAL_LOADING_SUCCESS,
                types.CORE_RAW_MATERIAL_LOADING_ERROR,
            ],
        },
    }
}

export function saveCoreRawMaterialList(coreRawMaterials){
    const data = []
    for (let field in coreRawMaterials.data) {
        let row = removeEmptyFields(coreRawMaterials.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/materials`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_RAW_MATERIALS_SAVING_REQUEST,
                types.CORE_RAW_MATERIALS_SAVING_SUCCESS,
                types.CORE_RAW_MATERIALS_SAVING_ERROR,
            ],
        },
    }
}

export function saveCoreRawMaterial(coreRawMaterial){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/material`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(coreRawMaterial),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_RAW_MATERIAL_SAVING_REQUEST,
                types.CORE_RAW_MATERIAL_SAVING_SUCCESS,
                types.CORE_RAW_MATERIAL_SAVING_ERROR,
            ],
        },
    }
}