import React from 'react';
import UserList from './List/UserList';
import '../styles/about-page.css';

export class UserListPage extends React.Component {

    render() {
        return (
            <UserList/>
        );
    }
}

export default UserListPage
