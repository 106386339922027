import React, { useEffect, useState } from "react";
import { faArrowCircleLeft, faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { testPrinter } from "../../actions/scanActions";
import "./style.css";
import { useHistory } from "react-router-dom";
import { PRINT_NODE_USERNAME, PRINT_NODE_PASSWORD } from "../../utils/api";

const axios = require("axios");

const Connect = () => {
  const dispatch = useDispatch();
  const [printNodePrinters, setPrintNodePrinters] = useState([]);
  const [boxlabel, setBoxlabel] = useState([]);
  const [selectedStationPrinter, setSelectedStationPrinter] = useState([]);
  let history = useHistory();

  useEffect(() => {
    const getPrinter = async () => {
      let res = await axios.get("https://api.printnode.com/printers", {
        auth: {
          username: PRINT_NODE_USERNAME,
          password: PRINT_NODE_PASSWORD
        }
      });
      setPrintNodePrinters([...res.data]);
    };
    getPrinter();
    if (window.localStorage.getItem("station printer/s") != null) {
      let printerIds = [];
      JSON.parse(window.localStorage.getItem("station printer/s")).map(
        (printer) => printerIds.push(printer.printerId)
      );
      JSON.parse(window.localStorage.getItem("station printer/s")).map(
        (printer) => setBoxlabel((prevState) => [...prevState, printer])
      );
      setSelectedStationPrinter(printerIds);
    }
    let station = window.localStorage.getItem("Printer Station");
    JSON.parse(station);
  }, []);

  const handleSelectStationPrinters = (e) => {
    let selectedPrinter = [];
    e.target.value.map((printerId) => {
      printNodePrinters.map((printer) => {
        if (printer.id === printerId) {
          selectedPrinter.push({ name: printer.name, printerId: printer.id });
          JSON.stringify(selectedPrinter);
          setBoxlabel(selectedPrinter);
        }
      });
    });
    setBoxlabel(selectedPrinter);
    setSelectedStationPrinter(e.target.value);
    window.localStorage.setItem(
      "station printer/s",
      JSON.stringify(selectedPrinter)
    );
  };

  let testButton = (
    <>
      <Grid item xs={12}>
        {boxlabel.map((selectedPrinter) => (
          <Box mt={3} key={selectedPrinter.printerId}>
            <Button
              className="connect_button"
              onClick={(e) => {
                printerTest(selectedPrinter.printerId, selectedPrinter.name);
              }}
            >
              Test Selected Printer
            </Button>
          </Box>
        ))}
      </Grid>
    </>
  );

  let printerName = (
    <Grid item xs={12}>
      {boxlabel.map((selectedPrinter) => (
        <Box mt={3} key={selectedPrinter.printerId}>
          <Typography style={{ fontSize: "20px" }}>
            {selectedPrinter.name} - {selectedPrinter.printerId}
          </Typography>
        </Box>
      ))}
    </Grid>
  );

  const printerTest = (name, locationArea) => {
    const data = {
      status_id: 1,
      printerName: locationArea,
      printerId: name
    };
    dispatch(testPrinter(data));
  };

  return (
    <>
      <Grid container className="connections-grid">
        <Grid container >
          <Grid>
            <Box m={4} style={{ display: "flex" }} display="inline-block">
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                size="2x"
                style={{ marginTop: "4px", color: "163762" }}
              />
              &nbsp;&nbsp;
              <Link
                className="connect-header"
                onClick={() => {
                  history.push("/production");
                }}
                variant="h5"
              >
                Return to Scan Production
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Container>
          <Box m={4} ml={0} display="inline-block" style={{ display: "flex" }} className="connect-header-box">
            <FontAwesomeIcon
              icon={faWifi}
              size="3x"
              style={{ marginTop: "5px", color: "163762" }}
            />
            &nbsp;&nbsp;
            <Typography className="connect-header" variant="h3">
              Connections
            </Typography>
          </Box>
        </Container>
        <Grid item xs={12} className="printer_container-grid">
          <Container className="printer_container">
            <Box ml={5} mr={5} className="printer-box">
              <Grid
                container
                style={{ borderTop: "solid 5px #2894c3", marginTop: "15px" }}
              >
                <Grid item xs={3}>
                  <Box mt={3}>
                    <h2 variant="h4"> Select Printer(s)</h2>
                    <>
                      <Box mt={4}>
                        <Grid item xs={8}>
                          <Select
                            variant="outlined"
                            fullWidth
                            multiple
                            onChange={handleSelectStationPrinters}
                            value={selectedStationPrinter}
                            renderValue={(selected) => {
                              return (
                                <>
                                  {selected.map((value) => (
                                    <Box key={value}>
                                      <Typography
                                        style={{ fontStyle: "oblique" }}
                                      >
                                        {value}
                                      </Typography>
                                    </Box>
                                  ))}
                                </>
                              );
                            }}
                          >
                            {printNodePrinters.map((option) => (
                              <MenuItem value={option.id} key={option.id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={selectedStationPrinter.includes(
                                        option.id
                                      )}
                                    />
                                  }
                                />
                                {option.name} - {option.id}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Box>
                    </>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mt={3}>
                    <h2 variant="h4"> Printer Selected</h2>
                    <>{printerName}</>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box mt={3} ml={5}>
                    <h2>Test Selected Printer</h2>
                    <>{testButton}</>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Grid>
        {/* <Grid item xs={12}>
          <Box mt={3}>
            <Container className="printer_container">
              <Box ml={5}>
                <h2>Scanner Connection</h2>
              </Box>
            </Container>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default Connect;
