import {
    ROLES_LOADING_ERROR, ROLES_LOADING_SUCCESS, ROLES_LOADING_REQUEST
} from '../constants/roleTypes'
import T from "prop-types";

const initialState = {
    isError: false,
    isLoading: false,
    saveSuccess: false,
    roles: []
}

export default function roleReducer(state = initialState, action) {
    switch (action.type) {
        case ROLES_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case ROLES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                roles: action.payload
            }
        case ROLES_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
                saveSuccess: false,
            }
        default:
            return state
    }
}