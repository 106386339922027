import React from "react";
import T from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {
  getFamily,
  saveFamily,
  updateFamilyField,
  addFabricLayer,
  getBuildType,
  updateFamily,
  getFamilies,
  updateQuickshipBox
} from "../../actions/familyActions";
import { Grid } from "@material-ui/core";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";

class FamilyForm extends React.Component {
  constructor(props) {
    super(props);
    // Bind the this context to the handler function
    this.handleFamilyFieldChange = this.handleFamilyFieldChange.bind(this);
    this.handleQuickshipBoxChange = this.handleQuickshipBoxChange.bind(this);
  }
  state = {
    familyError: "",
    buildType: [],
    familyBuildType: "",
    //Modal state
    modalOpenStatus: false,
    success: "",
    redirect: "",
    quickship: this.props.quickship,
  };

  async componentDidMount() {
    await this.getFamily();
  }

  getFamily = async (e) => {
    let builds = [];
    let fam = [];
    const { dispatch, isLoading } = this.props;
    await dispatch(getBuildType()).then((res) => {
      builds.push(res.payload);
    });
    await dispatch(getFamilies());
    const id = this.props.match.params.id;
    if (id) {
      await dispatch(getFamily(id)).then((res) => {
        fam.push(res.payload);
      });
    }

    builds.filter((bam) => {
      
      this.setState({ buildType: bam });
      bam.filter((bd) => {
        fam.find((fm) => {
          if (bd.id == fm.build_type_id) {
            this.setState({ familyBuildType: bd.build_type });
          }
          this.setState({ quickship: Boolean(fm.quick_ship) });
        });
      });
    });
  };

  handleFamilyFieldChange = async (e) => {
    const { dispatch } = this.props;
    await dispatch(
      updateFamilyField(e.target.name, e.target.value, e.target.innerText)
    );
  };
  
  handleQuickshipBoxChange = async(value) => {
    const { dispatch } = this.props;

    await dispatch(
      updateQuickshipBox(value)
      );
  };

  handleQuickship = (value) => {
    this.setState({ quickship: value});
  }


  handleSubmit = async (e) => {
    e.preventDefault();
    const { isError, dispatch, id, family, families, quickship } = this.props;
    let buildType = "";
    this.state.buildType.filter((f) => {
      if (f.build_type == this.state.familyBuildType) {
        buildType = f.id;
      }
    });
    let familyExists = await families.find(f => f.family === family)
      if(familyExists){
        if (familyExists.family === family) {
          const familyObj = {
            id: familyExists.id,
            family: family,
            build_type_id: buildType ? buildType : null,
            quick_ship: this.state.quickship
          };
          dispatch(updateFamily(familyObj))
            .then((res) => {
              if (res.type === "FAMILY_SAVING_SUCCESS") {
                this.setState({ 
                  modalOpenStatus: true,
                  success: [
                    { title: "Success!!" },
                    {
                      body: `Family successfully updated! You will now be redirected back to the families list.`
                    }
                  ],
                  redirect: `/families`
                });
              }
            });
        }
      } else{
        if (!id || id === -1) {
            const familyObj = {
                id: id,
                family: family,
                build_type_id: buildType ? buildType : null,
                quick_ship: this.state.quickship
              };
            delete familyObj.id;
            await dispatch(saveFamily(familyObj))
              .then((res) => {
                if (res.type === "FAMILY_SAVING_SUCCESS") {
                  this.setState({ 
                    modalOpenStatus: true,
                    success: [
                      { title: "Success!!" },
                      {
                        body: `New Family successfully created! You will now be redirected back to the families list.`
                      }
                    ],
                    redirect: `/families`
                  });
                }
              });
          }
      }
  }

  render() {
    const { isLoading, isError, saveSuccess, id, family, families, quickship } =
      this.props;
    return (
      <div className="baseContainer projectForm" style={{height: "100vh"}}>
        <ConfirmationModal
           modalOpenStatus={this.state.modalOpenStatus}
           success={this.state.success}
           redirect={this.state.redirect}
        />
        <h1></h1>
        <div>
          <Grid container spacing={9}>
            <Grid item xs={3}>
              <TextField
                label="Family"
                name="family"
                margin="normal"
                variant="outlined"
                className="name-field"
                value={family}
                onChange={this.handleFamilyFieldChange}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "15px" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="built_type_id"
                options={this.state.buildType.map(
                  (option) => option.build_type
                )}
                defaultValue={
                  this.state.familyBuildType ? this.state.familyBuildType : ""
                }
                value={this.state.familyBuildType}
                fullWidth={false}
                onChange={(e, value) => {
                  this.setState({ familyBuildType: value }),
                  this.handleFamilyFieldChange(e, this.state.familyBuildType);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Build Type"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "15px" }}>
              <FormControlLabel
                control={<Checkbox
                  value="checkBox"
                  checked={this.state.quickship}
                  onClick={(e) => {
                    this.setState({ quickship: !this.state.quickship })
                    this.handleQuickshipBoxChange(e.target.checked);
                  }}>
                </Checkbox>}
                label="Quickship?"
                labelPlacement="end"
                
              >   
              </FormControlLabel>
            </Grid>
          </Grid>
        </div>

        {this.state.familyError != "" ? (
          <span style={{ fontSize: 18, color: "red", fontWeight: "bold" }}>
            {this.state.familyError}
          </span>
        ) : (
          ""
        )}
        <div style={{ marginTop: 20 }}>
          <Button
            variant="contained"
            style={{ background: "rgb(30, 66, 113)", color: "white" }}
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

FamilyForm.defaultProps = {};

FamilyForm.propTypes = {
  isLoading: T.bool,
  isError: T.bool,
  saveSuccess: T.bool,
  id: T.number,
  family: T.string,
  families: T.array,
  build_type_id: T.number,
  quickship: T.bool
};

function mapStateToProps(state) {
  const { familyReducer } = state;
  return {
    isError: familyReducer.isError,
    saveSuccess: familyReducer.saveSuccess,
    id: familyReducer.id,
    family: familyReducer.family,
    families: familyReducer.families,
    build_type_id: familyReducer.build_type_id,
    quickship: familyReducer.quickship
  };
}

export default withRouter(connect(mapStateToProps)(FamilyForm));
