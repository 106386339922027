
export const CORE_LAYER_UPDATE_FIELD = 'CORE_LAYER_UPDATE_FIELD'

export const CORE_LAYER_LOADING_REQUEST = 'CORE_LAYER_LOADING_REQUEST'
export const CORE_LAYER_LOADING_SUCCESS = 'CORE_LAYER_LOADING_SUCCESS'
export const CORE_LAYER_LOADING_ERROR = 'CORE_LAYER_LOADING_ERROR'

export const CORE_LAYER_NEW_SUCCESS = 'CORE_LAYER_NEW_SUCCESS'

export const CORE_LAYERS_LOADING_REQUEST = 'CORE_LAYERS_LOADING_REQUEST'
export const CORE_LAYERS_LOADING_SUCCESS = 'CORE_LAYERS_LOADING_SUCCESS'
export const CORE_LAYERS_LOADING_ERROR = 'CORE_LAYERS_LOADING_ERROR'

export const CORE_LAYER_IMPORT_LOADING_REQUEST = 'CORE_LAYER_IMPORT_LOADING_REQUEST'
export const CORE_LAYER_IMPORT_LOADING_SUCCESS = 'CORE_LAYER_IMPORT_LOADING_SUCCESS'
export const CORE_LAYER_IMPORT_LOADING_ERROR = 'CORE_LAYER_IMPORT_LOADING_ERROR'

export const CORE_LAYER_SAVING_REQUEST = 'CORE_LAYER_SAVING_REQUEST'
export const CORE_LAYER_SAVING_SUCCESS = 'CORE_LAYER_SAVING_SUCCESS'
export const CORE_LAYER_SAVING_ERROR = 'CORE_LAYER_SAVING_ERROR'

export const CORE_LAYERS_SAVING_REQUEST = 'CORE_LAYERS_SAVING_REQUEST'
export const CORE_LAYERS_SAVING_SUCCESS = 'CORE_LAYERS_SAVING_SUCCESS'
export const CORE_LAYERS_SAVING_ERROR = 'CORE_LAYERS_SAVING_ERROR'

export const CORE_LAYER_ADDED = 'CORE_LAYER_ADDED'

export const CORE_LAYER_DELETE_REQUEST = "CORE_LAYER_DELETE_REQUEST"
export const CORE_LAYER_DELETE_SUCCESS = "CORE_LAYER_DELETE_SUCCESS"
export const CORE_LAYER_DELETE_ERROR = "CORE_LAYER_DELETE_ERROR"
