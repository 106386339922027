import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  Button,
  InputLabel,
  Backdrop,
  Tabs,
  Snackbar
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSatellite,
  faSearchLocation,
  faSearchMinus,
  faSync,
  faTimesCircle,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { findById } from "../../actions/scheduleActions";
import {
  getStationlists,
  getStatusList,
  postScanLog,
  reprintLabelByScanInfo,
  validateBatchScans,
  findSibling
} from "../../actions/scanActions";
import moment from "moment";
import "./style.css";
import LinearProgressBar from "./LinearProgressBar";
import { useLocation, useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { USER_SCAN_PASS } from "../../utils/api";
import SubComponentScan from "./SubComponentScan";
import {
  loginNewUser,
  getUserTotalScans,
  autoLogout
} from "./ProductionHelperFunctions/ProductionHelper";

const Production = () => {
  //// logout after 15min of no use
  autoLogout();
  const dispatch = useDispatch();
  let history = useHistory();
  let locationHistory = useLocation();
  const inputField1Ref = useRef(null);
  const initialState = "";
  const [userId, setUserId] = useState(initialState);
  const [status, setStatus] = useState([]);
  const [statusId, setStatusId] = useState(initialState);
  const [scan, setScan] = useState(initialState);
  const [refresh, setRefresh] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [scanLogObj, setScanLogObj] = useState({});
  const [epc, setEpc] = useState(initialState);
  const [stationName, setStationName] = useState(null);
  const [station, setStation] = useState([]);
  const [machineRequired, setMachineRequired] = useState(false);
  const [barcodeSkuName, setBarcodeSkuName] = useState(null);
  const [user, setUser] = useState(initialState);
  const [totalScanned, setTotalScanned] = useState(null);
  const [area, setArea] = useState(null);
  const [reprint, setReprint] = useState(false);
  const [reprintState, setReprintState] = useState({});
  const [stationPrinterInfo, setStationPrinterInfo] = useState([]);
  const [serialTag, setSerialTag] = useState(null);
  const [location, setLocation] = useState({});
  const [loading, setLoading] = useState(false);
  const [sucessGreen, setSucessGreen] = useState(false);
  const [failRed, setFailRed] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [overlaybackdrop, setOverlaybackdrop] = useState(false);
  const [scanError, setScanError] = useState(initialState);
  const [component, setComponent] = useState(null);
  const [machineNumber, setMachineNumber] = useState(initialState);
  const [openSucess, setOpenSucess] = useState(false);
  const [previouslyScanned, setPreviouslyScanned] = useState([]);
  const [flatPackLabel, setFlatPackLabel] = useState(initialState);
  const [orderNumber, setOrderNumber] = useState(initialState);

  let newScanObj = [];

  useEffect(() => {
    setDisabled(true);
    setDisableField(false);
    setScanLogObj({});
    setScan(initialState);
    setRefresh(false);
    setBarcodeSkuName(initialState);
    setFlatPackLabel(initialState);
    setOrderNumber(initialState);
    setSerialTag(null);
    setComponent(null);
    setOpenSucess(false);
    document.querySelector('[tabindex = "1"]').focus();
  }, [refresh]);

  useEffect(() => {
    dispatch(getStatusList()).then((res) => {
      setStatus(res.payload);
    });
    /// Used to get location / station area
    let newData = JSON.parse(window.localStorage.getItem("location"));
    if (newData) {
      setStatusId(newData.id);
      setLocation(newData);
      fetchAvailableStations(newData);

      let userid = JSON.parse(window.localStorage.getItem("userId"));
      setUserId(userid);

      /// Used to get machine status location
      let machineLocation = JSON.parse(window.localStorage.getItem("machine"))
        ? JSON.parse(window.localStorage.getItem("machine"))
        : null;
      setStationName(machineLocation);

      if (newData.station_required == 1) {
        setMachineRequired(true);
      }
      if (newData.allow_batch_scan == 1) {
        setMachineRequired(true);
        history.push("/batch_scan");
      }
      if (newData.allow_truck_scan == 1) {
        setMachineRequired(true);
        history.push("/truck_scan");
      }
      if (newData.id == 55) {
        history.push("/shipped_scan");
      }
    }
    getUserScans(JSON.parse(window.localStorage.getItem("userId")), statusId);

    checkForPrinter();
  }, [statusId]);

  const checkForPrinter = async () => {
    if (window.localStorage.getItem("station printer/s") != null) {
      setStationPrinterInfo(
        JSON.parse(window.localStorage.getItem("station printer/s"))
      );
    }
  };

  useEffect(() => {
    let firstName = window.localStorage.getItem("firstName");
    let lastName = window.localStorage.getItem("lastName");
    setUser(firstName + " " + lastName);
    let station = JSON.parse(window.localStorage.getItem("location"));
    let st = station ? station.name : "";
    setArea(st);
  }, [statusId, refresh, locationHistory.key, location]);

  const getUserScans = async (userId, statusId) => {
    console.time("getUserScans Function Execution Time");
    try {
      let total = await getUserTotalScans(dispatch, userId, statusId);
      setTotalScanned(total);
    } catch (error) {
      console.error("Error in fetching user scans:", error);
      throw error;
    }
    console.timeEnd("getUserScans Function Execution Time");
  };

  //handleClick function is used to keep field focused when user clicks on other areas of the page.
  const handleClickOutside = (e) => {
    if (inputField1Ref.current && !inputField1Ref.current.contains(e.target)) {
      document.getElementById("SearchbyScanning").focus();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const barcodeToStation = (data) => {
    if (data.length == 25) {
      setTimeout(async function () {
        let statusId = data
          .substring(7, data.indexOf(" /"))
          .replace(/\s/g, "")
          .replace(/^0+/, "");
        const machineId = data
          .substring(data.length - 2)
          .replace(/\s/g, "")
          .replace(/^0+/, "");
        let locationObj = status.find((item) => item.id == statusId);
        if (machineId != "") {
          await dispatch(getStationlists()).then((res) => {
            let machine = res.payload;
            machine.find((area) => {
              if (
                area.station_type_id == locationObj.station_type_id &&
                area.id == machineId
              ) {
                let result = area.station.split(" ").pop();
                setMachineNumber(result);
                window.localStorage.setItem("machine", machineId);
                setStationName(machineId);
              }
            });
          });
        }
        window.localStorage.setItem("location", JSON.stringify(locationObj));
        setStatusId(statusId);
        setRefresh(true);
      }, 50);
    }
  };

  const handleChange = (e) => {
    setScan("");
    setSerialTag("");
    setEpc("");
    setMachineNumber("");

    let data = e.target.value;
    let locationObj = status.find((item) => item.id == data);
    fetchAvailableStations(locationObj);
    setStatusId(data);
    setStationName(initialState);

    if (locationObj) {
      setLocation(locationObj);
      if (
        locationObj.allow_batch_scan === 1 &&
        locationObj.allow_batch_scan != 0
      ) {
        setMachineRequired(true);
        history.push("/batch_scan");
      } else setMachineRequired(false);
      window.localStorage.setItem("location", JSON.stringify(locationObj));
      window.localStorage.setItem("machine", JSON.stringify(0));
      setRefresh(true);
    }
  };

  const handleMachineChange = (e) => {
    let data = e.target.value;
    let stationArea = station.find((item) => item.id == data);
    let result = stationArea.station.split(" ").pop();
    setMachineNumber(result);
    window.localStorage.setItem("machine", JSON.stringify(data));
    setStationName(data);
  };

  const fetchAvailableStations = async (data) => {
    let newStatus = data;
    await dispatch(getStationlists()).then((res) => {
      let filteredStations = [];
      let machine = res.payload;
      machine.filter((area) => {
        if (area.station_type_id == newStatus.station_type_id) {
          filteredStations.push(area);
        }
      });
      setStation(filteredStations);
      let newData = window.localStorage.getItem("machine");
      if (newData) {
        let stationArea = filteredStations.find((item) => item.id == newData);
        let result = stationArea?.station.split(" ").pop();
        setMachineNumber(result);
      } else {
        let stationArea = res.payload.find((item) => item.id == newData);
        let result = stationArea?.station?.split(" ").pop();
        setMachineNumber(result);
      }
    });
  };

  const alertRed = (e) => {
    setDisableField(true);
    setFailRed(true);
    setOverlaybackdrop(true);
    setTimeout(() => {
      document.getElementById("SearchbyScanning").focus();
    }, 2000);

    setTimeout(() => {
      setRefresh(true);
      setOverlaybackdrop(false);
    }, 600);
  };

  const submitScanLogData = (obj) => {
    console.time("submitScanLogData Function Execution Time");
    setRefresh(true);
    let scannedSerial = {serial:obj.epc}
    setPreviouslyScanned([...previouslyScanned, scannedSerial])
    
    dispatch(postScanLog(obj)).then((res) => {
      if (res.type === "SCAN_LOG_SUCCESS") {
        getUserScans(userId, statusId);
        setRefresh(true);
      }
    });
    console.timeEnd("submitScanLogData Function Execution Time");
  };
  const alertGreen = async (obj) => {
    console.time("alertGreen Function Execution Time");
    setDisableField(true);
    setLoading(true);
        //Lawtag to subcomponent relationships Line Load & Kitting
        if (obj.schedule_id != null) {
          if (location.child_assembly != null) {
            setLoading(false);
            setComponent({
              parentSerialId: obj.serial_id,
              status: statusId,
              component_schedule: sku.component_schedule,
              sku: sku,
              parentData: obj
            });
          }
        } else {
          //Subcomponent to subcomponent relationships lamination
          if (obj.status.mfg_complete != 1) {
            await dispatch(findSibling(obj)).then(async (res) => {
              let newObj = res.payload.component_schedule;
              let coreType1 = newObj.filter(
                (res) =>
                  res.serial_numbers[0]?.serial_number == obj.epc &&
                  res.component_type_id == 1
              );
              if (coreType1.length == 1) {
                let sku = [];
                let componentType2 = res.payload.component_schedule.find(
                  (type2) => type2.component_type_id == 2
                );
    
                if (!componentType2) {
                  setLoading(false);
                  setOpenSucess(!openSucess);
                  setTotalScanned(totalScanned);
                  submitScanLogData(obj);
                  return;
                } else {
                  let sku = await dispatch(findById(coreType1[0].schedule_id)).then(
                    (res) => {
                      return res.payload;
                    }
                  );
                  setComponent({
                    parentSerialId: obj.serial_id,
                    status: statusId,
                    component_schedule: [componentType2],
                    sku: sku,
                    parentData: obj
                  });
                  setLoading(false);
                }
              } else {
                setScanError("Error!! Please scan a core serial number");
                alertRed();
                return false;
              }
            });
          }
        }
        if (
          obj.status.mfg_complete == 1 ||
          obj.status.quick_scan == 1 ||
          // This obj.status.id == "25" && "26" is hard coded for the convayer scan when we release line load this needs to be removed.
          obj.status.id == "25" ||
          obj.status.id == "26"
        ) {
          setTotalScanned(totalScanned);
          submitScanLogData(obj);
    }
  
  if (location.child_assembly == null) {
    setLoading(false);
    setSucessGreen(true);
    setOverlaybackdrop(true);
    setRefresh(true);
  }

  setTimeout(() => {
    setSucessGreen(false);
    setOverlaybackdrop(false);
     setLoading(false);
  }, 400);
};
   

  /* qc station - pass or fail check */
  const passFailCheck = (passFailObj) => {
    scanLogObj[0].pass = passFailObj;
    if (passFailObj == 0) {
      setScanError(`Mattress needs futher inspection.`);
      alertRed();
      scanLogObj[0].pass = 0;
      submitScanLogData(scanLogObj[0]);
      return false;
    }
    scanLogObj.map((dataObj) => {
      alertGreen(dataObj);
    });
  };

  // RFID
  const checkForStation = (serial) => {
    if (
      !JSON.parse(window.localStorage.getItem("location")) ||
      (machineRequired === true && !stationName)
    ) {
      setScanError(`Please Select Scan Station / Machine Number`);
      setLoading(false);
      alertRed();
      return false; 
    } else {
      setSerialTag(serial);
      setScan(serial);
      setEpc(serial);
      focusOnNextField();
    }
  };

  useEffect(() => {
    localStorage.setItem("lastActivity", new Date());
    if (!JSON.parse(window.localStorage.getItem("location"))) {
      // Logic for no station selected.
      checkForStation();
    }
    if (serialTag != null && serialTag.length == 16) {
      const scannedItem = previouslyScanned.filter((pv) => pv.serial == serialTag);
      console.log('pvs', scannedItem)
      if (scannedItem.length > 0) {
        setScanError(`Please scan valid Serial Number`);
        setLoading(false);
        alertRed();

      }else{ 
        setPreviouslyScanned(scannedItem)
        setScanError("");
        setFailRed(false);
        let serials = { serial_numbers: [serialTag] };
        setDisableField(true);
        setLoading(true);
        dispatch(validateBatchScans(serials, statusId)).then(
          async (pastScans) => {
            if (pastScans.payload.length > 0) {
              setBarcodeSkuName(pastScans.payload[0].schedule?.sku);
              setFlatPackLabel(pastScans.payload[0].schedule?.notes);
              setOrderNumber(pastScans.payload[0].schedule?.order_number);
              let scans = pastScans.payload[0];
              let isScanned = scans.scans;
              if (scans.allow_duplicates == 1) {
                setScanError(`Please scan valid Serial Number`);
                setLoading(false);
                alertRed();
                return false;
              }
              if (
                isScanned.length > 0 ||
                (isScanned.epc === serialTag && statusId == isScanned.status_id)
              ) {

                let lastScanInfo = isScanned[0];
                let lastUserScanned = isScanned[0].user;
                let firstName = lastUserScanned.first_name;
                let lastName = lastUserScanned.last_name;
                let date = lastScanInfo.date;
                let sku = lastScanInfo.rfid_scan;
                setScanError(
                  `${sku} - Scanned on ${moment(date).format(
                    "MM-DD-YYYY hh:mm A"
                  )} by ${firstName} ${lastName}`
                );
                setLoading(false);
                alertRed();
                setReprintState(pastScans.payload);
                if (location.scan_to_print == 1 || location.id == 31) {
                  setReprint(true);
                }
                return false;
              } else {
                const timeOutId = setTimeout(async () => {
                  let serials = { serial_numbers: [serialTag], scans };
                  scannedData(serials);
                  200;
                });
                return () => clearTimeout(timeOutId);
              }
            } else {
              setScanError(`Not A Valid Serial Number`);
              alertRed();
              setLoading(false);
            }
          }
        );
      }
    
    }
  }, [serialTag]);

  /* function executes if RFID tag has already been scanned */
  const scannedData = (scheduleData, scheduleComponent) => {
    let newObj = {
      status_id: statusId,
      epc: epc,
      rfid_scan: "",
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      pass: 1,
      user_id: userId,
      shipping_location: "",
      schedule_id: scheduleData.scans.schedule_id,
      serial_id: scheduleData.scans.id,
      station_id: stationName ? stationName : null,
      printerArray: stationPrinterInfo,
      order_number: "",
      status: location
    };
    setLoading(false);
    if (machineRequired === true && !stationName) {
      setRefresh(true);
      setScanError("Please Select Machine Number!");
      alertRed();
      return false;
    }
    setDisabled(false);
    newScanObj.push(newObj);

    status.find((stp) => {
      if (stp.id == statusId) {
        if (stp.pass_fail == 1) {
          setLoading(false);
          setScanLogObj(newScanObj);
          return false;
        }
        if (stp.barcode != 1 || stp.order_number != 1) {
          newScanObj.map((dataObj) => {
            alertGreen(dataObj, scheduleComponent);
          });
        }
      }
    });
  };
  /* focuses on next input line if line is present */
  const focusOnNextField = (e) => {
    const statusFiltering = status.filter(
      (st) =>
        st.id == statusId &&
        st.rfid == 1 &&
        st.barcode == 1 &&
        st.order_number == 1
    );
    if (statusFiltering) {
      const currentInput = document.activeElement;
      const currentTabIndex = parseInt(currentInput.getAttribute("tabIndex"));
      const nextTabIndex = currentTabIndex + 1;
      const nextInput = document.querySelector(
        '[tabindex="' + nextTabIndex + '"]'
      );
      if (nextInput !== null) {
        // Focus on the next input element
        nextInput.focus();
      }
    }
    return;
  };
  /* reprint labels */
  const reprintLabels = () => {
    setScanError("");
    setFailRed(false);
    let scanToPrint = [];
    const submitAfterScan = status.filter(
      (st) =>
        st.id == statusId &&
        st.pass_fail != 1 &&
        st.order_number != 1 &&
        st.barcode != 1
    );
    let check = submitAfterScan.find((pass) => {
      return pass;
    });
    scanToPrint.push(check);
    const scanInfo = {
      scanId: reprintState[0].scans[0].id,
      printerArray: stationPrinterInfo,
      scan_to_print: scanToPrint ? scanToPrint : null
    };
    dispatch(reprintLabelByScanInfo(scanInfo)).then((res) => {
      if (res.type === "REPRINT_LABEL_SUCCESS") {
        setRefresh(true);
        setReprint(false);
        setDisabled(true);
        document.getElementById("SearchbyScanning").focus();
      }
    });
  };

  const statusFilteringPassFailButtons = status.filter(
    (st) => st.id == statusId && st.pass_fail == 1
  );
  const showPassFailButtons = statusFilteringPassFailButtons.find(
    (show) => show.pass_fail === 1
  );

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSucess(!openSucess);
    setRefresh(!refresh);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      <Backdrop style={{ zIndex: "1" }} open={overlaybackdrop}>
        {sucessGreen == true ? (
          <FontAwesomeIcon
            id="flashGreen"
            icon={faCheckCircle}
            className="sucess-green-icon"
          />
        ) : (
          ""
        )}
        {failRed == true ? (
          <FontAwesomeIcon
            id="flashRed"
            icon={faTimesCircle}
            className="fail-red-icon"
          />
        ) : (
          ""
        )}
      </Backdrop>
      <div className="production_body">
        {loading == true ? <LinearProgressBar /> : ""}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSucess}
          autoHideDuration={3000}
          onClose={handleClose}
          message="Component sucessfully updated!!"
        >
          <Alert variant="filled" severity="success">
            Component successfully updated!!
          </Alert>
        </Snackbar>

        <Box className="production_header_title" pt={2}>
          <Grid container style={{ borderBottom: "solid 1px" }}>
            <Grid container item xs={6} justifyContent="flex-start">
              <Box pl={2}>
                <Typography className="production_header_title" variant="h3">
                  <FontAwesomeIcon icon={faUser} />
                  &nbsp;{user}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <Box mr={2} mb={1}>
                <Typography className="production_header_title" variant="h3">
                  <FontAwesomeIcon icon={faSearchLocation} />
                  &nbsp;{area}&nbsp;
                  {machineNumber != null ? <>{machineNumber} </> : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="production_sub_header" pt={1} pl={1}>
          <Grid container justifyContent="center">
            <Box>
              <Tabs indicatorColor="primary"></Tabs>
            </Box>
          </Grid>
        </Box>
        {failRed == true ? (
          <Alert variant="filled" severity="error" className="error">
            <Typography variant="h4"> {scanError}</Typography>
          </Alert>
        ) : (
          ""
        )}
        <Grid container style={{ marginBottom: "-65px" }}>
          <Grid item lg={8} xs={6}>
            {component != null ? (
              <Box m={12}>
                <SubComponentScan
                  component={component}
                  refresh={(component) => setRefresh(component.refresh)}
                  scan={(component) => {
                    setTotalScanned(totalScanned);
                  }}
                />
              </Box>
            ) : (
              <Box
                className="production_user_scanned-box"
                m={12}
                xs={6}
                textAlign="center"
              >
                <Typography className="production_user_scanned">
                  {totalScanned}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={6} lg={4}>
            <Grid container justifyContent="center">
              <Box mt={6} className="refresh-box">
                <Button
                  id="refresh"
                  onClick={(e) => {
                    setRefresh(true);
                    getUserScans(userId, statusId);
                    setReprint(false);
                    setFailRed(false);
                    document.querySelector('[tabindex = "1"]').focus();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSync}
                    className="production_font_icon_sync"
                    size="3x"
                  />
                </Button>
              </Box>
            </Grid>
            <Box ml={5} pl={2} pr={10} className="production_input_label-box">
              <Box mt={3} mb={2} className="scan-status-box">
                <InputLabel className="production_input_label">
                  Scan Status
                </InputLabel>
              </Box>
              <Select
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={statusId ? statusId : 0}
                options={status.map((option) => ({
                  value: option.id,
                  label: option.name,
                  required: option.station_required
                }))}
              >
                <MenuItem key={0} value={0}>
                  Select
                </MenuItem>
                {status.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {machineRequired == true ? (
                <Box mt={3}>
                  <InputLabel className="production_input_label">
                    Machine Number
                  </InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    onChange={handleMachineChange}
                    value={stationName ? stationName : 0}
                    options={station.map((option) => ({
                      value: option.id,
                      label: option.name
                    }))}
                  >
                    <MenuItem key={0} value={0}>
                      Select
                    </MenuItem>
                    {station.map((option) => (
                      <MenuItem key={option.station} value={option.id}>
                        {option.station}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              ) : (
                ""
              )}
              <Box mt={4} className="scan-data-box">
                <Typography variant="h4">
                  <b>Scan Data</b>
                </Typography>
                <form>
                  <TextField
                    value={scan}
                    id="SearchbyScanning"
                    className="sku_textfield"
                    inputProps={{ tabIndex: "1" }}
                    disabled={disableField}
                    onKeyDown={handleKeyDown}
                    ref={inputField1Ref}
                    onChange={(e) => {
                      e.preventDefault();
                      setScan(e.target.value);
                      let data = e.target.value;
                      if (data.toUpperCase().includes("STA")) {
                        barcodeToStation(data);
                      } else if (
                        data.toUpperCase().includes("E2C") &&
                        e.target.value.length === 9
                      ) {
                        loginNewUser(dispatch, e.target.value, USER_SCAN_PASS);
                      } else if (
                        e.target.value.length === 16 &&
                        !e.target.value.toUpperCase().includes("STA")
                      ) {
                        checkForStation(e.target.value);
                      }
                    }}
                    fullWidth
                    variant="filled"
                    label="Electronic Product Code"
                    InputProps={{
                      minLength: 22,
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            style={{ marginTop: "2px" }}
                            icon={faSearchMinus}
                            size="lg"
                          />
                        </InputAdornment>
                      )
                    }}
                  />

                  <Typography className="serial_number-sku_textfield">{barcodeSkuName}</Typography>

                  {/* reprint label button */}
                  {reprint ? (
                    <Grid>
                      <Box mt={4}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={reprintLabels}
                          size="large"
                        >
                          Press to reprint labels
                        </Button>
                      </Box>
                    </Grid>
                  ) : (
                    ""
                  )}
                </form>
              </Box>
            </Box>
          </Grid>
          {/* QC pass and fail buttons */}

          {showPassFailButtons ? (
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box sx={{ fontWeight: "bold" }} ml={1} textAlign="center">
                  {flatPackLabel ? (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                       
                          <Typography
                            style={{
                              fontSize: "46px",
                              fontWeight: "700px",
                              resize: "both"
                            }}
                          >
                            {flatPackLabel}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              fontSize: "46px",
                              fontWeight: "700px",
                              resize: "both"
                            }}
                          >
                            {orderNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box textAlign="center" mt={6}>
                  <Button
                    disabled={disabled}
                    onClick={(e) => {
                      passFailCheck(1);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "green", fontSize: "200px" }}
                      icon={faCheckCircle}
                    />
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box textAlign="center" mt={6}>
                  <Button
                    style={{
                      zIndex: "2",
                      position: "relative",
                      cursor: "pointer"
                    }}
                    disabled={disabled}
                    onClick={(e) => {
                      passFailCheck(0);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "red", fontSize: "200px" }}
                      icon={faTimesCircle}
                    />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
    </>
  );
};
export default Production;
