import * as types from '../constants/weightTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.WEIGHT_UPDATE_FIELD,
        name,
        value,
    }
}

export function getWeights() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/weights`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.WEIGHTS_LOADING_REQUEST,
                types.WEIGHTS_LOADING_SUCCESS,
                types.WEIGHTS_LOADING_ERROR,
            ],
        },
    }
}

export function getWeight(id) {
    console.log(id)
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/weight/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.WEIGHT_LOADING_REQUEST,
                types.WEIGHT_LOADING_SUCCESS,
                types.WEIGHT_LOADING_ERROR,
            ],
        },
    }
}

export function saveWeight(weight){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/weight-update/`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(weight),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.WEIGHT_SAVING_REQUEST,
                types.WEIGHT_SAVING_SUCCESS,
                types.WEIGHT_SAVING_ERROR,
            ],
        },
    }
}