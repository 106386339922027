import React from 'react';
import T, { number, object } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import InputOverlay from '../InputOverlay';
import {
  addFoundation,
  addMaterial,
  foundationAdded,
  getSku,
  getAllSkus,
  getActiveSkus,
  materialAdded,
  resetSKU,
  saveSkuList,
  updateField,
  updateFoundationGroup,
  updateMaterialGroup,
  updateSkuCore,
  updateSkuFamily,
  updateSkuBatch,
  updatePrototype,
  updateSkuFirmness,
  updateSkuParentCore,
  updateSkuTopPanel,
  updateSkuGussetPanel,
  updateSkuSidePanel,
  updateSkuBottomPanel,
  updateSkuAttribute,
  getSkuGroup,
  getSkuDraft,
  saveSkuDraft,
  updateCloseModal,
  updateOpenModal,
  setTopPanel,
  setGussetPanel,
  setSidePanel,
  setBottomPanel,
} from '../../actions/skuActions';
import { getAllPanels } from '../../actions/panelActions';
import {
  getTopPanelTicks,
  getBottomPanelTicks,
  getGussetPanelTicks,
  getSidePanelTicks,
  getAllPanelLayers,
} from '../../actions/panelLayerActions';
import { getCustomers } from '../../actions/customerActions';
import { getManufacturers } from '../../actions/manufacturerActions';
import { getMaterials } from '../../actions/materialActions';
import { getPrototypes } from '../../actions/prototypeActions';
import { getFoundations } from '../../actions/foundationActions';
import { getFamilies } from '../../actions/familyActions';
import { getBrands } from '../../actions/brandActions';
import { getCores, getCoresBySize } from '../../actions/coreActions';
import { getFabricQuiltPatterns } from '../../actions/fabricQuiltPatternActions';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { getSizes } from '../../actions/sizeActions';
// import { getBatches } from "../../actions/batchActions";
import { getParts } from '../../actions/partActions';
import {
  Paper,
  TableCell,
  TableHead,
  TableBody,
  Tooltip,
} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { createStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../../styles/sku.css';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { getMaterialGroups } from '../../actions/materialGroupActions';
import { getFoundationGroups } from '../../actions/foundationGroupActions';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
//IF THE BATCH EDIT BREAKING THEN ONE OF THE SKUS IN THE FAMILY COULD HAVE A DIFFERENT ATTRIBUTE THAN THE REST..
//EXAMPLE- ONE THE SKUS IN THE FAMILY HAS A DIFFERENT PROTOTYPE THAN THE REST

import { getCoreFirmnesses } from '../../actions/coreFirmnessActions';
import { getParentCores } from '../../actions/parentCoreActions';
import { getCoreTypes } from '../../actions/coreTypeActions';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddNewButton from '../global/AddNewButton';
import Can from '../Can';
import panelReducer from '../../reducers/panelReducer';
import panelLayerReducer from '../../reducers/panelLayerReducer';
import HelpIcon from '@material-ui/icons/Help';
import Popover from '@material-ui/core/Popover';
import ModalSkuForm from '../ModalSkuForm';
import { CORE_TYPE_IMPORT_LOADING_SUCCESS } from '../../constants/coreTypeTypes';

class SkuForm extends React.Component {
  constructor(props) {
    super(props);
    // Bind the this context to the handler function
    this.handleChange = this.handleChange.bind(this);
    this.handleInputOverlay = this.handleInputOverlay.bind(this);
    this.handleSubmitBtn = this.handleSubmitBtn.bind(this);
  }

  componentDidMount() {
    //BOM
    this.getFamilyList();
    this.getBrandList();
    this.getSizeList();
    // this.getBatchList();
    this.getCoreFirmnessList();
    this.getParentCoreList();
    this.getCoreTypeList();
    this.getCoreList();
    //Law Tag Lists
    this.getCustomerList();
    this.getManufacturerList();
    this.getPrototypeList();
    this.getMaterialGroupList();
    this.getFoundationGroupList();
    this.getFabricQuiltPatterns();
    //NEW get the list of core firmnesses

    this.getFishbowlParts();
    //Get The sku
    this.getSingleSku();
    this.getActiveSkuList();
    this.getAllSkuList();
    this.getAllPanels();
    this.getAllPanelLayers();
    this.getTopPanelTicks();
    this.getSidePanelTicks();
    this.getBottomPanelTicks();
    this.getGussetPanelTicks();
    //get the core last so we can get the cores that apply to the sku size selected
    this.getCoreList();
  }

  //LIST VALUES
  //BOM
  getFamilyList() {
    const { dispatch } = this.props;

    dispatch(getFamilies());
  }
  getBrandList() {
    const { dispatch } = this.props;

    dispatch(getBrands());
  }
  getSizeList() {
    const { dispatch } = this.props;

    dispatch(getSizes());
  }
  //   getBatchList() {
  //     const { dispatch } = this.props;

  //     dispatch(getBatches());
  //   }

  //NEW get the list of core firmnesses
  getCoreFirmnessList() {
    const { dispatch } = this.props;

    dispatch(getCoreFirmnesses());
  }
  getParentCoreList() {
    const { dispatch } = this.props;

    dispatch(getParentCores());
  }
  getCoreTypeList() {
    const { dispatch } = this.props;
    dispatch(getCoreTypes());
  }
  getCoreList() {
    const { dispatch } = this.props;

    dispatch(getCores());
  }

  //Law Tag

  getCustomerList() {
    const { dispatch } = this.props;

    dispatch(getCustomers());
  }
  getManufacturerList() {
    const { dispatch } = this.props;

    dispatch(getManufacturers());
  }
  getPrototypeList() {
    const { dispatch } = this.props;

    dispatch(getPrototypes());
  }
  getMaterialGroupList() {
    const { dispatch } = this.props;

    dispatch(getMaterialGroups());
  }
  getFoundationGroupList() {
    const { dispatch } = this.props;

    dispatch(getFoundationGroups());
  }
  getFabricQuiltPatterns() {
    const { dispatch } = this.props;

    dispatch(getFabricQuiltPatterns());
  }
  getAllPanels() {
    const { dispatch } = this.props;
    dispatch(getAllPanels());
  }
  getAllPanelLayers() {
    const { dispatch } = this.props;
    dispatch(getAllPanelLayers());
  }
  getTopPanelTicks() {
    const { dispatch } = this.props;
    dispatch(getTopPanelTicks());
  }
  getSidePanelTicks() {
    const { dispatch } = this.props;
    dispatch(getSidePanelTicks());
  }
  getBottomPanelTicks() {
    const { dispatch } = this.props;
    dispatch(getBottomPanelTicks());
  }
  getGussetPanelTicks() {
    const { dispatch } = this.props;
    dispatch(getGussetPanelTicks());
  }
  getFishbowlParts() {
    const { dispatch } = this.props;

    dispatch(getParts());
  }
  //need list of active skus to select the same fabric panels as them
  getAllSkuList() {
    const { dispatch } = this.props;

    dispatch(getAllSkus());
  }
  getActiveSkuList() {
    const { dispatch } = this.props;

    dispatch(getActiveSkus());
  }
  getSingleSku = async (e) => {
    const { dispatch, isLoading } = this.props;

    await dispatch(resetSKU());
    const id = await this.props.match.params.id;
    const family_id = await this.props.match.params.family_id;
    const staging_id = await this.props.match.params.staging_id;
    //if an id is on props it is for individual sku
    //if id exists and family_id does not then get individual sku
    if (id !== undefined && family_id === undefined) {
      await dispatch(getSku(id));
    } else if (family_id !== undefined) {
      await dispatch(getSkuGroup(family_id));
      //based on the parent core of the sku group we need to see the firmnesses available in firmness dropdown
      await this.updateValidFirmnesses();
      //if it is group of skus we need to set the sizeValues arr on local state = to the sizeList (list of sizes from the group of skus)
      //this helps to giv us the visual chart of the sku groups size and firmness variations
      //the sizeList also allows us to compare what sizes the batch currently has
      // vs sizeValues on local state is what user updated the sizes to
      await this.setState({
        sizeValues: this.props.sizeList.filter(
          (size, i) => this.props.sizeList.indexOf(size) === i
        ),
      });
      await this.setState({
        firmnessValues: this.props.firmnessList.filter(
          (size, i) => this.props.firmnessList.indexOf(size) === i
        ),
      });
      //this will create the the chips on the table based on the size value and firmness value from the sku group
      //we pass in initial load to be true because this is when the page initially loads
      //this will allow us to create the table sku list based on the size and firmness values in sizeValues list and firmnessValues list
      //but compare them against the skuGroup.. to confirm if each size and firmness variation exists yet or not
      //if not then we gray out the chip (ex: twin may only come in firm on the batch we are viewing.. so we need to gray out the med and soft chips on the table)

      await this.createTableSkuList(true);
      //all of this should not be here.. i dont know why i have skuAttributeArrays on local state
      //i did this when i was just creating sku/sku batches
      //i now we need to edit in batches and edit all of the panel attribute fields...
      //gotta fix ASAP..

      let upcRequired = await this.props.skuGroup.find((s) => s.upc !== null);
      if (upcRequired != undefined) {
        await this.setState({ upcRequired: true });
      }
      if (this.props.skuTape != null) {
        await this.setState({ skuTape: this.props.skuTape });
        let topTapeObj = this.props.skuTape.find(
          (attr) => attr.fabric_use_id === 1
        );
        topTapeObj != undefined
          ? await this.setState({
              topTapeObj: topTapeObj,
              topTape:
                topTapeObj.part.num + ' - ' + topTapeObj.part.internal_desc,
            })
          : '';
        let sideTapeObj = await this.props.skuTape.find(
          (attr) => attr.fabric_use_id === 2
        );
        sideTapeObj != undefined
          ? await this.setState({
              sideTapeObj: sideTapeObj,
              sideTape:
                sideTapeObj.part.num + ' - ' + sideTapeObj.part.internal_desc,
            })
          : '';
        let bottomTapeObj = await this.props.skuTape.find(
          (attr) => attr.fabric_use_id === 3
        );
        bottomTapeObj != undefined
          ? await this.setState({
              bottomTapeObj: bottomTapeObj,
              bottomTape:
                bottomTapeObj.part.num +
                ' - ' +
                bottomTapeObj.part.internal_desc,
            })
          : '';
      }

      if (this.props.coreGroupAttributeId == 2) {
        await this.setState({ specialtyMattress: true });
      } else {
        await this.setState({ specialtyMattress: false });
      }

      if (this.props.skuAttribute != undefined) {
        const { skuGroup, parts } = this.props;
        await this.setState({ skuAttribute: this.props.skuAttribute });
        //box objects for final sku array
        let boxObjs = [];
        //box names for display
        let boxNames = [];
        //zipper cover objects for final sku array
        let zcObjs = [];
        //zipper cover names for display
        let zcNames = [];
        //check if zipper cover exists
        let zcExists = this.props.skuAttribute.find(
          (attr) => attr.attribute_id == 3
        );
        // let plasExists = this.props.skuAttribute.forEach((attr) => {
        //   if (
        //     attr.attribute_value === '11294' ||
        //     attr.attribute_value === '10695'
        //   ) {
        //     this.setState({ plasticName: 2 });
        //   }
        // });

        if (zcExists !== undefined) {
          this.setState({ zipperCoverCheck: true });
        }
        await this.state.sizeValues.forEach((size) => {
          let skuObj = skuGroup.find((sku) => sku.size.size === size);
          let boxAttrArr = skuObj.skuAttribute.filter(
            (attr) => attr.attribute_id == 1
          );
          let boxNameObj = {};
          let boxObjsObj = {};
          //just get the multiple box names in an array
          let multipleBoxesInteralDesc = boxAttrArr.map(
            (attr) => attr.part.internal_desc
          );
          boxNameObj = {
            size: skuObj.size.size,
            boxName1: multipleBoxesInteralDesc[0],
            boxName2: multipleBoxesInteralDesc[1]
              ? multipleBoxesInteralDesc[1]
              : '',
          };
          boxObjsObj = {
            size: skuObj.size.size,
            attr_obj1: boxAttrArr[0],
            attr_obj2: boxAttrArr[1] ? boxAttrArr[1] : '',
          };
          boxObjs.push(boxObjsObj);
          boxNames.push(boxNameObj);

          let skuZCAttrObj = skuObj.skuAttribute.find(
            (attr) => attr.attribute_id == 3
          );
          let zcNameObj = {};
          let zcObjsObj = {};
          zcNameObj = {
            size: skuObj.size.size,
            zcName:
              skuZCAttrObj != undefined
                ? skuZCAttrObj.part.num +
                  ' - ' +
                  skuZCAttrObj.part.internal_desc
                : '',
          };
          zcObjsObj = {
            id: skuZCAttrObj != undefined ? skuZCAttrObj.id : '',
            sku_id: skuZCAttrObj != undefined ? skuZCAttrObj.sku_id : '',
            attribute_id:
              skuZCAttrObj != undefined ? skuZCAttrObj.attribute_id : '',
            attribute_value:
              skuZCAttrObj != undefined ? skuZCAttrObj.attribute_value : '',
            size: skuObj.size.size,
          };
          zcObjs.push(zcObjsObj);
          zcNames.push(zcNameObj);
        });

        await this.setState({ boxNames: boxNames });
        await this.setState({ boxObjs: boxObjs });
        await this.setState({ zcNames: zcNames });
        await this.setState({ zcObjs: zcObjs });
      }

      if (this.props.skuCoreAttribute != undefined) {
        const { skuGroup, parts } = this.props;
        await this.setState({ skuCoreAttribute: this.props.skuCoreAttribute });
        let frExists = this.props.skuCoreAttribute.find(
          (attr) => attr.core_attribute_id == 1
        );
        if (frExists !== undefined) {
          this.setState({ fireSockCheck: true });
        }
        //fr objects for final sku array
        let frObjs = [];
        //fr names for display
        let frNames = [];
        await this.state.sizeValues.forEach((size) => {
          let skuObj = skuGroup.find((sku) => sku.size.size === size);
          //get the FR attribute object out of the the skuCoreAttribute array
          //if no FR exists then skuFRAttrObj will be undefined
          let skuFRAttrObj = skuObj.skuCoreAttribute.find(
            (attr) => attr.core_attribute_id == 1
          );
          //grab the part object off of the FR attribute object
          let skuFRNamePart =
            skuFRAttrObj != undefined ? skuFRAttrObj.part : '';
          let frNameObj = {};
          let frObjsObj = {};
          frNameObj = {
            size: skuObj.size.size,
            frName:
              skuFRAttrObj != undefined
                ? skuFRNamePart.num + ' - ' + skuFRNamePart.internal_desc
                : '',
          };
          frObjsObj = {
            id: skuFRAttrObj != undefined ? skuFRAttrObj.id : '',
            sku_id: skuFRAttrObj != undefined ? skuFRAttrObj.sku_id : '',
            core_attribute_id:
              skuFRAttrObj != undefined ? skuFRAttrObj.core_attribute_id : '',
            attribute_value:
              skuFRAttrObj != undefined ? skuFRAttrObj.attribute_value : '',
            size: skuObj.size.size,
          };
          frObjs.push(frObjsObj);
          frNames.push(frNameObj);
        });
        await this.setState({ frNames: frNames });
        await this.setState({ frObjs: frObjs });
      }

      //top info
      if (this.props.skuTopPanelAttribute != undefined) {
        await this.setState({
          skuTopPanelAttribute: this.props.skuTopPanelAttribute,
        });
        let topQuiltPattern = await this.props.skuTopPanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 4
        );
        topQuiltPattern != undefined
          ? this.setState({
              topQuiltPatternObj: topQuiltPattern.fabricQuiltPattern,
              topQuiltPattern:
                topQuiltPattern.fabricQuiltPattern.fabric_quilt_pattern,
            })
          : '';
        let topHeight = await this.props.skuTopPanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 1
        );
        topHeight != undefined
          ? await this.setState({ topHeight: topHeight.attribute_value })
          : '';
      }

      //gusset info
      if (this.props.skuGussetPanelAttribute != undefined) {
        await this.setState({
          skuGussetPanelAttribute: this.props.skuGussetPanelAttribute,
        });
        let gussetQuiltPattern = await this.props.skuGussetPanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 4
        );
        gussetQuiltPattern != undefined
          ? this.setState({
              gussetQuiltPatternObj: gussetQuiltPattern.fabricQuiltPattern,
              gussetQuiltPattern:
                gussetQuiltPattern.fabricQuiltPattern.fabric_quilt_pattern,
            })
          : '';
        let gussetHeight = await this.props.skuGussetPanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 1
        );
        gussetHeight != undefined
          ? await this.setState({ gussetHeight: gussetHeight.attribute_value })
          : '';
      }

      //side info
      if (this.props.skuSidePanelAttribute != undefined) {
        await this.setState({
          skuSidePanelAttribute: this.props.skuSidePanelAttribute,
        });
        let sideQuiltPattern = await this.props.skuSidePanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 4
        );
        sideQuiltPattern != undefined
          ? await this.setState({
              sideQuiltPatternObj: sideQuiltPattern.fabricQuiltPattern,
              sideQuiltPattern:
                sideQuiltPattern.fabricQuiltPattern.fabric_quilt_pattern,
            })
          : '';
        let sideQuiltPattern2 = await this.props.skuSidePanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 5
        );
        sideQuiltPattern2 != undefined
          ? await this.setState({
              sideQuiltPatternObj2: sideQuiltPattern2.fabricQuiltPattern,
              sideQuiltPattern2:
                sideQuiltPattern2.fabricQuiltPattern.fabric_quilt_pattern,
            })
          : '';
        let sideHeight = await this.props.skuSidePanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 1
        );
        sideHeight != undefined
          ? await this.setState({ sideHeight: sideHeight.attribute_value })
          : '';
        let embroidery = await this.props.skuSidePanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 2
        );
        embroidery != undefined
          ? await this.setState({ embroidery: embroidery.attribute_value })
          : '';
        let handles = await this.props.skuSidePanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 3
        );
        handles != undefined
          ? await this.setState({
              handles: handles.attribute_value,
              hasHandles: true,
            })
          : '';
      }

      // bottom info
      if (this.props.skuBottomPanelAttribute != undefined) {
        await this.setState({
          skuBottomPanelAttribute: this.props.skuBottomPanelAttribute,
        });
        let bottomQuiltPattern = await this.props.skuBottomPanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 4
        );
        bottomQuiltPattern != undefined
          ? this.setState({
              bottomQuiltPatternObj: bottomQuiltPattern.fabricQuiltPattern,
              bottomQuiltPattern:
                bottomQuiltPattern.fabricQuiltPattern.fabric_quilt_pattern,
            })
          : '';
        let bottomHeight = await this.props.skuBottomPanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 1
        );
        bottomHeight != undefined
          ? await this.setState({ bottomHeight: bottomHeight.attribute_value })
          : '';
      }

      // no panel check
      if (
        this.props.topPanel == null &&
        this.props.sidePanel == null &&
        this.props.gussetPanel == null &&
        this.props.bottomPanel == null
      ) {
        this.setState({ noPanels: true });
      }
    } else if (staging_id != undefined) {
      await dispatch(getSkuDraft(staging_id));
      await this.updateValidFirmnesses();
      await this.setState({ sizeValues: this.props.sizeList });
      await this.setState({ firmnessValues: this.props.firmnessList });
      await this.setState({ tableSkuList: this.props.tableSkuList });
      await this.associateCoreId(true);
      await this.setState({ boxNames: this.props.boxNames });
      await this.setState({ boxObjs: this.props.boxObjs });
      await this.setState({ frNames: this.props.frNames });
      await this.setState({ frObjs: this.props.frObjs });
      await this.setState({ zcNames: this.props.zcNames });
      await this.setState({ zcObjs: this.props.zcObjs });
      await this.setState({ plastics: this.props.plastics });
      await this.setState({ zipperParts: this.props.zipperParts });

      if (this.props.plastics != undefined && this.props.plastics.length > 1) {
        this.setState({ plasticName: 2 });
      } else {
        this.setState({ plasticName: 1 });
      }

      let frExists = this.props.frNames.find((fr) => fr.frName != '');
      if (frExists != undefined) {
        this.setState({ fireSockCheck: true });
      }

      let zcExists = this.props.zcNames.find((zc) => zc.zcName != '');
      if (zcExists != undefined) {
        this.setState({ zipperCoverCheck: true });
      }

      if (this.props.skuAttribute != undefined) {
        await this.setState({ skuAttribute: this.props.skuAttribute });
      }

      if (this.props.skuTape != undefined) {
        await this.setState({ skuTape: this.props.skuTape });
        // let topTapeObj = await this.props.skuTape.find(attr => attr.fabric_use_id === 1)
        this.props.topTape != undefined
          ? await this.setState({ topTape: this.props.topTape })
          : '';
        // let sideTapeObj = await this.props.skuTape.find(attr => attr.fabric_use_id === 2)
        this.props.sideTape != undefined
          ? await this.setState({ sideTape: this.props.sideTape })
          : '';
        // let bottomTapeObj = await this.props.skuTape.find(attr => attr.fabric_use_id === 3)
        this.props.bottomTape != undefined
          ? await this.setState({ bottomTape: this.props.bottomTape })
          : '';
      }
      //top info
      if (this.props.skuTopPanelAttribute != undefined) {
        await this.setState({
          skuTopPanelAttribute: this.props.skuTopPanelAttribute,
        });
        // let topQuiltPattern = await this.props.skuTopPanelAttribute.find(attr => attr.fabric_attribute_id === 4)
        this.props.topQuiltPattern != undefined
          ? await this.setState({ topQuiltPattern: this.props.topQuiltPattern })
          : '';
        let topHeight = await this.props.skuTopPanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 1
        );
        topHeight != undefined
          ? await this.setState({ topHeight: topHeight.attribute_value })
          : '';
      }

      //gusset info
      if (this.props.skuGussetPanelAttribute != undefined) {
        await this.setState({
          skuGussetPanelAttribute: this.props.skuGussetPanelAttribute,
        });
        // let gussetQuiltPattern = await this.props.skuGussetPanelAttribute.find(attr => attr.fabric_attribute_id === 4)
        this.props.gussetQuiltPattern != undefined
          ? await this.setState({
              gussetQuiltPattern: this.props.gussetQuiltPattern,
            })
          : '';
        let gussetHeight = await this.props.skuGussetPanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 1
        );
        gussetHeight != undefined
          ? await this.setState({ gussetHeight: gussetHeight.attribute_value })
          : '';
      }

      //side info
      if (this.props.skuSidePanelAttribute != undefined) {
        await this.setState({
          skuSidePanelAttribute: this.props.skuSidePanelAttribute,
        });
        // let sideQuiltPattern = await this.props.skuSidePanelAttribute.find(attr => attr.fabric_attribute_id === 4)
        this.props.sideQuiltPattern != undefined
          ? await this.setState({
              sideQuiltPattern: this.props.sideQuiltPattern,
            })
          : '';
        this.props.sideQuiltPattern2 != undefined
          ? await this.setState({
              sideQuiltPattern2: this.props.sideQuiltPattern2,
            })
          : '';
        let sideHeight = await this.props.skuSidePanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 1
        );
        sideHeight != undefined
          ? await this.setState({ sideHeight: sideHeight.attribute_value })
          : '';
        let embroidery = await this.props.skuSidePanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 2
        );
        embroidery != undefined
          ? await this.setState({ embroidery: embroidery.attribute_value })
          : '';
        let handles = await this.props.skuSidePanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 3
        );
        handles != undefined
          ? await this.setState({
              handles: handles.attribute_value,
              hasHandles: true,
            })
          : '';
      }

      // bottom info
      if (this.props.skuBottomPanelAttribute != undefined) {
        await this.setState({
          skuBottomPanelAttribute: this.props.skuBottomPanelAttribute,
        });
        // let bottomQuiltPattern = await this.props.skuBottomPanelAttribute.find(attr => attr.fabric_attribute_id === 4)
        this.props.bottomQuiltPattern != undefined
          ? await this.setState({
              bottomQuiltPattern: this.props.bottomQuiltPattern,
            })
          : '';
        let bottomHeight = await this.props.skuBottomPanelAttribute.find(
          (attr) => attr.fabric_attribute_id === 1
        );
        bottomHeight != undefined
          ? await this.setState({ bottomHeight: bottomHeight.attribute_value })
          : '';
      }
    }
  };

  handleChange = (e) => {
    const { dispatch } = this.props;
    if (e.target.name === 'sku') {
      dispatch(updateField(e.target.name, e.target.value.toUpperCase()));
    } else {
      dispatch(updateField(e.target.name, e.target.value));
    }
  };
  handleActiveChange = (e) => {
    const { dispatch, active } = this.props;
    dispatch(updateField('active', active ? 0 : 1));
  };

  //Sku Discription
  handleNoteChange = async (e, object) => {
    const { dispatch, skuGroup } = this.props;
    let name = e.target.value;
    await dispatch(updateField('skuNote', name));

    name.includes("'") ||
    name.includes('"') ||
    name.includes(':') ||
    name.includes(';') ||
    name.includes(',') ||
    name.includes('-')
      ? await this.setState({
          noteError: `Sku Description can NOT contain ' " : ; , -`,
        })
      : await this.setState({ noteError: '' });
    object.attribute_value = await name;

    let updatedSkuGroup = [];
    if (skuGroup != undefined) {
      for (let i = 0; i < skuGroup.length; i++) {
        let objIndex = await skuGroup[i].skuAttribute.findIndex(
          (obj) => obj.attribute_id === object.attribute_id
        );
        //if the object does not exist then add it to the skuPanelAttribute array
        //if the state/value user entered is empty then remove the obj from the array
        //else the attribute value equals whatever the user entered
        (await objIndex) === -1
          ? (skuGroup[i].skuAttribute = [...skuGroup[i].skuAttribute, object])
          : name === ''
          ? skuGroup[i].skuAttribute.splice([objIndex], 1)
          : (skuGroup[i].skuAttribute[objIndex].attribute_value = name);
        await updatedSkuGroup.push({
          ...skuGroup[i],
          skuAttribute: skuGroup[i].skuAttribute,
        });
      }
      await dispatch(updateField(skuGroup, updatedSkuGroup));
    } else {
      let objIndex = await this.state.skuAttribute.findIndex(
        (obj) => obj.attribute_id === object.attribute_id
      );

      (await objIndex) === -1
        ? this.setState({ skuAttribute: [...this.state.skuAttribute, object] })
        : name == ''
        ? this.state.skuAttribute.splice([objIndex], 1)
        : this.state.skuAttribute.splice([objIndex], 1, object);
      await dispatch(updateSkuAttribute(this.state.skuAttribute));
    }
  };

  handleUpdateBatch = (event) => {
    const { dispatch } = this.props;

    const newBatchId = event.target.value;
    dispatch(updateSkuBatch(newBatchId));
  };

  handleMaterialGroupChange = (e) => {
    const { dispatch, materialGroups } = this.props;

    const name = e.target.value;
    const group = materialGroups.find((item) => item.group_name === name);
    if (group !== undefined) {
      dispatch(updateMaterialGroup(name, group));
    }
  };
  handleFoundationGroupChange = (value) => {
    const { dispatch, foundationGroups } = this.props;

    const name = value;
    if (name === '') {
      dispatch(updateFoundationGroup('', null));
    } else {
      const group = foundationGroups.find((item) => item.group_name === name);
      if (group !== undefined) {
        dispatch(updateFoundationGroup(name, group));
      }
    }
  };
  handlePrototypeChange = (e) => {
    const { dispatch, prototypes } = this.props;

    const name = e.target.value;

    const prototype = prototypes.find((item) => item.number === name);

    if (prototype !== undefined) {
      dispatch(updatePrototype(prototype, name));
    }
  };
  handleFamilyChange = (e) => {
    const { dispatch, families } = this.props;

    let familyId = null;
    const name = e.target.value;
    const family = families.find((item) => item.family === name);
    if (family !== undefined) {
      familyId = family.id;
    }
    dispatch(updateSkuFamily(name, family));
  };
  // NEW FABRIC PANEL HANDLE CHANGES
  handleTopPanelChange = async (e, panelId) => {
    const { dispatch, activeSkus, panelLayers, panels } = this.props;
    this.setState({ topCheck: e.target.checked });

    let tickLayerOptions = [];
    let topPanel = null;
    const name = panelId ? panelId : e.target.value;
    //if the name is empty then we need to reset the top panel to make sure no id is in it
    if (name === '') {
      dispatch(updateSkuTopPanel(null, null));
      this.setState({ topPanelsArr: [] });
    }
    const sku = activeSkus.find((item) => item.sku === name);
    //filter from the panelLayers that are ticks based on the part # of tick selected
    function myFxn() {
      tickLayerOptions = panelLayers.filter(
        (item) =>
          item.fishbowlPart !== null &&
          item.fishbowlPart.num === name &&
          item.fabric_layer_type_id === 1
      );
    }

    if (sku !== undefined) {
      //figure out how to grab the panel
      topPanel = sku.topPanel.id;
      let fullPanel = panels.find((p) => p.id === sku.topPanel.id);
      await dispatch(updateSkuTopPanel(topPanel, fullPanel.panelLayers));
      await this.setState({ topPanelsArr: [] });
    } else {
      await myFxn();
      //if more than one option for panel based on tick display options
      if (tickLayerOptions.length > 1) {
        console.log('READING MULTIPLE ENTRIES');
        //iterate over all the tick layer results that came back
        //find their full panel and put the panel layers of that panel into array to be iterated over
        let fullPanelLayers = await tickLayerOptions.map((tl) => {
          let fullPanel = panels.find((p) => p.id === tl.panel_id);
          return fullPanel.panelLayers;
        });
        await this.setState({ topPanelsArr: fullPanelLayers });
      }
      //if only one option for panel based on tick go ahead and set the panel id
      else if (tickLayerOptions.length === 1) {
        console.log('READING ONLY 1 ENTRY');
        let fullPanel = panels.find(
          (p) => p.id === tickLayerOptions[0].panel_id
        );
        //we have to make each group of panelLayers as their own array
        topPanel = tickLayerOptions[0].panel_id;
        await dispatch(updateSkuTopPanel(topPanel, fullPanel.panelLayers));
        await this.setState({ topPanelsArr: [] });
      } else if (panelId != null) {
        console.log('READING NULL');
        let fullPanel = panels.find((p) => p.id === panelId);
        await dispatch(updateSkuTopPanel(panelId, fullPanel.panelLayers));
        await this.setState({ topPanelsArr: [] });
      }
    }
  };

  handleGussetPanelChange = async (e, panelId) => {
    const { dispatch, activeSkus, panelLayers, panels } = this.props;
    this.setState({ gussetCheck: e.target.checked });
    let tickLayerOptions = [];
    let gussetPanel = null;
    const name = panelId ? panelId : e.target.value;
    //if the name is empty then we need to reset the top panel to make sure no id is in it
    if (name === '') {
      dispatch(updateSkuGussetPanel(null, null));
      this.setState({ gussetPanelsArr: [] });
    }
    const sku = activeSkus.find((item) => item.sku === name);
    //filter from the panelLayers that are ticks based on the part # of tick selected
    function myFxn() {
      tickLayerOptions = panelLayers.filter(
        (item) =>
          item.fishbowlPart !== null &&
          item.fishbowlPart.num === name &&
          item.fabric_layer_type_id === 1
      );
    }

    if (sku !== undefined) {
      //figure out how to grab the panel
      gussetPanel = sku.gussetPanel.id;
      let fullPanel = panels.find((p) => p.id === sku.gussetPanel.id);
      dispatch(updateSkuGussetPanel(gussetPanel, fullPanel.panelLayers));
      await this.setState({ gussetPanelsArr: [] });
    } else {
      await myFxn();
      //if more than one option for panel based on tick display options
      if (tickLayerOptions.length > 1) {
        //iterate over all the tick layer results that came back
        //find their full panel and put the panel layers of that panel into array to be iterated over
        let fullPanelLayers = await tickLayerOptions.map((tl) => {
          let fullPanel = panels.find((p) => p.id === tl.panel_id);
          return fullPanel.panelLayers;
        });
        await this.setState({ gussetPanelsArr: fullPanelLayers });
      }
      //if only one option for panel based on tick go ahead and set the panel id
      else if (tickLayerOptions.length === 1) {
        let fullPanel = panels.find(
          (p) => p.id === tickLayerOptions[0].panel_id
        );
        //we have to make each group of panelLayers as their own array
        gussetPanel = tickLayerOptions[0].panel_id;
        dispatch(updateSkuGussetPanel(gussetPanel, fullPanel.panelLayers));
        await this.setState({ gussetPanelsArr: [] });
      } else if (panelId != null) {
        let fullPanel = panels.find((p) => p.id === panelId);
        await dispatch(updateSkuGussetPanel(panelId, fullPanel.panelLayers));
        await this.setState({ gussetPanelsArr: [] });
      }
    }
  };
  handleSidePanelChange = async (e, panelId) => {
    const { dispatch, activeSkus, panelLayers, panels } = this.props;
    this.setState({ sideCheck: e.target.checked });
    let tickLayerOptions = [];
    let sidePanel = null;
    const name = panelId ? panelId : e.target.value;
    //if the name is empty then we need to reset the top panel to make sure no id is in it
    if (name === '') {
      dispatch(updateSkuSidePanel(null, null));
      this.setState({ sidePanelsArr: [] });
    }
    //check to see if user selected a sku instead of an MA #
    const sku = activeSkus.find((item) => item.sku === name);
    //filter from the panelLayers that are ticks based on the part # of tick selected
    function myFxn() {
      tickLayerOptions = panelLayers.filter(
        (item) =>
          item.fishbowlPart !== null &&
          item.fishbowlPart.num === name &&
          item.fabric_layer_type_id === 1
      );
    }
    //if a user selected a sku name
    if (sku !== undefined) {
      //figure out how to grab the panel
      sidePanel = sku.sidePanel.id;
      let fullPanel = panels.find((p) => p.id === sku.sidePanel.id);
      dispatch(updateSkuSidePanel(sidePanel, fullPanel.panelLayers));
      await this.setState({ sidePanelsArr: [] });
    } else {
      await myFxn();
      //if more than one option for panel based on tick display options
      if (tickLayerOptions.length > 1) {
        //iterate over all the tick layer results that came back
        //find their full panel and put the panel layers of that panel into array to be iterated over
        let fullPanelLayers = await tickLayerOptions.map((tl) => {
          let fullPanel = panels.find((p) => p.id === tl.panel_id);
          return fullPanel.panelLayers;
        });
        await this.setState({ sidePanelsArr: fullPanelLayers });
      }
      //if only one option for panel based on tick go ahead and set the panel id
      else if (tickLayerOptions.length === 1) {
        let fullPanel = panels.find(
          (p) => p.id === tickLayerOptions[0].panel_id
        );
        //we have to make each group of panelLayers as their own array
        sidePanel = tickLayerOptions[0].panel_id;
        dispatch(updateSkuSidePanel(sidePanel, fullPanel.panelLayers));
        await this.setState({ sidePanelsArr: [] });
      } else if (panelId != null) {
        let fullPanel = panels.find((p) => p.id === panelId);
        await dispatch(updateSkuSidePanel(panelId, fullPanel.panelLayers));
        await this.setState({ sidePanelsArr: [] });
      }
    }
  };
  handleBottomPanelChange = async (e, panelId) => {
    const { dispatch, activeSkus, panelLayers, panels } = this.props;
    this.setState({ bottomCheck: e.target.checked });
    let tickLayerOptions = [];
    let bottomPanel = null;
    const name = panelId ? panelId : e.target.value;
    //if the name is empty then we need to reset the top panel to make sure no id is in it
    if (name === '') {
      dispatch(updateSkuBottomPanel(null, null));
      this.setState({ bottomPanelsArr: [] });
    }
    const sku = activeSkus.find((item) => item.sku === name);
    //filter from the panelLayers that are ticks based on the part # of tick selected
    function myFxn() {
      tickLayerOptions = panelLayers.filter(
        (item) =>
          item.fishbowlPart !== null &&
          item.fishbowlPart.num === name &&
          item.fabric_layer_type_id === 1
      );
    }

    if (sku !== undefined) {
      //figure out how to grab the panel
      bottomPanel = sku.bottomPanel.id;
      let fullPanel = panels.find((p) => p.id === sku.bottomPanel.id);
      dispatch(updateSkuBottomPanel(bottomPanel, fullPanel.panelLayers));
      await this.setState({ bottomPanelsArr: [] });
    } else {
      await myFxn();
      //if more than one option for panel based on tick display options
      if (tickLayerOptions.length > 1) {
        //iterate over all the tick layer results that came back
        //find their full panel and put the panel layers of that panel into array to be iterated over
        let fullPanelLayers = await tickLayerOptions.map((tl) => {
          let fullPanel = panels.find((p) => p.id === tl.panel_id);
          return fullPanel.panelLayers;
        });
        await this.setState({ bottomPanelsArr: fullPanelLayers });
      }
      //if only one option for panel based on tick go ahead and set the panel id
      else if (tickLayerOptions.length === 1) {
        let fullPanel = panels.find(
          (p) => p.id === tickLayerOptions[0].panel_id
        );
        //we have to make each group of panelLayers as their own array
        bottomPanel = tickLayerOptions[0].panel_id;
        dispatch(updateSkuBottomPanel(bottomPanel, fullPanel.panelLayers));
        await this.setState({ bottomPanelsArr: [] });
      } else if (panelId != null) {
        let fullPanel = panels.find((p) => p.id === panelId);
        await dispatch(updateSkuBottomPanel(panelId, fullPanel.panelLayers));
        await this.setState({ bottomPanelsArr: [] });
      }
    }
  };

  // handleUpdateModalOpen = (e, panelType) => { // PANEL UPDATE: Commented out until bugs can be fixed, along with all buttons.
  //   const {
  //     dispatch,
  //     openModal,
  //     identifier,
  //     isTopPanel,
  //     isGussetPanel,
  //     isSidePanel,
  //     isBottomPanel
  //   } = this.props;
  //   if (panelType == "Top") {
  //     this.handleSetTopPanel();
  //   } else if (panelType == "Gusset") {
  //     this.handleSetGussetPanel();
  //   } else if (panelType == "Side") {
  //     this.handleSetSidePanel();
  //   } else if (panelType == "Bottom") {
  //     this.handleSetBottomPanel();
  //   }
  //   dispatch(updateOpenModal());
  // };

  handleCoreGroupAttributeChange = async (e) => {
    if (e.target.checked) {
      this.setState({ specialtyMattress: true });
    } else {
      this.setState({ specialtyMattress: false });
    }
  };

  //create an array on state to store the firmnesses user selected
  updateFirmnessValues = (arr) => {
    this.setState({ firmnessValues: arr }, () => {
      this.createTableSkuList();
    });
  };
  handleFirmnessChange = (e) => {
    const { dispatch, firmnesses } = this.props;

    const value = e.target.value;
    if (typeof value != 'string') {
      this.updateFirmnessValues(value);
    } else {
      let firmnessId = null;
      const name = e.target.value;
      const firmness = firmnesses.find((item) => item.firmness === name);
      if (firmness !== undefined) {
        firmnessId = firmness.id;
      }
      dispatch(updateSkuFirmness(name, firmness));
    }
  };

  updateValidFirmnesses = () => {
    const { firmnesses, parentCore, coreTypes } = this.props;
    //iterate through possible coreTypes and return possibilities based on parent core selected
    //return the firmness ids based on possible coreTypes
    let coreTypesAvailable = coreTypes.filter((val) =>
      val.parent_core_id === parentCore.id ? val.core_firmness_id : ''
    );
    //grab the firmness ids off of the core types available
    let firmnessIdsAvailable = coreTypesAvailable.map(
      (val) => val.core_firmness_id
    );
    //filter the list of firmnesses based on firmness id options available from the core type possibilities
    let firmnessesAvailable = firmnesses.filter((val) =>
      firmnessIdsAvailable.includes(val.id) ? val : ''
    );
    this.setState({ validFirmnesses: firmnessesAvailable });
  };

  updateBoxRows = async () => {
    let newBoxNamesArr = [...this.state.boxNames];
    let newBoxObjsArr = [...this.state.boxObjs];
    //add box name if it doesn't exist
    await this.state.sizeValues.forEach((size) => {
      let boxSizeExists = this.state.boxNames.find((box) => box.size === size);
      let boxObjExists = this.state.boxObjs.find((box) => box.size === size);
      if (boxSizeExists === undefined) {
        newBoxNamesArr.push({
          size: size,
          boxName1: '',
          boxName2: '',
        });
      }
      if (boxObjExists === undefined) {
        newBoxObjsArr.push({
          size: size,
          attr_obj1: {},
          attr_obj2: {},
        });
      }
    });
    //remove box Name if no longer in the size list
    await newBoxNamesArr.forEach((box) => {
      let sizeExists = this.state.sizeValues.find((size) => size === box.size);
      if (sizeExists === undefined) {
        newBoxNamesArr.splice(
          newBoxNamesArr.findIndex(({ size }) => size == box.size),
          1
        );
      }
    });
    await newBoxObjsArr.forEach((box) => {
      let sizeExists = this.state.sizeValues.find((size) => size === box.size);
      if (sizeExists === undefined) {
        newBoxObjsArr.splice(
          newBoxObjsArr.findIndex(({ size }) => size == box.size),
          1
        );
      }
    });

    await this.setState({ boxNames: newBoxNamesArr }, () =>
      this.createTableSkuList()
    );
    await this.setState({ boxObjs: newBoxObjsArr });
  };

  updateFRRows = async () => {
    //same logic as updating the boxes arrays
    let newFRNamesArr = [...this.state.frNames];
    let newFRObjsArr = [...this.state.frObjs];
    await this.state.sizeValues.forEach((size) => {
      let frSizeExists = this.state.frNames.find((fr) => fr.size === size);
      let frObjExists = this.state.frObjs.find((fr) => fr.size === size);
      if (frSizeExists === undefined) {
        newFRNamesArr.push({
          size: size,
          frName: '',
        });
      }
      if (frObjExists === undefined) {
        newFRObjsArr.push({
          id: null,
          size: size,
          sku_id: null,
          core_attribute_id: 1,
          attribute_value: '',
        });
      }
    });
    //remove FR Name if no longer in the size list
    await newFRNamesArr.forEach((fr) => {
      let sizeExists = this.state.sizeValues.find((size) => size === fr.size);
      if (sizeExists === undefined) {
        newFRNamesArr.splice(
          newFRNamesArr.findIndex(({ size }) => size == fr.size),
          1
        );
      }
    });
    await newFRObjsArr.forEach((fr) => {
      let sizeExists = this.state.sizeValues.find((size) => size === fr.size);
      if (sizeExists === undefined) {
        newFRObjsArr.splice(
          newFRObjsArr.findIndex(({ size }) => size == fr.size),
          1
        );
      }
    });

    await this.setState({ frNames: newFRNamesArr });
    await this.setState({ frObjs: newFRObjsArr });
  };

  updateZCRows = async () => {
    //same logic as updating the fire sock arrays
    let newZCNamesArr = [...this.state.zcNames];
    let newZCObjsArr = [...this.state.zcObjs];
    await this.state.sizeValues.forEach((size) => {
      let zcSizeExists = this.state.zcNames.find((zc) => zc.size === size);
      let zcObjExists = this.state.zcObjs.find((zc) => zc.size === size);
      if (zcSizeExists === undefined) {
        newZCNamesArr.push({
          size: size,
          frName: '',
        });
      }
      if (zcObjExists === undefined) {
        newZCObjsArr.push({
          id: null,
          size: size,
          sku_id: null,
          attribute_id: 3,
          attribute_value: '',
        });
      }
    });
    //remove ZC Name if no longer in the size list
    await newZCNamesArr.forEach((zc) => {
      let sizeExists = this.state.sizeValues.find((size) => size === zc.size);
      if (sizeExists === undefined) {
        newZCNamesArr.splice(
          newZCNamesArr.findIndex(({ size }) => size == zc.size),
          1
        );
      }
    });
    await newZCObjsArr.forEach((zc) => {
      let sizeExists = this.state.sizeValues.find((size) => size === zc.size);
      if (sizeExists === undefined) {
        newZCObjsArr.splice(
          newZCObjsArr.findIndex(({ size }) => size == zc.size),
          1
        );
      }
    });

    await this.setState({ zcNames: newZCNamesArr });
    await this.setState({ zcObjs: newZCObjsArr });
  };
  //create an array on state to store the sizes user selected
  updateSizeValues = (arr) => {
    this.setState({ sizeValues: arr }, () => {
      this.updateFRRows();
      this.updateBoxRows();
      this.updateZCRows();
    });
  };
  handleSizeChange = async (e) => {
    const { dispatch, sizes } = this.props;

    const value = e.target.value;
    if (typeof value != 'string') {
      this.updateSizeValues(value);
    } else {
      let sizeId = null;
      const name = e.target.value;
      const size = sizes.find((item) => item.size === name);
      if (size !== undefined) {
        sizeId = size.id;
      }
      await dispatch(updateField('sizeName', name));
      await dispatch(updateField('size', size));
      await this.associateCoreId();
    }
  };

  core_firmness_id;
  parent_core_id;
  handleParentCoreChange = async (e) => {
    const { dispatch, parentCores } = this.props;

    //get the parent core id
    let parentCoreId = null;
    const name = e.target.value;
    const parentCore = await parentCores.find(
      (item) => item.parent_core === name
    );
    await dispatch(updateSkuParentCore(name, parentCore));

    //if the Sku Group already exists we only want to update parent core on the existing group of skus
    //the user will still have to select the firmness again - because firmnesses available depend on selected parent core

    await this.updateValidFirmnesses();
    await this.setState({ tableSkuList: [] }); //clear the table & firmnesses if a user selects parent core
    await this.setState({ firmnessValues: [] }); //user my select a different parent core where previously selected firmnesses are not available
    await this.associateCoreId();
  };

  associateCoreId = async (initialLoad) => {
    const {
      dispatch,
      parentCore,
      firmnesses,
      firmnessName,
      coreTypes,
      sizes,
      sizeName,
      cores,
    } = this.props;
    //updating a batch of skus with core_id
    if (this.state.tableSkuList.length != 0 && parentCore.id != null) {
      //filter through the skuList and only associate core Ids to create the sku is create attribute is true
      //the create attribute is toggled by selecting or unselecting the gray chip on the size/firmness table
      let skuList = await this.state.tableSkuList.filter((sku) => {
        if (sku.create === true) {
          return true;
        } else {
          return false;
        }
      });
      let createSkuList = [];
      let iterateCreatingSkusAssociateCoreIdSkuList = () =>
        skuList.forEach(async (sku) => {
          //bring in firmnessId to get the coreTypeId
          let firmnessId = null;
          let firmnessName = null;
          let firmnessAlias = null;
          const firmness = await firmnesses.find(
            (item) => item.firmness === sku.firmness
          );
          if (firmness !== undefined) {
            firmnessId = await firmness.id;
            firmnessName = await firmness.firmness;
            firmnessAlias = await firmness.alias;
          }
          let coreTypeId = null;
          const coreTypeObj = await coreTypes.find(
            (item) =>
              item.core_firmness_id === firmnessId &&
              item.parent_core_id === parentCore.id
          );
          if (coreTypeObj != undefined) {
            coreTypeId = coreTypeObj.id;

            //use coreTypeId and and sizeId to get the coreId
            let sizeId = null;
            let sizeName = null;
            let sizeAlias = null;
            const size = await sizes.find((item) => item.size === sku.size);
            if (size !== undefined) {
              sizeId = size.id;
              sizeName = size.size;
              sizeAlias = size.alias;
            }
            //get coreId based on sizeId and coreTypeId
            //or statement is to account for the core
            let coreId = null;
            //we do the or item.size_id === null because all foam mattress cores are not size specific
            const coreObj = await cores.find(
              (item) =>
                (item.size_id === sizeId && item.core_type_id === coreTypeId) ||
                (item.size_id === null && item.core_type_id === coreTypeId)
            );
            if (coreObj == undefined && initialLoad !== true) {
              this.setState({
                coreError: `Make a ${parentCore.parent_core} Core for size ${sizeName} ${firmnessName}.`,
              });
            }

            coreId = await coreObj.id;
            let obj = {
              size_id: sizeId,
              core_id: coreId,
              sizeName: sizeName,
              sizeAlias: sizeAlias,
              firmnessName: firmnessName,
              firmnessAlias: firmnessAlias,
            };
            if (obj !== null) {
              createSkuList.push(obj);
            }
          }
        });
      await iterateCreatingSkusAssociateCoreIdSkuList();
      await this.setState({ createSkuList: createSkuList });
      // () => console.log(this.state.createSkuList, 'FINAL SKU LIST CREATED') put this in setstate callback to check
    }
    //for updating an existing sku with core_id
    else if (sizeName && firmnessName && parentCore.id != null) {
      const { dispatch } = this.props;
      //bring in firmnessId to get the coreTypeId
      let firmnessId = null;
      const firmness = await firmnesses.find(
        (item) => item.firmness === firmnessName
      );
      if (firmness !== undefined) {
        firmnessId = firmness.id;
      }
      let coreTypeId = null;
      const coreTypeObj = await coreTypes.find(
        (item) =>
          item.core_firmness_id === firmnessId &&
          item.parent_core_id === parentCore.id
      );
      coreTypeId = coreTypeObj.id;
      //use coreTypeId and and sizeId to get the coreId
      let sizeId = null;
      const size = await sizes.find((item) => item.size === sizeName);
      if (size !== undefined) {
        sizeId = size.id;
      }
      let coreId = null;
      const coreObj = await cores.find(
        (item) =>
          (item.size_id === sizeId && item.core_type_id === coreTypeId) ||
          (item.size_id === null && item.core_type_id === coreTypeId)
      );
      coreId = coreObj.id;
      //part num is not showing up
      await dispatch(updateSkuCore(coreObj));
    }
  };

  //logic for updating skuTable items and whether to create them or not
  handleChipSelect = (size, firmness) => {
    let newTableSkuList = this.state.tableSkuList.map((sku) => {
      if (sku.size === size && sku.firmness === firmness) {
        return {
          firmness: sku.firmness,
          size: sku.size,
          create: !sku.create,
        };
      } else {
        return sku;
      }
    });
    this.setState({ tableSkuList: newTableSkuList }, () => {
      this.associateCoreId();
    });
  };
  //logic to create unique sku objects based on size & firmness selected in dropdowns
  createTableSkuList = async (initialLoad) => {
    const { skuGroup } = this.props;
    const { firmnessValues, sizeValues } = this.state;
    let skuObject = {};
    let arr = [];
    let uniqueArr = [];
    await this.getCoreList();
    if (initialLoad !== true && this.state.tableSkuList.length < 1) {
    }
    for (let i = 0; sizeValues.length > i; i++) {
      for (let j = 0; firmnessValues.length > j; j++) {
        if (initialLoad === true) {
          let firmnessSizeComboExists = await skuGroup.find(
            (sku) =>
              sku.size.size === sizeValues[i] &&
              sku.core.type.firmness.firmness === firmnessValues[j]
          );
          skuObject = {
            firmness: firmnessValues[j],
            size: sizeValues[i],
            create: firmnessSizeComboExists != undefined ? true : false,
          };
        } else {
          //if skuGroup exists and the specific sku size and firmness exists in the skugroup then create should be false and just use the exists obj but replace the firmness and size values?
          //if Skugroup exists but the specific size and firmness does not exist then the create should be true
          //then on associate core id() we need to make sure it iterates over the existing skuGroup objects and replaces the core object on the existing sku in the existing sku group
          let existingSkuObj = this.state.tableSkuList.find(
            (s) => s.firmness === firmnessValues[j] && s.size === sizeValues[i]
          );
          if (existingSkuObj != undefined) {
            skuObject = {
              ...existingSkuObj,
            };
          } else {
            skuObject = {
              firmness: firmnessValues[j],
              size: sizeValues[i],
              create: true,
            };
          }
        }
        await arr.push(skuObject);
        uniqueArr = await _.uniqBy(arr, function (elem) {
          return [elem.size, elem.firmness].join();
        });
      }
    }
    await this.setState({ tableSkuList: uniqueArr }, () => {
      this.associateCoreId(initialLoad);
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.saveSuccess) {
      this.setState({ open: true });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleInputOverlay = () => {
    this.setState({ inputOverlay: !this.state.inputOverlay });
  };

  handleSubmitBtn = () => {
    this.setState({ submitButton: !this.state.submitButton });
  };

  state = {
    open: false,
    vertical: 'top',
    horizontal: 'center',
    showNewMaterial: false,
    showNewFoundation: false,
    plasticName: '',
    zipperName: '',
    //show the list of panels based on the tick part # selected, so user can select which specific panel they want
    topPanelsArr: [],
    topCheck: '',
    sidePanelsArr: [],
    sideCheck: '',
    bottomPanelsArr: [],
    bottomCheck: '',
    gussetPanelsArr: [],
    gussetCheck: '',
    firmnessValues: [],
    sizeValues: [],
    tableSkuList: [],
    createSkuList: [],
    //display firmnesses allowed for parent core selected - if user does not see firmness they are looking for
    //then a core with that parent core firmness does not exist
    validFirmnesses: [],
    //new sku panel attribute arrays to create and send
    skuTopPanelAttribute: [],
    skuGussetPanelAttribute: [],
    skuSidePanelAttribute: [],
    skuBottomPanelAttribute: [],
    skuAttribute: [],
    skuCoreAttribute: [],
    //heights to display
    topHeight: '',
    gussetHeight: '',
    sideHeight: '',
    bottomHeight: '',
    embroidery: '',
    hasHandles: false,
    hasZippers: false,
    handles: null,
    //quilt patterns to display
    topQuiltPatternObj: {},
    topQuiltPattern: '',
    gussetQuiltPatternObj: {},
    gussetQuiltPattern: '',
    sideQuiltPatternObj: {},
    sideQuiltPattern: '',
    sideQuiltPatternObj2: {},
    sideQuiltPattern2: '',
    bottomQuiltPatternObj: {},
    bottomQuiltPattern: '',
    //tapes to display
    topTapeObj: {},
    topTape: '',
    sideTapeObj: {},
    sideTape: '',
    bottomTapeObj: {},
    bottomTape: '',
    skuTape: [],
    boxObjs: [],
    boxNames: [],
    frObjs: [],
    frNames: [],
    zcNames: [],
    zcObjs: [],
    inputOverlay: false,
    masterSkuArr: [],
    upcRequired: false,
    noteError: '',
    coreError: '',
    fireSockCheck: false,
    noPanels: false,
    zipperCoverCheck: false,
    submitButton: false,
    plastic1Part: '',
    plastic2Part: '',
    plastics: [],
    plastic1PartDisplay: '',
    plastic2PartDisplay: '',
    zipperParts: [],
    openModal: false,
    successModal: false,
    identifier: 'testing',
    isTopPanel: false,
    isGussetPanel: false,
    isSidePanel: false,
    isBottomPanel: false,
    defineBottomPanel: '',
  };

  //what- updates/adds/removes embroidery, height, and handles attributes on the corresponding attribute arrays
  //how- takes in the object that should be created, edited, or added, the state/value the user wishes to add, edit, remove, name of the skuPanelAttribute that the attribute resides on.
  //(example- embroidery and height reside on the skuSidePanelAttribute array)
  //we locate the object, if it exists by its fabric_attribute_id then add it if it doesn't exist, remove it if it does, or edit if it needs to be edited
  handlePanelAttributeChange = async (object, state, skuPanelAttribute) => {
    const { dispatch, skuGroup } = this.props;
    //pass in object matching the correct fabric_attribute_id (ex: height)
    //pass the the state that was just updated
    //set attribute value of object to value that state was updated to
    object.attribute_value = state;
    //if skuGroup exists check all the skuPanelAttribute arrays on the skuGroup
    let updatedSkuGroup = [];
    if (skuGroup != undefined) {
      for (let i = 0; i < skuGroup.length; i++) {
        let objIndex = await skuGroup[i][skuPanelAttribute].findIndex(
          (obj) => obj.fabric_attribute_id === object.fabric_attribute_id
        );
        //if the object does not exist then add it to the skuPanelAttribute array
        //if the state/value user entered is empty then remove the obj from the array
        //else the attribute value equals whatever the user entered
        let tempSkuGroupAttributeObjArr = skuGroup[i][skuPanelAttribute];
        (await objIndex) === -1
          ? (tempSkuGroupAttributeObjArr = [
              ...skuGroup[i][skuPanelAttribute],
              object,
            ])
          : state === ''
          ? tempSkuGroupAttributeObjArr.splice([objIndex], 1)
          : (tempSkuGroupAttributeObjArr[objIndex].attribute_value = state);
        await updatedSkuGroup.push({
          ...skuGroup[i],
          [skuPanelAttribute]: tempSkuGroupAttributeObjArr,
        });
      }
      await dispatch(updateField('skuGroup', updatedSkuGroup));
    } else {
      //see if object exists
      let objIndex = await this.state[skuPanelAttribute].findIndex(
        (obj) => obj.fabric_attribute_id === object.fabric_attribute_id
      );
      //if object does not exist add it to skuFabricAttribute array if it does exist then check to see if user entered '' (meaning they deleted height entry)
      //if they deleted it then remove the object from the skuFabricAttribute array else update attribute_value with new value from user
      (await objIndex) === -1
        ? this.setState({
            [skuPanelAttribute]: [...this.state[skuPanelAttribute], object],
          })
        : state === ''
        ? this.state[skuPanelAttribute].splice([objIndex], 1)
        : (this.state[skuPanelAttribute][objIndex].attribute_value = state);
      await dispatch(
        updateField(skuPanelAttribute, this.state[skuPanelAttribute])
      );
    }
  };

  handleQuiltChange = async (
    quiltPattern,
    object,
    quiltPatternObj,
    skuPanelAttribute
  ) => {
    const { fabricQuiltPatterns, skuGroup, dispatch } = this.props;

    let name = quiltPattern;
    if (name == '') {
      let updatedArr = [...this.state[skuPanelAttribute]];
      let updatedSkuGroup = [];
      if (skuGroup != undefined) {
        for (let i = 0; i < skuGroup.length; i++) {
          let objIndex = await skuGroup[i][skuPanelAttribute].findIndex(
            (obj) => obj.fabric_attribute_id === object.fabric_attribute_id
          );
          (await objIndex) !== -1
            ? skuGroup[i][skuPanelAttribute].splice(objIndex, 1)
            : '';
          //update the skuTape array on local state and global state - redux
          await this.setState({ [skuPanelAttribute]: updatedArr });
          await updatedSkuGroup.push({
            ...skuGroup[i],
            [skuPanelAttribute]: skuGroup[i][skuPanelAttribute],
          });
        }
        await dispatch(updateField(skuGroup, updatedSkuGroup));
      } else {
        let objIndex = await this.state[skuPanelAttribute].findIndex(
          (obj) => obj.fabric_attribute_id === object.fabric_attribute_id
        );
        (await objIndex) !== -1 ? updatedArr.splice(objIndex, 1) : '';
        //update the skuTape array on local state and global state - redux
        await this.setState({ [skuPanelAttribute]: updatedArr });
        await dispatch(updateField(skuPanelAttribute, updatedArr));
      }
    } else {
      const fabricQuiltPatternObj = await fabricQuiltPatterns.find(
        (item) => item.fabric_quilt_pattern === name
      );
      if (fabricQuiltPatternObj != undefined) {
        await this.setState({
          [quiltPatternObj]: {
            ...fabricQuiltPatternObj,
            ...object,
            attribute_value: fabricQuiltPatternObj.id,
          },
        });
        let updatedSkuGroup = [];
        if (skuGroup != undefined) {
          for (let i = 0; i < skuGroup.length; i++) {
            //see if the object already exists in the skuTape array we want to send to DB
            let objIndex = await skuGroup[i][skuPanelAttribute].findIndex(
              (obj) => obj.fabric_attribute_id === object.fabric_attribute_id
            );
            (await objIndex) === -1
              ? (skuGroup[i][skuPanelAttribute] = [
                  ...skuGroup[i][skuPanelAttribute],
                  this.state[quiltPatternObj],
                ])
              : (skuGroup[i][skuPanelAttribute][objIndex].attribute_value =
                  this.state[quiltPatternObj].attribute_value);
            await updatedSkuGroup.push({
              ...skuGroup[i],
              [skuPanelAttribute]: skuGroup[i][skuPanelAttribute],
            });
          }
          await dispatch(updateField(skuGroup, updatedSkuGroup));
        } else {
          //see if the object already exists in the skuTape array we want to send to DB
          let objIndex = await this.state[skuPanelAttribute].findIndex(
            (obj) => obj.fabric_attribute_id === object.fabric_attribute_id
          );
          (await objIndex) === -1
            ? this.setState({
                [skuPanelAttribute]: [
                  ...this.state[skuPanelAttribute],
                  this.state[quiltPatternObj],
                ],
              })
            : (this.state[skuPanelAttribute][objIndex].attribute_value =
                this.state[quiltPatternObj].attribute_value);
          await dispatch(
            updateField(skuPanelAttribute, this.state[skuPanelAttribute])
          );
        }
      }
    }
  };

  handleTapeChange = async (e, fabric_use_id, tape, panelTapeObj) => {
    const { parts, skuGroup, dispatch } = this.props;
    let partNum = this.state[tape].split(' -')[0];
    //if the user clears the fields or nothing is entered when clicking on the field
    if (partNum == '') {
      let updatedArr = [...this.state.skuTape];
      let updatedSkuGroup = [];
      if (skuGroup != undefined) {
        for (let i = 0; i < skuGroup.length; i++) {
          let objIndex = await skuGroup[i].skuTape.findIndex(
            (obj) => obj.fabric_use_id === fabric_use_id
          );
          (await objIndex) !== -1
            ? skuGroup[i].skuTape.splice(objIndex, 1)
            : '';
          //update the skuTape array on local state and global state - redux
          await this.setState({ skuTape: updatedArr });
          await updatedSkuGroup.push({
            ...skuGroup[i],
            skuTape: skuGroup[i].skuTape,
          });
        }
        await dispatch(updateField(skuGroup, updatedSkuGroup));
      } else {
        let objIndex = await this.state.skuTape.findIndex(
          (obj) => obj.fabric_use_id === fabric_use_id
        );
        (await objIndex) !== -1 ? updatedArr.splice(objIndex, 1) : '';
        //update the skuTape array on local state and global state - redux
        await this.setState({ skuTape: updatedArr });
        await dispatch(updateField('skuTape', updatedArr));
      }
    } else {
      //search the e.target.value for a matching part.num on object from fishbowl_parts array of objects
      let partObj = await parts.find((item) => item.num === partNum);
      //create the object we need to send into the database only if the fishbowl_part user enters exists
      let tapeObj;
      if (partObj != undefined) {
        tapeObj = {
          id: null,
          sku_id: null,
          fishbowl_part_id: partObj.id,
          fabric_use_id: fabric_use_id,
        };
      }
      await this.setState({ [panelTapeObj]: tapeObj });
      if (tapeObj) {
        let updatedSkuGroup = [];
        if (skuGroup != undefined) {
          for (let i = 0; i < skuGroup.length; i++) {
            //see if the object already exists in the skuTape array we want to send to DB
            let objIndex = await skuGroup[i].skuTape.findIndex(
              (obj) => obj.fabric_use_id === tapeObj.fabric_use_id
            );
            (await objIndex) === -1
              ? (skuGroup[i].skuTape = [
                  ...skuGroup[i].skuTape,
                  this.state[panelTapeObj],
                ])
              : (skuGroup[i].skuTape[objIndex].fishbowl_part_id =
                  tapeObj.fishbowl_part_id);
            await updatedSkuGroup.push({
              ...skuGroup[i],
              skuTape: skuGroup[i].skuTape,
            });
          }
          await dispatch(updateField(skuGroup, updatedSkuGroup));
        } else {
          //see if the object already exists in the skuTape array we want to send to DB
          let objIndex = await this.state.skuTape.findIndex(
            (obj) => obj.fabric_use_id === tapeObj.fabric_use_id
          );
          (await objIndex) === -1
            ? this.setState({
                skuTape: [...this.state.skuTape, this.state[panelTapeObj]],
              })
            : (this.state.skuTape[objIndex].fishbowl_part_id =
                tapeObj.fishbowl_part_id);
          await dispatch(updateField('skuTape', this.state.skuTape));
        }
      }
    }
  };

  handleBoxChange = async (e, object, size) => {
    //pass in object matching the correct fabric_attribute_id (ex: box)
    //pass the the state that was just updated
    const { parts, dispatch } = this.props;

    let name = e.target.value;
    //find the box object matching the box field we just changed and update its name
    let newBoxNamesArr = await this.state.boxNames.map((box) => {
      if (box.size === size) {
        if (object.boxName == 1) {
          return {
            size: box.size,
            boxName1: name,
            boxName2: box.boxName2,
          };
        } else if (object.boxName == 2) {
          return {
            size: box.size,
            boxName1: box.boxName1,
            boxName2: name,
          };
        }
      }
      return box;
    });
    await this.setState({ boxNames: newBoxNamesArr });
    //this updates the boxNames array on state to update values displaying in box inputs
    //get list of boxes from fishbowl parts
    let boxes = await parts.filter((p) => p.category === 'Box');
    //find the box that matches what we selected in the dropdown
    let box = await boxes.find((b) => b.internal_desc === name);
    //add attribute_value on the passed in object to the id of the box from fishbowl parts
    object.attribute_value = box.id;
    let newBoxObjsArr = await this.state.boxObjs.map((box) => {
      if (box.size === size) {
        if (object.boxName == 1) {
          return {
            size: box.size,
            attr_obj1: object,
            attr_obj2: box.attr_obj2,
          };
        } else if (object.boxName == 2) {
          return {
            size: box.size,
            attr_obj1: box.attr_obj1,
            attr_obj2: object,
          };
        }
      }
      return box;
    });
    await this.setState({ boxObjs: newBoxObjsArr });
  };

  handlePlasticChange = async (e) => {
    this.setState({ plasticName: e.target.value });
  };

  handlePlastic1PartChange = async (e) => {
    this.setState({ plastic1PartDisplay: e.target.value });
    this.setState({
      plastic1Part: {
        id: null,
        sku_id: null,
        attribute_id: 1,
        attribute_value: e.target.value.id,
      },
    });
  };

  handlePlastic2PartChange = (e) => {
    this.setState({ plastic2PartDisplay: e.target.value });
    this.setState({
      plastic2Part: {
        id: null,
        sku_id: null,
        attribute_id: 1,
        attribute_value: e.target.value.id,
      },
    });
  };

  handleFRChange = async (e, size) => {
    const { parts } = this.props;
    let name = e.target.value;
    //find the FR object matching the FR field we just changed and update its name
    let newFRNamesArr = await this.state.frNames.map((fr) => {
      if (fr.size === size) {
        return {
          size: fr.size,
          frName: name,
        };
      } else {
        return fr;
      }
    });
    await this.setState({ frNames: newFRNamesArr });

    let frs = await parts.filter((p) => p.category === 'FR');
    //find the fr that matches what we selected in the dropdown
    let frPartObj = await frs.find(
      (fr) => fr.num + ' - ' + fr.internal_desc === name
    );
    //add attribute_value which is part id from the fr part object that matches the
    //num and internal desc
    let newfrObjsArr = await this.state.frObjs.map((fr) => {
      if (fr.size === size) {
        return {
          ...fr,
          attribute_value: frPartObj.id,
        };
      }
      return fr;
    });
    await this.setState({ frObjs: newfrObjsArr });
  };

  clearFRValues = async () => {
    let clearedFRNames = this.state.frNames.map((fr) => {
      return {
        ...fr,
        frName: '',
      };
    });
    await this.setState({ frNames: clearedFRNames });

    let clearedFRObjs = this.state.frObjs.map((fr) => {
      return {
        ...fr,
        attribute_value: '',
      };
    });
    await this.setState({ frObjs: clearedFRObjs });
  };

  handleZCChange = async (e, size) => {
    const { parts } = this.props;
    let name = e.target.value;
    //find the ZC object matching the ZC field we just changed and update its name
    let newZCNamesArr = await this.state.zcNames.map((zc) => {
      if (zc.size === size) {
        return {
          size: zc.size,
          zcName: name,
        };
      } else {
        return zc;
      }
    });
    await this.setState({ zcNames: newZCNamesArr });

    let zcs = await parts.filter((p) => p.category === 'Zipper Cover');
    //find the fr that matches what we selected in the dropdown
    let zcPartObj = await zcs.find(
      (zc) => zc.num + ' - ' + zc.internal_desc === name
    );
    //add attribute_value which is part id from the fr part object that matches the
    //num and internal desc
    let newzcObjsArr = await this.state.zcObjs.map((zc) => {
      if (zc.size === size) {
        return {
          ...zc,
          attribute_value: zcPartObj.id,
        };
      }
      return zc;
    });
    await this.setState({ zcObjs: newzcObjsArr });
  };

  clearZCValues = async () => {
    let clearedZCNames = this.state.zcNames.map((zc) => {
      return {
        ...zc,
        zcName: '',
      };
    });
    await this.setState({ zcNames: clearedZCNames });

    let clearedZCObjs = this.state.zcObjs.map((zc) => {
      return {
        ...zc,
        attribute_value: '',
      };
    });
    await this.setState({ zcObjs: clearedZCObjs });
  };

  handleDraftSubmit = async (e) => {
    e.persist();
    const {
      isError,
      sku,
      familyName,
      brandName,
      customerName,
      manufacturerName,
      parentCore,
      parentCoreName,
      batchId,
      ltFoundationGroup,
      ltMaterialGroup,
      topPanel,
      topPanelLayers,
      gussetPanel,
      gussetPanelLayers,
      sidePanel,
      sidePanelLayers,
      bottomPanel,
      bottomPanelLayers,
      skuTopPanelAttribute,
      skuGussetPanelAttribute,
      skuSidePanelAttribute,
      skuBottomPanelAttribute,
      skuAttribute,
      prototypeName,
      dispatch,
      plastics,
      zipperParts,
      plastic1Part,
      plastic2Part,
      plastic1PartDisplay,
    } = this.props;

    const skuObj = {
      sku: sku != '' ? sku : 'draft',
      familyName: familyName,
      batchId: batchId,
      brandName: brandName,
      customerName: customerName,
      manufacturerName: manufacturerName,
      parentCore: parentCore,
      parentCoreName: parentCoreName,
      prototypeName: prototypeName,
      ltFoundationGroup: ltFoundationGroup,
      ltMaterialGroup: ltMaterialGroup,
      topPanel: topPanel,
      topQuiltPattern: this.state.topQuiltPattern,
      topPanelLayers: topPanelLayers,
      gussetQuiltPattern: this.state.gussetQuiltPattern,
      gussetPanel: gussetPanel,
      gussetPanelLayers: gussetPanelLayers,
      sideQuiltPattern: this.state.sideQuiltPattern,
      sideQuiltPattern2: this.state.sideQuiltPattern2,
      sidePanel: sidePanel,
      sidePanelLayers: sidePanelLayers,
      bottomQuiltPattern: this.state.bottomQuiltPattern,
      bottomPanel: bottomPanel,
      bottomPanelLayers: bottomPanelLayers,
      skuTopPanelAttribute: skuTopPanelAttribute,
      skuGussetPanelAttribute: skuGussetPanelAttribute,
      skuSidePanelAttribute: skuSidePanelAttribute,
      skuBottomPanelAttribute: skuBottomPanelAttribute,
      skuAttribute: skuAttribute,
      skuTape: this.state.skuTape,
      topTape: this.state.topTape,
      sideTape: this.state.sideTape,
      bottomTape: this.state.bottomTape,
      firmnessList: this.state.firmnessValues ? this.state.firmnessValues : [],
      sizeList: this.state.sizeValues ? this.state.sizeValues : [],
      tableSkuList: this.state.tableSkuList ? this.state.tableSkuList : [],
      boxObjs: this.state.boxObjs ? this.state.boxObjs : [],
      boxNames: this.state.boxNames ? this.state.boxNames : [],
      frNames: this.state.frNames ? this.state.frNames : [],
      frObjs: this.state.frObjs ? this.state.frObjs : [],
      zcNames: this.state.zcNames ? this.state.zcNames : [],
      zcObjs: this.state.zcObjs ? this.state.zcObjs : [],
      // plastics: this.state.plastics ? this.state.plastics : [],
      plastics: [this.state.plastic1Part, this.state.plastic2Part]
        ? [this.state.plastic1Part, this.state.plastic2Part]
        : [],
      zipperParts: this.state.zipperParts ? this.state.zipperParts : [],
    };

    let skuObjArr;
    if (this.state.createSkuList.length >= 1) {
      //...obj includes 'size_id','core_id','sizeName','sizeAlias','firmnessName','firmnessAlias'
      skuObjArr = this.state.createSkuList.map((obj) => {
        return { ...obj, ...skuObj };
      });
    } else {
      skuObjArr = [{ ...skuObj }];
    }

    const draft = {
      id: this.props.match.params.staging_id
        ? this.props.match.params.staging_id
        : null,
      sku: sku != '' ? sku : 'draft',
      payload: await JSON.stringify(skuObjArr),
    };
    await dispatch(saveSkuDraft(draft));
    if (!isError) {
      this.props.history.push('/skus');
    }
  };

  handleSubmit = async (e) => {
    e.persist();
    const {
      isError,
      dispatch,
      id,
      active,
      firmness,
      sku,
      familyName,
      firmnessName,
      brandName,
      sizeName,
      families,
      firmnesses,
      brands,
      cores,
      sizes,
      parts,
      customerName,
      manufacturerName,
      prototypeName,
      prototype,
      ltFoundationGroup,
      ltMaterialGroup,
      customers,
      batchId,
      manufacturers,
      prototypes,
      skuAttribute,
      skuCoreAttribute,
      skuTopPanelAttribute,
      skuGussetPanelAttribute,
      skuSidePanelAttribute,
      skuBottomPanelAttribute,
      coreTypes,
      parentCore,
      topPanel,
      gussetPanel,
      sidePanel,
      bottomPanel,
      coreGroupAttributeId,
      skuTape,
      skuGroup,
      allSkus,
      plastics,
      zipperParts,
    } = this.props;
    let brandId = null;
    const brandObj = brands.find((item) => item.brand === brandName);
    if (brandObj !== undefined) {
      brandId = brandObj.id;
    }

    //TESTING
    let firmnessId = null;
    let firmnessAlias = null;
    const firmnessObj = firmnesses.find(
      (item) => item.firmness === firmnessName
    );
    if (firmnessObj !== undefined) {
      firmnessId = firmnessObj.id;
      firmnessAlias = firmnessObj.alias;
    }

    let familyId = null;
    const familyObj = families.find((item) => item.family === familyName);
    if (familyObj !== undefined) {
      familyId = familyObj.id;
    }
    let customerId = null;
    const customerObj = customers.find((item) => item.name === customerName);
    if (customerObj !== undefined) {
      customerId = customerObj.id;
    }
    let manufacturerId = null;
    const manufacturerObj = manufacturers.find(
      (item) => item.name === manufacturerName
    );
    if (manufacturerObj !== undefined) {
      manufacturerId = manufacturerObj.id;
    }
    let prototypeId = null;
    const prototypeObj = prototypes.find(
      (item) => item.number === prototypeName
    );
    if (prototypeObj !== undefined) {
      prototypeId = prototypeObj.id;
    }

    let sizeId = null;
    let coreId = null;
    let sizeAlias = null;
    // only need this for individual sku
    //for a batch this info is in this.state.createSkuList
    // - we associated it all and saved it in array createSkuList when the size/firmness chart was made
    if (id !== -1) {
      const sizeObj = sizes.find((item) => item.size === sizeName);
      if (sizeObj !== undefined) {
        sizeId = sizeObj.id;
        sizeAlias = sizeObj.alias;
      }
      let coreTypeId = null;
      const coreTypeObj = await coreTypes.find(
        (item) =>
          item.core_firmness_id === firmnessId &&
          item.parent_core_id === parentCore.id
      );
      coreTypeId = coreTypeObj.id;

      const coreObj = await cores.find(
        (item) =>
          (item.size_id === sizeId && item.core_type_id === coreTypeId) ||
          (item.size_id === null && item.core_type_id === coreTypeId)
      );
      if (coreObj !== undefined) {
        coreId = coreObj.id;
      }
    }

    let newCoreGroupAttributeId =
      this.determineCoreGroupAttributeValueBySize(sizeId);

    //this is for creating entirely new skus
    const masterSkuObj = {
      id: id != -1 ? id : null,
      active: id === -1 ? 1 : active,
      category_id: 1,
      brand_id: brandId,
      family_id: familyId,
      batch_id: batchId,
      //this is fix for now cause boxes both have the same attribute_id so in the array
      // i can't distinguish which is which unless I put another key value box which is either 1 or 2 depending on which box the user selects and changes
      // skuAttribute.map(({box,...rest}) => rest)
      skuAttribute: skuAttribute,
      skuCoreAttribute: skuCoreAttribute,
      customer_id: customerId,
      manufacturer_id: manufacturerId,
      prototype_id: prototypeId,
      material_group_id:
        ltMaterialGroup && ltMaterialGroup.id ? ltMaterialGroup.id : null,
      foundation_group_id:
        ltFoundationGroup && ltFoundationGroup.id ? ltFoundationGroup.id : null,
      skuTopPanelAttribute: skuTopPanelAttribute,
      skuGussetPanelAttribute: skuGussetPanelAttribute,
      skuSidePanelAttribute: skuSidePanelAttribute,
      skuBottomPanelAttribute: skuBottomPanelAttribute,
      top_panel_id: topPanel,
      gussett_panel_id: gussetPanel,
      side_panel_id: sidePanel,
      bottom_panel_id: bottomPanel,
      core_group_attribute_id: newCoreGroupAttributeId,
      skuTape: skuTape != undefined ? skuTape : null,
      plastics: [this.state.plastic1Part, this.state.plastic2Part],
      zipperParts: zipperParts,
    };
    //this is for editing skus that exist
    const masterEditSkuObj = {
      // id: id != -1 ? id : null,
      active: active,
      brand_id: brandId,
      family_id: familyId,
      batch_id: batchId,
      //this is fix for now cause boxes both have the same attribute_id so in the array
      // i can't distinguish which is which unless I put another key value box which is either 1 or 2 depending on which box the user selects and changes
      // skuAttribute.map(({box,...rest}) => rest)
      customer_id: customerId,
      manufacturer_id: manufacturerId,
      prototype_id: prototypeId,
      material_group_id:
        ltMaterialGroup && ltMaterialGroup.id ? ltMaterialGroup.id : null,
      foundation_group_id:
        ltFoundationGroup && ltFoundationGroup.id ? ltFoundationGroup.id : null,
      //only give the ids that are null - other attr objs need id from the skuObj
      //we should just be able to grab the skuAttributes array on state.. then just change the id on the obj if it exists
      //and change sku_id on the obj if it exists
      skuTopPanelAttribute: await skuTopPanelAttribute.filter(
        (attr) => attr.id == null
      ),
      skuGussetPanelAttribute: await skuGussetPanelAttribute.filter(
        (attr) => attr.id == null
      ),
      skuSidePanelAttribute: await skuSidePanelAttribute.filter(
        (attr) => attr.id == null
      ),
      skuBottomPanelAttribute: await skuBottomPanelAttribute.filter(
        (attr) => attr.id == null
      ),
      skuAttribute: await skuAttribute.filter((attr) => attr.id == null),
      skuCoreAttribute: await skuCoreAttribute.filter(
        (attr) => attr.id == null
      ),
      top_panel_id: topPanel,
      gussett_panel_id: gussetPanel,
      side_panel_id: sidePanel,
      bottom_panel_id: bottomPanel,
      core_group_attribute_id: newCoreGroupAttributeId,
      skuTape: await skuTape.filter((attr) => attr.id == null),
    };

    let totalHeight = 0;
    let getHeight = async () => {
      if (skuTopPanelAttribute) {
        let obj = await skuTopPanelAttribute.find(
          (i) => i.fabric_attribute_id === 1
        );
        obj !== undefined ? (totalHeight += Number(obj.attribute_value)) : '';
      }
      if (skuGussetPanelAttribute) {
        let obj = await skuGussetPanelAttribute.find(
          (i) => i.fabric_attribute_id === 1
        );
        obj !== undefined ? (totalHeight += Number(obj.attribute_value)) : '';
      }
      if (skuSidePanelAttribute) {
        let obj = await skuSidePanelAttribute.find(
          (i) => i.fabric_attribute_id === 1
        );
        obj !== undefined ? (totalHeight += Number(obj.attribute_value)) : '';
      }
      if (skuBottomPanelAttribute) {
        let obj = await skuBottomPanelAttribute.find(
          (i) => i.fabric_attribute_id === 1
        );
        obj !== undefined ? (totalHeight += Number(obj.attribute_value)) : '';
      }
    };
    await getHeight();
    let setHeight = async () => {
      if (totalHeight < 10 && totalHeight > 1) {
        totalHeight = '0' + Math.floor(totalHeight);
      } else if (totalHeight === 0) {
        totalHeight = '';
      } else {
        totalHeight = Math.floor(totalHeight);
      }
    };
    await setHeight();

    let skuObjArr;
    //editing single sku
    if (id != -1) {
      skuObjArr = [
        {
          ...masterSkuObj,
          sku: sku.toUpperCase(),
          size_id: sizeId,
          core_id: coreId,
        },
      ];
    }
    //making new batch of skus
    if (id === -1 && skuGroup == null) {
      skuObjArr = this.state.createSkuList.map((obj) => {
        //...obj includes 'size_id','core_id','sizeName','sizeAlias','firmnessName','firmnessAlias'
        let newSku =
          sku.toUpperCase().trim() + obj.sizeAlias + obj.firmnessAlias;
        return {
          ...obj,
          ...masterSkuObj,
          totalHeight: totalHeight,
          sku: newSku.trim(),
        };
      });
    }
    //editing already existing batch of skus
    else if (id === -1 && skuGroup != undefined) {
      skuObjArr = await Promise.all(
        this.state.createSkuList.map(async (obj) => {
          //need to check if the skuGroup we are viewing has any new sku additions or deletions
          //THINK ABOUT THE CORE IDS THAT ARE THE SAME ACROSS THE BOARD

          //THIS IS WHERE WE NEED TO WRITE LOGIC TO FIND IF SKU ALREADY EXISTS OR NOT IN A BETTER WAY
          //BECAUSE IF WE DO IT BASED ON CORE_ID THAT WON'T WORK SINCE WE HAVE TO HAVE THE ABILITY TO CHANGE THE CORE_ID ON AN EXISTING SKU
          let existsAlreadyButNewCore = await skuGroup.find(
            (sku) => obj.size_id === sku.size.id
          );

          //sku object exists already and core_id was NOT changed
          let existsAlready = await skuGroup.find(
            (sku) => sku.core.id === obj.core_id && obj.size_id === sku.size.id
          );
          let newSkuButSameCoreTypeAsExistingFamily1 =
            skuGroup[0].core.core_type_id;
          let newSkuButSameCoreTypeAsExistingFamily2 =
            skuGroup[1] === undefined
              ? skuGroup[0].core.core_type_id
              : skuGroup[1].core.core_type_id;
          if (existsAlready != undefined) {
            //must add size_id core_id sizeName and firmnessName here bc it would have come from createSkuList
            //but if sku exists we pull its information from the existing obj in the skuGroup arr
            //the masterEditSkuObj has all the new attributes in it but the obj
            let newSku = existsAlready.sku
              ? existsAlready.sku
              : obj.sizeAlias + obj.firmnessAlias;
            return {
              ...obj,
              ...masterEditSkuObj,
              skuTopPanelAttribute: existsAlready.skuTopPanelAttribute,
              skuSidePanelAttribute: existsAlready.skuSidePanelAttribute,
              skuBottomPanelAttribute: existsAlready.skuBottomPanelAttribute,
              skuGussetPanelAttribute: existsAlready.skuGussetPanelAttribute,
              skuTape: existsAlready.skuTape,
              skuAttribute: existsAlready.skuAttribute,
              skuCoreAttribute: existsAlready.skuCoreAttribute,
              size_id: existsAlready.size.id,
              core_id: existsAlready.core.id,
              sizeName: existsAlready.size.size,
              firmnessName: existsAlready.core.type.firmness.firmness,
              sku: newSku.trim(),
              id: existsAlready.id,
              upc: existsAlready.upc,
            };
          } else if (existsAlreadyButNewCore) {
            // console.log('EXISTS BUT NEEDS NEW CORE')
            let newSku = existsAlreadyButNewCore.sku
              ? existsAlreadyButNewCore.sku
              : obj.sizeAlias + obj.firmnessAlias;
            return {
              ...obj,
              ...masterSkuObj,
              skuTopPanelAttribute:
                existsAlreadyButNewCore.skuTopPanelAttribute,
              skuSidePanelAttribute:
                existsAlreadyButNewCore.skuSidePanelAttribute,
              skuBottomPanelAttribute:
                existsAlreadyButNewCore.skuBottomPanelAttribute,
              skuGussetPanelAttribute:
                existsAlreadyButNewCore.skuGussetPanelAttribute,
              skuTape: existsAlreadyButNewCore.skuTape,
              skuAttribute: existsAlreadyButNewCore.skuAttribute,
              skuCoreAttribute: existsAlreadyButNewCore.skuCoreAttribute,
              size_id: existsAlreadyButNewCore.size.id,
              sku: newSku.trim(),
              id: existsAlreadyButNewCore.id,
              upc: existsAlreadyButNewCore.upc,
            };
          } else if (
            existsAlready == undefined &&
            newSkuButSameCoreTypeAsExistingFamily1 ===
              newSkuButSameCoreTypeAsExistingFamily2
          ) {
            // console.log('just adding a new sku to the existing group where parent core did not change')
            return {
              ...obj,
              ...masterSkuObj,
              sku: obj.sizeAlias + obj.firmnessAlias,
            };
          }
        })
      );
    }
    //assign Attributes plastics, zipper
    let assignBoxes = async () => {
      skuObjArr = await Promise.all(
        skuObjArr.map(async (s) => {
          //break up the boxes and note then put back together because note is getting duplicates if it is changed..
          //there should only be 1 note attribute obj in the skuAttribute array
          let noteAttr = await s.skuAttribute.find(
            (attr) => attr.attribute_id == 2
          );
          if (noteAttr !== undefined) {
            noteAttr = {
              id: noteAttr.id,
              sku_id: noteAttr.sku_id,
              attribute_id: noteAttr.attribute_id,
              attribute_value: noteAttr.attribute_value.includes(s.sizeName)
                ? noteAttr.attribute_value
                : s.firmnessName == 'One Firmness'
                ? `${noteAttr.attribute_value}- ${s.sizeName}`
                : `${noteAttr.attribute_value}- ${s.sizeName} ${s.firmnessName}`,
            };
          }
          //    now we are going to assign the boxes to the skuAttribute array
          let boxAttr = await s.skuAttribute.filter(
            (attr) => attr.attribute_id == 1
          );
          //loop through the boxObjs array on state, see if the size on the sku matches the size on the boxObjs obj
          await this.state.boxObjs.forEach((boxObj) => {
            //if the size on the skuGroup sku matches the size on the boxObjs obj
            if (s.sizeName === boxObj.size) {
              //just assign the each boxAttr object to the new boxObj
              if (
                boxObj.attr_obj1 != '' &&
                boxObj.attr_obj1 != undefined &&
                boxObj.attr_obj1.attribute_value != undefined
              ) {
                boxAttr[0] = {
                  id: boxAttr[0] != undefined ? boxAttr[0].id : null,
                  sku_id: null,
                  attribute_id: 1,
                  attribute_value: boxObj.attr_obj1.attribute_value,
                };
              }
              if (
                boxObj.attr_obj2 != '' &&
                boxObj.attr_obj2 != undefined &&
                boxObj.attr_obj2.attribute_value != undefined
              ) {
                boxAttr[1] = {
                  id: boxAttr[1] != undefined ? boxAttr[1].id : null,
                  sku_id: null,
                  attribute_id: 1,
                  attribute_value: boxObj.attr_obj2.attribute_value,
                };
              }
            }
          });
          //now we are going to assign the ZC to the skuAttribute array
          //get the zcAttr that is currently on the sku object being iterated over skuAttribute array
          //zcAttr will be undefined if the sku did not have an zc sock on it already
          let zcAttr = await s.skuAttribute.find(
            (attr) => attr.attribute_id == 3
          );
          //now loop through the frObjs and find the frObj that matches the sku we are iterating over
          await this.state.zcObjs.forEach((zcObj) => {
            //only create the zcAttr is the zipper cover is true
            if (
              this.state.zipperCoverCheck == true &&
              zcObj.attribute_value != ''
            ) {
              if (s.sizeName === zcObj.size) {
                zcAttr = {
                  //mimic this below but the attr value needs to be the valueid from plasticPart
                  id: zcAttr != undefined ? zcAttr.id : null,
                  sku_id: zcAttr != undefined ? zcAttr.sku_id : null,
                  attribute_id: 3,
                  attribute_value: zcObj.attribute_value,
                };
              }
            } else if (this.state.zipperCoverCheck == false) {
              zcAttr = undefined;
            }
          });

          //Assinging plastic attribute array
          let plasAttr = [this.state.plastic1Part, this.state.plastic2Part];

          //Assinging zipper attribute array parts
          let attr = [];
          let filteredSizes = [45, 46, 3, 4, 5, 6, 30];
          let filteredChainSlide = [27, 28, 30, 38, 43, 8, 11, 29, 40, 7, 39];
          if (this.state.zipperName === 2) {
            //Black zipper
            const { parts, sizes } = this.props;

            let zipperComponent1 = await parts.filter((zip) => {
              return [11425].indexOf(zip.id) != -1;
            });

            let zipperComponent2 = await parts.filter((zip) => {
              return [11427].indexOf(zip.id) != -1;
            });
            let newChainSlide1 = await parts.filter((chain) => {
              return [11371, 11369].indexOf(chain.id) != -1;
            });

            let newChainSlide2 = await parts.filter((chain) => {
              return [11372, 11370].indexOf(chain.id) != -1;
            });

            if (filteredSizes.indexOf(s.size_id) != -1) {
              zipperComponent1.find((item) =>
                attr.push({
                  id: null,
                  sku_id: null,
                  attribute_id: 4,
                  attribute_value: item.id,
                })
              );
            } else {
              zipperComponent2.find((item) =>
                attr.push({
                  id: null,
                  sku_id: null,
                  attribute_id: 4,
                  attribute_value: item.id,
                })
              );
            }

            if (filteredChainSlide.indexOf(s.size_id) != -1) {
              newChainSlide1.forEach((item) =>
                attr.push({
                  id: null,
                  sku_id: null,
                  attribute_id: 4,
                  attribute_value: item.id,
                })
              );
            } else {
              newChainSlide2.forEach((item) =>
                attr.push({
                  id: null,
                  sku_id: null,
                  attribute_id: 4,
                  attribute_value: item.id,
                })
              );
            }
          } else if (this.state.zipperName === 1) {
            const { parts, sizes } = this.props;

            let zipperComponent1 = await parts.filter((zip) => {
              return [378].indexOf(zip.id) != -1;
            });

            let zipperComponent2 = await parts.filter((zip) => {
              return [737].indexOf(zip.id) != -1;
            });

            if (filteredSizes.indexOf(s.size_id) != -1) {
              zipperComponent1.find((item) =>
                attr.push({
                  id: null,
                  sku_id: null,
                  attribute_id: 4,
                  attribute_value: item.id,
                })
              );
            } else {
              zipperComponent2.find((item) =>
                attr.push({
                  id: null,
                  sku_id: null,
                  attribute_id: 4,
                  attribute_value: item.id,
                })
              );
            }

            let newChainSlide1 = await parts.filter((chain) => {
              return [11371, 11369].indexOf(chain.id) != -1;
            });

            let newChainSlide2 = await parts.filter((chain) => {
              return [11372, 11370].indexOf(chain.id) != -1;
            });

            if (filteredChainSlide.indexOf(s.size_id) != -1) {
              newChainSlide1.forEach((item) =>
                attr.push({
                  id: null,
                  sku_id: null,
                  attribute_id: 4,
                  attribute_value: item.id,
                })
              );
            } else {
              newChainSlide2.forEach((item) =>
                attr.push({
                  id: null,
                  sku_id: null,
                  attribute_id: 4,
                  attribute_value: item.id,
                })
              );
            }
          }
          return {
            ...s,
            skuAttribute:
              noteAttr != undefined && zcAttr != undefined
                ? [noteAttr, ...boxAttr, zcAttr, ...plasAttr, ...attr]
                : zcAttr != undefined
                ? [...boxAttr, zcAttr, ...plasAttr, ...attr]
                : attr != undefined
                ? [...boxAttr, ...plasAttr, ...attr]
                : [...boxAttr, ...plasAttr],
          };
        })
      );
    };

    let assignFR = async () => {
      skuObjArr = await Promise.all(
        skuObjArr.map(async (s) => {
          //tufting is entered in the backend right now so we do not want to remove or edit this object
          let tuftingAttr = await s.skuCoreAttribute.filter(
            (attr) => attr.core_attribute_id == 2
          );
          //remove the part key value pair on the object bc it does not get sent into db
          delete tuftingAttr['part'];
          //now we are going to assign the FR to the skuCoreAttribute array
          //get the frAttr that is currently on the sku object being iterated over skuCoreAttribute array
          //frAttr will be undefined if the sku did not have an fr sock on it already
          let frAttr = await s.skuCoreAttribute.find(
            (attr) => attr.core_attribute_id == 1
          );
          //now loop through the frObjs and find the frObj that matches the sku we are iterating over
          await this.state.frObjs.forEach((frObj) => {
            //only create the frAttr is the firesock is true
            if (this.state.fireSockCheck == true) {
              if (s.sizeName === frObj.size) {
                frAttr = {
                  id: frAttr != undefined ? frAttr.id : null,
                  sku_id: frAttr != undefined ? frAttr.sku_id : null,
                  core_attribute_id: 1,
                  attribute_value: frObj.attribute_value,
                };
              }
            } else if (this.state.fireSockCheck == false) {
              frAttr = undefined;
            }
          });
          return {
            ...s,
            skuCoreAttribute:
              frAttr != undefined && tuftingAttr.length >= 1
                ? [frAttr, ...tuftingAttr]
                : frAttr != undefined
                ? [frAttr]
                : tuftingAttr.length >= 1
                ? [...tuftingAttr]
                : [],
          };
        })
      );
    };

    // make sku_ids in the attributes arrays same as individual sku they are associated with
    let assignSkuId = async () => {
      await assignBoxes();
      await assignFR();

      await skuObjArr.map((s) => {
        s.skuTopPanelAttribute = s.skuTopPanelAttribute.map((attr) =>
          attr ? { ...attr, sku_id: s.id } : ''
        );
        s.skuSidePanelAttribute = s.skuSidePanelAttribute.map((attr) =>
          attr ? { ...attr, sku_id: s.id } : ''
        );
        s.skuGussetPanelAttribute = s.skuGussetPanelAttribute.map((attr) =>
          attr ? { ...attr, sku_id: s.id } : ''
        );
        s.skuBottomPanelAttribute = s.skuBottomPanelAttribute.map((attr) =>
          attr ? { ...attr, sku_id: s.id } : ''
        );
        s.skuAttribute = s.skuAttribute.map((attr) =>
          attr ? { ...attr, sku_id: s.id } : ''
        );
        s.skuCoreAttribute = s.skuCoreAttribute.map((attr) =>
          attr ? { ...attr, sku_id: s.id } : ''
        );
        s.skuTape = s.skuTape.map((attr) =>
          attr ? { ...attr, sku_id: s.id } : ''
        );
      });
    };
    if (skuGroup != undefined) {
      await assignSkuId();
    }

    //need to map over any quilt object in panel attribute arrays and remove certain key/value pairs
    await skuObjArr.map((s) => {
      s.skuTopPanelAttribute = s.skuTopPanelAttribute.map((attr) =>
        attr.fabric_attribute_id == 4
          ? {
              id: attr.id,
              sku_id: attr.sku_id,
              fabric_attribute_id: attr.fabric_attribute_id,
              attribute_value: attr.attribute_value,
            }
          : attr
      );
      s.skuSidePanelAttribute = s.skuSidePanelAttribute.map((attr) =>
        attr.fabric_attribute_id == 4
          ? {
              id: attr.id,
              sku_id: attr.sku_id,
              fabric_attribute_id: attr.fabric_attribute_id,
              attribute_value: attr.attribute_value,
            }
          : attr
      );
      s.skuGussetPanelAttribute = s.skuGussetPanelAttribute.map((attr) =>
        attr.fabric_attribute_id == 4
          ? {
              id: attr.id,
              sku_id: attr.sku_id,
              fabric_attribute_id: attr.fabric_attribute_id,
              attribute_value: attr.attribute_value,
            }
          : attr
      );
      s.skuBottomPanelAttribute = s.skuBottomPanelAttribute.map((attr) =>
        attr.fabric_attribute_id == 4
          ? {
              id: attr.id,
              sku_id: attr.sku_id,
              fabric_attribute_id: attr.fabric_attribute_id,
              attribute_value: attr.attribute_value,
            }
          : attr
      );
    });
    await assignBoxes();
    await assignFR();

    //map over completely new skus getting ready to create
    //see if their name already exists in all skus list
    if (skuGroup == undefined && id === -1) {
      skuObjArr = await skuObjArr.map((s) => {
        let nameExists = allSkus.find((sku) => sku.sku === s.sku);
        if (nameExists != undefined) {
          return {
            ...s,
            nameExists: true,
          };
        }
        return {
          ...s,
          nameExists: false,
        };
      });
    }

    await this.setState({ masterSkuArr: skuObjArr }, () =>
      this.handleInputOverlay()
    );

    //if id does not equal -1 then we are editing an existing sku and do not need to do the render the Input Overlay
    if (id !== -1) {
      await dispatch(saveSkuList(skuObjArr));
      if (!isError) {
        this.props.history.push('/skus');
      }
    }
  };

  determineCoreGroupAttributeValueBySize = (sizeId) => {
    if (this.state.specialtyMattress) {
      return 2;
    } else if (
      sizeId == 1 ||
      sizeId == 2 ||
      sizeId == 3 ||
      sizeId == 5 ||
      sizeId == 6 ||
      sizeId == 7 ||
      sizeId == 8
    ) {
      return 1;
    }

    return 3;
  };

  handleSpecialtyInfoPopoverOpen = (e) => {
    this.setState({ specialtyInfoPopoverAnchor: e.currentTarget });
  };

  handleSpecialtyInfoPopoverClose = (e) => {
    this.setState({ specialtyInfoPopoverAnchor: null });
  };

  //when one of the values of the autocomplete is empty (such as on load)
  //the autocomplete will no longer return a warning
  sanitizeAutocompleteOptionSelected = (option, value) => {
    if (!option) return true;
    if (!value) return true;
    return option === value;
  };

  //   handleSetTopPanel = async () => { // PANEL UPDATE: Commented out until bugs can be fixed.
  //     const { dispatch, isTopPanel } = this.props;
  //     await dispatch(setTopPanel());
  //   };
  //
  //   handleSetGussetPanel = async () => {
  //     const { dispatch } = this.props;
  //     await dispatch(setGussetPanel());
  //   };
  //
  //   handleSetSidePanel = async () => {
  //     const { dispatch } = this.props;
  //     await dispatch(setSidePanel());
  //   };
  //
  //   handleSetBottomPanel = async () => {
  //     const { dispatch } = this.props;
  //     await dispatch(setBottomPanel());
  //   };

  renderPlasticPartsNeededSelect = () => {
    const renderPlasticPartsOptions = () => {
      return this.props.parts
        .filter((plas) => plas.category == 'Film')
        .map((plasticPart) => (
          <MenuItem key={plasticPart.id} value={plasticPart}>
            {plasticPart.internal_desc}
          </MenuItem>
        ));
    };
    if (this.state.plasticName === 'Roll Pack') {
      return (
        <>
          <FormControl className={'sku-form-select'}>
            <InputLabel>Plastic 1</InputLabel>
            <Select
              className={'sku-select'}
              name={'plastic1'}
              value={this.state.plastic1PartDisplay}
              onChange={(e) => this.handlePlastic1PartChange(e)}
            >
              {renderPlasticPartsOptions()}
            </Select>
          </FormControl>
          <FormControl className={'sku-form-select'}>
            <InputLabel>Plastic 2</InputLabel>
            <Select
              className={'sku-select'}
              name={'plastic2'}
              value={this.state.plastic2PartDisplay}
              onChange={(e) => this.handlePlastic2PartChange(e)}
            >
              {renderPlasticPartsOptions()}
            </Select>
          </FormControl>
        </>
      );
    } else if (this.state.plasticName === 'Flat Pack') {
      return (
        <FormControl className={'sku-form-select'}>
          <InputLabel>Plastic 1</InputLabel>
          <Select
            className={'sku-select'}
            name={'plastic1'}
            value={this.state.plastic1PartDisplay}
            onChange={(e) => this.handlePlastic1PartChange(e)}
          >
            {renderPlasticPartsOptions()}
          </Select>
        </FormControl>
      );
    }
  };

  render() {
    const {
      isLoading,
      isError,
      saveSuccess,
      id,
      activeSkus,
      active,
      family,
      firmness,
      brand,
      sku,
      core,
      familyName,
      firmnessName,
      brandName,
      coreTypes,
      parentCoreName,
      parentCore,
      sizeName,
      families,
      firmnesses,
      brands,
      //   batches,
      batchId,
      cores,
      parentCores,
      sizes,
      customerName,
      manufacturerName,
      prototypeName,
      ltFoundationGroup,
      ltMaterialGroup,
      customers,
      manufacturers,
      prototypes,
      prototype,
      materialGroupName,
      foundationGroupName,
      materialGroups,
      foundationGroups,
      topPanel,
      topPanelLayers,
      gussetPanel,
      gussetPanelLayers,
      sidePanel,
      sidePanelLayers,
      bottomPanel,
      bottomPanelLayers,
      skuTopPanelAttribute,
      skuGussetPanelAttribute,
      skuSidePanelAttribute,
      skuBottomPanelAttribute,
      fabricQuiltPatterns,
      parts,
      boxNames,
      upc,
      skuTape,
      skuNote,
      skuAttribute,
      skuGroup,
      allSkus,
      topPanelTicks,
      sidePanelTicks,
      bottomPanelTicks,
      gussetPanelTicks,
      plastics,
      zipperParts,
      draft,
      openModal,
      identifier,
      isTopPanel,
      isGussetPanel,
      isSidePanel,
      isBottomPanel,
    } = this.props;

    let activeButtonColor = active === 1 ? '#009900' : '#cc2900';

    //get the active skus to select "same panel as" list filter to find skus where panel is not null
    //then map over to only return the sku name where that panel exists/ is not null
    //then combine the arr of sku names where the panel exists and the arr of tick part numbers for panels
    let topPanelSkus = activeSkus.filter((sku) => sku.topPanel != null);
    topPanelSkus = topPanelSkus.map((s) => s.sku);
    let topPanelOptions = [...topPanelTicks, ...topPanelSkus];
    let gussetPanelSkus = activeSkus.filter((sku) => sku.gussetPanel != null);
    gussetPanelSkus = gussetPanelSkus.map((s) => s.sku);
    let gussetPanelOptions = [...gussetPanelTicks, ...gussetPanelSkus];
    let sidePanelSkus = activeSkus.filter((sku) => sku.sidePanel != null);
    sidePanelSkus = sidePanelSkus.map((s) => s.sku);
    let sidePanelOptions = [...sidePanelTicks, ...sidePanelSkus];
    let bottomPanelSkus = activeSkus.filter((sku) => sku.bottomPanel != null);
    bottomPanelSkus = bottomPanelSkus.map((s) => s.sku);
    let bottomPanelOptions = [...bottomPanelTicks, ...bottomPanelSkus];
    let boxes = parts.filter((p) => p.category === 'Box');
    let tapeObjs = parts.filter((p) => (p.category === 'Tape' ? p.num : ''));
    let tapes = tapeObjs.map((t) => t.num + ' - ' + t.internal_desc);
    let familyIds = families.map((f) => f.id);
    //get the family_ids of all skus
    let assignedFamIds = allSkus.map((s) => s.family_id);
    //get the familyIds that are not being used, check against all skus
    familyIds = familyIds.filter((f) => assignedFamIds.indexOf(f) === -1);
    //get the family objects that are not being used based on the family ids not being used
    let unassignedFamilies = families.filter(
      (f) => familyIds.indexOf(f.id) !== -1
    );
    let quiltPatterns = fabricQuiltPatterns.map(
      (qp) => qp.fabric_quilt_pattern
    );
    let foundationGroupNames = foundationGroups.map((f) => f.group_name);

    let filterFireSockCategories = ['FR'];
    let filteredFireSockParts = parts.filter(
      (part) => filterFireSockCategories.indexOf(part.category) > -1
    );
    let plasticFamily = parts.filter((p) => p.category === "Film");
    let filterZipperCoverCategories = ["Zipper Cover"];
    let filteredZipperCoverParts = parts.filter(
      (part) => filterZipperCoverCategories.indexOf(part.category) > -1
    );
    console.log(plasticFamily);

    return (
      <div>
        <div
          className={
            this.state.inputOverlay
              ? 'blur baseContainer sku-form'
              : 'baseContainer sku-form'
          }
        >
          <ModalSkuForm
            open={openModal}
            value={openModal}
            success={this.state.SuccessModal}
          />
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={this.state.open}
            className='success'
            onClose={this.handleClose}
            autoHideDuration={2000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id='message-id'>Sku saved</span>}
          />
          <div className={'sku-col'} style={{ marginRight: 40 }}>
            <h1>{brandName}</h1>
            {id === -1 && skuGroup != undefined ? (
              <Button
                name={'active'}
                variant='contained'
                style={{ backgroundColor: activeButtonColor, color: 'white' }}
                onClick={this.handleActiveChange}
              >
                {active === 1 ? 'Active' : 'Inactive'}
              </Button>
            ) : (
              ''
            )}
            <div className={'sku-info-label'}>
              <h3>General Sku Info</h3>
            </div>
            <div className={'sku-info'}>
              <div>
                {id === -1 && skuGroup != undefined ? (
                  ''
                ) : (
                  <FormControl className={'sku-form-control'}>
                    <TextField
                      label='Sku'
                      name='sku'
                      margin='normal'
                      variant='outlined'
                      value={sku != null ? sku.toUpperCase().trim() : ''}
                      onChange={this.handleChange}
                    />
                  </FormControl>
                )}
                <FormControl className={'sku-form-select'}>
                  <InputLabel>Brand</InputLabel>
                  <Select
                    className={'sku-select'}
                    name={'brandName'}
                    placeholder={'Brand'}
                    value={brandName}
                    key={brandName}
                    input={
                      <Input name='brandName' id='brandName-placeholder' />
                    }
                    onChange={this.handleChange}
                  >
                    {brands.map((option) => (
                      <MenuItem key={option.id} value={option.brand}>
                        {option.brand}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={'sku-form-select'}>
                  <InputLabel>Family</InputLabel>
                  <Select
                    className={'sku-select'}
                    name={'familyName'}
                    placeholder={'Family'}
                    value={familyName}
                    key={familyName}
                    input={
                      <Input name='familyName' id='familyName-placeholder' />
                    }
                    onChange={this.handleFamilyChange}
                  >
                    {(id === -1 && skuGroup != undefined) || id != -1 ? (
                      <MenuItem key={familyName} value={familyName}>
                        {familyName}
                      </MenuItem>
                    ) : (
                      unassignedFamilies.map((option) => (
                        <MenuItem key={option.id} value={option.family}>
                          {option.family}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
                {id === -1 ? (
                  <FormControlLabel
                    className={'sku-switch'}
                    label='UPC Required'
                    control={
                      <Switch
                        checked={this.state.upcRequired}
                        onChange={(e) => {
                          this.setState({
                            upcRequired: !this.state.upcRequired,
                          });
                        }}
                      />
                    }
                  />
                ) : (
                  ''
                )}
                {id !== -1 && upc !== '' && id !== undefined ? (
                  <FormControl
                    style={{
                      fontSize: '18px',
                      marginTop: '30px',
                      marginLeft: '30px',
                    }}
                  >
                    UPC {upc}
                  </FormControl>
                ) : (
                  ''
                )}
                <FormControl
                  className={'sku-form-control'}
                  style={{ width: 590, marginBottom: '20px' }}
                >
                  {this.state.noteError ? (
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                      {' '}
                      {this.state.noteError}
                    </span>
                  ) : (
                    ''
                  )}
                  <TextField
                    label='Sku Description'
                    name='SkuNote'
                    margin='normal'
                    variant='outlined'
                    className='name-field'
                    value={skuNote || ''}
                    inputProps={{ maxLength: 232 }}
                    onChange={(e) =>
                      this.handleNoteChange(e, {
                        id: null,
                        sku_id: null,
                        attribute_id: 2,
                      })
                    }
                  />
                </FormControl>
                {/* <FormControl
                  className="sku-form-control"
                  style={{ width: 590, marginBottom: 20 }}
                >
                  <InputLabel id="batch-select-label">
                    Schedule Batch
                  </InputLabel>
                  <Select
                    labelId="batch-select-label"
                    name="batch"
                    id="batch-select"
                    defaultValue=""
                    value={batchId}
                    key={batchId}
                    onChange={this.handleUpdateBatch}
                  >
                    {batches.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.batch_number}&nbsp;{option.batch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </div>
            </div>
            <div className={'sku-lawtag-label'}>
              <h3>Law Tag Data</h3>
            </div>
            <div className={'sku-lawtag'}>
              <FormControl className={'sku-form-select'}>
                <InputLabel>Customer</InputLabel>
                <Select
                  className={'sku-select'}
                  name={'customerName'}
                  placeholder={'Customer'}
                  value={customerName}
                  key={customerName}
                  input={
                    <Input name='customerName' id='customerName-placeholder' />
                  }
                  onChange={this.handleChange}
                >
                  {customers.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={'sku-form-select'}>
                <InputLabel>Manufacturer</InputLabel>
                <Select
                  className={'sku-select'}
                  name={'manufacturerName'}
                  placeholder={'Manufacturer'}
                  value={manufacturerName}
                  key={manufacturerName}
                  input={
                    <Input
                      name='manufacturerName'
                      id='manufacturerName-placeholder'
                    />
                  }
                  onChange={this.handleChange}
                >
                  {manufacturers.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={'sku-form-select'}>
                <InputLabel>Prototype</InputLabel>
                <Select
                  className={'sku-select'}
                  name={'prototypeName'}
                  placeholder={'Prototype'}
                  value={prototypeName}
                  key={prototypeName}
                  input={
                    <Input
                      name='prototypeName'
                      id='prototypeName-placeholder'
                    />
                  }
                  onChange={this.handlePrototypeChange}
                >
                  {prototypes.map((option) => (
                    <MenuItem key={option.id} value={option.number}>
                      {option.number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={'sku-table'}>
                <div>
                  <div className={'sku-flex-row'}>
                    <h4>Law Tag Material</h4>
                    {materialGroups && (
                      <FormControl className={'sku-form-select-inline'}>
                        <InputLabel>Material Group</InputLabel>
                        <Select
                          className={'sku-select'}
                          name={'materialGroupName'}
                          placeholder={'Material Group'}
                          value={materialGroupName}
                          key={materialGroupName}
                          input={
                            <Input
                              name='materialGroupName'
                              id='materialGroupName-placeholder'
                            />
                          }
                          onChange={this.handleMaterialGroupChange}
                        >
                          {materialGroups.map((option) => (
                            <MenuItem key={option.id} value={option.group_name}>
                              {option.group_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            padding: '10px',
                            background: 'rgb(30, 66, 113)',
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                        >
                          Material
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            background: 'rgb(30, 66, 113)',
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                        >
                          Blend
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {ltMaterialGroup &&
                      ltMaterialGroup.materials &&
                      ltMaterialGroup.materials.map((m) => {
                        return (
                          <TableBody key={m.name}>
                            <TableRow className={'no-hover'}>
                              <TableCell style={{ padding: '10px' }}>
                                {m.name}
                              </TableCell>
                              <TableCell style={{ padding: '10px' }}>
                                {m.blend}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                  </Table>
                </div>
              </div>
              <div className={'sku-table'}>
                <div>
                  <div className={'sku-flex-row'}>
                    <h4>Law Tag Foundations</h4>
                    {foundationGroups && (
                      <Autocomplete
                        id='autocomplete-foundation-group'
                        value={foundationGroupName}
                        inputValue={foundationGroupName}
                        getOptionSelected={(option, value) =>
                          this.sanitizeAutocompleteOptionSelected(option, value)
                        }
                        onInputChange={(e, value) =>
                          this.handleFoundationGroupChange(value)
                        }
                        style={{
                          width: 100,
                          marginLeft: 215,
                          marginBottom: 10,
                        }}
                        options={foundationGroupNames}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Foundation Group'
                            style={{ width: 100 }}
                            className={'sku-auto-complete'}
                          />
                        )}
                      />
                    )}
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            padding: '10px',
                            background: 'rgb(30, 66, 113)',
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                        >
                          Foundation Sku
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '10px',
                            background: 'rgb(30, 66, 113)',
                            color: 'white',
                            fontWeight: 'bold',
                          }}
                        >
                          Description
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {ltFoundationGroup &&
                      ltFoundationGroup.foundations &&
                      ltFoundationGroup.foundations.map((f) => {
                        return (
                          <TableBody key={f.sku}>
                            <TableRow className={'no-hover'}>
                              <TableCell style={{ padding: '10px' }}>
                                {f.sku}
                              </TableCell>
                              <TableCell style={{ padding: '10px' }}>
                                {f.description}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                  </Table>
                </div>
              </div>
              {/*box area*/}
              <div style={{ marginLeft: 20, marginTop: 40 }}>
                <div style={{ alignSelf: 'center' }}>
                  <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                    Packing Methods
                  </Typography>
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <FormControl variant='standard' className={'sku-form-select'}>
                    <InputLabel id='demo-simple-select-standard-label'>
                      Plastic
                    </InputLabel>
                    <InputLabel id="testing input">Plastic</InputLabel>
                    <Select
                      className={'sku-select'}
                      name={'plastics'}
                      value={this.state.plasticName || ''}
                      onChange={(e) => this.handlePlasticChange(e)}
                    >
                      <MenuItem value={'Other'}>Other</MenuItem>
                      <MenuItem value={'Roll Pack'}>Roll Pack</MenuItem>
                      <MenuItem value={'Flat Pack'}>Flat Pack</MenuItem>
                    </Select>
                  </FormControl>
                  <div style={{ display: 'block', marginBottom: 20 }}>
                    {this.renderPlasticPartsNeededSelect()}
                  </div>
                </div>

                {this.state.boxNames
                  ? this.state.boxNames.map((box) => {
                      return (
                        <div key={box.size}>
                          <div
                            style={{
                              fontSize: 20,
                              fontWeight: 'bold',
                              display: 'block',
                              marginTop: 20,
                            }}
                          >
                            {box.size}
                          </div>
                          <div style={{ display: 'block', marginBottom: 20 }}>
                            <FormControl className={'sku-form-select'}>
                              <InputLabel>Box</InputLabel>
                              <Select
                                className={'sku-select'}
                                name={'boxName'}
                                placeholder={'Box'}
                                value={box.boxName1 || ''}
                                key={box.boxName1 || ''}
                                // input={<Input name="boxName" />}
                                onChange={(e) =>
                                  this.handleBoxChange(
                                    e,
                                    {
                                      id: null,
                                      sku_id: null,
                                      attribute_id: 1,
                                      boxName: 1,
                                    },
                                    box.size
                                  )
                                }
                              >
                                {boxes.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.internal_desc}
                                  >
                                    {option.internal_desc}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl className={'sku-form-select'}>
                              <InputLabel>Box (optional)</InputLabel>
                              <Select
                                className={'sku-select'}
                                name={'boxName'}
                                placeholder={'Box'}
                                value={box.boxName2 != '' ? box.boxName2 : ''}
                                key={box.boxName2 != '' ? box.boxName2 : ''}
                                // input={<Input name="boxName" id="boxName-placeholder"/>}
                                onChange={(e) =>
                                  this.handleBoxChange(
                                    e,
                                    {
                                      id: null,
                                      sku_id: null,
                                      attribute_id: 1,
                                      boxName: 2,
                                    },
                                    box.size
                                  )
                                }
                              >
                                {boxes.map((option) => (
                                  <MenuItem
                                    key={option.id}
                                    value={option.internal_desc}
                                  >
                                    {option.internal_desc}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      );
                    })
                  : ''}
              </div>
            </div>
          </div>
          <div className={'sku-col'}>
            <h1>{sku != null ? sku.toUpperCase() : ''}</h1>
            <div className={'sku-build-label'}>
              <h3>Build Data</h3>
            </div>
            <div className={'sku-border'}>
              <div className={'sku-build'}>
                <div style={{ marginBottom: '20px' }}>
                  <FormControl className={'sku-form-select'}>
                    <InputLabel id='core-select-helper-label'>
                      Parent Core
                    </InputLabel>
                    <Select
                      className={'sku-select'}
                      labelid='core-select-helper-label'
                      name={'coreName'}
                      placeholder={'Core'}
                      value={parentCoreName}
                      key={parentCoreName}
                      input={
                        <Input name='coreName' id='coreName-placeholder' />
                      }
                      onChange={this.handleParentCoreChange}
                    >
                      {parentCores.map((option) => (
                        <MenuItem key={option.id} value={option.parent_core}>
                          {option.parent_core}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* size & firmness multi-select dropdown only when creating a new sku(s)*/}
                  {id !== -1 ? (
                    <span>
                      <FormControl className={'sku-form-select'}>
                        <InputLabel>Size</InputLabel>
                        <Select
                          className={'sku-select'}
                          name={'sizeName'}
                          placeholder={'Size'}
                          value={sizeName}
                          key={sizeName}
                          input={
                            <Input name='sizeName' id='sizeName-placeholder' />
                          }
                          onChange={this.handleSizeChange}
                        >
                          {sizes.map((option) => (
                            <MenuItem key={option.id} value={option.size}>
                              {option.size}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className={'sku-form-select'}>
                        <InputLabel>Firmness</InputLabel>
                        <Select
                          className={'sku-select'}
                          name={'firmnessName'}
                          placeholder={'Firmness'}
                          value={firmnessName}
                          key={firmnessName}
                          input={
                            <Input
                              name='firmnessName'
                              id='firmness-placeholder'
                            />
                          }
                          onChange={this.handleFirmnessChange}
                        >
                          {firmnesses.map((option) => (
                            <MenuItem key={option.id} value={option.firmness}>
                              {option.firmness}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </span>
                  ) : (
                    <span>
                      <FormControl className={'sku-form-select'}>
                        <InputLabel id='demo-mutiple-chip-label'>
                          Size
                        </InputLabel>
                        <Select
                          className={'sku-select'}
                          labelid='demo-mutiple-chip-label'
                          id='demo-mutiple-chip'
                          multiple
                          displayEmpty={true}
                          value={this.state.sizeValues}
                          renderValue={() =>
                            this.state.sizeValues != undefined
                              ? this.state.sizeValues.length === 0
                                ? ''
                                : 'Size Selected'
                              : ''
                          }
                          onChange={this.handleSizeChange}
                          input={
                            <Input name='sizeName' id='select-multiple-sizes' />
                          }
                        >
                          {sizes.map((option) => (
                            <MenuItem key={option.id} value={option.size}>
                              {option.size}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl className={'sku-form-select'}>
                        <InputLabel id='demo-mutiple-chip-label'>
                          Firmness
                        </InputLabel>
                        <Select
                          className={'sku-select'}
                          labelid='demo-mutiple-chip-label'
                          id='demo-mutiple-chip'
                          multiple
                          value={this.state.firmnessValues}
                          renderValue={() =>
                            this.state.firmnessValues.length === 0
                              ? ''
                              : 'Firmness Selected'
                          }
                          onChange={this.handleFirmnessChange}
                          input={
                            <Input
                              name='firmnessName'
                              id='select-multiple-firmnesses'
                            />
                          }
                        >
                          {this.state.validFirmnesses.map((option) => (
                            <MenuItem key={option.id} value={option.firmness}>
                              {option.firmness}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </span>
                  )}
                  {/*size & firmness table - display only when creating new sku(s)*/}
                  {this.state.coreError != '' ? (
                    <div
                      style={{ fontSize: 17, color: 'red', fontWeight: 'bold' }}
                    >
                      {this.state.coreError}
                    </div>
                  ) : (
                    ''
                  )}
                  {this.state.sizeValues ? (
                    <Table>
                      {this.state.sizeValues.map((size) => {
                        return (
                          <TableBody key={size.id}>
                            <TableRow className={'no-hover'}>
                              <TableCell
                                style={{ width: '80px', padding: '7px' }}
                              >
                                {size}
                              </TableCell>
                              <TableCell style={{ padding: '7px' }}>
                                {this.state.tableSkuList
                                  ? this.state.tableSkuList.map((sku) => {
                                      if (sku.size == size) {
                                        return (
                                          <Chip
                                            key={sku.firmness}
                                            label={sku.firmness}
                                            className={
                                              sku.create
                                                ? 'table-chip-create'
                                                : 'table-chip-no-create'
                                            }
                                            onClick={() => {
                                              this.handleChipSelect(
                                                size,
                                                sku.firmness
                                              );
                                            }}
                                          />
                                        );
                                      }
                                    })
                                  : ''}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                  ) : (
                    ''
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.specialtyMattress}
                        onChange={this.handleCoreGroupAttributeChange}
                        style={{ color: 'rgb(30, 66, 113)' }}
                      />
                    }
                    label={`Is this a specialty mattress build?`}
                    style={{ marginLeft: 10, marginTop: 20, marginRight: 10 }}
                  />
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      verticalAlign: 'middle',
                      marginTop: 15,
                    }}
                    onClick={this.handleSpecialtyInfoPopoverOpen}
                  >
                    <HelpIcon />
                  </span>
                  <Popover
                    anchorEl={this.state.specialtyInfoPopoverAnchor}
                    open={Boolean(this.state.specialtyInfoPopoverAnchor)}
                    disableRestoreFocus
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={this.handleSpecialtyInfoPopoverClose}
                  >
                    <Typography style={{ padding: 10 }}>
                      Specialty mattresses require manual lamination
                      <br />
                      steps and usually have one of the following attributes:
                      <br />
                      Latex in the core, Tufting or Foam Encasement.
                    </Typography>
                  </Popover>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.zipperCoverCheck}
                        onChange={() =>
                          this.setState(
                            { zipperCoverCheck: !this.state.zipperCoverCheck },
                            () => {
                              this.state.zipperCoverCheck == false
                                ? this.clearZCValues()
                                : '';
                            }
                          )
                        }
                        style={{ color: 'rgb(30, 66, 113)' }}
                      />
                    }
                    label={'Zipper Cover?'}
                    style={{ marginLeft: 10, marginTop: 20 }}
                  />
                  {this.state.zipperCoverCheck == true &&
                  this.state.zcNames.length >= 1
                    ? this.state.zcNames.map((zc) => {
                        return (
                          <div key={zc.size} style={{ marginLeft: 20 }}>
                            <div
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                display: 'block',
                                marginTop: 20,
                              }}
                            >
                              {zc.size}
                            </div>
                            <div style={{ display: 'block', marginBottom: 20 }}>
                              <FormControl className={'sku-form-select'}>
                                <InputLabel>Zipper Cover</InputLabel>
                                <Select
                                  className={'sku-select'}
                                  name={'zcName'}
                                  placeholder={'Zipper Cover'}
                                  value={zc.zcName || ''}
                                  key={zc.zcName || ''}
                                  input={<Input name='zcName' />}
                                  onChange={(e) =>
                                    this.handleZCChange(e, zc.size)
                                  }
                                >
                                  {filteredZipperCoverParts.map((option) => (
                                    <MenuItem
                                      key={option.num}
                                      value={
                                        option.num +
                                        ' - ' +
                                        option.internal_desc
                                      }
                                    >
                                      {option.num} - {option.internal_desc}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        );
                      })
                    : ''}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.noPanels}
                        onChange={() =>
                          this.setState({ noPanels: !this.state.noPanels })
                        }
                        style={{ color: 'rgb(30, 66, 113)' }}
                      />
                    }
                    label={'There are NO panels'}
                    style={{ marginLeft: 10, marginTop: 20 }}
                  />
                  {id == -1 && this.state.noPanels == false ? (
                    <div>
                      {/*top panel info*/}
                      <div>
                        <FormControl style={{ width: '200px' }}>
                          <Autocomplete
                            id='autocomplete-top-panel'
                            options={topPanelOptions}
                            getOptionLabel={(options) => options}
                            value={isTopPanel ? identifier : null}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onSelect={this.handleTopPanelChange}
                            style={{ width: 350 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Top Panel'
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>

                        {/* <Button */}
                        {/*   variant="contained" */}
                        {/*   style={{ marginTop: 25, marginLeft: 25 }} */}
                        {/*   onClick={(e) => this.handleUpdateModalOpen(e, "Top")} */}
                        {/* > */}
                        {/*   Add New */}
                        {/* </Button> */}

                        {/*if the topPanelsArr.length is greater than 1 we will need to make a selection*/}
                        {this.state.topPanelsArr.length >= 1 &&
                        this.state.topPanelsArr != undefined ? (
                          <div>
                            {this.state.topPanelsArr.length > 1 ? (
                              <div
                                style={{
                                  marginLeft: 20,
                                  marginTop: 15,
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                }}
                              >
                                Select a Panel
                              </div>
                            ) : (
                              ''
                            )}
                            {this.state.topPanelsArr.map((layerArr, i) => {
                              return (
                                <div
                                  style={{ width: 800, marginTop: 20 }}
                                  key={i}
                                >
                                  <Checkbox
                                    checked={this.state.topCheck}
                                    onChange={(e) =>
                                      this.handleTopPanelChange(
                                        e,
                                        layerArr[0].panel_id
                                      )
                                    }
                                    style={{ color: 'rgb(30, 66, 113)' }}
                                  />
                                  <Table
                                    style={{
                                      marginLeft: 5,
                                      marginTop: 20,
                                      display: 'inline',
                                    }}
                                  >
                                    <TableBody>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            width: 100,
                                            padding: '5px',
                                            background: 'rgb(30, 66, 113)',
                                            color: 'white',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          Layer Type
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: 500,
                                            padding: '5px',
                                            background: 'rgb(30, 66, 113)',
                                            color: 'white',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          Part #
                                        </TableCell>
                                      </TableRow>
                                      {layerArr.map((l) => {
                                        return (
                                          <TableRow
                                            key={l.id}
                                            className={'no-hover'}
                                          >
                                            <TableCell
                                              style={{ padding: '5px' }}
                                            >
                                              {l.fabricLayerType != null
                                                ? l.fabricLayerType
                                                    .fabric_layer_type
                                                : ''}
                                            </TableCell>
                                            <TableCell
                                              style={{ padding: '5px' }}
                                            >
                                              {l.fishbowlPart != null
                                                ? l.fishbowlPart.num +
                                                  ' - ' +
                                                  l.fishbowlPart.description
                                                : ''}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          ''
                        )}
                        {topPanelLayers != undefined &&
                        topPanelLayers.length >= 1 ? (
                          <div>
                            <Table
                              style={{
                                maxWidth: 680,
                                marginLeft: 20,
                                marginTop: 20,
                              }}
                            >
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      padding: '5px',
                                      background: 'rgb(30, 66, 113)',
                                      color: 'white',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Layer Type
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: '5px',
                                      background: 'rgb(30, 66, 113)',
                                      color: 'white',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Part #
                                  </TableCell>
                                </TableRow>
                                {topPanelLayers.map((l) => {
                                  return (
                                    <TableRow key={l.id} className={'no-hover'}>
                                      <TableCell style={{ padding: '5px' }}>
                                        {l.fabricLayerType != null
                                          ? l.fabricLayerType.fabric_layer_type
                                          : ''}
                                      </TableCell>
                                      <TableCell style={{ padding: '5px' }}>
                                        {l.fishbowlPart != null
                                          ? l.fishbowlPart.num +
                                            ' - ' +
                                            l.fishbowlPart.description
                                          : ''}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <FormControl>
                          <TextField
                            className={'sku-height'}
                            label='Top Height'
                            name='height'
                            margin='normal'
                            value={this.state.topHeight}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  in
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              this.setState({ topHeight: e.target.value }, () =>
                                this.handlePanelAttributeChange(
                                  {
                                    id: null,
                                    sku_id: null,
                                    fabric_attribute_id: 1,
                                  },
                                  this.state.topHeight,
                                  'skuTopPanelAttribute'
                                )
                              );
                            }}
                          />
                        </FormControl>
                        <FormControl style={{ width: 200, marginLeft: 10 }}>
                          <Autocomplete
                            id='autocomplete-top-panel-quilt'
                            value={this.state.topQuiltPattern}
                            inputValue={this.state.topQuiltPattern}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onInputChange={(e, value) =>
                              this.setState({ topQuiltPattern: value }, () =>
                                this.handleQuiltChange(
                                  this.state.topQuiltPattern,
                                  {
                                    id: null,
                                    sku_id: null,
                                    fabric_attribute_id: 4,
                                  },
                                  'topQuiltPatternObj',
                                  'skuTopPanelAttribute'
                                )
                              )
                            }
                            style={{ width: 100 }}
                            options={quiltPatterns}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Top Quilt Pattern'
                                style={{ width: 100 }}
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl style={{ width: 200, marginLeft: 30 }}>
                          <Autocomplete
                            id='autocomplete-top-tape'
                            value={this.state.topTape}
                            inputValue={this.state.topTape}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onInputChange={(e, value) =>
                              this.setState({ topTape: value }, () =>
                                this.handleTapeChange(
                                  this.state.topTape.split(' -')[0],
                                  1,
                                  'topTape',
                                  'topTapeObj'
                                )
                              )
                            }
                            style={{ width: 100 }}
                            options={tapes}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Top Tape Part #'
                                style={{ width: 100 }}
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      {/*gusset panel info*/}
                      <div>
                        <FormControl style={{ width: '200px', marginTop: 10 }}>
                          <Autocomplete
                            id='autocomplete-gusset-panel'
                            options={gussetPanelOptions}
                            value={isGussetPanel ? identifier : null}
                            getOptionLabel={(options) => options}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onSelect={this.handleGussetPanelChange}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Gusset Panel'
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>

                        {/* <Button */}
                        {/*   variant="contained" */}
                        {/*   style={{ marginTop: 25, marginLeft: 25 }} */}
                        {/*   onClick={(e) => */}
                        {/*     this.handleUpdateModalOpen(e, "Gusset") */}
                        {/*   } */}
                        {/* > */}
                        {/*   Add New */}
                        {/* </Button> */}

                        {this.state.gussetPanelsArr.length >= 1 ? (
                          <div>
                            {this.state.gussetPanelsArr.length > 1 ? (
                              <div
                                style={{
                                  marginLeft: 20,
                                  marginTop: 15,
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                }}
                              >
                                Select a Gusset Panel
                              </div>
                            ) : (
                              ''
                            )}
                            {this.state.gussetPanelsArr.map((layerArr, i) => {
                              return (
                                <div
                                  style={{ width: 800, marginTop: 20 }}
                                  key={i}
                                >
                                  <Checkbox
                                    checked={this.state.gussetCheck}
                                    onChange={(e) =>
                                      this.handleGussetPanelChange(
                                        e,
                                        layerArr[0].panel_id
                                      )
                                    }
                                    style={{ color: 'rgb(30, 66, 113)' }}
                                  />
                                  <Table
                                    style={{
                                      marginLeft: 5,
                                      marginTop: 20,
                                      display: 'inline',
                                    }}
                                  >
                                    <TableBody>
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            width: 100,
                                            padding: '5px',
                                            background: 'rgb(30, 66, 113)',
                                            color: 'white',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          Layer Type
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: 500,
                                            padding: '5px',
                                            background: 'rgb(30, 66, 113)',
                                            color: 'white',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          Part #
                                        </TableCell>
                                      </TableRow>
                                      {layerArr.map((l) => {
                                        return (
                                          <TableRow
                                            key={l.id}
                                            className={'no-hover'}
                                          >
                                            <TableCell
                                              style={{ padding: '5px' }}
                                            >
                                              {l.fabricLayerType != null
                                                ? l.fabricLayerType
                                                    .fabric_layer_type
                                                : ''}
                                            </TableCell>
                                            <TableCell
                                              style={{ padding: '5px' }}
                                            >
                                              {l.fishbowlPart != null
                                                ? l.fishbowlPart.num +
                                                  ' - ' +
                                                  l.fishbowlPart.description
                                                : ''}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          ''
                        )}
                        {gussetPanelLayers != undefined &&
                        gussetPanelLayers.length >= 1 ? (
                          <div>
                            <Table
                              style={{
                                maxWidth: 680,
                                marginLeft: 20,
                                marginTop: 20,
                              }}
                            >
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      padding: '5px',
                                      background: 'rgb(30, 66, 113)',
                                      color: 'white',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Layer Type
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: '5px',
                                      background: 'rgb(30, 66, 113)',
                                      color: 'white',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Part #
                                  </TableCell>
                                </TableRow>
                                {gussetPanelLayers.map((l) => {
                                  return (
                                    <TableRow key={l.id} className={'no-hover'}>
                                      <TableCell style={{ padding: '5px' }}>
                                        {l.fabricLayerType != null
                                          ? l.fabricLayerType.fabric_layer_type
                                          : ''}
                                      </TableCell>
                                      <TableCell style={{ padding: '5px' }}>
                                        {l.fishbowlPart != null
                                          ? l.fishbowlPart.num +
                                            ' - ' +
                                            l.fishbowlPart.description
                                          : ''}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <FormControl>
                          <TextField
                            className={'sku-height'}
                            label='Gusset Height'
                            name='height'
                            margin='normal'
                            value={this.state.gussetHeight}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  in
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              this.setState(
                                { gussetHeight: e.target.value },
                                () =>
                                  this.handlePanelAttributeChange(
                                    {
                                      id: null,
                                      sku_id: null,
                                      fabric_attribute_id: 1,
                                    },
                                    this.state.gussetHeight,
                                    'skuGussetPanelAttribute'
                                  )
                              );
                            }}
                          />
                        </FormControl>
                        <FormControl style={{ width: 200, marginLeft: 10 }}>
                          <Autocomplete
                            id='autocomplete-gusset-panel-quilt'
                            value={this.state.gussetQuiltPattern}
                            inputValue={this.state.gussetQuiltPattern}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onInputChange={(e, value) =>
                              this.setState({ gussetQuiltPattern: value }, () =>
                                this.handleQuiltChange(
                                  this.state.gussetQuiltPattern,
                                  {
                                    id: null,
                                    sku_id: null,
                                    fabric_attribute_id: 4,
                                  },
                                  'gussetQuiltPatternObj',
                                  'skuGussetPanelAttribute'
                                )
                              )
                            }
                            style={{ width: 100 }}
                            options={quiltPatterns}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Gusset Quilt Pattern'
                                style={{ width: 100 }}
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div>
                        <FormControl style={{ width: '200px', marginTop: 10 }}>
                          <Autocomplete
                            id='autocomplete-side-panel'
                            options={sidePanelOptions}
                            getOptionLabel={(options) => options}
                            value={isSidePanel ? identifier : null}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onSelect={this.handleSidePanelChange}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Border Panel'
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>

                        {/* <Button */}
                        {/*   variant="contained" */}
                        {/*   style={{ marginTop: 25, marginLeft: 25 }} */}
                        {/*   onClick={(e) => this.handleUpdateModalOpen(e, "Side")} */}
                        {/* > */}
                        {/*   Add New */}
                        {/* </Button> */}
                      </div>
                      {this.state.sidePanelsArr.length >= 1 ? (
                        <div>
                          {this.state.sidePanelsArr.length > 1 ? (
                            <div
                              style={{
                                marginLeft: 20,
                                marginTop: 15,
                                fontSize: 20,
                                fontWeight: 'bold',
                              }}
                            >
                              Select a Side Panel
                            </div>
                          ) : (
                            ''
                          )}
                          {this.state.sidePanelsArr.map((layerArr, i) => {
                            return (
                              <div
                                style={{ width: 800, marginTop: 20 }}
                                key={i}
                              >
                                <Checkbox
                                  checked={this.state.sideCheck}
                                  onChange={(e) =>
                                    this.handleSidePanelChange(
                                      e,
                                      layerArr[0].panel_id
                                    )
                                  }
                                  style={{ color: 'rgb(30, 66, 113)' }}
                                />
                                <Table
                                  style={{
                                    marginLeft: 5,
                                    marginTop: 20,
                                    display: 'inline',
                                  }}
                                >
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          width: 100,
                                          padding: '5px',
                                          background: 'rgb(30, 66, 113)',
                                          color: 'white',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Layer Type
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: 500,
                                          padding: '5px',
                                          background: 'rgb(30, 66, 113)',
                                          color: 'white',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Part #
                                      </TableCell>
                                    </TableRow>
                                    {layerArr.map((l) => {
                                      return (
                                        <TableRow
                                          key={l.id}
                                          className={'no-hover'}
                                        >
                                          <TableCell style={{ padding: '5px' }}>
                                            {l.fabricLayerType != null
                                              ? l.fabricLayerType
                                                  .fabric_layer_type
                                              : ''}
                                          </TableCell>
                                          <TableCell style={{ padding: '5px' }}>
                                            {l.fishbowlPart != null
                                              ? l.fishbowlPart.num +
                                                ' - ' +
                                                l.fishbowlPart.description
                                              : ''}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ''
                      )}
                      {sidePanelLayers != undefined &&
                      sidePanelLayers.length >= 1 ? (
                        <div>
                          <Table
                            style={{
                              maxWidth: 680,
                              marginLeft: 20,
                              marginTop: 20,
                            }}
                          >
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  style={{
                                    padding: '5px',
                                    background: 'rgb(30, 66, 113)',
                                    color: 'white',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Layer Type
                                </TableCell>
                                <TableCell
                                  style={{
                                    padding: '5px',
                                    background: 'rgb(30, 66, 113)',
                                    color: 'white',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Part #
                                </TableCell>
                              </TableRow>
                              {sidePanelLayers.map((l) => {
                                return (
                                  <TableRow key={l.id} className={'no-hover'}>
                                    <TableCell style={{ padding: '5px' }}>
                                      {l.fabricLayerType != null
                                        ? l.fabricLayerType.fabric_layer_type
                                        : ''}
                                    </TableCell>
                                    <TableCell style={{ padding: '5px' }}>
                                      {l.fishbowlPart != null
                                        ? l.fishbowlPart.num +
                                          ' - ' +
                                          l.fishbowlPart.description
                                        : ''}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </div>
                      ) : (
                        ''
                      )}
                      <div>
                        <FormControl>
                          <TextField
                            className={'sku-height'}
                            label='Border Height'
                            name='height'
                            margin='normal'
                            value={this.state.sideHeight}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  in
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              this.setState(
                                { sideHeight: e.target.value },
                                () =>
                                  this.handlePanelAttributeChange(
                                    {
                                      id: null,
                                      sku_id: null,
                                      fabric_attribute_id: 1,
                                    },
                                    this.state.sideHeight,
                                    'skuSidePanelAttribute'
                                  )
                              );
                            }}
                          />
                        </FormControl>
                        <FormControl style={{ width: 200, marginLeft: 10 }}>
                          <Autocomplete
                            id='autocomplete-side-panel-quilt'
                            value={this.state.sideQuiltPattern}
                            inputValue={this.state.sideQuiltPattern}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onInputChange={(e, value) =>
                              this.setState({ sideQuiltPattern: value }, () =>
                                this.handleQuiltChange(
                                  this.state.sideQuiltPattern,
                                  {
                                    id: null,
                                    sku_id: null,
                                    fabric_attribute_id: 4,
                                  },
                                  'sideQuiltPatternObj',
                                  'skuSidePanelAttribute'
                                )
                              )
                            }
                            style={{ width: 100 }}
                            options={quiltPatterns}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Border Quilt Pattern'
                                style={{ width: 100 }}
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl style={{ width: 200, marginLeft: 10 }}>
                          <Autocomplete
                            id='autocomplete-side-panel-quilt2'
                            value={this.state.sideQuiltPattern2}
                            inputValue={this.state.sideQuiltPattern2}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onInputChange={(e, value) =>
                              this.setState({ sideQuiltPattern2: value }, () =>
                                this.handleQuiltChange(
                                  this.state.sideQuiltPattern2,
                                  {
                                    id: null,
                                    sku_id: null,
                                    fabric_attribute_id: 4,
                                  },
                                  'sideQuiltPatternObj2',
                                  'skuSidePanelAttribute'
                                )
                              )
                            }
                            style={{ width: 100 }}
                            options={quiltPatterns}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Border Quilt Pattern 2'
                                style={{ width: 100 }}
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl style={{ width: 200, marginLeft: 30 }}>
                          <Autocomplete
                            id='autocomplete-side-tape'
                            value={this.state.sideTape}
                            inputValue={this.state.sideTape}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onInputChange={(e, value) =>
                              this.setState({ sideTape: value }, () =>
                                this.handleTapeChange(
                                  this.state.sideTape.split(' -')[0],
                                  2,
                                  'sideTape',
                                  'sideTapeObj'
                                )
                              )
                            }
                            style={{ width: 100 }}
                            options={tapes}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Border Tape Part #'
                                style={{ width: 100 }}
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div>
                        <FormControl style={{ width: '200px', marginTop: 10 }}>
                          <Autocomplete
                            id='autocomplete-bottom-panel'
                            options={bottomPanelOptions}
                            getOptionLabel={(options) => options}
                            value={isBottomPanel ? identifier : null}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onSelect={this.handleBottomPanelChange}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Bottom Panel'
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>

                        {/* <Button */}
                        {/*   variant="contained" */}
                        {/*   style={{ marginTop: 25, marginLeft: 25 }} */}
                        {/*   onClick={(e) => */}
                        {/*     this.handleUpdateModalOpen(e, "Bottom") */}
                        {/*   } */}
                        {/* > */}
                        {/*   Add New */}
                        {/* </Button> */}
                      </div>
                      {this.state.bottomPanelsArr.length >= 1 ? (
                        <div>
                          {this.state.bottomPanelsArr.length > 1 ? (
                            <div
                              style={{
                                marginLeft: 20,
                                marginTop: 15,
                                fontSize: 20,
                                fontWeight: 'bold',
                              }}
                            >
                              Select a Bottom Panel
                            </div>
                          ) : (
                            ''
                          )}
                          {this.state.bottomPanelsArr.map((layerArr, i) => {
                            return (
                              <div
                                style={{ width: 1380, marginTop: 20 }}
                                key={i}
                              >
                                <Checkbox
                                  checked={this.state.bottomCheck}
                                  onChange={(e) =>
                                    this.handleBottomPanelChange(
                                      e,
                                      layerArr[0].panel_id
                                    )
                                  }
                                  style={{ color: 'rgb(30, 66, 113)' }}
                                />
                                <Table
                                  style={{
                                    marginLeft: 5,
                                    marginTop: 20,
                                    display: 'inline',
                                  }}
                                >
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          padding: '5px',
                                          background: 'rgb(30, 66, 113)',
                                          color: 'white',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Layer Type
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          padding: '5px',
                                          background: 'rgb(30, 66, 113)',
                                          color: 'white',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Part #
                                      </TableCell>
                                    </TableRow>
                                    {layerArr.map((l) => {
                                      return (
                                        <TableRow
                                          key={l.id}
                                          className={'no-hover'}
                                        >
                                          <TableCell style={{ padding: '5px' }}>
                                            {l.fabricLayerType != null
                                              ? l.fabricLayerType
                                                  .fabric_layer_type
                                              : ''}
                                          </TableCell>
                                          <TableCell style={{ padding: '5px' }}>
                                            {l.fishbowlPart != null
                                              ? l.fishbowlPart.num +
                                                ' - ' +
                                                l.fishbowlPart.description
                                              : ''}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ''
                      )}
                      {bottomPanelLayers != undefined &&
                      bottomPanelLayers.length >= 1 ? (
                        <div>
                          <Table
                            style={{
                              maxWidth: 680,
                              marginLeft: 20,
                              marginTop: 20,
                            }}
                          >
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  style={{
                                    padding: '5px',
                                    background: 'rgb(30, 66, 113)',
                                    color: 'white',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Layer Type
                                </TableCell>
                                <TableCell
                                  style={{
                                    padding: '5px',
                                    background: 'rgb(30, 66, 113)',
                                    color: 'white',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Part #
                                </TableCell>
                              </TableRow>
                              {bottomPanelLayers.map((l) => {
                                return (
                                  <TableRow key={l.id} className={'no-hover'}>
                                    <TableCell style={{ padding: '5px' }}>
                                      {l.fabricLayerType != null
                                        ? l.fabricLayerType.fabric_layer_type
                                        : ''}
                                    </TableCell>
                                    <TableCell style={{ padding: '5px' }}>
                                      {l.fishbowlPart != null
                                        ? l.fishbowlPart.num +
                                          ' - ' +
                                          l.fishbowlPart.description
                                        : ''}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </div>
                      ) : (
                        ''
                      )}
                      <div>
                        <FormControl>
                          <TextField
                            className={'sku-height'}
                            label='Bottom Height'
                            name='height'
                            margin='normal'
                            value={this.state.bottomHeight}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  in
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              this.setState(
                                { bottomHeight: e.target.value },
                                () =>
                                  this.handlePanelAttributeChange(
                                    {
                                      id: null,
                                      sku_id: null,
                                      fabric_attribute_id: 1,
                                    },
                                    this.state.bottomHeight,
                                    'skuBottomPanelAttribute'
                                  )
                              );
                            }}
                          />
                        </FormControl>
                        <FormControl style={{ width: 200, marginLeft: 10 }}>
                          <Autocomplete
                            id='autocomplete-bottom-panel-quilt'
                            value={this.state.bottomQuiltPattern}
                            inputValue={this.state.bottomQuiltPattern}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onInputChange={(e, value) =>
                              this.setState({ bottomQuiltPattern: value }, () =>
                                this.handleQuiltChange(
                                  this.state.bottomQuiltPattern,
                                  {
                                    id: null,
                                    sku_id: null,
                                    fabric_attribute_id: 4,
                                  },
                                  'bottomQuiltPatternObj',
                                  'skuBottomPanelAttribute'
                                )
                              )
                            }
                            style={{ width: 100 }}
                            options={quiltPatterns}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Bottom Quilt Pattern'
                                style={{ width: 100 }}
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl style={{ width: 200, marginLeft: 30 }}>
                          <Autocomplete
                            id='autocomplete-bottom-tape'
                            value={this.state.bottomTape}
                            inputValue={this.state.bottomTape}
                            getOptionSelected={(option, value) =>
                              this.sanitizeAutocompleteOptionSelected(
                                option,
                                value
                              )
                            }
                            onInputChange={(e, value) =>
                              this.setState({ bottomTape: value }, () =>
                                this.handleTapeChange(
                                  this.state.bottomTape.split(' -')[0],
                                  3,
                                  'bottomTape',
                                  'bottomTapeObj'
                                )
                              )
                            }
                            style={{ width: 100 }}
                            options={tapes}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Bottom Tape Part #'
                                style={{ width: 100 }}
                                className={'sku-auto-complete'}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <FormControl>
                          <TextField
                            className={'sku-input'}
                            label='Embroidery'
                            name='embroidery'
                            margin='normal'
                            value={this.state.embroidery}
                            onChange={(e) => {
                              this.setState(
                                { embroidery: e.target.value },
                                () =>
                                  this.handlePanelAttributeChange(
                                    {
                                      id: null,
                                      sku_id: null,
                                      fabric_attribute_id: 2,
                                    },
                                    this.state.embroidery,
                                    'skuSidePanelAttribute'
                                  )
                              );
                            }}
                          />
                        </FormControl>
                        <FormControlLabel
                          className={'sku-switch'}
                          label='Handles'
                          control={
                            <Switch
                              checked={this.state.hasHandles}
                              onChange={(e) => {
                                this.setState(
                                  { hasHandles: !this.state.hasHandles },
                                  () => {
                                    this.state.hasHandles === false
                                      ? this.handlePanelAttributeChange(
                                          {
                                            id: null,
                                            sku_id: null,
                                            fabric_attribute_id: 3,
                                          },
                                          '',
                                          'skuSidePanelAttribute'
                                        )
                                      : '';
                                  }
                                );
                              }}
                            />
                          }
                        />
                        {this.state.hasHandles ? (
                          <div style={{ marginLeft: 250 }}>
                            <RadioGroup
                              row
                              aria-label='position'
                              name='position'
                              onChange={(e) => {
                                this.setState({ handles: e.target.value }, () =>
                                  this.handlePanelAttributeChange(
                                    {
                                      id: null,
                                      sku_id: null,
                                      fabric_attribute_id: 3,
                                    },
                                    this.state.handles,
                                    'skuSidePanelAttribute'
                                  )
                                );
                              }}
                              value={this.state.handles}
                            >
                              <FormControlLabel
                                value={'1'}
                                control={<Radio color='primary' />}
                                label='Vertical'
                                labelPlacement='end'
                              />
                              <FormControlLabel
                                value={'0'}
                                control={<Radio color='primary' />}
                                label='Horizontal'
                                labelPlacement='end'
                              />
                            </RadioGroup>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {id !== -1 &&
                  skuSidePanelAttribute !== null &&
                  id !== undefined ? (
                    <FormControl
                      style={{ fontSize: 16, marginLeft: 20, marginBottom: 20 }}
                    >
                      {skuTopPanelAttribute !== null
                        ? skuSidePanelAttribute.map((item) => {
                            if (item.fabric_attribute_id === 2) {
                              return 'Embroidery: ' + item.attribute_value;
                            }
                          })
                        : ''}
                    </FormControl>
                  ) : (
                    ''
                  )}
                  {id !== -1 &&
                  skuSidePanelAttribute !== null &&
                  id !== undefined ? (
                    <FormControl
                      style={{ fontSize: 16, marginLeft: 60, marginBottom: 20 }}
                    >
                      {skuTopPanelAttribute !== null
                        ? skuSidePanelAttribute.map((item) => {
                            if (item.fabric_attribute_id === 3) {
                              return 'Handles: Yes';
                            }
                          })
                        : ''}
                    </FormControl>
                  ) : (
                    ''
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.fireSockCheck}
                        onChange={() =>
                          this.setState(
                            { fireSockCheck: !this.state.fireSockCheck },
                            () => {
                              this.state.fireSockCheck == false
                                ? this.clearFRValues()
                                : '';
                            }
                          )
                        }
                        style={{ color: 'rgb(30, 66, 113)' }}
                      />
                    }
                    label={'Does this core have a Fire Sock?'}
                    style={{ marginLeft: 10, marginTop: 20 }}
                  />{' '}
                  <FormControl variant='standard' className={'sku-form-select'}>
                    <InputLabel id='demo-simple-select-standard-label'>
                      Zipper Components
                    </InputLabel>
                    <Select
                      className={'sku-select'}
                      name={'zipper'}
                      value={this.state.zipperName || 'None'}
                    >
                      <MenuItem
                        onClick={(e) => this.setState({ zipperName: 0 })}
                        value={0}
                      >
                        None
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => this.setState({ zipperName: 1 })}
                        value={1}
                      >
                        White Zipper
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => this.setState({ zipperName: 2 })}
                        value={2}
                      >
                        Black Zipper
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {this.state.fireSockCheck == true &&
                  this.state.frNames.length >= 1
                    ? this.state.frNames.map((fr) => {
                        return (
                          <div style={{ marginLeft: 20 }}>
                            <div
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                display: 'block',
                                marginTop: 20,
                              }}
                            >
                              {fr.size}
                            </div>
                            <div style={{ display: 'block', marginBottom: 20 }}>
                              <FormControl className={'sku-form-select'}>
                                <InputLabel>Fire Sock</InputLabel>
                                <Select
                                  className={'sku-select'}
                                  name={'frName'}
                                  placeholder={'Fire Sock'}
                                  value={fr.frName || ''}
                                  key={fr.frName || ''}
                                  input={<Input name='frName' />}
                                  onChange={(e) =>
                                    this.handleFRChange(e, fr.size)
                                  }
                                >
                                  {filteredFireSockParts.map((option) => (
                                    <MenuItem
                                      key={option.num}
                                      value={
                                        option.num +
                                        ' - ' +
                                        option.internal_desc
                                      }
                                    >
                                      {option.num} - {option.internal_desc}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        );
                      })
                    : ''}
                  {id !== -1 && topPanel !== '' && id !== undefined ? (
                    <Accordion style={{ width: '620px', marginLeft: 20 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography>
                          {topPanel != null ? 'Top Panel' : ''}
                          <span
                            style={{ marginLeft: '15px', fontSize: '14px' }}
                          >
                            {skuTopPanelAttribute !== undefined
                              ? skuTopPanelAttribute.map((item) => {
                                  if (item.fabric_attribute_id === 1) {
                                    return (
                                      'height ' + item.attribute_value + '"'
                                    );
                                  }
                                })
                              : ''}
                          </span>
                          <span
                            style={{ marginLeft: '15px', fontSize: '14px' }}
                          >
                            {skuTopPanelAttribute !== undefined
                              ? skuTopPanelAttribute.map((item) => {
                                  if (item.fabric_attribute_id === 4) {
                                    return (
                                      'quilt ' +
                                      item.fabricQuiltPattern
                                        .fabric_quilt_pattern
                                    );
                                  }
                                })
                              : ''}
                          </span>
                          <span
                            style={{ marginLeft: '15px', fontSize: '14px' }}
                          >
                            {skuTape !== undefined
                              ? skuTape.map((item) => {
                                  if (item.fabric_use_id === 1) {
                                    return 'tape ' + item.part.internal_desc;
                                  }
                                })
                              : ''}
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Layer Type</TableCell>
                              <TableCell>Part #</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topPanel != null &&
                              topPanelLayers != null &&
                              id != -1 &&
                              id !== undefined &&
                              topPanelLayers.map((l) => {
                                return (
                                  <TableRow key={l.id}>
                                    <TableCell>
                                      {l.fabricLayerType != null
                                        ? l.fabricLayerType.fabric_layer_type
                                        : ''}
                                    </TableCell>
                                    <TableCell>
                                      {l.fishbowlPart != null
                                        ? l.fishbowlPart.num +
                                          ' - ' +
                                          l.fishbowlPart.description
                                        : ''}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    ''
                  )}
                  {id !== -1 && gussetPanel !== '' && id !== undefined ? (
                    <Accordion style={{ width: '620px', marginLeft: 20 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography>
                          {gussetPanel != null && id !== undefined
                            ? 'Gusset Panel'
                            : ''}
                          <span
                            style={{ marginLeft: '20px', fontSize: '14px' }}
                          >
                            {skuGussetPanelAttribute !== undefined
                              ? skuGussetPanelAttribute.map((item) => {
                                  if (item.fabric_attribute_id === 1) {
                                    return (
                                      'height ' + item.attribute_value + '"'
                                    );
                                  }
                                })
                              : ''}
                          </span>
                          <span
                            style={{ marginLeft: '20px', fontSize: '14px' }}
                          >
                            {skuGussetPanelAttribute !== undefined
                              ? skuGussetPanelAttribute.map((item) => {
                                  if (item.fabric_attribute_id === 4) {
                                    return (
                                      'quilt ' +
                                      item.fabricQuiltPattern
                                        .fabric_quilt_pattern
                                    );
                                  }
                                })
                              : ''}
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Layer Type</TableCell>
                              <TableCell>Part #</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {gussetPanel != null &&
                              gussetPanelLayers != null &&
                              id != -1 &&
                              id !== undefined &&
                              gussetPanelLayers.map((l) => {
                                return (
                                  <TableRow key={l.id}>
                                    <TableCell>
                                      {l.fabricLayerType != null
                                        ? l.fabricLayerType.fabric_layer_type
                                        : ''}
                                    </TableCell>
                                    <TableCell>
                                      {l.fishbowlPart != null
                                        ? l.fishbowlPart.num +
                                          ' - ' +
                                          l.fishbowlPart.description
                                        : ''}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    ''
                  )}
                  {id !== -1 && sidePanel !== '' && id !== undefined ? (
                    <Accordion style={{ width: '620px', marginLeft: 20 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography>
                          {sidePanel != null ? 'Side Panel' : ''}
                          <span
                            style={{ marginLeft: '15px', fontSize: '14px' }}
                          >
                            {skuSidePanelAttribute !== undefined
                              ? skuSidePanelAttribute.map((item) => {
                                  if (item.fabric_attribute_id === 1) {
                                    return (
                                      'height ' + item.attribute_value + '"'
                                    );
                                  }
                                })
                              : ''}
                          </span>
                          <span
                            style={{ marginLeft: '15px', fontSize: '14px' }}
                          >
                            {skuSidePanelAttribute !== undefined
                              ? skuSidePanelAttribute.map((item) => {
                                  if (item.fabric_attribute_id === 4) {
                                    return (
                                      'quilt ' +
                                      item.fabricQuiltPattern
                                        .fabric_quilt_pattern
                                    );
                                  }
                                })
                              : ''}
                          </span>
                          <span
                            style={{ marginLeft: '15px', fontSize: '14px' }}
                          >
                            {skuTape !== undefined
                              ? skuTape.map((item) => {
                                  if (item.fabric_use_id === 2) {
                                    return 'tape ' + item.part.internal_desc;
                                  }
                                })
                              : ''}
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Layer Type</TableCell>
                              <TableCell>Part #</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sidePanel != null &&
                              sidePanelLayers != null &&
                              id != -1 &&
                              id !== undefined &&
                              sidePanelLayers.map((l) => {
                                return (
                                  <TableRow key={l.id}>
                                    <TableCell>
                                      {l.fabricLayerType != null
                                        ? l.fabricLayerType.fabric_layer_type
                                        : ''}
                                    </TableCell>
                                    <TableCell>
                                      {l.fishbowlPart != null
                                        ? l.fishbowlPart.num +
                                          ' - ' +
                                          l.fishbowlPart.description
                                        : ''}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    ''
                  )}
                  {id !== -1 && bottomPanel !== '' && id !== undefined ? (
                    <Accordion style={{ width: '620px', marginLeft: 20 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography>
                          {bottomPanel != null && id !== undefined
                            ? 'Bottom Panel'
                            : ''}
                          <span
                            style={{ marginLeft: '15px', fontSize: '14px' }}
                          >
                            {skuBottomPanelAttribute !== undefined
                              ? skuBottomPanelAttribute.map((item) => {
                                  if (item.fabric_attribute_id === 1) {
                                    return (
                                      'height ' + item.attribute_value + '"'
                                    );
                                  }
                                })
                              : ''}
                          </span>
                          <span
                            style={{ marginLeft: '15px', fontSize: '14px' }}
                          >
                            {skuBottomPanelAttribute !== undefined
                              ? skuBottomPanelAttribute.map((item) => {
                                  if (item.fabric_attribute_id === 4) {
                                    return (
                                      'quilt ' +
                                      item.fabricQuiltPattern
                                        .fabric_quilt_pattern
                                    );
                                  }
                                })
                              : ''}
                          </span>
                          <span
                            style={{ marginLeft: '15px', fontSize: '14px' }}
                          >
                            {skuTape !== undefined
                              ? skuTape.map((item) => {
                                  if (item.fabric_use_id === 3) {
                                    return 'tape ' + item.part.internal_desc;
                                  }
                                })
                              : ''}
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Layer Type</TableCell>
                              <TableCell>Part #</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {bottomPanel != null &&
                              bottomPanelLayers != null &&
                              id != -1 &&
                              id !== undefined &&
                              bottomPanelLayers.map((l) => {
                                return (
                                  <TableRow key={l.id}>
                                    <TableCell>
                                      {l.fabricLayerType != null
                                        ? l.fabricLayerType.fabric_layer_type
                                        : ''}
                                    </TableCell>
                                    <TableCell>
                                      {l.fishbowlPart != null
                                        ? l.fishbowlPart.num +
                                          ' - ' +
                                          l.fishbowlPart.description
                                        : ''}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    ''
                  )}
                </div>

                {core != null &&
                  core.layers != null &&
                  id !== undefined &&
                  core.layers.map((l, i) => {
                    const paperStyle = {
                      // background: 'linear-gradient(45deg, ' + l.material.color + ' 30%, ' + '#CFB6AE' + ' 90%)',
                      background: l.material.color,
                      border: 0,
                      borderRadius: 3,
                      color: 'white',
                      fontWeight: 'bold',
                      paddingTop: l.layer_depth_in + 15 + 'px',
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      paddingBottom: l.layer_depth_in + 15 + 'px',
                    };
                    const paperLinkStyle = {
                      color: 'white',
                      align: 'center',
                    };
                    return (
                      <div key={i} style={{ width: '620px', marginLeft: 20 }}>
                        <div style={{ padding: '2px' }}>
                          <Paper style={paperStyle}>
                            <span>{l.material.raw_material}</span> -{' '}
                            <span>
                              {l.part ? l.part.num : ''} -{' '}
                              {l.part ? l.part.description : ''}{' '}
                            </span>
                            <span className={'side-text'}>
                              {l.layer_depth_in}"
                            </span>
                          </Paper>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.inputOverlay && this.state.noteError === ''
              ? ' blur sku-submit'
              : 'sku-submit'
          }
        >
          <Button
            variant='contained'
            style={{ background: 'rgb(30, 66, 113)', color: 'white' }}
            onClick={() => {
              this.props.history.push('/skus');
            }}
          >
            Cancel
          </Button>
        </div>
        {id === -1 && skuGroup === undefined ? (
          <div
            className={
              this.state.inputOverlay && this.state.noteError === ''
                ? ' blur sku-submit'
                : 'sku-submit'
            }
          >
            <Button
              variant='contained'
              style={{ background: 'rgb(30, 66, 113)', color: 'white' }}
              onClick={(e) => {
                this.state.noteError === '' ? this.handleDraftSubmit(e) : '';
              }}
            >
              Save as Draft
            </Button>
          </div>
        ) : (
          ''
        )}
        <Can
          role={this.props.role}
          perform='sku:edit'
          yes={() =>
            id == -1 ? (
              <div
                className={
                  this.state.inputOverlay && this.state.noteError === ''
                    ? ' blur sku-submit'
                    : 'sku-submit'
                }
              >
                {/*add more handling and do it for disabled*/}
                {customerName !== '' &&
                manufacturerName !== '' &&
                brandName !== '' &&
                familyName !== '' &&
                materialGroupName !== '' &&
                parentCore != '' &&
                prototypeName != '' ? (
                  <Button
                    variant='contained'
                    style={{ background: 'rgb(30, 66, 113)', color: 'white' }}
                    onClick={(e) => {
                      {
                        this.state.noteError === ''
                          ? this.setState({ submitButton: true }, () =>
                              this.handleSubmit(e)
                            )
                          : '';
                      }
                    }}
                    disabled={this.state.submitButton}
                  >
                    {id === -1 && skuGroup != undefined ? (
                      <span>Save & Update</span>
                    ) : (
                      <span>Save & Create</span>
                    )}
                  </Button>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )
          }
          no={() => null}
        />
        {id == -1 &&
        this.state.inputOverlay &&
        this.state.noteError === '' &&
        this.state.masterSkuArr.length > 0 ? (
          <InputOverlay
            masterSkuArr={this.state.masterSkuArr}
            closeFxn={this.handleInputOverlay}
            submitBtnFxn={this.handleSubmitBtn}
            dispatch={this.props.dispatch}
            upc={this.state.upcRequired}
            isError={this.props.isError}
            allSkus={allSkus}
            newSkus={skuGroup == undefined && id === -1 ? true : false}
            stagingId={
              this.props.match.params.staging_id
                ? this.props.match.params.staging_id
                : ''
            }
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

SkuForm.defaultProps = {};

SkuForm.propTypes = {
  isLoading: T.bool,
  isError: T.bool,
  saveSuccess: T.bool,
  id: T.number,
  activeSkus: T.array,
  active: T.number,
  brand: T.object,
  family: T.object,
  firmness: T.object,
  sku: T.string,
  core: T.object,
  families: T.array,
  firmnesses: T.array,
  brands: T.array,
  sizes: T.array,
  //   batches: T.array,
  cores: T.array,
  familyName: T.array,
  //   batchId: T.number,
  firmnessName: T.string,
  brandName: T.string,
  parentCoreName: T.string,

  coreTypes: T.array,
  // we need parent_core not coreName
  parentCore: T.object,
  sizeName: T.string,
  customers: T.array,
  ltFoundations: T.array,
  manufacturers: T.array,
  ltMaterials: T.array,
  prototypes: T.array,
  customerName: T.string,
  manufacturerName: T.string,
  prototypeName: T.string,
  materialName: T.string,
  blend: T.number,
  foundationName: T.string,
  ltFoundationGroup: T.object,
  ltMaterialGroup: T.object,
  materialGroupName: T.string,
  foundationGroupName: T.string,
  materialGroups: T.array,
  foundationGroups: T.array,

  // topPanel: T.number,
  topPanelLayers: T.array,
  // gussetPanel: T.number,
  gussetPanelLayers: T.array,
  // sidePanel: T.number,
  sidePanelLayers: T.array,
  // bottomPanel: T.number,
  bottomPanelLayers: T.array,
  coreGroupAttributeId: T.number,

  skuTopPanelAttribute: T.array,
  skuGussetPanelAttribute: T.array,
  skuSidePanelAttribute: T.array,
  skuBottomPanelAttribute: T.array,
  fabricQuiltPatterns: T.array,
  fabricQuiltPattern: T.string,
  parts: T.array,
  boxNames: T.array,
  upc: T.string,
  skuTape: T.array,
  skuAttribute: T.array,
  skuNote: T.string,
  panels: T.array,
  panelLayers: T.array,
  topPanelTicks: T.array,
  sidePanelTicks: T.array,
  bottomPanelTicks: T.array,
  gussetPanelTicks: T.array,
  plastics: T.array,
  zipperParts: T.array,

  openModal: T.bool,
  SuccessModal: T.bool,

  identifier: T.string,
  isTopPanel: T.bool,
  isGussetPanel: T.bool,
  isSidePanel: T.bool,
  isBottomPanel: T.bool,
};

function mapStateToProps(state) {
  const {
    skuForm,
    skuList,
    familyReducer,
    coreFirmnessReducer,
    parentCoreReducer,
    coreTypeReducer,
    brandReducer,
    batchReducer,
    sizeReducer,
    coreReducer,
    customerList,
    foundationList,
    manufacturerList,
    materialList,
    prototypeList,
    materialGroupReducer,
    foundationGroupReducer,
    fabricQuiltPatternReducer,
    partReducer,
    loginForm,
    panelReducer,
    panelLayerReducer,
    skuFormReducer,
  } = state;
  return {
    isError: skuForm.isError,
    saveSuccess: skuForm.saveSuccess,
    allSkus: skuList.allSkus,
    activeSkus: skuList.activeSkus,
    id: skuForm.id,
    active: skuForm.active,
    brand: skuForm.brand,
    family: skuForm.family,
    firmness: skuForm.firmness,
    sku: skuForm.sku,
    skuGroup: skuForm.skuGroup,
    core: skuForm.core,
    parentCore: skuForm.parentCore,
    families: familyReducer.families,
    firmnesses: coreFirmnessReducer.firmnesses,
    brands: brandReducer.brands,
    sizes: sizeReducer.sizes,
    // batches: batchReducer.batches,
    cores: coreReducer.cores,
    coreTypes: coreTypeReducer.coreTypes,
    parentCores: parentCoreReducer.parentCores,
    familyName: skuForm.familyName,
    batchId: skuForm.batchId,
    firmnessName: skuForm.firmnessName,
    brandName: skuForm.brandName,
    parentCoreName: skuForm.parentCoreName,
    sizeName: skuForm.sizeName,
    customers: customerList.customers,
    ltFoundations: foundationList.foundations,
    manufacturers: manufacturerList.manufacturers,
    ltMaterials: materialList.materials,
    prototypes: prototypeList.prototypes,
    prototype: skuForm.prototype,
    customerName: skuForm.customerName,
    manufacturerName: skuForm.manufacturerName,
    prototypeName: skuForm.prototypeName,
    materialName: skuForm.materialName,
    blend: skuForm.blend,
    foundationName: skuForm.foundationName,
    ltFoundationGroup: skuForm.ltFoundationGroup,
    ltMaterialGroup: skuForm.ltMaterialGroup,
    materialGroupName: skuForm.materialGroupName,
    foundationGroupName: skuForm.foundationGroupName,
    materialGroups: materialGroupReducer.materialGroups,
    foundationGroups: foundationGroupReducer.foundationGroups,
    //    fabrics
    topPanel: skuForm.topPanel,
    topPanelLayers: skuForm.topPanelLayers,
    gussetPanel: skuForm.gussetPanel,
    gussetPanelLayers: skuForm.gussetPanelLayers,
    sidePanel: skuForm.sidePanel,
    sidePanelLayers: skuForm.sidePanelLayers,
    bottomPanel: skuForm.bottomPanel,
    bottomPanelLayers: skuForm.bottomPanelLayers,
    coreGroupAttributeId: skuForm.coreGroupAttributeId,

    skuTopPanelAttribute: skuForm.skuTopPanelAttribute,
    skuGussetPanelAttribute: skuForm.skuGussetPanelAttribute,
    skuSidePanelAttribute: skuForm.skuSidePanelAttribute,
    skuBottomPanelAttribute: skuForm.skuBottomPanelAttribute,
    fabricQuiltPatterns: fabricQuiltPatternReducer.fabricQuiltPatterns,
    fabricQuiltPattern: fabricQuiltPatternReducer.fabricQuiltPattern,
    parts: partReducer.parts,
    boxNames: skuForm.boxNames,
    boxObjs: skuForm.boxObjs,
    frNames: skuForm.frNames,
    frObjs: skuForm.frObjs,
    zcNames: skuForm.zcNames,
    zcObjs: skuForm.zcObjs,
    upc: skuForm.upc,
    skuTape: skuForm.skuTape,
    skuAttribute: skuForm.skuAttribute,
    skuCoreAttribute: skuForm.skuCoreAttribute,
    role: loginForm.userRole,
    skuNote: skuForm.skuNote,
    panels: panelReducer.panels,
    panelLayers: panelLayerReducer.panelLayers,
    topPanelTicks: panelLayerReducer.topPanelTicks,
    sidePanelTicks: panelLayerReducer.sidePanelTicks,
    bottomPanelTicks: panelLayerReducer.bottomPanelTicks,
    gussetPanelTicks: panelLayerReducer.gussetPanelTicks,

    batchList: skuForm.batchList,
    sizeList: skuForm.sizeList,
    firmnessList: skuForm.firmnessList,
    draft: skuForm.draft,
    tableSkuList: skuForm.tableSkuList,
    topQuiltPattern: skuForm.topQuiltPattern,
    sideQuiltPattern: skuForm.sideQuiltPattern,
    sideQuiltPattern2: skuForm.sideQuiltPattern2,
    gussetQuiltPattern: skuForm.gussetQuiltPattern,
    bottomQuiltPattern: skuForm.bottomQuiltPattern,
    topTape: skuForm.topTape,
    sideTape: skuForm.sideTape,
    bottomTape: skuForm.bottomTape,
    plastics: skuForm.plastics,
    zipperParts: skuForm.zipperParts,

    openModal: skuForm.openModal,
    identifier: skuForm.identifier,
    isTopPanel: skuForm.isTopPanel,
    isGussetPanel: skuForm.isGussetPanel,
    isSidePanel: skuForm.isSidePanel,
    isBottomPanel: skuForm.isBottomPanel,
    //SuccessModal: skuFormReducer.SuccessModal,
  };
}

export default withRouter(connect(mapStateToProps)(SkuForm));
