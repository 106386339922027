import * as types from "../constants/scheduleTypes";
import { RSAA } from "redux-api-middleware";
import { removeEmptyFields } from "../utils/object";
import { API_ROOT } from "../utils/api";

export function updateField(name, value) {
  return {
    type: types.SCHEDULE_UPDATE_FIELD,
    name,
    value
  };
}

export function getSchedule(date) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule/flat/` + date, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_LOADING_REQUEST,
        types.SCHEDULE_LOADING_SUCCESS,
        types.SCHEDULE_LOADING_ERROR
      ]
    }
  };
}

export function deleteSchedule(deletes, date) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule`, // TODO: make base URL constant
      method: "PATCH",
      body: JSON.stringify({
        schedule: deletes,
        userId: localStorage.getItem("userId"),
        selectedDate: date
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_DELETING_REQUEST,
        types.SCHEDULE_DELETING_SUCCESS,
        types.SCHEDULE_DELETING_ERROR
      ]
    }
  };
}

export function saveScheduleList(schedule) {
  // const data = [];
  let saveObj = {};
  if (schedule.bypassFilter === true) {
    saveObj = { ...schedule };
  } else {
    const data = [];
    for (let field in schedule.data) {
      let row = removeEmptyFields(schedule.data[field]);
      data.push(row);
    }
    const csvObj = {
      data: data,
      date: schedule.date
    };
    saveObj = { ...csvObj };
  }
  // for (let field in schedule.data) {
  //   let row = removeEmptyFields(schedule.data[field]);
  //   data.push(row);
  // }
  // const saveObj = {
  //   data: data,
  //   date: schedule.date,
  // };
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedules`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(saveObj),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_SAVING_REQUEST,
        types.SCHEDULE_SAVING_SUCCESS,
        types.SCHEDULE_SAVING_ERROR
      ]
    }
  };
}

export function getScheduleSkus() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule-skus`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_GET_SKUS_REQUEST,
        types.SCHEDULE_GET_SKUS_SUCCESS,
        types.SCHEDULE_GET_SKUS_ERROR
      ]
    }
  };
}
export function findComponentBySerial(serial, status) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/component/schedule/serial/${serial}/${status}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_GET_SKUS_REQUEST,
        types.SCHEDULE_GET_SKUS_SUCCESS,
        types.SCHEDULE_GET_SKUS_ERROR
      ]
    }
  };
}
export function findBySerial(serial, status) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule/serial/${serial}/${status}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_GET_SKUS_REQUEST,
        types.SCHEDULE_GET_SKUS_SUCCESS,
        types.SCHEDULE_GET_SKUS_ERROR
      ]
    }
  };
}
export function findById(serialid, status) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule/serial/${serialid}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_GET_SKUS_REQUEST,
        types.SCHEDULE_GET_SKUS_SUCCESS,
        types.SCHEDULE_GET_SKUS_ERROR
      ]
    }
  };
}
export function findComponentBySchedulelId(scheduleId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/component/schedule/schedule/${scheduleId}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_GET_SKUS_REQUEST,
        types.SCHEDULE_GET_SKUS_SUCCESS,
        types.SCHEDULE_GET_SKUS_ERROR
      ]
    }
  };
}

export function skuInfoCheck(skus) {
  const skusObj = { skus: skus };
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sku-info-check`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(skusObj),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_SKU_CHECK_REQUEST,
        types.SCHEDULE_SKU_CHECK_SUCCESS,
        types.SCHEDULE_SKU_CHECK_ERROR
      ]
    }
  };
}

export function getFlatPackSchedule(statusId) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule/flatpack`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify({ statusId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_LOADING_REQUEST,
        types.SCHEDULE_LOADING_SUCCESS,
        types.SCHEDULE_LOADING_ERROR
      ]
    }
  };
}
export function getScheduleByTruckAndStop(data) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule/flatpack/${data.truck_number}/${data.stop_number}`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_LOADING_REQUEST,
        types.SCHEDULE_LOADING_SUCCESS,
        types.SCHEDULE_LOADING_ERROR
      ]
    }
  };
}
export function updateSchedule(schedule) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule/update`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(schedule),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_LOADING_REQUEST,
        types.SCHEDULE_LOADING_SUCCESS,
        types.SCHEDULE_LOADING_ERROR
      ]
    }
  };
}
export function updateFlatPackSchedule(schedule) {
  console.log("schedule", schedule, API_ROOT);
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule/updateflatpack`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(schedule),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_LOADING_REQUEST,
        types.SCHEDULE_LOADING_SUCCESS,
        types.SCHEDULE_LOADING_ERROR
      ]
    }
  };
}
export function getClosedTrucks(data) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/schedule/closed_trucks`, // TODO: make base URL constant
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SCHEDULE_LOADING_REQUEST,
        types.SCHEDULE_LOADING_SUCCESS,
        types.SCHEDULE_LOADING_ERROR
      ]
    }
  };
}
