import React  from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import {getManufacturers, saveManufacturerList} from "../../actions/manufacturerActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";
import Can from "../Can";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

class ManufacturerList extends React.Component {

    getOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <Can
                        role={this.props.role}
                        perform='sku:edit'
                        yes={() => (
                            <AddNewButton
                                entity={"manufacturer/-1"}
                                history={this.props.history}
                                data={data}
                            />
                        )}
                        no={() => null}
                    />
                )
            }
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            manufacturers: [],
            rows: [],
            cols: [],
            columns: [],
            filename: '',
            file: '',
        }
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('manufacturer'))
    }

    //Toolbar functions
    handleManufacturerSaveClick = () => {
        const {
            dispatch,
            manufacturers,
        } = this.props

        const data = {
            data: manufacturers.concat(this.state.manufacturers),
            filename: this.state.filename,
        }

        dispatch(saveManufacturerList(data))
        this.setState({
            cols: [],
            rows: [],
            manufacturers: [],
            filename: '',
        })
    }
    handleManufacturerDeleteClick = () => {

        this.setState({
            cols: [],
            rows: [],
            schedule: [],
        })
    }
    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {

        this.getColumns()

        const {
            dispatch,
        } = this.props

        dispatch(getManufacturers())
    }

    render() {
        const {
            isLoading,
            manufacturers,
            columns,
        } = this.props

        return (
            <div className="baseContainer manufacturerList">
                <h1>
                </h1>
                <MUIDataTable
                    title={"Manufacturers"}
                    data={manufacturers.concat(this.state.manufacturers)}
                    columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    options={this.getOptions(manufacturers.concat(this.state.manufacturers))}
                />
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

ManufacturerList.defaultProps = {
    date: getDBFormattedDate()
}

ManufacturerList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    manufacturers: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { manufacturerList, loginForm } = state
    return {
        isLoading: manufacturerList.isLoading,
        manufacturers: manufacturerList.manufacturers,
        rows: manufacturerList.rows,
        cols: manufacturerList.cols,
        columns: manufacturerList.columns,
        file: manufacturerList.file,
        role: loginForm.userRole
    }
}

export default withRouter(connect(mapStateToProps)(ManufacturerList))