import React from "react";
import T from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

import {
  getFoundation,
  saveFoundation,
  updateField
} from "../../actions/foundationActions";
import AddNewButton from "../global/AddNewButton";
import Can from "../Can";

class FoundationForm extends React.Component {
  constructor(props) {
    super(props);
    // Bind the this context to the handler function
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getFoundation();
  }
  getFoundation = (e) => {
    const { dispatch, isLoading } = this.props;

    const id = this.props.match.params.id;
    if (id) {
      dispatch(getFoundation(id));
    }
  };

  handleChange = (e) => {
    const { dispatch } = this.props;
    dispatch(updateField(e.target.name, e.target.value));
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.saveSuccess) {
      this.setState({ open: true });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  state = {
    open: false,
    vertical: "top",
    horizontal: "center"
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { isError, dispatch, id, sku, description } = this.props;

    const foundation = {
      id: id,
      sku: sku,
      description: description != null ? description : ""
    };
    if (!id || id == -1) {
      delete foundation.id;
    }
    await dispatch(saveFoundation(foundation));
    if (!isError) {
      this.props.history.push("/foundations");
    }
  };

  render() {
    const { isLoading, isError, saveSuccess, id, sku, description } =
      this.props;

    return (
      <div className="baseContainer projectForm">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.open}
          className="success"
          onClose={this.handleClose}
          autoHideDuration={2000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Foundation saved</span>}
        />
        <h1>{sku}</h1>
        <div>
          <TextField
            label="Sku"
            name="sku"
            margin="normal"
            variant="outlined"
            className="name-field"
            value={sku}
            onChange={this.handleChange}
          />
          <TextField
            label="Description"
            name="description"
            margin="normal"
            variant="outlined"
            className="name-field"
            value={description}
            onChange={this.handleChange}
          />
        </div>
        <Can
          role={this.props.role}
          perform="sku:edit"
          yes={() => (
            <div style={{ marginTop: 20 }}>
              <Button
                variant="contained"
                style={{ background: "rgb(30, 66, 113)", color: "white" }}
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </div>
          )}
          no={() => null}
        />
      </div>
    );
  }
}

FoundationForm.defaultProps = {};

FoundationForm.propTypes = {
  isLoading: T.bool,
  isError: T.bool,
  saveSuccess: T.bool,
  id: T.number,
  sku: T.string,
  description: T.string
};

function mapStateToProps(state) {
  const { foundationForm, loginForm } = state;
  return {
    isError: foundationForm.isError,
    saveSuccess: foundationForm.saveSuccess,
    id: foundationForm.id,
    sku: foundationForm.sku,
    description: foundationForm.description,
    role: loginForm.userRole
  };
}

export default withRouter(connect(mapStateToProps)(FoundationForm));
