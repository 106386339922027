import * as types from '../constants/coreLayerTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateLayerField(name, value) {
    return {
        type: types.CORE_LAYER_UPDATE_FIELD,
        name,
        value,
    }
}

export function layerAdded(layer, layers) {
    return {
        type: types.CORE_LAYER_ADDED,
    }
}

export function getCoreLayers() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/layers`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_LAYERS_LOADING_REQUEST,
                types.CORE_LAYERS_LOADING_SUCCESS,
                types.CORE_LAYERS_LOADING_ERROR,
            ],
        },
    }
}

export function getCoreLayer(id, coreId) {
    if (id > -1) {
        return {
            [RSAA]: {
                endpoint: `${API_ROOT}/mat-core/layer/${id}`, // TODO: make base URL constant
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                options: {
                    mode: 'cors',
                },
                types: [
                    types.CORE_LAYER_LOADING_REQUEST,
                    types.CORE_LAYER_LOADING_SUCCESS,
                    types.CORE_LAYER_LOADING_ERROR,
                ],
            },
        }
    } else {
        return {
            id: -1,
            core_id: coreId,
            core_layer: 0,
            layer_depth_in: 0,
            material: {},
            part: {},
            raw_material_id: -1,
            part_id: -1,
            type: types.CORE_LAYER_NEW_SUCCESS,
        }
    }
}

export function saveCoreLayerList(coreLayers){
    const data = []
    for (let field in coreLayers.data) {
        let row = removeEmptyFields(coreLayers.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/layers`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_LAYERS_SAVING_REQUEST,
                types.CORE_LAYERS_SAVING_SUCCESS,
                types.CORE_LAYERS_SAVING_ERROR,
            ],
        },
    }
}

export function saveCoreLayer(coreLayer){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/mat-core/layer`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(coreLayer),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.CORE_LAYER_SAVING_REQUEST,
                types.CORE_LAYER_SAVING_SUCCESS,
                types.CORE_LAYER_SAVING_ERROR,
            ],
        },
    }
}
/* NEW update core layer */
export function updateCoreLayer(coreLayer){
  console.log(coreLayer);
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/mat-core/layer/${coreLayer.id}`, // TODO: make base URL constant
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      options: {
        mode: 'cors',
      },
      types: [
        types.CORE_LAYER_SAVING_REQUEST,
        types.CORE_LAYER_SAVING_SUCCESS,
        types.CORE_LAYER_SAVING_ERROR,
      ],
    },
  }
}
/* NEW delete core layer */
export function deleteCoreLayer(coreLayerId){
    return {
      [RSAA]: {
        endpoint: `${API_ROOT}/mat-core/layer/${coreLayerId}`, // TODO: make base URL constant
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        options: {
          mode: 'cors',
        },
        types: [
          types.CORE_LAYER_DELETE_REQUEST,
          types.CORE_LAYER_DELETE_SUCCESS,
          types.CORE_LAYER_DELETE_ERROR,
        ],
      },
    }
}