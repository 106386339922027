
export const FOUNDATION_GROUP_UPDATE_FIELD = 'FOUNDATION_GROUP_UPDATE_FIELD'

export const FOUNDATION_GROUP_LOADING_REQUEST = 'FOUNDATION_GROUP_LOADING_REQUEST'
export const FOUNDATION_GROUP_LOADING_SUCCESS = 'FOUNDATION_GROUP_LOADING_SUCCESS'
export const FOUNDATION_GROUP_LOADING_ERROR = 'FOUNDATION_GROUP_LOADING_ERROR'

export const FOUNDATION_GROUPS_LOADING_REQUEST = 'FOUNDATION_GROUPS_LOADING_REQUEST'
export const FOUNDATION_GROUPS_LOADING_SUCCESS = 'FOUNDATION_GROUPS_LOADING_SUCCESS'
export const FOUNDATION_GROUPS_LOADING_ERROR = 'FOUNDATION_GROUPS_LOADING_ERROR'

export const FOUNDATION_GROUP_IMPORT_LOADING_REQUEST = 'FOUNDATION_GROUP_IMPORT_LOADING_REQUEST'
export const FOUNDATION_GROUP_IMPORT_LOADING_SUCCESS = 'FOUNDATION_GROUP_IMPORT_LOADING_SUCCESS'
export const FOUNDATION_GROUP_IMPORT_LOADING_ERROR = 'FOUNDATION_GROUP_IMPORT_LOADING_ERROR'

export const FOUNDATION_GROUP_SAVING_REQUEST = 'FOUNDATION_GROUP_SAVING_REQUEST'
export const FOUNDATION_GROUP_SAVING_SUCCESS = 'FOUNDATION_GROUP_SAVING_SUCCESS'
export const FOUNDATION_GROUP_SAVING_ERROR = 'FOUNDATION_GROUP_SAVING_ERROR'

export const FOUNDATION_GROUPS_SAVING_REQUEST = 'FOUNDATION_GROUPS_SAVING_REQUEST'
export const FOUNDATION_GROUPS_SAVING_SUCCESS = 'FOUNDATION_GROUPS_SAVING_SUCCESS'
export const FOUNDATION_GROUPS_SAVING_ERROR = 'FOUNDATION_GROUPS_SAVING_ERROR'

export const FG_FOUNDATIONS_LOADING_REQUEST = 'FG_FOUNDATIONS_LOADING_REQUEST'
export const FG_FOUNDATIONS_LOADING_SUCCESS = 'FG_FOUNDATIONS_LOADING_SUCCESS'
export const FG_FOUNDATIONS_LOADING_ERROR = 'FG_FOUNDATIONS_LOADING_ERROR'

export const ADD_FOUNDATION= 'ADD_FOUNDATION'
export const FOUNDATION_ADDED = 'FOUNDATION_ADDED'
export const REMOVE_FOUNDATION = 'REMOVE_FOUNDATION'
export const FOUNDATION_REMOVED = 'FOUNDATION_REMOVED'