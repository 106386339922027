import * as types from '../constants/prototypeTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.PROTOTYPE_UPDATE_FIELD,
        name,
        value,
    }
}

export function getPrototypes() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/prototypes`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PROTOTYPES_LOADING_REQUEST,
                types.PROTOTYPES_LOADING_SUCCESS,
                types.PROTOTYPES_LOADING_ERROR,
            ],
        },
    }
}

export function getPrototype(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/prototype/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PROTOTYPE_LOADING_REQUEST,
                types.PROTOTYPE_LOADING_SUCCESS,
                types.PROTOTYPE_LOADING_ERROR,
            ],
        },
    }
}

export function savePrototypeList(prototypes){
    const data = []
    for (let field in prototypes.data) {
        let row = removeEmptyFields(prototypes.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/prototypes`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PROTOTYPES_SAVING_REQUEST,
                types.PROTOTYPES_SAVING_SUCCESS,
                types.PROTOTYPES_SAVING_ERROR,
            ],
        },
    }
}

export function savePrototype(prototype){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/prototype`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(prototype),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.PROTOTYPE_SAVING_REQUEST,
                types.PROTOTYPE_SAVING_SUCCESS,
                types.PROTOTYPE_SAVING_ERROR,
            ],
        },
    }
}