
import {
    DEALER_UPDATE_FIELD,
    DEALER_LOADING_REQUEST,
    DEALER_LOADING_SUCCESS,
    DEALER_LOADING_ERROR,
    DEALER_SAVING_REQUEST,
    DEALER_SAVING_SUCCESS,
    DEALER_SAVING_ERROR,
} from '../constants/dealerTypes'

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    account_id: null,
    dealer_code: '',
    cfs_dealer_id: '',
    dealer_name: '',
    first_name: '',
    last_name: '',
    company_name: '',
    main_phone: '',
    main_email: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    deleted_by: '',
    deleted_at: '',
}

export default function dealerFormReducer(state = initialState, action) {
    switch (action.type) {
        case DEALER_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case DEALER_LOADING_REQUEST:
        case DEALER_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case DEALER_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                account_id: action.payload.account_id,
                dealer_code: action.payload.dealer_code,
                cfs_dealer_id: action.payload.cfs_dealer_id,
                dealer_name: action.payload.dealer_name,
                first_name: action.payload.first_name,
                last_name: action.payload.last_name,
                company_name: action.payload.company_name,
                main_phone: action.payload.main_phone,
                main_email: action.payload.main_email,
                street: action.payload.street,
                city: action.payload.city,
                state: action.payload.state,
                zip: action.payload.zip,
                deleted_by: action.payload.deleted_by,
                deleted_at: action.payload.deleted_at,
            }
        case DEALER_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                account_id: null,
                dealer_code: '',
                cfs_dealer_id: '',
                dealer_name: '',
                first_name: '',
                last_name: '',
                company_name: '',
                main_phone: '',
                main_email: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                deleted_by: '',
                deleted_at: '',
            }
        case DEALER_LOADING_ERROR:
        case DEALER_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}