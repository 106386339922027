
export const FABRIC_LAYER_TYPE_UPDATE_FIELD = 'FABRIC_LAYER_TYPE_UPDATE_FIELD'

export const FABRIC_LAYER_TYPE_LOADING_REQUEST = 'FABRIC_LAYER_TYPE_LOADING_REQUEST'
export const FABRIC_LAYER_TYPE_LOADING_SUCCESS = 'FABRIC_LAYER_TYPE_LOADING_SUCCESS'
export const FABRIC_LAYER_TYPE_LOADING_ERROR = 'FABRIC_LAYER_TYPE_LOADING_ERROR'

export const FABRIC_LAYER_TYPES_LOADING_REQUEST = 'FABRIC_LAYER_TYPES_LOADING_REQUEST'
export const FABRIC_LAYER_TYPES_LOADING_SUCCESS = 'FABRIC_LAYER_TYPES_LOADING_SUCCESS'
export const FABRIC_LAYER_TYPES_LOADING_ERROR = 'FABRIC_LAYER_TYPES_LOADING_ERROR'

export const FABRIC_LAYER_TYPE_IMPORT_LOADING_REQUEST = 'FABRIC_LAYER_TYPE_IMPORT_LOADING_REQUEST'
export const FABRIC_LAYER_TYPE_IMPORT_LOADING_SUCCESS = 'FABRIC_LAYER_TYPE_IMPORT_LOADING_SUCCESS'
export const FABRIC_LAYER_TYPE_IMPORT_LOADING_ERROR = 'FABRIC_LAYER_TYPE_IMPORT_LOADING_ERROR'

export const FABRIC_LAYER_TYPE_SAVING_REQUEST = 'FABRIC_LAYER_TYPE_SAVING_REQUEST'
export const FABRIC_LAYER_TYPE_SAVING_SUCCESS = 'FABRIC_LAYER_TYPE_SAVING_SUCCESS'
export const FABRIC_LAYER_TYPE_SAVING_ERROR = 'FABRIC_LAYER_TYPE_SAVING_ERROR'

export const FABRIC_LAYER_TYPES_SAVING_REQUEST = 'FABRIC_LAYER_TYPES_SAVING_REQUEST'
export const FABRIC_LAYER_TYPES_SAVING_SUCCESS = 'FABRIC_LAYER_TYPES_SAVING_SUCCESS'
export const FABRIC_LAYER_TYPES_SAVING_ERROR = 'FABRIC_LAYER_TYPES_SAVING_ERROR'
