
export const FABRIC_USE_UPDATE_FIELD = 'FABRIC_USE_UPDATE_FIELD'

export const FABRIC_USE_LOADING_REQUEST = 'FABRIC_USE_LOADING_REQUEST'
export const FABRIC_USE_LOADING_SUCCESS = 'FABRIC_USE_LOADING_SUCCESS'
export const FABRIC_USE_LOADING_ERROR = 'FABRIC_USE_LOADING_ERROR'

export const FABRIC_USES_LOADING_REQUEST = 'FABRIC_USES_LOADING_REQUEST'
export const FABRIC_USES_LOADING_SUCCESS = 'FABRIC_USES_LOADING_SUCCESS'
export const FABRIC_USES_LOADING_ERROR = 'FABRIC_USES_LOADING_ERROR'

export const FABRIC_USE_IMPORT_LOADING_REQUEST = 'FABRIC_USE_IMPORT_LOADING_REQUEST'
export const FABRIC_USE_IMPORT_LOADING_SUCCESS = 'FABRIC_USE_IMPORT_LOADING_SUCCESS'
export const FABRIC_USE_IMPORT_LOADING_ERROR = 'FABRIC_USE_IMPORT_LOADING_ERROR'

export const FABRIC_USE_SAVING_REQUEST = 'FABRIC_USE_SAVING_REQUEST'
export const FABRIC_USE_SAVING_SUCCESS = 'FABRIC_USE_SAVING_SUCCESS'
export const FABRIC_USE_SAVING_ERROR = 'FABRIC_USE_SAVING_ERROR'

export const FABRIC_USES_SAVING_REQUEST = 'FABRIC_USES_SAVING_REQUEST'
export const FABRIC_USES_SAVING_SUCCESS = 'FABRIC_USES_SAVING_SUCCESS'
export const FABRIC_USES_SAVING_ERROR = 'FABRIC_USES_SAVING_ERROR'
