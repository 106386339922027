import React from "react"
import '../../styles/shipping.scss';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip"
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { withStyles } from "@material-ui/core/styles"

const defaultToolbarStyles = {
};

class ShippingOrdersListSelectedToolbar extends React.Component {

  render() {
    const {
      onClick,
      selectedRows,
    } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Mark Shipped"}>
          <IconButton onClick={() => onClick(selectedRows)}>
            <AssignmentTurnedInIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbarSelect" })(ShippingOrdersListSelectedToolbar);
