import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {getErrorLogs, closeError, doReprocess} from "../../actions/fishbowlActions";
import {TableCell, TableHead, TableBody, Tooltip} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import MUIDataTable from "mui-datatables";
import {getColumns} from "../../actions/globalActions";
import Can from "../Can";
import AddNewButton from "../global/AddNewButton";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

class FishbowlReprocessingForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        this.getErrorOpenList()
        this.getColumns()
    }

    getErrorOpenList() {
        const {dispatch} = this.props
        dispatch(getErrorLogs())
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('reprocessing'))
    }


    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    handleReprocess = async (rowData) => {
        const{dispatch, reprocessResult, isError} = this.props
        await this.setState({errorId: null, errorCode: '', reprocessErrorResult: ''})
        // console.log(rowData)
        let error ={
            id: rowData[11],
            errorId: rowData[0],
            payload: rowData[12] != undefined ? rowData[12].filter(obj => obj != null) : rowData[12],
            functionName: rowData[9],
            deviceId: rowData[2],
            userId: rowData[10]
        }
        await this.setState({errorId: rowData[0]})
        await this.setState({errorCode: rowData[5]})
        if(error.id != undefined && error.payload != undefined){
            await dispatch(doReprocess(error))
            await this.handleReprocessResult()
        } else {
            this.setState({reprocessErrorResult: 'no payload to reprocess'})
        }
    }

    handleReprocessResult = async () => {
        const {reprocessResult} = this.props
        //if result has success then get refreshed list of open results
        if(reprocessResult.Err.includes('1000')){
            this.getErrorOpenList()
        }
        //else show user the reprocess result
        else if(reprocessResult.Err.includes(this.state.errorCode)){
            this.setState({reprocessErrorResult: 'Error has not been resolved'})
        } else{
            this.setState({reprocessErrorResult: reprocessResult.Err})
        }
    }

    handleCloseError = async (rowData) => {
        const {dispatch} = this.props
        let error = {
            id: rowData[0],
            resolved: 1
        }
        await dispatch(closeError(error))
        await this.getErrorOpenList()
    }

    getOptions(data) {
        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            expandableRows: true,
            renderExpandableRow: (rowData, rowMeta) => {
                // console.log(rowData, 'rowData')
                let payload = rowData[12].props.children
                // console.log(payload, 'payload')
                return (
                    payload !== undefined ?
                        <tr>
                        <td colSpan={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{padding: '10px', background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold'}}>
                                            Part Number
                                        </TableCell>
                                        <TableCell style={{padding: '10px', background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold'}}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{padding: '10px', background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold'}}>
                                            Quantity
                                        </TableCell>
                                        <TableCell style={{padding: '10px', background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold'}}>
                                            UOM
                                        </TableCell>
                                        <TableCell style={{padding: '10px', background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold'}}>
                                            Cost
                                        </TableCell>
                                        <TableCell style={{padding: '10px', background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold'}}>
                                            Note
                                        </TableCell>
                                        <TableCell style={{padding: '10px', background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold'}}>
                                            Begin Location
                                        </TableCell>
                                        <TableCell style={{padding: '10px', background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold'}}>
                                            End Location
                                        </TableCell>
                                        <TableCell style={{padding: '10px', background: 'rgb(30, 66, 113)', color: 'white', fontWeight: 'bold'}}>
                                            Location
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {payload.map(item => {
                                    return (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{item != null ? item.PartNumber : 'null'}</TableCell>
                                                <TableCell>{item != null ? item.PartDescription : 'null'}</TableCell>
                                                <TableCell>{item != null ? item.Qty : 'null'}</TableCell>
                                                <TableCell>{item != null ? item.Uom : 'null'}</TableCell>
                                                <TableCell>{item != null ? item.Cost : 'null'}</TableCell>
                                                <TableCell>{item != null ? item.Note : 'null'}</TableCell>
                                                <TableCell>{item != null ? item.BeginLocation : 'null'}</TableCell>
                                                <TableCell>{item != null ? item.EndLocation : 'null'}</TableCell>
                                                <TableCell>{item != null ? item.Location : 'null'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        )
                                })}
                            </Table>

                        </TableContainer>
                        </td>
                        </tr>
                    : 'No Payload Available'
                );
            },
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <Can
                        role={this.props.role}
                        perform='sku:edit'
                        yes={() => (
                            <AddNewButton
                                entity={"sku/-1"}
                                history={this.props.history}
                                data={data}
                            />
                        )}
                        no={() => null}
                    />
                )
            },
        }

        return options
    }


    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
        //give user information about result of reprocess
        reprocessErrorResult: '',
        //allows us to display reprocess error result in the correct row
        errorId: null,
        //allows us to check if the response from reprocess is the same or new
        errorCode: ''
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,

        } = this.props



    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            reprocessResult,
            errorLoad,
            columns
        } = this.props

        // console.log(errorLoad)

        let tableColumns = columns != undefined ?[...columns,
            {
                label: "REPROCESS",
                name: "Reprocess",
                key: "Reprocess",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span>
                            <Button
                                variant="contained"
                                style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                                onClick={() => {this.handleReprocess(tableMeta.rowData)}}
                                // disabled={true}
                            >
                                Reprocess
                            </Button>
                                <div style={{marginTop: 10, color: 'red', fontWeight: 'bold'}}>{this.state.errorId == tableMeta.rowData[0] ? this.state.reprocessErrorResult : ''}</div>
                            </span>
                        );
                    },
                },
            },
            {
                label: "Close ERROR",
                name: "Close",
                key: "Close",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Button
                                variant="contained"
                                style={{background: 'red', color: 'white'}}
                                onClick={() => {this.handleCloseError(tableMeta.rowData)}}
                                // disabled={true}
                            >
                                Close
                            </Button>
                        );
                    },
                },
            }] : []
        return (
            <div className="baseContainer CycleCountForm">
                <div style={{marginBottom: 20}}>
                    <MUIDataTable
                        title={"Open Fishbowl Errors"}
                        data={errorLoad}
                        columns={tableColumns.map(c => ({ ...c, ...defaultColumnProperties }))}
                        options={this.getOptions(errorLoad)}

                    />
                </div>


            </div>
        )
    }
}

FishbowlReprocessingForm.defaultProps = {

}

FishbowlReprocessingForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,

}

function mapStateToProps(state) {
    const { fishbowlReducer } = state
    return {
        isError: fishbowlReducer.isError,
        saveSuccess: fishbowlReducer.saveSuccess,
        errorLoad: fishbowlReducer.errorLoad,
        columns: fishbowlReducer.columns,
        reprocessResult: fishbowlReducer.reprocessResult

    }
}

export default withRouter(connect(mapStateToProps)(FishbowlReprocessingForm))