import {
    BATCHES_LOADING_REQUEST,
    BATCHES_LOADING_SUCCESS,
    BATCHES_LOADING_ERROR
} from '../constants/batchTypes'

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    batches: [],
    columns: [],
    rows: [],
    id: -1
}

export default function batchReducer(state = initialState, action) {
    switch (action.type) {
        case BATCHES_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case BATCHES_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case BATCHES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                batches: action.payload,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}