
import {
    PROTOTYPE_UPDATE_FIELD,
    PROTOTYPE_LOADING_REQUEST,
    PROTOTYPE_LOADING_SUCCESS,
    PROTOTYPE_LOADING_ERROR,
    PROTOTYPES_LOADING_REQUEST,
    PROTOTYPES_LOADING_SUCCESS,
    PROTOTYPES_LOADING_ERROR,
    PROTOTYPE_IMPORT_LOADING_REQUEST,
    PROTOTYPE_IMPORT_LOADING_SUCCESS,
    PROTOTYPE_IMPORT_LOADING_ERROR,
    PROTOTYPE_SAVING_REQUEST,
    PROTOTYPE_SAVING_SUCCESS,
    PROTOTYPE_SAVING_ERROR,
    PROTOTYPES_SAVING_REQUEST,
    PROTOTYPES_SAVING_SUCCESS,
    PROTOTYPES_SAVING_ERROR,
} from '../constants/prototypeTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";
import {addLink} from "../utils/column";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    prototypes: [],
    prototype: {},
    rows: [],
    cols: [],
    columns: [],
    file: '',
}

export default function prototypeReducer(state = initialState, action) {
    switch (action.type) {
        case PROTOTYPE_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case PROTOTYPE_LOADING_REQUEST:
        case PROTOTYPES_LOADING_REQUEST:
        case PROTOTYPE_IMPORT_LOADING_REQUEST:
        case PROTOTYPE_SAVING_REQUEST:
        case PROTOTYPES_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case PROTOTYPES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                prototypes: action.payload,
            }
        case PROTOTYPE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                prototype: action.payload,
            }
        case PROTOTYPE_IMPORT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                prototypes: action.prototypes,
                rows: action.rows,
                cols: action.cols,
            }
        case PROTOTYPES_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                prototypes: action.payload,
            }
        case PROTOTYPE_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                prototype: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: addLink(action.payload),
            }
        case PROTOTYPE_LOADING_ERROR:
        case PROTOTYPES_LOADING_ERROR:
        case PROTOTYPE_IMPORT_LOADING_ERROR:
        case PROTOTYPE_SAVING_ERROR:
        case PROTOTYPES_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}