import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBatches } from "../../../actions/batchActions";
import MaterialTable from "@material-table/core";
import { getActiveSkus, saveSku } from "../../../actions/skuActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress, TextField, Box, Container } from "@material-ui/core";
import "./style.css";
import SkuModal from "./skuModal";

const SkuPriorityBatch = () => {
  const dispatch = useDispatch();
  const [batches, setBatches] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [sku, setSku] = useState([]);
  const [sName, setSName] = useState([]);
  const [bName, setBname] = useState([]);
  const [sucess, setSucess] = useState("");
  const [sucessValue, setSucessValue] = useState([]);
  const [modalType, setModalType] = useState("");

  useEffect(() => {
    getAllData();
  }, []);

  async function getAllData() {
    let batch = [];
    let skus = [];
    await dispatch(getBatches()).then((res) => {
      setBatches(res.payload);
      batch = res.payload;
    });
    await dispatch(getActiveSkus()).then((res) => {
      setSku(res.payload);
      skus = res.payload;
    });
    dataConfiguration(batch, skus);
  }

  const dataConfiguration = (batch, skus) => {
    let batchName = [];
    let familyName = [];
    let skuName = [];

    batch.filter((batchData) => {
      const batchObj = {
        batch: `${batchData.batch_number} - ${batchData.batch}`
      };
      batchName.push(batchObj.batch);
    });
    skus.filter((sk) => {
      if (sk.active == 1 && sk.category == 1) {
        familyName.push(sk.family.family);
        skuName.push(sk.sku);
      }
    });
    setBname(batchName);
    setSName(skuName);

    let dataObj = [];
    batch.filter((batchData) => {
      skus.filter((skuData) => {
        if (batchData.id == skuData.batch)
          if (skuData.active == 1 && skuData.category == 1) {
            const newSku = {
              sku: skuData.sku,
              skuFamily: skuData.family.family,
              batch: `${batchData.batch_number} - ${batchData.batch}`,
              batchId: batchData.batch_number
            };
            dataObj.push(newSku);
          }
      });
    });
    setTableData(dataObj);
  };

  async function upDateEntry(newData) {
    if (newData != null) {
      let data = newData;
      sku.find((sk) => {
        if (sk.sku === newData.sku) {
          data["id"] = sk.id;
          let str = newData.batch;
          str = str.slice(0, 3);
          batches.find((batch) => {
            if (batch.batch_number == str) {
              let skuData = {
                id: newData.id,
                batch_id: batch.id
              };
              dispatch(saveSku(skuData)).then((res) => {
                if (res.type === "SKU_SAVING_SUCCESS") {
                  getAllData();
                  setSucess([
                    { title: "Sucess!!" },
                    {
                      body: "Batch ha been sucessfully updated!"
                    }
                  ]);
                  setSucessValue(true);
                  setModalType("sucess");
                }
              });
            }
          });
        }
      });
    }
  }

  async function addNewEntry(newData) {
    if (newData != null) {
      let data = newData;
      sku.find((sk) => {
        if (sk.sku === newData.sku) {
          data["id"] = sk.id;
          let str = newData.batch;
          str = str.slice(0, 3);
          batches.find((batch) => {
            if (batch.batch_number == str) {
              let skuData = {
                id: newData.id,
                batch_id: batch.id
              };
              dispatch(saveSku(skuData)).then((res) => {
                if (res.type === "SKU_SAVING_SUCCESS") {
                  getAllData();
                  setSucess([
                    { title: "Sucess!!" },
                    {
                      body: "Batch Assignment sucessfully completed!"
                    }
                  ]);
                  setSucessValue(true);
                  setModalType("sucess");
                }
              });
            }
          });
        }
      });
    }
  }

  const tableColumns = [
    {
      title: "SKU",
      field: "sku",
      editable: "onAdd",
      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => {
        return (
          <Autocomplete
            options={sName}
            getOptionLabel={(batch) => batch.toString()}
            name={"batch"}
            defaultValue={rowData.sku ? rowData.sku : null}
            onChange={(e, value) => {
              e.stopPropagation();
              let getSkuInfo = sku.filter((sk) => {
                return value === sk.sku;
              });
              if (getSkuInfo.length >= 1) {
                getSkuInfo.filter((family) => {
                  let newRowData = {
                    sku: family.sku,
                    skuFamily: family.family.family,
                    batch: null
                  };
                  onRowDataChange(newRowData);
                });
              }
            }}
            renderInput={(params) => (
              <div
                ref={params.InputProps.ref}
                style={{ marginTop: "-10px", marginLeft: "20px" }}
              >
                <TextField
                  name={"batch"}
                  {...params.inputProps}
                  variant="standard"
                  margin="normal"
                />
              </div>
            )}
          />
        );
      },
      render: (rowData) => <p value={"sku"}>{rowData.sku}</p>
    },
    {
      title: "family",
      field: "skuFamily",
      editable: "never",
      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => {
        <p value={"skuFamily"}>{rowData.skuFamily}</p>;
      }
    },
    {
      title: "Batch",
      field: "batch",
      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => {
        return (
          <Autocomplete
            options={bName}
            getOptionLabel={(batch) => batch.toString()}
            name={"batch"}
            defaultValue={rowData.batch ? rowData.batch : null}
            onChange={(e, value) => {
              e.stopPropagation();
              onChange(value);
            }}
            renderInput={(params) => (
              <div
                ref={params.InputProps.ref}
                style={{ marginTop: "-10px", marginLeft: "20px" }}
              >
                <TextField
                  name={"batcn"}
                  {...params.inputProps}
                  variant="standard"
                  margin="normal"
                />
              </div>
            )}
          />
        );
      },
      render: (rowData) => <p value={"batch"}>{rowData.batch}</p>
    }
  ];
  return (
    <div>
      <SkuModal
        open={sucessValue}
        value={sucessValue}
        sucess={sucess}
        modalType={modalType}
      />
      {tableData ? (
        <Container className="table-container">
          <MaterialTable
            className="batch-table"
            style={{ color: "black" }}
            title="Batch SKU Assignment"
            options={{
              pageSize: 20,
              addRowPosition: "first",
              actionsColumnIndex: -1
            }}
            columns={tableColumns}
            data={tableData}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setTableData([...tableData, newData]);
                    addNewEntry(newData);
                    resolve();
                  }, 1000);
                }),

              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...tableData];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setTableData([...dataUpdate]);
                    upDateEntry(newData);
                    resolve();
                  }, 1000);
                })
            }}
          />
        </Container>
      ) : (
        <>
          <Container style={{ justifyContent: "center" }} maxWidth="xl">
            <div className="progress-bar">
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size={200} />
              </Box>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default SkuPriorityBatch;
