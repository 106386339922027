import React  from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import {getBrands, saveBrandList} from "../../actions/brandActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";
import Can from "../Can";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

class BrandList extends React.Component {

    getOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <Can
                        role={this.props.role}
                        perform='sku:edit'
                        yes={() => (
                            <AddNewButton
                                entity={"brand/-1"}
                                history={this.props.history}
                                data={data}
                            />
                        )}
                        no={() => null}
                    />
                )
            }
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            brands: [],
            rows: [],
            cols: [],
            columns: [],
            filename: '',
            file: '',
        }
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('brand'))
    }

    //Toolbar functions
    handleBrandSaveClick = () => {
        const {
            dispatch,
            brands,
        } = this.props

        const data = {
            data: brands.concat(this.state.brands),
            filename: this.state.filename,
        }

        dispatch(saveBrandList(data))
        this.setState({
            cols: [],
            rows: [],
            brands: [],
            filename: '',
        })
    }
    handleBrandDeleteClick = () => {
        this.setState({
            cols: [],
            rows: [],
            schedule: [],
        })
    }
    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {

        this.getColumns()

        const {
            dispatch,
        } = this.props

        dispatch(getBrands())
    }

    render() {
        const {
            isLoading,
            brands,
            columns,
        } = this.props

        return (
            <div className="baseContainer brandList">
                <h1>

                </h1>
                <MUIDataTable
                    title={"Brands"}
                    data={brands.concat(this.state.brands)}
                    columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    options={this.getOptions(brands.concat(this.state.brands))}
                />
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

BrandList.defaultProps = {
    date: getDBFormattedDate()
}

BrandList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    brands: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { brandReducer, loginForm } = state
    return {
        isLoading: brandReducer.isLoading,
        brands: brandReducer.brands,
        rows: brandReducer.rows,
        cols: brandReducer.cols,
        columns: brandReducer.columns,
        file: brandReducer.file,
        role: loginForm.userRole
    }
}

export default withRouter(connect(mapStateToProps)(BrandList))