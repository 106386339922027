import React from 'react'
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

import {
    addFoundation,
    foundationAdded,
    getFoundationGroup, getFoundations, removeFoundation,
    saveFoundationGroup,
    updateField
} from '../../actions/foundationGroupActions'
import Table from "@material-ui/core/Table";
import {TableCell, TableHead, Tooltip} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import FormControl from "@material-ui/core/FormControl";
import TableBody from '@material-ui/core/TableBody';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";

import '../../styles/foundation.css'
import AddNewButton from "../global/AddNewButton";
import Can from "../Can";

class FoundationGroupForm extends React.Component {

    constructor(props) {
        super(props)
        // Bind the this context to the handler function
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        this.getFoundationList()
        this.getFoundationGroup()
    }
    getFoundationList() {
        const{
            dispatch,
        } = this.props

        const id = this.props.match.params.id ?  this.props.match.params.id : -1
        dispatch(getFoundations(id))
    }
    getFoundationGroup = e => {
        const {
            dispatch,
            isLoading,
        } = this.props

        const id = this.props.match.params.id
        if (id) {
            dispatch(getFoundationGroup(id))
        }
    }

    handleChange = e => {
        const { dispatch } = this.props
        dispatch(updateField(e.target.name, e.target.value))
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.saveSuccess) {
            this.setState({ open: true })
        }
    }

    handleAddFoundation = async e => {
        e.preventDefault()
        const {
            dispatch,
            foundationName,
            groupFoundations,
            foundations,
        } = this.props

        const foundation = foundations.find(item => item.sku === foundationName)
        await dispatch(addFoundation(foundation, groupFoundations, foundations))
        await dispatch(foundationAdded())
    }
    handleRemoveFoundation(id){
        const {
            dispatch,
            groupFoundations,
            foundations,
        } = this.props

        const foundation = groupFoundations.find(item => item.id === id)
        dispatch(removeFoundation(foundation, groupFoundations, foundations))
    }
    handleClose = () => {
        this.setState({ open: false })
    }

    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
    }

    handleSubmit = async e => {
        e.preventDefault()
        const {
            isError,
            dispatch,
            id,
            groupName,
            groupFoundations,
            foundations
        } = this.props

        const foundationGroup = {
            id: id,
            group_name: groupName,
            foundations: groupFoundations,
        }
        if(!id || id == -1){
            delete foundationGroup.id
        }
        await dispatch(saveFoundationGroup(foundationGroup))
        if(!isError) {
            this.props.history.push("/lt/foundation/groups")
        }
    }

    render() {

        const {
            isLoading,
            isError,
            saveSuccess,
            id,
            groupName,
            groupFoundations,
            foundationName,
            foundations,
         }= this.props

        return (
            <div className="baseContainer projectForm">
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={this.state.open}
                    className="success"
                    onClose={this.handleClose}
                    autoHideDuration={2000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">FoundationGroup saved</span>}
                />
                <h1>
                    {groupName}
                </h1>
                <div>
                    <TextField
                        label="Group #"
                        name="id"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        type='number'
                        value={id}
                        disabled={true}
                        onChange={this.handleChange}
                    />
                    <TextField
                        label="Group Name"
                        name="groupName"
                        margin="normal"
                        variant="outlined"
                        className="name-field"
                        value={groupName}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <FormControl className={'sku-form-select'}>
                        <InputLabel >Material</InputLabel>
                        <Select
                            className={'sku-select'}
                            name={"foundationName"}
                            placeholder={"Foundation"}
                            value={foundationName}
                            key={foundationName}
                            input={<Input name="foundationName" id="foundationName-placeholder"/>}
                            onChange={this.handleChange}
                        >
                            {foundations.map((option) => <MenuItem key={option.id} value={option.sku}>{option.sku}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {foundationName != '' &&
                    <FormControl className={'foundation-form-control-add-row'}>
                        <Tooltip title={"Add New Foundation"}>
                            <IconButton>
                                <FontAwesomeIcon icon={faPlus} style={{fontSize: '.75em'}}
                                                 onClick={this.handleAddFoundation}/>
                            </IconButton>
                        </Tooltip>
                    </FormControl>
                    }
                </div>
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Sku</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        {groupFoundations.map(f => {
                            return (
                                <TableBody key={f.id}>
                                    <TableRow>
                                        <TableCell>{f.sku}</TableCell>
                                        <TableCell>{f.description}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => this.handleRemoveFoundation(f.id)}>
                                                <FontAwesomeIcon icon={faTimes} style={{fontSize: '.75em'}} id={f.id}  />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )
                        })}
                    </Table>
                </div>
                <Can
                    role={this.props.role}
                    perform='sku:edit'
                    yes={() => (
                        <div>
                            <Button
                                variant="contained"
                                style={{background: 'rgb(30, 66, 113)', color: 'white'}}
                                onClick={this.handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    )}
                    no={() => null}
                />
            </div>
        )
    }
}

FoundationGroupForm.defaultProps = {

}

FoundationGroupForm.propTypes = {
    isLoading: T.bool,
    isError: T.bool,
    saveSuccess: T.bool,
    id: T.number,
    groupName: T.string,
    groupFoundations: T.array,
    foundationName: T.string,
    foundations: T.array,
}

function mapStateToProps(state) {
    const { foundationGroupReducer, loginForm } = state
    return {
        isError: foundationGroupReducer.isError,
        saveSuccess: foundationGroupReducer.saveSuccess,
        id: foundationGroupReducer.id,
        groupName: foundationGroupReducer.groupName,
        groupFoundations: foundationGroupReducer.groupFoundations,
        foundationName: foundationGroupReducer.foundationName,
        foundations: foundationGroupReducer.foundations,
        role: loginForm.userRole
    }
}

export default withRouter(connect(mapStateToProps)(FoundationGroupForm))