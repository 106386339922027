import React from 'react';
import FoundationList from './List/FoundationList';
import '../styles/about-page.css';

export class FoundationListPage extends React.Component {

    render() {
        return (
            <FoundationList/>
        );
    }
}

export default FoundationListPage;
