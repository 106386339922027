
export const LOGIN_UPDATE_FIELD = 'LOGIN_UPDATE_FIELD';

export const LOGIN_SUBMIT_REQUEST = 'LOGIN_SUBMIT_REQUEST';
export const LOGIN_SUBMIT_SUCCESS = 'LOGIN_SUBMIT_SUCCESS';
export const LOGIN_SUBMIT_FAILURE = 'LOGIN_SUBMIT_FAILURE';

export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';

export const CLEAR_USER = 'CLEAR_USER';





