import React from "react";
import LoginForm from "./forms/LoginForm";
import '../styles/about-page.css';

class LoginFormPage extends React.Component {

    render() {
        return (
            <LoginForm/>
        );
    }
}

export default LoginFormPage
