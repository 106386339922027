import * as types from "../../constants/forecastTypes";
import { RSAA } from "redux-api-middleware";
import { API_ROOT } from "../../utils/api";

export function createCampaign(upload) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/create-campaign`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(upload),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}
export function getCampaign() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/campaigns`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}
export function campaignUpdate(upload) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/update-campaign`, // TODO: make base URL constant
      method: "PUT",
      body: JSON.stringify(upload),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}
// curve action

export function createCurve(upload) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/create-forecast-curve`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(upload),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}
export function getCurve() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/get-curve`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}
export function curveUpdate(upload) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/update-curve`, // TODO: make base URL constant
      method: "PUT",
      body: JSON.stringify(upload),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}

// forcast action
export function createForecast(upload) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/forecasts`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(upload),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}
export function getForecast() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/forecast`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}
export function forecastUpdate(forecast) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/update-forecast`, // TODO: make base URL constant
      method: "PUT",
      body: JSON.stringify(forecast),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}

//Subchannel
export function createStoreProcedure(sql) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/store-procedure`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(sql),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}
export function junkStoreProcedure(sql) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/junk-store-procedure`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(sql),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}

export function spNewPlanFromLast(sql) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/sp-from-last`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(sql),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.FORECAST_UPDATE_FIELD,
        types.FORECAST_LOADING_SUCCESS,
        types.FORECAST_LOADING_ERROR
      ]
    }
  };
}

export function getSubchannel() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/subchannel`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SUBCHANNEL_LOADING_REQUEST,
        types.SUBCHANNEL_LOADING_SUCCESS,
        types.SUBCHANNEL_LOADING_ERROR
      ]
    }
  };
}

export function getUpload() {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/get-upload`, // TODO: make base URL constant
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SUBCHANNEL_LOADING_REQUEST,
        types.SUBCHANNEL_LOADING_SUCCESS,
        types.SUBCHANNEL_LOADING_ERROR
      ]
    }
  };
}
export function createUpload(data) {
  return {
    [RSAA]: {
      endpoint: `${API_ROOT}/create-upload`, // TODO: make base URL constant
      method: "POST",
      body: JSON.stringify(data),

      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      types: [
        types.SUBCHANNEL_LOADING_REQUEST,
        types.SUBCHANNEL_LOADING_SUCCESS,
        types.SUBCHANNEL_LOADING_ERROR
      ]
    }
  };
}
