
import {
    FOUNDATION_UPDATE_FIELD,
    FOUNDATION_LOADING_REQUEST,
    FOUNDATION_LOADING_SUCCESS,
    FOUNDATION_LOADING_ERROR,
    FOUNDATION_SAVING_REQUEST,
    FOUNDATION_SAVING_SUCCESS,
    FOUNDATION_SAVING_ERROR,
} from '../constants/foundationTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    id: null,
    sku: '',
    description: '',
}

export default function foundationFormReducer(state = initialState, action) {
    switch (action.type) {
        case FOUNDATION_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case FOUNDATION_LOADING_REQUEST:
        case FOUNDATION_SAVING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case FOUNDATION_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                id: action.payload.id,
                sku: action.payload.sku,
                description: action.payload.description,
            }
        case FOUNDATION_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                id: null,
                sku: '',
                description: '',
            }
        case FOUNDATION_LOADING_ERROR:
        case FOUNDATION_SAVING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}