import rules from "../rbac-rules";

/*
The Can component is wrapped around other components so that only certain roles with
certain perform capabilities are allowed to access them.
The check() function - checks if the user that is logged in, is allowed to access
the component. We map through the role array and add/push a role to the permissions array, if it exists in the rules array.
This allows us to see if the role(s) the user has are allowed to access the component.
Permissions give us an array of each role the user has and is allowed for the component. (basically a version of the rules array)
completePermissions gives us the static arrays off the permissions array - so it is an array of arrays.
Then we make the array or arrays into just one array and reassign to permissions. Now permissions array is a list of all the staticPermissions/actions
that the user can take. Remember static permissions were created in rbac-rules.js
Finally if staticPermissions exist and staticPermissions array includes the action then the Can component does whatever is on the yes prop.
This is normally the component the user is allowed to access after the verification.
If role is not present in the rules, return false, then whatever is on the no prop is executed, which is normally nothing.
Params ->
    rules: is an array of all the roles and their corresponding actions allowed ex: rules [{admin: {static: []}}, {sku_view: {static: []}},... etc]
    this comes directly from the rbac-rules.js file
    role: is an array of roles the user is assigned. this is a prop on the Can component and
    passed to the Can component from the role on the loginForm reducer. see the Header.js for example.
    action: the perform prop on the Can component
https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
visit this link and then ctrl F for 'Can' to find more information
 */

const check = (rules, role, action, data) => {
    let permissions = [];
    //we want to check if the role that the user has is listed in the rules, if so then push
    //this handles roles added to role table and to user_roles table that are not in rbac-rules.js
    role ? role.forEach(r => rules[r] ? permissions.push(rules[r]) : '') : ''
    let completePermissions = permissions != null ? permissions.map(r =>  r.static) : ''
    permissions = completePermissions ? [].concat.apply([], completePermissions) : ''
    if (!permissions) {
        return false;
    }

    const staticPermissions = permissions;

    if (staticPermissions && staticPermissions.includes(action)) {
        return true;
    }

};

const Can = props =>
    check(rules, props.role, props.perform, props.data)
        ? props.yes()
        : props.no();

Can.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Can;