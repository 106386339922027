import React from 'react';
import CoreList from './List/CoreList';
import '../styles/about-page.css';

export class CoreListPage extends React.Component {

    render() {
        return (
            <CoreList/>
        );
    }
}

export default CoreListPage;
