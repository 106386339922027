/* eslint-disable import/no-named-as-default */
import { Route, Switch } from "react-router-dom";
import React from "react";
import { hot } from "react-hot-loader";
import "../styles/app.css";
import PropTypes from "prop-types";
import LoginFormPage from "./LoginFormPage";
import LogoutPage from "./LogoutPage";
import HomePage from "./HomePage";
import DashboardPage from "./DashboardPage";
import ScheduleListPage from "./ScheduleListPage";
import UserListPage from "./UserListPage";
import NotFoundPage from "./NotFoundPage";
import Header from "./header/Header";
import UserFormPage from "./UserFormPage";
import SkuListPage from "./SkuListPage";
import SkuFormPage from "./SkuFormPage";
import CustomerListPage from "./CustomerListPage";
import CustomerFormPage from "./CustomerFormPage";
import FoundationListPage from "./FoundationListPage";
import FoundationFormPage from "./FoundationFormPage";
import ManufacturerListPage from "./ManufacturerListPage";
import ManufacturerFormPage from "./ManufacturerFormPage";
import MaterialListPage from "./MaterialListPage";
import MaterialFormPage from "./MaterialFormPage";
import PrototypeListPage from "./PrototypeListPage";
import PrototypeFormPage from "./PrototypeFormPage";
import DealerFormPage from "./DealerFormPage";
import DealerListPage from "./DealerListPage";
import BrandFormPage from "./BrandFormPage";
import BrandListPage from "./BrandListPage";
import CoreFormPage from "./CoreFormPage";
import CoreListPage from "./CoreListPage";
import ParentCoreFormPage from "./ParentCoreFormPage";
import ParentCoreListPage from "./ParentCoreListPage";
import PanelFormPage from "./PanelFormPage";
import PanelListPage from "./PanelListPage";
import FabricQuiltPatternFormPage from "./FabricQuiltPatternFormPage";
import FabricQuiltPatternListPage from "./FabricQuiltPatternListPage";
import FamilyFormPage from "./FamilyFormPage";
import FamilyListPage from "./FamilyListPage";
import SizeFormPage from "./SizeFormPage";
import SizeListPage from "./SizeListPage";
import MaterialGroupListPage from "./MaterialGroupListPage";
import MaterialGroupFormPage from "./MaterialGroupFormPage";
import FoundationGroupListPage from "./FoundationGroupListPage";
import FoundationGroupFormPage from "./FoundationGroupFormPage";
import NotAuthorizedPage from "./NotAuthorizedPage";
import ProtectedRoute from "./ProtectedRoute";
import CycleCountFormPage from "./CycleCountFormPage";
import FishbowlReprocessingFormPage from "./FishbowlReprocessingFormPage";
import LawTagViewerPage from "./LawTagViewerPage";
import ShippingOrdersListPage from "./ShippingOrdersListPage";
import PieceRateScanFormPage from "./PieceRateScanFormPage";
import SkuQueueListPage from "./SkuQueueListPage";
import RetailCommission from "./RetailCommission";
import DealerPage from "./DealerPage";
import LaborList from "./List/LaborList";
import ShippingList from "./List/ShippingList";
import PriorityBatch from "./List/batchList/PriorityBatch";
import SkuPriorityBatch from "./List/batchList/SkuPriorityBatch";
import CampaginCurve from "./Forcasting/CampaginCurve";
import Campaign from "./Forcasting/Campaign";
import Forecast from "./Forcasting/Forecast";
import ForecastWorkbookUpload from "./Forcasting/ForecastWorkbookUpload";
import ProductionScanHome from "./ProductionScan/ProductionScanHome";
import { NavBar } from "./header/NavBar";
import Production from "./ProductionScan/Production";
import Inventory from "./ProductionScan/Inventory";
import ProductionTracking from "./ProductionScan/ProductionTracking";
import Connect from "./ProductionScan/Connect";
import ForecastPage from "./Forcasting/ForecastPage";
import ViewCoreList from "./List/viewcorelist/ViewCoreList";
import ReportPage from "./ReportPage";
import CoreUpdateForm from "./forms/coreupdateform/CoreUpdateForm";
import CopyBomForm from "./forms/copybomform/CopyBomForm";
import SkuItemPage from "./SkuItemPage";
import SubComponentScan from "./ProductionScan/SubComponentScan";
import BatchScan from "./ProductionScan/batchscans/BatchScan";
import Truck from "./ProductionScan/Truck/Truck";
import Shipped from "./ProductionScan/shippedStatus/shipped";
import TruckLoad from "./ProductionScan/Truck/TruckLoad";
import TruckUpdate from "./ProductionScan/Truck/TruckUpdate";

class App extends React.Component {
  render() {
    return (
      <div className="main">
        <div className="header">
          <Header />
          {/* <NavBar /> */}
        </div>
        <div
          className="body-main"
          style={{ height: "auto", backgroundColor: "#f6f9fc" }}
        >
          <div className="body-content">
            <Switch>
              <ProtectedRoute exact={true} path="/" component={HomePage} />
              <Route exact={true} path="/login" component={LoginFormPage} />
              <ProtectedRoute path="/dashboard" component={DashboardPage} />
              {/* <ProtectedRoute
                path="/piece-rate"
                role={["piece", "admin"]}
                component={PieceRateScanFormPage}
              /> */}
              <ProtectedRoute
                path="/production-scan"
                role={["scan", "admin"]}
                component={ProductionScanHome}
              />
              <ProtectedRoute
                path="/sub_component_scan"
                role={["scan", "admin"]}
                component={SubComponentScan}
              />
              <ProtectedRoute
                path="/batch_scan"
                role={["scan", "admin"]}
                component={BatchScan}
              />
              <ProtectedRoute
                path="/truck_scan"
                role={["scan", "admin"]}
                component={Truck}
              />
              <ProtectedRoute
                path="/truck_update"
                role={["scan", "admin"]}
                component={TruckUpdate}
              />
              <ProtectedRoute
                path="/shipped_scan"
                role={["scan", "admin"]}
                component={Shipped}
              />
              <ProtectedRoute
                path="/truck_load"
                role={["scan", "admin"]}
                component={TruckLoad}
              />
              <ProtectedRoute
                path="/production"
                role={["scan", "admin"]}
                component={Production}
              />
              <ProtectedRoute
                path="/inventory"
                role={["scan", "admin"]}
                component={Inventory}
              />
              <ProtectedRoute
                path="/product-tracking"
                role={["scan", "admin"]}
                component={ProductionTracking}
              />
              <ProtectedRoute
                path="/connect"
                role={["scan", "admin"]}
                component={Connect}
              />
              <ProtectedRoute
                path="/schedule"
                role={["schedule", "admin"]}
                component={ScheduleListPage}
              />
              <ProtectedRoute
                path="/batch"
                role={["schedule", "admin"]}
                component={PriorityBatch}
              />
              <ProtectedRoute
                path="/sku-batch"
                role={["schedule", "admin"]}
                component={SkuPriorityBatch}
              />
              <ProtectedRoute
                path={"/shipping"}
                role={["admin", "shipping_edit"]}
                component={ShippingOrdersListPage}
              />
              <ProtectedRoute
                path={"/skuqueue"}
                role={["admin"]}
                component={SkuQueueListPage}
              />
              <ProtectedRoute
                path={"/users"}
                role={["admin"]}
                component={UserListPage}
              />
              <ProtectedRoute
                path="/user/:id"
                role={["admin"]}
                component={UserFormPage}
              />
              <ProtectedRoute
                path="/skus"
                role={["sku_view", "sku_edit", "admin"]}
                component={SkuListPage}
              />
              <ProtectedRoute
                path="/labor"
                role={["sku_view", "sku_edit", "admin"]}
                component={LaborList}
              />
              <ProtectedRoute
                path="/sku-shipping"
                role={["sku_view", "sku_edit", "admin"]}
                component={ShippingList}
              />
              {/* sku item page */}
              <ProtectedRoute
                path="/sku-item"
                role={["sku_view", "sku_edit", "admin"]}
                component={SkuItemPage}
              />
              <ProtectedRoute
                path={"/sku/:id"}
                role={["sku_view", "sku_edit", "admin"]}
                component={SkuFormPage}
              />
              <ProtectedRoute
                path={"/report/productiontracker"}
                role={["admin"]}
                component={ReportPage}
              />
              <ProtectedRoute
                path={"/sku-group/:family_id"}
                role={["sku_view", "sku_edit", "admin"]}
                exact={true}
                component={SkuFormPage}
              />
              <ProtectedRoute
                path={"/sku-staging/:staging_id"}
                role={["sku_view", "sku_edit", "admin"]}
                exact={true}
                component={SkuFormPage}
              />
              <ProtectedRoute
                path="/customers"
                role={["sku_view", "sku_edit", "admin"]}
                component={CustomerListPage}
              />
              <ProtectedRoute
                path={"/customer/:id"}
                role={["sku_view", "sku_edit", "admin"]}
                component={CustomerFormPage}
              />
              <ProtectedRoute
                path="/foundations"
                role={["sku_view", "sku_edit", "admin"]}
                component={FoundationListPage}
              />
              <ProtectedRoute
                path={"/foundation/:id"}
                role={["sku_view", "sku_edit", "admin"]}
                component={FoundationFormPage}
              />
              <ProtectedRoute
                path="/lt/foundation/groups"
                role={["sku_view", "sku_edit", "admin"]}
                component={FoundationGroupListPage}
              />
              <ProtectedRoute
                path={"/lt/foundation/group/:id"}
                role={["sku_view", "sku_edit", "admin"]}
                component={FoundationGroupFormPage}
              />
              <ProtectedRoute
                path="/manufacturers"
                role={["sku_view", "sku_edit", "admin"]}
                component={ManufacturerListPage}
              />
              <ProtectedRoute
                path={"/manufacturer/:id"}
                role={["sku_view", "sku_edit", "admin"]}
                component={ManufacturerFormPage}
              />
              <ProtectedRoute
                path="/materials"
                role={["sku_view", "sku_edit", "admin"]}
                component={MaterialListPage}
              />
              <ProtectedRoute
                path="/material/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={MaterialFormPage}
              />
              <ProtectedRoute
                path="/lt/material/groups"
                role={["sku_view", "sku_edit", "admin"]}
                component={MaterialGroupListPage}
              />
              <ProtectedRoute
                path="/lt/material/group/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={MaterialGroupFormPage}
              />
              <ProtectedRoute
                path={"/prototypes"}
                role={["sku_view", "sku_edit", "admin"]}
                component={PrototypeListPage}
              />
              <ProtectedRoute
                path="/prototype/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={PrototypeFormPage}
              />
              <ProtectedRoute
                path="/lawtag/viewer"
                role={["sku_view", "sku_edit", "admin"]}
                component={LawTagViewerPage}
              />
              <ProtectedRoute
                path="/dealer"
                role={["dealer_edit", "admin"]}
                component={DealerFormPage}
              />
              <ProtectedRoute
                path="/dealers"
                role={["dealer_edit", "admin"]}
                component={DealerPage}
              />
              <ProtectedRoute
                path="/brand/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={BrandFormPage}
              />
              <ProtectedRoute
                path="/brands"
                role={["sku_view", "sku_edit", "admin"]}
                component={BrandListPage}
              />
              <ProtectedRoute
                path="/core/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={CoreFormPage}
              />
              <ProtectedRoute
                path="/core-update/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={CoreUpdateForm}
              />
              <ProtectedRoute
                path="/copy-bom/-1"
                role={["sku_view", "sku_edit", "admin"]}
                component={CopyBomForm}
              />
              <ProtectedRoute
                path="/cores"
                role={["sku_view", "sku_edit", "admin"]}
                component={CoreListPage}
              />
              <ProtectedRoute
                path="/mat-core/parentCore/-1"
                role={["sku_view", "sku_edit", "admin"]}
                component={ParentCoreFormPage}
              />

              <ProtectedRoute
                path="/mat-core/parentCore/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={ViewCoreList}
              />
              <ProtectedRoute
                path="/mat-core/parentCores"
                role={["sku_view", "sku_edit", "admin"]}
                component={ParentCoreListPage}
              />
              <ProtectedRoute
                path="/panel/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={PanelFormPage}
              />
              <ProtectedRoute
                path="/panels"
                role={["sku_view", "sku_edit", "admin"]}
                component={PanelListPage}
              />
              <ProtectedRoute
                path="/fabric/quilt/pattern/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={FabricQuiltPatternFormPage}
              />
              <ProtectedRoute
                path="/fabric/quilt/patterns"
                role={["sku_view", "sku_edit", "admin"]}
                component={FabricQuiltPatternListPage}
              />
              <ProtectedRoute
                path="/family/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={FamilyFormPage}
              />
              <ProtectedRoute
                path="/families"
                role={["sku_view", "sku_edit", "admin"]}
                component={FamilyListPage}
              />
              <ProtectedRoute
                path="/size/:id"
                role={["sku_view", "sku_edit", "admin"]}
                component={SizeFormPage}
              />
              <ProtectedRoute
                path="/sizes"
                role={["sku_view", "sku_edit", "admin"]}
                component={SizeListPage}
              />
              <ProtectedRoute
                path="/cycle/count"
                role={["inventory_edit", "admin"]}
                component={CycleCountFormPage}
              />
              <ProtectedRoute
                path="/fishbowl/reprocessing"
                role={["inventory_edit", "admin"]}
                component={FishbowlReprocessingFormPage}
              />
              <ProtectedRoute
                path="/commission"
                role={["retail_edit", "admin"]}
                component={RetailCommission}
              />
              <ProtectedRoute path="/logout" component={LogoutPage} />
              <ProtectedRoute
                path={"/unauthorized"}
                component={NotAuthorizedPage}
              />
              <ProtectedRoute path="/campaign-upload" component={Campaign} />
              <ProtectedRoute
                role={["forecast_visit", "forecast_edit", "admin"]}
                path="/campaign-curve-upload"
                component={CampaginCurve}
              />
              <ProtectedRoute path="/forecast-upload" component={Forecast} />
              <ProtectedRoute
                role={["forecast_visit", "forecast_edit", "admin"]}
                path="/forecast-workbook-upload"
                component={ForecastWorkbookUpload}
              />
              <ProtectedRoute
                path="/forecast-page"
                role={["forecast_visit", "forecast_edit", "admin"]}
                component={ForecastPage}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
