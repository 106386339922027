import React from 'react';
import PanelList from './List/PanelList';
import '../styles/about-page.css';

export class PanelListPage extends React.Component {

    render() {
        return (
            <PanelList/>
        );
    }
}

export default PanelListPage;
