import React from "react";
import T from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import "../../styles/dealer.css";
import {
  getDealer,
  saveDealer,
  updateField
} from "../../actions/dealerActions";
import { Box, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

class DealerForm extends React.Component {
  constructor({ data, props }) {
    super({ data, props });
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { isError, dispatch, account_id, data } = this.props;
    dispatch(getDealer(account_id));
  }

  handleChange = (e) => {
    const { dispatch } = this.props;
    dispatch(updateField(e.target.name, e.target.value));
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.saveSuccess) {
      this.setState({ open: true });
    }
  }
  handleClickDeactivate = async (e) => {
    const { dispatch } = this.props;
    const user = localStorage.getItem("userId");
    const timestamp = moment(Date.now()).format("YYYY-DD-MM HH:mm:ss");
    const {
      account_id,
      dealer_code,
      cfs_dealer_id,
      dealer_name,
      first_name,
      last_name,
      company_name,
      main_phone,
      main_email,
      street,
      city,
      state,
      zip,
      cc_email,
      deleted_by,
      deleted_at
    } = this.state;

    const dealer = {
      account_id: account_id,
      dealer_code: dealer_code,
      cfs_dealer_id: cfs_dealer_id,
      dealer_name: dealer_name,
      first_name: first_name,
      last_name: last_name,
      company_name: company_name,
      main_phone: main_phone,
      main_email: main_email,
      street: street,
      city: city,
      state: state,
      zip: zip,
      cc_email: cc_email,
      deleted_by: user,
      deleted_at: timestamp
    };
    await dispatch(saveDealer(dealer)).then((res) => {
      if (res.type === "DEALER_SAVING_SUCCESS") {
        this.props.history.push("/dealers");
      }
    });
  };

  confirmDeactivate = () => {
    this.setState({ show: true });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  state = {
    show: false,
    open: false,
    vertical: "top",
    horizontal: "center",
    account_id: this.props.data.account_id,
    dealer_code: this.props.data.dealer_code,
    cfs_dealer_id: this.props.data.cfs_dealer_id,
    dealer_name: this.props.data.dealer_name,
    first_name: this.props.data.first_name,
    last_name: this.props.data.last_name,
    company_name: this.props.data.company_name,
    main_phone: this.props.data.main_phone,
    main_email: this.props.data.main_email,
    street: this.props.data.street,
    city: this.props.data.city,
    state: this.props.data.state,
    zip: this.props.data.zip,
    cc_email: this.props.data.cc_email
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;

    const {
      account_id,
      dealer_code,
      cfs_dealer_id,
      dealer_name,
      first_name,
      last_name,
      company_name,
      main_phone,
      main_email,
      street,
      city,
      state,
      zip,
      cc_email,
    } = this.state;

    const dealer = {
      account_id: account_id,
      dealer_code: dealer_code,
      cfs_dealer_id: cfs_dealer_id,
      dealer_name: dealer_name,
      first_name: first_name,
      last_name: last_name,
      company_name: company_name,
      main_phone: main_phone,
      main_email: main_email,
      street: street,
      city: city,
      state: state,
      zip: zip,
      cc_email: cc_email
    };
    if (!account_id) {
      delete dealer.account_id;
    }

    await dispatch(saveDealer(dealer));
    this.handleOpen();
  };

  render() {
    return (
      <div className="baseContainer projectForm">
        <Dialog
          open={this.state.show}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Deactivate Dealer?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please Confrim that you wish to deactivate this dealer. Upon
              deactivation you will be redirected to the Dealer list.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Disagree</Button>
            <Button onClick={this.handleClickDeactivate} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.open}
          className="success"
          onClose={this.handleClose}
          autoHideDuration={2000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
        >
          <Alert severity="success" variant="filled">
            Dealer saved
          </Alert>
        </Snackbar>
        <Grid container>
          <Grid item sm={6}>
            <Box p={1} m={1}>
              <a href="/dealers" style={{ color: "#5bb7db" }}>
                <Grid
                  className="row"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  &nbsp;
                  <FontAwesomeIcon
                    icon={faChevronCircleLeft}
                    style={{ fontSize: "1.75em", marginTop: "15px" }}
                  />
                  <h2>Return to Dealer List</h2>
                </Grid>
              </a>

              <h1>
                {this.state.cfs_dealer_id} {this.state.dealer_name}
              </h1>
            </Box>
          </Grid>
          <Grid sm={6} item justifyContent="flex-end">
            <Box item display="flex" justifyContent="flex-end">
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={this.confirmDeactivate}
              >
                Deactiveate Dealer
              </Button>
            </Box>
          </Grid>
        </Grid>
        <form onSubmit={this.handleSubmit}>
          <Grid container>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="Account Id"
                  name="account_id"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.account_id}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="Dealer Code"
                  name="dealer_code"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.dealer_code}
                  onChange={this.handleChange}
                  inputProps={{ readOnly: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="CFS Dealer Id"
                  name="cfs_dealer_id"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.cfs_dealer_id}
                  onChange={this.handleChange}
                  // inputProps={{ readOnly: true }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="Dealer Name"
                  name="dealer_name"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.dealer_name}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="Company Name"
                  name="company_name"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.company_name}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="First Name"
                  name="first_name"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.first_name}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="Last Name"
                  name="last_name"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.last_name}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="Phone"
                  name="main_phone"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.main_phone}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="Email"
                  name="main_email"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.main_email}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="Street"
                  name="street"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.street}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="City"
                  name="city"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.city}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="State"
                  name="state"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.state}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="Zip"
                  name="zip"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                  value={this.state.zip}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box p={1} m={1}>
                <TextField
                  fullWidth={true}
                  label="CC Email"
                  name="cc_email"
                  margin="normal"
                  variant="outlined"
                  className="name-field"
                    value={this.state.cc_email}
                    onChange={this.handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={4}>
                <Box p={2} m={1}>
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

DealerForm.defaultProps = {};

DealerForm.propTypes = {
  isLoading: T.bool,
  isError: T.bool,
  saveSuccess: T.bool,
  //   id: T.number,
  name: T.string,
  addressLineOne: T.string,
  addressLineTwo: T.string,
  city: T.string,
  state: T.string,
  postalCode: T.string
};

function mapStateToProps(state) {
  const { dealerForm } = state;
  return {
    isError: dealerForm.isError,
    saveSuccess: dealerForm.saveSuccess,
    // id: dealerForm.id,
    name: dealerForm.name,
    addressLineOne: dealerForm.addressLineOne,
    addressLineTwo: dealerForm.addressLineTwo,
    city: dealerForm.city,
    state: dealerForm.state,
    postalCode: dealerForm.postalCode
  };
}

export default withRouter(connect(mapStateToProps)(DealerForm));
