import {
  SKU_UPDATE_FIELD,
  SKU_LOADING_REQUEST,
  SKU_LOADING_SUCCESS,
  SKU_LOADING_ERROR,
  SKU_SAVING_REQUEST,
  SKU_SAVING_SUCCESS,
  SKU_SAVING_ERROR,
  SKU_ADD_MATERIAL,
  SKU_ADD_FOUNDATION,
  SKU_MATERIAL_ADDED,
  SKU_FOUNDATION_ADDED,
  SKU_PROTOTYPE_UPDATE,
  SKU_FOUNDATION_GROUP_UPDATE,
  SKU_MATERIAL_GROUP_UPDATE,
  SKU_UPDATE_CORE,
  SKU_UPDATE_FAMILY,
  SKU_UPDATE_BATCH,
  SKU_UPDATE_FIRMNESS,
  SKU_UPDATE_PARENT_CORE,
  SKU_UPDATE_TOP_PANEL,
  SKU_UPDATE_GUSSET_PANEL,
  SKU_UPDATE_SIDE_PANEL,
  SKU_UPDATE_BOTTOM_PANEL,
  SKU_UPDATE_SKU_ATTRIBUTE,
  SKU_RESET,
  SKU_GROUP_LOADING_REQUEST,
  SKU_GROUP_LOADING_SUCCESS,
  SKU_GROUP_LOADING_ERROR,
  SKU_DRAFT_LOADING_REQUEST,
  SKU_DRAFT_LOADING_SUCCESS,
  SKU_DRAFT_LOADING_ERROR,
  SKU_DRAFT_SAVING_REQUEST,
  SKU_DRAFT_SAVING_SUCCESS,
  SKU_DRAFT_SAVING_ERROR,
  SKU_DRAFT_DELETE_ERROR,
  SKU_DRAFT_DELETE_REQUEST,
  SKU_DRAFT_DELETE_SUCCESS,
  SKU_UPDATE_MODAL_CLOSED,
  SKU_UPDATE_MODAL_OPEN,
  SKU_UPDATE_IDENTIFIER,
  SKU_SET_TOP,
  SKU_SET_GUSSET,
  SKU_SET_SIDE,
  SKU_SET_BOTTOM
} from "../constants/skuTypes";
import {
  WEIGHT_LOADING_SUCCESS,
  WEIGHT_LOADING_REQUEST,
  WEIGHT_LOADING_ERROR,
  WEIGHTS_LOADING_REQUEST,
  WEIGHTS_LOADING_SUCCESS,
  WEIGHTS_LOADING_ERROR,
  WEIGHTS_SAVING_REQUEST,
  WEIGHTS_SAVING_SUCCESS,
  WEIGHTS_SAVING_ERROR
} from "../constants/weightTypes";

import {
  COLUMNS_LOADING_REQUEST,
  COLUMNS_LOADING_SUCCESS,
  COLUMNS_LOADING_ERROR
} from "../constants/globalTypes";

import { parseAllToJson } from "../utils/string";
import T from "prop-types";
import { compareValues } from "../utils/sort";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  isTopPanel: false,
  isGussetPanel: false,
  isSidePanel: false,
  isBottomPanel: false,
  id: -1,
  brand: {},
  batchId: -1,
  family: {},
  firmness: {},
  sku: "",
  skuGroup: [],
  core: {},
  parentCore: {},
  size: {},
  prototype: "",
  familyName: "",
  firmnessName: "",
  brandName: "",
  parentCoreName: "",
  sizeName: "",
  customerName: "",
  manufacturerName: "",
  prototypeName: "",
  materialName: "",
  blend: 0.0,
  foundationName: "",
  ltFoundationGroup: {},
  ltMaterialGroup: {},
  materialGroupName: "",
  foundationGroupName: "",
  plastics: [],
  zipperParts: [],

  topPanel: "",
  topPanelLayers: [],
  gussetPanel: "",
  gussetPanelLayers: [],
  sidePanel: "",
  sidePanelLayers: [],
  bottomPanel: "",
  bottomPanelLayers: [],

  skuTopPanelAttribute: [],
  skuGussetPanelAttribute: [],
  skuSidePanelAttribute: [],
  skuBottomPanelAttribute: [],
  skuAttribute: [],
  skuCoreAttribute: [],
  boxNames: [],
  upc: "",
  skuTape: [],
  sizeAlias: "",
  openModal: false,
  identifier: "",
  weights: []
};

export default function skuFormReducer(state = initialState, action) {
  switch (action.type) {
    case SKU_UPDATE_MODAL_CLOSED:
      return {
        ...state,
        openModal: false
      };
    case SKU_UPDATE_MODAL_OPEN:
      return {
        ...state,
        openModal: true
      };

    case SKU_SET_TOP:
      return {
        ...state,
        isTopPanel: true,
        isBottomPanel: false,
        isSidePanel: false,
        isGussetPanel: false
      };

    case SKU_SET_GUSSET:
      return {
        ...state,
        isTopPanel: false,
        isBottomPanel: false,
        isSidePanel: false,
        isGussetPanel: true
      };

    case SKU_SET_SIDE:
      return {
        ...state,
        isTopPanel: false,
        isBottomPanel: false,
        isSidePanel: true,
        isGussetPanel: false
      };

    case SKU_SET_BOTTOM:
      return {
        ...state,
        isTopPanel: false,
        isBottomPanel: true,
        isSidePanel: false,
        isGussetPanel: false
      };

    case SKU_UPDATE_IDENTIFIER:
      return {
        ...state,
        isLoading: false,
        identifier: action.identifier
      };

    case SKU_UPDATE_FIELD:
      return {
        ...state,
        isLoading: false,
        [action.name]: action.value
      };
    case SKU_UPDATE_SKU_ATTRIBUTE:
      return {
        ...state,
        isLoading: false,
        skuAttribute: action.skuAttribute
      };

    case SKU_UPDATE_FAMILY:
      return {
        ...state,
        isLoading: false,
        familyName: action.familyName,
        family: action.family
      };
    case SKU_UPDATE_BATCH:
      return {
        ...state,
        isLoading: false,
        batchId: action.batchId
      };
    case SKU_UPDATE_TOP_PANEL:
      return {
        ...state,
        isLoading: false,
        topPanel: action.topPanel,
        topPanelLayers: action.topPanelLayers
      };
    case SKU_UPDATE_GUSSET_PANEL:
      return {
        ...state,
        isLoading: false,
        gussetPanel: action.gussetPanel,
        gussetPanelLayers: action.gussetPanelLayers
      };
    case SKU_UPDATE_SIDE_PANEL:
      return {
        ...state,
        isLoading: false,
        sidePanel: action.sidePanel,
        sidePanelLayers: action.sidePanelLayers
      };
    case SKU_UPDATE_BOTTOM_PANEL:
      return {
        ...state,
        isLoading: false,
        bottomPanel: action.bottomPanel,
        bottomPanelLayers: action.bottomPanelLayers
      };
    //    new here
    case SKU_UPDATE_FIRMNESS:
      return {
        ...state,
        isLoading: false,
        firmnessName: action.firmnessName,
        firmness: action.firmness
      };
    // NEW HERE
    case SKU_UPDATE_PARENT_CORE:
      return {
        ...state,
        isLoading: false,
        parentCoreName: action.parentCoreName,
        parentCore: action.parentCore
      };
    case SKU_UPDATE_CORE:
      return {
        ...state,
        isLoading: false,
        parentCore: action.parentCore,
        core: action.core
      };
    case SKU_MATERIAL_GROUP_UPDATE:
      return {
        ...state,
        isLoading: false,
        ltMaterialGroup: action.ltMaterialGroup,
        materialGroupName: action.materialGroupName
      };
    case SKU_FOUNDATION_GROUP_UPDATE:
      return {
        ...state,
        isLoading: false,
        ltFoundationGroup: action.ltFoundationGroup,
        foundationGroupName: action.foundationGroupName
      };
    case SKU_PROTOTYPE_UPDATE:
      return {
        ...state,
        isLoading: false,
        prototype: action.prototype,
        prototypeName: action.prototypeName
      };
    case SKU_ADD_MATERIAL:
      return {
        ...state,
        ltMaterialGroup: action.ltMaterialGroup
      };
    case SKU_MATERIAL_ADDED:
      return {
        ...state,
        materialName: "",
        blend: 0.0
      };
    case SKU_ADD_FOUNDATION:
      return {
        ...state,
        ltFoundationGroup: action.ltFoundationGroup
      };
    case SKU_FOUNDATION_ADDED:
      return {
        ...state,
        foundationName: ""
      };
    case SKU_LOADING_REQUEST:
    case SKU_GROUP_LOADING_REQUEST:
    case SKU_DRAFT_LOADING_REQUEST:
    case SKU_DRAFT_SAVING_REQUEST:
    case SKU_DRAFT_DELETE_REQUEST:
    case SKU_SAVING_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSaved: false
      };
    case SKU_DRAFT_LOADING_SUCCESS:
      let draft = JSON.parse(action.payload.payload);
      let noteDraftAttribute = draft[0].skuAttribute
        ? draft[0].skuAttribute.filter((s) => s.attribute_id === 2)
        : "";
      return {
        ...state,
        isLoading: false,
        isError: false,
        // draft: action.payload,
        sku: draft[0].sku ? draft[0].sku : null,
        brandName: draft[0].brandName ? draft[0].brandName : "",
        familyName: draft[0].familyName ? draft[0].familyName : "",
        customerName: draft[0].customerName ? draft[0].customerName : "",
        manufacturerName:
          draft[0].manufacturerName != null ? draft[0].manufacturerName : "",
        parentCore: draft[0].parentCore ? draft[0].parentCore : "",
        parentCoreName: draft[0].parentCoreName ? draft[0].parentCoreName : "",
        prototypeName: draft[0].prototypeName ? draft[0].prototypeName : "",
        ltMaterialGroup: draft[0].ltMaterialGroup
          ? draft[0].ltMaterialGroup
          : "",
        materialGroupName:
          draft[0].ltMaterialGroup != null && draft[0].ltMaterialGroup !== {}
            ? draft[0].ltMaterialGroup.group_name
            : "",
        ltFoundationGroup: draft[0].ltFoundationGroup
          ? draft[0].ltFoundationGroup
          : "",
        foundationGroupName:
          draft[0].ltFoundationGroup != null &&
          draft[0].ltFoundationGroup !== {}
            ? draft[0].ltFoundationGroup.group_name
            : "",
        topPanel: draft[0].topPanel != null ? draft[0].topPanel : null,
        topPanelLayers:
          draft[0].topPanelLayers != null ? draft[0].topPanelLayers : [],
        sidePanel: draft[0].sidePanel != null ? draft[0].sidePanel : null,
        sidePanelLayers:
          draft[0].sidePanelLayers != null ? draft[0].sidePanelLayers : [],
        gussetPanel: draft[0].gussetPanel != null ? draft[0].gussetPanel : null,
        gussetPanelLayers:
          draft[0].gussetPanelLayers != null ? draft[0].gussetPanelLayers : [],
        bottomPanel: draft[0].bottomPanel != null ? draft[0].bottomPanel : null,
        bottomPanelLayers:
          draft[0].bottomPanelLayers != null ? draft[0].bottomPanelLayers : [],
        sizeList: draft[0].sizeList != null ? draft[0].sizeList : [],
        firmnessList:
          draft[0].firmnessList != null ? draft[0].firmnessList : [],
        tableSkuList:
          draft[0].tableSkuList != null ? draft[0].tableSkuList : [],
        topQuiltPattern:
          draft[0].topQuiltPattern != null ? draft[0].topQuiltPattern : "",
        skuTopPanelAttribute:
          draft[0].skuTopPanelAttribute != null
            ? draft[0].skuTopPanelAttribute
            : [],
        gussetQuiltPattern:
          draft[0].gussetQuiltPattern != null
            ? draft[0].gussetQuiltPattern
            : "",
        skuGussetPanelAttribute:
          draft[0].skuGussetPanelAttribute != null
            ? draft[0].skuGussetPanelAttribute
            : [],
        sideQuiltPattern:
          draft[0].sideQuiltPattern != null ? draft[0].sideQuiltPattern : "",
        skuSidePanelAttribute:
          draft[0].skuSidePanelAttribute != null
            ? draft[0].skuSidePanelAttribute
            : [],
        bottomQuiltPattern:
          draft[0].bottomQuiltPattern != null
            ? draft[0].bottomQuiltPattern
            : "",
        skuBottomPanelAttribute:
          draft[0].skuBottomPanelAttribute != null
            ? draft[0].skuBottomPanelAttribute
            : [],
        skuTape: draft[0].skuTape != null ? draft[0].skuTape : [],
        bottomTape: draft[0].bottomTape != null ? draft[0].bottomTape : "",
        sideTape: draft[0].sideTape != null ? draft[0].sideTape : "",
        topTape: draft[0].topTape != null ? draft[0].topTape : "",
        skuAttribute:
          draft[0].skuAttribute != null ? draft[0].skuAttribute : [],
        skuCoreAttribute:
          draft[0].skuCoreAttribute != null ? draft[0].skuCoreAttribute : [],
        skuNote:
          noteDraftAttribute[0] != undefined
            ? noteDraftAttribute[0].attribute_value.split("-")[0]
            : "",
        boxObjs: draft[0].boxObjs != null ? draft[0].boxObjs : [],
        boxNames: draft[0].boxNames != null ? draft[0].boxNames : [],
        frObjs: draft[0].frObjs != null ? draft[0].frObjs : [],
        frNames: draft[0].frNames != null ? draft[0].frNames : [],
        zcObjs: draft[0].zcObjs != null ? draft[0].zcObjs : [],
        zcNames: draft[0].zcNames != null ? draft[0].zcNames : [],
        plastics: draft[0].plastics != null ? draft[0].plastics : [],
        zipperParts: draft[0].zipperParts != null ? draft[0].zipperParts : []
      };
    case SKU_GROUP_LOADING_SUCCESS:
      let noteAttribute = action.payload[0].skuAttribute
        ? action.payload[0].skuAttribute.filter((s) => s.attribute_id === 2)
        : "";
      return {
        ...state,
        isLoading: false,
        isError: false,
        skuGroup: action.payload,
        active: action.payload[0].active ? action.payload[0].active : null,
        brandName: action.payload[0].brand ? action.payload[0].brand.brand : "",
        familyName: action.payload[0].family
          ? action.payload[0].family.family
          : "",
        customerName:
          action.payload[0].ltCustomer != null
            ? action.payload[0].ltCustomer.name
            : "",
        manufacturerName:
          action.payload[0].ltManufacturer != null
            ? action.payload[0].ltManufacturer.name
            : "",
        topPanel:
          action.payload[0].top_panel_id != null
            ? action.payload[0].top_panel_id
            : null,
        topPanelLayers:
          action.payload[0].topPanel != null
            ? action.payload[0].topPanel.panelLayers
            : [],
        gussetPanel:
          action.payload[0].gussett_panel_id != null
            ? action.payload[0].gussett_panel_id
            : null,
        gussetPanelLayers:
          action.payload[0].gussetPanel != null
            ? action.payload[0].gussetPanel.panelLayers
            : [],
        sidePanel:
          action.payload[0].side_panel_id != null
            ? action.payload[0].side_panel_id
            : null,
        sidePanelLayers:
          action.payload[0].sidePanel != null
            ? action.payload[0].sidePanel.panelLayers
            : [],
        bottomPanel:
          action.payload[0].bottom_panel_id != null
            ? action.payload[0].bottom_panel_id
            : null,
        bottomPanelLayers:
          action.payload[0].bottomPanel != null
            ? action.payload[0].bottomPanel.panelLayers
            : [],
        parentCore:
          action.payload[0].core !== null &&
          action.payload[0].core.type !== null
            ? action.payload[0].core.type.parentCore
            : "",
        parentCoreName:
          action.payload[0].core && action.payload[0].core.type
            ? action.payload[0].core.type.parentCore.parent_core
            : "",
        skuNote:
          noteAttribute[0] != undefined
            ? noteAttribute[0].attribute_value.split("-")[0]
            : "",
        prototype: action.payload[0].ltPrototype
          ? action.payload[0].ltPrototype
          : "",
        prototypeName: action.payload[0].ltPrototype
          ? action.payload[0].ltPrototype.prototype.number
          : "",
        materialGroupName:
          action.payload[0].ltMaterialGroup != null &&
          action.payload[0].ltMaterialGroup !== {}
            ? action.payload[0].ltMaterialGroup.group_name
            : "",
        ltMaterialGroup: action.payload[0].ltMaterialGroup
          ? {
              id: action.payload[0].ltMaterialGroup.id,
              group_name: action.payload[0].ltMaterialGroup,
              materials: action.payload[0].ltMaterialGroup.materials.filter(
                (m) => m.active === 1
              )
            }
          : "",
        skuTopPanelAttribute:
          action.payload[0].skuTopPanelAttribute != null
            ? action.payload[0].skuTopPanelAttribute
            : [],
        skuGussetPanelAttribute:
          action.payload[0].skuGussetPanelAttribute != null
            ? action.payload[0].skuGussetPanelAttribute
            : [],
        skuSidePanelAttribute:
          action.payload[0].skuSidePanelAttribute != null
            ? action.payload[0].skuSidePanelAttribute
            : [],
        skuBottomPanelAttribute:
          action.payload[0].skuBottomPanelAttribute != null
            ? action.payload[0].skuBottomPanelAttribute
            : [],
        skuTape:
          action.payload[0].skuTape != null ? action.payload[0].skuTape : null,
        skuAttribute:
          action.payload[0].skuAttribute != null
            ? action.payload[0].skuAttribute
            : [],
        skuCoreAttribute:
          action.payload[0].skuCoreAttribute != null
            ? action.payload[0].skuCoreAttribute
            : [],
        ltFoundationGroup:
          action.payload[0].ltFoundationGroup != null
            ? action.payload[0].ltFoundationGroup
            : null,
        foundationGroupName:
          action.payload[0].ltFoundationGroup != null &&
          action.payload[0].ltFoundationGroup !== {}
            ? action.payload[0].ltFoundationGroup.group_name
            : "",
        // boxNames: action.payload[0].skuAttribute ? action.payload[0].skuAttribute.filter(s => s.attribute_id === 1 && s.part.category === 'Box') : '',
        sizeList:
          action.payload.length >= 1
            ? action.payload.map((sku) => sku.size.size)
            : [],
        firmnessList:
          action.payload.length >= 1
            ? action.payload.map((sku) => sku.core.type.firmness.firmness)
            : []
        // topTapeName: topTape != '' ? topTape.part.num : ''
      };
    case SKU_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        id: action.payload.id,
        active: action.payload.active,
        brand: action.payload.brand,
        family: action.payload.family,
        //not all skus have a core_id yet so not all of them will be displaying a firmness yet
        //why is error 'can not read property 'firmness' of undefined
        firmness:
          action.payload.core !== null && action.payload.core.type !== null
            ? action.payload.core.type.firmness
            : "",
        parentCore:
          action.payload.core !== null && action.payload.core.type !== null
            ? action.payload.core.type.parentCore
            : "",
        upc: action.payload.upc != null ? action.payload.upc : "",
        sku: action.payload.sku ? action.payload.sku : "",
        core: action.payload.core,
        size: action.payload.size,
        familyName:
          action.payload.family != null ? action.payload.family.family : "",
        firmnessName: action.payload.core
          ? action.payload.core.type.firmness.firmness
          : "",
        brandName:
          action.payload.brand != null ? action.payload.brand.brand : "",
        parentCoreName:
          action.payload.core && action.payload.core.type
            ? action.payload.core.type.parentCore.parent_core
            : "",
        sizeName: action.payload.size ? action.payload.size.size : "",
        customerName:
          action.payload.ltCustomer != null
            ? action.payload.ltCustomer.name
            : "",
        ltFoundationGroup: action.payload.ltFoundationGroup,
        manufacturerName:
          action.payload.ltManufacturer != null
            ? action.payload.ltManufacturer.name
            : "",
        ltMaterialGroup: action.payload.ltMaterialGroup
          ? {
              id: action.payload.ltMaterialGroup.id,
              group_name: action.payload.ltMaterialGroup,
              materials: action.payload.ltMaterialGroup.materials.filter(
                (m) => m.active === 1
              )
            }
          : "",
        //fixing prototype
        prototype:
          action.payload.ltPrototype != null ? action.payload.ltPrototype : "",
        prototypeName:
          action.payload.ltPrototype != null
            ? action.payload.ltPrototype.prototype.number
            : "",
        materialName: "",
        blend: 0.0,
        foundationName: "",
        materialGroupName:
          action.payload.ltMaterialGroup != null &&
          action.payload.ltMaterialGroup !== {}
            ? action.payload.ltMaterialGroup.group_name
            : "",
        foundationGroupName:
          action.payload.ltFoundationGroup != null &&
          action.payload.ltFoundationGroup !== {}
            ? action.payload.ltFoundationGroup.group_name
            : "",
        //panels for the fabrics
        topPanel:
          action.payload.top_panel_id != null
            ? action.payload.top_panel_id
            : null,
        topPanelLayers:
          action.payload.topPanel != null
            ? action.payload.topPanel.panelLayers
            : "",
        gussetPanel:
          action.payload.gussett_panel_id != null
            ? action.payload.gussett_panel_id
            : null,
        gussetPanelLayers:
          action.payload.gussetPanel != null
            ? action.payload.gussetPanel.panelLayers
            : "",
        sidePanel:
          action.payload.side_panel_id != null
            ? action.payload.side_panel_id
            : null,
        sidePanelLayers:
          action.payload.sidePanel != null
            ? action.payload.sidePanel.panelLayers
            : "",
        bottomPanel:
          action.payload.bottom_panel_id != null
            ? action.payload.bottom_panel_id
            : null,
        bottomPanelLayers:
          action.payload.bottomPanel != null
            ? action.payload.bottomPanel.panelLayers
            : "",

        skuTopPanelAttribute:
          action.payload.skuTopPanelAttribute != null
            ? action.payload.skuTopPanelAttribute
            : [],
        skuGussetPanelAttribute:
          action.payload.skuGussetPanelAttribute != null
            ? action.payload.skuGussetPanelAttribute
            : [],
        skuSidePanelAttribute:
          action.payload.skuSidePanelAttribute != null
            ? action.payload.skuSidePanelAttribute
            : [],
        skuBottomPanelAttribute:
          action.payload.skuBottomPanelAttribute != null
            ? action.payload.skuBottomPanelAttribute
            : [],
        boxNames: action.payload.skuAttribute
          ? action.payload.skuAttribute.filter(
              (s) => s.attribute_id === 1 && s.part.category === "Box"
            )
          : "",
        skuTape: action.payload.skuTape != null ? action.payload.skuTape : [],
        skuAttribute:
          action.payload.skuAttribute != null
            ? action.payload.skuAttribute
            : "",
        skuNote: action.payload.skuAttribute
          ? action.payload.skuAttribute.filter((s) => s.attribute_id === 2)
          : ""
      };
    case SKU_DRAFT_SAVING_SUCCESS:
    case SKU_DRAFT_DELETE_SUCCESS:
    case SKU_SAVING_SUCCESS:
      return {
        //fix this with the correct fields to save a SKU
        ...state,
        isError: false,
        isLoading: false,
        isSaved: false,
        id: -1,
        brand: {},
        family: {},
        firmness: {},
        sku: "",
        core: {},
        parentCore: {},
        size: {},
        familyName: "",
        firmnessName: "",
        brandName: "",
        parentCoreName: "",
        sizeName: "",
        customerName: "",
        manufacturerName: "",
        prototypeName: "",
        materialName: "",
        blend: 0.0,
        foundationName: "",
        ltFoundationGroup: {},
        ltMaterialGroup: {},
        materialGroupName: "",
        foundationGroupName: "",
        skuTopPanelAttribute: [],
        skuGussetPanelAttribute: [],
        skuSidePanelAttribute: [],
        skuBottomPanelAttribute: [],
        skuAttribute: [],
        skuTape: [],
        boxNames: [],
        openModal: false
      };
    case SKU_GROUP_LOADING_ERROR:
    case SKU_DRAFT_LOADING_ERROR:
    case SKU_LOADING_ERROR:
    case SKU_DRAFT_DELETE_ERROR:
    case SKU_DRAFT_SAVING_ERROR:
    case SKU_SAVING_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true
      };

    case WEIGHT_LOADING_REQUEST:
    case WEIGHT_LOADING_ERROR:
    case WEIGHTS_LOADING_REQUEST:
    case WEIGHTS_LOADING_ERROR:
    case WEIGHT_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        weights: action.payload
      };
    case WEIGHTS_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        weights: action.payload
      };

    case WEIGHTS_SAVING_REQUEST:
    case WEIGHTS_SAVING_ERROR:
    case WEIGHTS_SAVING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        weights: action.payload
      };
    case SKU_RESET:
      return {
        isError: false,
        isLoading: false,
        isSaved: false,
        id: -1,
        brand: {},
        family: {},
        firmness: {},
        sku: "",
        core: {},
        parentCore: {},
        size: {},
        familyName: "",
        firmnessName: "",
        brandName: "",
        parentCoreName: "",
        sizeName: "",
        customerName: "",
        manufacturerName: "",
        prototypeName: "",
        materialName: "",
        blend: 0.0,
        foundationName: "",
        ltFoundationGroup: {},
        ltMaterialGroup: {},
        materialGroupName: "",
        foundationGroupName: "",
        skuTopPanelAttribute: [],
        skuGussetPanelAttribute: [],
        skuSidePanelAttribute: [],
        skuBottomPanelAttribute: [],
        skuAttribute: [],
        skuCoreAttribute: [],
        boxNames: [],
        skuNote: "",
        skuTape: [],
        openModal: false
      };
    default:
      return {
        ...state,
        isLoading: false
      };
  }
}
