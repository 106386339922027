import React from 'react';
import T from 'prop-types'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { 
  Box, 
  Container, 
  Paper } from '@material-ui/core';
import "./corelayerdraggablecontext.css"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? "" : "",
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "" : "none",
  padding: "grid",
  width: '800px'
});

const CoreLayerDraggableContext = (props) => {
  const { layers, confirmLayerRemoval, setCoreData } = props;
  const onDragEnd = async (result) => {
    if(!result.destination) {
      return;
    }
    const reorderedLayers = reorder(
      layers,
      result.source.index,
      result.destination.index
    );
    setCoreData(prevState => ({...prevState, layers: reorderedLayers}))
  };
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {layers.map((l,index) => {
                const paperStyle = {
                  background: l.material ? l.material.color : '',
                  border: 0,
                  borderRadius: 3,
                  fontWeight: 'bold',
                  padding: "18px 30px 18px 30px",
                  width: '800px',
                }
                return (
                  <Draggable key={l.id} draggableId={l.core_layer.toString()} index={index}>
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}
                        id={l.id}
                      >
                        <Container className='layers-container' >
                          <Paper style={paperStyle}>
                            <FontAwesomeIcon 
                              icon={faTimes}
                              id='font-awesome-icon-remove' 
                              onClick={() => confirmLayerRemoval(l.id)}
                            />
                            <span id='layer-material' >
                              {l.material ? l.material.raw_material : ''}</span> <span id='layer-part'>- {l.part ? l.part.num : ''} - {l.part ? l.part.description : ''}
                            </span>
                            <span id='layer-topper'>
                              {l.topper == true ? 'topper' : ''}</span> <span id='layer-convoluted' >{l.convoluted == 1 ? 'convoluted' : ''}
                            </span>
                            <span id='layer-depth_in'>
                              {l.layer_depth_in ? l.layer_depth_in : ''}"
                            </span>
                          </Paper>
                        </Container>
                      </Box>
                    )}
                  </Draggable>
                )
              })}
                {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
};
CoreLayerDraggableContext.propType = {
  layers: T.array
};
export default CoreLayerDraggableContext;