import {
  SCANS_LOADING_REQUEST,
  SCANS_LOADING_SUCCESS,
  SCANS_LOADING_ERROR,
  SCAN_UPDATE_FIELD,
  SCAN_STATUS_UPDATE,
  SCAN_LOG_REQUEST,
  SCAN_LOG_SUCCESS,
  SCAN_LOG_ERROR,
  SCAN_STATUS_LIST_REQUEST,
  SCAN_STATUS_LIST_SUCCESS,
  SCAN_STATUS_LIST_ERROR,
  USER_SCANS_BY_STATUS_DAY_ERROR,
  USER_SCANS_BY_STATUS_DAY_REQUEST,
  USER_SCANS_BY_STATUS_DAY_SUCCESS,
  SCAN_SCHED_SERIAL_DATA_REQUEST,
  SCAN_SCHED_SERIAL_DATA_SUCCESS,
  SCAN_SCHED_SERIAL_DATA_ERROR,
  SCANS_BY_STATUS_DAY_SUCCESS,
  SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_REQUEST,
  SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_SUCCESS,
  SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_ERROR,
  VALIDATE_SCANS_REQUEST,
  VALIDATE_SCANS_SUCCESS,
  VALIDATE_SCANS_ERROR
} from "../constants/scanTypes";
import { COLUMNS_LOADING_SUCCESS } from "../constants/globalTypes";
import { parseAllToJson } from "../utils/string";
import { getFormattedDateTime } from "../utils/dates";

const initialState = {
  isError: false,
  isLoading: false,
  scans: [],
  columns: [],
  scanStartDate: "", //getFormattedDateTime(new Date()),
  scanEndDate: "", //getFormattedDateTime(new Date()),
  scanStatusName: localStorage.getItem("scanStatusName"),
  scanStatusId: localStorage.getItem("scanStatusId"),
  scanLog: {},
  statusList: [],
  userTotalScansByStatusToday: [],
  scanScheduleSerialData: {},
  isSerialSearchLoading: false
};

export default function scanListReducer(state = initialState, action) {
  switch (action.type) {
    case SCANS_LOADING_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SCANS_LOADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        scans: action.payload
      };
    case SCAN_STATUS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case SCAN_LOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case VALIDATE_SCANS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case USER_SCANS_BY_STATUS_DAY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case SCAN_SCHED_SERIAL_DATA_REQUEST:
      return {
        ...state,
        isSerialSearchLoading: true,
        isError: false
      };
    case COLUMNS_LOADING_SUCCESS:
      const columns = parseAllToJson(action.payload, "options");
      return {
        ...state,
        isLoading: false,
        isError: false,
        columns: columns
      };
    case SCAN_LOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        scanLog: action.payload
      };
    case VALIDATE_SCANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        scanLog: action.payload
      };
    case SCAN_STATUS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        statusList: action.payload
      };
    case USER_SCANS_BY_STATUS_DAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        userTotalScansByStatusToday: action.payload
      };
    case SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        userTotalScansByStatusToday: action.payload
      };
    case SCANS_BY_STATUS_DAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        totalScansByStatusToday: action.payload
      };
    case SCAN_SCHED_SERIAL_DATA_SUCCESS:
      return {
        ...state,
        isSerialSearchLoading: false,
        isError: false,
        scanScheduleSerialData: action.payload
      };
    case SCANS_LOADING_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case SCAN_STATUS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case SCAN_LOG_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case VALIDATE_SCANS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case USER_SCANS_BY_STATUS_DAY_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case SCAN_SCHED_SERIAL_DATA_ERROR:
      return {
        ...state,
        isSerialSearchLoading: false,
        isError: true
      };
    case SCAN_STATUS_UPDATE:
      localStorage.setItem("scanStatusName", action.scanStatusName);
      localStorage.setItem("scanStatusId", action.scanStatusId);
      return {
        ...state,
        scanStatusName: localStorage.getItem("scanStatusName"),
        scanStatusId: localStorage.getItem("scanStatusId")
      };
    case SCAN_UPDATE_FIELD:
      return {
        ...state,
        [action.name]: action.value
      };
    default:
      return state;
  }
}
