import React  from "react"

import {
    DEALER_UPDATE_FIELD,
    DEALERS_LOADING_REQUEST,
    DEALERS_LOADING_SUCCESS,
    DEALERS_LOADING_ERROR,
    DEALER_IMPORT_LOADING_REQUEST,
    DEALER_IMPORT_LOADING_SUCCESS,
    DEALER_IMPORT_LOADING_ERROR,
    DEALERS_SAVING_REQUEST,
    DEALERS_SAVING_ERROR,
    DEALERS_SAVING_SUCCESS,
} from '../constants/dealerTypes'

import {
    COLUMNS_LOADING_REQUEST,
    COLUMNS_LOADING_SUCCESS,
    COLUMNS_LOADING_ERROR,
} from "../constants/globalTypes";

import {parseAllToJson} from "../utils/string";
import Link from "@material-ui/core/Link";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    dealers: [],
    rows: [],
    cols: [],
    columns: [],
    file: '',
}

export default function dealerListReducer(state = initialState, action) {
    switch (action.type) {
        case DEALER_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case DEALERS_LOADING_REQUEST:
        case DEALER_IMPORT_LOADING_REQUEST:
        case DEALERS_SAVING_REQUEST:
        case COLUMNS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case DEALERS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                dealers: action.payload,
            }
        case DEALER_IMPORT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                dealers: action.dealers,
                rows: action.rows,
                cols: action.cols,
            }
        case DEALERS_SAVING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSaved: true,
                dealers: action.payload,
            }
        case COLUMNS_LOADING_SUCCESS:
            let columns = parseAllToJson(action.payload, 'options')
            columns = addColumnLinks(columns)
            return {
                ...state,
                isLoading: false,
                isError: false,
                columns: columns,
            }
        case DEALERS_LOADING_ERROR:
        case DEALER_IMPORT_LOADING_ERROR:
        case DEALERS_SAVING_ERROR:
        case COLUMNS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }

    function addColumnLinks(columns){
        columns.forEach(function checkOptions(column){
            if(column.options != null && column.options.nav === true){
                column.options = {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Link to={"/dealer"}>
                                <span className="menu-icon-e icon-back"/>
                            </Link>
                        )
                    }
                }
            }
        })

        return columns
    }
}