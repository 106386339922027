export const SCAN_UPDATE_FIELD = "SCAN_UPDATE_FIELD";
export const SCAN_STATUS_UPDATE = "SCAN_STATUS_UPDATE";

export const SCAN_LOG_REQUEST = "SCAN_LOG_REQUEST";
export const SCAN_LOG_SUCCESS = "SCAN_LOG_SUCCESS";
export const SCAN_LOG_ERROR = "SCAN_LOG_ERROR";

export const SCAN_STATUS_LIST_REQUEST = "SCAN_STATUS_LIST_REQUEST";
export const SCAN_STATUS_LIST_SUCCESS = "SCAN_STATUS_LIST_SUCCESS";
export const SCAN_STATUS_LIST_ERROR = "SCAN_STATUS_LIST_ERROR";

export const USER_SCANS_BY_STATUS_DAY_REQUEST =
  "USER_SCANS_BY_STATUS_DAY_REQUEST";
export const USER_SCANS_BY_STATUS_DAY_SUCCESS =
  "USER_SCANS_BY_STATUS_DAY_SUCCESS";
export const USER_SCANS_BY_STATUS_DAY_ERROR = "USER_SCANS_BY_STATUS_DAY_ERROR";

export const SCANS_BY_STATUS_DAY_REQUEST = "SCANS_BY_STATUS_DAY_REQUEST";
export const SCANS_BY_STATUS_DAY_SUCCESS = "USER_SCANS_BY_STATUS_DAY_SUCCESS";
export const SCANS_BY_STATUS_DAY_ERROR = "USER_SCANS_BY_STATUS_DAY_ERROR";

export const SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_REQUEST =
  "SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_REQUEST";
export const SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_SUCCESS =
  "SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_SUCCESS";
export const SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_ERROR =
  "SCANS_BY_STATUS_AND_SERIAL_NUMBER_30DAYS_ERROR";

export const SCAN_SCHED_SERIAL_DATA_SUCCESS = "SCAN_SCHED_SERIAL_DATA_SUCCESS";
export const SCAN_SCHED_SERIAL_DATA_ERROR = "SCAN_SCHED_SERIAL_DATA_ERROR";
export const SCAN_SCHED_SERIAL_DATA_REQUEST = "SCAN_SCHED_SERIAL_DATA_REQUEST";

export const SCAN_UPDATE_START_DATE_FIELD = "SCAN_UPDATE_START_DATE_FIELD";
export const SCAN_UPDATE_END_DATE_FIELD = "SCAN_UPDATE_END_DATE_FIELD";

export const SCANS_LOADING_REQUEST = "SCANS_LOADING_REQUEST";
export const SCANS_LOADING_SUCCESS = "SCANS_LOADING_SUCCESS";
export const SCANS_LOADING_ERROR = "SCANS_LOADING_ERROR";

export const STATION_LOADING_REQUEST = "STATIONS_LOADING_REQUEST";
export const STATION_LOADING_SUCCESS = "STATIONS_LOADING_SUCCESS";
export const STATION_LOADING_ERROR = "STATIONS_LOADING_ERROR";

export const REPRINT_LABEL_REQUEST = "REPRINT_LABEL_REQUEST";
export const REPRINT_LABEL_SUCCESS = "REPRINT_LABEL_SUCCESS";
export const REPRINT_LABEL_ERROR = "REPRINT_LABEL_ERROR";

export const LABEL_PRINTER_REQUEST = "LABEL_PRINTER_REQUEST";
export const LABEL_PRINTER_SUCCESS = "LABEL_PRINTER_SUCCESS";
export const LABEL_PRINTER_ERROR = "LABEL_PRINTER_ERROR";

export const VALIDATE_SCANS_REQUEST = "VALIDATE_SCANS_REQUEST";
export const VALIDATE_SCANS_SUCCESS = "VALIDATE_SCANS_SUCCESS";
export const VALIDATE_SCANS_ERROR = "VALIDATE_SCANS_ERROR";

export const FLATPACK_SHIPPING_REQUEST = "SCAN_LOG_REQUEST";
export const FLATPACK_SHIPPING_SUCCESS = "SCAN_LOG_SUCCESS";
export const FLATPACK_SHIPPING_ERROR = "SCAN_LOG_ERROR";
