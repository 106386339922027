import * as types from '../constants/fabricLayerTypeTypes'
import { RSAA } from 'redux-api-middleware'
import {removeEmptyFields} from "../utils/object";
import {API_ROOT} from "../utils/api";


export function updateField(name, value) {
    return {
        type: types.FABRIC_LAYER_TYPE_UPDATE_FIELD,
        name,
        value,
    }
}

export function getFabricLayerTypes() {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/layer-types`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_LAYER_TYPES_LOADING_REQUEST,
                types.FABRIC_LAYER_TYPES_LOADING_SUCCESS,
                types.FABRIC_LAYER_TYPES_LOADING_ERROR,
            ],
        },
    }
}

export function getFabricLayerType(id) {
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/layer-type/${id}`, // TODO: make base URL constant
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_LAYER_TYPE_LOADING_REQUEST,
                types.FABRIC_LAYER_TYPE_LOADING_SUCCESS,
                types.FABRIC_LAYER_TYPE_LOADING_ERROR,
            ],
        },
    }
}

export function saveFabricLayerTypeList(fabricLayerTypes){
    const data = []
    for (let field in fabricLayerTypes.data) {
        let row = removeEmptyFields(fabricLayerTypes.data[field])
        data.push(row)
    }
    const saveObj = {
        data: data,
    }
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/layer-types`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(saveObj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_LAYER_TYPES_SAVING_REQUEST,
                types.FABRIC_LAYER_TYPES_SAVING_SUCCESS,
                types.FABRIC_LAYER_TYPES_SAVING_ERROR,
            ],
        },
    }
}

export function saveFabricLayerType(fabricLayerType){
    return {
        [RSAA]: {
            endpoint: `${API_ROOT}/fabric/layer-type`, // TODO: make base URL constant
            method: 'POST',
            body: JSON.stringify(fabricLayerType),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            options: {
                mode: 'cors',
            },
            types: [
                types.FABRIC_LAYER_TYPE_SAVING_REQUEST,
                types.FABRIC_LAYER_TYPE_SAVING_SUCCESS,
                types.FABRIC_LAYER_TYPE_SAVING_ERROR,
            ],
        },
    }
}