
export const USER_UPDATE_FIELD = 'USER_UPDATE_FIELD'
export const USERS_UPDATE_FIELD = 'USER_UPDATE_FIELD'
export const USER_UPDATE_PASS_REQ = 'USER_UPDATE_PASS_REQ'
export const USER_RESET = 'USER_RESET'

export const USER_LOADING_REQUEST = 'USER_LOADING_REQUEST'
export const USER_LOADING_SUCCESS = 'USER_LOADING_SUCCESS'
export const USER_LOADING_ERROR = 'USER_LOADING_ERROR'

export const USERS_LOADING_REQUEST = 'USERS_LOADING_REQUEST'
export const USERS_LOADING_SUCCESS = 'USERS_LOADING_SUCCESS'
export const USERS_LOADING_ERROR = 'USERS_LOADING_ERROR'

export const USER_SAVING_REQUEST = 'USER_SAVING_REQUEST'
export const USER_SAVING_SUCCESS = 'USER_SAVING_SUCCESS'
export const USER_SAVING_ERROR = 'USER_SAVING_ERROR'

