import React  from "react"
import T from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../styles/about-page.css'
import {getFabricQuiltPatterns, saveFabricQuiltPatternList} from "../../actions/fabricQuiltPatternActions"
import {getColumns} from "../../actions/globalActions";
import MUIDataTable from "mui-datatables"
import {getDBFormattedDate} from "../../utils/dates"
import Loader from '../global/Loader'
import AddNewButton from "../global/AddNewButton";

const defaultColumnProperties = {
    sortable: true,
    width: 250
}

class FabricQuiltPatternList extends React.Component {

    getOptions(data) {

        const options = {
            filterType: 'multiselect',
            responsive: 'standard',
            fixedHeader: true,
            rowsPerPage: 100,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customToolbar: () => {
                return (
                    <AddNewButton
                        entity={"fabric/quilt/pattern/-1"}
                        history={this.props.history}
                        data={data}
                    />
                )
            }
        }
        return options
    }

    constructor(props) {
        super(props)
        this.state = {
            fabricQuiltPatterns: [],
            rows: [],
            cols: [],
            columns: [],
            filename: '',
            file: '',
        }
    }

    getColumns = e => {
        const {
            dispatch,
        } = this.props

        dispatch(getColumns('fabric_quilt_pattern'))
    }

    //Toolbar functions
    handleFabricQuiltPatternSaveClick = () => {
        const {
            dispatch,
            fabricQuiltPatterns,
        } = this.props

        const data = {
            data: fabricQuiltPatterns.concat(this.state.fabricQuiltPatterns),
            filename: this.state.filename,
        }

        dispatch(saveFabricQuiltPatternList(data))
        this.setState({
            cols: [],
            rows: [],
            fabricQuiltPatterns: [],
            filename: '',
        })
    }
    handleFabricQuiltPatternDeleteClick = () => {
        this.setState({
            cols: [],
            rows: [],
            schedule: [],
        })
    }
    /*END TOOLBAR FUNCTIONS*/

    componentDidMount () {

        this.getColumns()

        const {
            dispatch,
        } = this.props

        dispatch(getFabricQuiltPatterns())
    }

    render() {
        const {
            isLoading,
            fabricQuiltPatterns,
            columns,
        } = this.props

        return (
            <div className="baseContainer fabricQuiltPatternList">
                <h1>

                </h1>
                <MUIDataTable
                    title={"Quilt Patterns"}
                    data={fabricQuiltPatterns.concat(this.state.fabricQuiltPatterns)}
                    columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
                    options={this.getOptions(fabricQuiltPatterns.concat(this.state.fabricQuiltPatterns))}
                />
                <Loader isLoading={isLoading}/>
            </div>
        )
    }
}

FabricQuiltPatternList.defaultProps = {
    date: getDBFormattedDate()
}

FabricQuiltPatternList.propTypes = {
    isLoading: T.bool,
    isSaved: T.bool,
    fabricQuiltPatterns: T.array,
    rows: T.array,
    cols: T.array,
    columns: T.array,
    file: T.string,
}

function mapStateToProps(state) {
    const { fabricQuiltPatternReducer } = state
    return {
        isLoading: fabricQuiltPatternReducer.isLoading,
        fabricQuiltPatterns: fabricQuiltPatternReducer.fabricQuiltPatterns,
        rows: fabricQuiltPatternReducer.rows,
        cols: fabricQuiltPatternReducer.cols,
        columns: fabricQuiltPatternReducer.columns,
        file: fabricQuiltPatternReducer.file,
    }
}

export default withRouter(connect(mapStateToProps)(FabricQuiltPatternList))