import {
    PANELS_LOADING_ERROR,
    PANELS_LOADING_REQUEST,
    PANELS_LOADING_SUCCESS,
    PANEL_LOADING_ERROR,
    PANEL_LOADING_REQUEST,
    PANEL_LOADING_SUCCESS,
    PANEL_SAVING_ERROR,
    PANEL_SAVING_REQUEST,
    PANEL_SAVING_SUCCESS,
    PANEL_UPDATE_FIELD,
} from '../constants/panelTypes'
import {SKU_UPDATE_FIELD} from "../constants/skuTypes";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    panels: [],
    fabricUseName: '',
}

export default function panelReducer(state = initialState, action) {
    switch (action.type) {
        case PANEL_UPDATE_FIELD:
            return {
                ...state,
                isLoading: false,
                [action.name]: action.value,
            }
        case PANEL_LOADING_REQUEST:
        case PANEL_SAVING_REQUEST:
        case PANELS_LOADING_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case PANEL_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                fabricUseName: null,
                id: action.payload.id
            }
        case PANELS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                panels: action.payload,
            }
        case PANEL_SAVING_SUCCESS:
            return {
                isLoading: false,
                isError: false,
            }
        case PANEL_LOADING_ERROR:
        case PANEL_SAVING_ERROR:
        case PANELS_LOADING_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return {
                ...state,
                isLoading: false,
            }
    }
}
