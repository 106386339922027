import React, { useEffect, useState } from 'react';
import {
  getSkuLabor,
  updateSkuLabor,
  createSkuLabor,
  getActiveSkus,
  getAllSkus,
} from '../../actions/skuActions';
import { useDispatch } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  TextField,
  Tab,
  Tabs,
  makeStyles,
  Snackbar,
  Backdrop,
  Typography,
} from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import BasicTable from './BasicTable';
import { ExcelRenderer } from 'react-excel-renderer';
import { CSVLink } from 'react-csv';
import '../../styles/ShippingAddModal.styles.css';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(0,0,0,0.75)',
  },
  textStyles: {
    fontSize: '2.5rem',
    textShadow: '2px 0 black',
    fontWeight: 'bold',
  },
}));

const LaborList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [skuId, setSkuId] = useState([]);
  const [skuIdObj, setSkuIdObj] = useState([]);
  const [value, setValue] = useState('active');
  const [isActive, setActive] = useState(null);
  const [sku, setSku] = useState([]);
  const [previousLabor, setPreviousLabor] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [inputMessage, setInputMessage] = useState('Upload File');
  const [allSkus, setAllSkus] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState('info');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const csvData = [{ SkuColumn: ' ' }, { LaborCost: ' ' }];

  const selectedTabStyle = {
    backgroundColor: '#163762',
    color: '#FFFFFF',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  };
  const unselectedTabStyle = {
    backgroundColor: 'rgba(0,0,0,0.0)',
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    let skuIdArr = [];
    let skuName = [];
    if (skuId.length) {
      skuId.filter((skuObj) => {
        skuIdArr.push(skuObj.id);
        skuName.push(skuObj.sku);
      });
      setSkuIdObj(skuName);
    }
    getSkuName();
    getActive();
  }, [sku]);

  function getSkuName() {
    let getName = [...sku];
    let table = [];

    getName.filter((dataId) => {
      tableData.filter((element) => {
        if (element.sku_id === dataId.id) {
          element['sku'] = dataId.sku;
          table.push(element);
          setAllTableData(table);
        }
      });
    });
  }
  function getAllData() {
    let active = [];
    dispatch(getSkuLabor()).then((res) => {
      active.push(res.payload);
      let data = active[0].filter((res) => {
        if (res.date_end > moment('9999-01-01').format('YYYY-MM-DD')) {
          return active;
        }
      });
      setTableData(data);
      setPreviousLabor(res.payload);
    });
    dispatch(getActiveSkus()).then((res) => {
      setSkuId(res.payload);
    });
    dispatch(getAllSkus()).then((res) => {
      setSku(res.payload);
      setAllSkus(res);
    });
  }
  function getActive() {
    let unassigned = skuId.filter(
      (id) => !tableData.find((f) => f.sku_id == id.id)
    );
    setActive(unassigned);
  }
  function addNewEntry(newData) {
    let getName = [...sku];
    getName.filter((s) => {
      if (newData.sku_id === s.sku) {
        newData['sku_id'] = s.id;
      }
    });
    dispatch(createSkuLabor(newData)).then((res) => {
      let idCheck = newData.sku_id;
      if (res.type === 'SKU_SAVING_SUCCESS') {
        getAllData();
        let tableToUpdate = tableData.filter(
          (oldData) => oldData.sku_id === idCheck
        );
        tableToUpdate.filter((checkDate) => {
          checkDate.date_end > newData.date_start;
        });
        tableToUpdate.map((data) => {
          let startDate = moment(newData.date_start)
            .subtract(1, 'days')
            .format('YYYY-MM-DD');

          let updatedData = {
            id: data.id,
            sku_id: parseInt(data.sku_id),
            sku_labor: parseFloat(data.sku_labor),
            date_start: moment(data.date_start).format('YYYY-MM-DD'),
            date_end: startDate,
          };
          upDateEntry(updatedData);
        });
      }
    });
  }

  async function upDateEntry(newData) {
    dispatch(updateSkuLabor(newData)).then((res) => {
      if (res.type === 'SKU_SAVING_SUCCESS') {
        getAllData();
      }
    });
  }

  const tableColumns = [
    {
      title: 'Sku id',
      field: 'sku',
      type: 'numeric',
      editable: 'onAdd',
      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => {
        return (
          <Autocomplete
            options={skuIdObj}
            getOptionLabel={(skuId) => skuId.toString()}
            name={'sku'}
            defaultValue={rowData.sku ? rowData.sku : null}
            onChange={(e, value) => {
              e.stopPropagation();
              onChange(value);
              onRowDataChange(value);
            }}
            onChange={(e, value) => {
              e.stopPropagation();
              const newRowData = {
                ...rowData,
                sku_id: value,
              };
              onChange(value);
              onRowDataChange(newRowData);
            }}
            renderInput={(params) => (
              <div
                ref={params.InputProps.ref}
                style={{ marginTop: '-10px', marginLeft: '20px' }}
              >
                <TextField
                  name={'sku_id'}
                  {...params.inputProps}
                  variant='standard'
                  margin='normal'
                  inputProps={{
                    ...params,
                    onKeyDown: (e) => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              </div>
            )}
          />
        );
      },
    },
    {
      title: 'Sku Labor',
      field: 'sku_labor',
      type: 'numeric',
      editComponent: ({ onRowDataChange, onChange, rowData, ...props }) => (
        <TextField
          type='numeric'
          value={props.value ? props.value : ''}
          onChange={(e) => {
            e.stopPropagation();
            onChange(e.target.value);
          }}
        />
      ),
    },
  ];

  function getTabDetails(tabName) {
    if (tabName === 'active') {
      if (allTableData.length) {
        return (
          <fieldset style={{ border: 'none' }}>
            <MaterialTable
              key={allTableData.id}
              style={{ color: 'black' }}
              title='Sku Labor Cost'
              options={{
                pageSize: 20,
                addRowPosition: 'first',
                actionsColumnIndex: -1,
              }}
              columns={tableColumns}
              data={allTableData}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      setAllTableData([...tableData, newData]);
                      const data = {
                        sku_id: newData.sku_id,
                        sku_labor: parseFloat(newData.sku_labor),
                        date_start: moment().format('YYYY-MM-DD'),
                        date_end: moment('9999-01-01').format('YYYY-MM-DD'),
                      };
                      addNewEntry(data);
                      resolve();
                    }, 1000);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...tableData];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;

                      let endDate = moment()
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD');
                      const updateOldData = {
                        id: oldData.id,
                        sku_id: parseInt(oldData.sku_id),
                        sku_labor: parseFloat(oldData.sku_labor),
                        date_start: moment(oldData.date_start).format(
                          'YYYY-MM-DD'
                        ),
                        date_end: endDate,
                      };
                      upDateEntry(updateOldData);
                      const data = {
                        sku_id: parseInt(newData.sku_id),
                        sku_labor: parseFloat(newData.sku_labor),
                        date_start: moment().format('YYYY-MM-DD'),
                        date_end: moment('9999-01-01').format('YYYY-MM-DD'),
                      };
                      addNewEntry(data);

                      resolve();
                    }, 1000);
                  }),
              }}
            />
          </fieldset>
        );
      }
    } else if (tabName === 'without') {
      return <BasicTable active={isActive} />;
    }
  }

  async function fileHandler(event) {
    let fileObj = event.target.files[0];
    let indexes = [];
    let newDataToSave = [];
    let oldDataToUpdate = [];
    const errorHandlingString = fileObj.name.toLowerCase().slice(-4);

    setInputMessage(fileObj.name);
    setSnackbarType('success');
    setSnackbarMessage(`All Entries were saved!`);

    if (errorHandlingString !== '.csv') {
      setSnackbarType('error');
      setSnackbarMessage('Please choose a correct File Type');
      handleSnackbarClick();
    } else if (errorHandlingString == '.csv') {
      setOpen(!open);
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          setOpen(false);
          console.log(err);
        } else if (resp.rows[0][1] !== 'LaborCost') {
          setSnackbarType('error');
          setSnackbarMessage('Please use a LaborCost CSV');
          setOpen(false);
          handleSnackbarClick();
          return;
        } else {
          for (const index of resp.rows) {
            let foundIndex = 'this is a test';
            let oldLabor = 'this will become old labor info';
            let parentIndex = 'this will become an object';
            for (const value of allSkus.payload) {
              if (index[0] == value.sku) {
                foundIndex = value.id;
                parentIndex = value;
              }
            }
            for (const value of previousLabor) {
              if (foundIndex === value.sku_id) {
                oldLabor = value;
              }
            }
            let indexObject = {
              id: undefined,
              sku_id: foundIndex,
              sku_name: index[0],
              sku_labor: index[1],
              parentIndex: parentIndex,
              previousInfo: oldLabor,
            };
            indexes.push(indexObject);
          }
          indexes.shift();
        }
        for (const index of indexes) {
          for (let i = 0; i < previousLabor.length; i++) {
            if (index.sku_id == previousLabor[i].sku_id) {
              index.id = previousLabor[i].id;
            }
          }
          let oldData = index.parentIndex;
          let endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
          const updateOldData = {
            id: index.id,
            sku_id: index.sku_id,
            sku_labor: index.previousInfo.sku_labor,
            date_start: moment(oldData.date_start).format('YYYY-MM-DD'),
            date_end: endDate,
          };
          oldDataToUpdate.push(updateOldData);
          const newDataEntry = {
            sku_id: index.previousInfo.sku_id,
            sku_labor: index.sku_labor,
            date_start: moment().format('YYYY-MM-DD'),
            date_end: moment().format('9999-01-01'),
          };
          if (newDataEntry.sku_id === undefined) {
            setSnackbarType('error'),
              setSnackbarMessage(
                'Something went wrong, one or more entries were not updated.'
              );
            continue;
          } else {
            newDataToSave.push(newDataEntry);
          }
        }
        for (const index of oldDataToUpdate) {
          const updateAllPromise = Promise.allSettled([
            dispatch(updateSkuLabor(index)).then((res) => {
              return res.type;
            }),
          ]).then((results) =>
            results.forEach((result) => {
              if (result.value === 'SKU_SAVING_ERROR') {
                const createErrorType = new Promise(
                  (resolve, reject) => setSnackbarType('error'),
                  setSnackbarMessage(
                    'Something went wrong, one or more entries were not updated.'
                  )
                );
              }
            })
          );
        }
        for (const index of newDataToSave) {
          const createAllPromise = Promise.allSettled([
            dispatch(createSkuLabor(index)).then((res) => {
              return res.type;
            }),
          ]).then((results) =>
            results.forEach((result) => {
              if (result.value === 'SKU_SAVING_ERROR') {
                const createErrorType = new Promise(
                  (resolve, reject) => setSnackbarType('error'),
                  setSnackbarMessage(
                    'Something went wrong, one or more entries were not updated.'
                  )
                );
              }
            })
          );
        }
        setOpen(false);
        handleSnackbarClick();
        getAllData();
      });
    }
  }
  const handleSnackbarClick = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <div>
        <Backdrop
          className={classes.backdrop}
          open={open}
          onClick={() => {
            setOpen(false);
          }}
        >
          <Typography display='inline' className={classes.textStyles}>
            Loading, please wait. <br /> This may take a while; <br /> do not
            refresh the page.
          </Typography>
        </Backdrop>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarType}
          sx={{ width: '150%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Container maxWidth='xl'>
        <Grid container justifyContent='center'>
          <Box m={2} pt={3}>
            <Tabs value={value} indicatorColor='primary'>
              <Tab
                value='active'
                label='Active Labor Cost'
                onClick={() => setValue('active')}
                style={
                  value === 'active' ? selectedTabStyle : unselectedTabStyle
                }
              />

              <Tab
                value='without'
                label='Skus Missing Labor Cost'
                onClick={() => setValue('without')}
                style={
                  value === 'without' ? selectedTabStyle : unselectedTabStyle
                }
              />
            </Tabs>
            <input
              type='file'
              name='file'
              id='file'
              className='inputfile'
              onChange={fileHandler.bind(this)}
              style={{ padding: '10px' }}
            />
            <label htmlFor='file'>{inputMessage}</label>
            <CSVLink data={csvData} filename='LaborCost.csv'>
              <label
                className='shippingAddButton'
                style={{ marginLeft: '5px' }}
              >
                Download LaborCost Template
              </label>
            </CSVLink>
          </Box>
        </Grid>
        <div className='dataTable_row'>{getTabDetails(value)}</div>
      </Container>
    </>
  );
};

export default LaborList;
